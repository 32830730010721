import React from 'react'
import { ActionButton, Avatar, Details, Group, Item, Widget } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { formatPeriod } from '@src/utils/format'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { makeReviewTypesDescription } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSettings/constants'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

interface Props {
  cycle: ReviewCyclesInterface
  settings?: PerformanceSettingsInterface
}

export const CycleSettings = ({ cycle, settings }: Props) => {
  const { id, name } = cycle

  const isTest = cycleModel.isTest(cycle)
  const isAdHoc = cycleModel.isAdHoc(cycle)
  const hasAutoScorecardsGeneration = cycleModel.hasAutoScorecardsGeneration(cycle)
  const startDate = cycleModel.getStartDate(cycle)
  const endDate = cycleModel.getEndDate(cycle)

  const editSettingsPath = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL_SETTINGS, { id })

  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="IndustrialGear" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Cycle Settings</Item.Title>
        </Item.Content>
        <Item.Side>
          <Item.Value>
            <ActionButton to={editSettingsPath} use={InternalLink}>
              Edit
            </ActionButton>
          </Item.Value>
        </Item.Side>
      </Item>
      <Group px="s-16" style={transparentThemeBackgroundOverrides}>
        <Details>
          <Details.Title>Cycle name</Details.Title>
          <Details.Content>{name}</Details.Content>
        </Details>
        {!isTest && !isAdHoc && (
          <Details>
            <Details.Title>Cycle timeframe</Details.Title>
            <Details.Content>{formatPeriod(startDate, endDate)}</Details.Content>
          </Details>
        )}
        {settings && (
          <Details>
            <Details.Title>Review types</Details.Title>
            <Details.Content>
              {makeReviewTypesDescription(cycle, settings)}
            </Details.Content>
          </Details>
        )}
        <Details>
          <Details.Title>Auto scorecard generation</Details.Title>
          <Details.Content>
            {hasAutoScorecardsGeneration ? 'Enabled' : 'Disabled'}
          </Details.Content>
        </Details>
      </Group>
    </Widget>
  )
}
