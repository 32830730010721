import React, { useState } from 'react'
import { ApprovalFlowResponse, ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { EntityPermissions } from '@src/store/auth/types'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { UseQueryResult } from 'react-query'
import { FieldOptions } from '@src/interfaces'
import { ApproveButton } from '@src/features/SettingsButtons/ApproveButton/ApproveButton'
import { MutationResult } from '@src/features/SettingsButtons/types'
import { RejectButton } from '@src/features/SettingsButtons/RejectButton/RejectButton'

interface UseApprovalReturnType {
  approvalButtons: React.ReactNode | null
  approvalFlow: React.ReactNode | null
  refetchApproval: () => Promise<any>
}

export interface UseApprovalOptions {
  useGetApprovals: (id?: number) => UseQueryResult<ApprovalFlowResponse>
  isPendingApproval?: boolean
  statusFieldName?: string
  data?: { id: number; field_options: FieldOptions }
  noReject?: boolean
  approveButtonActionText?: string
  onViewRejectionReasonClick?: (comment?: string | null) => void
  api: () => MutationResult
}

const useApprovalFlow = ({
  data,
  useGetApprovals,
  statusFieldName = 'status',
  noReject,
  approveButtonActionText,
  onViewRejectionReasonClick,
  api,
  ...props
}: UseApprovalOptions): UseApprovalReturnType => {
  const [isApproving, setIsApproving] = useState(false)

  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useGetApprovals(data?.id)

  if (!data?.id) {
    return { approvalButtons: null, approvalFlow: null, refetchApproval }
  }

  const isPendingApproval =
    props.isPendingApproval !== undefined
      ? props.isPendingApproval
      : /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        data?.[statusFieldName] === ApprovalStatuses.Pending

  const canApprove =
    isPendingApproval && data?.field_options?.actions?.includes(EntityPermissions.Approve)

  const approvalFlow = (
    <ApprovalFlow
      isLoading={isApprovalLoading || isApproving}
      steps={approvalSteps || null}
      onViewRejectionReasonClick={onViewRejectionReasonClick}
    />
  )

  const approvalButtons = canApprove ? (
    <>
      <ApproveButton
        onBeforeSubmit={() => setIsApproving(true)}
        onAfterSubmit={() => {
          setIsApproving(false)
          refetchApproval()
        }}
        data={data}
        api={api}
        statusFieldName={statusFieldName}
        isVisible={canApprove}
        buttonActionText={approveButtonActionText}
      />
      <RejectButton
        onBeforeSubmit={() => setIsApproving(true)}
        onAfterSubmit={() => {
          setIsApproving(false)
          refetchApproval()
        }}
        data={data}
        api={api}
        statusFieldName={statusFieldName}
        rejectionFieldName="rejection_comment"
        isVisible={canApprove && !noReject}
      />
    </>
  ) : null

  return { approvalButtons, approvalFlow, refetchApproval }
}

export default useApprovalFlow
