import React from 'react'
import {
  Box,
  HStack,
  ItemSkeleton,
  Progress,
  ProgressSteps,
  TextSkeleton,
  TextWidget,
  TextWidgetSkeleton,
} from '@revolut/ui-kit'
import { StageProgressStep } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/ProgressWidget/StageProgressStep'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { formatPercentage } from '@src/utils/format'
import { adaptVisibleStagesProps } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/ProgressWidget/adaptVisibleStagesProps'

interface Props {
  cycle: ReviewCyclesInterface
  stages: ReviewCycleStage[]
  selectedStage: ReviewCycleStage
  handleSelectionChange: (stage: ReviewCycleStage) => () => void
}

export const ProgressWidget = ({
  cycle,
  stages,
  selectedStage,
  handleSelectionChange,
}: Props) => (
  <TextWidget>
    <TextWidget.Title>Timeline</TextWidget.Title>
    <TextWidget.Content>
      <ProgressSteps>
        {adaptVisibleStagesProps(cycle, selectedStage, stages).map(
          ({ key, ...props }) => (
            <StageProgressStep
              key={key}
              {...props}
              onClick={handleSelectionChange(key)}
            />
          ),
        )}
      </ProgressSteps>
    </TextWidget.Content>
  </TextWidget>
)

export const ProgressWidgetSkeleton = () => {
  const stepsCount = 5
  const stepWidth = formatPercentage(1 / stepsCount)

  return (
    <>
      <ItemSkeleton />
      <Box>
        <TextWidgetSkeleton>
          <TextWidgetSkeleton.Title />
          <HStack space="s-16">
            {[...Array(stepsCount)].map((_, index) => (
              <Box key={index} width={stepWidth}>
                <Progress />
                <Box pt="s-16">
                  <TextSkeleton />
                  <TextSkeleton />
                </Box>
              </Box>
            ))}
          </HStack>
        </TextWidgetSkeleton>
      </Box>
    </>
  )
}
