import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { IdAndName } from '@src/interfaces'
import { PromotionStage, PromotionStageInterface } from '@src/interfaces/promotions'

export const promotionTimelineStepsMap = {
  1: '1',
  2: '2a',
  3: '2b',
  4: '2c',
  5: '3',
  6: '4',
  7: '5',
  8: '6',
}

export const useCanNominateAnyTime = () => {
  const permissions = useSelector(selectPermissions)
  return permissions.includes(PermissionTypes.PromotionsAnyTimeNomination)
}

export const comparePromotionStagesBy =
  (order: IdAndName<PromotionStage>[]) =>
  (lhs: PromotionStageInterface, rhs: PromotionStageInterface) =>
    order.findIndex(({ id }) => lhs.stage_type.id === id) -
    order.findIndex(({ id }) => rhs.stage_type.id === id)
