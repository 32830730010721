import React from 'react'
import { FormattedMessage } from 'react-intl'

import { AppError } from '../parseError'

interface ActionErrorTitleProps {
  error: AppError
  fallbackTitle: string
}

export const ActionErrorTitle = ({ error, fallbackTitle }: ActionErrorTitleProps) => {
  switch (error.type) {
    case 'forbidden':
      return (
        <FormattedMessage
          id="error.action.noPermission"
          defaultMessage="You do not have permission to perform this action"
        />
      )
    case 'bad_request': {
      if (error.action === 'create') {
        return (
          <FormattedMessage
            id="error.action.badRequest.submit"
            defaultMessage="Failed to submit"
          />
        )
      }
      if (error.action === 'update') {
        return (
          <FormattedMessage
            id="error.action.badRequest.update"
            defaultMessage="Failed to update"
          />
        )
      }
      if (error.action === 'delete') {
        return (
          <FormattedMessage
            id="error.action.badRequest.delete"
            defaultMessage="Failed to delete"
          />
        )
      }
      return (
        <FormattedMessage
          id="error.action.badRequest.generic"
          defaultMessage="Could not handle request data"
        />
      )
    }
    case 'resource_not_found':
      return (
        <FormattedMessage
          id="error.action.resourceNotFound"
          defaultMessage="Resource not found"
        />
      )
    default:
      return <>{fallbackTitle}</>
  }
}
