import React, { ReactNode, Ref, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { SeniorityInterface } from '@src/interfaces/seniority'
import {
  CompetencyMatrixInterface,
  CompetencyMatrixSkill,
  SkillLevels,
} from '@src/interfaces/roles'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { SkillInterface } from '@src/interfaces/skills'
import { getMatrixSkills } from '@src/api/skills'
import {
  CellInsertParams,
  CellTypes,
  ColumnCellInterface,
  RowInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import {
  Box,
  Flex,
  IconButton,
  InputBase,
  Link,
  Spinner,
  Text,
  Token,
} from '@revolut/ui-kit'
import { RadioSelect } from '@components/Inputs/RadioSelect/RadioSelect'
import { RadioOptionInterface } from '@components/Inputs/RadioButtons/RadioButtons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Tooltip from '@components/Tooltip/Tooltip'
import { TableCellInputType } from '@components/Inputs/TableCellInput/TableCellInput'
import { connect, useLape } from 'lape'
import { useTable } from '@components/TableV2/hooks'
import { GetRequestInterface, IdAndName } from '@src/interfaces'
import { AxiosResponse } from 'axios'
import { ChevronDown, ChevronUp, Delete } from '@revolut/icons'
import WeightCell from '@src/features/CompetencyMatrixTable/WeightCell'
import Table from '@components/Table/Table'
import AdjustableTableLegacy from '@components/Table/AdjustableTable'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import {
  CompetencyLevels,
  getCompetencyBackground,
  getCompetencyOptions,
  getNormalizedSeniorityName,
  getSkillLevelDescription,
} from '@src/features/CompetencyMatrixTable/utils'
import { getLocationDescriptor, useOpenNewTab } from '@src/actions/RouterActions'
import { TableTypes } from '@src/interfaces/table'
import { TableNames } from '@src/constants/table'
import RadioSelectInput, {
  createNewKey,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { useGetSeniorityList } from '@src/api/seniority'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

const SkillWrap = styled.div`
  width: 100%;
  height: 50px;
  display: grid;
  align-items: center;
`

const StaticCompetencyTooltip = styled(Tooltip)`
  height: 100%;
  width: 100%;
  justify-content: flex-start;
`

const RadioSelectStyled = styled(RadioSelect)`
  padding: 0 3px;
  border: 0;
`

export interface MatrixRowInterface {
  sectionTitle: ReactNode
  isSkillOptional?: boolean
  staticSkill?: boolean
  disabled?: boolean
  children?: CompetencyMatrixInterface[]
  matrixToFilterOut?: CompetencyMatrixInterface[]
  onChange?: (data?: CompetencyMatrixInterface[], id?: string) => void
  disableWeights?: boolean
  hideActionsColumn?: boolean
}

const row = ({
  onChangeCompetency,
  onChangeSkill,
  onDelete,
  skillsets,
  competencyMatrices,
  seniorities,
  firstRowTitle,
  approvedSkills,
  withWeightColumn,
  onChangeWeight,
  weightsError,
  filterNonExistent,
  readOnly,
  navigateToNewTab,
  canAddSkill,
}: {
  onChangeCompetency?: (
    competencyLevel: SkillLevels,
    seniorityId: number,
    seniorityName: string,
    seniorityLevel: number,
    index: number,
    matrixId: number,
  ) => void
  onChangeSkill?: (skillId: number, index: number, matrixId: number) => void
  onDelete?: (index: number, matrixId: number) => void
  skillsets: CompetencyMatrixSkill[]
  competencyMatrices: MatrixRowInterface[]
  seniorities: SeniorityInterface[]
  firstRowTitle?: string
  withWeightColumn?: boolean
  approvedSkills: CompetencyMatrixSkill[]
  onChangeWeight?: (index: number, weight: number, matrixId: number) => void
  weightsError?: boolean
  filterNonExistent?: boolean
  readOnly?: boolean
  navigateToNewTab: (actionUrl: string) => void
  canAddSkill: boolean
}): RowInterface<CompetencyMatrixInterface> => ({
  noChildrenRequest: true,
  cells: [
    {
      type: CellTypes.insert,
      idPoint: 'title',
      dataPoint: 'title',
      selectorsKey: selectorKeys.none,
      filterKey: null,
      sortKey: null,
      width: 280,
      notHoverable: true,
      title: firstRowTitle,
      insert: ({
        data,
        parentIndexes,
      }: CellInsertParams<MatrixRowInterface | CompetencyMatrixInterface>) => {
        const isNested = parentIndexes.length !== 1
        const index = isNested ? parentIndexes[1] : parentIndexes[0]
        const matrixId = isNested ? parentIndexes[0] : 0
        const competencyMatrix = competencyMatrices[matrixId]
        const { matrixToFilterOut, staticSkill = true } = competencyMatrix
        if (data?.sectionTitle) {
          return (
            <Flex alignItems="center" height={48}>
              <Text color="grey-tone-50" fontWeight={500}>
                {data.sectionTitle}
              </Text>
            </Flex>
          )
        }

        const d = data as CompetencyMatrixInterface
        if (staticSkill || readOnly) {
          return (
            <SkillWrap data-testid={`skill-name-${index}`}>
              <Flex alignItems="center" justifyContent="space-between" maxWidth={203}>
                {d.skill?.id ? (
                  <Link
                    to={pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, {
                      id: d.skill.id,
                    })}
                    use={InternalLink}
                    target="_blank"
                    color="inherit"
                  >
                    {d.skill?.name}
                  </Link>
                ) : (
                  d.skill?.name
                )}
              </Flex>
            </SkillWrap>
          )
        }

        let options = approvedSkills
          .map(item => ({
            label: item.name!,
            value: {
              id: +item.id!,
              name: item.name!,
            },
          }))
          .filter(skill => {
            return !competencyMatrix?.children?.some((s, i) => {
              if (i !== index) {
                return s.skill?.name === skill.label
              }

              return false
            })
          })

        if (matrixToFilterOut) {
          options = options.filter(skill => {
            return !matrixToFilterOut.some(s => {
              return s.skill?.name === skill.label
            })
          })
        }

        return (
          <SkillWrap>
            <RadioSelectInput<IdAndName<string | number>>
              label="Select a skill"
              options={options}
              // when a functional skill is in pending status, it's not in the options,
              // so to make sure it's name is shown, we need to explicitly specify the label
              value={{
                id: d.skill?.id || '',
                name: d.skill?.name || '',
              }}
              onChange={option => {
                if (option?.id === createNewKey) {
                  navigateToNewTab(pathToUrl(ROUTES.FORMS.SKILL.GENERAL))
                } else {
                  onChangeSkill &&
                    option &&
                    onChangeSkill(Number(option.id), index, matrixId)
                }
              }}
              disabled={staticSkill}
              name={`skill-select-${index}`}
              placement="bottom"
              showCreateNewButton={canAddSkill}
              renderInput={(open, setOpen, ref) => (
                <Flex onClick={() => setOpen(!open)}>
                  <InputBase
                    placeholder="Select a skill"
                    value={d.skill?.name || ''}
                    ref={ref as Ref<HTMLInputElement>}
                    data-testid={`matrix-table-skill-${index}`}
                    readOnly
                  />
                  {open ? <ChevronUp size={22} /> : <ChevronDown size={22} />}
                </Flex>
              )}
              referenceUrl={
                d.skill?.id
                  ? getLocationDescriptor(
                      pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, {
                        id: d.skill.id,
                      }),
                    )
                  : undefined
              }
            />
          </SkillWrap>
        )
      },
    },
    ...seniorities.map(seniority => ({
      type: CellTypes.insert,
      idPoint: seniority.id,
      dataPoint: seniority.name,
      selectorsKey: selectorKeys.none,
      filterKey: null,
      sortKey: null,
      width: 112,
      background: (data: MatrixRowInterface | CompetencyMatrixInterface) => {
        if (data.sectionTitle) {
          return undefined
        }
        return getCompetencyBackground(seniority.level, data as CompetencyMatrixInterface)
      },
      title: getNormalizedSeniorityName(seniority.name),
      insert: ({
        data,
        parentIndexes,
      }: CellInsertParams<MatrixRowInterface | CompetencyMatrixInterface>) => {
        const isNested = parentIndexes.length !== 1
        const index = isNested ? parentIndexes[1] : parentIndexes[0]
        const matrixId = isNested ? parentIndexes[0] : 0
        const isDisabled = competencyMatrices[matrixId].disabled
        const competencyMatrix = competencyMatrices[matrixId].children || []
        if (data?.sectionTitle) {
          return <Box height={48} />
        }
        const d = data as CompetencyMatrixInterface
        const skillset = skillsets.find(item => item.id === d.skill?.id)
        const competencyMatrixItem = d.skill?.id
          ? competencyMatrix.find(item => item.skill?.id === d.skill?.id)
          : competencyMatrix[index]

        const competencyItem = d?.competencies?.find(
          item => item.seniority_level === seniority.level,
        )!
        const competencyName = CompetencyLevels.find(
          item => item.id === competencyItem?.competency_level,
        )?.name
        const competency = {
          id: competencyItem?.competency_level,
          name: competencyName,
        }

        const tooltip = {
          tooltipTitle: '',
          tooltipText: '',
        }

        if (competency.id && skillset) {
          tooltip.tooltipTitle = `${competency.name} ${skillset.name}`
          tooltip.tooltipText = getSkillLevelDescription(competency.id, skillset) || ''
        }

        if (isDisabled || readOnly) {
          return (
            <SkillWrap data-testid={`competency-static-${index}-${seniority.name}`}>
              <StaticCompetencyTooltip
                placement="left"
                title={tooltip.tooltipTitle}
                text={tooltip.tooltipText}
                listStyle="letters"
              >
                {competency?.name || null}
              </StaticCompetencyTooltip>
            </SkillWrap>
          )
        }

        const shouldFilterNonExistent =
          filterNonExistent &&
          competencyMatrices[matrixId].sectionTitle === 'Deliverables'
        const selectOptions = getCompetencyOptions({
          skillset,
          competencies: competencyMatrixItem?.competencies,
          seniorityId: seniority.id,
          seniorities,
        })

        return (
          <SkillWrap data-testid={`competency-${index}-${seniority.name}`}>
            <RadioSelectStyled
              id={`select_competency_${firstRowTitle}_${index}_${seniority.level}`}
              options={
                shouldFilterNonExistent
                  ? selectOptions.filter(option => option.id !== 'none')
                  : selectOptions
              }
              onChange={option => {
                onChangeCompetency?.(
                  option.id as SkillLevels,
                  seniority.id,
                  seniority.name,
                  seniority.level,
                  index,
                  matrixId,
                )
              }}
              value={competency as RadioOptionInterface}
              width={480}
              listStyle="letters"
              {...tooltip}
            />
          </SkillWrap>
        )
      },
    })),
    withWeightColumn
      ? {
          type: CellTypes.insert,
          idPoint: 'weight',
          dataPoint: 'weight',
          sortKey: null,
          filterKey: null,
          width: 100,
          selectorsKey: selectorKeys.none,
          title: 'Weight',
          wrapperCss: ({ sectionTitle }: CompetencyMatrixInterface) => ({
            borderWidth: weightsError && !sectionTitle ? 1 : 0,
            borderStyle: 'solid',
            borderColor: Token.color.red,
          }),
          insert: ({
            data,
            parentIndexes,
          }: CellInsertParams<MatrixRowInterface | CompetencyMatrixInterface>) => {
            const isNested = parentIndexes.length !== 1
            const index = isNested ? parentIndexes[1] : parentIndexes[0]
            const matrixId = isNested ? parentIndexes[0] : 0
            const isDisabled =
              competencyMatrices[matrixId].disabled &&
              competencyMatrices[matrixId].disableWeights
            if (data?.sectionTitle) {
              return null
            }
            const d = data as CompetencyMatrixInterface
            return (
              <WeightCell
                value={d.weight || 0}
                type={TableCellInputType.float}
                disabled={isDisabled || readOnly}
                onBlur={v => {
                  onChangeWeight?.(index, +v, matrixId)
                }}
                testid={`weight_${firstRowTitle}_${index}`}
              />
            )
          },
        }
      : undefined,
    !competencyMatrices?.every(d => !!d.hideActionsColumn) && !readOnly
      ? {
          type: CellTypes.insert,
          idPoint: 'delete',
          dataPoint: 'delete',
          selectorsKey: selectorKeys.none,
          filterKey: null,
          sortKey: null,
          width: 45,
          title: '',
          insert: ({
            parentIndexes,
            data,
          }: CellInsertParams<MatrixRowInterface | CompetencyMatrixInterface>) => {
            const isNested = parentIndexes.length !== 1
            const index = isNested ? parentIndexes[1] : parentIndexes[0]
            const matrixId = isNested ? parentIndexes[0] : 0
            const isSkillOptional = competencyMatrices[matrixId].isSkillOptional
            const isDisabled = competencyMatrices[matrixId].disabled
            const competencyMatrix = competencyMatrices[matrixId].children || []
            if (data?.sectionTitle) {
              return null
            }

            return (
              <Flex alignItems="center">
                {onDelete &&
                  (isSkillOptional || competencyMatrix.length > 1) &&
                  !isDisabled && (
                    <Box>
                      <IconButton
                        data-testid={`delete-${firstRowTitle}-${index}`}
                        useIcon={Delete}
                        aria-label={`Delete ${firstRowTitle} ${index}`}
                        onClick={() => onDelete(index, matrixId)}
                        color={Token.color.greyTone20}
                        size={16}
                      />
                    </Box>
                  )}
              </Flex>
            )
          },
        }
      : undefined,
  ].filter(i => i) as ColumnCellInterface<
    MatrixRowInterface | CompetencyMatrixInterface
  >[],
})

type Props = {
  minSeniority?: SeniorityInterface
  maxSeniority?: SeniorityInterface
  seniorities?: SeniorityInterface[]
  competencyMatrices?: MatrixRowInterface[]
  withWeightColumn?: boolean
  firstRowTitle?: string
  weightsError?: boolean
  isAdjustable?: boolean
  isV2Table?: boolean
  approvedSkills?: CompetencyMatrixSkill[]
  filterNonExistent?: boolean
  readOnly?: boolean
}

const CompetencyMatrixTable = ({
  minSeniority,
  maxSeniority,
  competencyMatrices,
  withWeightColumn,
  firstRowTitle = 'Skill',
  weightsError,
  isAdjustable = true,
  isV2Table = false,
  filterNonExistent = false,
  readOnly = false,
  ...props
}: Props) => {
  const [skillsets, setSkillsets] = useState<SkillInterface[] | CompetencyMatrixSkill[]>(
    [],
  )
  const navigateToNewTab = useOpenNewTab()
  const permissions = useSelector(selectPermissions)

  const canAddSkill = permissions.includes(PermissionTypes.AddSkillset)

  const { data: fetchedSkills = [] } = useGetSelectors<CompetencyMatrixSkill>(
    props.approvedSkills ? null : selectorKeys.approved_functional_skills,
  )
  const { data: senioritiesResponse } = useGetSeniorityList({
    sortBy: [{ sortBy: 'level', direction: SORT_DIRECTION.DESC }],
  })
  const seniorities = senioritiesResponse?.results || []

  const approvedSkills = props.approvedSkills || fetchedSkills

  const state = useLape<{
    data: MatrixRowInterface[] | undefined
  }>({
    data: competencyMatrices,
  })

  const filteredSeniorities = useMemo(() => {
    if (minSeniority && maxSeniority && seniorities.length) {
      const minIndex = seniorities.findIndex(item => item.level === minSeniority.level)
      const maxIndex =
        seniorities.findIndex(item => item.level === maxSeniority.level) + 1
      return seniorities.slice(minIndex, maxIndex)
    }

    return seniorities
  }, [minSeniority, maxSeniority, seniorities])

  const table = useTable<MatrixRowInterface | CompetencyMatrixInterface>({
    getItems: () =>
      new Promise(resolve => {
        resolve({
          data: {
            results: state.data,
            count: state.data?.length,
          },
        } as AxiosResponse<GetRequestInterface<MatrixRowInterface | CompetencyMatrixInterface>>)
      }),
  })

  useEffect(() => {
    if (!competencyMatrices?.length) {
      return
    }

    state.data = competencyMatrices

    table.setData(state.data)
  }, [competencyMatrices])

  const fetchSkills = async () => {
    let result: SkillInterface[]
    try {
      result = await getMatrixSkills()
    } catch (e) {
      result = []
    }

    setSkillsets(result)
  }

  useEffect(() => {
    fetchSkills()
  }, [])

  const onChangeSkill = (skillId: number, index: number, matrixId: number) => {
    if (state?.data?.[matrixId]?.children?.[index]) {
      const skill = approvedSkills.find(item => item.id === skillId)
      state.data![matrixId]!.children![index].skill = skill!
      state.data![matrixId]!.children![index].competencies = state.data![
        matrixId
      ].children![index]?.competencies?.map(competency => ({
        ...competency,
      }))
      competencyMatrices?.[matrixId]?.onChange?.(state.data![matrixId]!.children)
    }
  }

  const onChangeWeight = (index: number, weight: number, matrixId: number) => {
    if (state?.data?.[matrixId]?.children?.[index]) {
      state.data![matrixId]!.children![index].weight = weight
      competencyMatrices?.[matrixId]?.onChange?.(state.data![matrixId]!.children)
    }
  }

  const onDelete = (index: number, matrixId: number) => {
    if (state?.data?.[matrixId]?.children?.[index]) {
      state.data![matrixId]!.children!.splice(index, 1)
      competencyMatrices?.[matrixId]?.onChange?.(state.data![matrixId]!.children)
    }
  }

  const onChangeCompetency = (
    competencyLevel: SkillLevels,
    seniorityId: number,
    seniorityName: string,
    seniorityLevel: number,
    index: number,
    matrixId: number,
  ) => {
    if (state?.data?.[matrixId]?.children?.[index]) {
      const result = state?.data?.[matrixId]?.children?.[index]?.competencies?.find(
        item => item.seniority_id === seniorityId,
      )
      if (result) {
        result.competency_level = competencyLevel
      } else {
        state?.data?.[matrixId]?.children?.[index]?.competencies?.push({
          seniority_id: seniorityId,
          seniority_name: seniorityName,
          seniority_level: seniorityLevel,
          competency_level: competencyLevel,
        })
      }

      competencyMatrices?.[matrixId]?.onChange?.(state.data![matrixId]!.children)
    }
  }

  if (!state.data) {
    return <Spinner />
  }

  const commonRowProps = {
    seniorities: filteredSeniorities,
    competencyMatrices: state.data,
    approvedSkills,
    skillsets,
    firstRowTitle,
    onChangeWeight: withWeightColumn ? onChangeWeight : undefined,
    weightsError,
    withWeightColumn,
    filterNonExistent,
  }

  const rowInstance = row({
    ...commonRowProps,
    readOnly,
    onChangeSkill,
    onDelete,
    onChangeCompetency,
    navigateToNewTab,
    canAddSkill,
  })

  if (competencyMatrices?.length === 1) {
    table.data = [...(state.data[0]?.children || [])]
  }

  const commonTableProps = {
    idPath: 'skill.id',
    childrenAlwaysOpen: competencyMatrices?.length !== 1,
    row: rowInstance,
    ...table,
  }

  if (isV2Table) {
    return (
      <AdjustableTable
        name={TableNames.CompetencyMatrix}
        {...commonTableProps}
        hideCount
      />
    )
  }

  return isAdjustable ? (
    <AdjustableTableLegacy
      name={TableNames.CompetencyMatrix}
      {...commonTableProps}
      hideCount
    />
  ) : (
    <Table
      name={TableNames.CompetencyMatrix}
      type={TableTypes.Adjustable}
      noAutoResize
      {...commonTableProps}
    />
  )
}

export default connect(CompetencyMatrixTable)
