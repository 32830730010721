import React from 'react'
import MenuAction, { MenuActionType } from '@components/MenuAction/MenuAction'
import { DropdownItemProps } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { removeStage } from '@src/api/recruitment/interviews'
import { getErrorMessageFromError } from '@src/store/notifications/actions'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'

interface Props {
  onLoading: (loading: boolean) => void
  onClose: VoidFunction
  onRefresh?: VoidFunction
  roundId: number
  stageId: number
  itemProps: DropdownItemProps
  menuType: MenuActionType
}

export const RemoveStageAction = ({
  menuType,
  roundId,
  stageId,
  onRefresh,
  itemProps,
  onLoading,
  onClose,
}: Props) => {
  const showConfirmationDialog = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()

  const onClick = async () => {
    showConfirmationDialog.show({
      label: 'You are about to remove this interview round',
      onConfirm: async () => {
        onLoading(true)

        try {
          await removeStage(roundId, stageId)
          onClose()
          onRefresh?.()
        } catch (error) {
          showStatusPopup({
            title: 'Cannot remove interview',
            description: getErrorMessageFromError(error),
            status: 'error',
          })
        } finally {
          onLoading(false)
        }
      },
    })
  }

  return (
    <MenuAction
      menuType={menuType}
      props={{
        ...itemProps,
        onClick,
      }}
    >
      Remove interview
    </MenuAction>
  )
}
