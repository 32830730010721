import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  Box,
  InputGroup,
  StatusPopup,
  Subheader,
  TabBar,
  Text,
  VStack,
} from '@revolut/ui-kit'
import {
  AvailableInterviewerSlot,
  CandidateInterface,
  DurationUnitType,
  InterviewStageWithoutRoundInterface,
  ScheduleSidebarModeType,
  SchedulingMode,
  SchedulingType,
  ScheduleInterviewInterface,
} from '@src/interfaces/interviewTool'
import { OptionInterface } from '@src/interfaces/selectors'
import PreviewScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/PreviewScheduleSidebar'
import EditScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/EditScheduleSidebar'
import ScheduledInterview from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduledInterview'
import Loader from '@components/CommonSC/Loader'
import {
  editInterview,
  useFetchDynamicSlotsCount,
  useGetSchedulingInterview,
} from '@src/api/recruitment/interviewerScheduling'
import {
  DateFilterOptions,
  DEFAULT_WEEKS_COUNT,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotDateFilter'
import EditCustomDate from '@src/pages/Forms/Candidate/ScheduleSidebar/EditCustomDate'
import EditSlotsDate from '@src/pages/Forms/Candidate/ScheduleSidebar/EditSlotsDate'
import EditManualDate from '@src/pages/Forms/Candidate/ScheduleSidebar/EditManualDate'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { IdAndName } from '@src/interfaces'
import CalendarSelect from '@src/pages/Forms/Candidate/ScheduleSidebar/CalendarSelect'
import {
  dateToCustomDate,
  customDateToDate,
  getPendingSchedulingStages,
  getTimeZoneId,
  getNewCustomDateValue,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { ExtensionApiHandlerContext } from '@src/utils/extension'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import EmailScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/EmailScheduleSidebar'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import SideBar from '@components/SideBar/SideBar'
import { useScheduleInterviewContext } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import { DynamicSlotsMode } from '@src/pages/Forms/Candidate/ScheduleSidebar/DynamicSlotsMode'
import { useDynamicSlotsFeature } from '@src/features/InterviewAvailability/hooks/useDynamicSlotsFeature'
import { notReachable } from '@src/utils/notReachable'

const DAYS_PER_WEEK = 7

export type ScheduleSidebarProps = {
  candidate: CandidateInterface
  stages: InterviewStageWithoutRoundInterface[]
  roundId?: number
  onClose?: () => void
  totalStagesCount: number
  selectedStageId?: number
  onRefresh?: () => void
  onSchedulingSuccess?: (stage: InterviewStageWithoutRoundInterface) => void
  initialMode?: ScheduleSidebarModeType
  onChangeInitialMode?: (mode: ScheduleSidebarModeType) => void
  interviewId?: number
  isPrepCall?: boolean
  isStageDisabled?: boolean
  type?: SchedulingMode
  readonly?: boolean
  noSidebar?: boolean
}

export enum Tabs {
  Automatic = 'Automatic slots',
  Custom = 'Custom available slot',
  Manual = 'Manual',
  Dynamic = 'Dynamic slots',
}

const getSelectedStage = (
  stages: InterviewStageWithoutRoundInterface[],
  selectedStageId?: number,
): InterviewStageWithoutRoundInterface | undefined =>
  stages.find(item => item.id === selectedStageId) || stages[0]

// TODO: refactor to get rid of the form state https://revolut.atlassian.net/browse/PERF-5777
// the component should be controlled outside, e.g. should not be mounted if closed, as we have a lot of logic here happening before the opening
const ScheduleSidebar = ({
  candidate,
  stages,
  roundId,
  selectedStageId,
  totalStagesCount,
  onRefresh,
  initialMode,
  onChangeInitialMode,
  onSchedulingSuccess,
  isStageDisabled,
  interviewId,
  isPrepCall,
  type = 'default',
  readonly,
}: Omit<ScheduleSidebarProps, 'onClose'>) => {
  const { values } = useLapeContext<ScheduleInterviewInterface>()
  const {
    setDuration,
    setDurationUnit,
    setInterviewStage,
    calendarEvent,
    setDisabledCalendar,
  } = useScheduleInterviewContext()
  const useDynamicSlots = useDynamicSlotsFeature()
  const { sendAnalyticsEvent } = useAnalytics()
  const apiHandler = useContext(ExtensionApiHandlerContext)
  const { data: durationUnits = [], isFetched: unitsFetched } = useGetSelectors<
    IdAndName<DurationUnitType>
  >(selectorKeys.hiring_stage_duration_units)
  const durationUnitOptions = useMemo(
    () =>
      durationUnits.map(item => ({
        label: item.name,
        value: item,
      })),
    [durationUnits],
  )
  const getSteps = useCallback(
    (firstStep: ScheduleSidebarModeType = 'scheduling') => {
      const res: ScheduleSidebarModeType[] = [firstStep]

      if (firstStep === 'view') {
        return res
      }

      if (values.is_candidate_involved) {
        res.push('email')
      }

      res.push('preview', 'view')

      return res
    },
    [values.is_candidate_involved],
  )

  const defaultDateFilterOption =
    DateFilterOptions.find(item => item.id === DEFAULT_WEEKS_COUNT) ||
    DateFilterOptions[0]

  const firstStep = initialMode || 'scheduling'
  const initialTab =
    type === 'calendar' ? Tabs.Manual : useDynamicSlots ? Tabs.Dynamic : Tabs.Automatic
  const [steps, setSteps] = useState<ScheduleSidebarModeType[]>(getSteps(initialMode))
  const [mode, setModeState] = useState<ScheduleSidebarModeType>(firstStep)
  const [visitedSteps, setVisitedSteps] = useState([firstStep])
  const [tab, setTab] = useState(initialTab)
  const [selectedSlots, setSelectedSlots] = useState<AvailableInterviewerSlot[]>([])
  const [dateFilter, setDateFilter] = useState<OptionInterface | undefined>(
    defaultDateFilterOption,
  )
  const [interviewersFilter, setInterviewersFilter] = useState<OptionInterface[]>()
  const [interviewerGroupsFilter, setInterviewerGroupsFilter] = useState<IdAndName[]>()
  const [customSlot, setCustomSlot] = useState<string>()
  const [editSuccess, setEditSuccess] = useState<boolean | null>(null)
  const [editLoading, setEditLoading] = useState(false)

  const {
    data: interview,
    refetch: refetchInterview,
    isLoading,
  } = useGetSchedulingInterview(
    values.interview_stage?.id,
    roundId,
    isPrepCall,
    interviewId,
  )

  const pendingSchedulingStages = getPendingSchedulingStages(stages)
  const timeZoneId = getTimeZoneId(values.scheduling_timezone)
  const interviewScheduled = interview?.id

  const getSchedulingType = () => {
    switch (tab) {
      case Tabs.Dynamic:
        return SchedulingType.dynamic

      case Tabs.Automatic:
        return SchedulingType.classic

      case Tabs.Custom:
      case Tabs.Manual:
        return SchedulingType.custom

      default:
        return notReachable(tab)
    }
  }
  const schedulingType = getSchedulingType()

  const {
    data: dynamicSlotsCountData,
    isLoading: isDynamicSlotsCountDataLoading,
    isError: isDynamicSlotsCountDataError,
  } = useFetchDynamicSlotsCount(
    {
      stageId: values.interview_stage?.id,
      roundId,
      interviewers: interviewersFilter,
      interviewerGroups: interviewerGroupsFilter,
      duration: values.duration,
      durationUnit: values.duration_unit?.id,
      isAdhoc: isPrepCall,
      daysCount: values.days_to_suggest_slots_for,
    },
    schedulingType === SchedulingType.dynamic,
  )

  const reset = () => {
    setModeState(firstStep)
    setVisitedSteps([firstStep])
    setTab(initialTab)
    setSelectedSlots([])
    setDateFilter(defaultDateFilterOption)
    setInterviewersFilter([])
    setInterviewerGroupsFilter([])
    setCustomSlot(undefined)
    setEditSuccess(null)
    setEditLoading(false)
    const initialStage = getInitialStage()
    if (initialStage) {
      values.interview_stage = initialStage
    }
    setInterviewStage(values.interview_stage)
    values.is_candidate_involved = true
    values.additional_interviewers = []
    values.days_to_suggest_slots_for = defaultDateFilterOption.id * DAYS_PER_WEEK
  }

  const setMode = (newMode: ScheduleSidebarModeType) => {
    setModeState(newMode)

    if (visitedSteps[visitedSteps.length - 1] !== newMode) {
      setVisitedSteps(prev => [...prev, newMode])
    }
  }

  const getInitialStage = () => {
    if (isPrepCall) {
      return getSelectedStage(stages, selectedStageId)
    }

    switch (initialMode) {
      case 'scheduling': {
        return getSelectedStage(pendingSchedulingStages, selectedStageId)
      }
      default:
        return getSelectedStage(stages, selectedStageId)
    }
  }

  const loadCustomDate = () => {
    if (
      (!values.custom_date?.day || !values.custom_date?.time) &&
      interview?.event_date_time &&
      interview?.scheduling_timezone.id
    ) {
      values.custom_date = dateToCustomDate(
        interview.event_date_time,
        interview?.scheduling_timezone.id,
      )
      values.duration = interview.duration
      values.duration_unit = durationUnits.find(
        option => option.id === interview.duration_unit,
      )
    }
  }

  const moveNextStep = () => {
    const currentIdx = steps.findIndex(item => item === mode)
    setMode(steps[currentIdx + 1] || steps[steps.length - 1])
  }

  const movePrevStep = () => {
    const currentIdx = steps.findIndex(item => item === mode)
    setMode(steps[currentIdx - 1] || steps[0])
  }

  const changeStage = (interviewStage?: InterviewStageWithoutRoundInterface) => {
    if (interviewStage) {
      values.interview_stage = interviewStage
      values.duration = interviewStage.duration
      values.duration_unit = durationUnits.find(
        option => option.id === interviewStage.duration_unit,
      )
    }
  }

  useEffect(() => {
    if (values.duration) {
      setDuration(values.duration)
    }
  }, [values.duration])

  useEffect(() => {
    if (values.duration_unit) {
      setDurationUnit(values.duration_unit)
    }
  }, [values.duration_unit])

  useEffect(() => {
    if (values.interview_stage) {
      setInterviewStage({ ...values.interview_stage })
    }
  }, [values.interview_stage])

  const onSchedule = () => {
    if (!isPrepCall) {
      switch (tab) {
        case Tabs.Automatic:
          sendAnalyticsEvent(AnalyticsEvents.schedule_interview_auto)
          break

        case Tabs.Custom:
          sendAnalyticsEvent(AnalyticsEvents.schedule_interview_custom)
          break

        case Tabs.Manual:
          sendAnalyticsEvent(AnalyticsEvents.schedule_interview_manual)
          break

        case Tabs.Dynamic:
          sendAnalyticsEvent(AnalyticsEvents.schedule_interview_dynamic)
          break

        default:
          notReachable(tab)
      }
    } else {
      switch (tab) {
        case Tabs.Automatic:
          sendAnalyticsEvent(AnalyticsEvents.schedule_prep_call_auto)
          break

        case Tabs.Custom:
          sendAnalyticsEvent(AnalyticsEvents.schedule_prep_call_custom)
          break

        case Tabs.Manual:
          sendAnalyticsEvent(AnalyticsEvents.schedule_prep_call_manual)
          break

        case Tabs.Dynamic:
          sendAnalyticsEvent(AnalyticsEvents.schedule_prep_call_dynamic)
          break

        default:
          notReachable(tab)
      }
    }
  }

  useEffect(() => {
    changeStage(getInitialStage())
  }, [selectedStageId, stages])

  useEffect(() => {
    setMode(initialMode || 'scheduling')
  }, [initialMode, selectedStageId])

  useEffect(() => {
    setDisabledCalendar(mode === 'preview')
  }, [mode])

  useEffect(() => {
    if (!unitsFetched) {
      return
    }

    values.duration_unit = durationUnits.find(
      option => option.id === values.interview_stage?.duration_unit,
    )
  }, [unitsFetched])

  useEffect(() => {
    if (!values.scheduling_timezone && interview?.scheduling_timezone) {
      values.scheduling_timezone = interview.scheduling_timezone
    }
  }, [interview?.scheduling_timezone])

  // TODO: refactor to get rid of the form state https://revolut.atlassian.net/browse/PERF-5777
  useEffect(() => {
    if (type !== 'calendar') {
      return
    }

    const calendarEventStartDate = calendarEvent?.start

    if (calendarEventStartDate) {
      values.custom_date = getNewCustomDateValue(
        dateToCustomDate(calendarEventStartDate, null),
        null,
      )
    } else {
      delete values.custom_date
    }
  }, [calendarEvent, type])

  useEffect(() => {
    setSteps(getSteps(initialMode))
  }, [initialMode, values.is_candidate_involved])

  useEffect(() => {
    reset()
  }, [stages])

  const tabs = useMemo(
    () => [useDynamicSlots ? Tabs.Dynamic : Tabs.Automatic, Tabs.Custom, Tabs.Manual],
    [useDynamicSlots],
  )

  const renderContent = () => {
    if (isLoading) {
      return <Loader />
    }

    if (!roundId || !stages.length || !values.interview_stage) {
      return null
    }

    if (interviewScheduled && mode === 'view') {
      return (
        <ScheduledInterview
          candidate={candidate}
          roundId={roundId}
          interview={interview}
          onReschedule={() => {
            onChangeInitialMode?.('rescheduling')
          }}
          onCancelInterview={async () => {
            sendAnalyticsEvent(AnalyticsEvents.cancel_interview)
            await refetchInterview()
            await onRefresh?.()
          }}
          onEdit={() => {
            setMode('editing')
            setTab(Tabs.Manual)
            loadCustomDate()

            if (interview?.interviewer) {
              values.interviewer = { ...interview.interviewer }
            }

            if (interview?.additional_interviewers?.length) {
              values.additional_interviewers = [...interview.additional_interviewers]
            }
          }}
          isPrepCall={isPrepCall}
          readonly={readonly}
        />
      )
    }

    if (mode === 'email') {
      return (
        <EmailScheduleSidebar
          onBack={() => {
            movePrevStep()
          }}
          roundId={roundId}
          onSubmit={() => {
            moveNextStep()
          }}
          schedulingType={schedulingType}
          isPrepCall={isPrepCall}
          noInitialFetching={visitedSteps.includes('preview')}
        />
      )
    }

    if (mode === 'preview') {
      return (
        <PreviewScheduleSidebar
          selectedSlots={selectedSlots}
          onBack={() => {
            movePrevStep()
          }}
          onEditDetails={() => {
            setMode(steps[0])
          }}
          onEditEmail={() => {
            setMode('email')
          }}
          roundId={roundId}
          onSubmit={() => {
            moveNextStep()
            onChangeInitialMode?.('view')
            onSchedule()
            refetchInterview()
            onSchedulingSuccess?.(values.interview_stage!)
            onRefresh?.()
          }}
          schedulingType={schedulingType}
          isPrepCall={isPrepCall}
        />
      )
    }

    let isSubmitEnabled = false
    switch (tab) {
      case Tabs.Automatic:
        isSubmitEnabled = !!selectedSlots.length
        break
      case Tabs.Custom:
      case Tabs.Manual:
        isSubmitEnabled = !!(
          !editLoading &&
          values.interviewer &&
          values.custom_date?.day &&
          values.custom_date?.time &&
          !values.custom_date?.timeError
        )
        break

      case Tabs.Dynamic:
        isSubmitEnabled =
          !isDynamicSlotsCountDataLoading &&
          !isDynamicSlotsCountDataError &&
          !!dynamicSlotsCountData?.count
        break

      default:
        notReachable(tab)
    }

    return (
      <EditScheduleSidebar
        stages={isPrepCall ? stages : pendingSchedulingStages}
        roundId={roundId}
        onSubmit={async () => {
          if (mode === 'editing') {
            if (
              values.interview_stage?.id &&
              roundId &&
              interview?.id &&
              values.custom_date
            ) {
              const eventDateTime = customDateToDate(
                values.custom_date,
                timeZoneId,
              ).toISOString()
              try {
                setEditLoading(true)
                await editInterview(
                  values.interview_stage.id,
                  roundId,
                  interview.id,
                  {
                    interviewer: values.interviewer || undefined,
                    additional_interviewers: values.additional_interviewers,
                    event_date_time: eventDateTime,
                    scheduling_timezone: values.scheduling_timezone,
                    duration: values.duration,
                    duration_unit: values.duration_unit,
                  },
                  apiHandler,
                )
                sendAnalyticsEvent(AnalyticsEvents.edit_scheduled_interview)
                setEditSuccess(true)
              } catch (e) {
                setEditSuccess(false)
              } finally {
                setEditLoading(false)
                refetchInterview()
                onRefresh?.()
                moveNextStep()
              }
            }
          } else {
            moveNextStep()
          }
        }}
        totalStagesCount={totalStagesCount}
        isSubmitEnabled={isSubmitEnabled}
        isRescheduling={mode === 'rescheduling'}
        isEditing={mode === 'editing'}
        isPrepCall={isPrepCall}
        isStageDisabled={isStageDisabled}
        loading={editLoading}
        onStageChange={changeStage}
      >
        <VStack gap="s-16">
          <InputGroup variant="horizontal">
            <LapeNewInput
              name="duration"
              label="Duration"
              width="50%"
              type="number"
              onChange={e => {
                values.duration = +e.currentTarget.value
              }}
              required
            />
            <LapeRadioSelectInput<IdAndName<string>>
              label="Unit"
              labelPath="name"
              name="duration_unit"
              options={durationUnitOptions}
            />
          </InputGroup>
          {mode !== 'editing' && (
            <>
              <CalendarSelect roundId={roundId} />
              {type === 'default' && (
                <Box>
                  <Subheader variant="nested">
                    <Subheader.Title>Select scheduling method</Subheader.Title>
                  </Subheader>

                  <TabBar variant="segmented">
                    {tabs.map(elm => (
                      <TabBar.Item
                        key={elm}
                        aria-selected={tab === elm}
                        onClick={() => {
                          setTab(elm)
                          if (elm === Tabs.Manual) {
                            loadCustomDate()
                          }
                          if (elm === Tabs.Custom) {
                            values.custom_date = {}
                          }
                        }}
                      >
                        <Text use="div" px="s-20">
                          {elm}
                        </Text>
                      </TabBar.Item>
                    ))}
                  </TabBar>
                </Box>
              )}
            </>
          )}
          {Tabs.Automatic === tab && type === 'default' && (
            <EditSlotsDate
              onChangeSlots={setSelectedSlots}
              roundId={roundId}
              setDateFilter={setDateFilter}
              interviewersFilter={interviewersFilter}
              interviewerGroupsFilter={interviewerGroupsFilter}
              setInterviewersFilter={setInterviewersFilter}
              setInterviewerGroupsFilter={setInterviewerGroupsFilter}
              selectedSlots={selectedSlots}
              dateFilter={dateFilter}
              onClose={() => setTab(Tabs.Manual)}
              isPrepCall={!!isPrepCall}
            />
          )}
          {Tabs.Dynamic === tab && type === 'default' && (
            <DynamicSlotsMode
              roundId={roundId}
              setDateFilter={value => {
                setDateFilter(value)
                values.days_to_suggest_slots_for =
                  value?.id !== undefined ? Number(value.id) * DAYS_PER_WEEK : undefined
              }}
              interviewersFilter={interviewersFilter}
              interviewerGroupsFilter={interviewerGroupsFilter}
              setInterviewersFilter={value => {
                setInterviewersFilter(value)
                // we should create a shallow copy as we assign by a link and could mutate it later
                values.eligible_interviewers = value ? [...value] : []
              }}
              setInterviewerGroupsFilter={value => {
                setInterviewerGroupsFilter(value)
                // we should create a shallow copy as we assign by a link and could mutate it later
                values.eligible_interviewer_groups = value ? [...value] : []
              }}
              dateFilter={dateFilter}
              isPrepCall={!!isPrepCall}
            />
          )}
          {Tabs.Custom === tab && (
            <EditCustomDate
              roundId={roundId}
              customSlot={customSlot}
              onChangeCustomSlot={setCustomSlot}
              onClose={() => setTab(Tabs.Manual)}
            />
          )}

          {Tabs.Manual === tab && (
            <EditManualDate mode={mode} roundId={roundId} type={type} />
          )}
        </VStack>
      </EditScheduleSidebar>
    )
  }

  return (
    <>
      {renderContent()}

      {editSuccess !== null && (
        <>
          <StatusPopup
            variant="error"
            open={editSuccess === false}
            onClose={() => setEditSuccess(null)}
            // @ts-ignore
            labelButtonClose="Close error popup"
          >
            <StatusPopup.Title>Something went wrong</StatusPopup.Title>
            <StatusPopup.Description>
              Please refresh the page and try again
            </StatusPopup.Description>
          </StatusPopup>
          <StatusPopup
            variant="success"
            open={editSuccess}
            onClose={() => setEditSuccess(null)}
            // @ts-ignore
            labelButtonClose="Close success popup"
          >
            <StatusPopup.Title>Interview was edited successfully</StatusPopup.Title>
          </StatusPopup>
        </>
      )}
    </>
  )
}

export default (props: ScheduleSidebarProps) => {
  const form = (
    <LapeForm onSubmit={() => Promise.resolve({})}>
      <ScheduleSidebar {...props} />
    </LapeForm>
  )

  return props.noSidebar ? (
    form
  ) : (
    <SideBar useLayout customHeader={null} isOpen onClose={props.onClose} variant="wide">
      {form}
    </SideBar>
  )
}
