import React, { useEffect } from 'react'
import {
  InputGroup,
  Box,
  Group,
  Item,
  Avatar,
  Input,
  ActionButton,
  Token,
} from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BenefitsInterface } from '@src/interfaces/benefits'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { onPackageDetailsClick, PackageDetailItem } from './common'
import FileUploader from '@src/components/Inputs/FileUploader/FileUploader'
import UploadedFile from '@src/components/Inputs/FileUploader/UploadedFile'
import { FileGroup } from '@src/components/Inputs/FileUploader/FileGroup'
import { LapeYesOrNoRadioSelect } from '@components/Inputs/LapeFields/LapeYesOrNoRadioSelect'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

const General = () => {
  const { values } = useLapeContext<BenefitsInterface>()

  const params = useParams<{ id?: string }>()

  useEffect(() => {
    if (!values.id && values.packages == null) {
      values.packages = []
    }
    if (!values.id && values.enrolment_documents == null) {
      values.enrolment_documents = []
    }
    if (!values.files) {
      values.files = []
    }
  }, [])

  const packagesAdded = values.packages?.length > 0
  const addPackageDisabled = values.contribution_method == null
  const isFixedAmount = values.contribution_method?.id === 'fixed_amount'
  const isPercentOfQualifyingEarnings =
    values.contribution_method?.id === 'percentage_of_qualifying_earnings'
  const isPercentOfSalary = values.contribution_method?.id === 'percentage_of_salary'

  return (
    <>
      <PageHeader
        backUrl={
          params.id
            ? pathToUrl(ROUTES.FORMS.BENEFITS.PREVIEW, params)
            : ROUTES.APPS.BENEFITS.TEMPLATES
        }
        title={params.id ? 'Edit benefit' : 'New benefit'}
      />

      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General" />
          <InputGroup>
            <LapeNewInput label="Name" name="name" required />
            <LapeRadioSelectInput
              label="Category"
              name="category"
              selector={selectorKeys.benefits_template_categories}
            />
            <LapeNewInput
              label="Provider URL"
              name="provider_url"
              message="Please insert the link to the benefit provider website or benefit management platform"
            />
            <LapeNewInput
              label="Benefit guide"
              name="confluence_url"
              message="Please insert the link to the Confluence page dedicated to this benefit"
            />
            <LapeRadioSelectInput
              label="Owner"
              name="owner"
              selector={selectorKeys.employee}
            />
            <LapeNewTextArea
              label="Description"
              name="description"
              required
              rows={2}
              message="Please provide a short description of the benefit"
            />
          </InputGroup>

          <NewStepperTitle title="Enrolment" />
          <InputGroup>
            <LapeNewMultiSelect
              placeholder="Eligibility groups"
              name="eligibility_groups"
              selector={selectorKeys.dynamic_groups}
              required
            />
            <InputGroup variant="horizontal">
              <Box flex={3}>
                <LapeRadioSelectInput
                  label="Cut-off period"
                  name="cut_off_period"
                  selector={selectorKeys.benefits_template_cut_off_periods}
                  clearable
                  optional
                />
              </Box>
              {values.cut_off_period?.id === 'monthly' ? (
                <Box flex={1}>
                  <LapeNewInput
                    label="Day of month"
                    name="cut_off_period_day_of_month"
                    required
                  />
                </Box>
              ) : null}
            </InputGroup>
            <LapeYesOrNoRadioSelect
              name="employee_can_opt_out"
              label="Employee can opt out"
            />
            <LapeYesOrNoRadioSelect
              name="enrolment_documents_required"
              label="Enrolment documents required"
              message="Specify if any documents are required from employee to successfully enrol to the benefit."
            />
            {values.enrolment_documents_required ? (
              <>
                <FileUploader
                  value={values.files}
                  onChange={data => {
                    if (Array.isArray(data)) {
                      values.files = data
                    }
                    if (data == null) {
                      values.files = []
                    }
                  }}
                  multiple
                />
                {values.enrolment_documents?.length > 0 ? (
                  <FileGroup>
                    {values.enrolment_documents.map(document => (
                      <UploadedFile
                        title={document.name}
                        onDelete={() => {
                          values.enrolment_documents = values.enrolment_documents.filter(
                            doc => doc.id !== document.id,
                          )
                        }}
                        size={document.size}
                        key={document.id}
                      />
                    ))}
                  </FileGroup>
                ) : null}
              </>
            ) : null}
          </InputGroup>

          <NewStepperTitle title="Contributions" />
          <InputGroup>
            <LapeRadioSelectInput
              label="Contribution method"
              name="contribution_method"
              selector={selectorKeys.benefits_template_contribution_methods}
              message="Contribution method influences the package configuration. Once packages are added, you won't be able to change this field."
              disabled={packagesAdded}
            />
            {isFixedAmount ? (
              <LapeRadioSelectInput
                label="Contribution currency"
                name="contribution_currency"
                selector={selectorKeys.currencies}
              />
            ) : null}
            {isPercentOfSalary || isPercentOfQualifyingEarnings ? (
              <LapeYesOrNoRadioSelect
                name="employee_can_edit_contribution"
                label="Employee can edit contribution"
                disabled={values.packages?.length > 0}
              />
            ) : null}
            <LapeRadioSelectInput
              label="Contribution frequency"
              name="contribution_frequency"
              selector={selectorKeys.benefits_template_contribution_frequencies}
            />
            {isFixedAmount ? (
              <InputGroup variant="horizontal">
                <Box flex={3}>
                  <LapeNewInput
                    label="Notional value of the benefit"
                    name="notional_value_of_benefit"
                  />
                </Box>
                <Box flex={1}>
                  <Input
                    label="Currency"
                    value={values.contribution_currency?.iso_code || ''}
                    disabled
                  />
                </Box>
              </InputGroup>
            ) : null}
          </InputGroup>

          <NewStepperTitle
            title="Packages"
            subtitle="At least one package needs to be added. In package settings you will be able to define the contribution amounts and allowance of adding dependants for the benefit."
          />
          <Group>
            {values.packages?.map((benefitPackage, index) => (
              <PackageDetailItem
                onClick={() =>
                  onPackageDetailsClick({
                    packageId: benefitPackage.id,
                    index,
                    id: values.id,
                  })
                }
                benefitPackage={benefitPackage}
                benefit={values}
                key={index}
              />
            ))}
            {values.packages?.length === 0 ? (
              <Item>
                <Item.Avatar>
                  <Avatar color={Token.color.greyTone20} useIcon="Document" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title color={Token.color.greyTone50}>
                    No packages added
                  </Item.Title>
                </Item.Content>
              </Item>
            ) : null}
            <Item>
              <Item.Content>
                <ActionButton
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.BENEFITS.PACKAGE_NEW, { id: values.id }),
                    )
                  }
                  useIcon="Plus"
                  disabled={addPackageDisabled}
                >
                  Add package
                </ActionButton>
              </Item.Content>
            </Item>
          </Group>

          <NewStepperTitle title="Instructions" />
          <LapeHTMLEditor
            name="instructions"
            placeholder="Instructions"
            height={500}
            required
            nonResizable
          />
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Benefit successfully saved"
          previewUrl={ROUTES.FORMS.BENEFITS.PREVIEW}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default General
