import { useEffect, useState } from 'react'

export enum AvailabilityStatus {
  Unknown = 'Unknown',
  Installed = 'Installed',
}

interface Props {
  status: AvailabilityStatus
  isChecking: boolean
}

const INTERNAL_EXTENSION_ID = 'epeiijalnefjildjmbmmoggkgijbmdkk'

// this is awful, but currently there's no other way really to check if extension installed that works
const detect = (extensionId: string, onLoad: VoidFunction, onError: VoidFunction) => {
  let s = document.createElement('script')
  s.onerror = onError
  s.onload = onLoad
  document.body.appendChild(s)
  s.src = `chrome-extension://${extensionId}/manifest.json`
  s.remove()
}

export const useExtensionAvailability = (): Props => {
  const [status, setStatus] = useState<AvailabilityStatus>(AvailabilityStatus.Unknown)
  const [isChecking, setIsChecking] = useState(true)

  useEffect(() => {
    detect(
      INTERNAL_EXTENSION_ID,
      () => {
        setStatus(AvailabilityStatus.Installed)
        setIsChecking(false)
      },
      () => {
        setStatus(AvailabilityStatus.Unknown)
        setIsChecking(false)
      },
    )
  }, [])

  return {
    status,
    isChecking,
  }
}
