import React, { useState } from 'react'
import pluralize from 'pluralize'
import { selectorKeys } from '../api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import { ImportTimeOffBalancesInterface } from '@src/interfaces/importTimeOffBalances'
import {
  ChildTempPolicyAssignmentsEmployeeInterface,
  OnboardingTempPolicyAssignmentsEmployeeInterface,
  TempPolicyAssignmentsPolicyInterface,
  TimeOffPolicyTableInterface,
} from '@src/interfaces/timeOff'
import {
  CellWithError,
  GenericEditableTableColumn,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import EditableCell from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import Table from '@components/TableV2/Table'

type OnboardingDynamicGroupColumn = GenericEditableTableColumn<DynamicGroupInerface>
type OnboardingImportBalancesColumn =
  GenericEditableTableColumn<ImportTimeOffBalancesInterface>
type OnboardingPolicyColumn = GenericEditableTableColumn<TimeOffPolicyTableInterface>

type OnboardingTempPolicyAssignmentColumn = (
  onChange: (
    data: ChildTempPolicyAssignmentsEmployeeInterface,
    field: keyof TempPolicyAssignmentsPolicyInterface,
    value: number,
  ) => void,
) => ColumnInterface<OnboardingTempPolicyAssignmentsEmployeeInterface>

interface ProjectedBalanceInputCellProps {
  data: ChildTempPolicyAssignmentsEmployeeInterface
  onChange: (
    data: ChildTempPolicyAssignmentsEmployeeInterface,
    field: keyof TempPolicyAssignmentsPolicyInterface,
    value: number,
  ) => void
  suffix: string
}

const ProjectedBalanceInputCell = ({
  data,
  onChange,
  suffix,
}: ProjectedBalanceInputCellProps) => {
  const [value, setValue] = useState(
    data.data.projected_balance_amount != null ? data.data.projected_balance_amount : '',
  )
  return (
    <CellWithError data={data} field="projected_balance_amount">
      <EditableCell
        hidePencil
        onBlur={val => onChange(data, 'projected_balance_amount', Number(val))}
        onChange={val => setValue(val)}
        suffix={suffix}
        type={TableCellInputType.int}
        value={value}
      />
    </CellWithError>
  )
}

export const onboardingTimeManagementPolicyNameColumn: OnboardingPolicyColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.active_time_off_policies,
    title: 'Name',
    insert: ({ data }) => <TextCell data={data} field="name" onChange={onChange} />,
  })

export const onboardingTimeManagementPolicyCategoryColumn: OnboardingPolicyColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'category.id',
    dataPoint: 'category.name',
    sortKey: 'category__name',
    filterKey: 'category__id',
    selectorsKey: selectorKeys.time_off_policy_categories,
    title: 'Category',
    insert: ({ data }) => {
      return (
        <RadioSelectInputCell
          data={data}
          field="category"
          selector={selectorKeys.time_off_policy_categories}
          onChange={onChange}
        />
      )
    },
  })

export const onboardingTimeManagementPolicyGroupColumn: OnboardingPolicyColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'group.id',
    dataPoint: 'group.name',
    sortKey: 'group__name',
    filterKey: 'group__id',
    selectorsKey: selectorKeys.dynamic_groups,
    title: 'Group',
    insert: ({ data }) => {
      return (
        <RadioSelectInputCell
          data={data}
          field="group"
          selector={selectorKeys.dynamic_groups}
          onChange={onChange}
        />
      )
    },
  })

export const onboardingTimeManagementPolicyBalanceColumn: OnboardingPolicyColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'accrual_amount',
    dataPoint: 'accrual_amount',
    sortKey: 'balance_type',
    filterKey: 'balance_type',
    selectorsKey: selectorKeys.time_off_balance_types,
    title: 'Balance',
    insert: ({ data }) => {
      const isUnlimited = data.data.balance_type.id === 'unlimited'

      if (isUnlimited) {
        return <>{data.data.balance_type.name}</>
      }

      const suffix =
        data.data.unit?.name && data.data.accrual_amount != null
          ? ` ${pluralize(data.data.unit.name.toLowerCase(), data.data.accrual_amount)}`
          : ''

      return (
        <TextCell
          data={data}
          field="accrual_amount"
          onChange={onChange}
          suffix={suffix}
        />
      )
    },
  })

export const onboardingTimeManagementDynamicGroupsGroupNameColumn: OnboardingDynamicGroupColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.dynamic_groups,
    title: 'Eligibility Group',
    insert: ({ data }) => {
      const isInternal = data.data.type.id === 'internal'

      if (isInternal) {
        return <>{data.data.name}</>
      }

      return <TextCell data={data} field="name" onChange={onChange} />
    },
  })

export const onboardingTimeManagementDynamicGroupsEmployeesCountColumn: OnboardingDynamicGroupColumn =
  () => ({
    title: 'Employees',
    type: CellTypes.insert,
    idPoint: 'member_count',
    dataPoint: '',
    sortKey: 'member_count',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    insert: ({ data }) => pluralize('employee', data.data.member_count, true),
  })

export const onboardingTimeManagementDynamicGroupsActionsColumn: OnboardingDynamicGroupColumn =
  () => ({
    type: CellTypes.insert,
    idPoint: 'actions',
    dataPoint: 'actions',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  })

export const onboardingTimeManagementTempPolicyAssignmentsEmployeeColumn: ColumnInterface<OnboardingTempPolicyAssignmentsEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'full_name',
    sortKey: null,
    filterKey: 'id',
    selectorsKey: selectorKeys.employee,
    title: 'Employee',
    insert: ({ data }) => {
      if ('children' in data) {
        return <Table.EmployeeCell employee={data} size={40} />
      }
      return data.data.policy.name || ''
    },
  }

export const onboardingTimeManagementTempPolicyAssignmentsLocationColumn: ColumnInterface<OnboardingTempPolicyAssignmentsEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'location.id',
    dataPoint: 'location.location_name',
    sortKey: null,
    filterKey: 'location__id',
    selectorsKey: selectorKeys.location,
    title: 'Location',
    insert: ({ data }) => {
      if ('children' in data) {
        return data.location?.name || ''
      }
      return ''
    },
  }

export const onboardingTimeManagementTempPolicyAssignmentsActionColumn: ColumnInterface<OnboardingTempPolicyAssignmentsEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'action',
    dataPoint: 'action',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }

export const onboardingTimeManagementTempPolicyAssignmentsProjectedBalanceColumn: OnboardingTempPolicyAssignmentColumn =
  onEditAction => ({
    type: CellTypes.insert,
    idPoint: 'projected_balance_amount',
    dataPoint: 'projected_balance_amount',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Projected Balance',
    insert: ({ data }) => {
      if ('children' in data) {
        return ''
      }

      const isUnlimited = data.data.policy.balance_type.id === 'unlimited'

      if (isUnlimited) {
        return data.data.policy.balance_type.name
      }

      const suffix =
        data.data.policy.unit?.name && data.data.projected_balance_amount != null
          ? ` ${pluralize(
              data.data.policy.unit.name.toLowerCase(),
              Number(data.data.projected_balance_amount),
            )}`
          : ''

      return (
        <ProjectedBalanceInputCell data={data} onChange={onEditAction} suffix={suffix} />
      )
    },
  })

export const onboardingTimeManagementImportBalanceEmployeeColumn: OnboardingImportBalancesColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'email',
    dataPoint: 'email',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee',
    insert: ({ data }) => <TextCell data={data} field="email" onChange={onChange} />,
  })

export const onboardingTimeManagementImportBalancePolicyColumn: OnboardingImportBalancesColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'policy',
    dataPoint: 'policy',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.active_time_off_policies,
    title: 'Policy',
    insert: ({ data }) => {
      return (
        <RadioSelectInputCell
          data={data}
          field="policy"
          selector={selectorKeys.active_time_off_policies}
          selectorField="name"
          onChange={onChange}
        />
      )
    },
  })

export const onboardingTimeManagementImportSetBalanceColumn: OnboardingImportBalancesColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'set_balance',
    dataPoint: 'set_balance',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Set balance',
    insert: ({ data }) => (
      <TextCell
        data={data}
        field="set_balance"
        onChange={onChange}
        type={TableCellInputType.int}
      />
    ),
  })

export const onboardingTimeManagementImportAdjustBalanceColumn: OnboardingImportBalancesColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'adjust_balance',
    dataPoint: 'adjust_balance',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Adjust balance',
    insert: ({ data }) => (
      <TextCell
        data={data}
        field="adjust_balance"
        onChange={onChange}
        type={TableCellInputType.int}
      />
    ),
  })
