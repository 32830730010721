import React from 'react'
import { Text, TextButton, Token } from '@revolut/ui-kit'
import { connect } from 'lape'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { EditGrowthPlanFormContent } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/EditGrowthPlanFormContent'
import { PageActions } from '@components/Page/PageActions'
import { PageWrapper } from '@components/Page/Page'
import { UserAvatar } from '@components/UserWithAvatar/UserAvatar'
import { Summary as PerformanceSummary } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Summary'
import { useGetEmployeeLatestPublishedReviewCycle } from '@src/api/reviewCycles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { employeeModel } from '@src/features/GrowthPlans/GrowthPlan/models/employeeModel'
import { InternalLink } from '@components/InternalLink/InternalLink'

interface Props {
  title: string
  backUrl: string
  employee: EmployeeInterface
  actions: React.ReactNode
}

export const EditGrowthPlanLayout = connect(
  ({ title, employee, backUrl, actions }: Props) => {
    const { values } = useLapeContext<GrowthPlanInterface>()
    const { data: reviewCycle, isLoading: isReviewCycleLoading } =
      useGetEmployeeLatestPublishedReviewCycle(employee.id)

    const seniorityName =
      employeeModel.getCurrentSenioritySubLevelName(employee) ||
      employeeModel.getCurrentSeniorityName(employee)
    const specialisation = employeeModel.getCurrentSpecialisation(employee)

    return (
      <PageWrapper maxWidth={Token.breakpoint.xl}>
        <PageHeader
          title={
            <PageHeader.Title
              title={
                <TextButton
                  color={Token.color.foreground}
                  use={InternalLink}
                  target="_blank"
                  to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: employee.id })}
                >
                  {employee.full_name}
                </TextButton>
              }
              avatar={
                <UserAvatar
                  id={employee.id}
                  avatar={employee.avatar}
                  full_name={employee.full_name}
                  size={56}
                />
              }
              labels={
                <PageHeader.LabelsBar>
                  {specialisation && (
                    <PageHeader.Label
                      icon="RepairTool"
                      to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                        id: specialisation.id,
                      })}
                    >
                      {specialisation.name}
                    </PageHeader.Label>
                  )}
                  {seniorityName && (
                    <PageHeader.Label icon="People">{seniorityName}</PageHeader.Label>
                  )}
                </PageHeader.LabelsBar>
              }
            />
          }
          renderAboveTitle={() => <Text variant="heading2">{title}</Text>}
          backUrl={backUrl}
        />
        <TwoColumnsLayout
          left={
            <>
              <EditGrowthPlanFormContent
                employee={employee}
                reviewCycle={reviewCycle ?? null}
              />
              <PageActions>{actions}</PageActions>
            </>
          }
          right={
            <PerformanceSummary
              employee={employee}
              growthPlan={values}
              reviewCycle={reviewCycle ?? null}
              isLoading={isReviewCycleLoading}
            />
          }
          leftFlex={1.25}
        />
      </PageWrapper>
    )
  },
)
