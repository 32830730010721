import React from 'react'
import {
  DaySlotsGroup,
  DaySlotGroupInterface,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/DaySlotsGroup/DaySlotsGroup'
import { AvailableInterviewerSlot } from '@src/interfaces/interviewTool'

type Props = {
  slots: DaySlotGroupInterface<AvailableInterviewerSlot>[]
  onChange: (value: string[]) => void
  selectedSlotIds: string[]
  timeZone: string
  disabled?: boolean
}

const InterviewDateSlots = ({
  slots,
  selectedSlotIds,
  onChange,
  timeZone,
  disabled,
}: Props) => {
  return (
    <>
      {slots.map(day => (
        <DaySlotsGroup
          key={day.label}
          day={day}
          selectedSlotIds={selectedSlotIds}
          isDayChecked={day.items.every(item => selectedSlotIds.includes(item.id))}
          onChange={onChange}
          timeZone={timeZone}
          disabled={disabled}
        />
      ))}
    </>
  )
}

export default InterviewDateSlots
