import React from 'react'
import {
  Item,
  ActionButton,
  Avatar,
  StatusPopup,
  Button,
  useStatusPopup,
} from '@revolut/ui-kit'
import { MergeConnectionInterface } from '@src/interfaces/integrations'
import {
  CreateEmployeeUploadSource,
  createEmployeeUpload,
  useEmployeeUploadResync,
} from '@src/api/bulkDataImport'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { formatDateTime } from '@src/utils/format'

interface NotSyncedBannerProps {
  integration: MergeConnectionInterface
}

export const SyncBanner = ({ integration }: NotSyncedBannerProps) => {
  const statusPopup = useStatusPopup()
  const {
    mutateAsync: resync,
    isLoading: resyncLoading,
    isSuccess: resyncStarted,
  } = useEmployeeUploadResync()

  const lastSyncState = integration?.last_sync?.state
  const isCompleted = lastSyncState === 'success' || lastSyncState === 'failure'
  const isPending = (lastSyncState && !isCompleted) || resyncStarted

  const createEmployeeUploadFromWorkspace = (type: CreateEmployeeUploadSource) => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Importing data</StatusPopup.Title>
      </StatusPopup>,
    )

    createEmployeeUpload(type)
      .then(response => {
        statusPopup.hide()
        navigateTo(
          pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION, {
            id: response.data.id,
          }),
        )
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to import data</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary" elevated>
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
  }

  const handleResync = async (type: CreateEmployeeUploadSource) => {
    try {
      await resync({ source: type })
      statusPopup.show(
        <StatusPopup variant="success" preventUserClose>
          <StatusPopup.Title>Re-sync started</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to re-sync data</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide} variant="secondary" elevated>
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }
  }

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon="ArrowExchange" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          {!integration?.last_sync
            ? 'No employee data has been synced yet'
            : isPending
            ? 'Sync in progress'
            : `Last synced ${formatDateTime(integration.last_sync.update_date_time)}`}
        </Item.Title>
      </Item.Content>

      {isPending && !integration?.last_sync?.auto_apply && !resyncStarted ? (
        <Item.Side>
          <ActionButton
            onClick={() => {
              if (integration.last_sync?.id) {
                navigateTo(
                  pathToUrl(
                    ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION,
                    {
                      id: integration.last_sync.id,
                    },
                  ),
                )
              }
            }}
            disabled={!integration.last_sync?.id}
          >
            View
          </ActionButton>
        </Item.Side>
      ) : null}

      {(!integration?.last_sync || isCompleted) && !resyncStarted ? (
        <Item.Side>
          <ActionButton
            pending={resyncLoading}
            onClick={() =>
              isCompleted
                ? handleResync(integration.integration_type)
                : createEmployeeUploadFromWorkspace(integration.integration_type)
            }
          >
            Sync
          </ActionButton>
        </Item.Side>
      ) : null}
    </Item>
  )
}
