import React from 'react'
import {
  Box,
  chain,
  createChain,
  ErrorWidget,
  Flex,
  Widget,
  Text,
  List,
  Avatar,
  Link,
  Token,
} from '@revolut/ui-kit'
import { ArrowThinRight, Check, InfoOutline, SandWatch } from '@revolut/icons'
import Lock from '@src/assets/3D/Lock@2x.png'

import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import { FinalGrade } from '@src/interfaces/performance'
import { selectorKeys } from '@src/constants/api'
import { SummarySubheader } from '@src/features/Summary/common'
import { useGetPromotionProgression } from '@src/api/promotions'
import Tooltip from '@components/Tooltip/Tooltip'
import { PROGRESSION_POLICY_PLAYBOOK_LINK } from '@src/constants/externalLinks'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { useGetSelectors } from '@src/api/selectors'

interface CommonProps {
  data: EmployeeInterface
}

export const AccessError = () => (
  <ErrorWidget>
    <ErrorWidget.Image src={Lock} />
    <ErrorWidget.Title>You don’t have access to this section</ErrorWidget.Title>
    <ErrorWidget.Description>
      Only the employee’s managers and performance team can view this section
    </ErrorWidget.Description>
  </ErrorWidget>
)

interface ProgressionSectionProps extends CommonProps {
  sticky?: boolean
  renderHeader?: boolean
  withBorder?: boolean
  noError?: boolean
  cycleId?: number | string
}
export const ProgressionSection = ({
  data,
  sticky = false,
  renderHeader = true,
  withBorder = false,
  noError = false,
  cycleId,
}: ProgressionSectionProps) => {
  const { data: progression, error } = useGetPromotionProgression(data.id, cycleId)

  const { data: gradesOptions } = useGetSelectors<{ id: FinalGrade; name: string }>(
    selectorKeys.grades,
  )

  const strongGradeOption = gradesOptions?.find(grade => grade.id === FinalGrade.Strong)
  const isOldGrades: boolean = strongGradeOption?.name === 'Strong'

  const minProgressionGradeLabel = gradesOptions?.find(grade =>
    isOldGrades ? grade.id === FinalGrade.Strong : grade.id === FinalGrade.AveragePlus,
  )?.name
  const gradesChain = createChain(' / ')(
    minProgressionGradeLabel,
    isOldGrades ? undefined : 'Exceeding',
    'Exceptional',
  )

  if (error && noError) {
    return null
  }

  if (error) {
    return (
      <Box mt="s-16">
        <SummarySubheader>Progression</SummarySubheader>
        <AccessError />
      </Box>
    )
  }

  const showProgression =
    progression?.current_seniority &&
    progression?.target_seniority &&
    progression?.eligibility_criteria &&
    progression?.eligibility_criteria_result

  const canViewProgression =
    data.status.id === IdStatuses.active &&
    data.specialisation_seniority_sublevel?.id !== undefined

  if (!showProgression || !canViewProgression) {
    return null
  }

  const cyclesRequired = progression?.eligibility_criteria?.cycles_in_role_and_seniority
  const cyclesCurrent =
    progression?.eligibility_criteria_result?.cycles_in_role_and_seniority
  const cyclesEligible =
    cyclesCurrent && cyclesRequired && cyclesCurrent >= cyclesRequired

  const gradesRequired =
    progression?.eligibility_criteria?.strong_plus_grades_in_recent_cycles || 0
  const gradesCurrent =
    progression?.eligibility_criteria_result?.strong_plus_grades_in_recent_cycles || 0
  const gradesEligible = gradesCurrent >= gradesRequired

  const getBadge = (eligible: boolean) => {
    return eligible ? (
      <Avatar.Badge bg={Token.color.green} useIcon={Check} size={16} />
    ) : (
      <Avatar.Badge bg={Token.color.orange} useIcon={SandWatch} size={16} />
    )
  }

  return (
    <Box
      mt={sticky ? 0 : 's-16'}
      border={withBorder ? `1px solid ${Token.color.greyTone10}` : undefined}
      borderRadius={16}
    >
      {renderHeader && <SummarySubheader>Progression</SummarySubheader>}
      <Widget p="s-16">
        <Flex alignItems="center" mb="s-8">
          <Text variant="h6" fontWeight={500}>
            {progression.current_seniority?.name}
          </Text>
          <Box px="s-4">
            <ArrowThinRight color="grey-tone-20" size={16} />
          </Box>
          <Text variant="h6" fontWeight={500}>
            {progression.target_seniority?.name}
          </Text>
        </Flex>
        <List variant="compact">
          <List.Item useIcon={getBadge(Boolean(cyclesEligible))}>
            {chain(
              'Review cycles at your current level',
              `${cyclesCurrent}/${cyclesRequired}`,
            )}
          </List.Item>
          <List.Item useIcon={getBadge(Boolean(gradesEligible))}>
            {chain(
              `${minProgressionGradeLabel}+ grades at your current level (up to last 4 cycles)`,
              `${gradesCurrent}/${gradesRequired}`,
            )}
          </List.Item>
        </List>
        {progression.eligibility_criteria_result.progression_cycle && (
          <Flex justifyContent="space-between" alignItems="center" mt="s-16">
            <Box maxWidth="95%">
              <Text variant="h6" fontWeight={400}>
                On track for {progression.eligibility_criteria_result.progression_cycle}{' '}
              </Text>
            </Box>
            <Tooltip
              placement="top"
              body={
                <Flex p="s-16" flexDirection="column" alignItems="center">
                  <Text use="div" color="background" whiteSpace="nowrap">
                    Receiving a {gradesChain} grade will keep you on track for the next
                    seniority level.
                  </Text>
                  <Text use="div" color="background">
                    Note: This is a preliminary assessment only and the final grades not
                    being {gradesChain} and / or a change of roles may impact progression
                    eligibility.
                  </Text>
                  <HideIfCommercial>
                    <Text use="div" color="background" whiteSpace="nowrap">
                      For more information, please refer to the{' '}
                      <Link
                        target="_blank"
                        href={PROGRESSION_POLICY_PLAYBOOK_LINK}
                        color="background"
                        textDecoration="underline"
                      >
                        policy playbook
                      </Link>
                      .
                    </Text>
                  </HideIfCommercial>
                </Flex>
              }
            >
              <InfoOutline size={16} color="grey-tone-50" cursor="pointer" />
            </Tooltip>
          </Flex>
        )}
      </Widget>
    </Box>
  )
}
