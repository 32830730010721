import { Token } from '@revolut/ui-kit'
import { EmployeeTimeOffBalanceCycleStatusId } from '@src/interfaces/timeOff'
import { notReachable } from '@src/utils/notReachable'

export const getTagAndColors = (statusId: EmployeeTimeOffBalanceCycleStatusId) => {
  if (statusId === 'current') {
    return {
      bg: Token.color.green_20,
      color: Token.color.green,
      tag: 'current',
    }
  }
  if (statusId === 'future') {
    return {
      bg: Token.color.greyTone10,
      color: Token.color.greyTone50,
      tag: 'upcoming',
    }
  }
  if (statusId === 'past') {
    return {
      bg: Token.color.orange_20,
      color: Token.color.warning,
      tag: 'past',
    }
  }
  return notReachable(statusId)
}
