import { Group, InputGroup, Subheader } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { cycleNameDescription } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSettings/EditCycleSettings/constants'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import React from 'react'

interface Props {
  isAdHoc: boolean
}

export const GeneralSettings = ({ isAdHoc }: Props) => (
  <>
    <Subheader>
      <Subheader.Title>General settings</Subheader.Title>
    </Subheader>
    <Group p="s-16">
      <InputGroup>
        <LapeNewInput
          name="name"
          label="Cycle name"
          description={cycleNameDescription}
          required
        />
        {!isAdHoc && (
          <InputGroup variant="horizontal">
            <LapeDatePickerInput
              name="start_date_time"
              label="Cycle Start Date"
              required
            />
            <LapeDatePickerInput name="end_date_time" label="Cycle End Date" required />
          </InputGroup>
        )}
      </InputGroup>
    </Group>
  </>
)
