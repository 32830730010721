import pluralize from 'pluralize'
import { assign, capitalize, pick } from 'lodash'
import {
  ActionButton,
  Box,
  Checkbox,
  Flex,
  HStack,
  Icon,
  InputGroup,
  Item,
  Subheader,
  Text,
  Token,
  Tooltip,
  TransitionCollapse,
  VStack,
  Widget,
  useTooltip,
} from '@revolut/ui-kit'

import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext, useLapeField } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { AccrualPeriodType, TimeOffPolicyDetailsStep } from '@src/interfaces/timeOff'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import React, { useEffect } from 'react'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { ItemSwitch } from '@components/Inputs/ItemSwitch/ItemSwitch'
import PageLoading from '@components/PageLoading/PageLoading'
import { ActivePolicyDraftWarning } from '../components/ActivePolicyDraftWarning'
import { NextTabButton } from './NextTabButton'
import { isReadonly, PolicyEditTabCommonProps } from './common'

const doesNotExpireOption = {
  label: 'Does not expire',
  value: { id: 'true' },
}

const expiryOnDateOption = {
  label: 'Expire on a certain date',
  value: { id: 'false' },
}

const carryoverNoExpiryOptions = [doesNotExpireOption, expiryOnDateOption]

const TimeOffCycleResetWidget = ({ isUnlimited }: { isUnlimited?: boolean }) => {
  const { values } = useLapeContext<TimeOffPolicyDetailsStep>()

  const isAccrualYearly = values.accrual_period?.id === 'yearly'
  const isBalanceClosingPeriodReadOnly = values.field_options?.read_only.includes(
    'balance_closing_period',
  )

  const isClosingAnniversary = values.balance_closing_period?.id === 'anniversary'
  const isClosingYearly = values.balance_closing_period?.id === 'yearly'

  const adjustAccrual = () => {
    if (!isAccrualYearly || isUnlimited) {
      return
    }
    values.accrual_period_day_of_month = values.balance_closing_period_day_of_month
    values.accrual_period_month = values.balance_closing_period_month
  }

  useEffect(() => {
    if (isAccrualYearly) {
      values.balance_closing_period = { id: 'yearly', name: 'Yearly' }
      adjustAccrual()
    }
  }, [isAccrualYearly])

  return (
    <Widget p="s-16">
      <InputGroup>
        <LapeRadioSelectInput
          name="balance_closing_period"
          label="Renewal cycle"
          selector={selectorKeys.time_off_balance_closing_periods}
          disabled={
            isAccrualYearly ||
            isBalanceClosingPeriodReadOnly ||
            isReadonly(values, 'balance_closing_period')
          }
          onAfterChange={() => {
            values.balance_closing_period_day_of_month = null
            values.balance_closing_period_month = null
          }}
        />
        {!isClosingAnniversary && (
          <InputGroup variant="horizontal">
            {isClosingYearly && (
              <Flex flex="1">
                <LapeRadioSelectInput
                  name="balance_closing_period_month"
                  label="Month"
                  selector={selectorKeys.months}
                  clearable
                  onAfterChange={adjustAccrual}
                />
              </Flex>
            )}
            <Flex flex="1">
              <LapeNewInput
                name="balance_closing_period_day_of_month"
                label="Day"
                required
                type="number"
                onAfterChange={adjustAccrual}
              />
            </Flex>
          </InputGroup>
        )}
      </InputGroup>
    </Widget>
  )
}

export const PolicyEditDetailsTab = ({
  copyFrom,
  basicStepInfo,
  setIsCopied,
  nextTabPath,
  onNavigateToNextTab,
}: PolicyEditTabCommonProps) => {
  const tooltip = useTooltip()
  const { values } = useLapeContext<TimeOffPolicyDetailsStep>()

  useEffect(() => {
    if (!copyFrom) {
      return
    }
    if (copyFrom.balance_type?.id === 'unlimited') {
      assign(
        values,
        pick(copyFrom, [
          'balance_closing_period',
          'balance_closing_period_month',
          'balance_closing_period_day_of_month',
          'balance_type',
        ]),
      )
    } else {
      assign(
        values,
        pick(copyFrom, [
          'accrual_amount',
          'accrual_period',
          'accrual_period_day_of_month',
          'accrual_period_day_of_week',
          'accrual_period_month',
          'balance_closing_period',
          'balance_closing_period_month',
          'balance_closing_period_day_of_month',
          'balance_rounding_logic',
          'balance_rounding_precision',
          'balance_type',
          'can_use_leave_not_accrued',
          'carryover_amount',
          'carryover_expiry_day_of_month',
          'carryover_expiry_month',
          'carryover_limit',
          'first_accrual',
          'is_negative_balance_allowed',
          'max_request_duration',
          'maximum_balance',
          'prorate_accruals_with_fte',
        ]),
      )
    }

    setIsCopied()
  }, [copyFrom])

  useEffect(() => {
    values.carryover_no_expiry = values.carryover_expiry_month
      ? expiryOnDateOption.value
      : doesNotExpireOption.value

    if (!values.balance_type?.id) {
      values.balance_type = { id: 'unlimited', name: 'Unlimited' }
    }
  }, [])

  const unit = (amount?: number | null) =>
    pluralize(basicStepInfo?.unit?.name || 'day', amount || 0).toLowerCase()
  const isUnlimited = values.balance_type?.id === 'unlimited'
  const isAllowedToCarryover = values.carryover_limit?.id.includes('limited')
  const carryoverAmountField = useLapeField('carryover_amount')

  useEffect(() => {
    if (isUnlimited) {
      values.balance_rounding_logic = null
      values.balance_rounding_precision = null
      return
    }
    if (!values.balance_rounding_precision) {
      values.balance_rounding_precision = { id: 'half', name: 'Half' }
    }
    if (!values.balance_rounding_logic) {
      values.balance_rounding_logic = {
        id: 'round',
        name: 'Round',
      }
    }
    if (!values.carryover_limit?.id && isUnlimited) {
      values.carryover_limit = {
        id: 'no_carryover',
        name: 'No carryover',
      }
    }
    if (
      values.carryover_limit?.id === 'no_carryover' ||
      values.carryover_limit?.id === 'unlimited_carryover'
    ) {
      values.carryover_amount = null
    }
  }, [
    values.balance_rounding_precision,
    values.balance_rounding_logic,
    values.carryover_limit,
    isUnlimited,
  ])

  if (!basicStepInfo) {
    return <PageLoading />
  }
  return (
    <>
      {values.draft_has_active_policy && <ActivePolicyDraftWarning />}
      <VStack gap="s-16">
        <Box>
          <SectionTitle
            title="Renewal cycle"
            subtitle="Select how often the policy cycle renews"
          />
          <TimeOffCycleResetWidget />
        </Box>

        <>
          <Box>
            <SectionTitle title="Accruals" />
            <Widget>
              <ItemSwitch
                checked={values.balance_type?.id === 'unlimited'}
                description="When enabled, your employees will be granted unlimited time off. You will still be able to track time-off requests"
                disabled={isReadonly(values, 'balance_type')}
                name="balance_type"
                onChange={event => {
                  if (event.target.checked) {
                    values.balance_type = { id: 'unlimited', name: 'Unlimited' }
                  } else {
                    values.balance_type = { id: 'limited', name: 'Limited' }
                  }
                }}
                title="Unlimited time-off"
                transparent
              />
              <TransitionCollapse in={!isUnlimited}>
                <VStack gap="s-16" px="s-16" pb="s-16">
                  <Subheader>
                    <Subheader.Title>
                      Specify how much time off will employees earn per cycle
                    </Subheader.Title>
                  </Subheader>
                  <InputGroup variant="horizontal">
                    <LapeNewInput
                      label={`Accrual amount (${unit()})`}
                      name="accrual_amount"
                      required
                    />
                    <LapeRadioSelectInput<AccrualPeriodType>
                      clearable
                      label="Earned every"
                      name="accrual_period"
                      onAfterChange={() => {
                        values.accrual_period_day_of_month = null
                        values.accrual_period_day_of_week = null
                        values.accrual_period_month = null
                      }}
                      required
                      searchable={false}
                      selector={selectorKeys.time_off_accrual_periods_noun}
                    />
                  </InputGroup>
                  {values.accrual_period?.id &&
                    !['daily', 'anniversary', 'yearly'].includes(
                      values.accrual_period?.id,
                    ) && (
                      <HStack align="start" gap="s-8">
                        <Subheader>{capitalize(unit())} will be earned on</Subheader>
                        {values.accrual_period?.id === 'monthly' ? (
                          <Flex flex="1" maxWidth={300}>
                            <LapeRadioSelectInput
                              searchable={false}
                              name="accrual_period_monthly"
                              selector={
                                selectorKeys.time_off_accrual_periods_monthy_choices
                              }
                            />
                          </Flex>
                        ) : null}
                        {values.accrual_period?.id === 'weekly' && (
                          <Flex flex="1" maxWidth={300}>
                            <LapeRadioSelectInput
                              name="accrual_period_day_of_week"
                              label="Day of week"
                              selector={selectorKeys.days_of_the_week}
                            />
                          </Flex>
                        )}
                        {values.accrual_period?.id === 'monthly' &&
                          values.accrual_period_monthly?.id === 'specific_date' && (
                            <Flex flex="1" maxWidth={200}>
                              <LapeNewInput
                                name="accrual_period_day_of_month"
                                label="Day of month"
                                required
                                type="number"
                              />
                            </Flex>
                          )}
                      </HStack>
                    )}
                </VStack>
              </TransitionCollapse>
            </Widget>
          </Box>
          <TransitionCollapse in={!isUnlimited}>
            <Box>
              <Widget p="s-16">
                <Flex
                  alignItems="center"
                  color={Token.color.greyTone50}
                  gap="s-8"
                  mb="s-16"
                >
                  <Text variant="emphasis2">Extra accrual groups</Text>
                  <Icon
                    name="16/ExclamationMarkOutline"
                    size={16}
                    {...tooltip.getAnchorProps()}
                  />
                </Flex>
                <Tooltip width={350} {...tooltip.getTargetProps()}>
                  For example if a specific seniority will gain extra days per period. We
                  recommend having separate policies for easier maintenance.
                </Tooltip>
                <InputGroup>
                  {values.accrual_levels?.map((_accrual, index) => (
                    <InputGroup key={index} variant="horizontal">
                      <LapeNewInput
                        label={`Additional accrual amount (${unit()})`}
                        name={`accrual_levels.${index}.amount`}
                        required
                      />
                      <LapeRadioSelectInput
                        label="Eligibility group"
                        name={`accrual_levels.${index}.group`}
                        selector={selectorKeys.dynamic_groups}
                      />
                      <Flex alignItems="center">
                        <ActionButton
                          iconOnly
                          onClick={() => {
                            if (values.accrual_levels) {
                              values.accrual_levels = values.accrual_levels.filter(
                                (_, idx) => idx !== index,
                              )
                            }
                          }}
                          useIcon="Minus"
                        />
                      </Flex>
                    </InputGroup>
                  ))}

                  <ActionButton
                    onClick={() => {
                      const newLevel = { amount: '', group: null }

                      if (values.accrual_levels) {
                        values.accrual_levels.push(newLevel)
                      } else {
                        values.accrual_levels = [newLevel]
                      }
                    }}
                    useIcon="Plus"
                  >
                    Add group
                  </ActionButton>
                </InputGroup>
              </Widget>
            </Box>
            <Box>
              <SectionTitle title="Carry over" />
              <Widget>
                <ItemSwitch
                  checked={isAllowedToCarryover}
                  description="Allow employees to carry over their unused days after the renewal cycle."
                  name="carryover_limit"
                  onChange={event => {
                    if (event.target.checked) {
                      values.carryover_limit =
                        basicStepInfo.unit.id === 'day'
                          ? {
                              id: 'limited_number_of_days',
                              name: 'Limited number of days',
                            }
                          : {
                              id: 'limited_number_of_hours',
                              name: 'Limited number of hours',
                            }
                    } else {
                      values.carryover_limit = {
                        id: 'no_carryover',
                        name: 'No carryover',
                      }
                    }
                  }}
                  title="Allow balance to carry over"
                  transparent
                />
                <TransitionCollapse in={isAllowedToCarryover}>
                  <Box p="s-16">
                    <InputGroup>
                      <LapeNewInput
                        disabled={
                          values.carryover_limit?.id === 'unlimited_carryover' ||
                          isReadonly(values, 'carryover_amount')
                        }
                        name="carryover_amount"
                        label={`Maximum carry over amount (${unit()})`}
                        required
                      />
                      <Checkbox
                        checked={values.carryover_limit?.id === 'unlimited_carryover'}
                        onChange={event => {
                          if (event.target.checked) {
                            values.carryover_limit = {
                              id: 'unlimited_carryover',
                              name: 'Unlimited carryover',
                            }
                          } else {
                            values.carryover_limit =
                              basicStepInfo.unit.id === 'day'
                                ? {
                                    id: 'limited_number_of_days',
                                    name: 'Limited number of days',
                                  }
                                : {
                                    id: 'limited_number_of_hours',
                                    name: 'Limited number of hours',
                                  }
                          }
                          carryoverAmountField.cleanErrors()
                        }}
                      >
                        <Text>Do not limit carry over amount</Text>
                      </Checkbox>
                      <Subheader>Carry over days expire after</Subheader>
                      <LapeNewRadioButtons
                        name="carryover_no_expiry"
                        onAfterChange={option => {
                          if (option.value.id === 'true') {
                            values.carryover_expiry_month = null
                            values.carryover_expiry_day_of_month = null
                          }
                        }}
                        options={carryoverNoExpiryOptions}
                      />
                      <TransitionCollapse in={values.carryover_no_expiry?.id === 'false'}>
                        <InputGroup data-testid="carryover_expiry" variant="horizontal">
                          <LapeRadioSelectInput
                            label="Month"
                            name="carryover_expiry_month"
                            selector={selectorKeys.months}
                          />
                          <LapeNewInput
                            label="Day"
                            name="carryover_expiry_day_of_month"
                            required
                            type="number"
                          />
                        </InputGroup>
                      </TransitionCollapse>
                    </InputGroup>
                  </Box>
                </TransitionCollapse>
              </Widget>
            </Box>
            <Box data-testid="first_accrual">
              <SectionTitle
                title="Should the first leave earning be proportionate or given in full?"
                subtitle="For example, an employee that joins in the middle of the year will receive all of the time off for the year or just half?"
              />
              <LapeRadioSwitch<IdAndName<string>>
                name="first_accrual"
                variant="horizontal"
                options={[
                  {
                    id: 'full_amount',
                    label: 'In full',
                    description:
                      'Even when employees join in the middle of the period, they will receive the full amount.',
                    value: { id: 'full_amount', name: 'Full amount' },
                  },
                  {
                    id: 'prorate_amount',
                    label: 'Proportional',
                    description:
                      'If an employee joins in the middle of the period, they will receive proportional to their start date.',
                    value: { id: 'prorate_amount', name: 'Prorate amount' },
                  },
                ]}
              />
            </Box>
            <Box>
              <SectionTitle
                title="Should employees be able to use leave they haven't earned yet?"
                subtitle="Even if you currently have 5 days off in balance, you can take the full 10 days off that you're set to earn"
              />
              <LapeRadioSwitch<boolean>
                name="can_use_leave_not_accrued"
                variant="horizontal"
                options={[
                  {
                    id: 'allow',
                    label: 'Allow using unearned leave',
                    description:
                      "This will allow employees to use their time off before it's accrued",
                    value: true,
                  },
                  {
                    id: 'disallow',
                    label: 'Disallow using unearned leave',
                    description:
                      "This will won't allow employees to use their time off before it's accrued",
                    value: false,
                  },
                ]}
              />
            </Box>
            <Box>
              <SectionTitle title="Advanced settings" />

              <Widget>
                <LapeNewSwitch
                  itemTypeProps={{
                    title:
                      'Time off should be proportional to their contract working hours?',
                    description: `For example, if I work 30 hours instead of 40, I would receive less ${unit()} off.`,
                    transparent: true,
                  }}
                  name="prorate_accruals_with_fte"
                />
                <Item
                  data-testid="max_balance_allowed"
                  style={{ backgroundColor: 'transparent' }} // TODO: REVCOR-3046 revisit after ui-kit update
                >
                  <Item.Content>
                    <Item.Title>There should be a maximum balance allowed</Item.Title>
                    <Item.Description>
                      If an employee’s balance for this policy goes above the maximum
                      value, it will be capped
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <Box width={150}>
                      <LapeNewInput
                        name="maximum_balance"
                        label={`Maximum (${unit()})`}
                        hideOptional
                      />
                    </Box>
                  </Item.Side>
                </Item>
                <Item
                  data-testid="max_request_duration"
                  style={{ backgroundColor: 'transparent' }} // TODO: REVCOR-3046 revisit after ui-kit update
                >
                  <Item.Content>
                    <Item.Title>
                      Set the maximum duration for requests of this policy
                    </Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <Item.Side>
                      <Box width={150}>
                        <LapeNewInput
                          name="max_request_duration"
                          label={`Maximum (${unit()})`}
                          hideOptional
                        />
                      </Box>
                    </Item.Side>
                  </Item.Side>
                </Item>
                <Item
                  style={{ backgroundColor: 'transparent' }} // TODO: REVCOR-3046 revisit after ui-kit update
                >
                  <Item.Content>
                    <Item.Title>How should the balance be rounded?</Item.Title>
                    <Item.Description>
                      For example, if my balance is 2.3, what should it round to?
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <Box width={190}>
                      <LapeRadioSelectInput
                        name="balance_rounding_logic"
                        label="Balance rounding logic"
                        selector={
                          selectorKeys.time_off_balance_rounding_logics_human_readable
                        }
                      />
                    </Box>
                  </Item.Side>
                </Item>
                <Item
                  style={{ backgroundColor: 'transparent' }} // TODO: REVCOR-3046 revisit after ui-kit update
                >
                  <Item.Content>
                    <Item.Title>
                      How precise should the rounding of the balance be?
                    </Item.Title>
                    <Item.Description>
                      For example, if the amount is 0.3, should it round to 0.5 or 1
                      (assuming it's rounding up)?
                    </Item.Description>
                  </Item.Content>
                  <Item.Side>
                    <Box width={190}>
                      <LapeRadioSelectInput
                        name="balance_rounding_precision"
                        label="Balance rounding precision"
                        selector={selectorKeys.time_off_balance_rounding_precisions}
                      />
                    </Box>
                  </Item.Side>
                </Item>
              </Widget>
            </Box>
          </TransitionCollapse>
        </>
      </VStack>
      <NextTabButton
        nextTabPath={nextTabPath}
        onNavigateToNextTab={onNavigateToNextTab}
      />
    </>
  )
}
