import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Flex, Subheader, Text, Token, Widget } from '@revolut/ui-kit'
import { useGetCalibrationStats } from '@src/api/reviewCycles'
import { PerformancePieChart } from '@src/features/ReviewCycle/Analytics/Charts/PerformancePieChart/PerformancePieChart'
import { PerformanceItemDataKeys } from '@src/features/ReviewCycle/Analytics/Charts/PerformancePieChart/PerformanceItems'

interface Props {
  cycleId: number | string
  height: number
}

export const PerformanceScore = ({ cycleId, height }: Props) => {
  const { data: stats, isLoading } = useGetCalibrationStats(cycleId)

  const nips = Number(stats?.nips ?? 0)

  const nipsPieChartData = useMemo(
    () => [
      {
        name: PerformanceItemDataKeys.NIPS,
        value: Math.abs(nips),
        color: Token.colorChannel.blue,
      },
      {
        name: PerformanceItemDataKeys.ALL,
        value: 1 - Math.abs(nips),
        color: Token.colorChannel.grey20,
      },
    ],
    [nips],
  )

  return (
    <Flex alignItems="stretch" minHeight={height} width="100%">
      <Widget flex={1} width="100%">
        <Flex
          alignItems="stretch"
          flexDirection="column"
          px="s-16"
          pb="s-16"
          height="100%"
        >
          <Subheader>
            <Subheader.Title>
              <Text variant="emphasis1" fontWeight={500} color={Token.color.foreground}>
                <FormattedMessage defaultMessage="Performance" id="performance.label" />
              </Text>
            </Subheader.Title>
          </Subheader>
          <Box width="100%" height="100%" mt={-16}>
            <PerformancePieChart
              total={nips}
              data={nipsPieChartData}
              label="NIPS"
              isLoading={isLoading}
            />
          </Box>
        </Flex>
      </Widget>
    </Flex>
  )
}
