import React from 'react'
import { FormattedMessage } from 'react-intl'
import pluralize from 'pluralize'
import {
  Box,
  DataPoint,
  DataPointSkeleton,
  H4,
  Highlights,
  HStack,
  Spacer,
  Subheader,
  SubheaderSkeleton,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { useGetReviewStageStats } from '@src/api/reviewCycles'
import { formatPercentage } from '@src/utils/format'
import { getColorForPortion } from '@src/features/ReviewCycle/components/Progress/styles'
import {
  Progress,
  ProgressSkeleton,
} from '@src/features/ReviewCycle/components/Progress/Progress'

interface Props {
  cycleId: number | string
}

export const ReviewStats = ({ cycleId }: Props) => {
  const { data: stats, isLoading } = useGetReviewStageStats(cycleId)

  // TODO: revisit this logic once BE finishes https://revolut.atlassian.net/browse/REVC-7636
  //  and update the logic to use "total_reviews" and "total_reviews_completion" fields instead
  //  if they will be introduced
  const withReviewsCount = stats?.employees_with_reviews ?? 0
  const totalCount = stats?.total_employees ?? 0
  const employeesWithReviewPortion = withReviewsCount / totalCount
  const employeesWithReviewsPercentage = formatPercentage(
    isFinite(employeesWithReviewPortion) ? employeesWithReviewPortion : 0,
  )
  const employeesWithoutReviewsPercentage = formatPercentage(
    isFinite(employeesWithReviewPortion) ? 1 - employeesWithReviewPortion : 1,
  )
  const progressColor = getColorForPortion(employeesWithReviewPortion)

  if (isLoading) {
    return <Skeleton />
  }

  return (
    <VStack space="s-16">
      <Widget
        px="s-16"
        pb="s-16"
        data-testid="performance_analytics_review_completion_widget"
      >
        <Subheader>
          <Subheader.Title>
            <Text variant="emphasis1" fontWeight={500} color={Token.color.foreground}>
              <FormattedMessage
                defaultMessage="Completion rate"
                id="performance.analytics.reviews.completion.label"
              />
            </Text>
          </Subheader.Title>
        </Subheader>
        <Box mx="s-16">
          <Highlights>
            <DataPoint data-testid="lm_completion">
              <DataPoint.Label textAlign="center">
                <FormattedMessage defaultMessage="LM" id="performance.reviews.lm.label" />
              </DataPoint.Label>
              <DataPoint.Value textAlign="center">
                <H4>{formatPercentage((stats?.lm_completion ?? 0) / 100)}</H4>
              </DataPoint.Value>
            </DataPoint>
            <Spacer />
            <DataPoint data-testid="fm_completion">
              <DataPoint.Label textAlign="center">
                <FormattedMessage defaultMessage="FM" id="performance.reviews.fm.label" />
              </DataPoint.Label>
              <DataPoint.Value textAlign="center">
                <H4>{formatPercentage((stats?.fm_completion ?? 0) / 100)}</H4>
              </DataPoint.Value>
            </DataPoint>
            <Spacer />
            <DataPoint data-testid="self_completion">
              <DataPoint.Label textAlign="center">
                <FormattedMessage
                  defaultMessage="Self"
                  id="performance.reviews.self.label"
                />
              </DataPoint.Label>
              <DataPoint.Value textAlign="center">
                <H4>{formatPercentage((stats?.self_completion ?? 0) / 100)}</H4>
              </DataPoint.Value>
            </DataPoint>
          </Highlights>
        </Box>
      </Widget>
      <Widget
        px="s-16"
        pb="s-16"
        data-testid="performance_analytics_review_progress_widget"
      >
        <Subheader>
          <Subheader.Title>
            <Text variant="emphasis1" fontWeight={500} color={Token.color.foreground}>
              <FormattedMessage
                defaultMessage="Progress"
                id="performance.analytics.reviews.progress.title"
              />
            </Text>
          </Subheader.Title>
          <Subheader.Side>
            <FormattedMessage
              values={{ count: withReviewsCount }}
              defaultMessage={`Total: {count} ${pluralize('review', withReviewsCount)}`}
              id="performance.analytics.reviews.progress.description"
            />
          </Subheader.Side>
        </Subheader>
        <Progress
          value={employeesWithReviewPortion}
          title={
            <Text fontSize={14} color={progressColor}>
              <FormattedMessage defaultMessage="Completed" id="common.completed.label" />
            </Text>
          }
          description={
            <Text fontSize={14} color={Token.color.greyTone50}>
              <FormattedMessage defaultMessage="Remaining" id="common.remaining.label" />
            </Text>
          }
          isLoading={isLoading}
          label={
            <HStack>
              <Text fontSize={14} color={progressColor}>
                {employeesWithReviewsPercentage}
              </Text>
              <Spacer />
              <Text fontSize={14} color={Token.color.greyTone50}>
                {employeesWithoutReviewsPercentage}
              </Text>
            </HStack>
          }
        />
      </Widget>
    </VStack>
  )
}

const Skeleton = () => (
  <VStack space="s-16">
    <Widget p="s-16">
      <SubheaderSkeleton>
        <SubheaderSkeleton.Title />
      </SubheaderSkeleton>
      <Highlights>
        <DataPointSkeleton />
        <DataPointSkeleton />
        <DataPointSkeleton />
      </Highlights>
    </Widget>
    <Widget p="s-16">
      <SubheaderSkeleton>
        <SubheaderSkeleton.Title />
      </SubheaderSkeleton>
      <ProgressSkeleton />
    </Widget>
  </VStack>
)
