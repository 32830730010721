import {
  Flex,
  AvatarSkeleton,
  TextSkeleton,
  VStack,
  Separator,
  Box,
  Grid,
  Highlights,
} from '@revolut/ui-kit'
import React from 'react'
import { CycleType } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'

export const HeaderSummarySkeleton = ({ cycleType }: { cycleType?: CycleType }) => (
  <Flex p="s-16" gap="s-16" style={{ flexGrow: 1 }} flexWrap="wrap">
    <Flex ml="s-12" mt="s-16" mb="s-4" gap="s-16" alignItems="center">
      <AvatarSkeleton size={76} />
      <Flex minWidth={110} gap="s-2" flexDirection="column">
        <TextSkeleton size={6} variant="heading3" />
        <TextSkeleton size={8} variant="caption" />
      </Flex>
    </Flex>

    <VStack>
      <TextSkeleton pt="s-12" pb="s-16" pl="s-12" size={14} variant="emphasis2" />

      <Grid>
        <Highlights>
          <Flex gap="s-16" p="s-16" alignItems="center">
            <AvatarSkeleton size={48} />
            <TextSkeleton
              size={cycleType === CycleType.Year ? 7 : 5}
              variant="emphasis1"
            />
          </Flex>
          <Flex gap="s-16" p="s-16" alignItems="center">
            <AvatarSkeleton size={48} />
            <TextSkeleton
              size={cycleType === CycleType.Year ? 9 : 7}
              variant="emphasis1"
            />
          </Flex>
          <Box height={60}>
            <Separator orientation="vertical" />
          </Box>
          <Flex gap="s-16" p="s-16" alignItems="center">
            <AvatarSkeleton size={48} />
            <TextSkeleton size={5} variant="emphasis1" />
          </Flex>
          {cycleType === CycleType.Year && (
            <Flex gap="s-16" p="s-16" alignItems="center">
              <AvatarSkeleton size={48} />
              <TextSkeleton size={16} variant="emphasis1" />
            </Flex>
          )}
        </Highlights>
      </Grid>
    </VStack>
  </Flex>
)
