import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { Security } from '@src/pages/Settings/Security/Security'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { securitySettings } from '@src/api/settings'

const routes = [
  {
    title: 'Security settings',
    description: 'Choose employee login methods and manage two-factor authentication.',
    path: ROUTES.SETTINGS.SECURITY,
    url: ROUTES.SETTINGS.SECURITY,
    canView: [
      PermissionTypes.ViewSecuritySettings,
      PermissionTypes.ChangeSecuritySettings,
    ],
    component: Security,
  },
]

export const SecuritySettings = () => {
  return <SettingsForm routes={routes} api={securitySettings} />
}
