import { OneToOneMeetingChangelogItem } from '@src/interfaces/meetings'
import { format } from 'date-fns'

export function groupChangelogsByMonth(changelogs: OneToOneMeetingChangelogItem[]) {
  return changelogs.reduce<Record<string, OneToOneMeetingChangelogItem[]>>(
    (acc, changelog) => {
      const monthKey = format(new Date(changelog.change_time), 'MMM yyyy')

      if (!acc[monthKey]) {
        acc[monthKey] = []
      }

      acc[monthKey].push(changelog)

      return acc
    },
    {},
  )
}
