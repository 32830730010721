import React from 'react'
import { Flex, HStack, Text, Token, VStack, Widget } from '@revolut/ui-kit'

import { EngagementResultsItemDetailsInterface } from '@src/interfaces/engagement'
import { ChartSkeleton } from '@src/pages/Forms/QueryForm/components/Charts/ChartSkeleton'
import { NoDataPlaceholder, withLoader } from '../common'
import { PieChart } from './PieChart'

interface Props {
  data: EngagementResultsItemDetailsInterface | undefined
  isLoading: boolean
  containerHeight: number
  commentsCount: number
  isCommentsLoading: boolean
}
export const PieChartWidget = ({
  data,
  isLoading,
  containerHeight,
  commentsCount,
  isCommentsLoading,
}: Props) => {
  if (isLoading) {
    return <ChartSkeleton height={containerHeight} />
  }
  if (!data) {
    return (
      <Widget height="100%">
        <NoDataPlaceholder />
      </Widget>
    )
  }
  const contentPadding = 16

  return (
    <HStack space="s-16">
      <Widget width="80%" height={containerHeight} p={contentPadding}>
        {withLoader(
          isLoading,
          <PieChart data={data} containerHeight={containerHeight - contentPadding * 2} />,
        )}
      </Widget>
      <VStack space="s-16" width="20%" minWidth={140}>
        <Widget height="50%" width="100%">
          <Flex
            flex="1"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            {withLoader(
              isLoading,
              <VStack space="s-4" align="center">
                <Text variant="emphasis1" color={Token.color.greyTone50}>
                  Total answers
                </Text>
                <Text variant="h0">{data.respondents}</Text>
              </VStack>,
            )}
          </Flex>
        </Widget>
        <Widget height="50%" width="100%">
          <Flex
            flex="1"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            {withLoader(
              isCommentsLoading,
              <VStack space="s-4" align="center">
                <Text variant="emphasis1" color={Token.color.greyTone50}>
                  Total comments
                </Text>
                <Text variant="h0">{commentsCount}</Text>
              </VStack>,
            )}
          </Flex>
        </Widget>
      </VStack>
    </HStack>
  )
}
