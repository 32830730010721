import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Cell,
  Color,
  Flex,
  Header,
  HStack,
  IconButton,
  MoreBar,
  Popup,
  Sticky,
  Text,
  Token,
  Widget,
} from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import {
  ArrowExchange,
  Cross,
  ExclamationMarkOutline,
  ExclamationTriangle,
} from '@revolut/icons'
import { connect } from 'lape'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import get from 'lodash/get'

import {
  getChangePercent,
  getDepartmentEmpoyeeCompensationReviews,
  updateEmployeeCompensationReviews,
  useCalculateAllocatedBudgets,
  useDepartmentCompensationDetails,
} from '@src/api/compensation'
import {
  EmployeeCompensationChanges,
  EmployeeCompensationEditInterface,
  EmployeeCompensationReviewsUpdate,
} from '@src/interfaces/compensation'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { formatMoney } from '@src/utils/format'
import {
  ChangeInfo,
  EditableRowInterface,
} from '@src/components/Table/EditableTable/EditableTable'
import {
  cellIsIneligible,
  employeeCompensationActionColumn,
  employeeCompensationBonusGrantColumn,
  employeeCompensationBonusGrantRecommendationColumn,
  employeeCompensationCurrentSalaryColumn,
  employeeCompensationEmployeeColumn,
  employeeCompensationFlagsColumn,
  employeeCompensationJustificationColumn,
  employeeCompensationLatestGradeColumn,
  employeeCompensationLocationColumn,
  employeeCompensationNewSalaryColumn,
  employeeCompensationSalaryBandColumn,
  employeeCompensationSalaryChangeColumn,
  employeeCompensationSalaryChangeEditableColumn,
  employeeCompensationSalaryChangePercentColumn,
  employeeCompensationSalaryChangePercentEditableColumn,
  employeeCompensationSalaryChangeRecommendationColumn,
  employeeCompensationSeniorityColumn,
  employeeCompensationSpecialisationColumn,
} from '@src/constants/columns/compensation'
import LapeEditableTable from '@src/components/Table/EditableTable/LapeEditableTable'
import SettingsButtons from '@src/features/SettingsButtons'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import {
  AllocatedBudgetStats,
  defaultCurrency,
  DepartmentStats,
} from '@src/features/BudgetDistribution/BudgetDistribution'
import Stat from '@src/components/Stat/Stat'
import { useTable } from '@src/components/Table/hooks'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { goBack } from '@src/actions/RouterActions'
import { scheduledForTerminationOrIneligibleHighlight } from '@src/features/BudgetDistribution/BudgetDistributionTable'
import JustificationGuidelines from './JustificationGuidelines'
import SwitchButton from '@src/components/SwitchButton/SwitchButton'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { LocalStorageKeys, PermissionTypes } from '@src/store/auth/types'
import { TableNames } from '@src/constants/table'
import { useGetDepartment } from '@src/api/department'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

const rowDeviatedOver10Percent = (row: EmployeeCompensationEditInterface) => {
  if (cellIsIneligible(row)) {
    return false
  }

  const bonusDeviation = Math.abs(row.bonus_recommendation - row.bonus_amount_new)
  const tenPercentBonusRecc = 0.1 * row.bonus_recommendation
  if (bonusDeviation > tenPercentBonusRecc) {
    return true
  }

  const salaryDeviation = Math.abs(row.salary_recommendation - row.salary_amount_new)
  const tenPercentSalaryRecc = 0.1 * row.salary_recommendation
  if (salaryDeviation > tenPercentSalaryRecc) {
    return true
  }

  return false
}

const getRow = (
  resetRow: (data: EmployeeCompensationEditInterface) => void,
  onChangeRowJustification: (
    row: EmployeeCompensationEditInterface,
    justification: string,
  ) => void,
  rowsWithError: { id: number }[],
  changes: EmployeeCompensationChanges[],
): EditableRowInterface<EmployeeCompensationEditInterface> => {
  return {
    highlight: data => {
      const terminationHighlight = scheduledForTerminationOrIneligibleHighlight(data)

      if (terminationHighlight) {
        return terminationHighlight
      }

      if (rowDeviatedOver10Percent(data)) {
        return Token.color.actionBackground
      }

      if (rowsWithError.find(row => row.id === data.id)) {
        return Token.color.redActionBackground
      }

      const changeRow = changes.find(row => row.id === data.id)
      if (changeRow && !changeRow.justification) {
        return Token.color.actionBackground
      }

      return ''
    },
    cells: [
      {
        ...employeeCompensationEmployeeColumn,
        width: 200,
      },
      {
        ...employeeCompensationSeniorityColumn,
        width: 110,
      },
      {
        ...employeeCompensationSpecialisationColumn,
        width: 150,
      },
      {
        ...employeeCompensationLocationColumn,
        width: 100,
      },
      {
        ...employeeCompensationLatestGradeColumn,
        width: 100,
      },
      {
        ...employeeCompensationCurrentSalaryColumn,
        width: 100,
      },
      {
        ...employeeCompensationNewSalaryColumn,
        width: 100,
      },
      {
        ...employeeCompensationSalaryChangeEditableColumn,
        width: 100,
      },
      {
        ...employeeCompensationSalaryChangePercentEditableColumn,
        width: 100,
      },
      {
        ...employeeCompensationSalaryBandColumn,
        width: 150,
      },
      {
        ...employeeCompensationBonusGrantColumn,
        width: 100,
      },
      {
        ...employeeCompensationFlagsColumn,
        width: 120,
      },
      {
        ...employeeCompensationActionColumn(resetRow),
        width: 100,
      },
      {
        ...employeeCompensationJustificationColumn(changes, onChangeRowJustification),
        width: 150,
      },
      {
        ...employeeCompensationSalaryChangeRecommendationColumn,
        width: 300,
      },
      {
        ...employeeCompensationBonusGrantRecommendationColumn,
        width: 300,
      },
    ],
  }
}

interface MessageWidgetProps {
  text: string | React.ReactNode
  type: 'error' | 'info' | 'message'
}

const MessageWidgetColors = {
  error: {
    bg: Token.color.redActionBackground,
    color: Token.color.red,
  },
  info: {
    bg: Token.color.actionBackground,
    color: Token.color.blue,
  },
  message: {
    bg: Token.color.greyTone10,
    color: Token.color.foreground,
  },
}

const MessageWidget = ({ text, type }: MessageWidgetProps) => {
  const bg = MessageWidgetColors[type].bg
  const color = MessageWidgetColors[type].color

  return (
    <Widget bg={bg} p="s-16" mt="s-8">
      <HStack gap="s-8" align="center">
        <ExclamationMarkOutline color={color} />
        <Text color={color}>{text}</Text>
      </HStack>
    </Widget>
  )
}

export const BudgetViewingRecommendationBanner = () => {
  const [isHidden, setIsHidden] = useLocalStorage(
    LocalStorageKeys.HIDE_COMP_BANNER,
    false,
  )

  if (isHidden) {
    return null
  }

  return (
    <MessageWidget
      type="message"
      text={
        <HStack gap="s-8" align="center">
          <Text>
            We recommend viewing this screen on a large external monitor for the best
            experience possible. You can also turn specific columns on or off if needed by
            clicking on column settings button.
          </Text>
          <IconButton
            useIcon={Cross}
            onClick={() => setIsHidden(true)}
            aria-label="Close message"
          />
        </HStack>
      }
    />
  )
}

interface FormData {
  data: EmployeeCompensationEditInterface[]
  initialData: EmployeeCompensationEditInterface[]
  changes: EmployeeCompensationChanges[]
}

const EditDepartmentBudget = connect(() => {
  const { values } = useLapeContext<FormData>()
  const params = useParams<{ id?: string; currencyCode?: string }>()

  const id = params.id ? Number(params.id) : undefined
  const currencyCode = params.currencyCode ?? defaultCurrency

  const [resetPopupOpen, setResetPopupOpen] = useState(false)
  const [guidelinesOpen, setGuidelinesOpen] = useState(false)

  const permissions = useSelector(selectPermissions)

  const table = useTable(
    {
      getItems: getDepartmentEmpoyeeCompensationReviews(id),
    },
    undefined,
    undefined,
    {
      disable: !id,
      disableQuery: true,
    },
  )

  const details = useDepartmentCompensationDetails(id, currencyCode)

  const { data: departmentData } = useGetDepartment(details.data?.department?.id)

  const canBypassReviewRecommendations =
    departmentData?.field_options.permissions?.includes(
      PermissionTypes.BypassEmployeeCompensationReviewRecommendations,
    )

  const calculateAllocatedBudgets = useCalculateAllocatedBudgets(currencyCode)

  useEffect(() => {
    if (table.data.length && table.data.length > values.data.length) {
      /** To handle pagination */
      const newData = table.data.slice(values.data.length)
      set(values, 'data', [...values.data, ...cloneDeep(newData)])
      set(values, 'initialData', [...values.initialData, ...cloneDeep(newData)])
    }
  }, [table.data, values])

  const totals = useMemo(() => {
    const emptyValues = {
      total_salary_budget: undefined,
      total_bonus_budget: undefined,
      allocated_salary_budget: undefined,
      allocated_bonus_budget: undefined,
      total_salary_budget_num: undefined,
      total_bonus_budget_num: undefined,
      allocated_salary_budget_num: undefined,
      allocated_bonus_budget_num: undefined,
    }

    const data = details.data

    if (
      !data ||
      !('exchanged' in data) ||
      data.exchanged?.total_salary_budget == null ||
      data.exchanged?.total_bonus_budget == null ||
      data.exchanged?.allocated_bonus_budget == null ||
      data.exchanged?.allocated_salary_budget == null
    ) {
      return emptyValues
    }

    if (values.changes.length) {
      if (!calculateAllocatedBudgets?.data?.data || calculateAllocatedBudgets.isLoading) {
        return emptyValues
      }

      return {
        total_salary_budget: formatMoney(
          data.exchanged.total_salary_budget,
          data.exchanged.target_currency.iso_code,
        ),
        total_salary_budget_num: data.exchanged.total_salary_budget,
        total_bonus_budget: formatMoney(
          data.exchanged.total_bonus_budget,
          data.exchanged.target_currency.iso_code,
        ),
        total_bonus_budget_num: data.exchanged.total_bonus_budget,
        allocated_salary_budget: formatMoney(
          calculateAllocatedBudgets.data.data.allocated_salary_budget,
          data.exchanged.target_currency.iso_code,
        ),
        allocated_salary_budget_num:
          calculateAllocatedBudgets.data.data.allocated_salary_budget,
        allocated_bonus_budget: formatMoney(
          calculateAllocatedBudgets.data.data.allocated_bonus_budget,
          data.exchanged.target_currency.iso_code,
        ),
        allocated_bonus_budget_num:
          calculateAllocatedBudgets.data.data.allocated_bonus_budget,
      }
    }

    return {
      total_salary_budget: formatMoney(
        data.exchanged.total_salary_budget,
        data.exchanged.target_currency.iso_code,
      ),
      total_salary_budget_num: data.exchanged.total_salary_budget,
      total_bonus_budget: formatMoney(
        data.exchanged.total_bonus_budget,
        data.exchanged.target_currency.iso_code,
      ),
      total_bonus_budget_num: data.exchanged.total_bonus_budget,
      allocated_salary_budget: formatMoney(
        data.exchanged.allocated_salary_budget,
        data.exchanged.target_currency.iso_code,
      ),
      allocated_salary_budget_num: data.exchanged.allocated_salary_budget,
      allocated_bonus_budget: formatMoney(
        data.exchanged.allocated_bonus_budget,
        data.exchanged.target_currency.iso_code,
      ),
      allocated_bonus_budget_num: data.exchanged.allocated_bonus_budget,
    }
  }, [details.data, calculateAllocatedBudgets, values.changes])

  const recalculateAllocatedBudgets = () => {
    const changes = transformChangesForSubmit(values)
    if (changes.length) {
      calculateAllocatedBudgets.mutate(changes)
    }
  }

  const onResetRow = (row: EmployeeCompensationEditInterface) => {
    const rowToReset = values.initialData.find(r => r.id === row.id)

    if (rowToReset) {
      set(
        values,
        'data',
        values.data.map(r => (r.id === row.id ? cloneDeep(rowToReset) : r)),
      )
      set(values, 'changes', getChanges(values))
      recalculateAllocatedBudgets()
    }
  }

  const onChangeRowJustification = (
    row: EmployeeCompensationEditInterface,
    justification: string,
  ) => {
    const rowToChange = values.initialData.find(r => r.id === row.id)

    if (rowToChange) {
      set(
        values,
        'data',
        values.data.map(r => (r.id === row.id ? { ...row, justification } : r)),
      )
      set(values, 'changes', getChanges(values))
    }
  }

  const onTableChange = (changeInfo: ChangeInfo) => {
    const row = get(values.data, changeInfo.rowPath) as EmployeeCompensationEditInterface

    if (changeInfo.columnTitle === employeeCompensationSalaryChangeColumn.title) {
      const newSalaryAmount = row.salary_amount + changeInfo.fieldValue
      set(
        values,
        'data',
        values.data.map(r =>
          r.id === row.id
            ? {
                ...r,
                salary_change_percent: getChangePercent(
                  row.salary_amount,
                  newSalaryAmount,
                ),
                salary_amount_new: newSalaryAmount,
              }
            : r,
        ),
      )
    }
    if (changeInfo.columnTitle === employeeCompensationSalaryChangePercentColumn.title) {
      const change = Math.round(row.salary_amount * (changeInfo.fieldValue / 100))

      set(
        values,
        'data',
        values.data.map(r =>
          r.id === row.id
            ? {
                ...r,
                salary_change: change,
                salary_amount_new: row.salary_amount + change,
              }
            : r,
        ),
      )
    }
    if (changeInfo.columnTitle === employeeCompensationBonusGrantColumn.title) {
      set(
        values,
        'data',
        values.data.map(r =>
          r.id === row.id
            ? {
                ...r,
                bonus_change: row.bonus_amount_new - (row.bonus_amount || 0),
              }
            : r,
        ),
      )
    }

    set(values, 'changes', getChanges(values))
    recalculateAllocatedBudgets()
  }

  // const alreadyOverSalaryBandErrors = values.changes.filter(change => {
  //   const initialRow = values.initialData?.find(r => r.id === change.id)

  //   return (
  //     initialRow &&
  //     initialRow.salary_amount != null &&
  //     initialRow.salary_benchmark != null &&
  //     initialRow.salary_amount > initialRow.salary_benchmark.upper_band &&
  //     change.salary_amount_new > initialRow.salary_benchmark.upper_band
  //   )
  // })

  // const willGoOverSalaryBandErrors = values.changes.filter(change => {
  //   const initialRow = values.initialData?.find(r => r.id === change.id)

  //   return (
  //     initialRow &&
  //     initialRow.salary_amount != null &&
  //     initialRow.salary_benchmark != null &&
  //     initialRow.salary_amount <= initialRow.salary_benchmark.upper_band &&
  //     change.salary_amount_new > initialRow.salary_benchmark.upper_band
  //   )
  // })

  const over10PercentDeviation = () => {
    const deviatedRows = values.data.filter(rowDeviatedOver10Percent)

    return deviatedRows.length > 0
  }

  const isCompensationAdmin =
    canBypassReviewRecommendations ||
    permissions.includes(PermissionTypes.BypassEmployeeCompensationReviewRecommendations)

  const messages = [
    {
      text: 'You are deviating by more than 10% from the initial salary or bonus recommendation.',
      isVisible: over10PercentDeviation() && isCompensationAdmin,
      type: 'info' as const,
    },
    {
      text: 'Please add justification for any change made to employee salary or bonus.',
      isVisible: values.changes.some(change => !change.justification),
      type: 'error' as const,
    },
    {
      text: 'You are deviating by more than 10% from the initial salary or bonus recommendation.',
      isVisible: over10PercentDeviation() && !isCompensationAdmin,
      type: 'error' as const,
    },
    {
      text: 'The sum of all salaries exceeds the total amount of salaries budget.',
      isVisible:
        totals.allocated_salary_budget_num != null &&
        totals.total_salary_budget_num != null &&
        totals.allocated_salary_budget_num > totals.total_salary_budget_num,
      type: 'error' as const,
    },
    {
      text: 'The sum of all bonuses exceeds the total amount of bonuses budget.',
      isVisible:
        totals.allocated_bonus_budget_num != null &&
        totals.total_bonus_budget_num != null &&
        totals.allocated_bonus_budget_num > totals.total_bonus_budget_num,
      type: 'error' as const,
    },
    // {
    //   text: 'You cannot increase the salary of an employee that is already above maximum compensation band.',
    //   isVisible: alreadyOverSalaryBandErrors.length > 0,
    //   type: 'error' as const,
    // },
    // {
    //   text: 'You cannot increase the salary of an employee who is above maximum compensation band.',
    //   isVisible: willGoOverSalaryBandErrors.length > 0,
    //   type: 'error' as const,
    // },
  ].filter(err => err.isVisible)

  const canSubmitAsCompensationAdmin =
    isCompensationAdmin && messages.filter(err => err.type === 'error').length === 0

  const isEmpty = !table.loading && table.data.length === 0
  const departmentDetails = details.data?.department
  const poolDetails = details.data?.budget_pool_config
  const backUrl = (() => {
    if (departmentDetails) {
      return pathToUrl(ROUTES.FORMS.DEPARTMENT.BUDGET.DISTRIBUTION, {
        id: departmentDetails.id,
      })
    }
    if (poolDetails && details.data) {
      return pathToUrl(ROUTES.FORMS.VIEW_CUSTOM_BUDGET_POOL, { id: details.data.id })
    }
    return ROUTES.FORMS.COMPANY.COMPENSATION
  })()

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={departmentDetails ? 'Edit compensation' : 'Edit custom pool budgets'}
          backUrl={backUrl}
          subtitle={
            details.data?.department?.name || details.data?.budget_pool_config?.name
          }
          isLoading={details.isLoading}
        />

        <Cell>
          <Flex flexDirection="column" width="100%">
            <Flex mb="s-24" gap="s-32">
              <Stat label="Cycle" val={details.data?.company_compensation_review?.year} />
              <Stat label="Currency" val={currencyCode} />
              <AllocatedBudgetStats {...totals} />
              <DepartmentStats
                selectedCompensationReview={details.data}
                isEmpty={isEmpty}
              />
            </Flex>

            <SettingsButtons mb="s-16">
              <MoreBar.Action
                onClick={() => setResetPopupOpen(true)}
                disabled={values.changes?.length === 0}
                variant="negative"
                useIcon={ArrowExchange}
              >
                Reset all budget changes
              </MoreBar.Action>
              <SwitchButton
                checked={guidelinesOpen}
                onClick={() => setGuidelinesOpen(value => !value)}
              >
                Show Justification Guidelines
              </SwitchButton>
            </SettingsButtons>

            <BudgetViewingRecommendationBanner />

            <Flex style={{ position: 'relative' }} flex="1 0" mt="s-16">
              <LapeEditableTable<EmployeeCompensationEditInterface>
                name={TableNames.DepartmentBudgetEditable}
                {...table}
                dataFieldName="data"
                disableFilters
                disableSorting
                noReset
                useWindowScroll
                row={getRow(
                  onResetRow,
                  onChangeRowJustification,
                  [], // [...alreadyOverSalaryBandErrors, ...willGoOverSalaryBandErrors],
                  values.changes,
                )}
                onChange={onTableChange}
                emptyState={
                  <EmptyTableRaw
                    title="No budget data was uploaded yet"
                    imageId="3D098"
                    description="As soon as company budget for the cycle will be uploaded, the department budget will appear here."
                  />
                }
              />
            </Flex>
            <Sticky bottom={80}>
              {messages.map(message => (
                <MessageWidget {...message} key={message.text} />
              ))}
            </Sticky>
          </Flex>
        </Cell>

        {(messages.length === 0 || canSubmitAsCompensationAdmin) && (
          <PageActions>
            <NewSaveButtonWithPopup onAfterSubmit={() => goBack(backUrl)}>
              Submit
            </NewSaveButtonWithPopup>
          </PageActions>
        )}
      </PageWrapper>

      <Popup
        open={resetPopupOpen}
        onClose={() => setResetPopupOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet" displayMode="inline">
          <Header.Actions>
            <ExclamationTriangle color={Color.RED} size={40} />
          </Header.Actions>
          <Text fontSize="h5">
            Do you want to reset all the changes of budget allocation?
          </Text>
          <Header.Subtitle>
            <Box mt="s-16" mb="-s-16">
              You won't be able to revert this action.
            </Box>
          </Header.Subtitle>
        </Header>
        <Popup.Actions horizontal>
          <Button onClick={() => setResetPopupOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              set(values, 'data', cloneDeep(values.initialData))
              set(values, 'changes', [])
              setResetPopupOpen(false)
            }}
            elevated
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>
      <JustificationGuidelines
        isOpen={guidelinesOpen}
        onClose={() => setGuidelinesOpen(false)}
      />
    </>
  )
})

const getChanges = (form: FormData) => {
  return (
    form.data
      ?.filter(row => {
        const initialRow = form.initialData?.find(r => r.id === row.id)
        return (
          initialRow &&
          (initialRow.salary_amount_new !== row.salary_amount_new ||
            initialRow.bonus_amount_new !== row.bonus_amount_new)
        )
      })
      .map(({ id, salary_amount_new, bonus_amount_new, justification }) => ({
        id,
        salary_amount_new,
        bonus_amount_new,
        justification,
      })) || []
  )
}

const transformChangesForSubmit = (form: FormData) => {
  return form.changes.map(change => {
    const payload: EmployeeCompensationReviewsUpdate = {
      id: change.id,
      justification: change.justification,
    }

    const initialRow = form.initialData?.find(r => r.id === change.id)

    if (change.salary_amount_new !== initialRow?.salary_amount_new) {
      payload.salary_amount_new = change.salary_amount_new
      payload.salary_currency_iso_code = initialRow?.salary_currency.iso_code
    }
    if (change.bonus_amount_new !== initialRow?.bonus_amount_new) {
      payload.bonus_amount_new = change.bonus_amount_new
      payload.bonus_currency_iso_code = initialRow?.bonus_currency.iso_code
    }
    return payload
  })
}

const onSubmit = async (form: FormData) => {
  await updateEmployeeCompensationReviews(transformChangesForSubmit(form))

  return Promise.resolve(form)
}

export default () => {
  return (
    <LapeForm<FormData>
      onSubmit={form => onSubmit(form.values)}
      disableValidation
      initialValues={{ data: [], initialData: [], changes: [] }}
    >
      <EditDepartmentBudget />
    </LapeForm>
  )
}
