import React from 'react'
import { Skeleton, VStack } from '@revolut/ui-kit'

export const SlotBannerSkeleton = () => {
  return (
    <VStack gap="s-8" mt="s-8">
      <Skeleton width="70%" />
      <Skeleton width="100%" />
      <Skeleton width="85%" />
    </VStack>
  )
}
