import { HStack, Text, Token } from '@revolut/ui-kit'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  InterviewStatuses,
} from '@src/interfaces/interviewTool'
import React from 'react'
import { AutomatedStageIndicator } from '@src/pages/Forms/Candidate/StagesTable/AutomatedStageIndicator'
import {
  CurrentStage,
  isCurrentStage,
} from '@src/pages/Forms/Candidate/StagesWidget/components/CurrentStageLabel'

type StageTitleProps = {
  round: InterviewRoundInterface
  stage: InterviewStageWithoutRoundInterface
  noCurrentLabel?: boolean
}

export const StageTitle = ({ round, stage, noCurrentLabel }: StageTitleProps) => {
  return (
    <HStack gap="s-6" align="center">
      <Text
        variant="emphasis1"
        wrap="nowrap"
        color={
          stage.scheduling_status === InterviewStatuses.not_started
            ? Token.color.greyTone50
            : undefined
        }
      >
        {stage.title}
      </Text>
      <AutomatedStageIndicator stage={stage} />
      {!noCurrentLabel && isCurrentStage(round, stage) && <CurrentStage />}
    </HStack>
  )
}
