import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useGetReviewStageStats } from '@src/api/reviewCycles'
import { Group, ItemSkeleton, Token } from '@revolut/ui-kit'
import { BaseStatsItem } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/StatsItems'
import React from 'react'
import { makeReviewStatsItemTitleProps } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/constants'

interface Props {
  cycle: ReviewCyclesInterface
}

export const ReviewStats = ({ cycle }: Props) => {
  const { data, isLoading } = useGetReviewStageStats(cycle.id)

  const completedReviewsCount = data ? data.employees_with_reviews : 0
  const totalReviewsCount = data ? data.total_employees : 0
  const notCompletedReviewsCount = totalReviewsCount - completedReviewsCount
  const { completedTitle, notCompletedTitle, noReviewsTitle } =
    makeReviewStatsItemTitleProps(completedReviewsCount, notCompletedReviewsCount)

  if (isLoading) {
    return <ItemSkeleton />
  }

  return (
    <Group>
      {totalReviewsCount === 0 && (
        <BaseStatsItem
          title={noReviewsTitle}
          iconName="DocumentsPair"
          color={Token.color.warning}
          statusIconName="Cross"
        />
      )}
      {completedReviewsCount > 0 && (
        <BaseStatsItem
          title={completedTitle}
          iconName="DocumentsPair"
          color={Token.color.success}
          statusIconName="Check"
        />
      )}
      {notCompletedReviewsCount > 0 && (
        <BaseStatsItem
          title={notCompletedTitle}
          iconName="DocumentsPair"
          color={Token.color.warning}
          statusIconName="Cross"
        />
      )}
    </Group>
  )
}
