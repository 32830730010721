import React from 'react'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { getNewCandidateRequests } from '@src/api/newCandidate'
import NewCandidateGeneral from './General'
import { chain, Link } from '@revolut/ui-kit'
import { Route, Switch } from 'react-router-dom'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { NewCandidateInterface } from '@src/interfaces/newCandidate'
import CandidateFormPreview from '@src/features/CandidateFormPreview/CandidateFormPreview'
import { pathToUrl } from '@src/utils/router'
import { formatDate } from '@src/utils/format'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const NewCandidate = () => {
  const { values } = useLapeContext<NewCandidateInterface>()
  const backUrl = ROUTES.RECRUITMENT.CANDIDATES

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={
          values.id
            ? chain('Sourcing form', values.candidate?.full_name)
            : 'Create a new candidate'
        }
        subtitle={
          values.created_by && values.created_date_time ? (
            <>
              Submitted by{' '}
              <Link
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: values.created_by.id,
                })}
                use={InternalLink}
                target="_blank"
                color="inherit"
              >
                {values.created_by.name}
              </Link>
              , on {formatDate(values.created_date_time)}
            </>
          ) : undefined
        }
        backUrl={backUrl}
      />
      <Switch>
        <Route exact path={ROUTES.FORMS.NEW_CANDIDATE.GENERAL}>
          <NewCandidateGeneral />
        </Route>
        <Route exact path={ROUTES.FORMS.NEW_CANDIDATE.PREVIEW}>
          <CandidateFormPreview />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={getNewCandidateRequests()}>
    <NewCandidate />
  </Form>
))
