import { useGetPerformanceSelector } from '@src/api/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { useGetCycleSelector } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { useMemo } from 'react'

export const useGetEmployeeCyclesSelector = (
  employeeId?: number,
  hideNonGoalTypeProbation?: boolean,
) => {
  const { data: cycles, isLoading } = useGetPerformanceSelector(employeeId, {
    exclude_adhoc: true,
  })

  const filteredCycles = cycles?.filter(cycle => {
    // currently we do not support PIP cycles in goals
    if ([ReviewCycleCategory.PIP, ReviewCycleCategory.PIP_V2].includes(cycle.category)) {
      return false
    }
    if (cycle.category === ReviewCycleCategory.Probation) {
      if (!hideNonGoalTypeProbation) {
        return true
      }
      // only show probation cycles that are of goal type
      return cycle.probation_reviews_deliverables_type === 'goal'
    }
    return true
  })

  const cycleSelector = useGetCycleSelector(filteredCycles)
  const initialCycle =
    // select current cycle initially
    filteredCycles?.find(item => item.offset === 0) ||
    // if user does not have current cycle select the probation cycle
    filteredCycles?.find(item => item.category === ReviewCycleCategory.Probation) ||
    // but as this may fail due to probation not being in 'goal' deliverable type (legacy probation)
    // select cycle closest to current
    filteredCycles?.reduce((closestCycle, currentCycle) => {
      if (currentCycle.offset === undefined) {
        return closestCycle
      }

      if (
        closestCycle.offset === undefined ||
        Math.abs(currentCycle.offset) < Math.abs(closestCycle.offset)
      ) {
        return currentCycle
      }

      return closestCycle
    }, filteredCycles?.[0])

  return useMemo(
    () => ({
      isLoading,
      initialCycle,
      cycleSelector,
      getCycleById: (id: string) =>
        filteredCycles?.find(cycle => String(cycle.id) === String(id)),
    }),
    [initialCycle, cycleSelector, cycles, isLoading, employeeId],
  )
}
