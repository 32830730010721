import isNil from 'lodash/isNil'
import { KpiReviewCycle, KpiTargetEpics, KpiTargets } from '@src/interfaces/kpis'
import { ReviewCyclesInterface, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { useMemo } from 'react'

export interface AvailableCyclesOptions {
  targetIndex: number | null
  allTargets: KpiTargets[] | KpiTargetEpics[]
  probationId?: string
  isEmployeeTemplate?: boolean
}

interface UseAvailableCyclesReturnType {
  availableCycles: ReviewCyclesInterface[]
  defaultCycle?: ReviewCyclesInterface | KpiReviewCycle
  canEditCycle: boolean
  canAddCycle: boolean
}

export const probationCycleCategory = 'probation'

const getAvailableCyclesInfo = (
  thisTarget: KpiTargetEpics | KpiTargets | undefined,
  allTargets: KpiTargetEpics[] | KpiTargets[],
  allCycles: ReviewCyclesInterface[],
): UseAvailableCyclesReturnType => {
  const existingCycleIds = new Set(allTargets.map(t => t.review_cycle?.id))
  const availableCycles = allCycles.filter(
    cycle => cycle.status !== ReviewCycleStatus.closed && !existingCycleIds.has(cycle.id),
  )

  const canAddCycle = !!availableCycles.length

  if (thisTarget) {
    return {
      availableCycles,
      defaultCycle: thisTarget.review_cycle!,
      canEditCycle: allTargets.length < 2,
      canAddCycle,
    }
  }

  if (!canAddCycle) {
    return {
      availableCycles: [],
      defaultCycle: undefined,
      canAddCycle,
      canEditCycle: true,
    }
  }

  return {
    availableCycles,
    defaultCycle:
      availableCycles.find(cycle => cycle.offset === -1) || availableCycles[0],
    canEditCycle: true,
    canAddCycle,
  }
}

const getProbationCyclesInfo = (
  allTargets: KpiTargets[] | KpiTargetEpics[],
  probationId: string,
): UseAvailableCyclesReturnType => {
  const probationCycle = {
    id: probationId,
    name: 'Probation',
    category: probationCycleCategory,
  } as ReviewCyclesInterface

  return {
    availableCycles: [probationCycle],
    defaultCycle: probationCycle,
    canEditCycle: true,
    canAddCycle: !allTargets.length,
  }
}

const getEmployeeTemplateCyclesInfo = (
  thisTarget: KpiTargetEpics | KpiTargets | undefined,
  allTargets: KpiTargetEpics[] | KpiTargets[],
  allCycles: ReviewCyclesInterface[],
): UseAvailableCyclesReturnType => {
  const info = getAvailableCyclesInfo(thisTarget, allTargets, allCycles)

  const probationCycle = {
    id: 0,
    name: 'Probation',
    category: probationCycleCategory,
  } as ReviewCyclesInterface

  return {
    ...info,
    availableCycles: [...info.availableCycles, probationCycle],
    canAddCycle: info.canAddCycle && !allTargets?.[0]?.is_probation,
    defaultCycle: info.defaultCycle,
  }
}

export const getGenericCyclesInfo = (
  reviewCycles: ReviewCyclesInterface[],
  { targetIndex, allTargets, probationId, isEmployeeTemplate }: AvailableCyclesOptions,
): UseAvailableCyclesReturnType => {
  const thisTarget = !isNil(targetIndex) ? allTargets[targetIndex] : undefined

  if (probationId) {
    return getProbationCyclesInfo(allTargets, probationId)
  }
  if (isEmployeeTemplate) {
    return getEmployeeTemplateCyclesInfo(thisTarget, allTargets || [], reviewCycles)
  }
  return getAvailableCyclesInfo(thisTarget, allTargets || [], reviewCycles)
}

const useAvailableCycles = ({
  targetIndex,
  allTargets,
  probationId,
  isEmployeeTemplate,
}: AvailableCyclesOptions): { isLoading: boolean } & UseAvailableCyclesReturnType => {
  const { data: reviewCyclesData, isLoading } = useGetReviewCycles(
    { exclude_adhoc: true },
    {
      disable: !!probationId,
    },
  )
  const reviewCycles = reviewCyclesData?.results || []

  const thisTarget = targetIndex !== null ? allTargets[targetIndex] : undefined

  return useMemo(() => {
    if (probationId) {
      return { isLoading, ...getProbationCyclesInfo(allTargets, probationId) }
    }
    if (isEmployeeTemplate) {
      return {
        isLoading,
        ...getEmployeeTemplateCyclesInfo(thisTarget, allTargets || [], reviewCycles),
      }
    }
    return {
      isLoading,
      ...getAvailableCyclesInfo(thisTarget, allTargets || [], reviewCycles),
    }
  }, [
    isLoading,
    thisTarget,
    reviewCycles,
    allTargets.length,
    allTargets[0],
    probationId,
    isEmployeeTemplate,
  ])
}

export default useAvailableCycles
