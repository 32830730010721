import React from 'react'
import { useParams } from 'react-router-dom'
import { ActionButton, Box, Icon, VStack } from '@revolut/ui-kit'

import { InternalLink } from '@components/InternalLink/InternalLink'
import { DataHandlerInterface } from '@src/components/FormPreview/FormPreview'
import { ROUTES } from '@src/constants/routes'
import { useQuery } from '@src/utils/queryParamsHooks'
import { pathToUrl } from '@src/utils/router'

export type Sidebar =
  | 'workDetails'
  | 'personalDetails'
  | 'contractDetails'
  | 'rightToWorkDetails'

export type SidebarEditMode =
  | 'name'
  | 'position'
  | 'organisation'
  | 'notes'
  | 'bio'
  | 'contact info'
  | 'correspondence address'
  | 'identification details'
  | 'bank details'
  | 'emergency contact'
  | 'laptop delivery'
  | 'about me'
  | 'diversity'

export const useActiveProfileSidebar = () => {
  const params = useParams()
  const { query, changeQueryParam, deleteQueryParam } =
    useQuery<{ sidebar: Sidebar; edit: SidebarEditMode }>(true)

  return {
    sidebar: query.sidebar,
    editMode: query.edit,
    setSidebarQueryParam: (
      sidebar: Sidebar,
      isOpen: boolean,
      editMode?: SidebarEditMode,
    ) => {
      if (!isOpen) {
        deleteQueryParam('sidebar')
        deleteQueryParam('edit')
        return
      }
      if (editMode) {
        changeQueryParam('edit', editMode)
      } else {
        deleteQueryParam('edit')
      }
      changeQueryParam('sidebar', sidebar)
    },
    getUrlWithSidebarParam: (sidebar: Sidebar) =>
      pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, params, { sidebar }),
  }
}

type FormWrapperProps = {
  to: string
}
export const SidebarEditFormWrapper: React.FC<FormWrapperProps> = ({ children, to }) => {
  return (
    <VStack minHeight="75vh">
      <Box mb="s-12">
        <ActionButton
          useIcon={<Icon name="Pencil" size={15} />}
          use={InternalLink}
          to={to}
        >
          Go to edit page
        </ActionButton>
      </Box>
      {children}
    </VStack>
  )
}

export const isLoadingSectionData = <T,>(handler: DataHandlerInterface<T>) =>
  handler.status === 'loading' || handler.isRefetching
