import React from 'react'
import type { ReactNode } from 'react'
import { useRef } from 'react'

import { LineProps, Legend, LabelProps } from 'recharts'
import {
  ReferenceLine,
  XAxis,
  YAxis,
  LineChart as LineChartComponent,
  Tooltip,
  Line,
} from 'recharts'

import type { BoxProps } from '@revolut/ui-kit'
import { Box, Token } from '@revolut/ui-kit'

import { ChartEmptyResults } from './ChartEmptyResults'
import { ChartSkeleton } from './ChartSkeleton'
import { ChartTooltip } from './components/ChartTooltip'
import { getActiveShapeColor, getAxisWidth, getTicks, numberWithCommas } from './helpers'
import type {
  DataKey,
  YAxis as YAxisProps,
  XAxis as XAxisProps,
  ReferenceLine as ReferenceLineProps,
} from './types'

import { ResponsiveContainer } from './ResponsiveContainer'
import { DefaultTooltip } from '@src/pages/Forms/QueryForm/components/Charts/components/DefaultTooltip'
import {
  LINE_STROKE_WIDTH,
  LINE_ANIMATION_DURATION,
  ACTIVE_DOT_RADIUS,
  DOT_RADIUS,
} from '@src/pages/Forms/QueryForm/components/Charts/constants'
import { DefaultLegend } from '@src/pages/Forms/QueryForm/components/Charts/components/DefaultLegend'

const DEFAULT_TICK_COUNT = 6
const Y_AXIS_PADDING = { top: 10, bottom: 10 }
const X_AXIS_PADDING = { left: 25, right: 25 }
const LABELS_PADDING = 8
const TOP_LABEL_SHIFT = -5
const BOTTOM_LABEL_SHIFT = 12

type LineChartProps<T> = Omit<BoxProps, 'ref'> & {
  dataKeys: DataKey<T>[]
  data: T[]
  referenceLines?: ReferenceLineProps[]
  height?: number
  hiddenLines?: number[]
  tickCount?: number
  isLoading?: boolean
  tooltip?: (props: T) => ReactNode
  lineOptions?: Omit<LineProps, 'ref'>
  yAxis?: YAxisProps
  xAxis?: XAxisProps
}

export const LineChart = <T,>({
  dataKeys,
  data,
  tooltip,
  yAxis,
  xAxis,
  lineOptions,
  referenceLines = [],
  height = 300,
  isLoading,
  hiddenLines = [],
  tickCount = DEFAULT_TICK_COUNT,
  ...boxProps
}: LineChartProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const width = containerRef.current?.clientWidth

  const renderLines = dataKeys.filter(key => !hiddenLines.includes(key.id))
  const renderReferenceLines = referenceLines.filter(
    line => !hiddenLines.includes(line.id),
  )

  if (isLoading) {
    return <ChartSkeleton height={height} />
  }

  if (!data.length) {
    return <ChartEmptyResults height={height} />
  }

  const ticksData: number[] = [
    ...renderReferenceLines.map(line => line.value),
    ...renderLines.flatMap(dataKey => data.map(line => Number(line[dataKey.value]))),
  ]
  const ticks = getTicks(ticksData, tickCount, 'line')

  const CustomizedLabel = ({ x, y, value, index }: LabelProps) => {
    if (typeof x !== 'number' || typeof y !== 'number') {
      return null
    }
    let positionShift = TOP_LABEL_SHIFT
    if (index && index > 0) {
      positionShift =
        data[index][dataKeys[0].value] >= data[index - 1][dataKeys[0].value]
          ? TOP_LABEL_SHIFT
          : BOTTOM_LABEL_SHIFT
    }

    return (
      <text
        x={x}
        y={y}
        dy={positionShift}
        fill={Token.color.greyTone50}
        fontSize={10}
        textAnchor="middle"
      >
        {typeof value === 'number'
          ? numberWithCommas(Math.round(Number(value) * 100) / 100)
          : value}
      </text>
    )
  }

  return (
    <Box
      aria-label="Line chart"
      ref={containerRef}
      {...boxProps}
      height="100%"
      width="100%"
    >
      <ResponsiveContainer height="100%" width="100%">
        <LineChartComponent
          width={width}
          height={height}
          data={data}
          style={{ cursor: 'pointer' }}
        >
          {xAxis !== false && (
            <XAxis
              dataKey="label"
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
              tick={{ color: Token.color.greyTone50, fontSize: 12 }}
              padding={X_AXIS_PADDING}
              dy={LABELS_PADDING}
              allowDuplicatedCategory={false}
              {...xAxis}
            />
          )}

          {yAxis !== false && (
            <YAxis
              type="number"
              orientation="left"
              width={getAxisWidth(ticks, yAxis?.tickFormatter)}
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
              tickCount={tickCount}
              tick={{ color: Token.color.greyTone50, fontSize: 12 }}
              padding={Y_AXIS_PADDING}
              dx={-LABELS_PADDING}
              domain={['dataMin', 'dataMax']}
              ticks={ticks}
              {...yAxis}
            />
          )}

          <Tooltip
            cursor={{ stroke: Token.color.foreground, strokeWidth: 1 }}
            content={
              <ChartTooltip<T> tooltip={tooltip || DefaultTooltip} dataKeys={dataKeys} />
            }
          />

          {renderReferenceLines.map(line => (
            <ReferenceLine
              key={line.id}
              ifOverflow="extendDomain"
              y={line.value}
              label={
                typeof line.label === 'object'
                  ? {
                      fontSize: 12,
                      fill: getActiveShapeColor(line.color),
                      position: 'insideTopLeft',
                      ...line.label,
                    }
                  : line.label
              }
              stroke={getActiveShapeColor(line.color)}
              strokeDasharray="3 3"
            />
          ))}

          {renderLines.map(dataKey => (
            <Line
              key={dataKey.id}
              type="linear"
              dot={{
                r: DOT_RADIUS,
                fill: dataKey.color ? getActiveShapeColor(dataKey.color) : undefined,
              }}
              activeDot={{
                r: ACTIVE_DOT_RADIUS,
                stroke: Token.color.white,
              }}
              label={<CustomizedLabel />}
              animationDuration={LINE_ANIMATION_DURATION}
              dataKey={dataKey.value as string}
              strokeWidth={LINE_STROKE_WIDTH}
              stroke={dataKey.color ? getActiveShapeColor(dataKey.color) : undefined}
              {...lineOptions}
            />
          ))}

          <Legend
            content={props => <DefaultLegend props={props} dataKeys={dataKeys} />}
            wrapperStyle={{
              paddingTop: '10px',
            }}
          />
        </LineChartComponent>
      </ResponsiveContainer>
    </Box>
  )
}
