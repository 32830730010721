import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { ItemSkeleton } from '@revolut/ui-kit'
import { BaseStatsItem } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/StatsItems'
import React from 'react'
import { makePublishItemProps } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/constants'
import { usePublishStats } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/hooks/usePublishStats'

interface PublishStatsProps {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage.ManagersPublish | ReviewCycleStage.EmployeesPublish
}

export const PublishStats = ({ cycle, stage }: PublishStatsProps) => {
  const { totalCount, completedCount, isLoading } = usePublishStats(cycle)

  const { title, description, iconName } = makePublishItemProps(
    stage,
    totalCount,
    completedCount,
  )

  if (isLoading) {
    return <ItemSkeleton />
  }

  return <BaseStatsItem title={title} description={description} iconName={iconName} />
}
