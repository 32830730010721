import { useRef, useState } from 'react'
import set from 'lodash/set'

import { ApprovalChainFieldErrors } from '@src/pages/Forms/TimeOffPolicies/components/PolicyApprovalChainInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PolicyApprover, TimeOffPolicyInterface } from '@src/interfaces/timeOff'

const hasEmptyValue = (approver: PolicyApprover) => {
  if (approver.approver_type?.id === 'dynamic_group') {
    return !approver.groups?.length
  }
  if (approver.approver_type?.id === 'employee') {
    return !approver.employee
  }
  if (approver.approver_type?.id === 'relationship') {
    return !approver.relationship
  }

  return false
}

export const usePolicyApprovalChain = () => {
  const { values } = useLapeContext<TimeOffPolicyInterface>()

  const [approvalChainErrors, setApprovalChainErrors] =
    useState<ApprovalChainFieldErrors>()
  const approversChainInputRef = useRef<HTMLDivElement>(null)

  const handlePolicyApproversErrors = (newValue: ApprovalChainFieldErrors) => {
    setApprovalChainErrors(newValue)
    approversChainInputRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
    return Promise.reject()
  }

  const handlePolicyApproversValue = () => {
    if (values.is_approval_required && !values.policy_approvers?.length) {
      return handlePolicyApproversErrors({ noApproversSelected: true })
    }
    if (!values.is_approval_required && values.policy_approvers?.length) {
      values.policy_approvers = []
    }
    if (values.policy_approvers?.length) {
      const newApprovers = values.policy_approvers
        .map((approver, idx) => {
          if (!approver.approver_type) {
            return undefined
          }
          if (approver.approver_type.id === 'dynamic_group') {
            set(
              approver,
              'groups',
              approver.groups?.length ? approver.groups.slice(0, 1) : [],
            )
          }
          approver.sort_order = idx

          return approver
        })
        .filter(Boolean)

      const errors: ApprovalChainFieldErrors = { emptyFieldValueIndexes: [] }
      newApprovers.forEach((approver, idx) => {
        if (hasEmptyValue(approver)) {
          errors.emptyFieldValueIndexes!.push(idx)
        }
      })

      if (errors.emptyFieldValueIndexes?.length) {
        return handlePolicyApproversErrors(errors)
      }
      values.policy_approvers = newApprovers
    }
    return Promise.resolve()
  }

  return {
    approvalChainErrors,
    setApprovalChainErrors,
    handlePolicyApproversValue,
    approversChainInputRef,
  }
}
