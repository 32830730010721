import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { selectUser } from '@src/store/auth/selectors'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export const useMountHiringStage = (isSpecialisation?: boolean) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const user = useSelector(selectUser)
  useEffect(() => {
    if (!values.owner && user) {
      values.owner = {
        id: user.id,
        full_name: user.full_name,
      }
    }
    if (!values.duration_unit) {
      values.duration_unit = {
        id: 'minutes',
        name: 'Minutes',
      }
    }
  }, [])
  const isOnlineTest = values.stage_type?.id === 'online_test'
  const isCVScreening = values.stage_type?.id === 'cv_screening'

  // @TODO: remove this constraint after we test this approach for 1 platform and when BE is ready
  const isHireVue = values.test_platform?.id === 'hirevue'
  const isLastStage =
    values?.is_company_standard_round ||
    values?.limit_editing_in_specialisation ||
    isSpecialisation

  useEffect(() => {
    if (!isHireVue) {
      values.send_test_automatically = false
      values.archive_candidate_automatically = false
      delete values.send_auto_email_template
      delete values.online_test_pass_score
      delete values.archive_auto_email_template
      delete values.auto_archival_reason
    }
  }, [isHireVue])

  return {
    isOnlineTest,
    isCVScreening,
    isLastStage,
    isHireVue,
  }
}
