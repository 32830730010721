import { API } from '@src/constants/api'
import {
  GetRequestData,
  RequestInterfaceNew,
  UseFetchResult,
  WithOptionalId,
} from '@src/interfaces'
import {
  CalibrationFlag,
  DeliverableCategory,
  DeliverableRatingSetting,
  Grade,
  PerformanceScorecardSectionInterface,
  PerformanceSettingsInterface,
} from '@src/interfaces/settings'
import { api, apiWithoutHandling } from '.'
import { useFetch, useFetchV2, useUpdateV2 } from '@src/utils/reactQuery'
import { EligibleGroupInterface } from '@src/interfaces/reviewCycles'
import { AxiosPromise } from 'axios'

export const performanceSettings: RequestInterfaceNew<PerformanceSettingsInterface> = {
  get: async () => api.get(API.PERFORMANCE_SETTINGS),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.PERFORMANCE_SETTINGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.PERFORMANCE_SETTINGS, data),
}

/** `/1` is hardcoded on the BE */
export const performanceCustomScorecardSections: RequestInterfaceNew<PerformanceScorecardSectionInterface> =
  {
    get: async () => api.get(`${API.PERFORMANCE_SETTINGS}/1/customScorecardSections`),
    update: async data => apiWithoutHandling.patch(`${API.PERFORMANCE_SETTINGS}/1`, data),
    submit: async data => apiWithoutHandling.post(API.PERFORMANCE_SETTINGS, data),
  }

export const useGetPerformanceSettings = ({
  cacheTime,
  staleTime,
}: { cacheTime?: number; staleTime?: number } = {}) =>
  useFetch<PerformanceSettingsInterface>(
    API.PERFORMANCE_SETTINGS,
    undefined,
    undefined,
    true,
    {
      staleTime: typeof staleTime !== 'undefined' ? staleTime : Infinity,
      cacheTime: typeof cacheTime !== 'undefined' ? cacheTime : Infinity,
    },
  )

interface GlobalEligibleGroupsListInterface {
  eligibility_groups: EligibleGroupInterface[]
}

type UseGetGlobalEligibleGroupsInfoReturnType = UseFetchResult<{
  results: EligibleGroupInterface[]
}>

export const useGetGrades = () =>
  useFetchV2<GetRequestData<Grade>>({ url: '/grades', version: 'v2' })

export const useUpdateGrade = () =>
  useUpdateV2<Grade, Pick<Grade, 'label' | 'goal_performance_percent'>>({
    url: '/grades',
    version: 'v2',
  })

export const useGetCalibrationFlags = () =>
  useFetchV2<GetRequestData<CalibrationFlag>>({
    url: `${API.PERFORMANCE}/calibrationFlags`,
    version: 'v2',
    queryOptions: { cacheTime: Infinity, staleTime: Infinity }, // They shouldn't ever changed. Used for mapping
  })

export const useGetDeliverablesCategories = () =>
  useFetchV2<GetRequestData<DeliverableCategory>>({
    url: '/deliverablesCategory',
    version: 'v2',
  })

export const useUpdateDeliverableCategory = () =>
  useUpdateV2<DeliverableCategory, Pick<DeliverableCategory, 'label'>>({
    url: '/deliverablesCategory',
    version: 'v2',
  })

export const useUpdateDeliverableRating = () =>
  useUpdateV2<DeliverableRatingSetting, Pick<DeliverableRatingSetting, 'description'>>({
    url: '/deliverablesRating',
    version: 'v2',
  })

export const useGetGlobalEligibleGroupsInfo =
  (): UseGetGlobalEligibleGroupsInfoReturnType => {
    const context = useFetch<GlobalEligibleGroupsListInterface>({
      url: `${API.PERFORMANCE_SETTINGS}/1/globalEligibilityGroups`,
      version: 'v1',
      queryOptions: { staleTime: 1000 * 60 * 5 }, // 5 minutes
    })

    if (context.data && context.data.eligibility_groups) {
      return {
        ...context,
        data: { results: context.data.eligibility_groups }, // for backward compatability
      } as UseGetGlobalEligibleGroupsInfoReturnType
    }

    return context as UseGetGlobalEligibleGroupsInfoReturnType
  }

export const useGetCustomScorecardSections = () => {
  return useFetch<{ custom_scorecard_sections: PerformanceScorecardSectionInterface[] }>(
    `${API.PERFORMANCE_SETTINGS}/1/customScorecardSections`,
  )
}

export const globalEligiblityGroups: RequestInterfaceNew<EligibleGroupInterface> = {
  get: async params => {
    const response = await api.get<GlobalEligibleGroupsListInterface>(
      `${API.PERFORMANCE_SETTINGS}/1/globalEligibilityGroups`,
      undefined,
      'v1',
    )

    const result = response.data?.eligibility_groups
      ? {
          ...response,
          data: response.data?.eligibility_groups.find(
            ({ id }) => id === Number(params.id),
          ),
        }
      : response

    return result as unknown as AxiosPromise<EligibleGroupInterface>
  },
  update: async () =>
    // eslint-disable-next-line prefer-promise-reject-errors
    Promise.reject(
      'form update for global groups are not supported, please use updateGlobalEligiblityGroups',
    ),
  submit: async () =>
    // eslint-disable-next-line prefer-promise-reject-errors
    Promise.reject(
      'form submit for global groups are not supported, please use updateGlobalEligiblityGroups',
    ),
}

export const getGlobalEligibilityMutator = (groups: EligibleGroupInterface[]) => ({
  submit: async (updated: WithOptionalId<EligibleGroupInterface>) => {
    if (updated.id) {
      await updateGlobalEligiblityGroups(
        groups.map(existing => (existing.id === updated.id ? updated : existing)),
      )
    }
    await updateGlobalEligiblityGroups([...groups, updated])
  },
  delete: async (groupId: number) => {
    await updateGlobalEligiblityGroups(groups.filter(({ id }) => id !== groupId))
  },
})

export const updateGlobalEligiblityGroups = (
  groups: WithOptionalId<EligibleGroupInterface>[],
): AxiosPromise<PerformanceSettingsInterface> => {
  return api.patch<PerformanceSettingsInterface>(
    `${API.PERFORMANCE_SETTINGS}/1`,
    { eligibility_groups: groups },
    undefined,
    'v1',
  )
}

export const updateAdditionalScorecardSections = (
  sections?: PerformanceScorecardSectionInterface[],
): AxiosPromise<PerformanceSettingsInterface> => {
  return api.patch<PerformanceSettingsInterface>(
    `${API.PERFORMANCE_SETTINGS}/1`,
    { custom_scorecard_sections: sections },
    undefined,
    'v1',
  )
}
