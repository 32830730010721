import React from 'react'
import { TextButton } from '@revolut/ui-kit'

import Tooltip from '@src/components/Tooltip/Tooltip'
import { ConfigurableField } from '../helpers'

export const getAddFieldButtonProps = (
  entityName: string,
  field: ConfigurableField,
  addedFields: ConfigurableField[],
) => {
  if (entityName === 'contract' && field.column_name === 'subseniority') {
    return {
      disabled: !addedFields.some(f => f.column_name === 'seniority'),
    }
  }
  return { disabled: false }
}

interface WithLimitedFieldsAmountProps {
  addedFieldsNum: number
  children: React.ReactNode
}
export const WithLimitedFieldsAmount = ({
  addedFieldsNum,
  children,
}: WithLimitedFieldsAmountProps) => {
  const limit = 50

  if (addedFieldsNum >= limit) {
    return (
      <Tooltip
        placement="left"
        text={`Maximum allowed amount of fields to add is ${limit}`}
      >
        <TextButton disabled>Add</TextButton>
      </Tooltip>
    )
  }
  return <>{children}</>
}
