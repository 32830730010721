import React from 'react'
import MenuAction, { MenuActionType } from '@components/MenuAction/MenuAction'
import { DropdownItemProps } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { duplicateStage } from '@src/api/recruitment/interviews'
import { getErrorMessageFromError } from '@src/store/notifications/actions'

interface Props {
  onLoading: (loading: boolean) => void
  onClose: VoidFunction
  onRefresh?: VoidFunction
  roundId: number
  stageId: number
  itemProps: DropdownItemProps
  menuType: MenuActionType
}

export const DuplicateStageAction = ({
  menuType,
  roundId,
  stageId,
  onRefresh,
  itemProps,
  onLoading,
  onClose,
}: Props) => {
  const showStatusPopup = useShowStatusPopup()

  const onClick = async () => {
    onLoading(true)

    try {
      await duplicateStage(roundId, stageId)
      onClose()
      onRefresh?.()
    } catch (error) {
      showStatusPopup({
        title: 'Cannot add new interview round',
        description: getErrorMessageFromError(error),
        status: 'error',
      })
    } finally {
      onLoading(false)
    }
  }

  return (
    <MenuAction
      menuType={menuType}
      props={{
        ...itemProps,
        onClick,
      }}
    >
      Add another interview round
    </MenuAction>
  )
}
