import React from 'react'
import { FilterButton, MoreBar, Token } from '@revolut/ui-kit'

import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { useTable } from '@components/TableV2/hooks'
import { getAllScreeningChecks, getAllScreeningChecksStats } from '@src/api/screening'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import {
  AllScreeningChecksInterface,
  AllScreeningChecksStatsInterface,
} from '@src/interfaces/screeningChecksInterface'
import {
  screeningChecksCompletedOnColumn,
  screeningChecksCreatedByColumn,
  screeningChecksApproverColumn,
  screeningChecksCreatedOnColumn,
  screeningChecksEmployeeColumn,
  screeningChecksLastUpdateColumn,
  screeningChecksNameColumn,
  screeningChecksSourceColumn,
  screeningChecksStatusColumn,
} from '@src/constants/columns/ScreeningChecks'
import Table from '@src/components/TableV2/Table'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'
import { useAssignedToMeFilter } from '@src/features/Screening/hooks'

const ROW: RowInterface<AllScreeningChecksInterface> = {
  cells: [
    {
      ...screeningChecksEmployeeColumn,
      width: 220,
    },
    {
      ...screeningChecksNameColumn,
      width: 220,
    },
    {
      ...screeningChecksSourceColumn,
      width: 150,
    },
    {
      ...screeningChecksApproverColumn,
      width: 240,
    },
    {
      ...screeningChecksCreatedByColumn,
      width: 240,
    },
    {
      ...screeningChecksCreatedOnColumn,
      width: 180,
    },
    {
      ...screeningChecksLastUpdateColumn,
      width: 180,
    },
    {
      ...screeningChecksCompletedOnColumn,
      width: 140,
    },
    {
      ...screeningChecksStatusColumn,
      width: 180,
    },
  ],
}

const statsConfig: StatsConfig<AllScreeningChecksStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'pending_adjudication',
    title: 'Pending adjudication',
    filterKey: 'pending_adjudication',
    color: Token.color.orange,
  },
  {
    key: 'in_progress',
    title: 'In progress',
    filterKey: 'in_progress',
    color: Token.color.orange,
  },
  {
    key: 'pending_completion',
    title: 'Pending completion',
    filterKey: 'pending_completion',
    color: Token.color.orange,
  },
  {
    key: 'completed',
    title: 'Completed',
    filterKey: 'completed',
    color: Token.color.green,
  },
  {
    key: 'canceled',
    title: 'Cancelled',
    filterKey: 'canceled',
    color: Token.color.red,
  },
  {
    key: 'expired',
    title: 'Expired',
    filterKey: 'expired',
    color: Token.color.greyTone50,
  },
  {
    key: 'failed',
    title: 'Failed',
    filterKey: 'failed',
    color: Token.color.red,
  },
  {
    key: 'other',
    title: 'Other',
    filterKey: 'other',
    color: Token.color.greyTone50,
  },
]

export const ScreeningsTable = () => {
  const initialSortBy = [
    {
      sortBy: 'seniority',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const table = useTable<AllScreeningChecksInterface, AllScreeningChecksStatsInterface>(
    {
      getItems: getAllScreeningChecks,
      getStats: getAllScreeningChecksStats,
    },
    undefined,
    initialSortBy,
  )
  const statFiltersProps = useSelectableTableStats<
    AllScreeningChecksInterface,
    AllScreeningChecksStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
    totalKey: 'total',
  })

  const handleTriggerScreeningCheck = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.SCREENING.SELECT))
  }

  const handleRowClick = (check: AllScreeningChecksInterface) => {
    if (check.type.id === 'hireright') {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SCREENING.HIRERIGHT.PREVIEW, {
          employeeId: check.employee.id,
          id: check.screening_id,
        }),
      )
    } else if (check.type.id === 'manual') {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SCREENING.MANUAL.PREVIEW, {
          employeeId: check.employee.id,
          id: check.screening_id,
        }),
      )
    }
  }

  const { showAssignedToMe, onToggleAssignedToMe } = useAssignedToMeFilter<
    AllScreeningChecksInterface,
    AllScreeningChecksStatsInterface
  >(table)

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <StatFilters {...statFiltersProps} />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <MoreBar.Action onClick={handleTriggerScreeningCheck} useIcon="Plus">
            Add new
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <FilterButton onClick={onToggleAssignedToMe} active={showAssignedToMe}>
          Assigned to me
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<AllScreeningChecksInterface, AllScreeningChecksStatsInterface>
          hideCount
          name={TableNames.Screenings}
          useWindowScroll
          dataType="Screening Check"
          row={ROW}
          {...table}
          noDataMessage="Screening checks will appear here"
          onRowClick={handleRowClick}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
