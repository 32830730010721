import React, { useMemo } from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Item,
  useToggle,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { SkillPreview } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/SkillPreview'
import { SkillSidebar } from '@src/features/JobPostingFlow/HiringProcess/Scorecard/SkillsSidebar'
import uniq from 'lodash/uniq'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'

export const AddSkills = () => {
  const { initialValues } = useLapeContext<InterviewScorecardTemplateInterface>()
  const [openSkillsSidebar, toggleOpenSkillsSidebar] = useToggle()

  const { skillSections, skillIds } = useMemo(() => {
    const sections = (initialValues.sections || []).filter(
      ({ skills }) => !!skills?.length,
    )
    return {
      skillSections: sections,
      skillIds: uniq(sections.flatMap(({ skills }) => skills).map(opt => opt?.id)),
    }
  }, [initialValues.sections])

  return (
    <>
      <Widget mb="s-24">
        <VStack gap="s-16">
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Palette" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Skills</Item.Title>
            </Item.Content>
          </Item>
          {!!skillSections.length && (
            <VStack px="s-16" pb="s-16" gap="s-16">
              {skillSections.map(section => (
                <SkillPreview key={section.id} section={section} />
              ))}
            </VStack>
          )}
          <Box px="s-16" pb="s-16">
            <ActionButton useIcon="Plus" onClick={toggleOpenSkillsSidebar.switch}>
              Add skills
            </ActionButton>
          </Box>
        </VStack>
      </Widget>
      {openSkillsSidebar && (
        <SkillSidebar skillIds={skillIds} onClose={toggleOpenSkillsSidebar} />
      )}
    </>
  )
}
