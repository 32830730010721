import React from 'react'
import {
  chain,
  Flex,
  Text,
  Token,
  useTooltip,
  VStack,
  Tooltip as UIKitTooltip,
} from '@revolut/ui-kit'
import {
  PerformanceRating,
  ReviewDataInterface,
  ReviewDataSectionInterface,
  SkillCardInterface,
  ValueBasedCardInterface,
  ReviewSummaryDataInterface,
  Ratings,
  ReviewerRelationToShortTitle,
  SkillSummary,
  SummarySkillCardInterface,
  DeliverableOptions,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
  performanceRatingToFinalGrade,
  PerformanceKPISection,
  FinalGrade,
  GradeLabelMappingInterface,
  ReviewCalculatedDeliverablesRatingsInterface,
  SummaryCalculatedDeliverablesRatingsInterface,
} from '@src/interfaces/performance'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  performanceSummaryRatingColumn,
  performanceSummaryDeliverablesNameColumn,
  performanceSummaryExpectedRatingColumn,
  performanceSummarySkillsNameColumn,
  performanceSummaryExpectedCalcRatingColumn,
  performanceSummaryCalculatedRatingColumn,
} from '@src/constants/columns/performanceSummary'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { tooltipRatingToColor } from '@src/utils/performance'
import { GradesMapInterface, useGetReviewGradesMap } from '@src/utils/grades'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetSegmentedDeliverablesEnabled } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { GoalsSummaryTable } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/GoalsSummaryTable'
import { useGetPerformanceSelector } from '@src/api/performance'
import { ContributorType } from '@src/interfaces/talent/performance'
import { TableNames } from '@src/constants/table'
import { isEmpty, isNumber, isObject } from 'lodash'

interface Props {
  reviewData: ReviewDataInterface | ReviewSummaryDataInterface
  reviewValues?: ReviewSummaryInterface
  isPreviousValues?: boolean
  hideExpectations?: boolean
  cycleId?: string | number
}

export interface KpiRowInterface extends KpiInterface {
  rating_expectation: PerformanceRating
  recommended_rating: DeliverableOptions
}

const RatingWithTooltip = ({
  getTitle,
  rating,
  ratings,
  showAsText,
}: {
  getTitle: (rating: PerformanceRating) => string
  rating?: PerformanceRating
  ratings?: Ratings[]
  showAsText?: boolean
}) => {
  const ratingTooltip = useTooltip()
  if (!rating) {
    return <Text variant="h6">-</Text>
  }
  return (
    <Flex
      gap="s-8"
      {...ratingTooltip.getAnchorProps()}
      width="fit-content"
      style={{ cursor: 'pointer' }}
    >
      <Text variant="caption" textDecoration="underline">
        {getTitle(rating)}
      </Text>
      {showAsText && (
        <Text variant="heading3" data-testid="card-result">
          {getTitle(rating)}
        </Text>
      )}

      <UIKitTooltip {...ratingTooltip.getTargetProps()} placement="top">
        <VStack>
          {ratings?.map(item => (
            <Flex key={item.review.id}>
              {chain(
                <Text variant="h6" color={tooltipRatingToColor(item.value)}>
                  {getTitle(item.value)}
                </Text>,
                <Text>{item.review.reviewer.full_name}</Text>,
                <Text color={Token.color.greyTone50}>
                  {ReviewerRelationToShortTitle[item.review.reviewer_relation]}
                </Text>,
              )}
            </Flex>
          ))}
        </VStack>
      </UIKitTooltip>
    </Flex>
  )
}

const getRatingWithTooltip = (
  getGrade: boolean,
  rating?: PerformanceRating,
  ratings?: Ratings[],
  gradesMap?: GradesMapInterface,
) => {
  return (
    <RatingWithTooltip
      getTitle={perfRating => {
        return getGrade
          ? gradesMap?.[performanceRatingToFinalGrade(perfRating)] || '-'
          : PerformanceRatingTitle[perfRating]
      }}
      rating={rating}
      ratings={ratings}
    />
  )
}

const getRatingColumnValue = (
  data:
    | ReviewDataSectionInterface
    | SkillCardInterface
    | ValueBasedCardInterface
    | SummarySkillCardInterface,
) => {
  if (data.rating) {
    return PerformanceRatingTitle[data.rating]
  }
  return '-'
}

const getGradeColumnValue = (
  data:
    | ReviewDataSectionInterface
    | SkillCardInterface
    | ValueBasedCardInterface
    | SummarySkillCardInterface,
  gradesMap: GradesMapInterface,
) => {
  return data.rating ? gradesMap[performanceRatingToFinalGrade(data.rating)] : '-'
}

const getRecommendedRatingColumnValue = (
  data:
    | KpiRowInterface
    | ReviewCalculatedDeliverablesRatingsInterface
    | SummaryCalculatedDeliverablesRatingsInterface,
) => {
  if (isObject(data.recommended_rating)) {
    return getRatingWithTooltip(
      false,
      data.recommended_rating.rating || undefined,
      data.recommended_rating.ratings,
    )
  }
  return data.recommended_rating ? PerformanceRatingTitle[data.recommended_rating] : '-'
}

const getRatingSummaryColumnValue = (
  data: SkillSummary | SummarySkillCardInterface | KpiRowInterface,
) => {
  return getRatingWithTooltip(false, data.rating || undefined, data.ratings, undefined)
}

const getGradeSummaryColumnValue = (
  data:
    | SkillSummary
    | SummarySkillCardInterface
    | KpiRowInterface
    | PerformanceKPISection,
  gradesMap: GradesMapInterface,
) => {
  return getRatingWithTooltip(true, data.rating || undefined, data.ratings, gradesMap)
}

const getDeliverablesRow: (
  gradesMap: GradesMapInterface,
  showSegmented?: boolean,
  isSummaryReview?: boolean,
) => RowInterface<SkillCardInterface | SummarySkillCardInterface> = (
  gradesMap,
  showSegmented,
  isSummaryReview,
) => ({
  cells: [
    {
      ...performanceSummaryDeliverablesNameColumn,
      insert: ({ data }) => (showSegmented ? data.name : 'Contribution and impact'),
      width: 100,
    },
    {
      ...performanceSummaryRatingColumn,
      insert: ({ data }) =>
        'ratings' in data
          ? getGradeSummaryColumnValue(data, gradesMap)
          : getGradeColumnValue(data, gradesMap),
      title: isSummaryReview ? 'Grade' : 'Latest grade',
      width: 60,
    },
  ],
})

const getCalcDeliverablesRow: (
  isSummaryReview?: boolean,
) => RowInterface<
  | ReviewCalculatedDeliverablesRatingsInterface
  | SummaryCalculatedDeliverablesRatingsInterface
> = isSummaryReview => ({
  cells: [
    {
      ...performanceSummaryDeliverablesNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryCalculatedRatingColumn,
      insert: ({ data }) => getRecommendedRatingColumnValue(data),
      title: isSummaryReview ? 'Rating' : 'Latest rating',
      width: 60,
    },
    {
      ...performanceSummaryExpectedCalcRatingColumn,
      width: 60,
    },
  ],
})

const getSkillsRow: (
  isSummaryReview?: boolean,
) => RowInterface<SkillCardInterface | SummarySkillCardInterface> = isSummaryReview => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryRatingColumn,
      insert: ({ data }) =>
        'ratings' in data
          ? getRatingSummaryColumnValue(data)
          : getRatingColumnValue(data),
      title: isSummaryReview ? 'Rating' : 'Latest rating',
      width: 60,
    },
    {
      ...performanceSummaryExpectedRatingColumn,
      width: 60,
    },
  ],
})

const getValuesRow: (
  isSummaryReview?: boolean,
) => RowInterface<
  SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
> = isSummaryReview => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      width: 100,
    },
    {
      ...performanceSummaryRatingColumn,
      insert: ({ data }) =>
        'ratings' in data
          ? getRatingSummaryColumnValue(data)
          : getRatingColumnValue(data),
      title: isSummaryReview ? 'Rating' : 'Latest rating',
      width: 60,
    },
  ],
})

export const TableWrapper = ({
  children,
  headerTitle,
  headerStat,
  headerRating,
  headerGrade,
  gradesMap,
  completedReviews,
}: {
  children: React.ReactNode
  headerTitle: string
  headerStat?: number
  headerRating?: PerformanceRating | null
  headerGrade?: FinalGrade
  gradesMap?: GradesMapInterface
  completedReviews?: boolean
}) => {
  return (
    <VStack space="s-16" data-testid={`card-table-${headerTitle}`}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="h6" color={Token.color.greyTone50}>
          {chain(
            headerTitle,
            isNumber(headerStat) ? (
              <Text color={getPercentColor(headerStat * 100)}>
                {formatPercentage(headerStat)}
              </Text>
            ) : undefined,
          )}
        </Text>
        {completedReviews && headerRating && (
          <Text variant="h6" data-testid="card-result">
            {PerformanceRatingTitle[headerRating]}
          </Text>
        )}
        {completedReviews && headerGrade && gradesMap && (
          <Text variant="h6">{gradesMap[headerGrade]}</Text>
        )}
      </Flex>
      <Flex style={{ position: 'relative', fontWeight: 400 }} flex="1 0">
        {children}
      </Flex>
    </VStack>
  )
}

export const SummaryReviewTables = ({
  cycleId,
  reviewData,
  reviewValues,
  isPreviousValues,
  hideExpectations,
}: Props) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const { gradesMap: newGradesMap } = useGetReviewGradesMap()
  const summaryValues = reviewValues || values
  const gradesMap = summaryValues.grade_label_mapping || newGradesMap
  const isSummaryReview = 'ratings' in reviewData
  const isSegmentedDeliverables = useGetSegmentedDeliverablesEnabled()
  const { data: cycles } = useGetPerformanceSelector(
    'reviews' in summaryValues
      ? summaryValues.reviews[0]?.reviewed_employee.id
      : summaryValues.reviewed_employee.id,
  )

  const employeeId =
    'reviews' in summaryValues
      ? summaryValues.reviews[0]?.reviewed_employee.id
      : summaryValues.reviewed_employee.id

  const calcDeliverablesData =
    reviewData.calculated_deliverables_ratings &&
    !isEmpty(reviewData.calculated_deliverables_ratings)
      ? [reviewData.calculated_deliverables_ratings]
      : undefined
  let deliverablesData
  if (reviewData.deliverables?.cards?.[0]) {
    deliverablesData = isSegmentedDeliverables
      ? reviewData.deliverables?.cards
      : [reviewData.deliverables.cards[0]]
  }

  const selectedPeriod = Array.isArray(cycles)
    ? cycles.find(({ id }) => id === cycleId)
    : null

  const skillsData = reviewData.functional_skills?.cards
  const managerSkillsData = reviewData.manager_skills?.cards
  const managerValuesData = reviewData.manager_values?.cards
  const cultureValuesData = reviewData.culture_values?.cards
  const cultureSkillsData = reviewData.culture_skills?.cards

  const getAdjustedTitle = (title: string) => {
    if (isPreviousValues) {
      const cycleName =
        'reviews' in summaryValues
          ? summaryValues.reviews[0]?.cycle?.name
          : summaryValues.cycle?.name
      return `${title}${cycleName ? ` (${cycleName})` : ''}`
    }
    return title
  }

  const calculatedDeliverablesRating =
    reviewData.calculated_deliverables_ratings?.recommended_rating ||
    reviewData?.deliverables?.rating

  return (
    <VStack space="s-24">
      {calcDeliverablesData && (
        <TableWrapper
          headerTitle="Goals and deliverables"
          headerRating={
            isObject(calculatedDeliverablesRating)
              ? calculatedDeliverablesRating.rating
              : calculatedDeliverablesRating
          }
          completedReviews={isSummaryReview}
        >
          <AdjustableTable
            name={TableNames.PerformanceSummaryDeliverables}
            row={getCalcDeliverablesRow(isSummaryReview)}
            count={calcDeliverablesData.length}
            data={calcDeliverablesData}
            hideCountAndButtonSection
            hiddenCells={{
              [performanceSummaryExpectedCalcRatingColumn.idPoint]: hideExpectations,
            }}
          />
        </TableWrapper>
      )}
      {!isPreviousValues && selectedPeriod && (
        <GoalsSummaryTable
          employee={{
            id: employeeId,
          }}
          selectedPeriod={selectedPeriod}
          reviewSummary={{
            team: summaryValues.team,
            grade_label_mapping:
              summaryValues.grade_label_mapping as GradeLabelMappingInterface,
            reviewed_employee_type:
              summaryValues.reviewed_employee_type as ContributorType,
          }}
        />
      )}
      {deliverablesData && !isPreviousValues && (
        <TableWrapper
          headerTitle="Goals"
          headerGrade={
            reviewData.deliverables?.rating
              ? performanceRatingToFinalGrade(reviewData.deliverables?.rating)
              : undefined
          }
          gradesMap={gradesMap}
          completedReviews={isSummaryReview}
        >
          <AdjustableTable
            name={TableNames.PerformanceSummaryDeliverables}
            row={getDeliverablesRow(gradesMap, isSegmentedDeliverables, isSummaryReview)}
            count={deliverablesData.length}
            data={deliverablesData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}

      {skillsData && (
        <TableWrapper
          headerTitle={getAdjustedTitle('Functional skills')}
          headerRating={reviewData.functional_skills?.rating || undefined}
          completedReviews={isSummaryReview}
        >
          <AdjustableTable<SkillCardInterface | SummarySkillCardInterface>
            name={TableNames.PerformanceSummarySkills}
            row={getSkillsRow(isSummaryReview)}
            count={skillsData.length}
            data={skillsData}
            hideCountAndButtonSection
            hiddenCells={{
              [performanceSummaryExpectedRatingColumn.idPoint]: hideExpectations,
            }}
          />
        </TableWrapper>
      )}

      {managerSkillsData && (
        <TableWrapper
          headerTitle={getAdjustedTitle('Manager skills')}
          headerRating={reviewData.manager_skills?.rating || undefined}
          completedReviews={isSummaryReview}
        >
          <AdjustableTable<SkillCardInterface | SummarySkillCardInterface>
            name={TableNames.PerformanceSummaryManagerSkills}
            row={getSkillsRow(isSummaryReview)}
            hiddenCells={{
              [performanceSummaryExpectedRatingColumn.idPoint]: true,
            }}
            count={managerSkillsData.length}
            data={managerSkillsData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}

      {managerValuesData && (
        <TableWrapper
          headerTitle={getAdjustedTitle('Management values')}
          headerRating={reviewData.manager_values?.rating || undefined}
          completedReviews={isSummaryReview}
        >
          <AdjustableTable<
            SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
          >
            name={TableNames.PerformanceSummaryValues}
            row={getValuesRow(isSummaryReview)}
            count={managerValuesData.length}
            data={managerValuesData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}

      {cultureValuesData && (
        <TableWrapper
          headerTitle={getAdjustedTitle('Values')}
          headerRating={reviewData.culture_values?.rating || undefined}
          completedReviews={isSummaryReview}
        >
          <AdjustableTable<
            SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
          >
            name={TableNames.PerformanceSummaryValues}
            row={getValuesRow(isSummaryReview)}
            count={cultureValuesData.length}
            data={cultureValuesData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}

      {cultureSkillsData && (
        <TableWrapper
          headerTitle={getAdjustedTitle('Values')}
          headerRating={reviewData.culture_skills?.rating || undefined}
          completedReviews={isSummaryReview}
        >
          <AdjustableTable<
            SkillCardInterface | ValueBasedCardInterface | SummarySkillCardInterface
          >
            name={TableNames.PerformanceSummaryValues}
            row={getValuesRow(isSummaryReview)}
            count={cultureSkillsData.length}
            data={cultureSkillsData}
            hideCountAndButtonSection
          />
        </TableWrapper>
      )}
    </VStack>
  )
}
