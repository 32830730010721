import React, { useMemo } from 'react'
import { Box, Cell, Checkbox, HStack, InputGroup, Text, Token } from '@revolut/ui-kit'
import SlotDateFilter from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotDateFilter'
import SlotInterviewerFilter from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotInterviewerFilter'
import InterviewSlotsSkeleton from '@src/pages/Forms/Candidate/ScheduleSidebar/InterviewSlotsSkeleton'
import { useGroupByDaySlots } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks'
import { AvailableInterviewerSlot } from '@src/interfaces/interviewTool'
import { OptionInterface } from '@src/interfaces/selectors'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import TimezoneSelect from '@src/pages/Forms/Candidate/ScheduleSidebar/TimezoneSelect'
import ExpectingMoreSlotsWidget from '@src/pages/Forms/Candidate/ScheduleSidebar/ExpectingMoreSlotsWidget'
import { SlotInterviewerGroupFilter } from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotInterviewerGroupFilter'
import { IdAndName } from '@src/interfaces'
import InterviewDateSlots from '@src/pages/Forms/Candidate/ScheduleSidebar/InterviewDateSlots'

export interface SlotsSelectorProps {
  dateFilter?: OptionInterface
  interviewerFilter?: OptionInterface[]
  interviewerGroupsFilter?: IdAndName[]
  isPrepCall: boolean
  loadingSlots?: boolean
  availableSlots?: AvailableInterviewerSlot[]
  selectedSlots?: AvailableInterviewerSlot[]
  timeZoneId: string
  warningContent?: React.ReactNode
  onInterviewerFilterChange: (option?: OptionInterface[] | undefined) => void
  onInterviewerGroupsFilterChange: (option?: IdAndName[] | undefined) => void
  onDateFilterChange: (option?: OptionInterface | undefined) => void
  onSlotChange: (slots: AvailableInterviewerSlot[]) => void
  stageId?: number
  roundId: number
}

const SlotsSelector = ({
  dateFilter,
  interviewerFilter,
  interviewerGroupsFilter,
  isPrepCall,
  loadingSlots,
  availableSlots,
  selectedSlots,
  timeZoneId,
  warningContent,
  onInterviewerFilterChange,
  onInterviewerGroupsFilterChange,
  onDateFilterChange,
  onSlotChange,
  stageId,
  roundId,
}: SlotsSelectorProps) => {
  const selectedSlotsIds = useMemo(
    () => selectedSlots?.map(item => item.id),
    [selectedSlots],
  )
  const normalizedSlots = useGroupByDaySlots(timeZoneId, availableSlots)

  return (
    <>
      <HStack gap="s-8" mb="s-16">
        <SlotDateFilter onChange={onDateFilterChange} value={dateFilter} />
        <SlotInterviewerFilter
          onChange={onInterviewerFilterChange}
          value={interviewerFilter}
          isPrepCall={isPrepCall}
          stageId={stageId}
          roundId={roundId}
        />
        <SlotInterviewerGroupFilter
          onChange={onInterviewerGroupsFilterChange}
          value={interviewerGroupsFilter}
          isPrepCall={isPrepCall}
          stageId={stageId}
          roundId={roundId}
        />
      </HStack>
      <Box>
        {!normalizedSlots.length && !loadingSlots ? (
          <ActionWidget
            mt="s-16"
            title="No slots available"
            text={
              <Text>
                Eligible interviewers either have not marked any slots, or no slots are
                available for the window that you have selected. <br />
                <br /> To mark slots, interviewers need to create a recurring event in
                their calendar with the string “
                <Text color={Token.color.green}>[interview-slot]</Text>” added either in
                the subject or description of the event.
              </Text>
            }
          >
            {warningContent}
          </ActionWidget>
        ) : (
          <>
            <InputGroup>
              <Cell>
                <Checkbox
                  onChange={e => {
                    const checked = e.target.checked
                    if (checked) {
                      onSlotChange(availableSlots ? [...availableSlots] : [])
                    } else {
                      onSlotChange([])
                    }
                  }}
                  checked={
                    availableSlots?.length !== 0 &&
                    availableSlots?.length === selectedSlots?.length
                  }
                  data-testid="all-slots"
                >
                  <Text>Select all slots for {dateFilter?.name}</Text>
                </Checkbox>
              </Cell>
              <TimezoneSelect />
            </InputGroup>
            <InterviewDateSlots
              slots={normalizedSlots}
              selectedSlotIds={selectedSlotsIds ?? []}
              onChange={ids => {
                onSlotChange(availableSlots!.filter(slot => ids.includes(slot.id)))
              }}
              timeZone={timeZoneId}
            />
            {loadingSlots ? (
              <InterviewSlotsSkeleton />
            ) : (
              <ExpectingMoreSlotsWidget>{warningContent}</ExpectingMoreSlotsWidget>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default SlotsSelector
