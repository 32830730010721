import React from 'react'
import { Box, Flex, Widget } from '@revolut/ui-kit'
import CareersPositionApplyButton from '@src/pages/Careers/CareersPosition/CareersPositionApplyButton'
import { formattedHTMLContentCSS } from '@src/utils/styles'
import DOMPurify from 'dompurify'
import { Video } from '@src/components/Video/Video'

export interface CareersPositionBodyProps {
  text: string
  id: string
  description: string
  disabledApplyButton?: boolean
  video?: string
}

export const CareersPositionBody = ({
  text,
  id,
  description,
  disabledApplyButton,
  video,
}: CareersPositionBodyProps) => {
  return (
    <>
      <Box hide="*-lg" mt="s-24">
        <Flex justifyContent="center">
          <CareersPositionApplyButton
            positionId={id}
            positionTitle={text}
            disabled={disabledApplyButton}
          />
        </Flex>
      </Box>
      <Widget p="s-32" mt="s-72">
        <Box
          css={formattedHTMLContentCSS}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
        />

        {video ? (
          <Box pt="s-40">
            <Video url={video} />
          </Box>
        ) : null}

        <Flex justifyContent="center" mt="s-32">
          <CareersPositionApplyButton
            positionId={id}
            positionTitle={text}
            disabled={disabledApplyButton}
          />
        </Flex>
      </Widget>
    </>
  )
}
