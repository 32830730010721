import { useMemo } from 'react'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { CompanyInterface } from '@src/interfaces/company'
import { GoalContentType, GoalsOrganisationalUnit } from '@src/interfaces/goals'
import { OrgEntityInterface } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { useLocation } from 'react-router-dom'

interface Props {
  company?: CompanyInterface
  contentTypeModel?: GoalContentType['model']
  organisationalUnits?: GoalsOrganisationalUnit[]
  departmentsUnits?: GoalsOrganisationalUnit[]
}

export const useOrgUnitsProps = ({
  company,
  contentTypeModel,
  organisationalUnits,
  departmentsUnits,
}: Props) => {
  const location = useLocation<{
    entity: OrgEntityInterface
  }>()

  return useMemo(() => {
    const companyOption = company
      ? [
          {
            content_type_id: null,
            content_type_model: null,
            is_company: true,
            id: company.id,
            name: company.name,
          },
        ]
      : []

    switch (location.state.entity.type) {
      case 'employees':
        return {
          selectorKey: selectorKeys.organisational_units,
          orgUnitsSelectLabel: 'Select Teams, Department or Company',
          defaultValue: organisationalUnits?.find(
            unit =>
              location.state.entity.type === EntityTypes.employees &&
              unit.id === location.state.entity.data.team?.id,
          ),
        }
      case 'teams':
        return {
          selectorKey: selectorKeys.departments_company,
          orgUnitsSelectLabel: 'Select Department or Company',
          defaultValue: departmentsUnits?.find(
            unit =>
              location.state.entity.type === EntityTypes.teams &&
              unit.id === location.state.entity.data.department?.id,
          ),
        }
      case 'department':
        return {
          selectorKey: () => Promise.resolve(companyOption),
          orgUnitsSelectLabel: 'Select Company',
          defaultValue: companyOption?.[0],
        }
      default:
        return {
          selectorKey: selectorKeys.organisational_units,
          defaultValue: undefined,
          orgUnitsSelectLabel: 'Select Teams, Department or Company',
        }
    }
  }, [company, contentTypeModel, organisationalUnits, departmentsUnits, location])
}
