import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Badge, HStack, Icon, Text, Token } from '@revolut/ui-kit'

export const RecommendedBadge = () => {
  return (
    <Badge bg={Token.color.accent} color={Token.color.background}>
      <HStack gap="s-4" align="center" px="s-4">
        <Icon name="Cleaning" size={14} />
        <Text variant="emphasis4">
          <FormattedMessage id="common.recommended" defaultMessage="Recommended" />
        </Text>
      </HStack>
    </Badge>
  )
}
