import React from 'react'
import { css } from 'styled-components'
import { Action, Box, Color, Flex, Subheader } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

export const SummarySubheaderCss = css`
  white-space: pre;
`

export const summaryTableProps = {
  noReset: true,
  hideCount: true,
}

export const SummaryTableContainer: React.FC = ({ children }) => (
  <Box
    border="1px solid"
    borderColor={Color.GREY_TONE_10}
    overflow="hidden"
    borderRadius={12}
  >
    <Box m="-1px">{children}</Box>
  </Box>
)

export const SummarySubheader: React.FC<{ formUrl?: string }> = ({
  children,
  formUrl,
}) => {
  return (
    <Subheader variant="nested">
      <Subheader.Title css={SummarySubheaderCss}>
        <Flex alignItems="center">{children}</Flex>
      </Subheader.Title>
      {formUrl && (
        <Subheader.Side>
          <Action to={formUrl} use={InternalLink}>
            Details
          </Action>
        </Subheader.Side>
      )}
    </Subheader>
  )
}
