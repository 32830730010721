import React, { useState } from 'react'
import { Button, Header, Item, Switch, BottomSheet } from '@revolut/ui-kit'

interface SyncIntegrationSettingsPopupProps {
  isOpen: boolean
  onSubmit: (syncAll: boolean) => void
  onClose: () => void
  isSubmitting: boolean
}

export const SyncIntegrationSettingsPopup = ({
  isOpen,
  onSubmit,
  onClose,
  isSubmitting,
}: SyncIntegrationSettingsPopupProps) => {
  const [syncAll, setSyncAll] = useState(false)

  return (
    <BottomSheet open={isOpen} onClose={onClose}>
      <Header>
        <Header.Title>Sync settings</Header.Title>
      </Header>

      <Item use="label">
        <Item.Content>
          <Item.Title>Sync all employees</Item.Title>
          <Item.Description>
            Allow to sync all employee records, including deleted and archived
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <Switch checked={syncAll} onClick={() => setSyncAll(!syncAll)} />
        </Item.Side>
      </Item>

      <BottomSheet.Actions>
        <Button onClick={() => onSubmit(syncAll)} pending={isSubmitting}>
          Continue
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
