import React from 'react'
import { Action, Checkbox, Item } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { SkillInterface } from '@src/interfaces/skills'
import { pathToUrl } from '@src/utils/router'

interface SkillItemProps {
  disabled: boolean
  selected: boolean
  skill: SkillInterface
  onClick: VoidFunction
}

export const SkillItem = ({ disabled, selected, skill, onClick }: SkillItemProps) => {
  return (
    <Item
      use={disabled ? undefined : 'label'}
      onClick={e => {
        e.preventDefault()
        if (!disabled) {
          onClick()
        }
      }}
    >
      <Item.Prefix>
        <Checkbox disabled={disabled} checked={selected} />
      </Item.Prefix>
      <Item.Content>
        <Item.Title>{skill.name}</Item.Title>
        <Item.Description>{skill.description}</Item.Description>
        <Item.Actions>
          <Action
            useEndIcon="LinkExternal"
            to={pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, {
              id: skill.id,
            })}
            use={InternalLink}
            target="_blank"
            onClick={e => {
              e.stopPropagation()
            }}
          >
            View details
          </Action>
        </Item.Actions>
      </Item.Content>
    </Item>
  )
}
