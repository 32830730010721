import React, { useState } from 'react'
import {
  Action,
  ActionButton,
  Avatar,
  Bar,
  HStack,
  Icon,
  Item,
  ItemSkeleton,
  MoreBar,
  TableWidget,
  Token,
  Tooltip as UIKitTooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useTable } from '@components/Table/hooks'
import { jobPostingsRequests } from '@src/api/jobPosting'
import { RowInterface } from '@src/interfaces/data'
import { getSelectCellConfig } from '@components/Table/AdvancedCells/SelectCell/SelectCell'
import { JobPostingListInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import {
  jobDescriptionActionColumn,
  jobDescriptionGenericNameColumn,
  jobDescriptionOfficeLocationsColumn,
  jobDescriptionPlatformsColumn,
  jobDescriptionPublishedStatusColumn,
  jobDescriptionRemoteLocationsColumn,
} from '@src/constants/columns/jobDescription'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'
import { useGetCareerSettings, useGetJobPostingSettings } from '@src/api/settings'
import { getLocationDescriptor, useOpenNewTab } from '@src/actions/RouterActions'
import { Link } from 'react-router-dom'
import { useOnPublishClick } from '@src/pages/OnboardingChecklistV2/Jobs/utils'
import Tooltip from '@components/Tooltip/Tooltip'
import { Statuses } from '@src/interfaces'
import { PreviewButton } from '@src/pages/Careers/PreviewButton'
import { LOCAL_STORAGE } from '@src/constants/api'
import { generatePositionId } from '@src/pages/Careers/utils'
import { useSaveSwitcherItems } from '@components/SwitcherButtonsWIthSidebar/useSaveSwitcherItems'

type JobPostingPublishButtonProps = {
  data: JobPostingListInterface
  refresh: () => void
  isPublishingEnabled?: boolean
}

const JobPostingPublishButton = ({
  data,
  isPublishingEnabled,
  refresh,
}: JobPostingPublishButtonProps) => {
  const { onPublishClick, publishLoading } = useOnPublishClick(refresh)
  return (
    <Action
      disabled={!isPublishingEnabled || publishLoading}
      onClick={() => {
        onPublishClick(
          [data.id],
          data.status === PublishingStatuses.unpublished
            ? PublishingStatuses.fully_published
            : PublishingStatuses.unpublished,
        )
      }}
    >
      {data.status === PublishingStatuses.unpublished ? 'Publish' : 'Unpublish'}
    </Action>
  )
}

type ActionsProps = {
  isPublishingEnabled: boolean
  data: JobPostingListInterface
  refresh: () => void
}

const Actions = ({ isPublishingEnabled, data, refresh }: ActionsProps) => {
  const navigateTo = useOpenNewTab()

  return (
    <HStack gap="s-8">
      <Action
        disabled={!data.public_id}
        onClick={() => {
          if (data.public_id) {
            navigateTo(
              pathToUrl(PUBLIC_ROUTES.CAREERS.POSITION, {
                id: generatePositionId(data.name, data.public_id),
              }),
            )
          }
        }}
      >
        Preview
      </Action>
      <JobPostingPublishButton
        data={data}
        isPublishingEnabled={isPublishingEnabled}
        refresh={refresh}
      />
    </HStack>
  )
}

type JobPostingPlatformsProps = {
  data: JobPostingListInterface
}
const JobPostingPlatforms = ({ data }: JobPostingPlatformsProps) => {
  const { data: careersSettings } = useGetCareerSettings()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const isCareersPublished = careersSettings?.publish_career_website
  const isPublished = data.status === PublishingStatuses.fully_published
  const isMonsterEnabled = jobPostingSettings?.enable_monster_integration
  const isLinkedInEnabled = jobPostingSettings?.enable_linkedin_integration
  return (
    <HStack gap="s-8">
      <Tooltip placement="top">
        <Icon
          size={20}
          name="Globe"
          color={
            isCareersPublished && isPublished
              ? Token.color.foreground
              : Token.color.greyTone50
          }
        />
      </Tooltip>
      {isMonsterEnabled && (
        <Tooltip
          placement="top"
          text="It might take a couple of hours for job postings to be reflected on Monster"
        >
          <Avatar
            size={20}
            label="M"
            color={isPublished ? Token.color.foreground : Token.color.greyTone50}
          />
        </Tooltip>
      )}
      {isLinkedInEnabled && (
        <Tooltip
          placement="top"
          text="It might take a couple of hours for job postings to be reflected on linkedin"
        >
          <Icon
            size={20}
            name="LogoLinkedIn"
            color={isPublished ? Token.color.foreground : Token.color.greyTone50}
          />
        </Tooltip>
      )}
    </HStack>
  )
}

const row = (
  refresh: () => void,
  isPublishingEnabled?: boolean,
): RowInterface<JobPostingListInterface> => ({
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...jobDescriptionGenericNameColumn,
      width: 250,
    },
    {
      ...jobDescriptionOfficeLocationsColumn,
      width: 250,
    },

    {
      ...jobDescriptionRemoteLocationsColumn,
      width: 250,
    },
    {
      ...jobDescriptionPublishedStatusColumn,
      width: 250,
      colors: data =>
        data.status === PublishingStatuses.fully_published
          ? Token.color.green
          : Token.color.foreground,
    },
    {
      ...jobDescriptionPlatformsColumn,
      width: 250,
      insert: JobPostingPlatforms,
    },
    {
      ...jobDescriptionActionColumn,
      width: 250,
      insert: ({ data }) => (
        <Actions
          data={data}
          isPublishingEnabled={!!isPublishingEnabled}
          refresh={refresh}
        />
      ),
    },
  ],
})

export const JobsPreview = () => {
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<JobPostingListInterface>>()
  const initialFilterBy = [
    {
      columnName: 'approval_status',
      filters: [
        { id: Statuses.pending, name: Statuses.pending },
        { id: Statuses.approved, name: Statuses.approved },
      ],
    },
  ]
  const table = useTable(jobPostingsRequests, initialFilterBy, undefined, {
    refetchOnWindowFocus: true,
  })

  useSaveSwitcherItems<JobPostingListInterface>(
    LOCAL_STORAGE.JOBS_PREVIEW_TABLE_CONTEXT,
    table.data,
    undefined,
    item => generatePositionId(item.name, item.public_id!),
  )

  const { data: careersSettings, isLoading: isCareersSettingsLoading } =
    useGetCareerSettings()
  const { onPublishClick, publishLoading } = useOnPublishClick(table.refresh)
  const tooltip = useTooltip()

  const getSelectedItems = () => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData.map(r => r.id)
    }

    if (selectedData?.isAllSelected) {
      return table.data
        ?.map(r => r.id)
        .filter(r => !selectedData.excludeListIds.has(`${r}`))
    }

    return []
  }

  const isCareersPublished = careersSettings?.publish_career_website
  const actionsDisabled = !isCareersPublished || !selectedData?.someSelected

  return (
    <VStack gap="s-16">
      {isCareersSettingsLoading ? (
        <ItemSkeleton />
      ) : (
        <Item>
          <Item.Avatar>
            <Avatar
              color={isCareersPublished ? Token.color.green : Token.color.orange}
              useIcon={isCareersPublished ? 'Check' : 'ExclamationTriangle'}
            />
          </Item.Avatar>
          <Item.Content>
            {isCareersPublished ? (
              <>
                <Item.Title>Career website is published</Item.Title>
                <Item.Description>
                  All published jobs are now visible to external candidates. You can
                  change it in Publishing Settings anytime
                </Item.Description>
              </>
            ) : (
              <>
                <Item.Title>Career website is not published</Item.Title>
                <Item.Description>
                  To make your jobs visible to candidates, you have to configure career
                  website and job boards first. Go to “Publishing settings” to start.
                </Item.Description>
              </>
            )}

            <Bar mt="s-12">
              <ActionButton
                useIcon="Gear"
                use={Link}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={getLocationDescriptor(ROUTES.SETTINGS.CAREER_PAGE_ONBOARDING)}
                hasDot={!isCareersPublished}
              >
                Publishing settings
              </ActionButton>
              <ActionButton
                useIcon="Link"
                use={Link}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={getLocationDescriptor(ROUTES.SETTINGS.JOBS_INTEGRATIONS_ONBOARDING)}
              >
                Integrations
              </ActionButton>
              <PreviewButton />
            </Bar>
          </Item.Content>
        </Item>
      )}

      <TableWidget>
        <TableWidget.Actions>
          <MoreBar>
            {!isCareersPublished && (
              <UIKitTooltip {...tooltip.getTargetProps()}>
                You need to publish careers website before publishing job postings
              </UIKitTooltip>
            )}
            <MoreBar.Action
              {...tooltip.getAnchorProps()}
              useIcon="Globe"
              aria-disabled={actionsDisabled}
              pending={publishLoading}
              onClick={() => {
                if (actionsDisabled) {
                  return
                }
                onPublishClick(getSelectedItems(), PublishingStatuses.fully_published)
              }}
            >
              Publish
            </MoreBar.Action>
            <MoreBar.Action
              {...tooltip.getAnchorProps()}
              useIcon="EyeHide"
              pending={publishLoading}
              aria-disabled={actionsDisabled}
              variant="negative"
              onClick={() => {
                if (actionsDisabled) {
                  return
                }
                onPublishClick(getSelectedItems(), PublishingStatuses.unpublished)
              }}
            >
              Unpublish
            </MoreBar.Action>
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
            tableData={table.data}
          >
            <AdjustableTable
              name={TableNames.OnboardingJobsPreview}
              row={row(table.refresh, isCareersPublished)}
              useWindowScroll
              {...table}
            />
          </SelectTableWrapper>
        </TableWidget.Table>
      </TableWidget>
    </VStack>
  )
}
