import { useDeleteV2, useFetchV2, usePostV2, useUpdateV2 } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { FavouriteInterface } from '@src/interfaces/favourites'
import { GetRequestData } from '@src/interfaces'
import { apiV2 } from '.'

export const useGetFavourites = () =>
  useFetchV2<GetRequestData<FavouriteInterface>>({
    url: API.FAVOURITES,
    queryOptions: {
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 90,
      refetchOnWindowFocus: false,
    },
  })

export const changeFavouriteOrder = (id: number, targetId: number) =>
  apiV2.patch(`${API.FAVOURITES}/${id}/reorder`, {
    target_position_object_id: targetId,
  })

export const useAddFavourite = () =>
  usePostV2<
    GetRequestData<FavouriteInterface>,
    FavouriteInterface,
    { favourite_object: { id: number } }
  >({
    url: API.FAVOURITES,
    updater: (oldData, newData) => ({
      ...oldData,
      count: oldData.count + 1,
      results: [...oldData.results, newData],
    }),
  })

export const syncFavourites = (data: { favourite_object: { id: number } }[]) =>
  apiV2.post<GetRequestData<FavouriteInterface>>(API.FAVOURITES, data)

export const useDeleteFavourite = () =>
  useDeleteV2<GetRequestData<FavouriteInterface>>({
    url: API.FAVOURITES,
    updater: (oldData, id) => {
      const newResults = oldData.results.filter(f => f.id !== id)
      return {
        ...oldData,
        count: newResults.length,
        results: newResults,
      }
    },
  })

export const useUpdateFavourite = () =>
  useUpdateV2<GetRequestData<FavouriteInterface>, Partial<FavouriteInterface>>({
    url: API.FAVOURITES,
    updater: (oldData, newData, id) => {
      return {
        ...oldData,
        results: oldData.results.map(f => (f.id === id ? { ...f, ...newData } : f)),
      }
    },
    useId: false,
  })
