import React from 'react'
import { useParams } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useGetEmployee } from '@src/api/employees'
import PageLoading from '@components/PageLoading/PageLoading'
import { CreateGrowthPlanForm } from '@src/features/GrowthPlans/GrowthPlan/CreateGrowthPlan/CreateGrowthPlanForm'
import Page404 from '@src/pages/Page404/Page404'
import { GrowthPlanFormActions } from '@src/features/GrowthPlans/GrowthPlan/CreateGrowthPlan/GrowthPlanFormActions'
import { EditGrowthPlanLayout } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/EditGrowthPlanLayout'

export const CreateGrowthPlanPage = () => {
  const { employeeId } = useParams<{ employeeId: string }>()
  const { data: employee, isLoading: isEmployeeLoading } = useGetEmployee(employeeId)
  const currentUser = useSelector(selectUser)

  const backUrl = pathToUrl(
    ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.GROWTH_PLANS,
    { id: currentUser.id },
  )

  if (isEmployeeLoading) {
    return <PageLoading />
  }

  if (!isEmployeeLoading && !employee) {
    return <Page404 />
  }

  return (
    <CreateGrowthPlanForm employee={employee}>
      <EditGrowthPlanLayout
        title="Setup growth plan"
        employee={employee}
        backUrl={backUrl}
        actions={<GrowthPlanFormActions />}
      />
    </CreateGrowthPlanForm>
  )
}
