import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { navigateTo } from '@src/actions/RouterActions'

export const getBackUrl = (
  params: JobPostingFlowParams,
  locationState?: JobPostingLocationState,
) => {
  if (params.id && locationState?.specialisationId) {
    return pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
      id: params.id,
      specId: locationState.specialisationId,
    })
  }
  if (locationState?.requisitionId) {
    return pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {
      id: locationState.requisitionId,
    })
  }
  if (locationState?.specialisationId) {
    return pathToUrl(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, {
      id: locationState.specialisationId,
    })
  }
  return pathToUrl(ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS)
}

export const useJobPostingHiringStages = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const jobPostingHiringStages = (featureFlags ?? []).includes(
    FeatureFlags.JobPostingHiringStages,
  )
  return jobPostingHiringStages
}

export const useNavigateToJobPostingPath = () => {
  const jobPostingHiringStages = useJobPostingHiringStages()
  return (
    { specialisationId, requisitionId, name }: JobPostingLocationState,
    id?: string | number,
    route = ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS,
  ) => {
    const params = jobPostingHiringStages
      ? { id, action: id ? 'edit' : 'create' }
      : {
          id,
          specId: specialisationId,
        }
    const state = jobPostingHiringStages
      ? {
          requisitionId,
          specialisationId,
          name,
        }
      : undefined
    const path = jobPostingHiringStages ? route : ROUTES.FORMS.JOB_POSTING.GENERAL
    navigateTo(pathToUrl(path, params), state)
  }
}
