import {
  Avatar,
  Box,
  HStack,
  InputGroup,
  Link,
  Text,
  TextSkeleton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { isSameDay } from 'date-fns'
import pluralize from 'pluralize'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { deleteFile } from '@src/api/files'
import {
  useEmployeeTimeOffBalanceSelector,
  useEmployeeTimeOffRequestPreview,
  useEmployeeTimeOffRequiredApprovals,
  useGetTimeOffPolicy,
  useNonWorkingDays,
} from '@src/api/timeOff'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import LapeFileUploader from '@src/components/Inputs/LapeFields/LapeFileUploader'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { FileInterface } from '@src/interfaces/files'
import {
  EmployeeTimeOffBalanceSelectorOption,
  EmployeeTimeOffRequestInterface,
  TimeOffPeriods,
} from '@src/interfaces/timeOff'
import {
  ContactHRTeamButton,
  UserGuidesButton,
} from '@src/pages/EmployeeProfile/Preview/TimeOff/common'
import { formatDate } from '@src/utils/format'
import { localDateToUtc, utcToLocalDate } from '@src/utils/timezones'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'

import { RouteParams } from '..'
import { DateRangePicker } from './DateRangePicker'
import { TimePickers } from './TimePickers'

const List = styled.ul`
  padding-left: ${Token.space.s20};
  margin: 0;
`

const allDayOption = { id: 'all_day', name: 'All day' } as const

interface GeneralProps {
  recordAbsenceMode?: boolean
}

const General = ({ recordAbsenceMode }: GeneralProps) => {
  const { employeeId } = useParams<RouteParams>()
  const { values } = useLapeContext<EmployeeTimeOffRequestInterface>()

  const [initialAttachmentId, setInitialAttachmentId] = useState<number>()

  const { data: availableBalances, isLoading: isAvailableBalancesLoading } =
    useEmployeeTimeOffBalanceSelector(employeeId)
  const { data: nonWorkingDays } = useNonWorkingDays(
    employeeId,
    values.from_date_time,
    values.to_date_time,
  )
  const publicHolidays = nonWorkingDays
    ?.filter(date => date.type === 'public_holiday')
    .map(date => ({ ...date, day: utcToLocalDate(date.day).toISOString() }))

  const location = useLocation<{ balanceId?: number }>()

  useEffect(() => {
    // We can't save file to LocalStorage that is why I remove it if the data comes from there.
    if (values.attachment && !values.attachment.name) {
      values.attachment = undefined
    }

    // Some users have invalid date range cached in localstorage, which is crashing the form for them
    if (!values.id) {
      values.from_date_time = undefined
      values.to_date_time = undefined
    }

    if (values.id && (values.attachment as FileInterface)?.id) {
      setInitialAttachmentId((values.attachment as FileInterface).id)
    }
  }, [])

  const timeOffPeriods = useFetchOptions<IdAndName<TimeOffPeriods>>(
    selectorKeys.time_off_request_periods,
  )

  const balancePolicy = values.balance?.policy
  const selectedBalance =
    balancePolicy &&
    availableBalances?.options.find(option => option.policy.id === balancePolicy.id)
  const policyDetails = selectedBalance?.policy

  const timeOffPolicy = useGetTimeOffPolicy(balancePolicy?.id)

  const preSelectedBalance = useMemo(() => {
    if (!availableBalances || !location.state?.balanceId) {
      return undefined
    }
    return availableBalances.options.find(
      option => option.id === location.state.balanceId,
    )
  }, [availableBalances, location.state?.balanceId])

  useEffect(() => {
    if (!values.balance && preSelectedBalance) {
      values.balance = preSelectedBalance
    }
  }, [values.balance, preSelectedBalance])

  useEffect(() => {
    if (values.balance?.unit?.id === 'day') {
      values.from_time_period = allDayOption
      values.to_time_period = allDayOption

      if (values.from_date_time && values.to_date_time) {
        values.from_date_time = localDateToUtc(new Date(values.from_date_time))
        values.to_date_time = localDateToUtc(new Date(values.to_date_time))
      }
    }
    if (values.balance?.unit?.id === 'hour') {
      values.from_time_period = null
      values.to_time_period = null
    }
  }, [values.balance])

  const isApprovalRequired = policyDetails?.is_approval_required
  const isCommentRequired = policyDetails?.is_comment_required
  const halfDaysAllowed = values.balance?.half_days?.id === 'yes'
  const isHourlyRequest = values.balance?.unit?.id === 'hour'
  const requestInstructions = policyDetails?.request_instructions
  const detailsUrl = policyDetails?.details_url
  const isDatePickerDisabled =
    values.field_options?.read_only.includes('from_date_time') ||
    values.field_options?.read_only.includes('to_date_time')
  const isBalanceDisabled = !!values.field_options?.read_only.includes('balance')

  const { data: timeOffRequestPreview, isLoading: isTimeOffRequestPreviewLoading } =
    useEmployeeTimeOffRequestPreview(values)
  const { data: requiredApprovals, isLoading: isRequiredApprovalsLoading } =
    useEmployeeTimeOffRequiredApprovals(values.balance?.id)
  const isAttachmentRequired = timeOffRequestPreview?.attachment.required
  const timeOffDuration = timeOffRequestPreview?.duration

  const widgetTitle = (() => {
    if (isTimeOffRequestPreviewLoading || timeOffPolicy.isLoading) {
      return <TextSkeleton variant="h2" width="50%" />
    }
    if (timeOffDuration == null) {
      return isApprovalRequired ? 'Request approval chain' : undefined
    }
    if (timeOffDuration.error || timeOffDuration.duration == null) {
      return 'The dates selected are invalid'
    }
    const { duration, unit } = timeOffDuration
    if (unit.id === 'day') {
      return `You are requesting leave for ${pluralize(
        unit.name.toLowerCase(),
        duration,
        true,
      )}`
    }
    if (unit.id === 'hour') {
      const hours = Math.floor(duration)
      const minutes = Math.round((duration % 1) * 60)

      return `You are requesting leave for ${pluralize('hour', hours, true)}${
        minutes > 0 ? ` ${pluralize('minute', minutes, true)}` : ''
      }`
    }
    return null
  })()

  const fromAndToAreSameDay =
    !!values.from_date_time &&
    !!values.to_date_time &&
    isSameDay(new Date(values.from_date_time), new Date(values.to_date_time))

  const fromTimePeriodOptions = fromAndToAreSameDay
    ? timeOffPeriods.options
    : timeOffPeriods.options.filter(
        option => option.value.id === 'all_day' || option.value.id === 'afternoon',
      )

  const toTimePeriodOptions = fromAndToAreSameDay
    ? timeOffPeriods.options
    : timeOffPeriods.options.filter(
        option => option.value.id === 'all_day' || option.value.id === 'morning',
      )

  if (isAvailableBalancesLoading) {
    return <PageLoading />
  }

  if (availableBalances?.options.length === 0) {
    return (
      <PageBody>
        <ActionWidget
          title={`You don't have any time off balances open`}
          text="To request time off you need to have assigned time off policies and active balances."
          avatarColor={Token.color.blue}
        >
          <HideIfCommercial>
            <ContactHRTeamButton />
            <UserGuidesButton />
          </HideIfCommercial>
        </ActionWidget>
      </PageBody>
    )
  }

  const hasApprovalChain = !!isApprovalRequired && !!requiredApprovals?.length

  const renderApprovalInfo = () => {
    if (timeOffPolicy.isLoading || isRequiredApprovalsLoading) {
      return <TextSkeleton variant="heading2" />
    }
    if (recordAbsenceMode) {
      return <Text fontWeight={500}>Request will be automatically approved</Text>
    }
    if (hasApprovalChain) {
      return (
        <VStack mt="s-12" space="s-16">
          {requiredApprovals.map(approver => {
            if (approver.approver_type.id.includes('group') && approver.groups?.length) {
              return (
                <HStack key={approver.id} align="center" space="s-4">
                  <Avatar size={24} useIcon="People" />
                  <Text pl="s-4">{approver.groups[0].name}</Text>
                  <Text>(Dynamic Group)</Text>
                </HStack>
              )
            }

            if (
              (approver.approver_type.id === 'default_approver' ||
                approver.approver_type.id === 'employee') &&
              approver.employee
            ) {
              return <UserWithAvatar key={approver.id} {...approver.employee} />
            }

            if (
              approver.approver_type.id === 'relationship' &&
              approver.relationship?.name &&
              approver.employee
            ) {
              return (
                <HStack key={approver.id} align="center" space="s-4">
                  <UserWithAvatar {...approver.employee} />
                  <Text>{`(${approver.relationship.name})`}</Text>
                </HStack>
              )
            }

            return null
          })}
        </VStack>
      )
    }
    return <Text fontWeight={500}>Request does not require approval</Text>
  }

  return (
    <>
      <PageBody>
        <InputGroup>
          <LapeRadioSelectInput<EmployeeTimeOffBalanceSelectorOption>
            name="balance"
            label="Which policy should be used for balance deduction?"
            disabled={isBalanceDisabled || !!preSelectedBalance}
            loading={isAvailableBalancesLoading}
            options={availableBalances?.options.map(opt => ({
              label: opt.name,
              value: opt,
            }))}
            onAfterChange={() => {
              if (values.request_type_balance) {
                delete values.request_type_balance
              }
            }}
          />

          {selectedBalance?.request_type_balances?.length ? (
            <LapeRadioSelectInput
              label="Special request type"
              name="request_type_balance"
              options={selectedBalance.request_type_balances.map(option => ({
                label: option.name,
                value: option,
              }))}
              message="You can choose a special request type if needed. The number of days requested will be deducted from the balance selected at the top"
            />
          ) : null}

          <DateRangePicker />

          {isHourlyRequest && <TimePickers />}

          {halfDaysAllowed ? (
            <InputGroup variant="horizontal">
              <LapeRadioSelectInput
                name="from_time_period"
                label="Start period"
                options={fromTimePeriodOptions}
                loading={timeOffPeriods.asyncState === 'pending'}
                onAfterChange={option => {
                  if (fromAndToAreSameDay && option) {
                    values.to_time_period = option
                  }
                }}
                required
              />
              <LapeRadioSelectInput
                name="to_time_period"
                label="End period"
                options={toTimePeriodOptions}
                loading={timeOffPeriods.asyncState === 'pending'}
                disabled={fromAndToAreSameDay}
              />
            </InputGroup>
          ) : null}

          {policyDetails && !isDatePickerDisabled ? (
            <ActionWidget
              title={widgetTitle}
              text={
                <Box color={Token.color.foreground}>
                  <Box data-testid="request-approver">{renderApprovalInfo()}</Box>

                  {requestInstructions || detailsUrl ? (
                    <Box data-testid="instructions" mt="s-8">
                      <Text fontWeight={500}>Instructions:</Text>
                      {requestInstructions ? (
                        <Text ml="s-4">{requestInstructions}</Text>
                      ) : null}
                      {detailsUrl ? (
                        <Text ml="s-4">
                          More information on this policy can be found{' '}
                          <Link
                            href={detailsUrl}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            here
                          </Link>
                          .
                        </Text>
                      ) : null}
                    </Box>
                  ) : null}
                  {publicHolidays?.length ? (
                    <Box data-testid="public-holidays" mt="s-8">
                      <Text fontWeight={500}>Public holidays:</Text>
                      <List>
                        {publicHolidays.map(holiday => (
                          <li key={holiday.day}>
                            {holiday.name} - {formatDate(holiday.day)}
                          </li>
                        ))}
                      </List>
                    </Box>
                  ) : null}
                </Box>
              }
              avatarColor={Token.color.accent}
            />
          ) : null}

          <LapeNewTextArea
            name="note"
            label="Note"
            required={isCommentRequired}
            rows={3}
          />

          <LapeFileUploader
            name="attachment"
            required={isAttachmentRequired}
            label={`File attachment${isAttachmentRequired ? ' (mandatory)' : ''}`}
          />
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={data => {
            if (
              initialAttachmentId &&
              ((data as EmployeeTimeOffRequestInterface).attachment as FileInterface)
                ?.id !== initialAttachmentId
            ) {
              deleteFile(initialAttachmentId)
            }
          }}
          previewUrl={ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW}
          useValidator
        >
          {recordAbsenceMode ? 'Record absence' : 'Submit time-off request'}
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}

export default General
