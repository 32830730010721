import { Placeholder, Widget } from '@revolut/ui-kit'
import { AiGenerationStatus } from '@src/interfaces/meetings'
import { lowerCase } from 'lodash'
import React from 'react'

interface Props {
  genrationStatus: AiGenerationStatus
  variant: 'summary' | 'action_point'
}

const variantToNameMap: Record<Props['variant'], string> = {
  summary: 'Summary',
  action_point: 'Action items',
}

export const PendingAIGeneration = ({ genrationStatus, variant }: Props) => {
  return (
    <Widget>
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D362.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D362@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D362@3x.png',
          }}
        />
        <Placeholder.Title>{variantToNameMap[variant]}</Placeholder.Title>
        <Placeholder.Description>
          {genrationStatus === AiGenerationStatus.NOT_STARTED &&
            `${variantToNameMap[variant]} will be available after the meeting`}
          {genrationStatus === AiGenerationStatus.IN_PROGRESS &&
            `We're generating ${lowerCase(variantToNameMap[variant])} for this meeting`}
        </Placeholder.Description>
      </Placeholder>
    </Widget>
  )
}
