import React from 'react'
import { DetailsCell, DetailsCellSkeleton } from '@revolut/ui-kit'
import { ScheduleInterviewResponseInterface } from '@src/interfaces/interviewTool'
import { utcToZonedTime } from 'date-fns-tz'
import { formatDateTime } from '@src/utils/format'

type Props = {
  interview?: ScheduleInterviewResponseInterface
  isLoading?: boolean
}

const ScheduledDetails = ({ interview, isLoading }: Props) => {
  if (isLoading) {
    return <DetailsCellSkeleton />
  }

  if (!interview) {
    return null
  }

  const timeZone =
    interview.scheduling_type === 'classic_scheduling' ||
    interview.scheduling_type === 'dynamic_scheduling'
      ? interview.candidate_timezone
      : interview.scheduling_timezone

  const timeZoneName = timeZone?.name
  const timeZoneId = timeZone?.id

  return (
    <>
      {interview.event_date_time && timeZoneId && (
        <DetailsCell>
          <DetailsCell.Title>Local time for candidate</DetailsCell.Title>
          <DetailsCell.Content>
            {formatDateTime(
              utcToZonedTime(new Date(interview.event_date_time), timeZoneId),
            )}
          </DetailsCell.Content>
        </DetailsCell>
      )}
      {timeZoneName && (
        <DetailsCell>
          <DetailsCell.Title>Candidate's time zone</DetailsCell.Title>
          <DetailsCell.Content>{timeZoneName}</DetailsCell.Content>
        </DetailsCell>
      )}
    </>
  )
}

export default ScheduledDetails
