import React from 'react'
import { TeamNPS, TeamNPSStats } from '@src/interfaces/teams'
import { useTable } from '@components/TableV2/hooks'
import { formatNumber, formatPercentage } from '@src/utils/format'
import { RowInterface } from '@src/interfaces/data'
import {
  npsDetractorCommentColumn,
  npsDetractorCreationDateColumn,
  npsDetractorLinkColumn,
  npsDetractorScoreColumn,
} from '@src/constants/columns/npsDetractors'
import { PerformanceTimeRange } from '@src/constants/api'
import { getBarMonthFilter } from '@src/utils/graph'
import { BaseChartInner } from '@components/Charts/BaseChart/BaseChartInner'
import { Flex, Text, Token } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import {
  getDepartmentNPSDetractors,
  getDepartmentNPSGraph,
  getDepartmentNPSStats,
} from '@src/api/department'
import { DepartmentInterface } from '@src/interfaces/deparment'
import useChartTableSwitcher, {
  ChartTableTabs,
} from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

interface StructureProps {
  data: DepartmentInterface
}

export const getNPSColor = (value: number) => {
  if (value >= 40) {
    return Token.color.green
  }
  if (value >= 20) {
    return Token.color.warning
  }
  return Token.color.red
}

const ROW: RowInterface<TeamNPS> = {
  cells: [
    {
      ...npsDetractorScoreColumn,
      width: 120,
    },
    {
      ...npsDetractorCommentColumn,
      width: 700,
    },
    {
      ...npsDetractorCreationDateColumn,
      width: 200,
    },
    {
      ...npsDetractorLinkColumn,
      width: 120,
    },
  ],
}

const NPS = ({ data }: StructureProps) => {
  const { currentTab, tabBar, setCurrentTab } = useChartTableSwitcher()

  const table = useTable<TeamNPS, TeamNPSStats>({
    getItems: getDepartmentNPSDetractors(data.id),
    getStats: getDepartmentNPSStats(data.id),
  })

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label="NPS"
          val={
            table?.stats?.nps !== undefined ? (
              <Text color={getNPSColor(table.stats.nps * 100)}>
                {formatNumber(table?.stats?.nps * 100, 0)}
              </Text>
            ) : undefined
          }
        />
        <Stat
          label="VS last month"
          val={
            table?.stats?.nps_vs_last_month !== undefined ? (
              <Text
                color={
                  table?.stats?.nps_vs_last_month! < 0
                    ? Token.color.red
                    : Token.color.green
                }
              >
                {formatPercentage(table?.stats?.nps_vs_last_month)}
              </Text>
            ) : undefined
          }
        />
        <Stat
          label="Company avg"
          val={
            table?.stats?.nps_company !== undefined
              ? formatNumber(table?.stats?.nps_company * 100, 0)
              : undefined
          }
        />
      </Table.Widget.Info>
      <Table.Widget.Filters>{tabBar}</Table.Widget.Filters>
      {currentTab === ChartTableTabs.Table && (
        <>
          <Table.Widget.Status>
            <Text mt="36px">Showing only detractor scores (1-3)</Text>
          </Table.Widget.Status>
          <Table.Widget.Table>
            <AdjustableTable<TeamNPS, TeamNPSStats>
              name={TableNames.DepartmentNPS}
              useWindowScroll
              dataType="NPS"
              row={ROW}
              {...table}
              emptyState={<EmptyTableRaw title="No NPS for this department" />}
            />
          </Table.Widget.Table>
        </>
      )}
      {currentTab === ChartTableTabs.Chart && (
        <Table.Widget.Table>
          <Flex
            flexDirection="column"
            minHeight={0}
            style={{ position: 'relative' }}
            height={{ all: 570, md: 400, sm: 250 }}
          >
            <BaseChartInner
              isNew
              id={data.id}
              title="NPS over time"
              fetchData={getDepartmentNPSGraph(data.id)}
              showButtons
              graphTimeRange={PerformanceTimeRange.week}
              onClick={bar => {
                setCurrentTab(ChartTableTabs.Table)
                table.onFilterChange(
                  getBarMonthFilter(bar.data.progress_datetime as string),
                  false,
                )
              }}
            />
          </Flex>
        </Table.Widget.Table>
      )}
    </Table.Widget>
  )
}

export default NPS
