import React, { useEffect, useMemo, useState } from 'react'
import { PerformanceSelector } from '@src/interfaces/performance'
import { useGetPerformanceSelector } from '@src/api/performance'
import { useParams } from 'react-router-dom'
import { PromotionParams } from '@src/pages/Forms/PromotionNominationForm/common/common'
import isPast from 'date-fns/isPast'

export interface PerformanceContextValue {
  lastPublishedCycle?: PerformanceSelector
  isLoading: boolean
}

export const PerformanceContext = React.createContext<PerformanceContextValue>({
  isLoading: true,
})

export const PerformanceProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { employeeId, id } = useParams<PromotionParams>()

  const [isLoading, setIsLoading] = useState(true)
  const { data } = useGetPerformanceSelector(employeeId || id, { exclude_adhoc: true })
  const [publishedPeriod, setPublishedPeriod] = useState<PerformanceSelector>()

  useEffect(() => {
    if (!data) {
      return
    }
    setPublishedPeriod(
      // Select the most recent cycle with published data
      data
        .filter(
          p =>
            p.managers_publishing_day != null &&
            isPast(new Date(p.managers_publishing_day)),
        )
        .sort(
          (a, b) =>
            new Date(b.managers_publishing_day!).getTime() -
            new Date(a.managers_publishing_day!).getTime(),
        )[0],
    )
    setIsLoading(false)
  }, [data])

  const value: PerformanceContextValue = useMemo(
    () => ({
      lastPublishedCycle: publishedPeriod,
      isLoading,
    }),
    [publishedPeriod, isLoading],
  )

  return (
    <PerformanceContext.Provider value={value}>{children}</PerformanceContext.Provider>
  )
}
