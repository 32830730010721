import React, { useEffect, useRef, useState } from 'react'
import {
  ActionButton,
  Avatar,
  Dots,
  IconButton,
  Input,
  Item,
  ItemSkeleton,
  Placeholder,
  Side,
  Text,
  TextButton,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import {
  useCreateEngagementAnswerReply,
  useGetEngagementAnswerDetails,
  useGetEngagementAnswerReplies,
  useUpdateEngagementAnswerDetails,
} from '@src/api/engagement'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { formatDateTime } from '@src/utils/format'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface Props {
  answerId: number
  closeSidebar: VoidFunction
}
export const AcknowledgementSidebar = ({ answerId, closeSidebar }: Props) => {
  const [replyInputValue, setReplyInputValue] = useState<string>('')

  const { data: answerData, isLoading: isLoadingAnswer } =
    useGetEngagementAnswerDetails(answerId)
  const { mutateAsync: updateAnswerDetails } = useUpdateEngagementAnswerDetails()

  const {
    data: repliesData,
    isLoading: isLoadingReplies,
    isRefetching: isRefetchingReplies,
    refetch: refetchReplies,
  } = useGetEngagementAnswerReplies(answerId)
  const replies = repliesData?.results || []

  const { mutateAsync: sendReply, isLoading: isLoadingSendReply } =
    useCreateEngagementAnswerReply(answerId)

  const commentsBottomRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    commentsBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [isLoadingSendReply, commentsBottomRef])

  const handleSendReply = async () => {
    if (replyInputValue) {
      setReplyInputValue('')
      await sendReply({ text: replyInputValue })
      await refetchReplies()
      commentsBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }
  const avatarImageId = answerData?.answer_text ? '3D278' : '3D066'
  const isAcknowledged = !!answerData?.acknowledged
  const showStatusPopup = useShowStatusPopup()

  return (
    <VStack space="s-16">
      {isLoadingAnswer ? (
        <ItemSkeleton />
      ) : answerData ? (
        <Item>
          <Item.Avatar>
            <Avatar
              image={{
                default: `https://assets.revolut.com/assets/3d-images-business/${avatarImageId}.png`,
                '2x': `https://assets.revolut.com/assets/3d-images-business/${avatarImageId}@2x.png`,
                '3x': `https://assets.revolut.com/assets/3d-images-business/${avatarImageId}@3x.png`,
              }}
            />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              {answerData.answer_text || (
                <Text color={Token.color.greyTone50}>
                  This answer does not have text comment
                </Text>
              )}
            </Item.Title>
            <Item.Description>
              <VStack pt="s-4" space="s-8">
                {formatDateTime(answerData.creation_date_time)}
                <ActionButton
                  useIcon={isAcknowledged ? '16/CheckCircle' : undefined}
                  variant={isAcknowledged ? 'accent' : undefined}
                  size="xs"
                  onClick={async () => {
                    try {
                      const res = await updateAnswerDetails([
                        answerData.id,
                        { ...answerData, acknowledged: !answerData.acknowledged },
                      ])
                      showStatusPopup({
                        title: res.data.acknowledged
                          ? 'Answer was acknowledged'
                          : 'Answer was not acknowledged',
                      })
                    } catch (e) {
                      showStatusPopup({
                        status: 'error',
                        title: 'Could not update acknowledgement status',
                        description: getMessageFromApiError(e),
                      })
                    }
                  }}
                >
                  {isAcknowledged ? 'Acknowledged' : 'Acknowledge'}
                </ActionButton>
              </VStack>
            </Item.Description>
          </Item.Content>
        </Item>
      ) : (
        <Placeholder mt="s-64">
          <Placeholder.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D028.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@3x.png',
            }}
          />
          <Placeholder.Title>Answer was not found</Placeholder.Title>
          <Placeholder.Actions>
            <TextButton onClick={closeSidebar}>Close sidebar</TextButton>
          </Placeholder.Actions>
        </Placeholder>
      )}
      {!replies.length && isLoadingReplies ? (
        <>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </>
      ) : (
        <>
          {replies.map(reply => (
            <Widget key={reply.id} p="s-16">
              <VStack space="s-16">
                <EmployeeUserWithAvatar {...reply.employee} />
                <Text>{reply.text}</Text>
              </VStack>
            </Widget>
          ))}
          {(isLoadingSendReply || isRefetchingReplies) && (
            <Dots color={Token.color.greyTone50} duration={900} />
          )}
          <div ref={commentsBottomRef} />
        </>
      )}
      <Side.Actions>
        <Input
          label="Reply"
          value={replyInputValue}
          onChange={e => setReplyInputValue(e.currentTarget.value)}
          onKeyDown={async e => {
            if (e.key === 'Enter') {
              await handleSendReply()
            }
          }}
          renderAction={() => {
            const disabled = isLoadingReplies || isLoadingSendReply

            return (
              <IconButton
                aria-label="Send reply"
                color={disabled ? Token.color.greyTone20 : Token.color.blue}
                disabled={isLoadingSendReply}
                useIcon="SendMessage"
                onClick={handleSendReply}
              />
            )
          }}
        />
      </Side.Actions>
    </VStack>
  )
}
