import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'
import { Bar, Box, Color, Flex, InputGroup, Text, Widget } from '@revolut/ui-kit'

import { selectorKeys } from '@src/constants/api'
import { EmployeeInterface, RightToWorkInterface } from '@src/interfaces/employees'
import { employeesRequests, rightToWorkRequests } from '@src/api/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import EmployeeDocSectionLape from '@src/features/FormTabs/Employee/EmployeeDocSectionLape'
import { navigateReplace } from '@src/actions/RouterActions'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { DeleteButtonLape } from '../FormButtons'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useGetSelectors } from '@src/api/selectors'
import { IdAndName } from '@src/interfaces'

const RightToWorkForm = () => {
  const { values } = useLapeContext<RightToWorkInterface>()
  const [employee, setEmployee] = useState<EmployeeInterface>()
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()

  const fetchEmployee = async () => {
    const { data } = await employeesRequests.getItem(employeeId)
    setEmployee(data)
  }

  useEffect(() => {
    window.scroll(0, 0)
    fetchEmployee()
  }, [])

  useEffect(() => {
    if (values.id && !id) {
      navigateReplace(
        pathToUrl(ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.GENERAL, {
          employeeId,
          id: values.id,
        }),
      )
    }
  }, [values.id])

  const title = values.id
    ? `Right to work ${employee ? `for ${employee.full_name}` : ''}`
    : `New right to work ${employee ? `- ${employee.full_name}` : ''}`

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.RIGHT_TO_WORK, {
    id: employeeId,
  })

  const isTemporaryResidenceType = values.right_to_work_type?.id === 'temporary_residence'
  const isIndefiniteResidenceType =
    values.right_to_work_type?.id === 'indefinite_residence'

  const { data: countries } = useGetSelectors<IdAndName & { country_code: string }>(
    selectorKeys.countries,
  )
  const defaultCountry = countries?.find(country => country.country_code === 'GB') || null

  useEffect(() => {
    if (!values.country && !values.citizenship_country && defaultCountry) {
      values.country = defaultCountry
      values.citizenship_country = defaultCountry
    }
  }, [defaultCountry])

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={backUrl} />

      <PageBody>
        {values.id && (
          <Bar>
            <DeleteButtonLape
              data={values}
              backUrl={backUrl}
              deleteApi={rightToWorkRequests.delete!}
              title="right to work"
            />
          </Bar>
        )}

        <AutoStepper>
          <NewStepperTitle title="Right to work details" />
          <InputGroup>
            <LapeRadioSelectInput
              label="Right to work country"
              name="country"
              selector={selectorKeys.countries}
            />
            <LapeRadioSelectInput
              label="Citizenship country"
              name="citizenship_country"
              selector={selectorKeys.countries}
            />
            <LapeRadioSelectInput
              label="Type of right to work"
              name="right_to_work_type"
              selector={selectorKeys.right_to_work_types}
            />
            {isIndefiniteResidenceType && (
              <LapeRadioSelectInput
                label="Type of indefinite residence"
                name="indefinite_residence_type"
                selector={selectorKeys.indefinite_residence_types}
              />
            )}
            {isTemporaryResidenceType && (
              <LapeRadioSelectInput
                label="Type of temporary residence"
                name="temporary_residence_type"
                selector={selectorKeys.temporary_residence_types}
              />
            )}
            <LapeRadioSelectInput
              label="Right to work document"
              name="document_type"
              selector={selectorKeys.identification_document_types}
            />
            <LapeNewInput label="Document number" name="document_number" required />

            <InputGroup variant="horizontal">
              <LapeDatePickerInput
                label="Document valid from date"
                name="document_valid_from_date"
                required
              />
              <LapeDatePickerInput
                label="Document expiry date"
                name="document_expiry_date"
              />
            </InputGroup>

            <LapeDatePickerInput
              label="Last verified on"
              name="last_verified_on_date"
              required
            />
            <LapeRadioSelectInput
              label="Verified by"
              name="last_verified_by"
              selector={selectorKeys.employee}
            />

            {(isIndefiniteResidenceType || isTemporaryResidenceType) && (
              <Widget p="s-16" backgroundColor="background">
                <Text use="p" fontSize="primary" pb="s-6">
                  Sponsored by the company
                </Text>
                <Flex my="s-12">
                  <LapeNewRadioButtons
                    name="is_sponsored_by_company"
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]}
                    labelProps={{ pr: '26px' }}
                    defaultOptionIndex={0}
                  />
                </Flex>
              </Widget>
            )}
            <LapeNewTextArea
              label="Notes"
              name="notes"
              message="Add additional information"
              rows={3}
            />
          </InputGroup>

          <NewStepperTitle title="Document" />
          <Box mb="s-24">
            <InputGroup>
              <EmployeeDocSectionLape employee={employee} required />
            </InputGroup>
            <Box color={Color.GREY_TONE_50} mt="s-12">
              Please provide evidence of the right to work for this employee. You can
              select one or more documents already uploaded in the employee's profile, or
              upload a new one now
            </Box>
          </Box>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          onAfterSubmit={() => {
            if (employeeId) {
              navigateReplace(
                `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: employeeId,
                })}#rightToWork`,
              )
            }
          }}
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(RightToWorkForm)
