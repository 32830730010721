import { useScheduleInterviewContext } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ScheduleInterviewInterface } from '@src/interfaces/interviewTool'
import { useGetSelectors } from '@src/api/selectors'
import { Timezone } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { getCurrentTimezone } from '@src/utils/timezones'
import { useEffect } from 'react'

export const useSetInitialTimezone = () => {
  const { setTimeZone } = useScheduleInterviewContext()
  const { values } = useLapeContext<ScheduleInterviewInterface>()
  const { data: timezones } = useGetSelectors<Timezone>(selectorKeys.timezones)
  const currentTimezoneId = getCurrentTimezone()

  const timeZoneId = values.scheduling_timezone
    ? String(values.scheduling_timezone.id)
    : currentTimezoneId

  useEffect(() => {
    const currentTimezone = timezones?.find(item => item.id === timeZoneId)

    if (currentTimezone) {
      values.scheduling_timezone = currentTimezone
      setTimeZone(values.scheduling_timezone)
    }
  }, [timezones])
}
