import React from 'react'
import { Avatar, chain, Item, Widget, Text, Token, Box } from '@revolut/ui-kit'

import { getSurveyAdminResultCommentsTableRequests } from '@src/api/engagement'
import { TablePreview } from '@src/components/TablePreview/TablePreview'
import { EngagementResultsComment } from '@src/interfaces/engagement'
import { formatDateTime } from '@src/utils/format'
import { useTableReturnType } from '@src/components/TableV2/hooks'

interface Props {
  table: useTableReturnType<EngagementResultsComment>
}
export const Comments = ({ table }: Props) => {
  return (
    <Widget p="s-12">
      <TablePreview<EngagementResultsComment>
        title={
          <Box ml="s-12">
            <Text color={Token.color.foreground} variant="h4">
              {chain('Comments', table.count)}
            </Text>
          </Box>
        }
        table={table}
        api={getSurveyAdminResultCommentsTableRequests()}
        emptyState={{
          title: 'No comments found',
          icon: 'Chat',
        }}
        row={comment => {
          return (
            <Item my="s-12">
              <Item.Avatar>
                <Avatar
                  image={{
                    default: 'https://assets.revolut.com/assets/3d-images-v2/3D278.png',
                    '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D278@2x.png',
                    '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D278@3x.png',
                  }}
                />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{comment.answer_text}</Item.Title>
                <Item.Description>
                  {formatDateTime(comment.creation_date_time)}
                </Item.Description>
              </Item.Content>
            </Item>
          )
        }}
      />
    </Widget>
  )
}
