import { selectorKeys } from '@src/constants/api'
import React from 'react'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { IdAndName } from '@src/interfaces'
import { useScheduleInterviewContext } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import { Timezone } from '@src/interfaces/selectors'
import { useSetInitialTimezone } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks/useSetInitialTimezone'

type Props = {
  onChangeTimeZone?: (timeZone?: Timezone | null) => void
  disabled?: boolean
}

const TimezoneSelect = ({ disabled, onChangeTimeZone }: Props) => {
  const { setTimeZone } = useScheduleInterviewContext()
  useSetInitialTimezone()

  return (
    <LapeRadioSelectInput<IdAndName<string>>
      label="Time zone"
      name="scheduling_timezone"
      selector={selectorKeys.timezones}
      disabled={disabled}
      onAfterChange={val => {
        onChangeTimeZone?.(val)

        if (val) {
          setTimeZone(val)
        }
      }}
      useQuery
    />
  )
}

export default TimezoneSelect
