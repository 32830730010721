import {
  Button,
  Header,
  Icon,
  MoreBar,
  Popup,
  StatusPopup,
  Token,
  useStatusPopup,
  useToggle,
} from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { Questionnaire } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import {
  PerfReviewRequestFeedbackInterface,
  PerformanceSelector,
  RequestFeedbackInterface,
  ReviewCategory,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import {
  checkCanRequestFeedback,
  checkCanViewPIPv2Settings,
  checkIsPipPeriod,
  checkIsProbationPeriod,
  checkRejectAvailability,
  getReviewer,
} from '../../Preview/Performance/Common/utils'
import { PermissionTypes } from '@src/store/auth/types'
import { useGetPeriodTypes } from '@src/utils/performance'
import { rejectReview } from '@src/api/performanceReview'
import { ReviewCycleCategory, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import {
  closeProbationCycle,
  deleteEmployeeCycle,
  regenerateProbationScorecards,
} from '@src/api/probationEmployees'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'
import RequestFeedback from '../../Preview/Performance/PerformanceReview/RequestFeedback'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ProbationCheckpoints } from '@src/interfaces/probationReview'
import { CreateProbation } from '../../Preview/components/Buttons/Actions/CreateProbation'
import { CreatePip } from '../../Preview/components/Buttons/Actions/CreatePip'
import { NominateForPromotion } from '../../Preview/components/Buttons/Actions/NominateForPromotion'
import Table from '@src/components/TableV2/Table'
import SideBar from '@components/SideBar/SideBar'

import { isFuture, isPast } from 'date-fns'
import { ProbationPipAdditionalInfo } from './ProbationPipAdditionalInfo'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

type FloatValues = 'left' | 'right'

type Props = {
  selectedPeriod?: PerformanceSelector
  data: EmployeeInterface
  requests?: (PerfReviewRequestFeedbackInterface | RequestFeedbackInterface)[]
  fetchingRequests: boolean
  checkpoints?: ProbationCheckpoints
  refetchRequests: () => void
  float?: FloatValues
  cycleTimelineEvents?: SingleTimelineEventInterface[]
}

export const HeaderActions = ({
  selectedPeriod,
  data,
  requests,
  fetchingRequests,
  checkpoints,
  float = 'left',
  cycleTimelineEvents,
  refetchRequests,
}: Props) => {
  const { data: performanceSettings } = useGetPerformanceSettings()
  const canViewUpwards =
    performanceSettings?.enable_upwards_reviews &&
    selectedPeriod?.category === ReviewCycleCategory.Performance
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const [userRequest, setUserRequest] = useState<
    PerfReviewRequestFeedbackInterface | RequestFeedbackInterface
  >()
  const [popupOpened, setPopupOpened] = useState(false)
  const [isOpenCycleSidebar, cycleSideBarToggler] = useToggle()
  const showStatusPopup = useShowStatusPopup()

  const [deleteEmployeeCyclePending, setDeleteEmployeeCyclePending] = useState(false)
  const permissions = useSelector(selectPermissions)
  const queryClient = useQueryClient()
  const user = useSelector(selectUser)
  const statusPopup = useStatusPopup()

  const reviewPerformance = cycleTimelineEvents?.find(
    ({ category }) => category === 'review',
  )

  const isOngoingProbationOrPip =
    selectedPeriod &&
    [ReviewCycleCategory.Probation, ReviewCycleCategory.PIP].includes(
      selectedPeriod?.category,
    ) &&
    selectedPeriod.status === ReviewCycleStatus.ongoing

  const isPerformanceCycleStarted =
    reviewPerformance &&
    isPast(new Date(reviewPerformance.start_period)) &&
    isFuture(new Date(reviewPerformance.end_period))

  const canViewCycleExtraInfo =
    selectedPeriod && selectedPeriod?.category !== ReviewCycleCategory.Performance

  const canRequestReview =
    typeof performanceSettings?.enable_peer_reviews !== 'undefined'
      ? performanceSettings?.enable_peer_reviews &&
        (isPerformanceCycleStarted || isOngoingProbationOrPip)
      : checkCanRequestFeedback(data) &&
        (isPerformanceCycleStarted || isOngoingProbationOrPip)

  const canRegenerateScorecards = data.field_options.permissions?.includes(
    PermissionTypes.ProbationCommitteeHRPermissions,
  )
  const canDeleteProbationCycle = permissions.includes(
    PermissionTypes.DeleteEmployeePerformanceCycle,
  )
  const isProbationPeriod = checkIsProbationPeriod(data)
  const isPipPeriod = checkIsPipPeriod(selectedPeriod)
  const canChangeCycleSettings =
    canRegenerateScorecards && (isProbationPeriod || isPipPeriod)

  const { isNewFlow, isNewProbation, isOldProbation, isPIPv2, isPIP, isNewPerformance } =
    useGetPeriodTypes(selectedPeriod)
  const isProbationOrPip = isNewProbation || isOldProbation || isPIPv2 || isPIP

  const canViewPIPv2Settings = checkCanViewPIPv2Settings(selectedPeriod)
  const canRejectReview = checkRejectAvailability(userRequest)

  const probationEnabled = performanceSettings?.enable_probation

  useEffect(() => {
    setSidebarOpened(false)
  }, [selectedPeriod])

  useEffect(() => {
    const foundRequest = requests?.find(
      request => getReviewer(request, isNewPerformance)?.id === user.id,
    )
    setUserRequest(foundRequest)
  }, [requests])

  const handleFetchRequests = () => {
    queryClient.invalidateQueries({
      predicate: ({ queryHash }) => !queryHash.includes('performanceSettings'),
    })
  }

  const showError = (text: string) =>
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{text}</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )

  const showSuccess = (text: string) => {
    statusPopup.show(
      <StatusPopup variant="success">
        <StatusPopup.Title>{text}</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const handleReject = async () => {
    await rejectReview(data.id, userRequest?.id!.toString()!)
    showSuccess('Review request successfully rejected.')
    handleFetchRequests()
  }

  const handleOpenCycleSettings = () => {
    if (isPIP) {
      navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.PIP, { id: data.id }))
    } else {
      const url =
        selectedPeriod?.category === ReviewCycleCategory.PIP
          ? ROUTES.FORMS.PIP_OVERVIEW.CYCLE_SETTINGS
          : ROUTES.FORMS.PROBATION_OVERVIEW.CYCLE_SETTINGS
      navigateTo(pathToUrl(url, { employeeId: data.id, cycleId: selectedPeriod?.id }))
    }
  }

  const handleOpenPipSettings = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.PIP.SETTINGS, {
        employeeId: data.id,
        id: selectedPeriod?.id,
      }),
    )
  }

  const handleRegenerateScorecards = async () => {
    try {
      await regenerateProbationScorecards(
        getReviewCycleIdWithoutPrefix(selectedPeriod?.id),
      )
      refetchRequests()
      showSuccess('Scorecards regenerated')
    } catch (e) {
      showError('Could not regenerate scorecards')
    }
  }

  const handleCloseProbation = async () => {
    try {
      await closeProbationCycle(getReviewCycleIdWithoutPrefix(selectedPeriod?.id))
      refetchRequests()
      showSuccess('Probation cycle closed')
    } catch (e) {
      showError('Could not close probation')
    }
  }

  const showDeleteSuccess = () => {
    statusPopup.show(
      <StatusPopup
        variant="success"
        onClose={() => {
          navigateTo(
            pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
              id: data.id,
            }),
          )
        }}
      >
        <StatusPopup.Title>Cycle was successfully deleted</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const onDeleteEmployeeCycle = async () => {
    setDeleteEmployeeCyclePending(true)
    try {
      await deleteEmployeeCycle(getReviewCycleIdWithoutPrefix(selectedPeriod?.id))
      setPopupOpened(false)
      showDeleteSuccess()
      refetchRequests()
    } catch (e) {
      showStatusPopup({
        status: 'error',
        title: `Couldn't delete ${selectedPeriod?.category} cycle`,
        description: getStringMessageFromError(e),
      })
    } finally {
      setDeleteEmployeeCyclePending(false)
    }
  }

  const onViewUpwardsClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE.UPWARDS, {
        id: data.id,
      }),
    )
  }

  const isLeftFloat = float === 'left'

  const WrapperComponent = isLeftFloat ? MoreBar : Table.Widget.MoreBar

  const actionProps = {
    data,
    isDropdownItem: false,
  }

  return (
    <>
      <WrapperComponent maxCount={isLeftFloat ? 4 : 2}>
        {isProbationOrPip && selectedPeriod && (
          <MoreBar.Action
            use="button"
            useIcon={<Icon size={16} name="Time" />}
            onClick={() => {
              navigateTo(
                pathToUrl(
                  isPIPv2
                    ? ROUTES.FORMS.PIP_OVERVIEW.PIP_GOALS
                    : ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS,
                  {
                    employeeId: data.id,
                    cycleId: selectedPeriod.id,
                  },
                ),
              )
            }}
          >
            Open {isPIPv2 ? 'PIP' : 'probation'} timeline
          </MoreBar.Action>
        )}

        <CreatePip {...actionProps} />
        <CreateProbation {...actionProps} />
        <NominateForPromotion icon="Megaphone" {...actionProps} />

        {selectedPeriod ? (
          <>
            {canRequestReview && (
              <MoreBar.Action
                use="button"
                useIcon={<Icon size={16} name="Plus" />}
                onClick={() => setSidebarOpened(!sidebarOpened)}
              >
                Request review
              </MoreBar.Action>
            )}
            {canRejectReview && (
              <MoreBar.Action
                use="button"
                useIcon={<Icon size={16} name="Reverted" color={Token.color.red} />}
                onClick={handleReject}
              >
                Reject review request
              </MoreBar.Action>
            )}

            {canViewUpwards && (
              <MoreBar.Action useIcon={Questionnaire} onClick={onViewUpwardsClick}>
                View upwards review
              </MoreBar.Action>
            )}

            {canViewCycleExtraInfo && (
              <MoreBar.Action useIcon="Info" onClick={cycleSideBarToggler.on}>
                View cycle additional data
              </MoreBar.Action>
            )}

            {isProbationOrPip && canChangeCycleSettings && (
              <MoreBar.Action
                use="button"
                useIcon={<Icon size={16} name="CandlestickChart" />}
                onClick={handleOpenCycleSettings}
              >
                Cycle settings
              </MoreBar.Action>
            )}
            {isPIPv2 && canViewPIPv2Settings && (
              <MoreBar.Action
                use="button"
                useIcon={<Icon size={16} name="CandlestickChart" />}
                onClick={handleOpenPipSettings}
              >
                Setup
              </MoreBar.Action>
            )}
            {canRegenerateScorecards && isNewProbation && isProbationPeriod && (
              <>
                <MoreBar.Action
                  use="button"
                  useIcon={<Icon size={16} name="16/ArrowRecurring" />}
                  onClick={handleRegenerateScorecards}
                >
                  Regenerate scorecards
                </MoreBar.Action>
                {probationEnabled && (
                  <MoreBar.Action
                    use="button"
                    useIcon="Cross"
                    onClick={handleCloseProbation}
                  >
                    Close probation cycle
                  </MoreBar.Action>
                )}
              </>
            )}
            {isNewProbation && canDeleteProbationCycle && (
              <MoreBar.Action
                use="button"
                useIcon="Delete"
                variant="negative"
                onClick={() => setPopupOpened(true)}
              >
                Delete probation cycle
              </MoreBar.Action>
            )}
            {(isPIPv2 || isPIP) && (
              <MoreBar.Action
                variant="negative"
                useIcon="Delete"
                onClick={() => setPopupOpened(true)}
              >
                Delete PIP cycle
              </MoreBar.Action>
            )}
          </>
        ) : null}
      </WrapperComponent>
      {canRequestReview && sidebarOpened && selectedPeriod && (
        <RequestFeedback
          canRequest={canRequestReview}
          performanceCycle={selectedPeriod}
          isNewFlow={isNewFlow}
          category={(() => {
            if (isNewProbation) {
              return ReviewCategory.Probation
            }
            if (isPIPv2) {
              return ReviewCategory.PIP_V2
            }
            return ReviewCategory.Performance
          })()}
          onClose={() => setSidebarOpened(false)}
          id={data.id}
          requests={requests}
          onAddRequest={() => handleFetchRequests()}
          checkpoints={checkpoints?.checkpoints}
          fetching={fetchingRequests}
        />
      )}

      {selectedPeriod && (
        <SideBar
          variant="wide"
          title="Cycle Additional Data"
          isOpen={isOpenCycleSidebar}
          onClose={cycleSideBarToggler.off}
        >
          <ProbationPipAdditionalInfo data={data} selectedPeriod={selectedPeriod} />
        </SideBar>
      )}

      {popupOpened && (
        <Popup open onClose={() => setPopupOpened(false)} variant="bottom-sheet">
          <Header variant="bottom-sheet">
            <Header.Title>Are you sure you want to delete this cycle?</Header.Title>
          </Header>
          <Popup.Actions horizontal>
            <Button variant="secondary" onClick={() => setPopupOpened(false)}>
              Cancel
            </Button>
            <Button
              elevated
              onClick={onDeleteEmployeeCycle}
              pending={deleteEmployeeCyclePending}
            >
              Delete
            </Button>
          </Popup.Actions>
        </Popup>
      )}
    </>
  )
}
