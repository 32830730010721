import React, { useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  chain,
  Item,
  ItemSkeleton,
  Text,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'

import {
  getPayGroupTimelineTableRequests,
  useGetPayRollChangeDetails,
} from '@src/api/payrollV2'
import ButtonFilter from '@src/components/ButtonFilters/ButtonFilter'
import SideBar from '@src/components/SideBar/SideBar'
import { useTable } from '@src/components/TableV2/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { selectorKeys } from '@src/constants/api'
import {
  payCycleTimelineCategoryColumn,
  payCycleTimelineEffectiveDateTimeColumn,
  payCycleTimelineEmployeeNameColumn,
  payCycleTimelineChangeColumn,
  payCycleTimelineActionsColumn,
} from '@src/constants/columns/payCycleTimeline'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PayrollTimelineEventInterface } from '@src/interfaces/payrollV2'
import { getAvatarUrl, getInitials } from '@src/utils/employees'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

import { CommonTabProps } from '../common'
import { DetailsSidebarContent } from '../common/DetailsSidebarContent'
import { EVENT_TYPES_OPTIONS, useTimelineFilters } from './common'

const getRow = (
  onClick: (id: number) => void,
): RowInterface<PayrollTimelineEventInterface> => ({
  cells: [
    {
      ...payCycleTimelineEmployeeNameColumn,
      width: 360,
    },
    {
      ...payCycleTimelineEffectiveDateTimeColumn,
      width: 150,
    },
    {
      ...payCycleTimelineCategoryColumn,
      width: 120,
    },
    {
      ...payCycleTimelineChangeColumn,
      width: 300,
    },
    {
      ...payCycleTimelineActionsColumn,
      width: 200,
      insert: ({ data }) => {
        if (data.payroll_event !== 'data_change') {
          return null
        }
        return (
          <TextButton
            onClick={() => {
              onClick(data.content.id)
            }}
          >
            Preview details
          </TextButton>
        )
      },
    },
  ],
})

export const PayCycleTabChanges = ({
  data: initialPayCycle,
  selectedCycle,
}: CommonTabProps) => {
  const [selectedChangeId, setSelectedChangeId] = useState<number>()

  const timelineFilters = useTimelineFilters(initialPayCycle, selectedCycle)

  const {
    data: changeDetails,
    isLoading: isLoadingChangeDetails,
    isError: isChangeDetailsError,
  } = useGetPayRollChangeDetails(selectedChangeId)
  const selectedEmployee = changeDetails?.employee

  const table = useTable<PayrollTimelineEventInterface>(
    getPayGroupTimelineTableRequests(initialPayCycle.pay_group.id),
    timelineFilters.all,
    undefined,
    { disableQuery: true },
  )

  useEffect(() => {
    table.onFilterChange(timelineFilters.all)
  }, [timelineFilters.allStringified])

  const row = useMemo(() => getRow(id => setSelectedChangeId(id)), [])

  return (
    <>
      <Table.Widget>
        <Table.Widget.Filters>
          <ButtonFilter
            title="Dates range"
            selector={selectorKeys.none}
            type="DateRange"
            {...timelineFilters.dates}
          />
          <ButtonFilter
            title="Event types"
            selector={() =>
              Promise.resolve({
                options: EVENT_TYPES_OPTIONS,
              })
            }
            type="MultiSelect"
            {...timelineFilters.eventTypes}
          />
        </Table.Widget.Filters>
        <Table.Widget.Table>
          <AdjustableTable
            hideCount
            name={TableNames.PayCycleEmployeeReports}
            row={row}
            useWindowScroll
            emptyState={<EmptyTableRaw title="No changes found" />}
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>
      <SideBar
        title=""
        variant="wide"
        sideProps={{ resizable: true }}
        isOpen={!!selectedChangeId}
        onClose={() => setSelectedChangeId(undefined)}
      >
        {isLoadingChangeDetails ? (
          <VStack space="s-16">
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </VStack>
        ) : (
          <VStack space="s-20">
            {selectedEmployee ? (
              <Item>
                <Item.Avatar>
                  <Avatar
                    image={getAvatarUrl(selectedEmployee.avatar)}
                    label={getInitials(selectedEmployee.full_name || '')}
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    <Text>{selectedEmployee.full_name}</Text>
                  </Item.Title>
                  {!!(selectedEmployee.job_title || selectedEmployee.seniority) && (
                    <Item.Description>
                      <Text color={Token.color.greyTone50}>
                        {chain(
                          selectedEmployee.job_title,
                          selectedEmployee.seniority?.name,
                        )}
                      </Text>
                    </Item.Description>
                  )}
                </Item.Content>
              </Item>
            ) : (
              <ItemSkeleton />
            )}
            <DetailsSidebarContent
              isLoading={isLoadingChangeDetails}
              isError={isChangeDetailsError}
              timelineChanges={changeDetails ? [changeDetails] : undefined}
              onClose={() => setSelectedChangeId(undefined)}
            />
          </VStack>
        )}
      </SideBar>
    </>
  )
}
