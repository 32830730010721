import axios, {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios'

import { API_ENDPOINT, env, Environments, getTenantEndpoints } from '../constants/api'
import { pushError } from '../store/notifications/actions'
import { ApiHandlerInterface, ApiVersion } from '@src/interfaces'

/** @deprecated */
export const handleError = (error: AxiosError) => {
  pushError({ error })
  throw error
}

export const getConfig = (config?: AxiosRequestConfig): AxiosRequestConfig => {
  const cacheHeaders: AxiosRequestHeaders | undefined =
    env === Environments.developmentSubdomains
      ? {
          'Cache-Control': 'no-cache',
          Expires: '0',
        }
      : undefined

  if (!config) {
    return {
      withCredentials: true,
      headers: cacheHeaders,
    }
  }

  const { headers, ...rest } = config

  return {
    withCredentials: true,
    headers: {
      ...headers,
      ...(cacheHeaders || {}),
    },
    ...rest,
  }
}

// if BE endpoint uses Captcha validation it has different error handling logic (like in Careers and Reset Password)
export const normalizeCaptchaError = (error: AxiosError) => {
  if (
    error?.response?.data?.errors &&
    error?.response?.data?.message === 'validation-error'
  ) {
    // eslint-disable-next-line no-throw-literal
    throw {
      ...error,
      response: {
        ...error.response,
        data: { ...error.response.data.errors },
      },
    }
  }

  throw error
}

export const logoutTenant = (tenant: string) =>
  axios.post(
    `${getTenantEndpoints(tenant)?.apiEndpoint}external/v1/logout`,
    undefined,
    getConfig(),
  )

const getRoute = (route: string, apiVersion: ApiVersion, isExternal: boolean) => {
  return `${API_ENDPOINT()}${isExternal ? 'external/' : ''}${apiVersion}${route}`
}

/** @deprecated Use `apiV2` instead */
export const apiWithoutHandling: ApiHandlerInterface = {
  get: <T>(
    route: string,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ) => {
    return axios.get<T>(getRoute(route, apiVersion, isExternal), getConfig(config))
  },
  post: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios.post<T>(getRoute(route, apiVersion, isExternal), data, getConfig(config))
  },
  patch: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios.patch<T>(
      getRoute(route, apiVersion, isExternal),
      data,
      getConfig(config),
    )
  },
  put: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios.put<T>(getRoute(route, apiVersion, isExternal), data, getConfig(config))
  },
  delete: <T = any>(
    route: string,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise => {
    return axios.delete<T>(getRoute(route, apiVersion, isExternal), getConfig(config))
  },
}

export const apiV2 = apiWithoutHandling

/** @deprecated Use `apiV2` instead */
export const api: ApiHandlerInterface = {
  get: <T>(
    route: string,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ) => {
    return axios
      .get<T>(getRoute(route, apiVersion, isExternal), getConfig(config))
      .catch(handleError)
  },
  post: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios
      .post<T>(getRoute(route, apiVersion, isExternal), data, getConfig(config))
      .catch(handleError)
  },
  patch: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios
      .patch<T>(getRoute(route, apiVersion, isExternal), data, getConfig(config))
      .catch(handleError)
  },
  put: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios
      .put<T>(getRoute(route, apiVersion, isExternal), data, getConfig(config))
      .catch(handleError)
  },
  delete: <T = any>(
    route: string,
    config?: AxiosRequestConfig,
    apiVersion: ApiVersion = 'v1',
    isExternal = false,
  ): AxiosPromise<T> => {
    return axios
      .delete<T>(getRoute(route, apiVersion, isExternal), getConfig(config))
      .catch(handleError)
  },
}
