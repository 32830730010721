import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { GrowthPlanPerformanceMetricInterface } from '@src/interfaces/growthPlans'
import { selectorKeys } from '@src/constants/api'
import { TableCellShow } from '@components/TableV2/HeaderCell'
import { Ellipsis, HStack, Icon, Tag, Text, Token } from '@revolut/ui-kit'
import { ColorTag, ColorTagVariant } from '@components/ColorTag/ColorTag'
import { Capitalize } from '@components/CommonSC/General'
import { getComparedRatingsProps } from '@src/constants/columns/performanceSummary'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export interface RowProps {
  currentSeniorityName: string
  targetSeniorityName: string
}

interface RatingHeaderProps {
  title: string
  tagTitle: string
  tagVariant: ColorTagVariant
}

const RatingHeader = ({ title, tagTitle, tagVariant }: RatingHeaderProps) => (
  <TableCellShow>
    <HStack align="center" space="s-6">
      <Ellipsis>{title}</Ellipsis>
      <ColorTag icon="Profile" variant={tagVariant}>
        <Capitalize>{tagTitle}</Capitalize>
      </ColorTag>
    </HStack>
  </TableCellShow>
)

interface RatingTagProps {
  title: string
  color: string
}

const RatingTag = ({ title, color }: RatingTagProps) => (
  <Tag color={color}>
    <Capitalize>
      <Text color={Token.color.foreground}>{title}</Text>
    </Capitalize>
  </Tag>
)

export const functionalSkillNameColumn: ColumnInterface<GrowthPlanPerformanceMetricInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Skill',
    dynamicHyperlinks: ({ id }) => pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id }),
  }

export const culturalValueNameColumn: ColumnInterface<GrowthPlanPerformanceMetricInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Value',
    dynamicHyperlinks: ({ id }) => pathToUrl(ROUTES.FORMS.VALUE.PREVIEW, { id }),
  }

export const getCurrentRatingColumn = (
  props: RowProps,
): ColumnInterface<GrowthPlanPerformanceMetricInterface> => ({
  type: CellTypes.insert,
  idPoint: 'rating',
  dataPoint: 'rating',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Current rating',
  colors: ({ rating }) => (!rating ? Token.color.greyTone50 : 'inherit'),
  insert: ({ data: { rating, competency_level } }) => {
    const ratingProps = getComparedRatingsProps(rating, competency_level)

    return rating ? (
      <RatingTag title={PerformanceRatingTitle[rating]} color={ratingProps.color} />
    ) : (
      'N/A'
    )
  },
  renderCustomHeader: () => (
    <RatingHeader
      title="Current"
      tagTitle={props.currentSeniorityName}
      tagVariant="neutral"
    />
  ),
})

export const getTargetRatingColumn = (
  props: RowProps,
): ColumnInterface<GrowthPlanPerformanceMetricInterface> => ({
  type: CellTypes.insert,
  idPoint: 'competency_level',
  dataPoint: 'competency_level',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Target rating',
  colors: ({ competency_level }) =>
    !competency_level ? Token.color.greyTone50 : 'inherit',
  insert: ({ data: { rating, competency_level } }) => {
    const ratingProps = getComparedRatingsProps(rating, competency_level)

    return competency_level ? (
      <HStack align="center" space="s-24">
        <RatingTag
          title={PerformanceRatingTitle[competency_level]}
          color={Token.color.teal_30}
        />
        {rating && (
          <Icon size={16} name={ratingProps.icon} color={ratingProps.iconColor} />
        )}
      </HStack>
    ) : (
      'N/A'
    )
  },
  renderCustomHeader: () => (
    <RatingHeader
      title="Target"
      tagTitle={props.targetSeniorityName}
      tagVariant="outlined"
    />
  ),
})
