import React, { Suspense } from 'react'
import { Box, Skeleton } from '@revolut/ui-kit'
import { createGlobalStyle } from 'styled-components'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { editor as monacoEditor } from 'monaco-editor/esm/vs/editor/editor.api'

const MonacoEditor = React.lazy(() => import('react-monaco-editor'))

export interface NewResponsiveCodeEditorProps {
  disabled?: boolean
  language?: 'sql' | 'html' | 'json'
  name?: string
  readonly?: boolean
  onChange?: (value: string) => void
  onRun?: () => void
  onSave?: () => void
  value: string
  height?: number | string
  width?: number | string
  transparent?: boolean
  customOptions?: monacoEditor.IStandaloneEditorConstructionOptions
}

const GlobalEditorStyle = createGlobalStyle`
  .monaco-editor.transparent .monaco-editor-background,
  .monaco-editor.transparent .margin,
  .monaco-editor.transparent {    
      background-color: transparent !important;
  } 
`

const WhileMonacoLoading = (props: {
  height: number | string
  width: number | string
}) => {
  return (
    <Box {...props}>
      <Skeleton data-testid="monaco-skeleton" />
    </Box>
  )
}

const NewResponsiveCodeEditor = ({
  language = 'sql',
  name,
  onChange,
  onRun,
  onSave,
  readonly,
  value,
  height = '100%',
  width = '100%',
  transparent,
  customOptions,
  ...rest
}: NewResponsiveCodeEditorProps) => {
  const { theme } = useAppTheme()

  const options = {
    renderIndicators: false,
    selectOnLineNumbers: true,
    minimap: {
      enabled: false,
    },
    renderLineHighlight: 'none',
    scrollbar: {
      vertical: 'auto',
      horizontal: 'auto',
      alwaysConsumeMouseWheel: false,
    },
    readOnly: readonly,
    scrollBeyondLastLine: false,
    hideCursorInOverviewRuler: true,
    overviewRulerBorder: false,
    overviewRulerLanes: 0,
    automaticLayout: true,
  } as const

  /* eslint-disable no-bitwise */
  /* using bitwise OR to create a bit-pattern which refers to the combination of the keys */
  const handleEditorDidMount = (editor: any, monaco: any) => {
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, () => {
      onRun?.()
    })

    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, () => {
      onSave?.()
    })
  }

  return (
    <Suspense fallback={<WhileMonacoLoading height={height} width={width} />}>
      <MonacoEditor
        disabled
        editorDidMount={handleEditorDidMount}
        height={height}
        language={language}
        onChange={onChange}
        options={{ ...options, ...customOptions }}
        theme={theme === 'dark' ? 'vs-dark' : 'vs'}
        value={value}
        width={width}
        className={transparent ? 'transparent' : null}
        {...rest}
      />
      <GlobalEditorStyle />
    </Suspense>
  )
}

export default NewResponsiveCodeEditor
