import React, { useContext } from 'react'
import {
  Text,
  HStack,
  DetailsCell,
  Avatar,
  Item,
  Box,
  Tooltip,
  useTooltip,
  Token,
  opacity,
} from '@revolut/ui-kit'
import {
  Field,
  ParentType,
  PaycycleContext,
  determineParentType,
} from '../PaycyclePreview'
import { PaycycleItemIcon } from './PaycycleItemIcon'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { formatDate } from '@src/utils/format'
import { PayCycleDetailedReportInterface } from '@src/interfaces/payroll'
import { ArrowThinRight, ExclamationMarkSign, IconComponentType } from '@revolut/icons'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { capitalize, get } from 'lodash'
import { getStatusColor } from '@src/components/CommonSC/General'
import { Statuses } from '@src/interfaces'

export type RelevancyType = 'all' | 'issue' | 'change' | null

// DetailsCell won't support as={InternalLink} correctly, so here's a quick fix
const openLinkInNewTab = (url: string) => {
  if (window) {
    const tab = window.open(url, '_blank')
    tab?.focus()
  }
}

const checkRelevant = (parent: ParentType, field: Field | Field[]) => {
  if (!parent || !field) {
    return null
  }

  const fields = Array.isArray(field) ? field : [field]

  const hasIssue = fields.some(f => Object.keys(parent.field_issues)?.includes(f))
  if (hasIssue) {
    return 'issue'
  }

  const hasChange = fields.some(f => Object.keys(parent.field_changes)?.includes(f))
  if (hasChange) {
    return 'change'
  }

  return null
}

const getSafe = <T, K extends keyof T>(obj: T, key: K): T[K] => {
  return obj[key]
}

const linkToItem = (employeeId: string, item: string, itemId?: string) => {
  const routeMap = {
    contract_reports: ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW,
    benefits_reports: ROUTES.FORMS.EMPLOYEE_BENEFIT.PREVIEW,
    time_off_reports: ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW,
    bonus_reports: ROUTES.FORMS.EMPLOYEE.REWARDS.BONUSES,
    personal_report: ROUTES.FORMS.EMPLOYEE.PROFILE,
    termination_report: ROUTES.FORMS.EMPLOYEE.PROFILE,
  }
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  const route = routeMap[item] || routeMap.personal_report
  const isSpecialCase = [
    'bonus_reports',
    'personal_report',
    'termination_report',
  ].includes(item)

  const params = isSpecialCase ? { id: `${employeeId}` } : { employeeId, id: itemId }

  return pathToUrl(route, params)
}

type PayCycleItemProps = {
  title?: React.ReactNode
  field: Field | Field[]
  data: PayCycleDetailedReportInterface
  showRelevantOnly: RelevancyType
  type?: string
  insert?: string | JSX.Element
  style?: React.CSSProperties
  forceRelevancy?: 'issue' | 'change' | null
}
export const PayCycleItem = ({
  title,
  field,
  type,
  insert,
  data,
  showRelevantOnly,
  style,
  forceRelevancy,
}: PayCycleItemProps) => {
  const parent = useContext(PaycycleContext)
  const tooltip = useTooltip()
  if (!parent || !field) {
    return null
  }
  const parentInfo = determineParentType(parent)
  const relevancy = forceRelevancy || checkRelevant(parent, field)
  if (
    (showRelevantOnly === 'all' && relevancy === null) ||
    (showRelevantOnly === 'issue' && (relevancy === 'change' || relevancy === null)) ||
    (showRelevantOnly === 'change' && (relevancy === 'issue' || relevancy === null))
  ) {
    return null
  }

  let text = insert || parent[field as keyof typeof parent]
  if (type === 'date' && typeof text === 'string') {
    text = formatDate(text)
  }

  const link = linkToItem(
    `${data.employee.id}`,
    parentInfo?.type,
    `${getSafe(parent, parentInfo.itemId)}`,
  )

  const issue = get(parent.field_issues, field)

  let change = get(parent.field_changes, field)
  if (type === 'date' && typeof change === 'string') {
    change = formatDate(change)
  }

  const isAddition = relevancy === 'change' && !change

  return (
    <DetailsCell
      style={style}
      use="button"
      onClick={() => {
        openLinkInNewTab(link)
      }}
      {...tooltip.getAnchorProps()}
    >
      {title && <DetailsCell.Title>{title}</DetailsCell.Title>}

      <DetailsCell.Content>
        <HStack space="s-8" align="center">
          {relevancy === 'change' && !isAddition && (
            <>
              <Text color={opacity(Token.colorChannel.foreground, 0.4)}>
                {change || 'None'}
              </Text>
              <ArrowThinRight size={12} color={Token.color.black_40} />
            </>
          )}
          <Text>{text || '-'}</Text>
          <PaycycleItemIcon relevancy={relevancy} isAddition={isAddition} />
        </HStack>
      </DetailsCell.Content>
      {!!issue && (
        <Tooltip placement="left" {...tooltip.getTargetProps()}>
          {issue}
        </Tooltip>
      )}
    </DetailsCell>
  )
}

export const IndentedPayCycleItem = (props: PayCycleItemProps) => {
  // This is a hack to match the design since directly adding these items
  // to the PayCycleRichItem is not supported (neatly) by ui-kit
  return (
    <Box pl="s-56">
      <PayCycleItem {...props} />
    </Box>
  )
}

type PayCycleRichItemProps = Omit<PayCycleItemProps, 'type' | 'insert'> & {
  subtitle: string
  useIcon: IconComponentType
  status: string
}
export const PayCycleRichItem = ({
  title,
  subtitle,
  useIcon,
  status,
  field,
  showRelevantOnly,
  data,
}: PayCycleRichItemProps) => {
  const parent = useContext(PaycycleContext)
  const parentInfo = determineParentType(parent)
  const tooltip = useTooltip()

  if (!parent || !field) {
    return null
  }

  const relevancy = checkRelevant(parent, field)

  if (
    (showRelevantOnly === 'all' && relevancy === null) ||
    (showRelevantOnly === 'issue' && (relevancy === 'change' || relevancy === null)) ||
    (showRelevantOnly === 'change' && (relevancy === 'issue' || relevancy === null))
  ) {
    return null
  }

  const link = linkToItem(
    `${data.employee.id}`,
    parentInfo?.type,
    `${getSafe(parent, parentInfo.itemId)}`,
  )
  const issue = (Array.isArray(field) ? field : [field])
    .map(specificField => get(parent.field_issues, specificField))
    .filter(Boolean)

  return (
    <Item use={InternalLink} to={link} target="_blank" {...tooltip.getAnchorProps()}>
      <Item.Avatar>
        <Avatar backgroundColor="gray-10" useIcon={useIcon}>
          {relevancy === 'issue' && (
            <Avatar.Badge backgroundColor="orange" useIcon={ExclamationMarkSign} />
          )}
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        <Item.Description>{subtitle}</Item.Description>
      </Item.Content>
      <Item.Side>
        <Text color={getStatusColor(status as Statuses)}>{capitalize(status)}</Text>
      </Item.Side>
      {!!issue?.length && (
        <Tooltip placement="left" {...tooltip.getTargetProps()}>
          {issue.join('; ')}
        </Tooltip>
      )}
    </Item>
  )
}
