import { useEffect, useMemo, useRef, useState } from 'react'
import { Color } from '@revolut/ui-kit'

import { useTableReturnType } from '@components/TableV2/hooks'
import { FilterOption, Stats } from '@src/interfaces/data'
import { useQuery } from '@src/utils/queryParamsHooks'
import { StatFilter, StatFiltersProps } from './StatFilters'

export type StatsConfig<S extends {}> = {
  key: keyof S
  title: string
  filterKey?: string
  color?: Color
  /** To override the main columnName */
  customColumnName?: string
}[]

export type SelectableTableStatsProps<T, S extends {} = Stats> = {
  table: useTableReturnType<T, S>
  statsConfig?: StatsConfig<S>
  columnName: string
  totalKey?: string
  disableQueryParam?: boolean
  keepInitialStats?: boolean
}
export const useSelectableTableStats = <T, S extends {} = Stats>({
  table,
  statsConfig = [],
  columnName,
  totalKey = 'total',
  disableQueryParam,
  keepInitialStats = false,
}: SelectableTableStatsProps<T, S>): StatFiltersProps => {
  const { deleteQueryParam } = useQuery()

  const [selectedFilter, setSelectedFilter] = useState<string | undefined>()
  const initialStats = useRef<S | null>(null)

  useEffect(() => {
    if (table.loading) {
      return
    }

    if (!initialStats.current && table.stats) {
      initialStats.current = table.stats
    }

    const { filters: currentFilters = [] } = table.fetchQuery

    const activeColumns = currentFilters.reduce((acc, filter) => {
      return [...acc, filter.columnName]
    }, [] as string[])

    const filtersByStats = currentFilters.reduce((acc, filter) => {
      if (filter.columnName === columnName) {
        return [...acc, ...filter.filters]
      }
      return acc
    }, [] as FilterOption[])

    const customActiveColumnFilter = statsConfig.find(config => {
      return currentFilters.find(
        filter =>
          filter.columnName === config.customColumnName &&
          filter.filters.find(f => f.id === config.filterKey),
      )
    })

    if (activeColumns.length === 0) {
      setSelectedFilter(totalKey || undefined)
    } else if (filtersByStats.length) {
      const key = String(filtersByStats[0].id)
      const configKey = statsConfig.find(k => k.filterKey === key)?.key
      setSelectedFilter(typeof configKey === 'string' ? configKey : key)
    } else if (customActiveColumnFilter) {
      setSelectedFilter(
        typeof customActiveColumnFilter.key === 'string'
          ? customActiveColumnFilter.key
          : undefined,
      )
    } else {
      setSelectedFilter(undefined)
    }
  }, [table.loading])

  const statsFilters = useMemo<StatFilter[]>(
    () =>
      statsConfig.map(({ key, title, color }) => {
        const stats =
          keepInitialStats && initialStats.current ? initialStats.current : table.stats
        return {
          id: key as string,
          title,
          value: stats?.[key],
          loading: !stats,
          color,
        }
      }),
    [table.loading, table.stats, keepInitialStats],
  )

  const onFilterSelect = (newFilter: string) => {
    setSelectedFilter(newFilter)
    const selectedStatItem = statsConfig.find(conf => conf.key === newFilter)
    const selectedFilterKey = selectedStatItem?.filterKey
    const customColumnName = selectedStatItem?.customColumnName

    if (customColumnName && selectedFilterKey) {
      table.onFilterChange(
        [
          ...(table.fetchQuery.filters?.filter(f => f.columnName !== columnName) || []),
          {
            filters: [{ id: selectedFilterKey, name: selectedFilterKey }],
            columnName: customColumnName,
            disableQueryParam,
          },
        ],
        true,
        true,
      )
      return
    }

    if (totalKey && selectedFilterKey === totalKey) {
      table.onFilterChange([{ filters: [], columnName, disableQueryParam }], true, true)
      deleteQueryParam(columnName)

      return
    }

    if (columnName && selectedFilterKey) {
      const customColumnNames = statsConfig
        .map(stat => stat.customColumnName)
        .filter(Boolean)

      table.onFilterChange(
        [
          ...(table.fetchQuery.filters?.filter(
            f => !customColumnNames.includes(f.columnName),
          ) || []),
          {
            filters: [{ id: selectedFilterKey, name: selectedFilterKey }],
            columnName,
            disableQueryParam,
          },
        ],
        true,
        true,
      )
    }
  }

  return {
    filters: statsFilters,
    selectedFilter,
    onClick: onFilterSelect,
  }
}
