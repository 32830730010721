import { AxiosError, AxiosPromise } from 'axios'
import { useQueryClient } from 'react-query'

import { api, apiV2, apiWithoutHandling } from '@src/api/index'
import { AudienceInterface } from '@src/components/AddAudienceSidebar/interfaces'
import { API, selectorKeys } from '@src/constants/api'
import {
  GetRequestData,
  GetRequestInterface,
  RequestInterfaceNew,
  Statuses,
  TableRequestInterface,
} from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import {
  DriverResultStatsInterface,
  EngagementAnswerInterface,
  EngagementAnswerReplyInterface,
  EngagementDriverChartDatum,
  EngagementDriverInterface,
  EngagementQuestionChartDatum,
  EngagementQuestionInterface,
  EngagementQuestionVoicesInterface,
  EngagementResultInterface,
  EngagementResultsComment,
  EngagementResultsCommentsStats,
  EngagementResultsEntities,
  EngagementResultsHeatmapRowInterface,
  EngagementResultsItemDetailsInterface,
  EngagementResultsItemTimeSeriesInterface,
  EngagementResultsScope,
  EngagementResultV2Interface,
  EngagementStartSurveyInterface,
  EngagementSurveyAnalyticsInterface,
  EngagementSurveyDetailsInterface,
  EngagementSurveyInterface,
  EngagementSurveyRoundInterface,
  EngagementSurveySettingsInterface,
  EngagementSurveyStatsInterface,
  GroupByOption,
  SurveyParticipationStats,
} from '@src/interfaces/engagement'
import { NotificationsInterface } from '@src/interfaces/notifications'
import { FeedbackSubmitInterface } from '@src/store/feedback/types'
import {
  useFetch,
  useFetchV2,
  usePostV2,
  useUpdate,
  useUpdateV2,
} from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { ItemsToAnalyse } from '@src/pages/Performance/Engagement/components/v2/ResultsTab/common'

export const getQuestionsQueue = (surveyId: string, isTestSurvey?: boolean) => {
  return api.get<EngagementQuestionVoicesInterface[]>(
    `${API.ENGAGEMENT_QUESTIONS}/queue`,
    {
      params: {
        survey_id: surveyId,
        is_test: isTestSurvey ? true : undefined,
      },
    },
  )
}

export const engagementQuestionBoxFormRequests: RequestInterfaceNew<EngagementAnswerInterface> =
  {
    get: async () => api.get(API.ENGAGEMENT_ANSWERS),
    update: async data => apiWithoutHandling.patch(API.ENGAGEMENT_ANSWERS, data),
    submit: async data => apiWithoutHandling.post(API.ENGAGEMENT_ANSWERS, data),
  }

export const didAnswerOnboardingQuestion = () => {
  return api.get<boolean>(
    `${API.USER}/onboardingFeedback/did_answer_onboarding_question`,
    undefined,
    undefined,
    true,
  )
}

export const submitOnboardingAnswer = (answer: FeedbackSubmitInterface) =>
  api.post(`${API.USER}/onboardingFeedback`, answer, undefined, undefined, true)

export const engagementSurveySettingsRequests: RequestInterfaceNew<EngagementSurveySettingsInterface> =
  {
    get: async () => api.get(API.ENGAGEMENT_SURVEY_SETTINGS),
    update: async data => apiWithoutHandling.patch(API.ENGAGEMENT_SURVEY_SETTINGS, data),
    submit: async data => apiWithoutHandling.post(API.ENGAGEMENT_SURVEY_SETTINGS, data),
  }

export const getEngagementAudiences = (filterParams: Object) =>
  api.get<GetRequestData<AudienceInterface>>(API.ENGAGEMENT_AUDIENCES, {
    params: filterParams,
  })

export const useEngagementAudiencesSelector = (filterParams: Object) => {
  return useFetch<{ options: AudienceInterface[] }>(
    `${API.ENGAGEMENT_AUDIENCES}/selector`,
    'v1',
    {
      params: filterParams,
    },
  )
}

export const engagementDriversTableRequests: TableRequestInterface<EngagementDriverInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      apiV2.get(API.ENGAGEMENT_DRIVERS, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const engagementDriverFormRequests: RequestInterfaceNew<EngagementDriverInterface> =
  {
    get: async ({ id }) => api.get(`${API.ENGAGEMENT_DRIVERS}/${id}`),
    update: async (data, { id }) => apiV2.patch(`${API.ENGAGEMENT_DRIVERS}/${id}`, data),
    submit: async data => apiWithoutHandling.post(API.ENGAGEMENT_DRIVERS, data),
  }

export const archiveEngagementDriver = (
  id: number,
): AxiosPromise<EngagementDriverInterface> =>
  apiV2.post(`${API.ENGAGEMENT_DRIVERS}/${id}/archive`)

export const unarchiveEngagementDriver = (
  id: number,
): AxiosPromise<EngagementDriverInterface> =>
  api.post(`${API.ENGAGEMENT_DRIVERS}/${id}/unarchive`)

export const engagementQuestionsTableRequests: TableRequestInterface<EngagementQuestionInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      apiV2.get(API.ENGAGEMENT_QUESTIONS, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const engagementQuestionFormRequests: RequestInterfaceNew<EngagementQuestionInterface> =
  {
    get: async ({ id }) => apiV2.get(`${API.ENGAGEMENT_QUESTIONS}/${id}`),
    update: async (data, { id }) =>
      apiV2.patch(`${API.ENGAGEMENT_QUESTIONS}/${id}`, data),
    submit: async data => apiV2.post(API.ENGAGEMENT_QUESTIONS, data),
  }

export const archiveEngagementQuestion = (
  id: number,
): AxiosPromise<EngagementQuestionInterface> =>
  apiV2.post(`${API.ENGAGEMENT_QUESTIONS}/${id}/archive`)

export const unarchiveEngagementQuestion = (
  id: number,
): AxiosPromise<EngagementQuestionInterface> =>
  apiV2.post(`${API.ENGAGEMENT_QUESTIONS}/${id}/unarchive`)

export const engagementSurveysTableRequests: TableRequestInterface<
  EngagementSurveyInterface,
  EngagementSurveyStatsInterface
> = {
  getItems: async ({ sortBy, filters, page }) =>
    apiV2.get(API.ENGAGEMENT_SURVEYS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ filters, page }) =>
    apiV2.get(`${API.ENGAGEMENT_SURVEYS}/stats`, {
      params: filterSortPageIntoQuery(undefined, filters, page),
    }),
}

export const useGetSurveysStats = () =>
  useFetch<EngagementSurveyStatsInterface>(`${API.ENGAGEMENT_SURVEYS}/stats`, undefined, {
    params: filterSortPageIntoQuery(undefined, [
      {
        columnName: 'round_status',
        filters: [
          { id: Statuses.active, name: Statuses.active },
          { id: Statuses.inactive, name: Statuses.inactive },
          { id: Statuses.running, name: Statuses.running },
          { id: Statuses.planned, name: Statuses.planned },
        ],
      },
      {
        columnName: 'status',
        filters: [
          { id: Statuses.active, name: Statuses.active },
          { id: Statuses.inactive, name: Statuses.inactive },
          { id: Statuses.scheduled, name: Statuses.scheduled },
          { id: Statuses.completed, name: Statuses.completed },
          { id: Statuses.archived, name: Statuses.archived },
          { id: Statuses.terminated, name: Statuses.terminated },
        ],
      },
    ]),
  })

export const useRefetchEngagementSurveyRounds = (surveyId?: number | string) => {
  const queryClient = useQueryClient()

  const refetchEngagementSurveyRounds = () => {
    if (surveyId) {
      queryClient.refetchQueries(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/rounds`)
    }
  }

  return { refetchEngagementSurveyRounds }
}

export const useEngagementSurveyRounds = (surveyId?: number | string) =>
  useFetch<GetRequestInterface<EngagementSurveyRoundInterface>>(
    surveyId ? `${API.ENGAGEMENT_SURVEYS}/${surveyId}/rounds` : null,
    undefined,
    {
      params: {
        ordering: 'status_order,-sent_on',
        is_test: false,
      },
    },
  )

export const getEngagementRoundFormRequest = (
  surveyId: string | number,
  roundId: string | number,
): RequestInterfaceNew<EngagementSurveyRoundInterface> => ({
  get: async () => api.get(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/rounds/${roundId}`),
  update: data =>
    api.patch(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/rounds/${roundId}`, data),
  submit: data =>
    api.post(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/rounds/${roundId}`, data),
})

export const engagementSurveyFormRequests: RequestInterfaceNew<EngagementSurveyInterface> =
  {
    get: async ({ id }) => api.get(`${API.ENGAGEMENT_SURVEYS}/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.ENGAGEMENT_SURVEYS}/${id}`, data),
    submit: async data => apiWithoutHandling.post(API.ENGAGEMENT_SURVEYS, data),
  }

export const getEngagementQuestionsOptions = ({ filters }: { filters?: Object }) =>
  api.get<GetRequestData<EngagementQuestionInterface>>(`${API.ENGAGEMENT_QUESTIONS}`, {
    params: filters,
  })

export const useEngagementSurveysTemplates = () =>
  useFetch<GetRequestInterface<EngagementSurveyInterface>>(API.ENGAGEMENT_SURVEYS, 'v1', {
    params: filterSortPageIntoQuery(undefined, [
      { columnName: 'status', filters: [{ id: 'template', name: 'Template' }] },
    ]),
  })

export const useGetEngagementSurvey = (id?: number | string) =>
  useFetch<EngagementSurveyInterface>(id ? `${API.ENGAGEMENT_SURVEYS}/${id}` : null)

export const useUpdateEngagementSurvey = () =>
  useUpdate<EngagementSurveyInterface, EngagementSurveyInterface>(
    API.ENGAGEMENT_SURVEYS,
    undefined,
    undefined,
    false,
    (_oldData, newData) => newData,
    true,
  )

export const cancelEngagementSurvey = (
  id: number,
): AxiosPromise<EngagementSurveyInterface> =>
  api.post(`${API.ENGAGEMENT_SURVEYS}/${id}/cancel`)

export const activateEngagementSurvey = (
  id: number,
): AxiosPromise<EngagementSurveyInterface> =>
  api.post(`${API.ENGAGEMENT_SURVEYS}/${id}/activate`)

export const archiveEngagementSurvey = (
  id: number,
): AxiosPromise<EngagementSurveyInterface> =>
  api.post(`${API.ENGAGEMENT_SURVEYS}/${id}/archive`)

export const unarchiveEngagementSurvey = (
  id: number,
): AxiosPromise<EngagementSurveyInterface> =>
  api.post(`${API.ENGAGEMENT_SURVEYS}/${id}/unarchive`)

export const sendEngagementSurvey = (
  id: number | string,
): AxiosPromise<EngagementSurveyInterface> =>
  api.post(`${API.ENGAGEMENT_SURVEYS}/${id}/send`)

export const closeSurveyRound = (
  id: number,
  roundId: number,
): AxiosPromise<EngagementSurveyInterface> =>
  api.post(`${API.ENGAGEMENT_SURVEYS}/${id}/rounds/${roundId}/close`)

export const cancelSurveyRound = (
  id: number,
  roundId: number,
): AxiosPromise<EngagementSurveyInterface> =>
  api.post(`${API.ENGAGEMENT_SURVEYS}/${id}/rounds/${roundId}/cancel`)

export const testEngagementSurvey = (
  id: number,
): AxiosPromise<EngagementSurveyInterface> =>
  api.post(`${API.ENGAGEMENT_SURVEYS}/${id}/test`)

export const createSurveyFromTemplate = (
  id: number,
  data?: EngagementSurveyInterface,
): AxiosPromise<EngagementSurveyInterface> =>
  api.post(`${API.ENGAGEMENT_SURVEYS}/${id}/createFromTemplate`, data)

export const getEngagementResultsTableRequests: <S>(
  scopeId: number,
  surveyId: number,
  scope: EngagementResultsScope | undefined,
  entity: EngagementResultsEntities,
) => TableRequestInterface<EngagementResultInterface, S> = (
  id,
  surveyId,
  scopeId,
  entity,
) => {
  const apiUrl = `/${scopeId}/${id}/culture/${surveyId}/${entity}`

  return {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(
        apiUrl,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
        'v2',
      ),
  }
}

export const useEngagementSurveyResultsChart = <
  T extends EngagementDriverChartDatum | EngagementQuestionChartDatum,
>({
  scope,
  entity,
  scopeId,
}: {
  scope: EngagementResultsScope | undefined
  entity: EngagementResultsEntities
  scopeId: number
  filterParams?: Object
  onlyFiltered?: boolean
}) =>
  useFetch<GetRequestInterface<T>>(`/${scope}/${scopeId}/culture/${entity}/chart`, 'v2')

export const useEngagementResultsQuestions = ({
  enabled = true,
  filtersParams,
}: {
  enabled?: boolean
  filtersParams?: Object
}) =>
  useFetch<GetRequestInterface<EngagementQuestionInterface>>(
    enabled ? API.ENGAGEMENT_QUESTIONS : null,
    undefined,
    { params: filtersParams },
  )

export const refreshEngagementSurveyStatistics = ({
  surveyId,
}: {
  surveyId: number | string
}) => apiWithoutHandling.post(`/engagementSurveys/${surveyId}/refreshStatistics`)

export const useEngagementResultsDrivers = ({
  enabled = true,
  filtersParams,
}: {
  enabled?: boolean
  filtersParams?: Object
}) =>
  useFetch<GetRequestInterface<EngagementDriverInterface>>(
    enabled ? API.ENGAGEMENT_DRIVERS : null,
    undefined,
    { params: filtersParams },
  )

export const getResultsComments = ({
  scope,
  scopeId,
  filters,
}: {
  scope: EngagementResultsScope | undefined
  scopeId: number
  filters?: Object
}) =>
  apiV2.get<GetRequestData<EngagementResultsComment>>(
    `/${scope}/${scopeId}/engagementComments`,
    { params: filters },
  )

export const getSurveyPublishedResultCommentsTableRequests = ({
  scope,
  scopeId,
}: {
  scope: EngagementResultsScope | undefined
  scopeId: number
}): TableRequestInterface<EngagementResultsComment> => ({
  getItems: async ({ sortBy, filters = [], page }) =>
    apiV2.get(`/${scope}/${scopeId}/engagementComments`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getSurveyAdminResultCommentsTableRequests =
  (): TableRequestInterface<EngagementResultsComment> => ({
    getItems: async ({ sortBy, filters = [], page }) =>
      apiV2.get(`/company/1/all/engagementComments`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  })

export const useEngagementSurveyParticipationStats = ({
  entityType,
  entityId,
  surveyId,
  surveyRoundId,
}: {
  entityType: EngagementResultsScope | undefined
  entityId: number | undefined
  surveyId: number | undefined
  surveyRoundId: number | undefined
}) => {
  return useFetchV2<SurveyParticipationStats>({
    url: `/${entityType}/${entityId}/culture/${surveyId}/${surveyRoundId}/stats`,
    version: 'v2',
    queryOptions: {
      enabled: !!entityType && !!entityId && !!surveyId && !!surveyRoundId,
    },
  })
}

export const useAdminEngagementResultStats = (
  surveyId?: number,
  roundId?: string | number,
) =>
  useFetch<DriverResultStatsInterface>(
    roundId && surveyId ? `/company/1/culture/${surveyId}/all/drivers/stats` : null,
    'v2',
    { params: { survey_round_id: roundId } },
    true,
  )

export const useEngagementResultStats = ({
  surveyId,
  roundId,
  scope,
  entity,
}: {
  surveyId?: string | number
  roundId?: string | number
  scope: {
    type: EngagementResultsScope
    id: number
  }
  entity: EngagementResultsEntities
}) => {
  const uri =
    entity !== EngagementResultsEntities.Feedback && roundId && surveyId
      ? `/${scope.type}/${scope.id}/culture/${surveyId}/${entity}/stats`
      : null
  return useFetch<DriverResultStatsInterface>(
    uri,
    'v2',
    { params: { survey_round_id: roundId } },
    true,
  )
}

export const getSurveyAdminResultQuestionsTableRequests = ({
  surveyId,
}: {
  surveyId: number
}): TableRequestInterface<EngagementResultInterface> => ({
  getItems: async ({ sortBy, filters = [], page }) =>
    apiWithoutHandling.get(
      `/company/1/culture/${surveyId}/all/questions`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const getSurveyAdminResultDriversTableRequests = ({
  surveyId,
}: {
  surveyId: number
}): TableRequestInterface<EngagementResultInterface> => ({
  getItems: async ({ sortBy, filters = [], page }) =>
    apiWithoutHandling.get(
      `/company/1/culture/${surveyId}/all/drivers`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const getResultsCommentsTableRequests = ({
  scope,
  scopeId,
  defaultFilters,
}: {
  scope: EngagementResultsScope | undefined
  scopeId: number
  defaultFilters: FilterByInterface[]
}): TableRequestInterface<EngagementResultsComment, EngagementResultsCommentsStats> => ({
  getItems: async ({ sortBy, filters = [], page }) =>
    api.get(`/${scope}/${scopeId}/engagementComments`, {
      params: filterSortPageIntoQuery(sortBy, [...defaultFilters, ...filters], page),
    }),
  getStats: async ({ sortBy, filters = [], page }) =>
    api.get(`/${scope}/${scopeId}/engagementComments/stats`, {
      params: filterSortPageIntoQuery(sortBy, [...defaultFilters, ...filters], page),
    }),
})

export const useResultsCommentsStats = (
  scope: EngagementResultsScope | undefined,
  scopeId: number,
  filters: FilterByInterface[],
  page: number,
) =>
  useFetch<EngagementResultsCommentsStats>(
    `/${scope}/${scopeId}/engagementComments/stats`,
    undefined,
    { params: filterSortPageIntoQuery(undefined, filters, page) },
  )

export const useEngagementWelcomePage = ({
  surveyId,
  isTestSurvey,
}: {
  surveyId?: string
  isTestSurvey?: boolean
}) =>
  useFetch<EngagementSurveyDetailsInterface>(
    surveyId ? `${API.ENGAGEMENT_SURVEYS}/${surveyId}/get_welcome_page` : null,
    'v1',
    { params: isTestSurvey ? { is_test: true } : {} },
    false,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: 'always',
    },
  )

export const useEngagementNotifications = (enabled: boolean) =>
  useFetch<GetRequestInterface<NotificationsInterface>>(
    enabled ? `${API.NOTIFICATIONS}/engagement` : null,
    'v2',
  )

export const engagementSurveyHistoryRequests = (
  id: string | number,
): TableRequestInterface<EngagementSurveyRoundInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    apiV2.get(`${API.ENGAGEMENT_SURVEYS}/${id}/rounds`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getStartSurveyFormRequests = (
  surveyId: number,
): RequestInterfaceNew<EngagementStartSurveyInterface> => ({
  get: async () => api.get(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/send`),
  update: async data => apiV2.patch(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/send`, data),
  submit: async data => {
    return apiV2.post(`${API.ENGAGEMENT_SURVEYS}/${surveyId}/send`, data)
  },
})

export const getSurveyResultsHeatmapTableRequests = ({
  itemsToAnalyse,
  surveyId,
  onError,
}: {
  surveyId: number
  itemsToAnalyse: ItemsToAnalyse
  onError: (e: AxiosError) => void
}): TableRequestInterface<EngagementResultsHeatmapRowInterface> => {
  const analyse = itemsToAnalyse === 'questions' ? 'questions' : 'drivers'

  return {
    // @ts-expect-error current table implementation doesn't support empty returns from getters on the type level
    getItems: async ({ sortBy, filters, page }) => {
      return apiV2
        .get(`/engagement/analytics/survey/${surveyId}/${analyse}/heatmap`, {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        })
        .catch(e => {
          onError(e) // see @ts-expect-error above
        })
    },
  }
}

export const useGetGroupByOptionItems = (
  surveyId: number | undefined,
  groupBy?: string,
) =>
  useFetch<{ options: GroupByOption[] }>({
    url: API.SELECTORS,
    params: {
      params: {
        name: selectorKeys.engagement_analytics_heatmap_groups_options,
        survey_id: surveyId,
        group_by: groupBy,
      },
    },
    queryOptions: {
      enabled: Boolean(groupBy && surveyId),
    },
  })

export const useGetSurveyAnalytics = (surveyId: number | undefined) =>
  useFetchV2<EngagementSurveyAnalyticsInterface>({
    url: `/engagement/analytics/survey/${surveyId}`,
    queryOptions: {
      enabled: !!surveyId,
    },
  })

export const getEngagementResultsTableV2Requests: <S>(
  surveyId: number,
  entity: 'questions' | 'drivers',
) => TableRequestInterface<EngagementResultV2Interface, S> = (surveyId, entity) => {
  return {
    getItems: async ({ sortBy, filters, page }) =>
      apiV2.get(`/engagement/analytics/survey/${surveyId}/${entity}`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }
}

export const useGetSurveyResultsItemDetails = (
  surveyId: number | string,
  itemsToAnalyse?: ItemsToAnalyse,
  itemId?: number | string,
) =>
  useFetchV2<EngagementResultsItemDetailsInterface>({
    url: `/engagement/analytics/survey/${surveyId}/${
      itemsToAnalyse === 'questions' ? 'questions' : 'drivers'
    }/${itemId}`,
    queryOptions: {
      enabled: Boolean(itemsToAnalyse && itemId),
    },
  })

export const useGetSurveyResultsItemTimeSeries = ({
  surveyId,
  itemsToAnalyse,
  itemId,
  groupBy = 'week',
}: {
  surveyId: number | string
  itemsToAnalyse?: ItemsToAnalyse
  itemId?: number | string
  groupBy?: 'run' | 'week' | 'month'
}) =>
  useFetchV2<EngagementResultsItemTimeSeriesInterface[]>({
    url: `/engagement/analytics/survey/${surveyId}/${
      itemsToAnalyse === 'questions' ? 'questions' : 'drivers'
    }/${itemId}/timeSeries`,
    params: {
      params: {
        group_by: groupBy,
      },
    },
    queryOptions: {
      enabled: Boolean(itemsToAnalyse && itemId),
    },
  })

export const engagementAnswersTableRequests: TableRequestInterface<EngagementAnswerInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      apiV2.get('/engagementAnswers', {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const useGetEngagementAnswerDetails = (answerId: number) =>
  useFetchV2<EngagementAnswerInterface>({
    url: `/engagementAnswers/${answerId}`,
  })

export const useUpdateEngagementAnswerDetails = () =>
  useUpdateV2<EngagementAnswerInterface, Partial<EngagementAnswerInterface>>({
    url: `/engagementAnswers`,
  })

export const useGetEngagementAnswerReplies = (answerId: number) =>
  useFetchV2<GetRequestInterface<EngagementAnswerReplyInterface>>({
    url: `/engagementAnswers/${answerId}/replies`,
  })

export const useCreateEngagementAnswerReply = (answerId: number) =>
  usePostV2({
    url: `/engagementAnswers/${answerId}/replies`,
  })
