import React from 'react'

import Table from '@components/TableV2/Table'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  DocumentsBulkRequestInterface,
  DocumentsBulkRequestStatus,
} from '@src/interfaces/documents'

export const documentsBulkRequestsNameColumn: ColumnInterface<DocumentsBulkRequestInterface> =
  {
    type: CellTypes.text,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Name',
  }

export const documentsBulkRequestsEmployeesCountColumn: ColumnInterface<DocumentsBulkRequestInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employees_count',
    dataPoint: 'employees_count',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employees count',
  }

export const documentsBulkRequestsCreatedByColumn: ColumnInterface<DocumentsBulkRequestInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'created_by.id',
    dataPoint: 'created_by.full_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Created by',
    insert: ({ data }) => <UserWithAvatar {...data.created_by} />,
  }

export const documentsBulkRequestsCreatedOnColumn: ColumnInterface<DocumentsBulkRequestInterface> =
  {
    type: CellTypes.date,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Created on',
  }

const getStatusVariant = (status: DocumentsBulkRequestStatus) => {
  switch (status.id) {
    case 'ready_to_be_submitted':
      return 'success'

    case 'validation_pending':
    case 'validation_in_progress':
    case 'submit_in_progress':
      return 'warning'

    case 'submit_completed':
    case 'validation_completed':
      return 'neutral'

    default:
      return 'neutral'
  }
}

export const documentsBulkRequestsStatusColumn: ColumnInterface<DocumentsBulkRequestInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    insert: ({ data }) => (
      <Table.StatusCell
        status={data.status.name}
        variant={getStatusVariant(data.status)}
      />
    ),
  }
