import React, { useEffect, useMemo, useRef } from 'react'
import { ActionButton, Token } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import { useTable } from '@components/TableV2/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { navigateTo } from '@src/actions/RouterActions'
import { getEmployeeRewardsSalarySacrifice } from '@src/api/rewards'
import {
  salarySacrificeAmountColumn,
  salarySacrificeEndAtColumn,
  salarySacrificeNumberColumn,
  salarySacrificeSchemaColumn,
  salarySacrificeStartAtColumn,
  salarySacrificeStatusColumn,
} from '@src/constants/columns/rewards'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeInterface, SalarySacrificeStatuses } from '@src/interfaces/employees'
import { SalarySacrificeInterface } from '@src/interfaces/rewards'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

import { useYear, YearFilter } from './common'
import Table from '@src/components/TableV2/Table'

const ROW: RowInterface<SalarySacrificeInterface> = {
  highlight: ({ status }) => {
    if (status.id === SalarySacrificeStatuses.Expired) {
      return Token.color.greyTone2
    }
    return ''
  },
  cells: [
    {
      ...salarySacrificeStatusColumn,
      width: 200,
    },
    {
      ...salarySacrificeSchemaColumn,
      width: 200,
    },
    {
      ...salarySacrificeStartAtColumn,
      width: 200,
    },
    {
      ...salarySacrificeEndAtColumn,
      width: 200,
    },
    {
      ...salarySacrificeAmountColumn,
      width: 200,
    },
    {
      ...salarySacrificeNumberColumn,
      width: 200,
    },
  ],
}

interface Props {
  data: EmployeeInterface
}

export const SalarySacrifice = ({ data }: Props) => {
  const [year, setYear] = useYear()
  const mounted = useRef(false)

  const table = useTable<SalarySacrificeInterface>({
    getItems: getEmployeeRewardsSalarySacrifice(data.id, year),
  })

  const total = useMemo(() => {
    if (table.loading) {
      return undefined
    }
    return table.data.reduce((tot, val) => tot + val.number_of_shares, 0)
  }, [table.data, table.loading])

  useEffect(() => {
    if (mounted.current) {
      table.refresh()
    } else {
      mounted.current = true
    }
  }, [year])

  const canAddSalarySacrifice = data.field_options?.permissions?.includes(
    PermissionTypes.AddSalarySacrifice,
  )

  const handleNewRowClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_SALARY_SACRIFICE.GENERAL, { employeeId: data.id }),
    )
  }

  const handleRowClick = (contract: SalarySacrificeInterface) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_SALARY_SACRIFICE.GENERAL, {
        employeeId: data.id,
        id: contract.id,
      }),
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Total options" val={total} mr="s-32" />
        <YearFilter value={year} onChange={setYear} />
      </Table.Widget.Info>

      {canAddSalarySacrifice && (
        <Table.Widget.Actions>
          <ActionButton onClick={handleNewRowClick} mr="s-16" useIcon="Plus">
            Add salary sacrifice
          </ActionButton>
        </Table.Widget.Actions>
      )}
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.SalarySacrifice}
          useWindowScroll
          dataType="Salary Sacrifice"
          row={ROW}
          {...table}
          noDataMessage="Salary sacrifices will appear here"
          onRowClick={handleRowClick}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
