import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { MoreBar, Token } from '@revolut/ui-kit'

import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'
import { useTable } from '@components/TableV2/hooks'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { navigateTo } from '@src/actions/RouterActions'
import {
  exportResponsibilitiesAssignmentsData,
  keyPersonsResponsibilitiesAssignmentsTableRequests,
} from '@src/api/keyPerson'
import { useFilterButtons } from '@src/apps/People/KeyPersons/Responsibility/hooks'
import { ActionButtons } from '@src/apps/People/KeyPersons/ResponsibilityAssignmentsTable/ActionButtons'
import { ResponsibilityAssignmentSidebar } from '@src/apps/People/KeyPersons/ResponsibilityAssignmentsTable/ResponsibilityAssignmentSidebar'
import {
  keyPersonsResponsibilitiesAssignmentsActionsColumn,
  keyPersonsResponsibilitiesAssignmentsEmployeeColumn,
  keyPersonsResponsibilitiesAssignmentsEmployeeStatusColumn,
  keyPersonsResponsibilitiesAssignmentsEndDateColumn,
  keyPersonsResponsibilitiesAssignmentsPeriodColumn,
  keyPersonsResponsibilitiesAssignmentsResponsibilityCategoryColumn,
  keyPersonsResponsibilitiesAssignmentsResponsibilityColumn,
  keyPersonsResponsibilitiesAssignmentsStartDateColumn,
  keyPersonsResponsibilitiesAssignmentsStatusColumn,
  keyPersonsResponsibilitiesAssignmentsSuccessionColumn,
  keyPersonsResponsibilitiesAssignmentsUnitColumn,
} from '@src/constants/columns/keyPersonsResponsibilitiesAssignments'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  KeyPersonResponsibilityAssignmentInterface,
  KeyPersonResponsibilityAssignmentsStatsInterface,
} from '@src/interfaces/keyPerson'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { saveFileByBlob } from '@src/utils'
import { pathToUrl } from '@src/utils/router'

const getRow = ({
  onEdit,
  refresh,
}: {
  refresh: () => void
  onEdit: (data: KeyPersonResponsibilityAssignmentInterface) => void
}): RowInterface<KeyPersonResponsibilityAssignmentInterface> => ({
  linkToForm: (data: KeyPersonResponsibilityAssignmentInterface) =>
    data.employee?.id
      ? navigateTo(
          `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
            id: data.employee.id,
          })}#keyPerson`,
        )
      : undefined,
  cells: [
    {
      ...keyPersonsResponsibilitiesAssignmentsEmployeeColumn,
      width: 220,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsEmployeeStatusColumn,
      width: 150,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsResponsibilityColumn,
      width: 240,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsResponsibilityCategoryColumn,
      width: 220,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsUnitColumn,
      width: 220,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsStartDateColumn,
      width: 180,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsEndDateColumn,
      width: 180,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsPeriodColumn,
      width: 180,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsSuccessionColumn,
      width: 100,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsStatusColumn,
      width: 160,
    },
    {
      ...keyPersonsResponsibilitiesAssignmentsActionsColumn,
      width: 160,
      insert: ({ data }) => (
        <ActionButtons data={data} refresh={refresh} onEdit={onEdit} />
      ),
    },
  ],
})

const statsConfig: StatsConfig<KeyPersonResponsibilityAssignmentsStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'unassigned',
    title: 'Unassigned',
    filterKey: 'unassigned',
    color: Token.color.orange,
  },
  {
    key: 'pending_start',
    title: 'Pending start',
    filterKey: 'pending_start',
    color: Token.color.orange,
  },
  {
    key: 'active',
    title: 'Active',
    filterKey: 'active',
    color: Token.color.green,
  },
  {
    key: 'expired',
    title: 'Expired',
    filterKey: 'expired',
    color: Token.color.red,
  },
  {
    key: 'archived',
    title: 'Archived',
    filterKey: 'archived',
    color: Token.color.greyTone50,
  },
]

export const KeyPersonsResponsibilityAssignmentsTable = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [assignmentId, setAssignmentId] = useState<number>()
  const [isExportDataPending, setIsExportDataPending] = useState(false)

  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddKeyRiskProfile)

  const table = useTable<
    KeyPersonResponsibilityAssignmentInterface,
    KeyPersonResponsibilityAssignmentsStatsInterface
  >(keyPersonsResponsibilitiesAssignmentsTableRequests, [
    { columnName: 'active_stats', filters: [{ id: 'unassigned', name: 'unassigned' }] },
  ])

  const row = useMemo(
    () =>
      getRow({
        onEdit: data => {
          setAssignmentId(data.id)
          setIsSidebarOpen(true)
        },
        refresh: () => table.refresh(),
      }),
    [],
  )

  const filtersButtons = useFilterButtons<
    KeyPersonResponsibilityAssignmentInterface,
    KeyPersonResponsibilityAssignmentsStatsInterface
  >(table)

  const statFiltersProps = useSelectableTableStats<
    KeyPersonResponsibilityAssignmentInterface,
    KeyPersonResponsibilityAssignmentsStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
  })

  const onExportResponsibilitiesData = () => {
    setIsExportDataPending(true)
    exportResponsibilitiesAssignmentsData()
      .then(response => {
        saveFileByBlob(
          response.data,
          'KeyRiskProfile.csv',
          response.headers['content-type'],
        )
      })
      .finally(() => setIsExportDataPending(false))
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <StatFilters {...statFiltersProps} />
        </Table.Widget.Info>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {canAdd && (
              <MoreBar.Action
                onClick={() => {
                  setIsSidebarOpen(true)
                }}
                useIcon="Plus"
              >
                Assign responsibility
              </MoreBar.Action>
            )}
            <MoreBar.Action
              useIcon="Download"
              pending={isExportDataPending}
              onClick={onExportResponsibilitiesData}
            >
              Download
            </MoreBar.Action>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Filters>{filtersButtons}</Table.Widget.Filters>
        <Table.Widget.Table>
          <AdjustableTable<
            KeyPersonResponsibilityAssignmentInterface,
            KeyPersonResponsibilityAssignmentsStatsInterface
          >
            hideCount
            name={TableNames.KeyPersonsSuccession}
            useWindowScroll
            row={row}
            {...table}
            noDataMessage="All assignments will appear here"
          />
        </Table.Widget.Table>
      </Table.Widget>
      <ResponsibilityAssignmentSidebar
        assignmentId={assignmentId}
        isOpen={isSidebarOpen}
        onClose={() => {
          setIsSidebarOpen(false)
          setAssignmentId(undefined)
        }}
        onUpdateResponsibility={() => {
          table.refresh()
        }}
      />
    </>
  )
}
