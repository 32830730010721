import {
  ActionButton,
  Avatar,
  Item,
  ItemSkeleton,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { useGetDeliverablesCategories } from '@src/api/performanceSettings'
import { DeliverableCategory } from '@src/interfaces/settings'
import React, { useState } from 'react'
import { DeliverablesSidebar } from './DeliverablesSidebar'

export const DeliverablesSettingsWidget = () => {
  const {
    data: { results: deliverableCategories = [] } = {},
    isLoading,
    refetch,
  } = useGetDeliverablesCategories()
  const [openedCategory, setOpenedCategory] = useState<DeliverableCategory>()
  return (
    <>
      <Widget>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="Wealth" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Deliverables</Item.Title>
            <Item.Description>
              The calibration logic sets a benchmark for expected results by defining the
              percentage of employees you expect to achieve each performance grade.
            </Item.Description>
          </Item.Content>
        </Item>
        <VStack gap="s-8" pb="s-16" pl="s-64" pr="s-16">
          {isLoading ? (
            <>
              <ItemSkeleton />
              <ItemSkeleton />
              <ItemSkeleton />
            </>
          ) : (
            deliverableCategories.map(category => (
              <Item
                key={category.id}
                style={{ backgroundColor: Token.color.widgetBackground }}
              >
                <Item.Content>
                  <Item.Title>{category.label}</Item.Title>
                </Item.Content>
                <Item.Side>
                  <ActionButton
                    useIcon="Pencil"
                    aria-label={`Edit ${category.label}`}
                    size="xs"
                    onClick={() => {
                      setOpenedCategory(category)
                    }}
                  />
                </Item.Side>
              </Item>
            ))
          )}
        </VStack>
      </Widget>
      <DeliverablesSidebar
        openedCategory={openedCategory}
        onClose={options => {
          if (options?.refetch) {
            refetch()
          }
          setOpenedCategory(undefined)
        }}
      />
    </>
  )
}
