import React from 'react'
import { Virtuoso } from 'react-virtuoso'
import { IconName, VStack } from '@revolut/ui-kit'
import { OrgUnitGoalsStats } from '@src/interfaces/goals'
import { ListItemsSkeleton } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/Goals/ListItems'

export interface Context {
  iconName: IconName
  makeTitleLink: (id: number) => string
  onActionClick: (id: number) => void
}

interface Props<T extends OrgUnitGoalsStats, C> {
  data: T[]
  isFetchingMore: boolean
  fetchMore: VoidFunction
  renderItem: (_index: number, props: T, context: C) => React.ReactNode
  context?: C
  scrollParent?: HTMLDivElement
}

export const List = <T extends OrgUnitGoalsStats, C extends Context>({
  data,
  fetchMore,
  isFetchingMore,
  renderItem,
  scrollParent,
  context,
}: Props<T, C>) => {
  return (
    <Virtuoso<T, C>
      context={context}
      increaseViewportBy={200}
      customScrollParent={scrollParent}
      data={data}
      endReached={fetchMore}
      itemContent={renderItem}
      components={{
        Footer: () => <>{isFetchingMore ? Footer : null}</>,
      }}
    />
  )
}

const Footer = () => (
  <VStack space="s-16">
    <ListItemsSkeleton />
  </VStack>
)
