import React, { useState } from 'react'
import { Flex, Placeholder } from '@revolut/ui-kit'
import { usersRequests, useUsersSelector } from '@src/api/accessManagement'
import { bulkActivationEmail } from '@src/api/platformOnboarding'
import {
  emailColumn,
  userNameColumn,
} from '@src/constants/columns/accessManagement/accessManagementUsers'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'
import { RowInterface } from '@src/interfaces/data'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import Table from '@src/components/TableV2/Table'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { finishConfig } from '@src/pages/OnboardingChecklist/common/constants'
import OnboardingActions from '@src/pages/OnboardingChecklist/components/OnboardingActions'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'

const Row: RowInterface<UserAccessManagementInterface> = {
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...userNameColumn,
      width: 300,
    },
    {
      ...emailColumn,
      width: 300,
    },
  ],
}

export const InviteTeam = () => {
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<UserAccessManagementInterface>>()

  const table = useTable<UserAccessManagementInterface>(usersRequests, [
    {
      columnName: 'status',
      filters: [{ id: Statuses.inactive, name: Statuses.inactive }],
      nonResettable: true,
    },
    {
      columnName: 'user_type',
      filters: [{ id: 'employee', name: 'employee' }],
      nonResettable: true,
    },
  ])

  const users = useUsersSelector(table.filterBy)

  const onSendInvitationEmails = async () => {
    if (!selectedData && !users) {
      return Promise.resolve()
    }

    const employeeToSendTo = (() => {
      if (!selectedData && users) {
        return users.map(user => ({ id: user.employee.id }))
      }

      if (selectedData?.selectedRowsData.length) {
        return selectedData.selectedRowsData
          .filter(row => !!row.employee?.id)
          .map(row => ({ id: row.employee!.id }))
      }

      if (selectedData?.isAllSelected && users) {
        return users
          .filter(
            user => !!user.employee?.id && !selectedData.excludeListIds.has(`${user.id}`),
          )
          .map(user => ({ id: user.employee.id }))
      }

      return []
    })()

    return bulkActivationEmail(employeeToSendTo)
  }

  return (
    <>
      <SectionTitle
        title="All set. Invite your team now."
        subtitle="An Invitation email with onboarding instructions will be sent to your employees to get started"
      />
      <Table.Widget>
        <Table.Widget.Table>
          <SelectTableWrapper
            enabled={!!table.data.length}
            filters={table.filterBy}
            onChange={setSelectedData}
            selectAll
            tableDataLength={table.data.length}
            tableData={table.data}
          >
            <AdjustableTable
              dataType="Users"
              emptyState={
                <Flex alignItems="center" flexDirection="column" my="s-64">
                  <Placeholder>
                    <Placeholder.Image
                      src="https://assets.revolut.com/assets/3d-images/3D261.png"
                      srcSet="https://assets.revolut.com/assets/3d-images/3D261@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D261@3x.png 3x"
                    />
                    <Placeholder.Title>No team members to activate</Placeholder.Title>
                    <Placeholder.Description>
                      Activation message has already been sent to the employees
                    </Placeholder.Description>
                  </Placeholder>
                </Flex>
              }
              name={TableNames.SendInvitationEmails}
              noDataMessage="Users will appear here."
              row={Row}
              useWindowScroll
              {...table}
            />
          </SelectTableWrapper>
        </Table.Widget.Table>
      </Table.Widget>
      <OnboardingActions
        config={finishConfig}
        currentStep="Invite team"
        isForm={false}
        isLastCheckpoint
        isLastStep
        updateSteps
        disableBack={false}
        disableNext={false}
        pendingBack={false}
        pendingNext={false}
        nextButtonLabel={
          (!selectedData && !!users?.length) || selectedData?.someSelected
            ? 'Send invitation'
            : 'Finish onboarding'
        }
        nextRoute=""
        previousRoute={ROUTES.ONBOARDING_CHECKLIST.FINISH.INTEGRATIONS}
        onBeforeSubmit={onSendInvitationEmails}
      />
    </>
  )
}
