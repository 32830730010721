import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { FunctionInterface } from '@src/interfaces/functions'
import { CompanyInterface } from '@src/interfaces/company'
import { EmployeeInterface } from '@src/interfaces/employees'
import { TalentType } from '@src/interfaces/talent/talent'
import { VStack } from '@revolut/ui-kit'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import React, { useMemo } from 'react'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { PromotionNominationInterface } from '@src/interfaces/promotions'
import { promotionNominationRequests } from '@src/api/promotions'
import { useTable } from '@components/Table/hooks'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  getLocationPathnameWithoutWorkspace,
  navigateTo,
} from '@src/actions/RouterActions'
import {
  promotionEmployeeColumn,
  promotionStageColumn,
  promotionStatusColumn,
  promotionTargetSeniorityColumn,
  promotionTargetSpecialisationColumn,
  promotionUpdateTimeColumn,
  promotionProgressionTrackColumn,
  promotionNominatedByColumn,
  promotionRevieversColumn,
} from '@src/constants/columns/promotion/promotionNomintations'
import { Statuses } from '@src/interfaces'
import ButtonFilters, {
  ButtonFilterConfig,
} from '@components/ButtonFilters/ButtonFilters'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { useSelectedPerformanceCycle } from '@src/utils/performance'
import Loader from '@components/CommonSC/Loader'
import TalentCycleSettingsBanner from '@src/pages/Forms/CommonTalentTab/TalentCycleSettingsBanner'
import Table from '@src/components/TableV2/Table'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { CycleFilterButton } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import Stat from '@src/components/Stat/Stat'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

interface TalentProps {
  data:
    | DepartmentInterface
    | TeamInterface
    | RoleInterface
    | SpecialisationInterface
    | FunctionInterface
    | CompanyInterface
    | EmployeeInterface
  talentType: TalentType
  initialCycleId?: number | string
  navigation?: React.ReactElement
}

const row: RowInterface<PromotionNominationInterface> = {
  disabled: data => data.status === Statuses.archived,
  linkToForm: ({ id, employee }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.PREVIEW, {
        id,
        employeeId: employee.id,
      }),
      { backUrl: `${getLocationPathnameWithoutWorkspace()}${window.location.search}` },
    ),
  cells: [
    {
      ...promotionEmployeeColumn,
      width: 150,
    },
    {
      ...promotionTargetSpecialisationColumn,
      width: 300,
    },
    {
      ...promotionTargetSeniorityColumn,
      width: 150,
    },
    {
      ...promotionProgressionTrackColumn,
      width: 100,
    },
    {
      ...promotionNominatedByColumn,
      width: 150,
    },
    {
      ...promotionStageColumn,
      width: 250,
    },
    {
      ...promotionRevieversColumn,
      width: 150,
    },
    {
      ...promotionStatusColumn,
      width: 100,
    },
    {
      ...promotionUpdateTimeColumn,
      width: 100,
    },
  ],
}

const getInitialFilters = (
  talentType: TalentType,
  data: TalentProps['data'],
  initialCycleId?: string | number,
): FilterByInterface[] => {
  let idFilterColumn: string | null = null

  switch (talentType) {
    case TalentType.Department:
      idFilterColumn = 'employee__team__department__id'
      break
    case TalentType.Function:
      idFilterColumn = 'employee__specialisation__role__function__id'
      break
    default:
      break
  }
  if (!idFilterColumn) {
    return []
  }
  return [
    {
      columnName: idFilterColumn,
      filters: [{ id: data.id, name: `${data.id}` }],
      nonResettable: true,
    },
    {
      filters: [{ name: String(initialCycleId || 0), id: initialCycleId || 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]
}
const sortBy: SortByInterface[] = [
  { sortBy: 'update_date_time', direction: SORT_DIRECTION.ASC },
]

const getExtraFilters = (talentType: TalentType): ButtonFilterConfig => {
  if (talentType === TalentType.Function) {
    return {
      employee__team__department__id: {
        selector: selectorKeys.department,
        type: 'MultiSelect',
        title: 'Department',
      },
    }
  }
  if (talentType === TalentType.Department) {
    return {
      employee__specialisation__role__function__id: {
        selector: selectorKeys.functions,
        type: 'MultiSelect',
        title: 'Function',
      },
    }
  }
  return {}
}

const TalentPromotionNominationsContent = ({
  data,
  talentType,
  initialCycleId,
  navigation,
}: TalentProps) => {
  const table = useTable<PromotionNominationInterface>(
    promotionNominationRequests,
    getInitialFilters(talentType, data, initialCycleId),
    sortBy,
  )

  const isNewTable = useIsNewTable()

  const extraFilters = useMemo(() => getExtraFilters(talentType), [talentType])

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {!isNewTable ? (
          <VStack space="s-8">
            <CycleFilter
              type={CycleFilterType.NewUI}
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__offset"
              filter={table.filterBy}
            />
          </VStack>
        ) : (
          <Stat label="Reports" val={table.count} />
        )}
      </Table.Widget.Info>

      <Table.Widget.Filters>
        {isNewTable && navigation}
        {isNewTable && (
          <CycleFilterButton
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__offset"
            filter={table.filterBy}
          />
        )}
        <ButtonFilters
          filtersConfig={extraFilters}
          filters={table.filterBy}
          onChange={table.onFilterChange}
        />
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <Table.ColumnsSettingsButton />
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<PromotionNominationInterface>
          hideCount
          name={TableNames.TalentPromotionNominations}
          useWindowScroll
          dataType="Promotion nominee"
          row={row}
          {...table}
          emptyState={<EmptyTableRaw title="No promotion nominees" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

const TalentPromotionNominations = (props: TalentProps) => {
  const { cycles, loading } = useSelectedPerformanceCycle()

  if (loading) {
    return <Loader />
  }
  const initialCycle = cycles.find(cycle => cycle.performance_reviews_selected_cycle)

  if (!initialCycle) {
    return <TalentCycleSettingsBanner />
  }

  return <TalentPromotionNominationsContent {...props} initialCycleId={initialCycle.id} />
}

export default TalentPromotionNominations
