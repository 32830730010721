import {
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import {
  normalizeInterviewForPreview,
  transformInterviewToPerformanceScorecard,
} from '@src/utils/interview'
import { useFetch } from '@src/utils/reactQuery'

export const getInterviewScorecardTemplatePreview = async (
  data?: InterviewScorecardTemplateInterface,
  id?: number,
) => {
  const resp = await api.post<InterviewFeedbackInterface>(
    `${API.HIRING_PROCESS}${API.INTERVIEW_SCORECARD_TEMPLATES}/generateScorecardPreview`,
    normalizeInterviewForPreview(data),
    {
      params: {
        template_id: id,
      },
    },
  )

  return { ...resp, data: transformInterviewToPerformanceScorecard(resp.data) }
}

export const getInterviewScorecardTemplate = (templateId?: number | string) =>
  api.get<InterviewScorecardTemplateInterface>(
    `${API.HIRING_PROCESS}${API.INTERVIEW_SCORECARD_TEMPLATES}/${templateId}`,
  )

export const deleteInterviewScorecardTemplate = async ({
  id,
}: Partial<InterviewScorecardTemplateInterface>) =>
  api.delete<InterviewScorecardTemplateInterface>(
    `${API.HIRING_PROCESS}${API.INTERVIEW_SCORECARD_TEMPLATES}/${id}`,
  )

export const interviewScorecardTemplateFormRequests: RequestInterfaceNew<InterviewScorecardTemplateInterface> =
  {
    get: async ({ id }) =>
      api.get(`${API.HIRING_PROCESS}${API.INTERVIEW_SCORECARD_TEMPLATES}/${id}`),
    update: async (diff, { id }, data) =>
      apiWithoutHandling.patch(
        `${API.HIRING_PROCESS}${API.INTERVIEW_SCORECARD_TEMPLATES}/${id}`,
        normalizeInterviewForPreview(diff, data),
      ),
    submit: async data =>
      apiWithoutHandling.post(
        `${API.HIRING_PROCESS}${API.INTERVIEW_SCORECARD_TEMPLATES}`,
        normalizeInterviewForPreview(data),
      ),
    delete: deleteInterviewScorecardTemplate,
  }

export const interviewScorecardTemplateRequests: TableRequestInterface<InterviewScorecardTemplateInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`${API.HIRING_PROCESS}${API.INTERVIEW_SCORECARD_TEMPLATES}`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const useGetInterviewScorecardTemplates = () =>
  useFetch<GetRequestInterface<InterviewScorecardTemplateInterface>>(
    `${API.HIRING_PROCESS}${API.INTERVIEW_SCORECARD_TEMPLATES}`,
    undefined,
    {
      params: {
        stage_type: 'screen_call',
      },
    },
  )
