import {
  GetRequestInterface,
  IdAndName,
  RequestInterfaceNew,
  tableRequests,
  UseFetchResult,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import {
  AddInterviewRoundInterface,
  CandidateCVInterface,
  CandidateInterface,
  InterviewCandidateStageOptionInterface,
  InterviewRoundInterface,
  InterviewRoundSummaryInterface,
  InterviewFeedbackInterface,
  InterviewSpecialisationInterface,
  InterviewStageInterface,
  OriginType,
  BulkMoveToStageInterface,
  BulkSendOnlineTestInterface,
  CandidateFreezingPeriodInterface,
  SnoozeCandidateInterface,
  InterviewStageWithoutRoundInterface,
  HireVueEventType,
  OnlineTestResultStageInterface,
  InterviewFeedbackStats,
  CandidatesListInterface,
  PerformanceHiringRoundSummaryInterface,
  CandidateScoringExplanationInterface,
  RecruiterDetailsInterface,
} from '@src/interfaces/interviewTool'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { AxiosPromise, AxiosResponse } from 'axios'
import produce from 'immer'
import { transformInterviewToPerformanceScorecard } from '@src/utils/interview'
import { useFetch } from '@src/utils/reactQuery'
import { Currency, OptionInterface } from '@src/interfaces/selectors'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { RequisitionSelectorInterface } from '@src/interfaces/requisitions'
import { pathToUrl } from '@src/utils/router'
import { useMemo } from 'react'
import { QueryClient } from 'react-query'
import groupBy from 'lodash/groupBy'
import lodashSortBy from 'lodash/sortBy'

export const getCandidate = (id: string | number, apiHandler = apiWithoutHandling) =>
  apiHandler.get<CandidateInterface>(`${API.INTERVIEWS}/candidates/${id}`)

export const useGetCandidate = (id?: string | number | null) =>
  useFetch<CandidateInterface>(id ? `${API.INTERVIEWS}/candidates/${id}` : null)

export const snoozeCandidate = (data: Partial<SnoozeCandidateInterface>, id: string) =>
  apiWithoutHandling.patch(`${API.INTERVIEWS}/candidates/${id}/snooze`, {
    snooze_until: data.snooze_until,
    notify_employees: data.notify_employees,
    comment: data.comment,
  })

export const snoozeCandidateApi: RequestInterfaceNew<SnoozeCandidateInterface> = {
  // there is no get for snoozing candidates
  get: () => Promise.resolve({ data: {} } as any),
  update: async (data, { id }) => snoozeCandidate(data, id!),
  submit: async (data, { id }) => snoozeCandidate(data, id!),
}

export const bulkSnoozeCandidate = (data: Partial<SnoozeCandidateInterface>) =>
  apiWithoutHandling.post(API.CANDIDATES_BULK_SNOOZE, {
    candidate_ids: data.candidate_ids,
    snooze_until: data.snooze_until,
    notify_employees: data.notify_employees,
    comment: data.comment,
  })

export const bulkSnoozeCandidateApi: RequestInterfaceNew<SnoozeCandidateInterface> = {
  // there is no get for bulk snoozing candidates
  get: () => Promise.resolve({ data: {} } as any),
  update: async data => bulkSnoozeCandidate(data),
  submit: async data => bulkSnoozeCandidate(data),
}

export const unsnoozeCandidate = (id: number) =>
  api.patch(`${API.INTERVIEWS}/candidates/${id}/unsnooze`)

export const bulkUnsnoozeCandidate = (ids: number[]) =>
  api.post(API.CANDIDATES_BULK_UNSNOOZE, {
    candidate_ids: ids,
  })

export const getCandidateEligibleRequisitions = (
  id: number,
): AxiosPromise<GetRequestInterface<InterviewSpecialisationInterface>> =>
  api.get(`${API.INTERVIEWS}/candidates/${id}/eligibleSpecialisations`)

export const createInterviewRound = (
  candidateId: number,
  specialisationId: number,
  seniorityId?: number,
  recruiterId?: number,
  coordinatorId?: number,
  origin?: OriginType,
  created_by?: EmployeeOptionInterface | null,
  hiringManager?: EmployeeOptionInterface | null,
  requisition?: RequisitionSelectorInterface | null,
  preferred_location?: IdAndName & { location_name: string },
  local_currency?: Currency,
  local_expected_salary?: number,
  additional_information_about_expected_salary?: string,
  candidate_declined_to_disclose_expected_salary?: boolean,
): AxiosPromise =>
  api.post(`${API.INTERVIEWS}/rounds`, {
    candidate: { id: candidateId },
    specialisation: { id: specialisationId },
    seniority: seniorityId ? { id: seniorityId } : null,
    recruiter: recruiterId ? { id: recruiterId } : null,
    coordinator: coordinatorId ? { id: coordinatorId } : null,
    origin,
    created_by,
    hiring_manager: hiringManager,
    requisition,
    preferred_location,
    local_currency,
    local_expected_salary,
    additional_information_about_expected_salary,
    candidate_declined_to_disclose_expected_salary,
  })

export const updateInterviewRound = ({
  id,
  seniorityId,
  recruiterId,
  coordinatorId,
  origin,
  created_by,
  hiringManager,
  requisition,
  preferred_location,
  local_currency,
  local_expected_salary,
  additional_information_about_expected_salary,
  candidate_declined_to_disclose_expected_salary,
}: {
  id: number
  seniorityId?: number
  recruiterId?: number
  coordinatorId?: number
  origin?: OriginType
  created_by?: EmployeeOptionInterface | null
  hiringManager?: EmployeeOptionInterface | null
  requisition?: RequisitionSelectorInterface | null
  preferred_location?: IdAndName & { location_name: string }
  local_currency?: Currency
  local_expected_salary?: number
  additional_information_about_expected_salary?: string
  candidate_declined_to_disclose_expected_salary?: boolean
}): AxiosPromise<InterviewRoundInterface> =>
  api.patch(`${API.INTERVIEWS}/rounds/${id}`, {
    seniority: { id: seniorityId },
    recruiter: { id: recruiterId },
    coordinator: coordinatorId ? { id: coordinatorId } : null,
    origin,
    created_by,
    hiring_manager: hiringManager,
    requisition,
    preferred_location,
    local_currency,
    local_expected_salary,
    additional_information_about_expected_salary,
    candidate_declined_to_disclose_expected_salary,
  })

export const getInterviewRound = (
  id: number,
  apiHandler = api,
): AxiosPromise<InterviewRoundInterface> =>
  apiHandler.get(`${API.INTERVIEWS}/rounds/${id}`)

export const changeInterviewStage = (
  id: number,
  stageId: number,
): AxiosPromise<InterviewRoundInterface> =>
  api.patch(`${API.INTERVIEWS}/rounds/${id}`, {
    latest_interview_stage: { id: stageId },
  })

export const skipAutomaticScheduling = (roundId: number, stageId: number) =>
  api.post(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/skipAutomations`,
  )

export const getCandidateInterviewStages = async (
  { sortBy, filters, page }: FetchDataQueryInterface,
  id: number | string,
  apiHandler = api,
) => {
  const resp = await apiHandler.get<
    GetRequestInterface<InterviewStageWithoutRoundInterface>
  >(`${API.INTERVIEWS}/rounds/${id}/interviewStages`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

  return produce(resp, draft => {
    draft.data.results.forEach(stage => {
      stage.interview_feedbacks = stage.interview_feedbacks.map(feedback =>
        transformInterviewToPerformanceScorecard(feedback),
      )
    })
  })
}

export const useFetchCandidateInterviewStages = (
  { sortBy, filters, page }: FetchDataQueryInterface,
  id?: number | string,
) => {
  const context = useFetch<GetRequestInterface<InterviewStageWithoutRoundInterface>>(
    id ? `${API.INTERVIEWS}/rounds/${id}/interviewStages` : null,
    undefined,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  )

  const results = useMemo(
    () =>
      produce(context.data?.results, draft => {
        draft?.forEach(stage => {
          stage.interview_feedbacks = stage.interview_feedbacks.map(feedback =>
            transformInterviewToPerformanceScorecard(feedback),
          )
        })
      }),
    [context.data?.results],
  )

  return {
    ...context,
    data: results || [],
  }
}

export const getCandidateInterviewStage = async (roundId: number, stageId: number) => {
  const resp = await getCandidateInterviewStages(
    {
      filters: [
        {
          filters: [
            {
              id: stageId,
              name: String(stageId),
            },
          ],
          columnName: 'id',
        },
      ],
    },
    roundId,
  )

  return {
    data: resp.data.results[0],
  } as AxiosResponse<InterviewStageInterface>
}

export const getInterviewFeedbacks = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<InterviewFeedbackInterface>
> =>
  api.get(`${API.INTERVIEWS}/interviewFeedbacks`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const useGetInterviewStats = (filters: FilterByInterface[]) =>
  useFetch<InterviewFeedbackStats>(
    `${API.INTERVIEWS}/interviewFeedbacks/stats`,
    undefined,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
  )

export const cancelInterviewFeedback = async (id: number) =>
  api.post(`${API.INTERVIEWS}/interviewFeedbacks/${id}/cancelFeedback`)

export const getUpcomingInterviews = (
  id: string,
): AxiosPromise<InterviewFeedbackInterface[]> =>
  api.get(`${API.INTERVIEWS}/interviewFeedbacks/currentUserUpcomingInterview/${id}`)

export const interviewCandidatesRequests: tableRequests<
  CandidatesListInterface,
  undefined
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.INTERVIEWS}/candidates`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const getAllCandidateStages = (id: number | string) =>
  api.get<{ options: InterviewCandidateStageOptionInterface[] }>(
    `${API.INTERVIEWS}/candidates/${id}/allStages`,
  )

export const getRoundSummary = async (id: number) => {
  const resp = await api.get<InterviewRoundSummaryInterface>(
    pathToUrl(API.ROUND_SUMMARY, {
      roundId: id,
    }),
  )

  return produce(resp, draft => {
    draft.data.skill_ratings_normalized = [
      {
        name: 'Deliverables',
        children: draft.data.skill_ratings.delivery,
      },
      {
        name: 'Skills',
        children: draft.data.skill_ratings.skill,
      },
      {
        name: 'Values',
        children: draft.data.skill_ratings.value,
      },
    ]
  })
}

export const useGetCandidatePerformanceProfile = (
  roundId?: number,
  interviewerIds?: number[],
) =>
  useFetch<PerformanceHiringRoundSummaryInterface>(
    roundId
      ? pathToUrl(API.CANDIDATE_PERFORMANCE_PROFILE, {
          roundId,
        })
      : null,
    undefined,
    {
      params: {
        interviewer_ids: interviewerIds?.join(','),
      },
    },
    true,
  )

export const useGetCV = (id?: number | null) => {
  const context = useFetch<GetRequestInterface<CandidateCVInterface>>(
    id ? `${API.INTERVIEWS}/candidates/${id}/resumes` : null,
    undefined,
    undefined,
    undefined,
    {
      // as we use iframe
      refetchOnWindowFocus: false,
      staleTime: 1000,
    },
  )

  return { ...context, data: context.data?.results[0] }
}

export const skipScheduledInterview = (
  roundId: number,
  stageId: number,
): AxiosPromise<InterviewRoundInterface> =>
  api.post(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/skipScheduling`,
  )

export const unSkipScheduledInterview = (
  roundId: number,
  stageId: number,
): AxiosPromise<InterviewRoundInterface> =>
  api.post(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/unskipScheduling`,
  )

export const useGetUnarchivedInterviewRounds = (id: number | null) => {
  const context = useFetch<InterviewRoundInterface[]>(
    id ? `${API.INTERVIEWS}/candidates/${id}/unarchivedInterviewRounds` : null,
  )

  const data = useMemo(() => lodashSortBy(context.data, o => !o.active), [context.data])

  return {
    ...context,
    data,
  }
}

export const useGetInterviewRounds = (id?: number) =>
  useFetch<InterviewRoundInterface[]>(
    id ? `${API.INTERVIEWS}/candidates/${id}/interviewRounds` : null,
  )

export const archiveInterviews = async (
  id: number,
  roundIds: number[],
  reason: OptionInterface,
  comment?: string,
  queryClient?: QueryClient,
): Promise<AxiosPromise<InterviewRoundInterface>> => {
  const resp = await apiWithoutHandling.post(
    `${API.INTERVIEWS}/candidates/${id}/bulkUpdateArchivedStateInterviewRounds`,
    {
      interview_round_ids: roundIds,
      archived_reason: reason,
      archived_comment: comment,
    },
  )

  queryClient?.invalidateQueries(pathToUrl(API.FULL_INTERVIEW_ROUNDS, { id }))

  return resp
}

export const bulkArchiveCandidates = (
  candidateIds: (number | string)[],
  archiveAll: boolean,
  reason: OptionInterface,
  comment?: string,
): AxiosPromise<InterviewRoundInterface> =>
  api.post(`/candidateBulkArchive`, {
    candidate_ids: candidateIds,
    archive_all_rounds: archiveAll,
    archived_reason: reason,
    archived_comment: comment,
  })

export const unarchiveInterviewRound = async (
  id: number,
  candidateId?: number,
  queryClient?: QueryClient,
): Promise<AxiosPromise<InterviewRoundInterface>> => {
  const resp = await api.patch(`${API.INTERVIEWS}/rounds/${id}`, {
    archived_reason: null,
  })

  if (candidateId) {
    queryClient?.invalidateQueries(
      pathToUrl(API.FULL_INTERVIEW_ROUNDS, { id: candidateId }),
    )
  }

  return resp
}

export const updatePersonalData = (
  id: number,
  data: Partial<CandidateInterface>,
): AxiosPromise<CandidateInterface> =>
  apiWithoutHandling.patch(`${API.INTERVIEWS}/candidates/${id}`, data)

export const updateCandidateTags = (
  id: number,
  tags: (OptionInterface | { name: string })[],
) => api.patch(`${API.INTERVIEWS}/candidates/${id}`, { tags })

export const anonymizeCandidate = (id: string) =>
  api.patch(`${API.INTERVIEWS}/candidates/${id}/anonymise`)

export const useGetFullInterviewRounds = (candidateId: number | null) =>
  useFetch<InterviewRoundInterface[]>(
    candidateId ? pathToUrl(API.FULL_INTERVIEW_ROUNDS, { id: candidateId }) : null,
    undefined,
    undefined,
    undefined,
    {
      staleTime: 1000 * 30,
      cacheTime: 1000 * 30,
    },
  )

export const getFullInterviewRounds = (candidateId: number | null) =>
  apiWithoutHandling.get<InterviewRoundInterface[]>(
    pathToUrl(API.FULL_INTERVIEW_ROUNDS, { id: candidateId }),
  )

export const changeInterviewRound = (candidateId: number, roundId: number) =>
  api.patch(`${API.INTERVIEWS}/candidates/${candidateId}/updateActiveInterviewRound`, {
    active_interview_round: { id: roundId },
  })

export const addNewInterviewRoundRequest: RequestInterfaceNew<AddInterviewRoundInterface> =
  {
    get: async ({ candidateId, id }) =>
      api.get(`${API.INTERVIEWS}/candidates/${candidateId}/sourceForPosting/${id}`),
    update: async (data, { candidateId, id }) =>
      apiWithoutHandling.patch(
        `${API.INTERVIEWS}/candidates/${candidateId}/sourceForPosting/${id}`,
        data,
      ),
    submit: async (data, { candidateId }) =>
      apiWithoutHandling.post(
        `${API.INTERVIEWS}/candidates/${candidateId}/sourceForPosting`,
        data,
      ),
    delete: async (_, { candidateId, id }) =>
      api.delete(`${API.INTERVIEWS}/candidates/${candidateId}/sourceForPosting/${id}`),
  }

export const bulkMoveToStage = (data: BulkMoveToStageInterface) =>
  apiWithoutHandling.post(API.CANDIDATES_BULK_MOVE_TO_STAGE, data)

export const bulkSendOnlineTest = (data: BulkSendOnlineTestInterface) =>
  apiWithoutHandling.post(API.CANDIDATES_BULK_SEND_ONLINE_TEST, data)

export const useGetFreezingPeriod = (
  candidateId?: number | string,
  specialisationId?: number,
) =>
  useFetch<CandidateFreezingPeriodInterface>(
    candidateId && specialisationId
      ? `/candidateFreezingPeriods/getFreezingPeriod`
      : null,
    undefined,
    {
      params: {
        candidate_id: candidateId,
        specialisation_id: specialisationId,
      },
    },
  )

export const refreshInterviewStages = (roundId: number) => {
  return api.post(`${API.INTERVIEWS}/rounds/${roundId}/refreshInterviewStages`)
}

export const markCVasSeen = (roundId: number, stageId: number) =>
  apiWithoutHandling.post(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/skipCVScreening`,
  )

export const useGetSources = (id?: number) =>
  useFetch<{ options: IdAndName<OriginType>[] }>(
    id ? `${API.INTERVIEWS}/candidates/${id}/originChoices` : null,
  )

export const useGetOnlineTestResults = (
  roundId?: number,
  stageId?: number,
): UseFetchResult<OnlineTestResultStageInterface[]> => {
  const context = useFetch<{ id: number; event_data: HireVueEventType }[]>(
    roundId && stageId
      ? `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/onlineTestResultEvents`
      : null,
  )

  const data = useMemo<OnlineTestResultStageInterface[]>(() => {
    if (context.data) {
      const groupedByStage = groupBy(context.data, 'event_data.InterviewId')

      return Object.keys(groupedByStage).map(interviewId => {
        const items = groupedByStage[interviewId]

        return items.reduce<OnlineTestResultStageInterface>(
          (acc, item) => {
            if ('CurrentStage' in item.event_data) {
              acc.currentStage = item.event_data.CurrentStage
            }

            acc.events.push({
              id: item.id,
              band: item.event_data.Band,
              interviewId: item.event_data.InterviewId,
              interviewStatus:
                'ReviewUrl' in item.event_data ? item.event_data.Status : undefined,
              workflowStatus:
                'ReviewUrl' in item.event_data ? undefined : item.event_data.Status,
              score: item.event_data.Score,
              reviewUrl:
                'ReviewUrl' in item.event_data ? item.event_data.ReviewUrl : undefined,
              startedDate:
                'StartedDate' in item.event_data
                  ? item.event_data.StartedDate
                  : undefined,
              submittedDate:
                'SubmittedDate' in item.event_data
                  ? item.event_data.SubmittedDate
                  : undefined,
            })

            return acc
          },
          { interviewId: +interviewId, currentStage: '', events: [] },
        )
      })
    }

    return []
  }, [context.data])

  return {
    ...context,
    data,
  } as UseFetchResult<OnlineTestResultStageInterface[]>
}

export const useFetchCandidateScoreExplanation = (roundId: number | null) =>
  useFetch<CandidateScoringExplanationInterface>(
    roundId ? `${API.INTERVIEWS}/rounds/${roundId}/candidateScoreExplanation` : null,
  )

export const getRecruiterDetails = (candidateId: number) =>
  api.get<RecruiterDetailsInterface>(
    `${API.INTERVIEWS}/candidates/${candidateId}/getRecruiterDetails`,
  )

export const duplicateStage = (roundId: number, stageId: number) =>
  apiWithoutHandling.post<InterviewStageWithoutRoundInterface>(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/duplicateStage`,
  )

export const removeStage = (roundId: number, stageId: number) =>
  apiWithoutHandling.post<InterviewStageWithoutRoundInterface>(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/archiveStage`,
  )
