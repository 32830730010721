import React, { useRef, useState } from 'react'
import { CheckboxSelect, FilterButton as FilterButtonComponent } from '@revolut/ui-kit'
import { OptionInterface } from '@src/interfaces/selectors'

interface Props<T> {
  options: T[]
  label: string
  onChange: (options?: T[]) => void
  value?: T[]
  disabled?: boolean
  width?: number
  anchor?: React.RefObject<HTMLElement | null>
  searchable?: boolean
  loading?: boolean
}

const FilterButtonCheckboxSelect = <T extends OptionInterface>({
  options,
  label,
  onChange,
  value,
  disabled,
  width,
  anchor,
  searchable = false,
  loading,
}: Props<T>) => {
  const anchorRef = useRef<HTMLSpanElement>(null)
  const [isOpen, setOpen] = useState(false)
  const hasValue = !!value?.length

  return (
    <>
      <span ref={anchorRef}>
        <FilterButtonComponent
          useIcon="Filter"
          onClick={() => setOpen(!isOpen)}
          onClear={
            hasValue
              ? () => {
                  onChange([])
                  setOpen(false)
                }
              : undefined
          }
          active={hasValue}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          disabled={disabled}
          pending={loading}
        >
          {label} {hasValue ? ` · ${value!.length}` : ''}
        </FilterButtonComponent>
      </span>
      <CheckboxSelect
        anchorRef={anchor || anchorRef}
        open={isOpen}
        type="confirm"
        onClose={() => setOpen(false)}
        options={options.map(item => ({
          key: item.id,
          label: item.name,
          value: item,
        }))}
        value={value}
        onChange={val => {
          onChange(val)
          setOpen(false)
        }}
        labelList={label}
        width={width}
        maxWidth={width}
        searchable={searchable}
      />
    </>
  )
}

export default FilterButtonCheckboxSelect
