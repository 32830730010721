import React from 'react'
import { useSelector } from 'react-redux'

import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { PermissionTypes } from '@src/store/auth/types'
import { performanceSettings } from '@src/api/performanceSettings'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import { ApprovalProcessesFields } from '@src/pages/Settings/KPI/ApprovalProcessesFields'
import { selectPermissions } from '@src/store/auth/selectors'
import { GoalsSettingsProps } from './Goals'
import { SaveApprovalSettingsButton } from '@src/pages/Settings/common/SaveApprovalSettingsButton'

export const ApprovalProcesses = () => {
  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangePerformancePreferences)

  return (
    <>
      <PageBody>
        <AutoStepper>
          <ApprovalProcessesFields disableEdit={disableEdit} variant="goal" />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <SaveApprovalSettingsButton />
      </PageActions>
    </>
  )
}

export const GoalsSettingsApprovalProcesses = ({
  approvalProcessRoute,
}: GoalsSettingsProps) => {
  const routes = [
    {
      title: 'General',
      path: approvalProcessRoute,
      url: approvalProcessRoute,
      canView: [
        PermissionTypes.ViewPerformancePreferences,
        PermissionTypes.ChangePerformancePreferences,
      ],
      component: ApprovalProcesses,
    },
  ]

  return (
    <SettingsForm routes={routes} api={performanceSettings} title="Approval process" />
  )
}
