import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import isPast from 'date-fns/isPast'
import { formatDistance } from 'date-fns'
import upperFirst from 'lodash/upperFirst'
import {
  ActionButton,
  Text,
  Group,
  MoreBar,
  Box,
  Image,
  Item,
  ErrorWidget,
  Avatar,
  VStack,
  HStack,
  TextButton,
  H5,
  Input,
  Icon,
  Token,
} from '@revolut/ui-kit'

import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  KeyPersonResponsibilityAssignmentInterface,
  KeyPersonSuccessionPlanInterface,
} from '@src/interfaces/keyPerson'
import { FormPreview } from '@components/FormPreview/FormPreview'
import SearchIcon from '@src/assets/3D/Search@2x.png'
import { getStatusColor } from '@components/CommonSC/General'
import { formatDate } from '@src/utils/format'
import { ActiveSuccessorsNumBanner } from '../ActiveSuccessorsNumBanner'
import {
  approveSuccessionPlan,
  getEmployeeKeyPersonResponsibilitiesAssignments,
  rejectSuccessionPlan,
  useSuccessionPlanApprovals,
  useSuccessionPlanFiles,
} from '@src/api/keyPerson'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { getOrderedSuccessors } from '../helpers'

type Props = {
  setEmployeeId: (id: number) => void
  showResponsibilitiesSidebar: () => void
}
export const Preview = ({ setEmployeeId, showResponsibilitiesSidebar }: Props) => {
  const { values, initialValues, reset } =
    useLapeContext<KeyPersonSuccessionPlanInterface>()

  const [isApprovalPending, setIsApprovalPending] = useState(false)
  const [isApproveConfirmationOpen, setIsApproveConfirmationOpen] = useState(false)
  const [isRejectionPending, setIsRejectionPending] = useState(false)
  const [isRejectConfirmationOpen, setIsRejectConfirmationOpen] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const [responsibilities, setResponsibilities] = useState<
    KeyPersonResponsibilityAssignmentInterface[]
  >([])

  useEffect(() => {
    if (initialValues.employee?.id) {
      getEmployeeKeyPersonResponsibilitiesAssignments(initialValues.employee.id).then(
        ({ data }) => {
          setResponsibilities(data)
        },
      )
    }
  }, [initialValues.employee?.id])

  const activeResponsibilities = responsibilities.filter(
    ({ assignment_status }) => assignment_status?.id !== 'archived',
  )

  useEffect(() => {
    if (initialValues.employee?.id) {
      setEmployeeId(initialValues.employee.id)
    }
  }, [initialValues.employee?.id])

  const canEdit = initialValues.field_options?.permissions?.includes(
    PermissionTypes.ChangeKeyRiskProfileSuccessionPlans,
  )
  const canApprove = initialValues.field_options?.permissions?.includes(
    PermissionTypes.ApproveKeyRiskProfileSuccessionPlans,
  )
  const canReject = initialValues.field_options?.permissions?.includes(
    PermissionTypes.RejectKeyRiskProfileSuccessionPlans,
  )
  const {
    data: approvalSteps,
    isFetching: isApprovalLoading,
    refetch: refetchApprovals,
  } = useSuccessionPlanApprovals(initialValues.id!)

  const { data: files } = useSuccessionPlanFiles(values.id)

  return (
    <>
      <PageBody>
        <Box mb="s-24">
          <MoreBar>
            {canEdit && (
              <MoreBar.Action
                useIcon="Pencil"
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.EDIT, {
                  id: String(initialValues.id),
                })}
              >
                Edit
              </MoreBar.Action>
            )}
            {canApprove && initialValues.status?.id === 'pending_approval' && (
              <MoreBar.Action
                useIcon="Check"
                pending={isApprovalPending}
                onClick={() => setIsApproveConfirmationOpen(true)}
              >
                Approve
              </MoreBar.Action>
            )}
            {canReject && initialValues.status?.id === 'pending_approval' && (
              <MoreBar.Action
                variant="negative"
                useIcon="CrossSmall"
                pending={isRejectionPending}
                onClick={() => setIsRejectConfirmationOpen(true)}
              >
                Reject
              </MoreBar.Action>
            )}
            <MoreBar.Action useIcon="EyeShow" onClick={showResponsibilitiesSidebar}>
              Show responsibilities
            </MoreBar.Action>
          </MoreBar>
        </Box>
        {approvalSteps === undefined || approvalSteps?.length ? (
          <ApprovalFlow isLoading={isApprovalLoading} steps={approvalSteps || null} />
        ) : null}
        <FormPreview data={initialValues} title="General">
          <Group>
            {files?.results.map(fileData => (
              <FormPreview.Item<KeyPersonSuccessionPlanInterface>
                key={fileData.id}
                title={fileData.file.name}
                insert={() =>
                  fileData.file.url ? (
                    <TextButton
                      onClick={() => {
                        window.open(`${fileData.file.url}`, '_blank')?.focus()
                      }}
                    >
                      <HStack align="center" space="s-8">
                        <Text>Download</Text>
                        <Icon name="Download" size={15} />
                      </HStack>
                    </TextButton>
                  ) : (
                    '-'
                  )
                }
              />
            ))}

            <FormPreview.Item<KeyPersonSuccessionPlanInterface>
              title="Valid until"
              field="valid_until_date_time"
              insert={data => {
                return isPast(new Date(data.valid_until_date_time)) ? (
                  <VStack>
                    <HStack align="center" space="s-8" alignSelf="flex-end">
                      <Text color={Token.color.red}>
                        {formatDate(data.valid_until_date_time)}
                      </Text>
                      <Icon name="ExclamationMark" size={16} color={Token.color.red} />
                    </HStack>
                    <Text color={Token.color.greyTone50} alignSelf="flex-end">
                      Expired{' '}
                      {formatDistance(new Date(), new Date(data.valid_until_date_time))}{' '}
                      ago
                    </Text>
                  </VStack>
                ) : (
                  formatDate(data.valid_until_date_time)
                )
              }}
            />
            <FormPreview.Details title="Notes" field="notes" />
          </Group>
        </FormPreview>
        {activeResponsibilities.map(responsibility => {
          const successionPlanConfig =
            initialValues?.key_risk_profile_succession_plan_config?.find(
              config => config.key_risk_profile.id === responsibility.id,
            )

          return (
            <FormPreview
              data={initialValues}
              key={responsibility.id}
              title={`${responsibility.key_person_type.name} successors`}
            >
              {successionPlanConfig ? (
                <>
                  <ActiveSuccessorsNumBanner
                    successionPlanConfig={successionPlanConfig}
                    withActionButton
                  />
                  {successionPlanConfig.successors.length ? (
                    <Group>
                      {getOrderedSuccessors(
                        successionPlanConfig.successors,
                        successionPlanConfig.successors_order,
                      ).map(successor => {
                        const isActive = successor.status === 'active'

                        return (
                          <Item
                            key={successor.id}
                            use={InternalLink}
                            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                              id: successor.id,
                            })}
                            inactive={!isActive}
                          >
                            <Item.Avatar>
                              <Avatar useIcon="Profile" />
                            </Item.Avatar>
                            <Item.Content>
                              <Item.Title>{successor.full_name}</Item.Title>
                              {!!successor.specialisation?.name && (
                                <Item.Description>
                                  {successor.specialisation?.name}
                                </Item.Description>
                              )}
                            </Item.Content>
                            <Item.Side>
                              <Item.Title color={getStatusColor(successor.status)}>
                                {upperFirst(successor.status)}
                              </Item.Title>
                            </Item.Side>
                          </Item>
                        )
                      })}
                    </Group>
                  ) : (
                    <ErrorWidget>
                      <ErrorWidget.Image src={SearchIcon} />
                      <ErrorWidget.Title>
                        No successors added to this plan
                      </ErrorWidget.Title>
                    </ErrorWidget>
                  )}
                </>
              ) : (
                <Item mb="s-16">
                  <Item.Avatar>
                    <Image
                      image={{
                        default: 'https://assets.revolut.com/assets/3d-images/3D018.png',
                        '2x': 'https://assets.revolut.com/assets/3d-images/3D018@2x.png',
                        '3x': 'https://assets.revolut.com/assets/3d-images/3D018@3x.png',
                      }}
                    />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>Configure successor strategy</Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <ActionButton
                      to={pathToUrl(
                        ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.EDIT,
                        {
                          id: String(initialValues.id),
                        },
                        { selectedResponsibilityIdx: String(responsibility.id) },
                      )}
                      use={Link}
                    >
                      Configure
                    </ActionButton>
                  </Item.Side>
                </Item>
              )}
            </FormPreview>
          )
        })}
      </PageBody>
      <ConfirmationDialog
        open={isApproveConfirmationOpen}
        onClose={() => setIsApproveConfirmationOpen(false)}
        onReject={() => setIsApproveConfirmationOpen(false)}
        loading={isApprovalPending}
        onConfirm={async () => {
          try {
            setIsApprovalPending(true)
            const { data } = await approveSuccessionPlan(initialValues.id!)
            reset(data)
            await refetchApprovals()
            setIsApproveConfirmationOpen(false)
          } finally {
            setIsApprovalPending(false)
          }
        }}
        showHeader={false}
        body={<H5>Are you sure you want to approve this succession plan?</H5>}
        yesMessage="Confirm"
        noMessage="Go back"
      />
      <ConfirmationDialog
        open={isRejectConfirmationOpen}
        onClose={() => setIsRejectConfirmationOpen(false)}
        onReject={() => setIsRejectConfirmationOpen(false)}
        loading={isRejectionPending}
        onConfirm={async () => {
          try {
            setIsRejectionPending(true)
            const { data } = await rejectSuccessionPlan(
              initialValues.id!,
              rejectionReason,
            )
            reset(data)
            await refetchApprovals()
            setIsRejectConfirmationOpen(false)
          } finally {
            setIsRejectionPending(false)
          }
        }}
        showHeader={false}
        body={
          <>
            <H5 mb="s-12">Please add rejection reason</H5>
            <Input
              value={rejectionReason}
              onChange={e => setRejectionReason(e.currentTarget.value)}
            />
          </>
        }
        yesMessage="Confirm"
        noMessage="Go back"
      />
    </>
  )
}
