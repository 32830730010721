import React from 'react'
import { FormattedMessage } from 'react-intl'

export const EnableApprovalsTitle = () => (
  <FormattedMessage
    id="growthPlanSettings.approvalProcess.title"
    defaultMessage="Growth plan approval"
  />
)

export const EnableApprovalsDescription = () => (
  <FormattedMessage
    id="growthPlanSettings.approvalProcess.description"
    defaultMessage="Enable this option to set an approval process for created growth plans"
  />
)

export const EnableExtraApprovalsTitle = () => (
  <FormattedMessage
    id="growthPlanSettings.approvalProcess.extra.title"
    defaultMessage="Extra HoD and HoF approval for to Lead+ seniorities"
  />
)

export const EnableExtraApprovalsDescription = () => (
  <FormattedMessage
    id="growthPlanSettings.approvalProcess.extra.description"
    defaultMessage="By enabling  this, all growth plans for Lead+ seniorities will have to be approved by HoD/HoF"
  />
)
