import React from 'react'
import { useParams } from 'react-router-dom'

import RiskCircles, {
  statsToKri,
} from '@components/ColumnInserts/RiskCircles/RiskCircles'
import { useTable } from '@components/TableV2/hooks'
import {
  getRiskControls,
  getRiskControlsStats,
  getRiskIncidents,
  getRiskIncidentsStats,
  getRiskIndicators,
  getRiskIndicatorsStats,
  getRiskSummary,
  getRiskSummaryStats,
} from '@src/api/risk'
import { ROUTES } from '@src/constants/routes'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  RiskControlsInterface,
  RiskIncidentsInterface,
  RiskIndicatorInterface,
  RiskStatsInterface,
  RiskStatus,
  RiskSummaryInterface,
} from '@src/interfaces/risk'
import Controls from '@src/pages/Forms/CommonRiskTab/Controls'
import Incidents from '@src/pages/Forms/CommonRiskTab/Incidents'
import Indicators from '@src/pages/Forms/CommonRiskTab/Indicators'
import RiskTab from '@src/pages/Forms/CommonRiskTab/RiskTab'
import Summary from '@src/pages/Forms/CommonRiskTab/Summary'
import { pathToUrl } from '@src/utils/router'

interface Props {
  data: EmployeeInterface
}

export const Risk = ({ data }: Props) => {
  const params = useParams<{ id: string; subtab?: string }>()
  const initialFilter = [
    {
      filters: [{ name: data.id.toString(), id: data.id }],
      columnName: 'assignee__id',
      nonResettable: true,
      nonInheritable: true,
    },
  ]
  const cntrolsInitialFilter = [
    {
      filters: [{ name: data.id.toString(), id: data.id }],
      columnName: 'owner__id',
      nonResettable: true,
      nonInheritable: true,
    },
  ]
  const initialFilterIncidents = [
    ...initialFilter,
    {
      filters: [{ id: RiskStatus.progress, name: RiskStatus.progress }],
      columnName: 'status',
      nonResettable: true,
      nonInheritable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'risk_level_order',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const summaryTable = useTable<RiskSummaryInterface, RiskStatsInterface>(
    {
      getItems: getRiskSummary,
      getStats: getRiskSummaryStats,
    },
    initialFilter,
    initialSortBy,
  )

  const indicatorTable = useTable<RiskIndicatorInterface, RiskStatsInterface>(
    {
      getItems: getRiskIndicators,
      getStats: getRiskIndicatorsStats,
    },
    initialFilter,
    initialSortBy,
  )

  const incidentTable = useTable<RiskIncidentsInterface, RiskStatsInterface>(
    {
      getItems: getRiskIncidents,
      getStats: getRiskIncidentsStats,
    },
    initialFilterIncidents,
    initialSortBy,
  )

  const controlTable = useTable<RiskControlsInterface, RiskStatsInterface>(
    {
      getItems: getRiskControls,
      getStats: getRiskControlsStats,
    },
    cntrolsInitialFilter,
    initialSortBy,
  )

  const tabs = [
    {
      title: 'Summary',
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.SUMMARY, params),
      component: <Summary table={summaryTable} entity="employee" />,
      quickSummary: summaryTable.stats !== undefined && (
        <RiskCircles data={statsToKri(summaryTable.stats)} />
      ),
    },
    {
      title: 'Risks and controls',
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.CONTROLS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.CONTROLS, params),
      component: <Controls table={controlTable} entity="employee" />,
      quickSummary: controlTable.stats !== undefined && (
        <RiskCircles data={statsToKri(controlTable.stats)} />
      ),
    },
    {
      title: 'Risk Indicators',
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.INDICATORS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.INDICATORS, params),
      component: <Indicators table={indicatorTable} entity="employee" />,
      quickSummary: indicatorTable.stats !== undefined && (
        <RiskCircles data={statsToKri(indicatorTable.stats)} />
      ),
    },
    {
      title: 'Risk Incidents',
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.INCIDENTS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.RISK.INCIDENTS, params),
      component: <Incidents table={incidentTable} entity="employee" />,
      quickSummary: incidentTable.stats !== undefined && (
        <RiskCircles data={statsToKri(incidentTable.stats)} />
      ),
    },
  ]

  return <RiskTab tabs={tabs} />
}
