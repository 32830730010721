import React, { useState } from 'react'
import { IconButton, Text, Token } from '@revolut/ui-kit'
import {
  getTimeOffPolicyAssignmentsEmployees,
  getTimeOffPolicyAssignmentsEmployeesPolicies,
} from '@src/api/timeOff'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useTable } from '@src/components/TableV2/hooks'
import {
  timeOffAssignmentsActionColumn,
  timeOffAssignmentsCurrentBalanceColumn,
  timeOffAssignmentsEmployeeColumn,
  timeOffAssignmentsLocationColumn,
} from '@src/constants/columns/timeOff'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { TimeOffEmployeePolicyAssignmentsInterface } from '@src/interfaces/timeOff'
import { UnassignTimeOffPolicyPopup } from './UnassignPolicyPopup'

const getRow = (
  onDeleteAssignment: (id: number, rowIndex: number) => void,
): RowInterface<TimeOffEmployeePolicyAssignmentsInterface> => ({
  cells: [
    {
      ...timeOffAssignmentsEmployeeColumn,
      width: 250,
    },
    {
      ...timeOffAssignmentsLocationColumn,
      width: 250,
    },
    {
      ...timeOffAssignmentsCurrentBalanceColumn,
      width: 200,
    },
    {
      ...timeOffAssignmentsActionColumn,
      width: 100,
      insert: ({ data, parentIndexes }) => {
        if ('children' in data) {
          return <Text color={Token.color.greyTone20}>n/a</Text>
        }

        return (
          <IconButton
            color={Token.color.greyTone50}
            onClick={() => onDeleteAssignment(data.assignment_id, parentIndexes[0])}
            size={16}
            useIcon="Delete"
          />
        )
      },
    },
  ],
})

export const TimeOffPoliciesAssignments = () => {
  const [assignmentId, setAssignmentId] = useState<number | undefined>()
  const [employeeId, setEmployeeId] = useState<number | undefined>()
  const [popupOpen, setPopupOpen] = useState(false)

  const table = useTable({ getItems: getTimeOffPolicyAssignmentsEmployees })

  const onDeleteAssignment = (id: number, rowIndex: number) => {
    setAssignmentId(id)
    setEmployeeId(table.data[rowIndex].id)
    setPopupOpen(true)
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable
            {...table}
            emptyState={<EmptyTableRaw title="List of assignments will appear here" />}
            expandableType="chevron"
            name={TableNames.TimeOffPoliciesAssignments}
            row={getRow(onDeleteAssignment)}
            useFetchedChildren
            useWindowScroll
            fetchChildren={(_, id) => getTimeOffPolicyAssignmentsEmployeesPolicies(id)}
          />
        </Table.Widget.Table>
      </Table.Widget>
      <UnassignTimeOffPolicyPopup
        assignmentId={assignmentId}
        employeeId={employeeId}
        isOpen={popupOpen}
        onAfterUnassign={table.refresh}
        onClose={() => {
          setPopupOpen(false)
          setAssignmentId(undefined)
          setEmployeeId(undefined)
        }}
      />
    </>
  )
}
