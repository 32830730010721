import { DurationUnitType } from '@src/interfaces/interviewTool'

const DEFAULT_MIN_MINUTES = 10
const DEFAULT_MIN_HOURS = 1

export const getDurationPayload = (
  duration?: number,
  durationUnit?: DurationUnitType,
) => {
  if (duration === undefined) {
    return undefined
  }

  if (durationUnit === 'minutes' && duration < DEFAULT_MIN_MINUTES) {
    return DEFAULT_MIN_MINUTES
  }

  if (duration === 0) {
    return DEFAULT_MIN_HOURS
  }

  return duration
}
