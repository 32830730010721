import { RequestInterfaceNew, GetRequestInterface } from '@src/interfaces'
import {
  InterviewAvailabilityInterface,
  InterviewAvailabilityPreferencesInterface,
} from '@src/interfaces/interviewAvailability'
import { apiV2 } from '@src/api'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetchV2 } from '@src/utils/reactQuery'
import { FetchDataQueryInterface } from '@src/interfaces/data'

export const useFetchInterviewAvailability = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  useFetchV2<GetRequestInterface<InterviewAvailabilityInterface>>({
    url: API.INTERVIEWS_INTERVIEW_SLOTS,
    params: {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
  })

const INTERVIEWER = '/interviewer'
const INTERVIEW_AVAILABILITY_LIMITS = '/interviewsAvailabilityLimits'
const getInterviewAvailabilityPreferencesEndpoint = (employeeId: number | string) =>
  `${API.INTERVIEWS}${INTERVIEWER}/${employeeId}${INTERVIEW_AVAILABILITY_LIMITS}`

export const interviewAvailabilityPreferencesFormRequests = (
  employeeId: string | number,
): RequestInterfaceNew<InterviewAvailabilityPreferencesInterface> => {
  const endpoint = getInterviewAvailabilityPreferencesEndpoint(employeeId)
  return {
    get: async () => apiV2.get(endpoint),
    update: async data => apiV2.patch(endpoint, data),
    submit: async data => apiV2.post(endpoint, data),
  }
}

export const useInterviewerAvailabilityPreferences = (employeeId: string | number) =>
  useFetchV2<InterviewAvailabilityPreferencesInterface>({
    url: getInterviewAvailabilityPreferencesEndpoint(employeeId),
  })

const REFRESH_INTERVIEWER_SLOTS = '/refreshInterviewerSlots'

export const refreshInterviewSlots = (employeeId: string | number) => {
  return apiV2.post(
    `${API.INTERVIEWS_INTERVIEW_SLOTS}${REFRESH_INTERVIEWER_SLOTS}`,
    undefined,
    {
      params: {
        interviewer_id: employeeId,
      },
    },
  )
}
