import React from 'react'
import { Box, Button, Header, Input, InputGroup, Popup, Text } from '@revolut/ui-kit'
import {
  employeePolicyAssignmentRequest,
  unassignEmployeePolicyAssignment,
} from '@src/api/timeOff'
import { EmployeeTimeOffPolicyAssignmentInterface } from '@src/interfaces/timeOff'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

interface Props {
  assignmentId?: number
  employeeId?: number
  isOpen: boolean
  onAfterUnassign: VoidFunction
  onClose: VoidFunction
}

const UnassignEmployeeTimeOffPolicyForm = ({
  onAfterUnassign,
  onClose,
}: Pick<Props, 'onAfterUnassign' | 'onClose'>) => {
  const { values } = useLapeContext<EmployeeTimeOffPolicyAssignmentInterface>()

  const onUnassign = async () => {
    return unassignEmployeePolicyAssignment(
      values.id,
      values.employee.id,
      values.end_date || undefined,
      values.last_accrual_amount || undefined,
    )
  }

  return (
    <>
      <Header>
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Unassign policy</Header.Title>
      </Header>
      <Text mb="s-8">Please fill in the below details</Text>
      <InputGroup>
        <LapeDatePickerInput label="End date" name="end_date" required />
        {values.end_date ? (
          <>
            <InputGroup variant="horizontal">
              <Box flex={3}>
                <LapeNewInput
                  label="Last accrual amount"
                  name="last_accrual_amount"
                  required
                />
              </Box>
              <Box flex={1}>
                <Input disabled label="Unit" value={values.policy.unit?.name} />
              </Box>
            </InputGroup>

            <ActionWidget
              text="Based on policy type and its end date."
              title="Last accrual for this time-off policy needs to be manually calculated"
            />
          </>
        ) : null}
      </InputGroup>
      <Popup.Actions>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => {
            onAfterUnassign()
            onClose()
          }}
          onClick={onUnassign}
          disabled={!values.end_date}
          successText="Policy has been unassigned"
          useValidator
        >
          Submit
        </NewSaveButtonWithPopup>
      </Popup.Actions>
    </>
  )
}

export const UnassignTimeOffPolicyPopup = ({
  assignmentId,
  employeeId,
  isOpen,
  onAfterUnassign,
  onClose,
}: Props) => {
  return (
    <Popup onClose={onClose} open={isOpen}>
      <Form
        api={employeePolicyAssignmentRequest}
        disableDataCleanup
        disableLocalStorageCaching
        forceParams={{
          id: assignmentId ? String(assignmentId) : undefined,
          employeeId: employeeId ? String(employeeId) : undefined,
        }}
      >
        <UnassignEmployeeTimeOffPolicyForm
          onAfterUnassign={onAfterUnassign}
          onClose={onClose}
        />
      </Form>
    </Popup>
  )
}
