import React from 'react'
import { VStack, Widget } from '@revolut/ui-kit'
import { CycleOption } from '@src/interfaces/selectors'
import { FilterByInterface } from '@src/interfaces/data'
import { CycleFilterButton } from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { CYCLE_ID_QUERY_PARAM_KEY } from '@src/features/ReviewCycle/Analytics/PerformanceReviewsAnalytics'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { PerformanceScore } from '@src/features/ReviewCycle/Analytics/Overview/PerformanceScore'
import { ReviewStats } from '@src/features/ReviewCycle/Analytics/Overview/ReviewStats'

interface Props {
  cycleId: string | number | undefined
  cycleOptions: CycleOption[]
  handleFilterCycle: (filters: FilterByInterface) => void
}

const LEFT_COLUMN_MIN_WIDTH = 220
const LEFT_COLUMN_MAX_WIDTH = 340

export const AnalyticsOverview = ({
  cycleId,
  cycleOptions,
  handleFilterCycle,
}: Props) => {
  return (
    <Widget p="s-16">
      <VStack space="s-16">
        <CycleFilterButton
          selector={() => Promise.resolve({ options: cycleOptions })}
          onFilterChange={handleFilterCycle}
          columnName={CYCLE_ID_QUERY_PARAM_KEY}
          filter={[
            {
              columnName: CYCLE_ID_QUERY_PARAM_KEY,
              filters: cycleId ? [{ id: cycleId, name: `${cycleId}` }] : [],
            },
          ]}
        />
        {cycleId && (
          <TwoColumnsLayout
            left={
              <VStack space="s-16">
                <PerformanceScore cycleId={cycleId} height={380} />
                <ReviewStats cycleId={cycleId} />
              </VStack>
            }
            right={null}
            leftFlex={1}
            rightFlex={1}
            leftMinWidth={LEFT_COLUMN_MIN_WIDTH}
            leftMaxWidth={{
              sm: '100%',
              all: LEFT_COLUMN_MAX_WIDTH,
            }}
            overflow="auto"
          />
        )}
      </VStack>
    </Widget>
  )
}
