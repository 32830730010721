import React, { useMemo } from 'react'
import {
  GrowthPlanInterface,
  GrowthPlanPerformanceMetricInterface,
} from '@src/interfaces/growthPlans'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { getRow } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Skills/Row'

interface Props {
  data: GrowthPlanPerformanceMetricInterface[]
  growthPlan: GrowthPlanInterface
  isLoading: boolean
}

export const FunctionalSkillsTable = ({ data, growthPlan, isLoading }: Props) => {
  const row = useMemo(
    () =>
      getRow({
        currentSeniorityName: growthPlan.current_seniority.name,
        targetSeniorityName: growthPlan.target_seniority.name,
      }),
    [growthPlan.current_seniority.name, growthPlan.target_seniority.name],
  )

  return (
    <AdjustableTable<GrowthPlanPerformanceMetricInterface>
      name={TableNames.GrowthPlanFunctionalSkills}
      row={row}
      disabledFiltering
      disabledSorting
      data={data}
      count={data.length}
      loading={isLoading}
      emptyState={<EmptyTableRaw title="No Skills evaluated" imageSize={72} />}
    />
  )
}
