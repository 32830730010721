import { IdAndName } from '.'
import { ImportSessionState } from '@src/interfaces/bulkDataImport'

export interface SlackPreferencesInterface {
  id: number
  enabled: boolean
}

export interface SlackBotInterface {
  id: number
  name: string
  description: string
  scopes: string
  access_token: string
}

export interface ClickupIntegrationInterface {
  enabled: boolean
  is_external_app: boolean
  client_id: string
  redirect_uri: string
  id: number
  server: string
  client_secret?: string
}

export interface OktaIntegrationInterface {
  id: number
  enabled: boolean
  metadata_url: string
}

export interface TableauIntegrationInterface {
  enabled: boolean
  id: number
  creation_date_time: string
  server: string
  update_date_time: string
  owner: { id: number }
  client_id?: string
  server_id?: string
  secret_value: string
}

export interface SalesforceIntergrationInterface {
  id: number
  enabled: boolean
  server: string
}

export interface JiraPreferencesInterface {
  id: number
  enabled: boolean
  create_hr_tickets_enabled: boolean
  server: string
  username: string
  api_key: string
  hr_project_key: string
  hrsd_project_key: string
  connection_type?: IdAndName<'jira'> // used on FE only so far
  issue_key?: string // used on FE only so far
}

export interface LookerIntegrationInterface {
  enabled: boolean
  id: number
  creation_date_time: string
  server: string
  update_date_time: string
  method: IdAndName<'basic' | 'oauth'>
}

export interface JiraConnectionTestInterface {
  issue_key: string
  api_key?: string
  server?: string
  username?: string
}

export interface JiraConnectionTestResult {
  success: boolean
  detail: string
}

export interface GoogleCalendarPreferencesInterface {
  id: number
  enabled: boolean
  credentials: string
}

export interface DocusignPreferencesInterface {
  id: number
  enabled: boolean
  base_path: string
  oauth_host_name: string
  client_id: string
  user_id: string
  account_id: string
  private_key: string
  connect_enabled: boolean
  connect_username: string
  connect_password: string
}

export interface DocusignConsentInterface {
  url: string | null
}

export interface DocusignUsersInterface {
  id: number
  creation_date_time: string
  email: string
  permission_profile_id: string
  status: IdAndName<'active' | 'closed'>
  user_id: string
  update_date_time: string
}

export const MergeIntegrations = ['bamboohr', 'hibob', 'personio'] as const
export type MergeIntegration = typeof MergeIntegrations[number]

export interface MergeConnectionInterface {
  id: string
  integration_type: MergeIntegration
  sync_all_statuses: boolean | null
  last_sync: {
    state: ImportSessionState
    update_date_time: string
    id: number
    auto_apply: boolean
  } | null
  available_fields: {
    id: string
    name: string
    required: boolean
  }[]
  selected_fields: IdAndName<string>[]
}

export interface MergeConnectionRequest {
  integration_type: MergeIntegration
  sync_all_statuses?: boolean
}

export interface MergeConnectionResponse {
  id: string
  token: string
}
