import React from 'react'
import { Props } from 'recharts/types/component/DefaultLegendContent'
import { Box, Flex, Text, Token } from '@revolut/ui-kit'
import { DataKeysType } from './CycleStatsChart'

interface ChartLegendProps {
  dataKeys: DataKeysType
  heading: string
  props: Props
}

export const CycleStatsChartLegend = ({ dataKeys, heading, props }: ChartLegendProps) => {
  const { payload } = props

  return (
    <Flex flexWrap="wrap" justifyContent="space-between" mb="s-8" rowGap="s-2">
      <Text variant="heading3">{heading}</Text>
      <Flex columnGap="s-12" flexWrap="wrap" justifyContent="end" rowGap="s-2">
        {payload?.map(entry => {
          const key = dataKeys.find(
            dataKey => String(dataKey.value) === String(entry.dataKey),
          )
          if (!key) {
            return null
          }
          return (
            <Flex key={String(key.value)} alignItems="center" gap="s-4">
              <Box
                bg={entry.color}
                border={`1px solid ${Token.color.greyTone10}`}
                borderRadius={Token.radius.r2}
                height="s-12"
                width="s-12"
              />
              <Text color={Token.color.greyTone50} fontSize="small" use="div">
                {key.label}
              </Text>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}
