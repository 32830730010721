import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Flex, Text, Token, Widget } from '@revolut/ui-kit'

import {
  getSurveyAdminResultCommentsTableRequests,
  useGetSurveyResultsItemDetails,
  useGetSurveyResultsItemTimeSeries,
} from '@src/api/engagement'
import Loader from '@src/components/CommonSC/Loader'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { useTable } from '@src/components/TableV2/hooks'
import { ROUTES } from '@src/constants/routes'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { LineChart } from '@src/pages/Forms/QueryForm/components/Charts/LineChart'
import { formatDate } from '@src/utils/format'
import { toIdAndName } from '@src/utils/toIdAndName'
import { isHubAppPath } from '../../../helpers/isHubApp'
import { ItemsToAnalyse } from '../ResultsTab/common'
import { Comments } from './Comments'
import { PieChartWidget } from './PieChartWidget'
import { NoDataPlaceholder } from './common'

export const EngagementSurveyResultsItemDetails = () => {
  const params = useParams<{ id: string; type: ItemsToAnalyse; itemId?: string }>()
  const isHubApp = isHubAppPath()

  const { data: itemDetailsData, isLoading: isLoadingItemDetailsData } =
    useGetSurveyResultsItemDetails(params.id, params.type, params.itemId)

  const { data: timeSeriesData = [], isLoading: isLoadingTimeSeriesData } =
    useGetSurveyResultsItemTimeSeries({
      surveyId: params.id,
      itemsToAnalyse: params.type,
      itemId: params.itemId,
    })

  const timeSeriesContainerHeight = 320
  const timeSeriesYPaddingPx = 16
  const timeSeriesTitleHeight = 34
  const containerHeight =
    timeSeriesContainerHeight + timeSeriesTitleHeight + timeSeriesYPaddingPx * 2

  const lineChartProps = useMemo(
    () => ({
      dataKeys: [
        {
          id: 0,
          label: 'Score value',
          value: 'value0' as const,
          color: Token.colorChannel.blue,
        },
      ],
      data: timeSeriesData
        .filter(datum =>
          Boolean(datum.average_score != null && datum.from_date && datum.to_date),
        )
        .map(datum => ({
          id: formatDate(datum.to_date),
          label: formatDate(datum.to_date),
          value0: datum.average_score,
        })),
    }),
    [timeSeriesData],
  )

  const commentsTable = useTable(getSurveyAdminResultCommentsTableRequests(), [
    {
      columnName: params.type === 'questions' ? 'question__id' : 'driver__id',
      filters: params.itemId ? [toIdAndName(params.itemId)] : [],
    },
  ])

  return (
    <PageWrapper>
      <PageHeader
        title={
          itemDetailsData ? (
            <PageHeader.Title
              title={itemDetailsData.name}
              useIcon={params.type === 'questions' ? 'Chat' : 'Folder'}
            />
          ) : undefined
        }
        backUrl={ROUTES[isHubApp ? 'APPS' : 'PERFORMANCE'].ENGAGEMENT.DASHBOARD}
        isLoading={isLoadingItemDetailsData}
      />

      <PageBody maxWidth={Token.breakpoint.xxl}>
        <TwoColumnsLayout
          left={
            <Box width="100%" height={containerHeight}>
              <PieChartWidget
                data={itemDetailsData}
                isLoading={isLoadingItemDetailsData}
                containerHeight={containerHeight}
                commentsCount={commentsTable.count}
                isCommentsLoading={commentsTable.loading}
              />
            </Box>
          }
          right={
            <Widget px="s-12" py={timeSeriesYPaddingPx} height="100%">
              <Box height={timeSeriesTitleHeight}>
                <Text use="div" variant="emphasis1" mb="s-16">
                  Average score over time
                </Text>
              </Box>
              {isLoadingTimeSeriesData ? (
                <Flex
                  width="100%"
                  height={timeSeriesContainerHeight}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Loader />
                </Flex>
              ) : timeSeriesData.length ? (
                <Box width="100%" height={timeSeriesContainerHeight}>
                  <LineChart {...lineChartProps} />
                </Box>
              ) : (
                <NoDataPlaceholder />
              )}
            </Widget>
          }
        />
        <Box mt="s-24">
          <Comments table={commentsTable} />
        </Box>
      </PageBody>
    </PageWrapper>
  )
}
