import React, { useEffect } from 'react'
import { connect } from 'lape'
import format from 'date-fns/format'

import { BottomSheet, Button, Header, InputGroup, Portal } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import { getEngagementRoundFormRequest } from '@src/api/engagement'
import LapeNewDatePicker from '@components/Inputs/LapeFields/LapeNewDatePicker'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EngagementSurveyRoundInterface } from '@src/interfaces/engagement'
import { addDays } from 'date-fns'
import { RoundPopupVariant } from '../types'

const SurveyRoundFormContent = ({
  isOpen,
  onClose,
  refetch,
  type,
}: Omit<Props, 'surveyId' | 'roundId'>) => {
  const { values, submit, initialValues } = useLapeContext<
    EngagementSurveyRoundInterface & { temp_date: string; temp_time: string }
  >()

  useEffect(() => {
    if (initialValues) {
      // at init we need to setup correct data to be displayed in the time input from currently saved data
      // that is returned in ISO format
      values.temp_time = (
        (type === 'reschedule' ? initialValues.sent_on : initialValues.closing_date) ||
        '00:00'
      )
        ?.split('T')?.[1]
        ?.substring(0, 5)
    }
  }, [initialValues])

  useEffect(() => {
    if (values.temp_date) {
      const newDate = format(new Date(values.temp_date), 'yyyy-MM-dd')
      if (type === 'reschedule') {
        // we have single field for 'sent_on' and 'closing_date', yet we do not have a component that would be responsible for setting date and time in the single input
        // so we need to replace just the date value in the current values
        values.sent_on = (values.sent_on || new Date().toISOString()).replace(
          /\d\d\d\d-\d\d-\d\dT/,
          `${newDate}T`,
        )
        values.closing_date = addDays(
          new Date(values.sent_on),
          values.response_deadline_days,
        ).toISOString()
      } else {
        values.closing_date = (values.closing_date || new Date().toISOString()).replace(
          /\d\d\d\d-\d\d-\d\dT/,
          `${newDate}T`,
        )
      }
    }
  }, [values.temp_date])

  useEffect(() => {
    if (values.temp_time) {
      if (type === 'reschedule') {
        // we have single field for 'sent_on' and 'closing_date', yet we do not have a component that would be responsible for setting date and time in the single input
        // so we need to replace just the time value in the current values
        values.sent_on = (values.sent_on || new Date().toISOString()).replace(
          /T\d\d:\d\d/,
          `T${values.temp_time}`,
        )
      } else {
        values.closing_date = (values.closing_date || new Date().toISOString()).replace(
          /T\d\d:\d\d/,
          `T${values.temp_time}`,
        )
      }
    }
  }, [values.temp_time])

  return (
    <BottomSheet open={isOpen} onClose={onClose}>
      <Header>
        <Header.Title>
          {type === 'reschedule'
            ? 'When do you want to start the survey?'
            : 'When do you want your survey to end?'}
        </Header.Title>
      </Header>
      <InputGroup>
        <LapeNewDatePicker
          name="temp_date"
          required
          label="Date"
          value={
            (type === 'reschedule' ? values.sent_on : values.closing_date) || undefined
          }
        />
        <LapeNewTimeInput
          name="temp_time"
          required
          formatValueToDateTime={false}
          label="Time"
        />
      </InputGroup>
      <BottomSheet.Actions horizontal>
        <Button
          variant="secondary"
          type="button"
          onClick={e => {
            e.preventDefault()
            onClose()
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          elevated
          onClick={async () => {
            await submit()
            await refetch()
            onClose()
          }}
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}

type Props = {
  surveyId: number
  roundId: number
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  type?: RoundPopupVariant
}
export const SurveyRoundForm = connect((props: Props) =>
  props.isOpen ? (
    <Portal>
      <Form api={getEngagementRoundFormRequest(props.surveyId, props.roundId)}>
        <SurveyRoundFormContent {...props} />
      </Form>
    </Portal>
  ) : null,
)
