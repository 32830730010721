import React from 'react'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { SwitchStageAction } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/SwitchStageAction'

interface Props {
  cycle: ReviewCyclesInterface
  stages: ReviewCycleStage[]
  stage: ReviewCycleStage
  canSwitchNext: boolean
  canSwitchPrevious: boolean
}

export const PublishActions = ({
  cycle,
  stages,
  stage,
  canSwitchNext,
  canSwitchPrevious,
}: Props) => {
  if (!canSwitchNext && !canSwitchPrevious) {
    return null
  }

  return (
    <>
      {canSwitchNext && (
        <SwitchStageAction
          cycle={cycle}
          stages={stages}
          stage={stage}
          direction="forward"
        />
      )}
      {canSwitchPrevious && (
        <SwitchStageAction
          cycle={cycle}
          stages={stages}
          stage={stage}
          direction="backward"
        />
      )}
    </>
  )
}
