import { useGetDeliverablesCategories } from '@src/api/performanceSettings'
import { getGradesWithExpectations } from './Cards/utils'
import { DeliverableOptions } from '@src/interfaces/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'

export const useGetSegmentedDeliveryGrades = () => {
  const { data: { results: deliverablesCategories } = {} } =
    useGetDeliverablesCategories()

  const getSegmentedDeliverablesGrades = (
    key: string,
  ): ReturnType<typeof getGradesWithExpectations> => {
    const ratingData = deliverablesCategories?.find(
      category => category.title === key,
    )?.deliverables_ratings

    const mappedRating: Parameters<typeof getGradesWithExpectations>[0] =
      ratingData?.map(rating => ({
        key: rating.rating,
        text: PerformanceRatingTitle[rating.rating],
        description: [rating.description],
        tooltip: rating.description,
      })) || []

    return getGradesWithExpectations(mappedRating, DeliverableOptions.INTERMEDIATE)
  }

  return getSegmentedDeliverablesGrades
}
