import React from 'react'
import { createChain, Ellipsis, HStack, Icon, Text, Token } from '@revolut/ui-kit'
import { formatSnakeCase } from '@src/utils/string'
import { getIconProps, isNull } from './utils'

const MAX_PREVIEW_WIDTH = 150

interface Props {
  from: string | null
  to: string | null
}

export const ValuesDiff = ({ from, to }: Props) => {
  const arrowChain = createChain(<Icon name="ArrowThinRight" size={12} />)
  const isNullFrom = isNull(from)
  const isNullTo = isNull(to)

  return (
    <HStack align="center" space="s-4">
      <Icon {...getIconProps(from)} size={15} />
      {arrowChain(
        !isNullFrom ? (
          <Ellipsis maxWidth={MAX_PREVIEW_WIDTH} tooltip="auto">
            <Text variant="emphasis1">{formatSnakeCase(from)}</Text>
          </Ellipsis>
        ) : undefined,
        <Ellipsis
          maxWidth={isNullFrom ? MAX_PREVIEW_WIDTH * 2 : MAX_PREVIEW_WIDTH}
          tooltip="auto"
        >
          <Text color={isNullTo ? Token.color.greyTone50 : undefined} variant="emphasis1">
            {isNullTo ? 'n/a' : formatSnakeCase(to)}
          </Text>
        </Ellipsis>,
      )}
    </HStack>
  )
}
