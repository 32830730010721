import React from 'react'

import { CellInsertParams } from '@src/interfaces/data'
import { TalentUIInterface } from '../types'
import GradeLockIcon from '@src/features/Calibration/GradeLockIcon'
import {
  Icon,
  TextSkeleton,
  Token,
  Text,
  Grid,
  Ellipsis,
  VStack,
  Flex,
} from '@revolut/ui-kit'
import EmployeePerformanceChart from '@src/components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import { getEmployeePerformanceGraph } from '@src/api/employees'
import { getGradeLabel } from '../utils'
import { getInverseColorsAndGrade } from '@src/utils/grades'
import { IdAndName } from '@src/interfaces'
import { FinalGrade } from '@src/interfaces/performance'
import Tooltip from '@src/components/Tooltip/Tooltip'

interface Props {
  data: CellInsertParams<TalentUIInterface>['data']
  viewHistory: boolean
  gradeOptions: IdAndName<FinalGrade>[]
}

export const FinalGradeCell = ({ data, viewHistory, gradeOptions }: Props) => {
  return (
    <Grid
      columns={`1fr repeat(${viewHistory ? 2 : 1}, auto)`}
      gap="s-8"
      data-testid={`final_grade__${data.id}`}
    >
      {data.self_employee_locked ? (
        <GradeLockIcon />
      ) : data.isPendingGrade ? (
        <TextSkeleton width={100} />
      ) : (
        <>
          <Ellipsis>
            <Text
              color={getInverseColorsAndGrade(data.display_grade)?.color || undefined}
            >
              {getGradeLabel(gradeOptions, data.display_grade.id) || '-'}
            </Text>
          </Ellipsis>
          <Tooltip
            placement="left"
            backgroundColor={Token.color.popoverBackground}
            body={
              <VStack p="s-12" width={275} color={Token.color.foreground} gap="s-2">
                <Text variant="emphasis2">Performance</Text>
                <Flex justifyContent="space-between">
                  <Text>Functional Manager</Text>
                  <Text
                    color={
                      getInverseColorsAndGrade(data.functional_manager_grade_suggestion)
                        ?.color || undefined
                    }
                  >
                    {getGradeLabel(
                      gradeOptions,
                      data.functional_manager_grade_suggestion?.id,
                    ) || 'n/a'}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text>Line Manager</Text>
                  <Text
                    color={
                      getInverseColorsAndGrade(data.functional_manager_grade_suggestion)
                        ?.color || undefined
                    }
                  >
                    {getGradeLabel(
                      gradeOptions,
                      data.line_manager_grade_suggestion?.id,
                    ) || 'n/a'}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text>Head of Department</Text>
                  <Text
                    color={
                      getInverseColorsAndGrade(data.department_owner_grade_suggestion)
                        ?.color || undefined
                    }
                  >
                    {getGradeLabel(
                      gradeOptions,
                      data.department_owner_grade_suggestion?.id,
                    ) || 'n/a'}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text>Head of Function</Text>
                  <Text
                    color={
                      getInverseColorsAndGrade(data.function_owner_grade_suggestion)
                        ?.color || undefined
                    }
                  >
                    {getGradeLabel(
                      gradeOptions,
                      data.function_owner_grade_suggestion?.id,
                    ) || 'n/a'}
                  </Text>
                </Flex>
                <Text variant="emphasis2">How is this calculated?</Text>
                <Text color={Token.color.greyTone50}>
                  The grade calculates as average of Line manager grade suggestion,
                  Functional manager grade suggestion and Calculated grade. Suggestions
                  from the Heads of Functions are not final.
                </Text>
              </VStack>
            }
          >
            <Icon name="InfoOutline" size={16} color={Token.color.greyTone20} />
          </Tooltip>
          {viewHistory && (
            <EmployeePerformanceChart
              id={data.employee.id}
              fetchKpi={getEmployeePerformanceGraph}
              vertical="left"
            >
              <Icon name="BarChart" size={16} color={Token.color.greyTone20} />
            </EmployeePerformanceChart>
          )}
        </>
      )}
    </Grid>
  )
}
