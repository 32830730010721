import React from 'react'
import { ActionButton, opacity, Token } from '@revolut/ui-kit'

import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { getEmployeeAccessRequests } from '@src/api/accessRequests'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'
import {
  accessRequestAccessType,
  accessRequestApproverColumn,
  accessRequestDatabaseColumn,
  accessRequestDateFromColumn,
  accessRequestDateToColumn,
  accessRequestPermissionColumn,
  accessRequestReasonColumn,
  accessRequestSourceColumn,
  accessRequestStatusColumn,
  accessRequestTool,
} from '@src/constants/columns/accessRequest'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { FieldOptions, Statuses } from '@src/interfaces'
import { AccessRequestInterface, AccessTypes } from '@src/interfaces/accessRequest'
import { FetchDataQueryInterface, RowInterface } from '@src/interfaces/data'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

interface Props {
  data: {
    id?: any
    full_name: string
    field_options: FieldOptions
  }
}

const ROW: RowInterface<AccessRequestInterface> = {
  highlight: data => {
    if (data.status === Statuses.pending) {
      return Token.color.red_20
    }

    if (data.status === Statuses.expired) {
      return opacity(Token.colorChannel.deepGrey, 0.2)
    }

    return ''
  },
  linkToForm: ({ access_type, access_request_id, employee, team }) => {
    switch (access_type.id) {
      case AccessTypes.METABASE_USER:
        return navigateTo(
          pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.GENERAL, {
            id: access_request_id,
            employeeId: employee.id,
          }),
        )
      case AccessTypes.METABASE_TEAM:
        return navigateTo(
          pathToUrl(ROUTES.FORMS.TEAM_ACCESS_REQUESTS.GENERAL, {
            id: access_request_id,
            teamId: team.id,
          }),
        )
      case AccessTypes.LOOKER_MODEL:
        return navigateTo(
          pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_MODEL, {
            id: access_request_id,
            employeeId: employee.id,
          }),
        )
      case AccessTypes.LOOKER_FOLDER:
        return navigateTo(
          pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_FOLDER, {
            id: access_request_id,
            employeeId: employee.id,
          }),
        )
      default:
        return '/'
    }
  },
  cells: [
    {
      ...accessRequestTool,
      width: 100,
    },
    {
      ...accessRequestAccessType,
      width: 150,
    },
    {
      ...accessRequestDatabaseColumn,
      width: 150,
    },
    {
      ...accessRequestApproverColumn,
      width: 150,
    },
    {
      ...accessRequestSourceColumn,
      width: 150,
    },
    {
      ...accessRequestPermissionColumn,
      width: 100,
    },
    {
      ...accessRequestDateFromColumn,
      width: 100,
    },
    {
      ...accessRequestDateToColumn,
      width: 100,
    },
    {
      ...accessRequestReasonColumn,
      width: 240,
    },
    {
      ...accessRequestStatusColumn,
      width: 200,
    },
  ],
}

export const DataAccess = ({ data }: Props) => {
  const initialFilter = [
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]
  const table = useTable<AccessRequestInterface>(
    {
      getItems: (requestData: FetchDataQueryInterface) =>
        getEmployeeAccessRequests(requestData, data.id),
    },
    initialFilter,
  )

  const canCreateRequests = data?.field_options?.permissions?.includes(
    PermissionTypes.CreateAccessRequests,
  )

  const handleNewRow = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.ACCESS_REQUESTS.ROUTER, { employeeId: `${data.id}` }),
      {
        initialValues: { employee: { id: data.id, name: data.full_name } },
      },
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Filters>
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="review_cycle__offset"
          filter={table.filterBy}
        />
      </Table.Widget.Filters>
      {canCreateRequests && (
        <Table.Widget.Actions>
          <ActionButton onClick={handleNewRow} useIcon="Plus">
            New Data Access Request
          </ActionButton>
        </Table.Widget.Actions>
      )}
      <Table.Widget.Table>
        <AdjustableTable<AccessRequestInterface>
          name={TableNames.EmployeeAccessRequests}
          useWindowScroll
          dataType="Access Request"
          row={ROW}
          {...table}
          emptyState={<EmptyTableRaw title="All access requests will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
