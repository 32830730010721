import { VStack } from '@revolut/ui-kit'
import React from 'react'
import SectionTitle from '../components/SectionTitle'
import { ReviewSettings } from '@src/pages/Settings/Performance/ReviewSettings/ReviewSettings'

export const PerformanceReviewers = () => {
  return (
    <VStack>
      <SectionTitle
        title="Who should be invited to provide review feedback?"
        subtitle="We recommend 360 reviews, however you can disable some review types"
      />
      <ReviewSettings />
    </VStack>
  )
}
