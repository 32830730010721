import { PermissionTypes } from '@src/store/auth/types'

export const getCalibrationPermissions = (permissions: PermissionTypes[]) => {
  const canDelegate = permissions.includes(PermissionTypes.CanDelegateCalibrators)

  const canChangeGradeSuggestion = permissions.includes(
    PermissionTypes.ChangeGradeSuggestion,
  )

  const canChangeTalentRank = permissions.includes(PermissionTypes.ChangeTalentRank)

  return { canDelegate, canChangeGradeSuggestion, canChangeTalentRank }
}
