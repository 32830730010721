import { Token } from '@revolut/ui-kit'

export enum PerformanceItemDataKeys {
  NIPS = 'NIPS',
  ALL = 'ALL',
}

export interface PerformanceItemData {
  name: PerformanceItemDataKeys
  value: number
  color: typeof Token.colorChannel[keyof typeof Token.colorChannel]
}
