import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { TicketsView } from '@src/pages/Forms/ProbationOverview/ProbationGoals/TicketsView'
import { useGetProbationGoals } from '@src/api/probationReview'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

interface Props {
  employee: EmployeeInterface
  reviewCycle: PerformanceSelector
}

export const JiraGoals = ({ employee, reviewCycle }: Props) => {
  const { data: probationGoals, isLoading } = useGetProbationGoals(
    employee.id,
    String(reviewCycle.id),
  )

  const user = useSelector(selectUser)
  const isLineManager = user.id === employee.line_manager.id

  const onAddGoalRedirect = () => {
    navigateTo(
      `${pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS, {
        employeeId: employee.id,
        cycleId: reviewCycle.id,
      })}`,
    )
  }

  return (
    <TicketsView
      type={reviewCycle.category}
      onAddGoalRedirect={onAddGoalRedirect}
      tickets={probationGoals?.results ?? []}
      isLoading={isLoading}
      isLineManager={isLineManager}
    />
  )
}
