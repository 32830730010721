import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  SkillInterface,
  SkillSpecialisationInterface,
  SkillsTalentInterface,
  SkillType,
} from '@src/interfaces/skills'
import { Tag, Token, Text, HStack } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { competencyToColor } from '@src/utils/competency'
import { SkillLevels } from '@src/interfaces/roles'
import { ratingToColor } from '@src/utils/performance'
import Tooltip from '@src/components/Tooltip/Tooltip'
import Table from '@src/components/TableV2/Table'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'
import { SKILLS_DEFAULT_ICON } from '@src/constants/common'

export const skillGenericNameColumn: ColumnInterface<SkillInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.skillset,
  title: 'Skill',
  insert: ({ data }) => (
    <Table.EntityCell
      useIcon={data.icon || SKILLS_DEFAULT_ICON}
      avatar={data.avatar}
      url={getLocationDescriptor(
        pathToUrl(
          data.status === Statuses.draft
            ? ROUTES.FORMS.SKILL.GENERAL
            : ROUTES.FORMS.SKILL.PREVIEW,
          { id: data.id },
        ),
      )}
    >
      {data.status === Statuses.draft && (
        <Text color={Token.color.greyTone50} pr="s-6">
          [Draft]
        </Text>
      )}
      {data.name}
    </Table.EntityCell>
  ),
}

export const skillFunctionColumn: ColumnInterface<SkillInterface> = {
  type: CellTypes.text,
  idPoint: 'function.id',
  dataPoint: 'function.name',
  sortKey: 'function__name',
  filterKey: 'function__id',
  selectorsKey: selectorKeys.functions,
  title: 'Function',
}

export const skillCategoryColumn: ColumnInterface<SkillInterface> = {
  type: CellTypes.insert,
  idPoint: 'skill_type',
  dataPoint: 'skill_type',
  sortKey: 'skill_type',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Category',
  insert: ({ data }) => {
    switch (data.skill_type) {
      case SkillType.Functional:
        return (
          <Tag variant="faded" color="foreground" bg="grey-tone-8">
            Functional skill
          </Tag>
        )
      case SkillType.Executive:
        return <Tag variant="faded">Company skill</Tag>
      case SkillType.Culture:
        return (
          <Tag variant="faded" color="foreground" bg="grey-tone-8">
            Cultural value
          </Tag>
        )
      default:
        return '-'
    }
  },
}

export const skillSpecialisationsCountColumn: ColumnInterface<SkillInterface> = {
  type: CellTypes.text,
  textAlign: 'right',
  idPoint: 'specialisations_count',
  dataPoint: 'specialisations_count',
  sortKey: 'specialisations_count',
  filterKey: 'specialisations_count',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Roles (specialisations)',
}

export const skillRolesColumn: ColumnInterface<SkillInterface> = {
  type: CellTypes.insert,
  idPoint: 'role_skills',
  dataPoint: 'role_skills',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Roles using this skill',
  insert: ({ data }) => {
    switch (true) {
      case data.role_skills.length > 1:
        return (
          <HStack space="s-4">
            <Text>{data.role_skills[0].name}</Text>
            <Tooltip
              placement="top"
              text={data.role_skills
                .slice(1)
                .map(({ name }) => name)
                .join(', ')}
            >
              <Text color={Token.color.blue}>+{data.role_skills.length - 1}</Text>
            </Tooltip>
          </HStack>
        )
      case data.role_skills.length === 1:
        return data.role_skills[0].name
      default:
        return '-'
    }
  },
}

export const skillTalentCountColumn: ColumnInterface<SkillInterface> = {
  type: CellTypes.text,
  textAlign: 'right',
  idPoint: 'talent_count',
  dataPoint: 'talent_count',
  sortKey: 'talent_count',
  filterKey: 'talent_count',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.range,
  title: 'Talent',
}

const getSkillStatusVariant = (status: SkillInterface['status']): ColorTagVariant => {
  switch (status) {
    case Statuses.approved:
      return 'success'
    case Statuses.pending:
    case Statuses.pending_reapproval:
      return 'warning'
    case Statuses.draft:
      return 'neutral'
    case Statuses.archived:
      return 'outlined'
    case Statuses.expired:
    case Statuses.rejected:
      return 'danger'
    default:
      return 'default'
  }
}

export const skillStatusColumn: ColumnInterface<SkillInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status_display',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.skillsets_status,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={String(data.status)}
      variant={getSkillStatusVariant(data.status)}
    />
  ),
}

export const skillValidUntilColumn: ColumnInterface<SkillInterface> = {
  type: CellTypes.date,
  idPoint: 'valid_until',
  dataPoint: 'valid_until',
  sortKey: 'valid_until',
  filterKey: 'valid_until',
  selectorsKey: selectorKeys.none,
  title: 'Valid until',
  colors: data => {
    switch (data.status) {
      case Statuses.expired:
        return Token.color.danger
      case Statuses.pending_reapproval:
        return Token.color.warning
      default:
        return 'inherit'
    }
  },
}

export const skillSpecialisationNameColumn: ColumnInterface<SkillSpecialisationInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.approved_specialisations,
    title: 'Role (Specialisation)',
  }

export const skillSpecialisationHeadcountColumn: ColumnInterface<SkillSpecialisationInterface> =
  {
    type: CellTypes.text,
    idPoint: 'headcount',
    dataPoint: 'headcount',
    sortKey: 'headcount',
    filterKey: 'headcount',
    filterType: FilterType.range,
    selectorsKey: selectorKeys.none,
    title: 'Headcount',
    colors: data => {
      if (data.headcount === 0) {
        return Token.color.danger
      }
      return 'inherit'
    },
  }

export const skillSpecialisationFunctionColumn: ColumnInterface<SkillSpecialisationInterface> =
  {
    type: CellTypes.text,
    idPoint: 'function__id',
    dataPoint: 'function__name',
    sortKey: 'role__function__name',
    filterKey: 'role__function__id',
    selectorsKey: selectorKeys.functions,
    title: 'Function',
    dynamicHyperlinks: data =>
      pathToUrl(ROUTES.FORMS.FUNCTION.SKILLS, { id: data.function?.id }),
  }

export const getSkillCompetencyColumn = (
  seniority: SeniorityInterface,
): ColumnInterface<SkillSpecialisationInterface> => ({
  type: CellTypes.text,
  idPoint: `competency__${seniority.name}`,
  dataPoint: `competency__${seniority.name}`,
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: seniority.name,
  background: data => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const competency: SkillLevels | undefined = data.competency[seniority.name]
    if (!competency) {
      return 'inherit'
    }
    return competencyToColor(competency) || 'inherit'
  },
})

export const skillTalentEmployeeColumn: ColumnInterface<SkillsTalentInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Employee',
  insert: ({ data }) => <Table.EmployeeCell employee={data.employee} />,
}

export const skillTalentTeamColumn: ColumnInterface<SkillsTalentInterface> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const skillTalentRoleColumn: ColumnInterface<SkillsTalentInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.approved_specialisations,
  title: 'Role (Specialisation)',
}

export const skillTalentSeniorityColumn: ColumnInterface<SkillsTalentInterface> = {
  type: CellTypes.insert,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__level',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => <Table.SeniorityCell seniority={data.seniority.name} />,
}

export const skillTalentDepartmentColumn: ColumnInterface<SkillsTalentInterface> = {
  type: CellTypes.text,
  idPoint: 'department.id',
  dataPoint: 'department.name',
  sortKey: 'department__name',
  filterKey: 'department__id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const skillTalentFunctionColumn: ColumnInterface<SkillsTalentInterface> = {
  type: CellTypes.text,
  idPoint: 'function.id',
  dataPoint: 'function.name',
  sortKey: 'function__name',
  filterKey: 'function__id',
  selectorsKey: selectorKeys.functions,
  title: 'Function',
}

export const skillTalentLatestRatingColumn: ColumnInterface<SkillsTalentInterface> = {
  type: CellTypes.text,
  idPoint: 'rating',
  dataPoint: 'rating',
  sortKey: 'rating_score',
  filterKey: 'rating',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'Latest rating',
  background: data => {
    if (!data.rating) {
      return 'inherit'
    }
    return ratingToColor(data.rating) || 'inherit'
  },
}

export const skillTalentExpectedRatingColumn: ColumnInterface<SkillsTalentInterface> = {
  type: CellTypes.text,
  idPoint: 'expected_rating',
  dataPoint: 'expected_rating',
  sortKey: 'expected_rating_score',
  filterKey: 'expected_rating',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'Expectation',
  background: data => {
    if (!data.expected_rating) {
      return 'inherit'
    }
    return ratingToColor(data.expected_rating) || 'inherit'
  },
}
