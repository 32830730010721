import React, { forwardRef, useState } from 'react'
import {
  Circle,
  Flex,
  Icon,
  Text,
  Token,
  TransitionCollapse,
  VStack,
  IconName,
} from '@revolut/ui-kit'
import { getPercentColor } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'

interface CollapsableTableWrapperProps {
  children: React.ReactNode
  headerTitle: string
  headerStat?: number | null
  icon?: IconName
  testId?: string
  readOnly?: boolean
  headerStatColor?: string
}

export const CollapsableTableWrapper = forwardRef<
  HTMLDivElement,
  CollapsableTableWrapperProps
>(
  (
    { children, headerTitle, headerStat, icon, testId, readOnly, headerStatColor },
    ref,
  ) => {
    const [isCollapsed, setIsCollapsed] = useState(!!readOnly || headerTitle === 'Goals')

    const onCollapse = () => {
      if (readOnly) {
        return
      }
      setIsCollapsed(!isCollapsed)
    }

    return (
      <VStack
        bg={Token.color.grey50_10}
        data-testid={testId || `card-table-${headerTitle}`}
        style={{ borderRadius: Token.radius.r16 }}
        ref={ref}
      >
        <Flex
          style={{ cursor: readOnly ? '' : 'pointer' }}
          onClick={onCollapse}
          padding="s-16"
          justifyContent="space-between"
          alignItems="center"
          data-testid="collapsable-table-wrapper"
        >
          <Flex gap="s-16" alignItems="center">
            {!readOnly && (
              <Icon
                color={Token.color.greyTone50}
                name={isCollapsed ? 'ChevronDown' : 'ChevronRight'}
                size={24}
              />
            )}
            {icon && (
              <Circle size={40} bg={Token.color.accent}>
                <Icon name={icon} color={Token.color.onAccent} size={24} />
              </Circle>
            )}

            <Text variant="emphasis1" color={Token.color.foreground}>
              {headerTitle}
            </Text>
          </Flex>
          <Text color={headerStatColor ?? getPercentColor((headerStat || 0) * 100)}>
            {formatPercentage(headerStat === undefined ? null : headerStat)}
          </Text>
        </Flex>
        <TransitionCollapse in={isCollapsed}>
          <Flex style={{ position: 'relative', fontWeight: 400 }} flex="1 0">
            {children}
          </Flex>
        </TransitionCollapse>
      </VStack>
    )
  },
)
