import React, { useMemo, useState } from 'react'
import { FilterButton, MoreBar, Text, Token } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useIsNewTable, useTableReturnType } from '@components/TableV2/hooks'
import Tooltip from '@components/Tooltip/Tooltip'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'
import {
  riskAssigneeColumn,
  riskDueDateColumn,
  riskJiraColumn,
  riskLastBreachColumn,
  riskLevelIndicatorsColumn,
  riskLimit1Column,
  riskLimit2Column,
  riskLimit3Column,
  riskQualityColumn,
  riskReasonIndicatorColumn,
  riskSummaryColumn,
  riskTypeColumn,
  riskValueColumn,
} from '@src/constants/columns/risk'
import { teamNameColumn } from '@src/constants/columns/team'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  RiskIndicatorInterface,
  RiskLevel,
  RiskStatsInterface,
} from '@src/interfaces/risk'

interface Props {
  table: useTableReturnType<RiskIndicatorInterface, RiskStatsInterface>
  entity: 'employee' | 'team' | 'department' | 'company'
  useWrapper?: boolean
}

const ROW = (
  isEmployee: boolean,
  isNewTable?: boolean,
): RowInterface<RiskIndicatorInterface> => ({
  highlight: data => {
    if (isNewTable) {
      return ''
    }
    if (data.risk_level === RiskLevel.high) {
      return Token.color.red_20
    }
    return ''
  },
  cells: [
    {
      ...riskSummaryColumn,
      width: 260,
    },
    {
      ...riskLevelIndicatorsColumn,
      width: 130,
    },
    {
      ...riskTypeColumn,
      width: 90,
    },
    {
      ...riskValueColumn,
      width: 130,
    },
    {
      ...riskLimit1Column,
      width: 90,
    },
    {
      ...riskLimit2Column,
      width: 90,
    },
    {
      ...riskLimit3Column,
      width: 90,
    },
    {
      ...riskQualityColumn,
      width: 100,
    },
    {
      ...riskReasonIndicatorColumn,
      width: 100,
    },
    {
      ...riskLastBreachColumn,
      width: 100,
    },
    {
      ...(isEmployee ? teamNameColumn : riskAssigneeColumn),
      width: 90,
    },
    {
      ...riskDueDateColumn,
      width: 90,
    },
    {
      ...riskJiraColumn,
      width: 80,
    },
  ],
})

const Indicators = ({ table, entity }: Props) => {
  const isNewTable = useIsNewTable()
  const [showBCI, setShowBCI] = useState(true)

  const isEmployee = entity === 'employee'
  const isCompany = entity === 'company'

  const row = useMemo(() => ROW(isEmployee, isNewTable), [isEmployee, isNewTable])
  const handleNewRow = () => {
    window.open('https://backoffice.revolut.com/risks/risk-indicators', '_blank')
  }

  const onChangeShowClosed = () => {
    if (showBCI) {
      table.onFilterChange({
        filters: [
          { id: 'RAS', name: 'RAS' },
          { id: 'KRI', name: 'KRI' },
        ],
        columnName: 'type',
      })
    } else {
      table.onFilterChange({
        filters: [],
        columnName: 'type',
      })
    }
    setShowBCI(!showBCI)
  }

  return (
    <>
      {isNewTable ? null : (
        <Table.Widget.Info>
          <Stat
            label="High"
            val={
              table.stats?.high !== undefined ? (
                <Tooltip
                  text="Risk indicators with limit 2 or limit 3 breached. Imediate action is required to reduce financial or reputational loss. Limit 2 breaches are escalated to Executive Risk Committee and Limit 3 breaches are escalated to the Board."
                  placement="right"
                >
                  <Text color="red">{table.stats?.high}</Text>
                </Tooltip>
              ) : undefined
            }
          />
          <Stat
            label="Medium"
            val={
              table.stats?.medium !== undefined ? (
                <Tooltip
                  text="Risk indicators with limit 1 breached. Remediation plan needs to be applied before due date to avoid escalation."
                  placement="right"
                >
                  <Text color="warning">{table.stats?.medium}</Text>
                </Tooltip>
              ) : undefined
            }
          />
          <Stat
            label="Low"
            val={
              table.stats?.low !== undefined ? (
                <Tooltip text="Risk indicator is not breached" placement="right">
                  <Text color="green">{table.stats?.low}</Text>
                </Tooltip>
              ) : undefined
            }
          />
        </Table.Widget.Info>
      )}
      {isCompany && isNewTable ? null : (
        <Table.Widget.Filters>
          <FilterButton active={showBCI} onClick={onChangeShowClosed}>
            Show BCIs
          </FilterButton>
        </Table.Widget.Filters>
      )}

      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <MoreBar.Action useIcon="Plus" onClick={handleNewRow}>
            Add Risk Indicator
          </MoreBar.Action>
          <MoreBar.Action
            target="_blank"
            rel="noreferrer noopener"
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1478372102/Risk+Indicators+KRIs+and+BCIs"
            use="a"
            useIcon="InfoOutline"
          >
            How to manage risk indicators?
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<RiskIndicatorInterface, RiskStatsInterface>
          name={TableNames.Indicators}
          useWindowScroll
          dataType="Risk Indicator"
          row={row}
          {...table}
          emptyState={
            <EmptyTableRaw title={`This ${entity} has no risk indicators data`} />
          }
          hideCount={isNewTable}
        />
      </Table.Widget.Table>
    </>
  )
}

export default Indicators
