import React, { useRef } from 'react'
import {
  Action,
  Box,
  Button,
  Image,
  Popup,
  Relative,
  Scrollbar,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'

import { useNavigation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useNavigation'
import { generateScorecardsPopupProps } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/GenerateScorecards/constants'
import { useGenerationStatusPopup } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/GenerateScorecards/hooks/useGenerationStatusPopup'
import { PopupBody } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/GenerateScorecards/PopupBody'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
  isOpen: boolean
  isConfirmDisabled?: boolean
  isConfirmPending?: boolean
  handleClose: VoidFunction
  handleConfirm: () => Promise<void>
}

export const GenerateScorecardsPopup = ({
  cycle,
  eligibleGroups,
  isOpen,
  isConfirmDisabled,
  isConfirmPending,
  handleClose,
  handleConfirm,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { toEditEligibleEmployees } = useNavigation()
  const { showGenerationFailed, showGenerationCompleted } = useGenerationStatusPopup()

  const { title, description, image, confirmLabel, cancelLabel } =
    generateScorecardsPopupProps

  const onConfirmClick = async () => {
    try {
      await handleConfirm()
      showGenerationCompleted()
    } catch (err) {
      showGenerationFailed(err)
    } finally {
      handleClose()
    }
  }

  return (
    <Popup size="sm" open={isOpen}>
      <VStack space="s-8" align="center">
        <Image size={88} image={image} />
        <Text variant="heading3">{title}</Text>
        <Text variant="caption">{description}</Text>
      </VStack>
      <Widget marginY="s-16">
        <Relative>
          <Box minHeight="s-8" maxHeight={300} borderRadius="r16" ref={scrollRef}>
            <PopupBody
              cycle={cycle}
              eligibleGroups={eligibleGroups}
              scrollRef={scrollRef}
            />
            <Scrollbar scrollRef={scrollRef} cornerSpace="s-8" offset="s-2" />
          </Box>
        </Relative>
        <Box px="s-16" pt="s-8" pb="s-16">
          <Action useIcon="PlusCircle" onClick={() => toEditEligibleEmployees(cycle)()}>
            Add employee group
          </Action>
        </Box>
      </Widget>
      <Popup.Actions>
        <Button
          onClick={onConfirmClick}
          pending={isConfirmPending}
          disabled={isConfirmDisabled}
        >
          {confirmLabel}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {cancelLabel}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
