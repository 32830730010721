import React from 'react'
import { Box, Link } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import Table from '@components/TableV2/Table'
import { getDataOwnership } from '@src/api/ownership'
import {
  dataOwnershipActionColumn,
  dataOwnershipDependencyColumn,
  dataOwnershipInstructionsColumn,
  dataOwnershipResourceColumn,
} from '@src/constants/columns/ownership'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { DataOwnershipInterface } from '@src/interfaces/ownership'

import { Banner } from './common'

const ROW: RowInterface<DataOwnershipInterface> = {
  cells: [
    {
      ...dataOwnershipDependencyColumn,
      width: 80,
    },
    {
      ...dataOwnershipResourceColumn,
      width: 160,
    },
    {
      ...dataOwnershipInstructionsColumn,
      width: 500,
    },
    {
      ...dataOwnershipActionColumn,
      width: 60,
    },
  ],
}

export const dataBannerTitle =
  'Please ask the employee to transfer ownership for data dependencies'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
}

const DataOwnership = ({ data, stats }: Props) => {
  const table = useTable<DataOwnershipInterface>(getDataOwnership(data.id))

  return (
    <>
      <Box mb="s-16">
        <Banner
          title={dataBannerTitle}
          description={
            <>
              You can check{' '}
              <Link
                target="_blank"
                href="https://revolut.atlassian.net/wiki/spaces/BD/pages/1557963048/Data+Ownership+roles+responsibilities"
              >
                this confluence page
              </Link>{' '}
              for more details on how to transfer each type of dependency.
            </>
          }
        />
      </Box>

      <Table.Widget>
        <Table.Widget.Info>
          <Stat label="Data dependencies" val={stats?.owned_data_items_count} />
        </Table.Widget.Info>
        <Table.Widget.Table>
          <AdjustableTable
            name={TableNames.EmployeeDataOwnership}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="Data ownership will appear here"
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}

export default DataOwnership
