import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import capitalize from 'lodash/capitalize'
import pluralize from 'pluralize'
import {
  Button,
  Header,
  MoreBar,
  Popup,
  StatusPopup,
  TableWidget,
  Text,
  useStatusPopup,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import {
  bulkApproveBenchmarks,
  bulkArchiveBenchmarks,
  bulkUnarchiveBenchmarks,
  getBenchmarks,
} from '@src/api/benchmarks'
import { useGetOrganisationSettings } from '@src/api/settings'
import { API } from '@src/constants/api'
import {
  benchmarkCreatedByColumn,
  benchmarkCreatedOnColumn,
  benchmarkLocationColumn,
  benchmarkLowerBandColumn,
  benchmarkSeniorityColumn,
  benchmarkSeniorityLevelColumn,
  benchmarkSpecialisationColumn,
  benchmarkStatusColumn,
  benchmarkTagsColumn,
  benchmarkTypeColumn,
  benchmarkUpperBandColumn,
} from '@src/constants/columns/benchmarks'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { BenchmarkInterface } from '@src/interfaces/benchmarks'
import { CurrencySelect } from '@src/components/CurrencySelect/CurrencySelect'
import Stat from '@src/components/Stat/Stat'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import { useFetchBulkIds } from '@src/hooks/useFetchBulkIds'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'

type ActionType = 'approve' | 'archive' | 'unarchive'

export const SelectTable = () => {
  const permissions = useSelector(selectPermissions)
  const statusPopup = useStatusPopup()

  const isCommercial = useIsCommercial()

  const [currency, setCurrency] = useState<string>()
  const [isPending, setIsPending] = useState(false)
  const [popupOpen, setPopupOpen] = useState<ActionType | undefined>()
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<BenchmarkInterface>>()

  const table = useTable<BenchmarkInterface, undefined>({ getItems: getBenchmarks })

  const { data: settings } = useGetOrganisationSettings()

  const { bulkIds, fetchBulkIds } = useFetchBulkIds<BenchmarkInterface>(
    API.BENCHMARKS,
    table,
    selectedData,
  )

  const showError = (title: string, text?: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{title}</StatusPopup.Title>
        {text && <StatusPopup.Description>{text}</StatusPopup.Description>}
      </StatusPopup>,
    )
  }

  const showSuccess = (text: string) => {
    statusPopup.show(
      <StatusPopup variant="success">
        <StatusPopup.Title>{text}</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const showPending = () => {
    statusPopup.show(
      <StatusPopup variant="pending">
        <StatusPopup.Title>Task in progress</StatusPopup.Title>
        <StatusPopup.Description>
          Please wait for the data to update, this can take several minutes.
        </StatusPopup.Description>
      </StatusPopup>,
    )
  }

  const handleBulkAction = async (
    cb: (data: { id: number }[]) => ReturnType<typeof bulkApproveBenchmarks>,
    successTitle: string,
    errorTitle: string,
  ) => {
    setIsPending(true)
    showPending()
    try {
      await cb(bulkIds.map(id => ({ id })))
      showSuccess(successTitle)
    } catch (err) {
      const errorMsg = getStringMessageFromError(err)
      showError(errorTitle, errorMsg)
    } finally {
      setIsPending(false)
      setPopupOpen(undefined)
      table.refresh()
    }
  }

  const handleClick = () => {
    switch (popupOpen) {
      case 'approve':
        return handleBulkAction(
          data => bulkApproveBenchmarks(data),
          'Bands successfully approved',
          'Failed to approve',
        )
      case 'archive':
        return handleBulkAction(
          data => bulkArchiveBenchmarks(data),
          'Bands successfully archived',
          'Failed to archive',
        )
      case 'unarchive':
        return handleBulkAction(
          data => bulkUnarchiveBenchmarks(data),
          'Bands successfully unarchived',
          'Failed to unarchive',
        )
      default:
        return undefined
    }
  }

  const ROW: RowInterface<BenchmarkInterface> = {
    linkToForm: ({ id, is_hidden }) => {
      if (is_hidden) {
        return
      }
      navigateTo(pathToUrl(ROUTES.FORMS.BENCHMARK.PREVIEW, { id }))
    },
    disabled: ({ is_hidden }) => is_hidden,
    cells: [
      {
        ...getSelectCellConfig(),
      },
      {
        ...benchmarkSpecialisationColumn,
        width: 160,
      },
      {
        ...benchmarkSeniorityColumn,
        width: 100,
      },
      {
        ...benchmarkSeniorityLevelColumn,
        width: 100,
      },
      {
        ...benchmarkLocationColumn,
        width: 100,
      },
      {
        ...benchmarkTypeColumn,
        width: 100,
      },
      {
        ...benchmarkTagsColumn,
        width: 120,
      },
      {
        ...benchmarkLowerBandColumn,
        width: 100,
      },
      {
        ...benchmarkUpperBandColumn,
        width: 100,
      },
      {
        ...benchmarkCreatedOnColumn,
        width: 100,
      },
      {
        ...benchmarkCreatedByColumn,
        width: 160,
      },
      {
        ...benchmarkStatusColumn,
        width: 120,
      },
    ],
  }

  const disableBulkActions = !selectedData?.someSelected

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <Stat
            label="Compensation bands"
            val={table?.loading ? undefined : table?.count}
            mr="s-16"
          />

          <CurrencySelect
            onCurrencyChange={({ iso_code }) => {
              setCurrency(iso_code)
              table.onFilterChange({
                columnName: 'target_currency',
                filters: [{ id: iso_code, name: iso_code }],
              })
            }}
            value={currency ?? 'Select'}
          />
        </TableWidget.Info>
        <TableWidget.Actions>
          <MoreBar>
            {permissions.includes(PermissionTypes.BulkBenchmarkApprove) && (
              <MoreBar.Action
                onClick={async () => {
                  await fetchBulkIds()
                  setPopupOpen('approve')
                }}
                pending={isPending}
                disabled={disableBulkActions}
                useIcon="Check"
              >
                Bulk approve
              </MoreBar.Action>
            )}
            {permissions.includes(PermissionTypes.BulkBenchmarkArchive) && (
              <MoreBar.Action
                onClick={async () => {
                  await fetchBulkIds()
                  setPopupOpen('archive')
                }}
                pending={isPending}
                disabled={disableBulkActions}
                variant="negative"
                useIcon="Archive"
              >
                Bulk archive
              </MoreBar.Action>
            )}
            {permissions.includes(PermissionTypes.BulkBenchmarkUnarchive) && (
              <MoreBar.Action
                onClick={async () => {
                  await fetchBulkIds()
                  setPopupOpen('unarchive')
                }}
                pending={isPending}
                disabled={disableBulkActions}
                useIcon="Unarchive"
              >
                Bulk unarchive
              </MoreBar.Action>
            )}
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Table>
          <SelectTableWrapper
            enabled
            filters={table.filterBy}
            onChange={setSelectedData}
            tableDataLength={table.data.length}
            tableData={table.data}
          >
            <AdjustableTable
              hiddenCells={{
                [benchmarkSeniorityLevelColumn.idPoint]:
                  !settings?.enable_multiple_levels_per_seniority,
                [benchmarkTagsColumn.idPoint]: isCommercial,
              }}
              name={TableNames.CompensationBenchmarks}
              noDataMessage="Benchmarks will appear here."
              row={ROW}
              useWindowScroll
              {...table}
            />
          </SelectTableWrapper>
        </TableWidget.Table>
      </TableWidget>
      <Popup
        onClose={() => setPopupOpen(undefined)}
        open={!!popupOpen}
        variant="bottom-sheet"
      >
        <Header variant="main">
          <Header.Title>{capitalize(popupOpen)} bands</Header.Title>
        </Header>
        <Text mb="s-16" use="p" variant="caption">
          Clicking the button below will {popupOpen}{' '}
          {pluralize('band', bulkIds.length, true)}. Are you sure you want to proceed?
        </Text>
        <Popup.Actions horizontal>
          <Button elevated onClick={handleClick}>
            {`${capitalize(popupOpen)} ${pluralize('band', bulkIds.length, true)}`}
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
