import React from 'react'

import {
  Popup,
  Header,
  VStack,
  Subheader,
  Button,
  Item,
  Radio,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface, ReviewVisibility } from '@src/interfaces/settings'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { IdAndName } from '@src/interfaces'

interface Props {
  variant: undefined | 'upward' | 'peer'
  onClose: VoidFunction
}

const contentMap: Record<
  NonNullable<Props['variant']>,
  { description: string; name: string }
> = {
  peer: {
    name: 'Peer',
    description: 'Manage who has access to peer review feedback within the organisation.',
  },
  upward: {
    name: 'Upward',
    description: `Control who can access an employee's upward review within the organisation.`,
  },
}

const descriptionMap: Record<ReviewVisibility, string> = {
  [ReviewVisibility.RevieweeAndManagers]:
    'The reviewed person and their managers can see the review',
  [ReviewVisibility.Managers]: 'Only the reviewed person’s LM & FM can see the review',
  [ReviewVisibility.ReviewerSet]: 'The reviewer determines who can view their review.',
}

export const VisibilityModal = ({ variant, onClose }: Props) => {
  const { data: options, isLoading } = useGetSelectors<IdAndName<ReviewVisibility>>(
    selectorKeys.performance_review_visibility,
  )

  const { values, submit, isSubmitting, initialValues } =
    useLapeContext<PerformanceSettingsInterface>()
  const showStatusPopup = useShowStatusPopup()
  if (!variant) {
    return null
  }
  return (
    <Popup open onClose={onClose}>
      <Header displayMode="large">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>{contentMap[variant].name} review visibility</Header.Title>
        <Header.Description>{contentMap[variant].description}</Header.Description>
      </Header>

      <Subheader>
        Who can see the {contentMap[variant].name.toLowerCase()} review?
      </Subheader>
      <VStack gap="s-8">
        {isLoading && (
          <>
            <ItemSkeleton />
            <ItemSkeleton />
          </>
        )}
        {options?.map(option => (
          <Item
            use="label"
            key={option.id}
            onClick={() => {
              if (variant === 'peer') {
                values.peer_reviews_visibility = option
              }
              if (variant === 'upward') {
                values.upwards_reviews_visibility = option
              }
            }}
          >
            <Item.Prefix>
              <Radio
                checked={
                  variant === 'peer'
                    ? option.id === values.peer_reviews_visibility?.id
                    : option.id === values.upwards_reviews_visibility?.id
                }
                aria-labelledby={`${variant}-${option.id}-title`}
                aria-describedby={`${variant}-${option.id}-description`}
              />
            </Item.Prefix>
            <Item.Content>
              <Item.Title id={`${variant}-${option.id}-title`}>{option.name}</Item.Title>
              <Item.Description id={`${variant}-${option.id}-description`}>
                {descriptionMap[option.id]}
              </Item.Description>
            </Item.Content>
          </Item>
        ))}
      </VStack>

      <Popup.Actions horizontal>
        <Button
          variant="secondary"
          onClick={() => {
            if (variant === 'peer') {
              values.peer_reviews_visibility =
                initialValues.peer_reviews_visibility || null // null to satisfy TS
            }
            if (variant === 'upward') {
              values.upwards_reviews_visibility =
                initialValues.upwards_reviews_visibility || null // null to satisfy TS
            }
            onClose()
          }}
        >
          Cancel
        </Button>
        <Button
          elevated
          disabled={isLoading}
          pending={isSubmitting}
          onClick={async () => {
            try {
              await submit()
            } catch (e) {
              showStatusPopup({
                status: 'error',
                title: "Couldn't set review visibility",
                description: getMessageFromApiError(e),
              })
            } finally {
              onClose()
            }
          }}
        >
          Save
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
