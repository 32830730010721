import React, { PropsWithChildren } from 'react'
import { Flex, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import { FunctionOptionInterface } from '@src/interfaces/functions'
import pluralize from 'pluralize'

interface SkillGroupProps {
  function?: FunctionOptionInterface
  numberOfSkills: number
}

export const SkillGroup = ({
  function: func,
  numberOfSkills,
  children,
}: PropsWithChildren<SkillGroupProps>) => {
  return (
    <VStack gap="s-12">
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="heading3">{func?.name}</Text>
        <Text variant="body3" color={Token.color.greyTone50}>
          {pluralize('Skill', numberOfSkills, true)}
        </Text>
      </Flex>
      {children && <Widget>{children}</Widget>}
    </VStack>
  )
}
