import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import {
  ReviewCyclesInterface,
  ReviewCyclesScorecardSectionInterface,
} from '@src/interfaces/reviewCycles'
import {
  deleteAdditionalScorecardSection,
  reviewCyclesAdditionalScorecards,
  reviewCyclesRequestsNew,
  useGetScorecardSectionsInfo,
} from '@src/api/reviewCycles'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router'
import { Text, Box, ActionButton, VStack } from '@revolut/ui-kit'
import NoDataWidget from '@src/pages/Forms/ReviewCycle/components/NoDataWidget'
import { AdditionalScorecardsSidebar } from './AdditionalScorecardsSidebar'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PreviewSection } from './PreviewScorecardSection'
import { formatPeriod } from '@src/utils/format'

const getFormTitle = (values: ReviewCyclesInterface) => {
  return values.id ? (
    <VStack space="s-8">
      <Text>Edit Review Cycle</Text>
      <Text variant="caption" color="grey-tone-50">
        {values.name}{' '}
        {values.start_date_time && values.end_date_time
          ? `(${formatPeriod(values.start_date_time, values.end_date_time)})`
          : ''}
      </Text>
    </VStack>
  ) : (
    'New Review Cycle'
  )
}

interface Props {
  currentSection?: ReviewCyclesScorecardSectionInterface
  setCurrentSection: React.Dispatch<
    React.SetStateAction<ReviewCyclesScorecardSectionInterface | undefined>
  >
  openSidebar: boolean
  setOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>
  showDeletePopup: boolean
  setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>
}

const AdditionalScorecards = ({
  currentSection,
  setCurrentSection,
  openSidebar,
  setOpenSidebar,
  showDeletePopup,
  setShowDeletePopup,
}: Props) => {
  const { values } = useLapeContext<ReviewCyclesScorecardSectionInterface>()
  const params = useParams<{ id: string }>()
  const { data: scorecards, isLoading, refetch } = useGetScorecardSectionsInfo(params.id)

  const [cycleData, setCycleData] = useState<ReviewCyclesInterface>()
  const [deletePending, setDeletePending] = useState(false)

  useEffect(() => {
    reviewCyclesRequestsNew.get({ id: params.id }).then(res => setCycleData(res.data))
  }, [params.id])

  const handleDeleteSection = async () => {
    try {
      setDeletePending(true)
      if (currentSection?.id) {
        await deleteAdditionalScorecardSection(currentSection.id)
        pushNotification({
          value: 'Section was deleted',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
        setCurrentSection(undefined)
        refetch()
      }
    } finally {
      setDeletePending(false)
      setShowDeletePopup(false)
    }
  }

  return (
    <PageWrapper>
      {cycleData && (
        <PageHeader
          title={getFormTitle(cycleData)}
          backUrl={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, params)}
        />
      )}

      <PageBody>
        <Text variant="primary" mb="s-8">
          Do you need any additional questions included on the review scorecards?
        </Text>
        <Text variant="caption" color="grey-tone-50" mb="s-32">
          Add additional question sections
        </Text>
        {scorecards?.results?.length || isLoading ? (
          scorecards?.results.map(scorecard => (
            <Box mb="s-16" key={scorecard.id}>
              <PreviewSection
                data={scorecard}
                isCurrentSection={scorecard.id === currentSection?.id}
                onClickEdit={item => {
                  setOpenSidebar(true)
                  setCurrentSection(item)
                }}
                onClickDelete={item => {
                  setCurrentSection(item)
                  setShowDeletePopup(true)
                }}
              />
            </Box>
          ))
        ) : (
          <Box mb="s-16">
            <NoDataWidget text="No question sections added" />
          </Box>
        )}
        {!values.id && values.questions && openSidebar && (
          <Box mb="s-16">
            <PreviewSection isCurrentSection />
          </Box>
        )}
        <ActionButton useIcon="Plus" onClick={() => setOpenSidebar(true)}>
          Add question section
        </ActionButton>
      </PageBody>

      <ConfirmationDialog
        open={showDeletePopup}
        onClose={() => {
          setCurrentSection(undefined)
          setShowDeletePopup(false)
        }}
        onConfirm={handleDeleteSection}
        onReject={() => {
          setCurrentSection(undefined)
          setShowDeletePopup(false)
        }}
        label="Delete this section?"
        body="Deleting a section will permanently remove it"
        loading={deletePending}
        submitDisabled={deletePending}
        yesMessage="Delete"
        noMessage="Cancel"
      />

      <AdditionalScorecardsSidebar
        isOpen={openSidebar}
        handleClose={() => {
          setOpenSidebar(false)
          setCurrentSection(undefined)
        }}
        scorecardData={currentSection}
        refetch={refetch}
      />
    </PageWrapper>
  )
}

export const AdditionalScorecardsForm = connect(() => {
  const [currentSection, setCurrentSection] =
    useState<ReviewCyclesScorecardSectionInterface>()
  const [openSidebar, setOpenSidebar] = useState(!!currentSection?.id)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const id = currentSection?.id ? String(currentSection.id) : undefined

  return (
    <Form
      api={reviewCyclesAdditionalScorecards}
      forceParams={id ? { id } : {}}
      disableLocalStorageCaching
      disableDataCleanup
      forceSetIsExistingData
    >
      <AdditionalScorecards
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        openSidebar={openSidebar}
        setOpenSidebar={setOpenSidebar}
        showDeletePopup={showDeletePopup}
        setShowDeletePopup={setShowDeletePopup}
      />
    </Form>
  )
})
