import React from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import {
  Flex,
  Status,
  Subheader,
  SubheaderSkeleton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import pluralize from 'pluralize'
import { List } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/Goals/List'
import {
  ListItemsSkeleton,
  renderWithGoalsItem,
  renderWithoutGoalsItem,
} from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/Goals/ListItems'
import { useGoalsStats } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/Goals/useGoalsStats'
import { GoalsOwner } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/GoalsModel'
import capitalize from 'lodash/capitalize'
import { goalsOwnerToEntityNameMap } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/constants'
import { useNavigation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useNavigation'

interface Props {
  cycle: ReviewCyclesInterface
  goalsOwner: GoalsOwner
  scrollRef: React.RefObject<HTMLDivElement>
}

export const Goals = ({ cycle, goalsOwner, scrollRef }: Props) => {
  const { toDepartmentGoals, toTeamGoals, makeTeamLink, makeDepartmentLink } =
    useNavigation()

  const {
    count,
    withoutGoalsItems,
    withGoalsItems,
    isLoading,
    isFetchingMoreWithoutGoals,
    isFetchingMoreWithGoals,
    handleFetchMoreWithGoals,
    handleFetchMoreWithoutGoals,
  } = useGoalsStats(cycle, goalsOwner)
  const { withoutGoalsCount, withGoalsCount } = count

  const withoutGoalsStatus = `${withoutGoalsCount} ${capitalize(
    pluralize(goalsOwnerToEntityNameMap[goalsOwner], withoutGoalsCount),
  )} without goals`
  const withGoalsStatus = `${withGoalsCount} ${capitalize(
    pluralize(goalsOwnerToEntityNameMap[goalsOwner], withGoalsCount),
  )} with goals`

  const toGoals = (id: number) =>
    goalsOwner === GoalsOwner.Team
      ? toTeamGoals(cycle, { id })()
      : toDepartmentGoals(cycle, { id })()
  const makeGoalsOwnerLink = (id: number) =>
    goalsOwner === GoalsOwner.Team ? makeTeamLink({ id }) : makeDepartmentLink({ id })
  const itemIconName = goalsOwner === GoalsOwner.Team ? 'People' : 'Bank'

  if (isLoading) {
    return <GoalsSkeleton />
  }

  return (
    <>
      {!!withoutGoalsItems.length && (
        <>
          <Subheader>
            <Subheader.Title>
              <Flex alignItems="center">
                <Status
                  useIcon="ExclamationTriangle"
                  iconSize={24}
                  color={Token.color.warning}
                />
                {withoutGoalsStatus}
              </Flex>
            </Subheader.Title>
          </Subheader>
          <List
            data={withoutGoalsItems}
            isFetchingMore={isFetchingMoreWithoutGoals}
            fetchMore={handleFetchMoreWithoutGoals}
            renderItem={renderWithoutGoalsItem}
            scrollParent={scrollRef?.current ?? undefined}
            context={{
              iconName: itemIconName,
              onActionClick: toGoals,
              makeTitleLink: makeGoalsOwnerLink,
            }}
          />
        </>
      )}
      {!!withGoalsItems.length && (
        <>
          <Subheader>
            <Subheader.Title>
              <Flex alignItems="center">
                <Status
                  useIcon="CheckSuccess"
                  iconSize={24}
                  color={Token.color.success}
                />
                {withGoalsStatus}
              </Flex>
            </Subheader.Title>
          </Subheader>
          <List
            data={withGoalsItems}
            isFetchingMore={isFetchingMoreWithGoals}
            fetchMore={handleFetchMoreWithGoals}
            renderItem={renderWithGoalsItem}
            scrollParent={scrollRef?.current ?? undefined}
            context={{
              iconName: itemIconName,
              onActionClick: toGoals,
              makeTitleLink: makeGoalsOwnerLink,
            }}
          />
        </>
      )}
    </>
  )
}

const GoalsSkeleton = () => (
  <VStack space="s-16">
    <SubheaderSkeleton>
      <SubheaderSkeleton.Title />
    </SubheaderSkeleton>
    <ListItemsSkeleton />
  </VStack>
)
