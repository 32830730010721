import React, { useEffect, useMemo } from 'react'
import { InputGroup, Flex, Widget } from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  MeetingTemplateFrequency,
  MeetingTemplateInterface,
} from '@src/interfaces/meetings'
import { useGetSelectors } from '@src/api/selectors'
import { BaseOptionId } from '@src/interfaces/selectors'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'

export const GeneralTimingSection = () => {
  const { data: frequencies } = useGetSelectors(selectorKeys.feedback_template_frequency)
  const { data: durations } = useGetSelectors(selectorKeys.feedback_template_duration)
  const { values } = useLapeContext<MeetingTemplateInterface>()

  useEffect(() => {
    if (!values.frequency) {
      values.frequency = MeetingTemplateFrequency.WEEKLY
    }
  }, [values.frequency])

  const frequenciesNameRecord = useMemo(() => {
    return frequencies?.reduce<Record<BaseOptionId, string>>((acc, item) => {
      acc[item.id] = item.name
      return acc
    }, {})
  }, [frequencies])

  const durationNameRecord = useMemo(() => {
    return durations?.reduce<Record<BaseOptionId, string>>((acc, item) => {
      acc[item.id] = item.name
      return acc
    }, {})
  }, [frequencies])

  return (
    <InputGroup variant="horizontal">
      <LapeRadioSelectInput
        selector={selectorKeys.feedback_template_frequency}
        name="frequency"
        label="Default frequency"
        value={
          values.frequency
            ? {
                id: values.frequency,
                name: frequenciesNameRecord?.[values.frequency],
              }
            : null
        }
        onChange={option => {
          if (option) {
            values.frequency = option.id
          }
        }}
      />
      <LapeRadioSelectInput
        value={
          values.duration
            ? { id: values.duration, name: durationNameRecord?.[values.duration] }
            : null
        }
        onChange={option => {
          if (option) {
            values.duration = option.id
          }
        }}
        selector={selectorKeys.feedback_template_duration}
        required
        name="duration"
        label="Default duration"
      />
    </InputGroup>
  )
}

export const General = () => {
  return (
    <Widget padding="s-16">
      <Flex flexDirection="column" gap="s-16">
        <InputGroup>
          <LapeNewInput label="Template title" name="name" required />
          <LapeNewTextArea label="Description" name="description" required />
        </InputGroup>
        <GeneralTimingSection />
      </Flex>
    </Widget>
  )
}
