import React from 'react'
import { Avatar, IconName, Item, Token, VStack } from '@revolut/ui-kit'

interface Props {
  title: string
  iconName: IconName
  children: React.ReactNode
}

export const SummarySection = ({ title, iconName, children }: Props) => (
  <VStack>
    <Item>
      <Item.Avatar>
        <Avatar useIcon={iconName} bg={Token.color.accent} color={Token.color.white} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title data-testid={`summary.${title.toLowerCase()}`}>{title}</Item.Title>
      </Item.Content>
    </Item>
    {children}
  </VStack>
)
