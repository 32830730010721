import React, { useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'
import pluralize from 'pluralize'
import { Cell, Skeleton, Text, Token } from '@revolut/ui-kit'
import { EmployeeTimeOffBalanceCycleStats } from '@src/interfaces/timeOff'
import { BarChart as HorizontalBarChart } from '@src/pages/Forms/QueryForm/components/Charts/BarChart'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { CycleStatsChartLegend } from './CycleStatsChartLegend'

interface CycleStatsChartProps {
  data?: EmployeeTimeOffBalanceCycleStats
  isLoading: boolean
  isMobile: boolean
  isUnlimited: boolean
  unit: 'day' | 'hour'
}

export type DataKeysType = DataKey<
  Record<keyof EmployeeTimeOffBalanceCycleStats, string>
>[]

const DATA_KEYS: DataKeysType = [
  {
    id: 0,
    label: 'Available',
    value: 'available',
    color: Token.colorChannel.green,
  },
  {
    id: 1,
    label: 'To be earned',
    value: 'to_be_earned',
    color: Token.colorChannel.deepGrey,
  },
  {
    id: 2,
    label: 'Booked',
    value: 'booked',
    color: Token.colorChannel.orange,
  },
  {
    id: 3,
    label: 'Taken',
    value: 'taken',
    color: Token.colorChannel.deepPink,
  },
]
const MAX_BAR_SIZE = 32
const BORDER_RADIUS = 6

const checkIsPositive = (
  data: EmployeeTimeOffBalanceCycleStats,
  value: keyof EmployeeTimeOffBalanceCycleStats,
) => Number(data[value]) > 0

const getPositiveValuesIndices = (
  keys: DataKeysType,
  data?: EmployeeTimeOffBalanceCycleStats,
) => {
  const positiveIndices = keys
    .map(({ value }, index) => (data && checkIsPositive(data, value) ? index : -1))
    .filter(index => index !== -1)
  const firstIndex = positiveIndices[0]
  const lastIndex = positiveIndices[positiveIndices.length - 1]

  return { firstIndex, lastIndex }
}

export const CycleStatsChart = ({
  data,
  isLoading,
  isMobile,
  isUnlimited,
  unit,
}: CycleStatsChartProps) => {
  const { height, ref } = useResizeObserver<HTMLDivElement>()

  const dataKeys = useMemo(() => {
    if (!data) {
      return []
    }

    return DATA_KEYS.filter(item => checkIsPositive(data, item.value))
  }, [data])

  const cycleStatsSum = data ? data.booked + data.taken + data.to_be_earned : null
  const heading = isUnlimited
    ? 'Unlimited balance'
    : `${pluralize(unit, data?.available || 0, true)} available`

  if (isLoading) {
    return (
      <Cell>
        <Skeleton height={24} width="100%" />
      </Cell>
    )
  }

  if (isMobile || !cycleStatsSum) {
    return (
      <Cell>
        <Text variant="heading3">{heading}</Text>
      </Cell>
    )
  }

  const { firstIndex, lastIndex } = getPositiveValuesIndices(dataKeys, data)

  return (
    <Cell height="110px" pt={0} ref={ref}>
      <HorizontalBarChart
        barOptions={{
          barSize: MAX_BAR_SIZE,
        }}
        customLegend={(props, keys) => {
          return <CycleStatsChartLegend dataKeys={keys} heading={heading} props={props} />
        }}
        customRadius={index => {
          if (firstIndex === lastIndex) {
            return BORDER_RADIUS
          }
          if (index === firstIndex) {
            return [BORDER_RADIUS, 0, 0, BORDER_RADIUS]
          }
          if (index === lastIndex) {
            return [0, BORDER_RADIUS, BORDER_RADIUS, 0]
          }
          return 0
        }}
        data={[{ ...data, label: '', tooltip: '' }]}
        dataKeys={dataKeys}
        isLoading={isLoading}
        isStacked
        layout="vertical"
        hideLabelList
        height={height}
        margin={{ bottom: 0, left: 0 }}
        stackOffset="expand"
        verticalAlign="top"
        yAxis={{ hide: true }}
      />
    </Cell>
  )
}
