import { EmployeeInterface, EmployeeType, IdStatuses } from '@src/interfaces/employees'

export enum ApprovalStatuses {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
  Archived = 'archived',
  Draft = 'draft',
  Skipped = 'skipped',
  Canceled = 'canceled',
  Cancelled = 'cancelled',
  PendingReApproval = 'pending_reapproval',
  NoStatus = 'none',
  RequiresChanges = 'requires_changes',
}

export interface ApproverInterface extends Omit<Partial<EmployeeInterface>, 'status'> {
  id: number
  avatar: string | null
  full_name: string
  status: IdStatuses
  employee_type?: EmployeeType
}

export interface ApprovalFlowStep {
  approval_step: string | null
  approval_status: ApprovalStatuses
  approval_date_time: string | null
  rejection_comment?: string | null
  approver: ApproverInterface | null
  expected_approver: ApproverInterface | null
}

export interface LegacyApprovalStep {
  approval_datetime: string | null
  actual_approver: ApproverInterface | null
  expected_approver: ApproverInterface
  approval_status: ApprovalStatuses
  approval_step?: string
  rejection_comment?: string | null
}

export type ApprovalFlowResponse = ApprovalFlowStep[]
