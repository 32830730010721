import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { PermissionTypes } from '@src/store/auth/types'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useOwnerId } from './useOwnerId'
import { pathToUrl } from '@src/utils/router'

const useDefaultWriteAccess = () => {
  const { entity } = useOrgEntity()

  const canAddGoals = !!entity?.data.field_options?.permissions?.includes(
    PermissionTypes.CanAddGoals,
  )

  if (!entity) {
    return false
  }

  switch (entity.type) {
    case EntityTypes.company:
    case EntityTypes.companyV2:
    case EntityTypes.function:
    case EntityTypes.role:
    case EntityTypes.specialisation:
      return false
    default:
      return canAddGoals
  }
}

export const CascadeGoalAction = () => {
  const canAdd = useDefaultWriteAccess()
  const isOnboarding = isOnboardingPath()
  const ownerId = useOwnerId()
  const { navigateReplaceWithEntity } = useOrgEntity()

  if (isOnboarding) {
    return null
  }

  return canAdd ? (
    <MoreBar.Action
      onClick={() =>
        navigateReplaceWithEntity(
          pathToUrl(ROUTES.FORMS.CASCADE_GOAL, {
            ownerId,
          }),
        )
      }
      useIcon="Materials"
    >
      Cascade goals
    </MoreBar.Action>
  ) : null
}
