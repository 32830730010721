import { useFetchCandidateInterviewStages } from '@src/api/recruitment/interviews'
import { SORT_DIRECTION } from '@src/interfaces/data'

export type StagesLoadingStatusType = 'success' | 'error' | 'loading' | 'idle'

export const useFetchInterviewStages = (roundId?: number) => {
  const sortBy = [
    {
      sortBy: 'interview_number',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'interview_number_for_type',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'id',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  const filters = [
    {
      filters: [{ name: 'True', id: 'True' }],
      columnName: 'is_enabled',
      nonResettable: true,
    },
  ]

  return useFetchCandidateInterviewStages({ filters, sortBy }, roundId)
}
