import React from 'react'
import {
  getFeedbackRecommendationProps,
  getOnlineTestStageRecommendationProps,
  isOnlineTestStage,
} from '@src/pages/Forms/Candidate/StagesWidget/utils'
import {
  CandidateInterface,
  InterviewStageWithoutRoundInterface,
  StagesWidgetOnClickHandler,
} from '@src/interfaces/interviewTool'
import { Avatar, Text, Token, VStack } from '@revolut/ui-kit'
import { StageItemLayout } from '@src/pages/Forms/Candidate/StagesWidget/components/StageItemLayout'
import { formatTimeAgo } from '@src/utils/format'
import {
  getStageItemInfoProps,
  StageItemInfo,
} from '@src/pages/Forms/Candidate/StagesWidget/components/StageItemInfo'
import { StageItemRecommendation } from '@src/pages/Forms/Candidate/StagesWidget/components/StageItemRecommendation'
import { StageItemRating } from '@src/pages/Forms/Candidate/StagesWidget/components/StageItemRating'
import { UserAvatar } from '@src/components/UserWithAvatar/UserAvatar'
import UserName from '@src/components/UserWithAvatar/UserName'
import { upperFirst } from 'lodash'
import { convertOperators } from '@src/pages/Forms/Candidate/Performance/utils'
import { PerformanceRating } from '@src/interfaces/performance'

type StageItemsProps = {
  candidate: CandidateInterface
  disable: boolean
  stage: InterviewStageWithoutRoundInterface
  onClick?: StagesWidgetOnClickHandler
}

export const StageItems = ({ candidate, stage, onClick }: StageItemsProps) => {
  switch (stage.scheduling_status) {
    case 'pending_candidate_response':
      return (
        <StageItemLayout
          id={`Scheduling of ${stage.title}`}
          avatar={<Avatar size={40} useIcon="CalendarDate" />}
          disabled
          description={
            stage.updated_date_time ? (
              <Text variant="body2">
                Interview slots sent {formatTimeAgo(stage.updated_date_time)}
              </Text>
            ) : null
          }
          title={`Pending ${candidate.full_name} response`}
          titleProps={{ color: Token.color.orange }}
          onClick={() => {
            onClick?.(stage, undefined, stage.interview_type)
          }}
        />
      )

    case 'scheduling_expired':
      return (
        <StageItemLayout
          id={`Scheduling of ${stage.title}`}
          avatar={<Avatar size={40} useIcon="CalendarDate" />}
          disabled
          description={
            stage.updated_date_time ? (
              <Text variant="body2">
                Interview slots sent {formatTimeAgo(stage.updated_date_time)}
              </Text>
            ) : null
          }
          title="Interview slots expired"
          titleProps={{ color: Token.color.orange }}
          onClick={() => {
            onClick?.(stage, undefined, stage.interview_type)
          }}
        />
      )

    case 'interview_cancelled':
      return (
        <StageItemLayout
          id={`Scheduling of ${stage.title}`}
          avatar={<Avatar size={40} useIcon="CalendarDate" />}
          disabled
          description={
            stage.updated_date_time ? (
              <Text variant="body2">
                Interview slots sent {formatTimeAgo(stage.updated_date_time)}
              </Text>
            ) : null
          }
          title="Interview cancelled"
          titleProps={{ color: Token.color.danger }}
          onClick={() => {
            onClick?.(stage, undefined, stage.interview_type)
          }}
        />
      )

    case 'offer_expired':
    case 'offer_declined_recruiter':
    case 'offer_declined_candidate':
    case 'offer_cancelled_recruiter':
      return (
        <StageItemLayout
          id={`Offer of ${stage.title}`}
          avatar={<Avatar size={40} useIcon="ExclamationTriangle" />}
          disabled
          title={stage.scheduling_status_display}
          titleProps={{ color: Token.color.danger }}
          onClick={() => {
            onClick?.(stage, undefined, stage.interview_type)
          }}
        />
      )

    case 'pending_candidate_signature':
      return (
        <StageItemLayout
          id={`Scheduling of ${stage.title}`}
          avatar={<Avatar size={40} useIcon="ExclamationTriangle" />}
          disabled
          title={stage.scheduling_status_display}
          titleProps={{ color: Token.color.warning }}
          onClick={() => {
            onClick?.(stage, undefined, stage.interview_type)
          }}
        />
      )

    case 'offer_signed':
      return (
        <StageItemLayout
          id={`Offer of ${stage.title}`}
          avatar={<Avatar size={40} useIcon="Trophy" />}
          disabled
          title={stage.scheduling_status_display}
          titleProps={{ color: Token.color.success }}
          onClick={() => {
            onClick?.(stage, undefined, stage.interview_type)
          }}
        />
      )
  }

  if (isOnlineTestStage(stage)) {
    const { color, text } = getStageItemInfoProps(stage, null)

    return (
      <StageItemLayout
        id={`Online test of ${stage.title}`}
        avatar={<Avatar size={40} useIcon="CalendarDate" />}
        disabled
        description={<StageItemInfo color={color} text={text} />}
        title={stage.online_test?.name}
        recommendation={
          <StageItemRecommendation {...getOnlineTestStageRecommendationProps(stage)} />
        }
        rating={
          <StageItemRating
            rating={
              stage.online_test_result?.percentage_score
                ? `${stage.online_test_result?.percentage_score}%`
                : ''
            }
          />
        }
        onClick={() => {
          onClick?.(stage, undefined, stage.interview_type)
        }}
      />
    )
  }
  return (
    <VStack>
      {stage.interview_feedbacks.map((feedback, index) => {
        const { interviewer = {} } = feedback
        let rating: PerformanceRating | null = feedback.rating || null

        if (!rating) {
          rating = feedback.recommended_rating
        }
        const { color, text } = getStageItemInfoProps(stage, feedback)
        return (
          <StageItemLayout
            key={feedback.id}
            id={`Feedback ${index} of ${stage.title}`}
            avatar={<UserAvatar {...interviewer} size={40} />}
            description={text ? <StageItemInfo color={color} text={text} /> : null}
            title={<UserName {...interviewer} ellipsisProps={{ maxWidth: '200px' }} />}
            recommendation={
              <StageItemRecommendation {...getFeedbackRecommendationProps(feedback)} />
            }
            rating={
              <StageItemRating
                rating={rating ? upperFirst(convertOperators(rating)) : ''}
              />
            }
            onClick={() => {
              onClick?.(feedback, undefined, stage.interview_type)
            }}
          />
        )
      })}
    </VStack>
  )
}
