import React from 'react'
import { FormattedMessage } from 'react-intl'
import { MoreBar, useToggle } from '@revolut/ui-kit'
import { CreateReviewCyclePopup } from '@src/features/ReviewCycle/PerformanceReviewCycle/CreateCycle/CreateReviewCyclePopup'

export const CreateReviewCycleAction = () => {
  const [isPopupOpened, popupToggler] = useToggle()

  return (
    <>
      <MoreBar.Action variant="accent" useIcon="Plus" onClick={popupToggler.on}>
        <FormattedMessage
          defaultMessage="Add Review Cycle"
          id="performance.reviews.cycles.create.label"
        />
      </MoreBar.Action>
      <CreateReviewCyclePopup isOpen={isPopupOpened} onClose={popupToggler.off} />
    </>
  )
}
