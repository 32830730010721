import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { GoalsOwner } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/GoalsModel'
import { useGoalsStats } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/hooks/useGoalsStats'
import { useNavigation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useNavigation'
import {
  GoalsStatsItem,
  GoalsStatsItemProps,
} from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/StatsItems'
import { ItemSkeleton } from '@revolut/ui-kit'
import React from 'react'
import { makeGoalsStatsItemProps } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/constants'

interface Props {
  cycle: ReviewCyclesInterface
  goalsOwner: GoalsOwner
}

export const GoalsStats = ({ cycle, goalsOwner }: Props) => {
  const { totalCount, isLoading } = useGoalsStats(cycle, goalsOwner)
  const { toPerformanceDepartmentGoals, toPerformanceTeamGoals } = useNavigation()

  const handleOverviewGoalsClick =
    goalsOwner === GoalsOwner.Team
      ? toPerformanceTeamGoals(cycle)
      : toPerformanceDepartmentGoals(cycle)

  const goalItemProps = makeGoalsStatsItemProps(goalsOwner, totalCount)
  const actionProps: Partial<GoalsStatsItemProps> =
    totalCount > 0
      ? {
          actionIconName: 'LinkExternal',
          actionLabel: 'Review Goals',
          onActionClick: handleOverviewGoalsClick,
        }
      : {}

  if (isLoading) {
    return <ItemSkeleton />
  }

  return <GoalsStatsItem {...goalItemProps} {...actionProps} />
}
