import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FilterButton, MoreBar, Token } from '@revolut/ui-kit'

import { StatFilters } from '@components/StatFilters/StatFilters'
import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { getSelectCellConfig } from '@components/TableV2/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { getContracts, getContractsStats } from '@src/api/contracts'
import { useGetPayrollSettings } from '@src/api/settings'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import {
  contractEndAtColumn,
  contractEntityColumn,
  contractLocationColumn,
  contractPaygroupColumn,
  contractRankColumn,
  contractSeniorityColumn,
  contractStartAtColumn,
  contractStatusColumn,
  contractTermColumn,
  documentsColumn,
  employeeColumn,
  employeeTypeColumn,
  ownerColumn,
  specialisationRoleNameColumn,
} from '@src/constants/columns/contracts'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { ContractsStatsInterface } from '@src/interfaces/contracts'
import { FilterOption, RowInterface } from '@src/interfaces/data'
import { EmployeeContractsInterface } from '@src/interfaces/employees'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

export const statsConfig: StatsConfig<ContractsStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'pending_completion',
    title: 'Pending completion',
    filterKey: 'pending_completion',
    color: Token.color.warning,
  },
  {
    key: 'rejected',
    title: 'Rejected',
    filterKey: 'rejected',
    color: Token.color.danger,
  },
  {
    key: 'pending',
    title: 'Pending start',
    filterKey: 'pending',
    color: Token.color.foreground,
  },
  {
    key: 'active',
    title: 'Active',
    filterKey: 'active',
    color: Token.color.success,
  },
  {
    key: 'inactive',
    title: 'Inactive',
    filterKey: 'inactive',
    color: Token.color.foreground,
  },
  {
    key: 'expired',
    title: 'Expired',
    filterKey: 'expired',
    color: Token.color.foreground,
  },
  {
    key: 'terminated',
    title: 'Terminated',
    filterKey: 'terminated',
    color: Token.color.foreground,
  },
]

const getRows = (
  paygroupsEnabled?: boolean,
): RowInterface<EmployeeContractsInterface> => ({
  linkToForm: ({ id, employee }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW, { id, employeeId: employee.id }),
    ),
  cells: [
    {
      ...getSelectCellConfig(undefined, 60),
    },
    {
      ...employeeColumn,
      width: 140,
    },
    {
      ...employeeTypeColumn,
      width: 140,
    },
    {
      ...specialisationRoleNameColumn,
      width: 140,
    },
    {
      ...contractSeniorityColumn,
      width: 120,
    },
    {
      ...contractRankColumn,
      width: 100,
    },
    {
      ...contractLocationColumn,
      width: 140,
    },
    {
      ...contractEntityColumn,
      width: 130,
    },
    paygroupsEnabled
      ? {
          ...contractPaygroupColumn,
          width: 140,
        }
      : null,
    {
      ...contractTermColumn,
      width: 140,
    },
    {
      ...contractStartAtColumn,
      width: 140,
    },
    {
      ...contractEndAtColumn,
      width: 140,
    },
    {
      ...contractStatusColumn,
      width: 140,
    },
    {
      ...documentsColumn,
      width: 100,
    },
    {
      ...ownerColumn,
      width: 140,
    },
  ].filter(Boolean),
})

export const ContractsTable = () => {
  const location = useLocation()
  const user = useSelector(selectUser)
  const table = useTable<EmployeeContractsInterface, ContractsStatsInterface>({
    getItems: getContracts,
    getStats: getContractsStats,
  })
  const statFiltersProps = useSelectableTableStats<
    EmployeeContractsInterface,
    ContractsStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
    totalKey: 'total',
  })

  const { data: payrollSettings } = useGetPayrollSettings()

  const row = useMemo(() => getRows(payrollSettings?.enabled), [payrollSettings?.enabled])

  const [showAssignedToMe, setShowAssignedToMe] = useState(false)
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<EmployeeContractsInterface>>()

  useEffect(() => {
    if (table.loading) {
      return
    }
    const allFiltersByOwner = table.fetchQuery?.filters
      ?.filter(f => f.columnName === 'owner__id')
      .reduce((acc, f) => {
        acc = [...acc, ...f.filters]
        return acc
      }, [] as FilterOption[])

    if (!allFiltersByOwner?.some(f => String(f.id) === String(user.id))) {
      setShowAssignedToMe(false)
    } else {
      setShowAssignedToMe(true)
    }
  }, [table.loading])

  const getFiltersByCurrentUser = (assignedToMeOnly: boolean) => {
    const filters = assignedToMeOnly
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : []

    return {
      filters,
      columnName: 'owner__id',
    }
  }

  const onToggleAssignedToMe = () => {
    setShowAssignedToMe(!showAssignedToMe)
    table.onFilterChange(getFiltersByCurrentUser(!showAssignedToMe))
  }

  const permissions = useSelector(selectPermissions)

  const selectedContractsProps = useMemo(() => {
    if (selectedData?.isAllSelected) {
      return table.data.reduce<{ employeeId: string; contractId: string }[]>(
        (acc, item) => {
          if (!selectedData?.excludeListIds?.has(String(item.id))) {
            acc.push({
              contractId: String(item.id),
              employeeId: String(item.employee.id),
            })
          }

          return acc
        },
        [],
      )
    }
    return selectedData?.selectedRowsData?.map(datum => {
      return { contractId: String(datum.id), employeeId: String(datum.employee.id) }
    })
  }, [selectedData?.selectedRowsData, selectedData?.isAllSelected])

  const showReviewInBulk = !!selectedContractsProps?.length
  const showEditInBulk =
    permissions.includes(PermissionTypes.BulkEditContracts) && !showReviewInBulk

  return (
    <Table.Widget>
      <Table.Widget.Filters>
        <Table.Search
          variant="compact"
          placeholder="Search by employee name"
          onFilter={table.onFilterChange}
        />
        <FilterButton onClick={onToggleAssignedToMe} active={showAssignedToMe}>
          Assigned to me
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Info>
        <StatFilters {...statFiltersProps} />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {showReviewInBulk && (
            <MoreBar.Action
              onClick={() => {
                navigateTo(
                  `${pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW, {
                    id: selectedContractsProps[0].contractId,
                    employeeId: selectedContractsProps[0].employeeId,
                  })}${location.search}`,
                  { reviewInBulk: selectedContractsProps },
                )
              }}
              useIcon="ArrowSend"
            >
              Review contracts
            </MoreBar.Action>
          )}
          {showEditInBulk && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.BULK_EDIT_CONTRACTS.SELECT)}
              useIcon="Pencil"
            >
              Edit in bulk
            </MoreBar.Action>
          )}
          {permissions.includes(PermissionTypes.BulkUploadContracts) && (
            <MoreBar.Action
              use={InternalLink}
              useIcon="ShareIOs"
              to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.CONTRACTS.UPLOAD_FILE)}
            >
              Bulk update
            </MoreBar.Action>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <SelectTableWrapper
          enabled
          onChange={setSelectedData}
          filters={table.filterBy}
          tableDataLength={table.data.length}
          tableData={table.data}
        >
          <AdjustableTable<EmployeeContractsInterface, ContractsStatsInterface>
            name={TableNames.Contracts}
            useWindowScroll
            dataType="Contract"
            row={row}
            {...table}
            noDataMessage="Contracts will appear here."
          />
        </SelectTableWrapper>
      </Table.Widget.Table>
    </Table.Widget>
  )
}
