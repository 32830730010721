import { useMemo } from 'react'
import { Statuses } from '@src/interfaces'
import { BaseOption } from '@src/interfaces/selectors'
import { FilterByInterface } from '@src/interfaces/data'
import { toIdAndName } from '@src/utils/toIdAndName'

interface UseInitialFiltersParams {
  cycle?: BaseOption
  object_id?: number | null
  is_company?: boolean
  contentTypeFilter?: FilterByInterface | null
  childObjectId: number
  childContentTypeId: number | null
}

export const useInitialFilters = ({
  object_id,
  cycle,
  is_company,
  contentTypeFilter,
  childObjectId,
  childContentTypeId,
}: UseInitialFiltersParams) => {
  const initialFilters = useMemo(() => {
    const computedFilters = is_company
      ? [
          {
            filters: [{ name: `True`, id: 'True' }],
            columnName: 'is_company',
            nonResettable: true,
            nonInheritable: true,
          },
          {
            filters: [{ name: `False`, id: 'False' }],
            columnName: 'is_top_level',
            nonResettable: true,
            nonInheritable: true,
          },
        ]
      : [
          contentTypeFilter,
          {
            columnName: 'object_id',
            filters: [{ id: String(object_id), name: String(object_id) }],
            nonResettable: true,
            nonInheritable: true,
          },
        ].filter(Boolean)

    return [
      ...(cycle?.id
        ? [
            {
              columnName: 'cycle__id',
              filters: [{ id: String(cycle.id), name: String(cycle.name) }],
              nonResettable: true,
            },
          ]
        : []),
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
      {
        columnName: 'children_object_id',
        nonResettable: true,
        filters: [toIdAndName(String(childObjectId))],
      },
      ...(childContentTypeId
        ? [
            {
              columnName: 'children_content_type_id',
              filters: [toIdAndName(String(childContentTypeId))],
              nonResettable: true,
            },
          ]
        : []),
      ...computedFilters,
    ]
  }, [object_id, cycle, is_company, contentTypeFilter])

  return initialFilters
}
