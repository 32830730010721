import React from 'react'
import { Text, HStack, Avatar } from '@revolut/ui-kit'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { RequiredBadge } from './RequiredBadge'

export const SelfReviewsSwitch = () => {
  return (
    <LapeNewSwitch
      itemTypeProps={{
        avatar: (
          <Avatar
            variant="brand"
            bg="transparent"
            size={40}
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D259.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@3x.png',
            }}
          />
        ),
        title: (
          <HStack gap="s-8" align="center">
            <Text>Self reviews</Text>
            <RequiredBadge />
          </HStack>
        ),
        description:
          'Allows the employee to submit a review of their own performance and behaviours',
      }}
      name="enable_self_reviews"
      disabled // always required
    />
  )
}
