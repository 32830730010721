import React from 'react'
import { ActionButton, Avatar, Item, ItemSkeleton, Widget } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import {
  LinkedInJobBoardDetails,
  IndeedJobBoardDetails,
  MonsterJobBoardDetails,
} from '@src/pages/Forms/JobPosting/JobBoards/JobBoardDetails'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, JobPostingJobBoards } from '@src/interfaces/jobPosting'
import useIsCommercial from '@src/hooks/useIsCommercial'

export const JobBoards = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const { data: jobPostingSettings, isLoading: loadingJobPostingSettings } =
    useGetJobPostingSettings()
  const isCommercial = useIsCommercial()

  const canViewLinkedInPostings = permissions.includes(
    PermissionTypes.ViewLinkedInApiPosting,
  )
  const linkedinIntegration = featureFlags.includes(FeatureFlags.LinkedinIntegration)
  const indeedIntegration = featureFlags.includes(FeatureFlags.IndeedIntegration)
  const jobPostingHiringStages = featureFlags.includes(
    FeatureFlags.JobPostingHiringStages,
  )

  const publishToJobBoard = (jobBoardId: JobPostingJobBoards) =>
    values.publish_to_job_boards?.includes(jobBoardId)

  const showLinkedin =
    isCommercial &&
    jobPostingSettings?.enable_linkedin_integration &&
    canViewLinkedInPostings &&
    linkedinIntegration &&
    publishToJobBoard('linkedin')
  const showIndeed =
    jobPostingSettings?.indeed_connected &&
    indeedIntegration &&
    publishToJobBoard('indeed')
  const showMonster =
    jobPostingSettings?.enable_monster_integration && publishToJobBoard('monster')

  if (loadingJobPostingSettings) {
    return <ItemSkeleton />
  }
  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Newspaper" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Job boards</Item.Title>
        </Item.Content>
        <Item.Side>
          <ActionButton
            use={InternalLink}
            to={
              jobPostingHiringStages
                ? pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.PUBLISH, {
                    id: values.id,
                    action: 'edit',
                  })
                : !indeedIntegration
                ? pathToUrl(ROUTES.SETTINGS.JOBS.LIST)
                : pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)
            }
          >
            {jobPostingHiringStages ? 'Publishing settings' : 'Manage Integrations'}
          </ActionButton>
        </Item.Side>
      </Item>
      {showLinkedin && <LinkedInJobBoardDetails />}
      {showIndeed && <IndeedJobBoardDetails />}
      {showMonster && <MonsterJobBoardDetails />}
    </Widget>
  )
}
