import React from 'react'
import { chain, Color, Text, Token } from '@revolut/ui-kit'
import { formatDateTime, formatTimeAgo } from '@src/utils/format'
import {
  InterviewFeedbackInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'

export const getStageItemInfoProps = (
  stage: InterviewStageWithoutRoundInterface,
  feedback: InterviewFeedbackInterface | null,
) => {
  let text: React.ReactNode = null
  let color = Token.color.greyTone50

  if (feedback) {
    switch (feedback.status) {
      case 'completed':
        return {
          text: formatTimeAgo(feedback.updated_date_time),
          color,
        }
      case 'rejected': {
        return {
          text: feedback.scheduled_date_time ? (
            chain(
              `Scheduled for ${formatDateTime(feedback.scheduled_date_time)}`,
              <Text color={Token.color.red}>Rejected</Text>,
            )
          ) : (
            <Text color={Token.color.red}>Rejected</Text>
          ),
          color,
        }
      }
    }
  }

  switch (stage.scheduling_status) {
    case 'feedback_submitted': {
      return {
        text: feedback?.updated_date_time
          ? formatTimeAgo(feedback.updated_date_time)
          : stage.scheduling_status_display,
        color,
      }
    }
    case 'awaiting_feedback': {
      return {
        text: feedback?.scheduled_date_time
          ? chain(
              formatTimeAgo(feedback?.scheduled_date_time),
              <Text color={Token.color.orange}>{stage.scheduling_status_display}</Text>,
            )
          : stage.scheduling_status_display,
        color,
      }
    }
    case 'scheduling_expired':
    case 'offer_expired':
    case 'interview_passed': {
      return {
        text: stage.scheduling_status_display,
        color,
      }
    }
    case 'interview_scheduled': {
      return {
        text: feedback?.scheduled_date_time
          ? `Scheduled for ${formatDateTime(feedback.scheduled_date_time)}`
          : stage.scheduling_status_display,
        color,
      }
    }
    case 'pending_scheduling':
    case 'pending_candidate_response':
    case 'awaiting_interviewer_availability':
    case 'pending_cv_screening':
    case 'pending_candidate_signature': {
      return {
        text: stage.scheduling_status_display,
        color: Token.color.orange,
      }
    }
    case 'interviewer_rejected':
    case 'interview_cancelled':
    case 'offer_declined_recruiter':
    case 'offer_declined_candidate': {
      return {
        text: stage.scheduling_status_display,
        color: Token.color.red,
      }
    }
    case 'test_sent': {
      const testSentDate = stage?.online_test_result?.sent_date_time
      return {
        text: `${stage.scheduling_status_display}${
          testSentDate ? ` on ${formatDateTime(testSentDate)}` : ''
        }`,
        color,
      }
    }
    case 'test_completed':
      return {
        text: stage.online_test_result?.completed_date_time
          ? formatTimeAgo(stage.online_test_result.completed_date_time)
          : null,
        color,
      }
    case 'offer_signed': {
      return {
        text: stage.scheduling_status_display,
        color: Token.color.green,
      }
    }
    case 'offer_cancelled_recruiter': {
      return {
        text: stage.scheduling_status_display,
        color,
      }
    }
    default: {
      return {
        text,
        color,
      }
    }
  }
}

interface StageItemInfoProps {
  color: Color
  text: React.ReactNode
}

export const StageItemInfo = ({ color, text }: StageItemInfoProps) => {
  return (
    <Text variant="body2" color={color}>
      {text}
    </Text>
  )
}
