import React, { MouseEvent, useState } from 'react'
import NewLoadingButton from '@components/Button/NewLoadingButton'
import { Popup, Button, Header, Text, Portal } from '@revolut/ui-kit'

type Props = {
  onConfirm: () => Promise<any>
}

const HiringStageDeleteButton = ({ onConfirm }: Props) => {
  const [loading, setLoading] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)

  const onHandleClick = async () => {
    setOpenPopup(true)
  }

  const onClickConfirm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    try {
      setLoading(true)
      await onConfirm()
      setOpenPopup(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Portal>
        <Popup
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          variant="bottom-sheet"
        >
          <Header variant="bottom-sheet">
            <Header.Title>Remove this stage?</Header.Title>
          </Header>
          <Text use="p" variant="caption" color="grey-tone-50">
            Are you sure you want to remove this stage? This will update all the existing
            candidates that are going through the interview process for this
            specialisation/role
          </Text>
          <Popup.Actions horizontal>
            <Button
              variant="secondary"
              onClick={e => {
                e.stopPropagation()
                setOpenPopup(false)
              }}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              elevated
              pending={loading}
              disabled={loading}
              onClick={onClickConfirm}
            >
              Confirm
            </Button>
          </Popup.Actions>
        </Popup>
      </Portal>
      <NewLoadingButton
        p={0}
        style={{ fontWeight: 'normal' }}
        onSubmit={onHandleClick}
        variant="negative"
        useTextButton
      >
        Remove
      </NewLoadingButton>
    </>
  )
}

export default HiringStageDeleteButton
