import React, { createContext, useContext, useMemo } from 'react'
import {
  RootProvider,
  ThemeBackground,
  ThemeBackgroundsSet,
  ThemeMode,
  useTheme,
} from '@revolut/ui-kit'
import '@revolut/ui-kit/styles.css'

import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LocalStorageKeys } from '@src/store/auth/types'
import { useRouteMatch } from 'react-router-dom'
import { PUBLIC_ROUTES, ROUTES, WORKSPACES } from '@src/constants/routes'
import { BannerProvider } from './BannerProvider'
import { useTopNav } from '@src/features/TopNav/useTopNav'
import { useIsLandingPage } from '@src/pages/Landing/hooks/useIsLandingPage'

type ThemeContextValue = {
  themeSettingValue: ThemeMode
  setTheme: (value: ThemeMode) => void
  loginTheme: ThemeMode
  setLoginTheme: (value: 'light' | 'dark') => void
  setTopNavEnabled: (value: boolean) => void
  themeBackground?: ThemeBackground
  setThemeBackground: (background?: ThemeBackground) => void
}

const ThemeContext = createContext<ThemeContextValue | null>(null)

export const useAppTheme = () => {
  const context = useContext(ThemeContext)
  const { mode } = useTheme()

  if (context === null) {
    throw new Error('useAppTheme must be used within an UIKitWithThemeProvider')
  }
  return { ...context, theme: mode }
}

export const UIKitWithThemeProvider: React.FC = ({ children }) => {
  const { topNav, setEnabled } = useTopNav()

  const [savedThemeSettingValue, setThemeSettingValue] = useLocalStorage<
    ThemeMode | 'transparent'
  >(LocalStorageKeys.THEME, 'light', false)
  /** We saved "transparent" to localstorage, that's deprecated now, we need to convert it to "dark" */
  const themeSettingValue =
    savedThemeSettingValue === 'transparent' ? 'dark' : savedThemeSettingValue
  const [loginTheme, setLoginTheme] = useLocalStorage<'light' | 'dark'>(
    LocalStorageKeys.LOGIN_THEME,
    'dark',
  )
  const [themeBackground, setThemeBackground] = useLocalStorage<
    ThemeBackground | undefined
  >(LocalStorageKeys.THEME_BACKGROUND, ThemeBackgroundsSet.People[0])

  const isLoginThemeRoute = !!useRouteMatch({
    path: [
      ROUTES.LOGIN.MAIN,
      ROUTES.SIGNUP.MAIN,
      WORKSPACES.ANY,
      ROUTES.TWO_FACTOR_LOGIN,
    ],
  })
  const isLandingPage = useIsLandingPage()
  const isPublicRoute = !!useRouteMatch({ path: [PUBLIC_ROUTES.ANY] })

  const contextValue = useMemo<ThemeContextValue>(() => {
    return {
      themeSettingValue,
      setTheme: (mode: ThemeMode) => {
        setThemeSettingValue(mode)
      },
      loginTheme,
      setLoginTheme: (mode: 'light' | 'dark') => {
        setLoginTheme(mode)
      },
      setTopNavEnabled: setEnabled,
      themeBackground,
      setThemeBackground,
    }
  }, [
    themeSettingValue,
    setThemeSettingValue,
    setLoginTheme,
    loginTheme,
    setEnabled,
    themeBackground,
    setThemeBackground,
  ])

  const background = useMemo(() => {
    return isLoginThemeRoute ? undefined : themeBackground
  }, [themeBackground, isLoginThemeRoute])

  return (
    <ThemeContext.Provider value={contextValue}>
      <RootProvider
        topNav={isPublicRoute ? undefined : topNav}
        mode={
          isPublicRoute
            ? 'light'
            : isLandingPage
            ? 'dark'
            : isLoginThemeRoute
            ? loginTheme
            : contextValue.themeSettingValue
        }
        background={isPublicRoute || isLandingPage ? undefined : background}
      >
        <BannerProvider>{children}</BannerProvider>
      </RootProvider>
    </ThemeContext.Provider>
  )
}
