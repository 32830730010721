import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Badge, HStack, Icon, Text, Token } from '@revolut/ui-kit'

export const RequiredBadge = () => {
  return (
    <Badge bg={Token.color.background} color={Token.color.foreground}>
      <HStack gap="s-4" align="center" px="s-4">
        <Icon name="ChangePasscode" size={14} />
        <Text variant="emphasis4">
          <FormattedMessage id="common.required" defaultMessage="Required" />
        </Text>
      </HStack>
    </Badge>
  )
}
