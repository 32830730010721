import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Button,
  Caption,
  Cell,
  Header,
  HStack,
  Paragraph,
  Popup,
  Radio,
  Subheader,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { CycleTypes } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import {
  ReviewCyclesInterface,
  ReviewCyclesPreviewInterface,
} from '@src/interfaces/reviewCycles'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import {
  useCreateAdHocCycle,
  useCreateReviewCycleFromTemplate,
  useGetReviewCycleTemplates,
} from '@src/api/reviewCycles'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { CycleOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { useNavigation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useNavigation'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
}

export const CreateReviewCyclePopup = ({ isOpen, onClose }: Props) => {
  const [cycleType, setCycleType] = useState<CycleTypes>(CycleTypes.Regular)
  const [cycleTemplate, setCycleTemplate] = useState<ReviewCyclesPreviewInterface | null>(
    null,
  )
  const [parentCycle, setParentCycle] = useState<CycleOption | null>(null)
  const { toReviewCycleDetails } = useNavigation()
  const showStatusPopup = useShowStatusPopup()

  const { data: cycleTemplates } = useGetReviewCycleTemplates()
  const { options: cycleOptions } = useFetchOptions<CycleOption>(
    selectorKeys.review_cycles,
  )
  const { mutateAsync: createRegularCycle, isLoading: isRegularCycleCreating } =
    useCreateReviewCycleFromTemplate()
  const { mutateAsync: createAdHocCycle, isLoading: isAdHocCycleCreating } =
    useCreateAdHocCycle()

  const cycleTemplateOptions: RadioSelectOption<ReviewCyclesPreviewInterface>[] =
    useMemo(() => {
      if (!cycleTemplates?.length) {
        return []
      }

      return cycleTemplates.map(template => ({
        value: template,
        label: template.name,
        disabled: !template.enabled,
      }))
    }, [cycleTemplates])

  useEffect(() => {
    setParentCycle(cycleOptions[0]?.value ?? null)
  }, [cycleOptions])

  useEffect(() => {
    const [preselectedCycleTemplateOption] = cycleTemplateOptions.filter(
      ({ disabled }) => !disabled,
    )

    setCycleTemplate(preselectedCycleTemplateOption?.value ?? null)
  }, [cycleTemplateOptions])

  const handleConfirm = async () => {
    try {
      let reviewCycle: ReviewCyclesInterface

      if (cycleType === CycleTypes.Regular && cycleTemplate) {
        const { data } = await createRegularCycle({
          name: cycleTemplate.name,
          cycle_template_id: cycleTemplate.template_id,
        })
        reviewCycle = data
      } else if (cycleType === CycleTypes.AdHoc && parentCycle) {
        const { data } = await createAdHocCycle({
          parent_cycle_id: parentCycle.id,
        })
        reviewCycle = data
      } else {
        return
      }

      showStatusPopup({
        title: (
          <FormattedMessage
            defaultMessage="New performance cycle created"
            id="performance.reviews.cycles.create.success"
          />
        ),
        onClose: () => {
          onClose()
          toReviewCycleDetails(reviewCycle)()
        },
      })
    } catch (err) {
      showStatusPopup({
        title: (
          <FormattedMessage
            defaultMessage="Failed to create performance cycle"
            id="performance.reviews.cycles.create.error"
          />
        ),
        description: getMessageFromApiError(err),
        status: 'error',
        onClose,
      })
    }
  }

  return (
    <Popup size="sm" open={isOpen} onClose={onClose}>
      <Header>
        <Header.Title>
          <Header.CloseButton />
          <FormattedMessage
            defaultMessage="Add Review Cycle"
            id="performance.reviews.cycles.create.label"
          />
        </Header.Title>
        <Header.Description>
          <FormattedMessage
            defaultMessage="Create a new review cycle to evaluate performance of your employees."
            id="performance.reviews.cycles.create.popup.description"
          />
        </Header.Description>
      </Header>
      <Subheader>
        <Subheader.Title>
          <FormattedMessage
            defaultMessage="Type"
            id="performance.reviews.cycles.create.popup.type.title"
          />
        </Subheader.Title>
      </Subheader>
      <VStack space="s-8">
        <Cell use="label" onClick={() => setCycleType(CycleTypes.Regular)}>
          <HStack space="s-16" align="center">
            <Radio checked={cycleType === CycleTypes.Regular} />
            <VStack space="s-4">
              <Paragraph>
                <FormattedMessage
                  defaultMessage="Regular cycle"
                  id="performance.reviews.cycles.regular.option.title"
                />
              </Paragraph>
              <Caption color={Token.color.greyTone50}>
                <FormattedMessage
                  defaultMessage="Create a standard review cycle using fixed dates for future performance reviews."
                  id="performance.reviews.cycles.regular.option.description"
                />
              </Caption>
            </VStack>
          </HStack>
        </Cell>
        <Cell use="label" onClick={() => setCycleType(CycleTypes.AdHoc)}>
          <HStack space="s-16" align="center">
            <Radio checked={cycleType === CycleTypes.AdHoc} />
            <VStack space="s-4">
              <Paragraph>
                <FormattedMessage
                  defaultMessage="Ad-hoc cycle"
                  id="performance.reviews.cycles.adhoc.option.title"
                />
              </Paragraph>
              <Caption color={Token.color.greyTone50}>
                <FormattedMessage
                  defaultMessage="Set up a custom review cycle with manual dates, ideal for special cases like testing features or unique assessments."
                  id="performance.reviews.cycles.adhoc.option.description"
                />
              </Caption>
            </VStack>
          </HStack>
        </Cell>
      </VStack>
      <Subheader>
        <Subheader.Title>
          <VStack>
            {cycleType === CycleTypes.Regular ? (
              <FormattedMessage
                defaultMessage="Select Cycle"
                id="performance.reviews.cycles.select.label"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Copy settings from"
                id="performance.reviews.cycles.create.popup.select.parent.title"
              />
            )}
            <Caption color={Token.color.greyTone50}>
              {cycleType === CycleTypes.Regular ? (
                <FormattedMessage
                  defaultMessage="Choose the period for this performance review cycle."
                  id="performance.reviews.cycles.create.popup.select.template.description"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Select a cycle to inherit settings for this ad-hoc review cycle."
                  id="performance.reviews.cycles.create.popup.select.parent.description"
                />
              )}
            </Caption>
          </VStack>
        </Subheader.Title>
      </Subheader>
      {cycleType === CycleTypes.Regular && (
        <RadioSelectInput<ReviewCyclesPreviewInterface>
          label="Cycle"
          value={cycleTemplate}
          options={cycleTemplateOptions}
          hasError={!cycleTemplate}
          message={
            !cycleTemplate && (
              <FormattedMessage
                defaultMessage="This field is required"
                id="validation.field.required"
              />
            )
          }
          required
          onChange={value => setCycleTemplate(value)}
        />
      )}
      {cycleType === CycleTypes.AdHoc && (
        <RadioSelectInput<CycleOption>
          label="Cycle"
          value={parentCycle}
          options={cycleOptions}
          hasError={!parentCycle}
          message={
            !parentCycle && (
              <FormattedMessage
                defaultMessage="This field is required"
                id="validation.field.required"
              />
            )
          }
          required
          onChange={value => setParentCycle(value)}
        />
      )}
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          <FormattedMessage defaultMessage="Cancel" id="common.cancel" />
        </Button>
        <Button
          variant="primary"
          pending={isRegularCycleCreating || isAdHocCycleCreating}
          disabled={
            (cycleType === CycleTypes.Regular && !cycleTemplate) ||
            (cycleType === CycleTypes.AdHoc && !parentCycle)
          }
          onClick={handleConfirm}
        >
          <FormattedMessage defaultMessage="Confirm" id="common.confirm" />
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
