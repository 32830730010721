import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { Checkbox, CheckboxGroup, Text, VStack, Widget, Token } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { integrationNameMap } from '@src/pages/Hub/Integrations/components/constants'
import { MergeConnectionInterface } from '@src/interfaces/integrations'
import {
  useGetMergeConnections,
  useUpdateMergeConnection,
  useInvalidateMergeConnections,
} from '@src/api/integrations'
import { PageNotFoundWidget } from '@src/features/Errors/components/Page/PageNotFoundWidget'
import PageLoading from '@components/PageLoading/PageLoading'
import { connect } from 'lape'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { goBack } from '@src/actions/RouterActions'

export const FieldMappingForm = () => {
  const { values } = useLapeContext<MergeConnectionInterface>()
  const invalidateConnections = useInvalidateMergeConnections()

  const integrationName =
    integrationNameMap[values.integration_type] || values.integration_type

  const afterSubmit = async () => {
    await invalidateConnections()
    goBack()
  }

  return (
    <PageWrapper>
      <PageHeader
        title={`Sync ${integrationName} and Revolut People`}
        subtitle={`Specify which fields you want to fetch from ${integrationName} to synchronise with Revolut People`}
        backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
      />
      <PageBody>
        <Widget p="s-16">
          <Text pb="s-16" use="div" color={Token.color.greyTone50}>
            Data fields · {values?.selected_fields.length} of{' '}
            {values?.available_fields.length}
          </Text>
          <CheckboxGroup
            onChange={value => {
              values.selected_fields = values.available_fields
                .filter(field => value.includes(field.id))
                .map(({ id, name }) => ({
                  id,
                  name,
                }))
            }}
            value={values?.selected_fields.map(field => field.id)}
          >
            {group => (
              <VStack space="s-32">
                {values?.available_fields.map(field => (
                  <Checkbox
                    {...group.getInputProps({ value: field.id })}
                    disabled={field.required}
                    key={field.id}
                  >
                    <Checkbox.Label>{field.name}</Checkbox.Label>
                    {field.required ? (
                      <Checkbox.Description>Required</Checkbox.Description>
                    ) : null}
                  </Checkbox>
                ))}
              </VStack>
            )}
          </CheckboxGroup>
        </Widget>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Mapping updated successfully"
          useValidator
          onAfterSubmit={afterSubmit}
        />
      </PageActions>
    </PageWrapper>
  )
}

export const FieldMapping = connect(() => {
  const { id } = useParams<{ id: string }>()
  const { data: integrations, isLoading } = useGetMergeConnections()
  const { mutateAsync: update } = useUpdateMergeConnection()

  const handleSubmit = async (integration: MergeConnectionInterface) => {
    return update([
      integration?.id,
      { selected_fields: integration.selected_fields },
    ]).then(resp => resp.data)
  }

  if (isLoading) {
    return <PageLoading />
  }

  const currentIntegration = integrations?.results?.find(int => int.id === id)

  if (!currentIntegration) {
    return <PageNotFoundWidget />
  }

  return (
    <LapeForm<MergeConnectionInterface>
      initialValues={currentIntegration}
      onSubmit={({ values }) => handleSubmit(values)}
    >
      <FieldMappingForm />
    </LapeForm>
  )
})
