import React, { useState } from 'react'
import {
  Avatar,
  Button,
  chain,
  Header,
  HStack,
  Icon,
  Item,
  Popup,
  Radio,
  Subheader,
  Text,
  TextArea,
  VStack,
} from '@revolut/ui-kit'
import { getInitials } from '@src/utils/employees'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { CellInsertParams } from '@src/interfaces/data'
import { TalentUIInterface } from '../types'
import { FinalGrade } from '@src/interfaces/performance'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { isString } from 'lodash'
import { IdAndName } from '@src/interfaces'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
  data: CellInsertParams<TalentUIInterface>['data']
  gradeOptions: {
    id: FinalGrade
    name: string
    tooltipText?: string
    disabled?: boolean
  }[]
  requireJustification: boolean
  initialSelectedGrade: IdAndName<FinalGrade> | null
  gradeSuggestion: FinalGrade | undefined
  onConfirm: (selectedGrade: FinalGrade, justification: string) => void
}

export const SetGradePopup = ({
  isOpen,
  onClose,
  data,
  initialSelectedGrade,
  gradeOptions,
  requireJustification,
  gradeSuggestion,
  onConfirm,
}: Props) => {
  const [justificationInput, setJustificationInput] = useState('')
  const [error, setError] = useState('')
  const [selectedGradeValue, setSelectedGradeValue] = useState<{
    id: FinalGrade
    name: string
  } | null>(initialSelectedGrade)
  return (
    <Popup open={isOpen} onClose={onClose}>
      <Header>
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Calibrate grade</Header.Title>
        <Header.Description>
          Set up an exit strategy to automatically close your position when the specified
          conditions are met
        </Header.Description>
      </Header>
      <Item>
        <Item.Avatar>
          <Avatar
            image={data.employee.avatar}
            label={getInitials(data.employee.full_name)}
          />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{data.employee.full_name}</Item.Title>
          <Item.Description>
            {chain(data.specialisation?.name, data.seniority?.name)}
          </Item.Description>
        </Item.Content>
      </Item>
      <Subheader>Grade</Subheader>
      <VStack gap="s-16">
        <RadioSelectInput
          label="Select grade"
          value={selectedGradeValue}
          options={gradeOptions?.map(option => ({
            key: option.id,
            id: option.id,
            disabled: option.disabled,
            tooltipText: option.tooltipText,
            label: option.name || '-',
            value: { id: option.id, name: option.name },
          }))}
          searchable={false}
          onChange={val => {
            setSelectedGradeValue(val)
          }}
        >
          {option => (
            <HStack
              gap="s-8"
              style={{
                pointerEvents: 'all' /* so that disabled can be hovered for tooltip */,
              }}
            >
              <Radio
                checked={option.key === selectedGradeValue?.id}
                aria-label={option.value.name || ''}
              />
              <Text>
                {option.label} {gradeSuggestion === option.key && '(recommended)'}
              </Text>
              {'tooltipText' in option && isString(option.tooltipText) ? (
                <Tooltip text={option.tooltipText} placement="top">
                  <Icon name="Info" />
                </Tooltip>
              ) : null}
            </HStack>
          )}
        </RadioSelectInput>
        <TextArea
          label={`Justification ${
            gradeSuggestion === selectedGradeValue?.id ? '(Optional)' : ''
          }`}
          rows={3}
          value={justificationInput}
          errorMessage={error}
          invalid={!!error}
          onChange={e => {
            setJustificationInput(e.currentTarget.value)
            setError('')
          }}
        />
      </VStack>
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button
          elevated
          onClick={async () => {
            if (requireJustification && !justificationInput.length) {
              // BE Doesn't validate this
              setError('Justification required')
              return
            }
            if (selectedGradeValue) {
              onConfirm(selectedGradeValue.id, justificationInput)
            }
          }}
        >
          Confirm
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
