import React from 'react'
import { Tag, Token, useTooltip, Tooltip } from '@revolut/ui-kit'

export const SyncedTag = () => {
  const tooltip = useTooltip()

  return (
    <>
      <Tag variant="outlined" color={Token.color.success} {...tooltip.getAnchorProps()}>
        Synced
      </Tag>
      <Tooltip {...tooltip.getTargetProps()} maxWidth={240}>
        This employee is automatically imported and synced with an external platform. It
        cannot be deleted, and some fields are read-only.
      </Tooltip>
    </>
  )
}
