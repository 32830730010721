import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isBefore } from 'date-fns'
import { employeesRequestsNew } from '@src/api/employees'
import { useGetPipCheckpoints } from '@src/api/pip'
import { useGetPerformanceSelector } from '@src/api/performance'
import { Statuses } from '@src/interfaces'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ReviewCategory } from '@src/interfaces/performance'
import { CheckpointType } from '@src/interfaces/probationReview'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import PageLoading from '@src/components/PageLoading/PageLoading'
import ValidationPre from '@src/features/TabBarNavigation/ValidationPre'
import { PipGoals } from '@src/pages/Forms/PipV2/PipGoals'
import { GenericOverview } from '@src/pages/Forms/Overview'
import { Checkpoint } from '@src/pages/Forms/Overview/Checkpoint/Checkpoint'
import { Committee } from '@src/pages/Forms/Overview/Committee/Committee'
import PipSettings from '@src/pages/Forms/Overview/Settings/PipSettings'

const isCpCompleted = (date?: string) => {
  if (!date) {
    return false
  }

  return isBefore(new Date(date), new Date())
}

export const PipOverview = () => {
  const params = useParams<{ employeeId: string; cycleId: string }>()
  const { employeeId, cycleId } = params
  const employeeIdValid = employeeId && !isNaN(parseFloat(employeeId))

  const [loading, setLoading] = useState(employeeIdValid)
  const [data, setData] = useState<EmployeeInterface>()
  const { data: checkpoints, refetch } = useGetPipCheckpoints(
    employeeIdValid ? employeeId : null,
    cycleId,
  )

  const { data: performanceSelectorData } = useGetPerformanceSelector(employeeId, {
    exclude_adhoc: true,
  })
  const permissions = performanceSelectorData?.find(
    cycle => cycle.id === cycleId,
  )?.extra_permissions_by_version

  useEffect(() => {
    if (employeeIdValid) {
      employeesRequestsNew
        .get({ id: employeeId })
        .then(res => {
          setData(res.data)
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }
  }, [])

  if (loading) {
    return <PageLoading />
  }

  const committeeCp = checkpoints?.decision_checkpoints?.find(
    item => item.checkpoint_type === CheckpointType.Decision,
  )
  const committeeDate = committeeCp?.checkpoint_date_time
  const committeeCompleted = isCpCompleted(committeeDate)

  const reviewCheckpoints =
    checkpoints?.checkpoints.filter(
      item => item.checkpoint_type === CheckpointType.Review,
    ) || []

  const checkpointTabs = reviewCheckpoints?.map(checkpoint => {
    return {
      title: `Checkpoint ${checkpoint.checkpoint_number}`,
      preTitle: (
        <ValidationPre
          isValid={isCpCompleted(checkpoint?.checkpoint_date_time)}
          isVisible
        />
      ),
      path: pathToUrl(ROUTES.FORMS.PIP_OVERVIEW.CHECKPOINT, {
        ...params,
        id: checkpoint.checkpoint_number,
      }),
      url: pathToUrl(ROUTES.FORMS.PIP_OVERVIEW.CHECKPOINT, {
        ...params,
        id: checkpoint.checkpoint_number,
      }),
      component: Checkpoint,
      checkpointNumber: checkpoint.checkpoint_number,
      disabled: false,
    }
  })

  const tabs = [
    {
      title: 'PIP goals',
      preTitle: (
        <ValidationPre
          isPending={checkpoints?.checkpoints?.[0]?.goals?.status !== Statuses.approved}
          isValid
          isVisible
        />
      ),
      path: ROUTES.FORMS.PIP_OVERVIEW.PIP_GOALS,
      url: pathToUrl(ROUTES.FORMS.PIP_OVERVIEW.PIP_GOALS, params),
      component: PipGoals,
      disabled: false,
    },
    ...checkpointTabs,
    {
      title: 'Committee',
      preTitle: <ValidationPre isValid={committeeCompleted} isVisible />,
      path: ROUTES.FORMS.PIP_OVERVIEW.COMMITTEE,
      url: pathToUrl(ROUTES.FORMS.PIP_OVERVIEW.COMMITTEE, params),
      component: Committee,
      disabled: !committeeCp,
    },
    {
      title: 'Settings',
      preTitle: <ValidationPre isValid isVisible />,
      path: ROUTES.FORMS.PIP_OVERVIEW.CYCLE_SETTINGS,
      url: pathToUrl(ROUTES.FORMS.PIP_OVERVIEW.CYCLE_SETTINGS, params),
      component: PipSettings,
    },
  ].filter(tab => !tab.disabled)

  if (!data) {
    return null
  }

  return (
    <GenericOverview
      category={ReviewCategory.PIP_V2}
      checkpoints={checkpoints}
      refetch={refetch}
      cycleId={cycleId}
      data={data}
      extraPermissions={permissions}
      tabs={tabs}
      performanceCycles={performanceSelectorData}
    />
  )
}
