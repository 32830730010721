import React, { useState } from 'react'
import { useParams } from 'react-router'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { reviewCyclesRequestsNew } from '@src/api/reviewCycles'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { EditFormContent } from '@src/features/ReviewCycle/PerformanceReviewCycle/CalibrationLogic/EditCalibrationLogic/EditFormContent'
import { ValidationResult } from '@src/features/ReviewCycle/PerformanceReviewCycle/CalibrationLogic/EditCalibrationLogic/validation'

export const EditCalibrationLogicPage = () => {
  const params = useParams<{ id: string }>()
  const [isEnabled, setIsEnabled] = useState(true)

  const backUrl = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, params)

  const handleOnValidate = (validationResult: ValidationResult) => {
    setIsEnabled(validationResult.isValid)
  }

  return (
    <Form api={reviewCyclesRequestsNew} disableDataCleanup>
      <PageWrapper>
        <PageHeader title="Calculation & Calibration logic" backUrl={backUrl} />
        <PageBody>
          <EditFormContent handleOnValidate={handleOnValidate} />
        </PageBody>
        <PageActions>
          <NewSaveButtonWithPopup
            afterSubmitUrl={backUrl}
            disabled={!isEnabled}
            useValidator
          />
        </PageActions>
      </PageWrapper>
    </Form>
  )
}
