import React from 'react'
import { VStack, Text, HStack, Avatar } from '@revolut/ui-kit'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { RecommendedBadge } from './RecommendedBadge'

interface Props {
  disabled?: boolean
  descriptionSuffix?: React.ReactNode
}

export const PeerReviewsSwitch = ({ disabled, descriptionSuffix }: Props) => {
  return (
    <LapeNewSwitch
      aria-label="Peer reviews"
      itemTypeProps={{
        avatar: (
          <Avatar
            variant="brand"
            bg="transparent"
            size={40}
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D259.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@3x.png',
            }}
          />
        ),
        title: (
          <HStack gap="s-8" align="center">
            <Text>Peer reviews</Text>
            <RecommendedBadge />
          </HStack>
        ),
        description: (
          <VStack gap="s-8">
            <Text>Allow peers to review each other</Text>
            {descriptionSuffix}
          </VStack>
        ),
      }}
      name="enable_peer_reviews"
      disabled={disabled}
    />
  )
}
