import React from 'react'
import { getAboveBelowSuggestedGrades } from '../utils'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { Icon, Token, VStack, Text, HStack } from '@revolut/ui-kit'

interface Props {
  attitude: ReturnType<typeof getAboveBelowSuggestedGrades>
  calibratorName?: string
  justification?: string | null
}

export const AdjustedTooltipWithIcon = ({
  attitude,
  calibratorName,
  justification,
}: Props) => {
  if (attitude === 'neutral') {
    return null
  }
  return (
    <Tooltip
      placement="left"
      backgroundColor={Token.color.popoverBackground}
      body={
        <VStack p="s-12" width={275} color={Token.color.foreground} gap="s-2">
          <Text variant="emphasis2">
            Calibrated {attitude === 'above' ? 'Up' : 'Down'}
          </Text>
          {calibratorName && (
            <HStack gap="s-2">
              <Icon name="Profile" size={16} />
              <Text color={Token.color.greyTone50}>{calibratorName}</Text>
            </HStack>
          )}
          {justification && (
            <Text color={Token.color.greyTone50} variant="body3">
              {justification}
            </Text>
          )}
        </VStack>
      }
    >
      <Icon
        name={attitude === 'above' ? 'ArrowUp' : 'ArrowDown'}
        size={16}
        color={attitude === 'above' ? Token.color.success : Token.color.red}
      />
    </Tooltip>
  )
}
