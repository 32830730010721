import { AxiosPromise } from 'axios'

import { FetchDataQueryInterface } from '@src/interfaces/data'
import {
  ApiVersion,
  GetRequestData,
  GetRequestInterface,
  FieldOptions,
} from '@src/interfaces'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { apiWithoutHandling } from '@src/api'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getEditableTable =
  <T>(
    apiEndpoint: string,
    apiVersion?: ApiVersion,
    dataTransform?: (data: GetRequestData<T>) => GetRequestInterface<ImportInterface<T>>,
  ) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ImportInterface<T>>> => {
    return apiWithoutHandling
      .get<GetRequestInterface<{ id: number }>>(
        apiEndpoint,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
        apiVersion,
      )
      .then(response => {
        if (dataTransform) {
          return {
            ...response,
            data: dataTransform(response.data as GetRequestData<T>),
          }
        }
        return {
          ...response,
          data: {
            ...response.data,
            results: response.data.results.map(row => ({
              id: row.id,
              data: row as unknown as T & { field_options?: FieldOptions },
              errors: {},
              state: { id: 'pending' as const, name: 'Pending' },
              error_message: null,
              loading: {},
            })),
          },
        }
      })
  }

export const editEditableTableCell = <T>(
  apiEndpoint: string,
  entityId: number,
  data: T,
  apiVersion?: ApiVersion,
) =>
  apiWithoutHandling.patch<T>(`${apiEndpoint}/${entityId}`, data, undefined, apiVersion)

export const deleteEditableTableRow = (
  apiEndpoint: string,
  rowId: number,
  apiVersion?: ApiVersion,
) => apiWithoutHandling.delete(`${apiEndpoint}/${rowId}`, undefined, apiVersion)
