import React, { useEffect, useState } from 'react'
import pluralize from 'pluralize'
import { useSelector } from 'react-redux'
import { FilterButton, MoreBar } from '@revolut/ui-kit'

import { StatFilters } from '@components/StatFilters/StatFilters'
import { useSelectableTableStats } from '@components/StatFilters/hooks'
import { useTable } from '@components/TableV2/hooks'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { getContracts, getContractsStats } from '@src/api/contracts'
import { getFilteredContractsSelector } from '@src/api/contractsBulkEdit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { getSelectCellConfig } from '@src/components/TableV2/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import { LOCAL_STORAGE } from '@src/constants/api'
import {
  contractEndAtColumn,
  contractEntityColumn,
  contractLocationColumn,
  contractPaygroupColumn,
  contractRankColumn,
  contractSeniorityColumn,
  contractStartAtColumn,
  contractStatusColumn,
  contractTermColumn,
  documentsColumn,
  employeeColumn,
  employeeTypeColumn,
  ownerColumn,
  specialisationRoleNameColumn,
} from '@src/constants/columns/contracts'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { BulkEditData } from '@src/interfaces/bulkEdit'
import { ContractsStatsInterface } from '@src/interfaces/contracts'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeContractsInterface } from '@src/interfaces/employees'
import { statsConfig } from '@src/pages/People/PeopleSubTabs/Contracts/ContractsTable'
import { selectUser } from '@src/store/auth/selectors'
import { useIsMount } from '@src/utils/isMount'
import { pathToUrl } from '@src/utils/router'

const Row: RowInterface<EmployeeContractsInterface> = {
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...employeeColumn,
      width: 140,
    },
    {
      ...employeeTypeColumn,
      width: 140,
    },
    {
      ...specialisationRoleNameColumn,
      width: 140,
    },
    {
      ...contractSeniorityColumn,
      width: 120,
    },
    {
      ...contractRankColumn,
      width: 100,
    },
    {
      ...contractLocationColumn,
      width: 140,
    },
    {
      ...contractEntityColumn,
      width: 130,
    },
    {
      ...contractPaygroupColumn,
      width: 140,
    },
    {
      ...contractTermColumn,
      width: 140,
    },
    {
      ...contractStartAtColumn,
      width: 140,
    },
    {
      ...contractEndAtColumn,
      width: 140,
    },
    {
      ...contractStatusColumn,
      width: 140,
    },
    {
      ...documentsColumn,
      width: 100,
    },
    {
      ...ownerColumn,
      width: 140,
    },
  ],
}

const SelectContractTable = () => {
  const user = useSelector(selectUser)

  const [showMyReports, setShowMyReports] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_REPORTS,
    false,
  )

  const isMount = useIsMount()

  const getFilterByLineManager = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'line_manager__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters = []

    if (showMyReports) {
      filters.push(getFilterByLineManager(true))
    }

    return filters
  }

  const sortBy = [
    {
      sortBy: 'status',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
    {
      sortBy: 'employee_type',
      direction: SORT_DIRECTION.ASC,
      nonResettable: true,
    },
    {
      sortBy: 'full_name',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  const table = useTable<EmployeeContractsInterface, ContractsStatsInterface>(
    { getItems: getContracts, getStats: getContractsStats },
    getInitialFilters(),
    sortBy,
  )
  const statFiltersProps = useSelectableTableStats<
    EmployeeContractsInterface,
    ContractsStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
    totalKey: 'total',
  })

  const [bulkEditData, setBulkEditData] = useState<BulkEditData>({
    selectedIds: [],
    isAllSelected: false,
    filters: [],
  })

  useEffect(() => {
    setBulkEditData({
      selectedIds: [],
      isAllSelected: false,
      filters: [],
    })
  }, [])

  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<EmployeeContractsInterface>>()

  useEffect(() => {
    const selectedIds = (
      selectedData?.selectedRowsIds ? Array.from(selectedData?.selectedRowsIds) : []
    ).map(id => Number(id))

    setBulkEditData({
      selectedIds,
      isAllSelected: selectedData?.isAllSelected || false,
      filters: table.filterBy,
    })
  }, [selectedData?.selectedRowsIds, selectedData?.isAllSelected, table.filterBy])

  const onToggleMyReports = () => {
    setShowMyReports(!showMyReports)
    table.onFilterChange(getFilterByLineManager(!showMyReports))
  }

  useEffect(() => {
    if (isMount) {
      return
    }

    if (
      !bulkEditData.isAllSelected &&
      Array.from(selectedData?.selectedRowsIds || []).length === 0
    ) {
      setBulkEditData({
        ...bulkEditData,
        selectedIds: [],
      })
    }

    const updateIds = async () => {
      const selectedContracts = await getFilteredContractsSelector(bulkEditData.filters)
      const arrayOfIds = selectedContracts.data.options.map(contract =>
        Number(contract.id),
      )
      setBulkEditData({
        ...bulkEditData,
        selectedIds: arrayOfIds,
      })
    }

    if (bulkEditData.isAllSelected) {
      updateIds()
    }
  }, [bulkEditData.isAllSelected])

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <StatFilters {...statFiltersProps} />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <MoreBar.Action
            use={InternalLink}
            // @ts-expect-error object works fine here, but UI kit expects string
            to={getLocationDescriptor(
              pathToUrl(ROUTES.FORMS.BULK_EDIT_CONTRACTS.EDIT),
              bulkEditData,
            )}
            style={bulkEditData.selectedIds.length ? {} : { pointerEvents: 'none' }}
            disabled={!bulkEditData.selectedIds.length}
            useIcon="Pencil"
            variant="accent"
          >
            Edit {pluralize('contract', bulkEditData.selectedIds.length, true)} in bulk
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <FilterButton onClick={onToggleMyReports} active={showMyReports}>
          My reports
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <SelectTableWrapper
          enabled
          onChange={setSelectedData}
          filters={table.filterBy}
          tableDataLength={table.data.length}
          tableData={table.data}
        >
          <AdjustableTable<EmployeeContractsInterface, ContractsStatsInterface>
            name={TableNames.SelectContracts}
            useWindowScroll
            dataType="Contract"
            row={Row}
            {...table}
            noDataMessage="Contracts will appear here."
          />
        </SelectTableWrapper>
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default SelectContractTable
