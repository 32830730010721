import React, { useRef, useState } from 'react'
import {
  ActionButton,
  Box,
  DragAndDrop,
  Dropdown,
  Grid,
  Token,
  useToggle,
  VStack,
} from '@revolut/ui-kit'
import { InterviewType } from '@src/interfaces/interviewTool'
import { getHiringSectionIndexes } from '@src/pages/Settings/HiringProcess/utils'
import { useGetHiringProcessSettings } from '@src/api/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  EnumSectionOptionInterface,
  InterviewScorecardEnumSectionIdCheckbox,
  InterviewScorecardEnumSectionIdOption,
  InterviewScorecardEnumSectionIdDropdown,
  InterviewScorecardEnumSectionIdText,
  InterviewScorecardEnumSectionIdThumbs,
  InterviewScorecardTemplateInterface,
  InterviewScorecardTemplateSectionInterface,
} from '@src/interfaces/interviewScorecardTemplates'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import InterviewScorecardTemplateSection from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/InterviewScorecardTemplateSection'
import { usePossibleSectionOptions } from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/utils'
import LocationsQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/LocationsQuestionSection'
import SalaryQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/SalaryQuestionSection'
import RightToWorkQuestionSection from '@src/pages/Settings/Candidates/ScreenCallScorecards/components/RightToWorkQuestionSection'
import { EvaluationSection } from './EvaluationSection'
import { move } from '@src/utils/move'

interface AddEvaluationProps {
  stageType?: InterviewType | null
}

export const AddEvaluation = ({ stageType }: AddEvaluationProps) => {
  const { values, errors, submit, isSubmitting } =
    useLapeContext<InterviewScorecardTemplateInterface>()

  const addEvaluationAnchorRef = useRef(null)
  const [openAddEvaluation, toggleAddEvaluation] = useToggle()

  const [editIndex, setEditIndex] = useState<number | undefined>()
  const [activeDragId, setActiveDragId] = useState<string | number | null>(null)

  const possibleSectionOptions = usePossibleSectionOptions(values.scorecard_type?.id)

  const showErrorPopup = useErrorPopup()

  const interviewFeedbackSections = values.sections || []

  const isScreenCall = stageType === 'screen_call'
  const { data: settings } = useGetHiringProcessSettings(!isScreenCall)
  const { predefinedSectionsLength, globalSectionsLength } = getHiringSectionIndexes(
    stageType === 'screen_call',
    settings,
  )

  const handleDeleteSection = async (
    section: InterviewScorecardTemplateSectionInterface,
  ) => {
    if (interviewFeedbackSections) {
      const oldSections = [...interviewFeedbackSections]
      try {
        const { id } = section
        values.sections = interviewFeedbackSections.filter(curr => curr.id !== id)
        await submit()
        setEditIndex(undefined)
      } catch (error) {
        values.sections = oldSections
        showErrorPopup.show({
          error,
          fallbackTitle: 'There was a error removing section',
        })
      }
    }
  }

  const handleEdit = async () => {
    if (interviewFeedbackSections) {
      try {
        await submit()
        setEditIndex(undefined)
      } catch (error) {
        showErrorPopup.show({
          error,
          fallbackTitle: 'There was a error editing section',
        })
      }
    }
  }

  const handleAddSection = (sectionType: EnumSectionOptionInterface) => {
    values.sections = [
      ...interviewFeedbackSections,
      { section_type: sectionType, questions: [] },
    ]
    setEditIndex(values.sections.length - 1)
    toggleAddEvaluation.off()
  }

  const handleOrderChange = async (startIndex: number, endIndex: number) => {
    const oldOrder = [...interviewFeedbackSections]
    const newOrder = move(interviewFeedbackSections, startIndex, endIndex)
    values.sections = newOrder
    try {
      await submit()
    } catch (error) {
      values.sections = oldOrder
      showErrorPopup.show({
        error,
        fallbackTitle: 'There was a error ordering section',
      })
    }
  }

  const renderPredefinedSections = () => {
    if (!(predefinedSectionsLength || globalSectionsLength)) {
      return null
    }
    return (
      <Grid gap={16}>
        {settings?.enable_location_collecting && (
          <LocationsQuestionSection optional={settings.is_location_optional} locked />
        )}
        {settings?.enable_compensation_collecting && (
          <SalaryQuestionSection optional={settings.is_compensation_optional} locked />
        )}
        {settings?.enable_right_to_work_collecting && (
          <RightToWorkQuestionSection
            optional={settings.is_right_to_work_optional}
            locked
          />
        )}
        {settings?.global_sections?.map((section, id) => {
          return (
            <InterviewScorecardTemplateSection
              key={id}
              isPreview
              id={id}
              editId={editIndex}
              setEditId={setEditIndex}
              section={section}
              sections={values.sections!}
              errors={errors?.sections?.[id]}
              skills={values.skills}
              scorecardType={values.scorecard_type?.id}
              sectionOptions={possibleSectionOptions}
              sectionsFieldName="global_sections"
              locked
              titleIndexNumber={id + predefinedSectionsLength + 1}
            />
          )
        })}
      </Grid>
    )
  }

  const activeDragSection =
    activeDragId &&
    interviewFeedbackSections.find(({ id }) => String(id) === activeDragId)

  const activeDragSectionIndex =
    activeDragId !== undefined
      ? interviewFeedbackSections.findIndex(({ id }) => String(id) === activeDragId)
      : undefined

  return (
    <Box mb="s-24">
      <VStack gap="s-24" mb="s-24">
        {renderPredefinedSections()}
        <DragAndDrop.Provider
          onDragStart={event => {
            setActiveDragId(event.active.id)
          }}
          onDragEnd={event => {
            if (event.over) {
              handleOrderChange(
                event?.active?.data?.current?.sortable.index ?? 0,
                event?.over?.data?.current?.sortable.index ?? 0,
              )
            }
          }}
          onDragCancel={() => {
            setActiveDragId(null)
          }}
        >
          <DragAndDrop.Sortable
            id="sortable"
            items={interviewFeedbackSections.map(({ id }) => String(id))}
          >
            {sortable => {
              const sectionIndex = interviewFeedbackSections.findIndex(
                ({ id }) => String(id) === sortable.id,
              )
              const sectionValues = values.sections?.[sectionIndex]
              const sectionErrors = errors?.sections?.[sectionIndex]
              const titleIndexNumber = predefinedSectionsLength + sectionIndex + 1
              const isEditing = editIndex !== undefined
              const editingCurrent = editIndex === sectionIndex
              if (!sectionValues) {
                return null
              }
              return (
                <EvaluationSection
                  disableActions={isEditing && !editingCurrent}
                  dragId={`drag_${sectionIndex}`}
                  isEditing={editingCurrent}
                  isSubmitting={editingCurrent && isSubmitting}
                  sortable={sortable}
                  onDelete={() => handleDeleteSection(sectionValues)}
                  onEdit={() => {
                    if (editIndex === sectionIndex) {
                      handleEdit()
                    } else {
                      setEditIndex(sectionIndex)
                    }
                  }}
                >
                  <InterviewScorecardTemplateSection
                    key={sectionValues.id}
                    editId={sectionValues.id}
                    errors={sectionErrors}
                    hideControls
                    hideTitleIndex
                    id={sectionIndex}
                    isPreview={!editingCurrent}
                    scorecardType={values.scorecard_type?.id}
                    section={sectionValues}
                    sectionOptions={possibleSectionOptions}
                    sections={values.sections!}
                    sectionsFieldName="sections"
                    skills={values.skills}
                    titleIndexNumber={titleIndexNumber}
                    wrapper={Box}
                    onChange={val => {
                      values.sections![sectionIndex] = val
                    }}
                  />
                </EvaluationSection>
              )
            }}
          </DragAndDrop.Sortable>
          <DragAndDrop.DragOverlay>
            {activeDragSection && activeDragSectionIndex !== undefined && (
              <EvaluationSection disableActions dragId={`drag_${activeDragId}`}>
                <InterviewScorecardTemplateSection
                  key={activeDragSection.id}
                  editId={activeDragSection.id}
                  hideControls
                  hideTitleIndex
                  id={activeDragSectionIndex}
                  isPreview
                  scorecardType={values.scorecard_type?.id}
                  section={activeDragSection}
                  sectionOptions={possibleSectionOptions}
                  sections={values.sections!}
                  sectionsFieldName="sections"
                  setEditId={() => {}}
                  skills={values.skills}
                  titleIndexNumber={activeDragSectionIndex}
                  wrapper={Box}
                />
              </EvaluationSection>
            )}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>
      </VStack>
      <ActionButton
        useIcon="Plus"
        ref={addEvaluationAnchorRef}
        disabled={editIndex !== undefined}
        onClick={() => toggleAddEvaluation()}
      >
        Add evaluation
      </ActionButton>
      <Dropdown
        open={openAddEvaluation}
        anchorRef={addEvaluationAnchorRef}
        onClose={toggleAddEvaluation.off}
      >
        <Dropdown.Item
          useIcon="CheckboxOn"
          use="button"
          iconColor={Token.color.blue}
          onClick={() => {
            handleAddSection(InterviewScorecardEnumSectionIdCheckbox)
          }}
        >
          Checkbox
        </Dropdown.Item>
        <Dropdown.Item
          useIcon="RadiobuttonOn"
          use="button"
          iconColor={Token.color.blue}
          onClick={() => {
            handleAddSection(InterviewScorecardEnumSectionIdOption)
          }}
        >
          Option
        </Dropdown.Item>
        <Dropdown.Item
          useIcon="CheckSuccess"
          use="button"
          iconColor={Token.color.blue}
          onClick={() => {
            handleAddSection(InterviewScorecardEnumSectionIdDropdown)
          }}
        >
          Dropdown
        </Dropdown.Item>
        <Dropdown.Item
          useIcon="Text"
          use="button"
          iconColor={Token.color.blue}
          onClick={() => {
            handleAddSection(InterviewScorecardEnumSectionIdText)
          }}
        >
          Text
        </Dropdown.Item>
        <Dropdown.Item
          useIcon="LikeDislike"
          use="button"
          iconColor={Token.color.blue}
          onClick={() => {
            handleAddSection(InterviewScorecardEnumSectionIdThumbs)
          }}
        >
          Thumbs
        </Dropdown.Item>
      </Dropdown>
    </Box>
  )
}
