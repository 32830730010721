import React, { useMemo } from 'react'
import { Group, ItemSkeleton, VStack } from '@revolut/ui-kit'
import { HiringStageSwitcher } from '@src/pages/Forms/Candidate/StagesWidget/HiringStageSwitcher'
import {
  CandidateInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  InterviewStatuses,
  StagesWidgetOnClickHandler,
} from '@src/interfaces/interviewTool'
import { FilterBar } from '@src/pages/Forms/Candidate/StagesWidget/FilterBar'
import { LocalStorageKeys } from '@src/store/auth/types'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { Stage } from '@src/pages/Forms/Candidate/StagesWidget/components/Stage'
import { getGroupedStages } from '@src/pages/Forms/Candidate/StagesWidget/normalizators/getGroupedStages'
import { StageGroup } from '@src/pages/Forms/Candidate/StagesWidget/components/StageGroup'

const getAssessmentOnlyStages = (stages?: InterviewStageWithoutRoundInterface[]) => {
  const assessmentTypes = [
    'home_assessment',
    'skills_round',
    'online_test',
    'test_review',
    'final_round',
  ]
  return (stages || [])?.filter(({ interview_type }) =>
    assessmentTypes.includes(interview_type),
  )
}

type StagesWidgetProps = {
  candidate: CandidateInterface
  canViewEditOffer: boolean
  disableActions: boolean
  isMainRound: boolean
  loading: boolean
  round?: InterviewRoundInterface
  stages: InterviewStageWithoutRoundInterface[]
  onClick?: StagesWidgetOnClickHandler
  onRefresh: () => void
  onRoundChange: (roundId: number) => void
}

export const StagesWidget = ({
  candidate,
  canViewEditOffer,
  disableActions,
  isMainRound,
  loading,
  round,
  stages,
  onClick,
  onRefresh,
  onRoundChange,
}: StagesWidgetProps) => {
  const [assessmentOnly, changeAssessmentOnly] = useLocalStorage(
    LocalStorageKeys.SHOW_ASSESSMENT_ONLY,
    false,
  )
  const [feedbackCompleted, changeFeedbackCompleted] = useLocalStorage(
    LocalStorageKeys.SHOW_FEEDBACK_COMPLETED,
    false,
  )
  const displayStages = useMemo(() => {
    let currentStages = stages
    if (assessmentOnly) {
      currentStages = getAssessmentOnlyStages(currentStages)
    }
    if (feedbackCompleted) {
      currentStages = currentStages.filter(
        ({ scheduling_status }) =>
          scheduling_status === InterviewStatuses.feedback_submitted,
      )
    }
    return currentStages
  }, [stages, assessmentOnly, feedbackCompleted])

  const groups = useMemo<InterviewStageWithoutRoundInterface[][]>(
    () => getGroupedStages(displayStages),
    [displayStages],
  )

  if (!round || !candidate) {
    return null
  }
  if (loading && stages.length === 0) {
    return (
      <Group data-testid="Stages widget skeleton">
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </Group>
    )
  }

  return (
    <VStack gap="s-16" data-testid="Stages widget">
      <HiringStageSwitcher candidate={candidate} round={round} onChange={onRoundChange} />
      <FilterBar
        assessmentOnly={assessmentOnly}
        feedbackCompleted={feedbackCompleted}
        onAssessmentOnlyChange={() => {
          changeAssessmentOnly(!assessmentOnly)
        }}
        onFeedbackCompletedChange={() => {
          changeFeedbackCompleted(!feedbackCompleted)
        }}
      />
      {groups.map(group =>
        group.length === 1 ? (
          <Stage
            key={group[0].id}
            candidate={candidate}
            canViewEditOffer={canViewEditOffer}
            disableActions={disableActions}
            isMainRound={isMainRound}
            round={round}
            stage={group[0]}
            stages={displayStages}
            onClick={onClick}
            onRefresh={onRefresh}
          />
        ) : (
          <StageGroup
            key={group[0].id}
            round={round}
            stages={group}
            onClick={onClick}
            onRefresh={onRefresh}
            candidate={candidate}
            canViewEditOffer={canViewEditOffer}
            disableActions={disableActions}
          />
        ),
      )}
    </VStack>
  )
}
