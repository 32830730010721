import { GetRequestData, GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  ClickupIntegrationInterface,
  DocusignPreferencesInterface,
  DocusignUsersInterface,
  GoogleCalendarPreferencesInterface,
  JiraPreferencesInterface,
  LookerIntegrationInterface,
  SalesforceIntergrationInterface,
  SlackBotInterface,
  SlackPreferencesInterface,
  TableauIntegrationInterface,
  MergeConnectionResponse,
  MergeConnectionRequest,
  MergeConnectionInterface,
  MergeIntegration,
  OktaIntegrationInterface,
  DocusignConsentInterface,
} from '@src/interfaces/integrations'
import {
  useFetch,
  useUpdate,
  useUpdateV2,
  useDeleteV2,
  usePostV2,
} from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { useQueryClient } from 'react-query'

export const useGetSlackPreferences = () =>
  useFetch<SlackPreferencesInterface>(
    API.SLACK_INTEGRATION_PREFERENCES,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const useUpdatePreferences = () =>
  useUpdate<
    GetRequestData<SlackPreferencesInterface>,
    Partial<SlackPreferencesInterface>
  >(
    API.SLACK_INTEGRATION_PREFERENCES,
    undefined,
    undefined,
    false,
    (oldData, newData) => ({
      ...oldData,
      ...newData,
    }),
    undefined,
    false,
  )

export const getSlackIntegrationBots = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<SlackBotInterface>> =>
  api.get(`/slackIntegrationBots`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const slackBotRequests: RequestInterfaceNew<SlackBotInterface> = {
  get: async ({ id }) => {
    const resp = await api.get<SlackBotInterface>(`/slackIntegrationBots/${id}`)
    const data = resp.data || {}
    return {
      ...resp,
      data: {
        ...data,
        access_token: '**********',
      },
    }
  },
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`/slackIntegrationBots/${id}`, data),
  submit: async data => apiWithoutHandling.post('/slackIntegrationBots', data),
}

export const deleteSlackBot = (id: number) => api.delete(`/slackIntegrationBots/${id}`)

export const useGetJiraPreferences = () =>
  useFetch<JiraPreferencesInterface>(
    API.JIRA_INTEGRATION_PREFERENCES,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

// show "fake" data in secret inputs
export const maskedJiraCredentials = {
  username: '**********',
  api_key: '**********',
}

export const useGetTableauPreferences = () =>
  useFetch<TableauIntegrationInterface>(
    API.TABLEAU_INTEGRATION_PREFERENCES,
    undefined,
    undefined,
    true,
  )

export const useGetClickupPreferences = () =>
  useFetch<ClickupIntegrationInterface>(
    API.CLICKUP_INTEGRATION_PREFERENCES,
    undefined,
    undefined,
    true,
  )

export const jiraPreferencesRequests: RequestInterfaceNew<JiraPreferencesInterface> = {
  get: async ({ id }) => {
    const resp = await api.get<JiraPreferencesInterface>(
      `${API.JIRA_INTEGRATION_PREFERENCES}/${id}`,
    )
    const data = resp.data || {}
    return {
      ...resp,
      data: {
        ...data,
        ...maskedJiraCredentials,
      },
    }
  },
  update: async (data, { id }) => {
    const resp = await apiWithoutHandling.patch(
      `${API.JIRA_INTEGRATION_PREFERENCES}/${id}`,
      data,
    )
    const respData = resp.data || {}
    return {
      ...resp,
      data: {
        ...respData,
        ...maskedJiraCredentials,
      },
    }
  },
  submit: async data => apiWithoutHandling.post(API.JIRA_INTEGRATION_PREFERENCES, data),
}

export const clickupIntegrationPreferences: RequestInterfaceNew<ClickupIntegrationInterface> =
  {
    get: async ({ id }) =>
      api.get<ClickupIntegrationInterface>(
        `${API.CLICKUP_INTEGRATION_PREFERENCES}/${id}`,
      ),

    update: async (data, { id }) => {
      const resp = await apiWithoutHandling.patch(
        `${API.CLICKUP_INTEGRATION_PREFERENCES}/${id}`,
        data,
      )
      return resp
    },
    submit: async data =>
      apiWithoutHandling.post(`${API.CLICKUP_INTEGRATION_PREFERENCES}`, data),
  }

export const oktaIntegrationPreferences: RequestInterfaceNew<OktaIntegrationInterface> = {
  get: async ({ id }) =>
    api.get<OktaIntegrationInterface>(`${API.OKTA_INTEGRATION_PREFERENCES}/${id}`),

  update: async (data, { id }) => {
    const resp = await apiWithoutHandling.patch(
      `${API.OKTA_INTEGRATION_PREFERENCES}/${id}`,
      data,
    )
    return resp
  },
  submit: async data =>
    apiWithoutHandling.post(`${API.OKTA_INTEGRATION_PREFERENCES}`, data),
}

export const lookerIntegrationPreferences: RequestInterfaceNew<LookerIntegrationInterface> =
  {
    get: async ({ id }) =>
      api.get<LookerIntegrationInterface>(`${API.LOOKER_INTEGRATION_PREFERENCES}/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.LOOKER_INTEGRATION_PREFERENCES}/${id}`, data),

    submit: async data =>
      apiWithoutHandling.post(`${API.LOOKER_INTEGRATION_PREFERENCES}`, data),
  }

export const tableauIntegrationPreferences: RequestInterfaceNew<TableauIntegrationInterface> =
  {
    get: async ({ id }) =>
      api.get<TableauIntegrationInterface>(
        `${API.TABLEAU_INTEGRATION_PREFERENCES}/${id}`,
      ),
    update: async (data, { id }) => {
      const resp = await apiWithoutHandling.patch(
        `${API.TABLEAU_INTEGRATION_PREFERENCES}/${id}`,
        data,
      )
      return resp
    },
    submit: async data =>
      apiWithoutHandling.post(`${API.TABLEAU_INTEGRATION_PREFERENCES}`, data),
  }

export const salesforceIntegrationPreferences: RequestInterfaceNew<SalesforceIntergrationInterface> =
  {
    get: async ({ id }) =>
      api.get<SalesforceIntergrationInterface>(
        `${API.SALESFORCE_INTEGRATION_PREFERENCES}/${id}`,
      ),
    update: async (data, { id }) => {
      const resp = await apiWithoutHandling.patch(
        `${API.SALESFORCE_INTEGRATION_PREFERENCES}/${id}`,
        data,
      )
      return resp
    },
    submit: async data =>
      apiWithoutHandling.post(`${API.SALESFORCE_INTEGRATION_PREFERENCES}`, data),
  }

export const googleCalendarRequests: RequestInterfaceNew<GoogleCalendarPreferencesInterface> =
  {
    get: async ({ id }) => {
      const resp = await api.get<GoogleCalendarPreferencesInterface>(
        `${API.GOOGLE_CALENDAR_INTEGRATION_PREFERENCES}/${id}`,
      )

      return {
        ...resp,
        data: { ...resp.data, credentials: '**********' },
      }
    },
    update: async (data, { id }) => {
      const resp = await apiWithoutHandling.patch(
        `${API.GOOGLE_CALENDAR_INTEGRATION_PREFERENCES}/${id}`,
        data,
      )
      return {
        ...resp,
        data: { ...resp.data, credentials: '**********' },
      }
    },
    submit: async data =>
      apiWithoutHandling.post(API.GOOGLE_CALENDAR_INTEGRATION_PREFERENCES, data),
  }

export const docuSignPreferencesRequests: RequestInterfaceNew<DocusignPreferencesInterface> =
  {
    get: async ({ id }) =>
      api.get<DocusignPreferencesInterface>(
        `${API.DOCUSIGN_INTEGRATION_PREFERENCES}/${id}`,
      ),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.DOCUSIGN_INTEGRATION_PREFERENCES}/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(API.DOCUSIGN_INTEGRATION_PREFERENCES, data),
  }

export const useGetDocuSignPreferences = () =>
  useFetch<DocusignPreferencesInterface>(
    API.DOCUSIGN_INTEGRATION_PREFERENCES,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const getDocusignOAuthUrl = (data: DocusignPreferencesInterface) =>
  apiWithoutHandling.post<{ url: string | null }>(
    `${API.DOCUSIGN_INTEGRATION_PREFERENCES}/oauth`,
    data,
  )

export const useGetDocusignConsent = () =>
  useFetch<DocusignConsentInterface>(`${API.DOCUSIGN_INTEGRATION_PREFERENCES}/oauth/me`)

export const docusignUsersTableRequests = {
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<DocusignUsersInterface>>(
      `${API.DOCUSIGN_INTEGRATIONS}/users`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
}

export const docusignUsersSync = () =>
  apiWithoutHandling.post(`${API.DOCUSIGN_INTEGRATIONS}/users/sync`)

export const useGetMergeConnections = (integration?: MergeIntegration) =>
  useFetch<GetRequestInterface<MergeConnectionInterface>>({
    url: API.MERGE_CONNECTIONS,
    params: {
      params: {
        ...(integration ? { integration_type: integration } : null),
      },
    },
    withoutHandling: true,
  })

export const useCreateMergeConnection = () =>
  usePostV2<MergeConnectionResponse, MergeConnectionResponse, MergeConnectionRequest>({
    url: `${API.MERGE_CONNECTIONS}/initiate`,
  })

export const useUpdateMergeConnection = () =>
  useUpdateV2<MergeConnectionInterface, Partial<MergeConnectionInterface>>({
    url: API.MERGE_CONNECTIONS,
  })

export const useActivateMergeConnection = (id?: string) =>
  useUpdateV2({ url: `${API.MERGE_CONNECTIONS}/${id}/activate`, usePut: true })

export const useDeleteMergeConnection = () =>
  useDeleteV2({ url: `${API.MERGE_CONNECTIONS}` })

export const useInvalidateMergeConnections = () => {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries(API.MERGE_CONNECTIONS)
}
