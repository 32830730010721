import React from 'react'
import { useGetJobPostingsApplicationFormSettings } from '@src/api/settings'
import ApplicationFormPreviewSection from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormPreviewSection'

interface ApplicationFormGlobalSectionsProps {
  locked?: boolean
  onClickEdit?: (sectionId: number) => void
}

export const ApplicationFormGlobalSections = ({
  locked,
  onClickEdit,
}: ApplicationFormGlobalSectionsProps) => {
  const { data: applicationSettings } = useGetJobPostingsApplicationFormSettings()
  return (
    <>
      {applicationSettings?.global_sections?.map((_, sectionIdx) => (
        <ApplicationFormPreviewSection
          key={sectionIdx}
          idx={sectionIdx}
          onClickEdit={onClickEdit}
          sections={applicationSettings.global_sections}
          locked={locked}
        />
      ))}
    </>
  )
}
