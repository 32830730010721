import React from 'react'
import pluralize from 'pluralize'
import { MoreBar } from '@revolut/ui-kit'

import { InternalLink } from '@components/InternalLink/InternalLink'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { navigateTo } from '@src/actions/RouterActions'
import { serviceDeskCategoryTableRequests } from '@src/api/faq'
import { useTable } from '@src/components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import {
  sdCategoryFaqCategoryNameColumn,
  sdCategoryLinkColumn,
  sdCategoryNameColumn,
  sdCategorySubcategoriesCountColumn,
} from '@src/constants/columns/faqSDCategories'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ServiceDeskCategoryInterface } from '@src/interfaces/faq'
import { pathToUrl } from '@src/utils/router'

const ROW: RowInterface<ServiceDeskCategoryInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.APPS.HELP_CENTER.SD_CATEGORY.PREVIEW, { id })),
  cells: [
    {
      ...sdCategoryNameColumn,
      width: 200,
    },
    {
      ...sdCategorySubcategoriesCountColumn,
      width: 50,
    },
    {
      ...sdCategoryFaqCategoryNameColumn,
      width: 200,
    },
    {
      ...sdCategoryLinkColumn,
      width: 200,
    },
  ],
}

export const FaqSDCategoriesTable = () => {
  const table = useTable<ServiceDeskCategoryInterface>(serviceDeskCategoryTableRequests)

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label={pluralize('Category', table.count)}
          val={table.loading ? undefined : table.count}
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <MoreBar.Action
            useIcon="Plus"
            use={InternalLink}
            to={pathToUrl(ROUTES.APPS.HELP_CENTER.SD_CATEGORY.EDIT)}
          >
            Add category
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.FaqSDCategories}
          useWindowScroll
          row={ROW}
          {...table}
          dataType="Category"
          noDataMessage="All categories will appear here"
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
