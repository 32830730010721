import React, { useState } from 'react'
import StagesTableActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StagesTableActions'
import { MoveStageAction } from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/MoveStageAction'
import { InterviewStageWithoutRoundInterface } from '@src/interfaces/interviewTool'
import { DuplicateStageAction } from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/DuplicateStageAction'
import { DropdownItemProps } from '@revolut/ui-kit'

interface Props {
  currentStageId: number
  groupStages: InterviewStageWithoutRoundInterface[]
  roundId: number
  onRefresh?: VoidFunction
}

export const StageGroupActions = ({
  roundId,
  groupStages,
  currentStageId,
  onRefresh,
}: Props) => {
  const [isOpen, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const notCurrentStage = groupStages.every(stage => stage.id !== currentStageId)

  const getActions = () => {
    const itemProps: DropdownItemProps = {
      disabled: loading,
      use: 'button',
    }
    const actions: React.ReactNode[] = []

    const moveStageAction = () => {
      if (notCurrentStage) {
        actions.push(
          <MoveStageAction
            stage={groupStages[0]}
            roundId={roundId}
            menuType="dropdown"
            onClose={() => setOpen(false)}
            onLoading={setLoading}
            itemProps={itemProps}
            onRefresh={onRefresh}
            key="Move to stage"
          />,
        )
      }
    }

    const duplicateStageAction = () => {
      actions.push(
        <DuplicateStageAction
          stageId={groupStages[0].id}
          roundId={roundId}
          menuType="dropdown"
          onClose={() => setOpen(false)}
          onLoading={setLoading}
          itemProps={itemProps}
          onRefresh={onRefresh}
          key="Duplicate stage"
        />,
      )
    }

    moveStageAction()
    duplicateStageAction()

    return actions
  }

  return (
    <StagesTableActions isOpen={isOpen} setOpen={setOpen} loading={loading}>
      {getActions()}
    </StagesTableActions>
  )
}
