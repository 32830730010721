import React from 'react'
import { DetailsCellSkeleton, ErrorWidget, Group } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Color, HStack } from '@revolut/ui-kit'
import OnlineTestPreviewLink from '@src/features/HiringStageSidebarPreview/OnlineTestPreviewLink'
import OnlineTestPreviewDuration from '@src/features/HiringStageSidebarPreview/OnlineTestPreviewDuration'
import upperFirst from 'lodash/upperFirst'
import OnlineTestRadioSelect from '@src/pages/Forms/HiringStage/OnlineTestRadioSelect'
import { IdAndName } from '@src/interfaces'
import { useGetCandidate } from '@src/api/recruitment/interviews'
import {
  fetchOnlineTestInfo,
  useGetSpecialisationHiringStage,
} from '@src/api/hiringProcess'
import {
  HiringProcessInterface,
  OnlineTestInterface,
} from '@src/interfaces/hiringProccess'

interface Props {
  displayCandidateInfo?: boolean
  onChange: (onlineTest: OnlineTestInterface) => void
  candidateId?: string | number
  hiringStageId: number | null
  onlineTest?: OnlineTestInterface
}

const SendOnlineTestPreview = ({
  displayCandidateInfo,
  onChange,
  candidateId,
  hiringStageId,
  onlineTest,
}: Props) => {
  const { data: candidate, isLoading: isCandidateLoading } = useGetCandidate(candidateId)
  const { data: hiringStage, isLoading: isHiringStageLoading } =
    useGetSpecialisationHiringStage(hiringStageId)

  if (isCandidateLoading || isHiringStageLoading) {
    return (
      <Group>
        <DetailsCellSkeleton />
        <DetailsCellSkeleton />
        <DetailsCellSkeleton />
        <DetailsCellSkeleton />
      </Group>
    )
  }

  if (!hiringStage) {
    return (
      <ErrorWidget>
        <ErrorWidget.Image />
        <ErrorWidget.Title>Cannot show online test preview</ErrorWidget.Title>
      </ErrorWidget>
    )
  }

  const onTestChange = async (newOnlineTest: IdAndName | null) => {
    if (newOnlineTest) {
      const resp = await fetchOnlineTestInfo(newOnlineTest.id)
      onChange(resp.data)
    }
  }

  return (
    <>
      <Group>
        <FormPreview<HiringProcessInterface> data={hiringStage}>
          <FormPreview.Header title="Test information" />
          <FormPreview.Item<HiringProcessInterface>
            title="Test name"
            insert={data => (
              <HStack gap="s-8">
                {onlineTest?.name || 'N/A'}{' '}
                {data.test_platform && (
                  <OnlineTestRadioSelect
                    testPlatform={data.test_platform.id}
                    noInput
                    onChange={onTestChange}
                  />
                )}
              </HStack>
            )}
          />
          <FormPreview.Item
            title="Testing platform"
            field="test_platform.name"
            type="capitalized"
          />
          <OnlineTestPreviewLink<HiringProcessInterface> />
          <OnlineTestPreviewDuration<HiringProcessInterface> />
          <FormPreview.Item
            title="Send test automatically"
            field="send_test_automatically"
            type="boolean"
          />
          {hiringStage.send_test_automatically && (
            <FormPreview.Item<HiringProcessInterface>
              title="Email template for automatic sending"
              field="send_auto_email_template.name"
              href={data =>
                pathToUrl(ROUTES.FORMS.EMAIL_TEMPLATE.PREVIEW, {
                  id: data.send_auto_email_template?.id,
                })
              }
            />
          )}
          <FormPreview.Item
            title="Archive candidate automatically"
            field="archive_candidate_automatically"
            type="boolean"
          />
          {hiringStage.archive_candidate_automatically && (
            <>
              <FormPreview.Item
                title="Automatic archival reason"
                field="auto_archival_reason.name"
              />
              <FormPreview.Item title="Pass score" field="online_test_pass_score" />
              <FormPreview.Item<HiringProcessInterface>
                title="Email template for automatic archival"
                field="archive_auto_email_template.name"
                href={data =>
                  pathToUrl(ROUTES.FORMS.EMAIL_TEMPLATE.PREVIEW, {
                    id: data.archive_auto_email_template?.id,
                  })
                }
              />
            </>
          )}
        </FormPreview>
        {candidate && (
          <FormPreview<CandidateInterface> data={candidate}>
            <FormPreview.Divider />
            <FormPreview.Header title="Candidate information" />
            {displayCandidateInfo && (
              <>
                <FormPreview.Item<CandidateInterface>
                  title="Full name"
                  field="full_name"
                  to={data =>
                    pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                      id: data.id,
                    })
                  }
                />
                <FormPreview.Item title="Candidate email" field="email" />
              </>
            )}
            <FormPreview.Item<CandidateInterface>
              title="Interviewing for"
              field="active_interview_round.specialisation.name"
              to={data =>
                pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                  id: data.active_interview_round?.specialisation.id,
                })
              }
            />
          </FormPreview>
        )}
      </Group>
      {hiringStage.test_platform && (
        <ActionWidget
          mt="s-16"
          title={`Test invite by default will be sent from ${upperFirst(
            hiringStage.test_platform.name,
          )}`}
          avatarColor={Color.BLUE}
        />
      )}
    </>
  )
}

export default SendOnlineTestPreview
