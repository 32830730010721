import React, { useEffect } from 'react'
import { Avatar, Caption, Item, Text, TextWidget, Token, VStack } from '@revolut/ui-kit'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { SeniorityProgression } from '@src/features/GrowthPlans/GrowthPlan/components/SeniorityProgression'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { employeeModel } from '@src/features/GrowthPlans/GrowthPlan/models/employeeModel'
import { EmployeeInterface } from '@src/interfaces/employees'
import { BaseOption, SpecialisationOption } from '@src/interfaces/selectors'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { useGetSpecialisationSeniorities } from '@src/api/specialisations'

interface Props {
  employee: EmployeeInterface
}

export const GeneralInfoWidget = ({ employee }: Props) => {
  const { values } = useLapeContext<GrowthPlanInterface>()

  const {
    target_specialisation: targetSpecialisation,
    current_specialisation: currentSpecialisation,
  } = values

  const { data: seniorityList, isLoading: isSeniorityListLoading } =
    useGetSpecialisationSeniorities(targetSpecialisation.id)

  useEffect(() => {
    if (isSeniorityListLoading) {
      return
    }

    if (targetSpecialisation.id === currentSpecialisation.id) {
      const nextSeniority =
        employeeModel.getNextSeniority(employee, seniorityList ?? []) ||
        employeeModel.getCurrentSeniority(employee)

      if (nextSeniority) {
        values.target_seniority = {
          id: nextSeniority.id,
          name: nextSeniority.name,
        }
      }
    }
  }, [targetSpecialisation.id, isSeniorityListLoading])

  const handleOptionInputChange = <T extends BaseOption>(
    key: keyof Pick<GrowthPlanInterface, 'target_seniority' | 'target_specialisation'>,
    value: T | null,
  ) => {
    if (!value) {
      return
    }

    values[key] = {
      id: Number(value.id),
      name: value.name,
    }
  }

  return (
    <TextWidget>
      <TextWidget.Title>
        <Text>General</Text>
        <Caption color={Token.color.greyTone50}>
          Define a plan to progress towards selected specialisation and seniority level.
        </Caption>
      </TextWidget.Title>
      <TextWidget.Content>
        <VStack space="s-16" pt="s-8">
          <LapeRadioSelectInput<SpecialisationOption>
            name="target_specialisation"
            label="Target Specialisation"
            selector={selectorKeys.specialisations}
            disabled={isSeniorityListLoading}
            onChange={value => handleOptionInputChange('target_specialisation', value)}
          />
          {targetSpecialisation.id !== currentSpecialisation.id && (
            <LapeRadioSelectInput<SeniorityInterface>
              name="target_seniority"
              label="Target Seniority"
              options={
                (seniorityList ?? []).map(seniority => ({
                  label: seniority.name,
                  value: seniority,
                })) ?? []
              }
              disabled={isSeniorityListLoading}
              onChange={value => handleOptionInputChange('target_seniority', value)}
            />
          )}
          <Item style={{ border: `1px solid ${Token.color.greyTone10}` }}>
            <Item.Avatar>
              <Avatar useIcon="Profile" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <SeniorityProgression
                  values={[
                    values.current_seniority?.name,
                    values.target_seniority?.name,
                  ].filter(Boolean)}
                />
              </Item.Title>
              <Item.Description>Seniority</Item.Description>
            </Item.Content>
          </Item>
          <LapeDatePickerInput
            name="deadline"
            label="Select Due date"
            description="This is the date by which you want the target to be achieved."
            required
          />
          <LapeHTMLEditor
            name="description"
            placeholder="Description"
            message="Optional. Briefly outline the purpose of this growth plan, including key areas of development and the expected outcomes."
            hideOptional
            resetEmptyContent
            height={120}
          />
        </VStack>
      </TextWidget.Content>
    </TextWidget>
  )
}
