import { OptionInterface } from '@src/interfaces/selectors'
import { useFetch, useFetchV2 } from '@src/utils/reactQuery'
import {
  ApiHandlerInterface,
  GetRequestData,
  GetRequestInterface,
  IdAndName,
} from '@src/interfaces'
import {
  AvailableInterviewerSlot,
  CandidateCancelledInterviewInterface,
  EligibleInterviewerInterface,
  PotentialInterviewerSlots,
  SchedulingCalendarInterface,
  SchedulingEmailPreviewInterface,
  ScheduleInterviewResponseInterface,
  SchedulingType,
  ScheduleInterviewInterface,
  SendInvitePayloadInterface,
  DynamicSlotsCountInterface,
  DurationUnitType,
} from '@src/interfaces/interviewTool'
import { API } from '@src/constants/api'
import axios, { AxiosPromise, AxiosResponse, CancelTokenSource } from 'axios'
import { apiV2 } from '@src/api'
import { IdInterface } from '@components/Inputs/NewCheckboxGroup/NewCheckboxGroup'
import { FileInterface } from '@src/interfaces/files'
import { uploadFile } from '@src/api/files'
import { isFile } from '@src/utils/files'
import { getDurationPayload } from '@src/pages/Forms/Candidate/ScheduleSidebar/normalizers/getDurationPayload'

export interface AvailableInterviewerSlotRequestArguments {
  stageId?: number
  roundId?: number
  interviewId?: number
  dateRange?: string[]
  interviewers?: OptionInterface[]
  interviewerGroups?: IdAndName[]
  duration?: number
  durationUnit?: DurationUnitType
  onlySentSlots?: boolean
  isAdhoc?: boolean
  cancelToken?: CancelTokenSource
  apiHandler?: ApiHandlerInterface
  daysCount?: number
}

const getAvailableInterviewerSlotsParams = ({
  interviewId,
  dateRange,
  interviewers,
  duration,
  durationUnit,
  onlySentSlots,
  isAdhoc,
  interviewerGroups,
  daysCount,
}: Omit<AvailableInterviewerSlotRequestArguments, 'stageId' | 'roundId'>) => ({
  employee: interviewers?.length
    ? interviewers?.map(item => item.id).join(',')
    : undefined,
  interviewers_groups: interviewerGroups?.length
    ? interviewerGroups?.map(item => item.id).join(',')
    : undefined,
  event_start_datetime: dateRange?.join(','),
  duration: getDurationPayload(duration, durationUnit),
  duration_unit: durationUnit,
  sent: onlySentSlots,
  interview_scheduling_id: interviewId,
  disable_pagination: true,
  is_adhoc: isAdhoc ? true : undefined,
  days_to_suggest_slots_for: daysCount,
})

export const useGetAvailableInterviewSlots = (
  params: AvailableInterviewerSlotRequestArguments,
) => {
  const { stageId, roundId, ...restParams } = params

  return useFetch<{ results: AvailableInterviewerSlot[] }>(
    roundId && stageId
      ? `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/availableInterviewSlots`
      : null,
    undefined,
    {
      params: getAvailableInterviewerSlotsParams(restParams),
    },
    true,
    {
      refetchOnWindowFocus: false,
    },
    undefined,
    true,
  )
}

export const getAvailableInterviewSlots = (
  params: AvailableInterviewerSlotRequestArguments,
) => {
  const { stageId, roundId, apiHandler = apiV2, cancelToken, ...restParams } = params

  return apiHandler
    .get<GetRequestInterface<AvailableInterviewerSlot>>(
      `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/availableInterviewSlots`,
      {
        params: getAvailableInterviewerSlotsParams(restParams),
        cancelToken: cancelToken?.token,
      },
    )
    .catch(e => {
      if (axios.isCancel(e)) {
        throw new Error('cancelPrevQuery')
      }

      throw e
    })
}

export const useGetCustomPotentialInterviewSlots = (
  id?: number,
  roundId?: number,
  interviewers?: IdInterface[],
  requireAll?: boolean,
  duration?: number,
  durationUnit?: string,
) => {
  return useFetch<PotentialInterviewerSlots[]>(
    id && roundId && interviewers?.length
      ? `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/customPotentialInterviewSlots`
      : null,
    undefined,
    {
      params: {
        interviewer_ids: interviewers?.map(item => item.id).join(','),
        require_everyone_availability: requireAll,
        duration,
        duration_unit: durationUnit,
      },
    },
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const getSchedulingEmailPreview = (
  id: number,
  roundId?: number,
  type?: SchedulingType,
  customDate?: string,
  timeZone?: string,
  templateId?: string | number,
  duration?: number,
  durationUnit?: string,
  apiHandler = apiV2,
) =>
  apiHandler.get<SchedulingEmailPreviewInterface>(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/schedulingEmailPreview`,
    {
      params: {
        scheduling_type: type,
        event_date_time: customDate,
        scheduling_timezone: timeZone,
        email_template_id: templateId,
        duration,
        duration_unit: durationUnit,
      },
    },
  )

export const useGetSchedulingInterview = (
  id?: number,
  roundId?: number,
  isPrepCall?: boolean,
  interviewId?: number,
) => {
  let url = null

  if (isPrepCall && roundId) {
    url = `${API.INTERVIEWS}/rounds/${roundId}/adhocInterviews`
  } else if (id && roundId) {
    url = `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/interviewScheduling`
  }

  const context = useFetchV2<ScheduleInterviewResponseInterface[]>({ url })

  return {
    ...context,
    data: interviewId
      ? context.data?.find(item => item.id === interviewId)
      : context.data?.[0],
  }
}

export const useGetSchedulingCalendars = (roundId?: number) => {
  const context = useFetchV2<GetRequestData<SchedulingCalendarInterface>>({
    url: roundId
      ? `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/availableCalendars`
      : null,
  })

  return { ...context, data: context.data?.results }
}

export const useGetPrepCallInterviews = (roundId?: number) =>
  useFetchV2<ScheduleInterviewResponseInterface[]>({
    url: roundId ? `${API.INTERVIEWS}/rounds/${roundId}/adhocInterviews` : null,
  })

export const getSchedulingInterview = (
  stageId: number,
  roundId: number,
  apiHandler = apiV2,
) =>
  apiHandler.get<ScheduleInterviewResponseInterface[]>(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/interviewScheduling`,
  )

export const useGetCandidateCancelledStages = (roundId?: number) =>
  useFetchV2<CandidateCancelledInterviewInterface[]>({
    url: roundId
      ? `${API.INTERVIEWS}/rounds/${roundId}/candidateCancelledInterviews`
      : null,
  })

export const sendInterviewInvite = async (
  id: number,
  roundId: number,
  data: SendInvitePayloadInterface,
  apiHandler = apiV2,
): Promise<AxiosPromise<SendInvitePayloadInterface>> => {
  let files: AxiosResponse<FileInterface>[] | undefined
  if (data.attachments) {
    files = await Promise.all(
      data.attachments.map(file =>
        isFile(file)
          ? uploadFile(file, 'candidate_email', undefined, apiHandler)
          : new Promise<AxiosResponse<FileInterface>>(resolve => {
              resolve({ data: file } as AxiosResponse<FileInterface>)
            }),
      ),
    )
  }

  return apiHandler.post(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/interviewScheduling`,
    {
      ...data,
      attachments:
        files?.map(file => ({
          id: file?.data?.id,
        })) || [],
    },
  )
}

export const editInterview = (
  stageId: number,
  roundId: number,
  id: number,
  data: Partial<ScheduleInterviewInterface>,
  apiHandler = apiV2,
) =>
  apiHandler.patch(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/interviewScheduling/${id}`,
    data,
  )

export const useGetEligibleInterviewers = (
  id?: number,
  roundId?: number,
  is_adhoc?: boolean,
  availability_time_range?: string,
) => {
  const context = useFetchV2<GetRequestInterface<EligibleInterviewerInterface>>({
    url:
      id && roundId
        ? `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/eligibleInterviewers`
        : null,
    params: {
      params: {
        is_adhoc,
        availability_time_range,
      },
    },
  })

  return { ...context, data: context.data?.results }
}

export const useGetEligibleInterviewerGroups = (
  id?: number,
  roundId?: number,
  is_adhoc?: boolean,
  availability_time_range?: string,
) => {
  const context = useFetchV2<GetRequestInterface<IdAndName>>({
    url:
      id && roundId
        ? `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/interviewerGroups`
        : null,
    params: {
      params: {
        is_adhoc,
        availability_time_range,
      },
    },
  })

  return { ...context, data: context.data?.results }
}

export const cancelScheduledInterview = async (
  roundId: number,
  stageId: number,
  interviewId: number,
  apiHandler = apiV2,
) =>
  apiHandler.post(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/interviewScheduling/${interviewId}/cancel`,
  )

export const useFetchDynamicSlotsCount = (
  params: AvailableInterviewerSlotRequestArguments,
  enabled = true,
) => {
  const { stageId, roundId, cancelToken, ...restParams } = params

  return useFetchV2<DynamicSlotsCountInterface>({
    url: `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/availableDynamicInterviewSlotsCount`,
    params: {
      params: getAvailableInterviewerSlotsParams(restParams),
    },
    queryOptions: {
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60 * 5,
      enabled,
    },
    useRequestCancelling: true,
  })
}
