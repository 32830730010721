import SideBar from '@components/SideBar/SideBar'
import React, { useEffect, useMemo, useState } from 'react'
import { Group, Token, Flex, Header, Text, Item, Avatar } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { TeamInterface } from '@src/interfaces/teams'
import { navigateTo } from '@src/actions/RouterActions'
import { FormPreview } from '@components/FormPreview/FormPreview'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { CommunicationDetails } from '@src/features/CommunicationGroups/CommunicationDetails'
import { useGetRoadmapSettings } from '@src/api/settings'
import { ExternalChannelItem } from '@src/features/ExternalChannels/ExternalChannelItem'
import { ExternalChannelItemInterface } from '@src/interfaces/deparment'
import { useDepartmentSidebar } from '@src/pages/Department/Layout/useDepartmentSidebar'
import {
  useGetExternalChannels,
  useUpdateDepartmentExternalChannels,
} from '@src/api/department'
import { parseSlackConnections } from '@src/features/ExternalChannels/mapper'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import LapeForm from '@src/features/Form/LapeForm'
import { FormValidatorProvider } from '@src/features/Form/FormValidator'
import { ExternalChannelForm } from '@src/features/ExternalChannels/ExternalChannelForm'
import Icon from '@components/Icon/Icon'

interface DetailsSidebarProps {
  onClose: VoidFunction
  team: TeamInterface
  approvalFlow: React.ReactNode
}

export const DetailsSidebar = ({ onClose, team, approvalFlow }: DetailsSidebarProps) => {
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const [channelEdit, setChannelEdit] = useState<ExternalChannelItemInterface>()
  const { sidebar, editMode, setSidebarQueryParam } = useDepartmentSidebar()
  const setEditMode = (id: number | undefined) =>
    setSidebarQueryParam('teamDetails', true, id)
  const featureFlags = useSelector(selectFeatureFlags)
  const canEdit = !!team.field_options.permissions?.includes(PermissionTypes.ChangeTeams)

  const slackUserGroupEnabled = featureFlags?.includes(FeatureFlags.SlackUserGroupEnabled)
  const { data: externalChannels, refetch: refetchExternalChannels } =
    useGetExternalChannels({ teamId: team.id })
  const parsedSlackData: ExternalChannelItemInterface[] = useMemo(
    () => parseSlackConnections(externalChannels),
    [externalChannels],
  )

  useEffect(() => {
    if (editMode) {
      const channel = parsedSlackData.find(item => item.id === Number(editMode))
      setChannelEdit(channel)
    }
  }, [parsedSlackData])
  const setEdit = (channel: ExternalChannelItemInterface | undefined) => {
    if (channel) {
      setChannelEdit({ ...channel })
      setEditMode(channel.id)
    } else {
      setChannelEdit(undefined)
      setEditMode(undefined)
    }
  }
  const { mutateAsync: updateConnection } = useUpdateDepartmentExternalChannels(
    channelEdit?.id,
  )
  return (
    <SideBar
      variant="wide"
      isOpen={sidebar === 'teamDetails'}
      onClose={onClose}
      title="Team details"
      useIcon="People"
      avatarProps={{ color: Token.color.greyTone20 }}
      customHeader={
        channelEdit ? (
          <Header>
            <Header.BackButton
              color={Token.color.foreground}
              onClick={() => {
                setEdit(undefined)
              }}
            />
            <Header.Title data-testid="sidebar-title">
              <Text color={Token.color.foreground}>{channelEdit.name}</Text>
            </Header.Title>
          </Header>
        ) : undefined
      }
    >
      {editMode && channelEdit ? (
        <>
          <LapeForm<{ name: string; handle: string }>
            initialValues={channelEdit}
            onSubmit={form => {
              return updateConnection([undefined, form.values]).then(() => {
                refetchExternalChannels()
                return form.values
              })
            }}
          >
            <FormValidatorProvider>
              <ExternalChannelForm
                onAfterSubmit={() => {
                  setEdit(undefined)
                  refetchExternalChannels()
                }}
              />
            </FormValidatorProvider>
          </LapeForm>
        </>
      ) : (
        <>
          {approvalFlow}
          <FormPreview<TeamInterface>
            data={team}
            title="General details"
            onEdit={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, { id: team.id }))
            }
          >
            <Group>
              <FormPreview.Item<TeamInterface> field="name" title="Team name" />
              <FormPreview.Item<TeamInterface>
                title="Department"
                field="department.name"
                to={data =>
                  pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, { id: data.department?.id })
                }
              />
              <FormPreview.Item<TeamInterface>
                field="team_owner"
                title="Owner"
                type="employee"
              />
              <FormPreview.Details<TeamInterface>
                title="KPI champions"
                description="These people have increased permissions to help when editing and setting KPIs"
                insert={d =>
                  d.kpi_champions && d.kpi_champions.length > 0 ? (
                    <Flex flexWrap="wrap">
                      {d.kpi_champions.map(kpiChamp => (
                        <UserWithAvatar
                          height="s-32"
                          mr="s-12"
                          key={kpiChamp.id}
                          {...kpiChamp}
                        />
                      ))}
                    </Flex>
                  ) : (
                    '-'
                  )
                }
              />
              <FormPreview.Details<TeamInterface> field="mission" title="Mission" />
              <CommunicationDetails
                jiraProjects={team.jira_projects}
                linkedGroups={team.linked_communication_groups}
                jiraEnabled={roadmapSettings?.jira_epics_enabled}
              />
            </Group>
          </FormPreview>

          {!!parsedSlackData.length &&
            slackUserGroupEnabled &&
            team.settings.enable_slack_user_groups && (
              <Group mt="s-16">
                <Item>
                  <Item.Avatar>
                    <Avatar
                      useIcon={
                        <Icon
                          type="SlackMono"
                          size="tiny"
                          color={Token.color.foreground}
                        />
                      }
                    />
                  </Item.Avatar>
                  <Item.Content>
                    <Text variant="emphasis1"> Slack Integration</Text>
                  </Item.Content>
                </Item>
                {parsedSlackData.map((channel, index) => (
                  <ExternalChannelItem
                    channel={channel}
                    key={index}
                    canEdit={canEdit}
                    onChannelEdit={() => {
                      setEdit(channel)
                    }}
                  />
                ))}
              </Group>
            )}
        </>
      )}
    </SideBar>
  )
}
