import {
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { API } from '@src/constants/api'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { LeadershipPositionInterface, LeadershipStats } from '@src/interfaces/leadership'

const getBaseUrl = ({ id, entity }: { id: number; entity: 'team' | 'department' }) => {
  return `${{ team: API.TEAMS, department: API.DEPARTMENT }[entity]}/${id}${
    API.LEADERSHIP_POSITIONS
  }`
}

export const getLeadershipRequestsNew = ({
  entityId,
  entityType,
}: {
  entityId: number
  entityType: 'team' | 'department'
}): RequestInterfaceNew<LeadershipPositionInterface> => ({
  get: async ({ id }) =>
    api.get(`${getBaseUrl({ id: entityId, entity: entityType })}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(
      `${getBaseUrl({ id: entityId, entity: entityType })}/${id}`,
      data,
    ),
  submit: async data =>
    apiWithoutHandling.post(`${getBaseUrl({ id: entityId, entity: entityType })}`, data),
  delete: async ({ id }) =>
    api.delete(`${getBaseUrl({ id: entityId, entity: entityType })}/${id}`),
})

export const getDepartmentLeadershipPositions = (
  departmentId: number,
): RequestInterface<LeadershipPositionInterface>['getItems'] => {
  return ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<LeadershipPositionInterface>>(
      getBaseUrl({ id: departmentId, entity: 'department' }),
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    )
}

export const getDepartmentLeadershipStats = (
  departmentId: number,
): RequestInterface<LeadershipPositionInterface, LeadershipStats>['getStats'] => {
  return ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get(`${getBaseUrl({ id: departmentId, entity: 'department' })}/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
}

export const getTeamLeadershipPositions = (
  id: number,
): RequestInterface<LeadershipPositionInterface>['getItems'] => {
  return ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get(getBaseUrl({ id, entity: 'team' }), {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
}

export const getTeamLeadershipStats = (
  id: number,
): RequestInterface<LeadershipPositionInterface, LeadershipStats>['getStats'] => {
  return ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get(`${getBaseUrl({ id, entity: 'team' })}/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
}
