import React, { CSSProperties } from 'react'
import { ProgressWidget, ProgressWidgetSkeleton } from '@revolut/ui-kit'
import { getColorForPortion } from '@src/features/ReviewCycle/components/Progress/styles'
import { formatPrecisePercentage } from '@src/features/ReviewCycle/PerformanceReviewCycle/formatters/formatPrecisePercentage'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

const widgetStylesOverrides: CSSProperties = {
  padding: 0,
  ...transparentThemeBackgroundOverrides,
}

export const makeDescriptionText = (dividend: number, divisor: number): string =>
  divisor > 0 ? formatPrecisePercentage(dividend / divisor) : 'N/A'

interface Props {
  value: number
  title: React.ReactNode
  description: React.ReactNode
  isLoading: boolean
  label: React.ReactNode
}

export const Progress = ({ value, title, description, isLoading, label }: Props) => {
  const color = getColorForPortion(value)

  if (isLoading) {
    return <ProgressSkeleton />
  }

  return (
    <ProgressWidget
      style={widgetStylesOverrides}
      data-testid="performance_progress_widget"
    >
      <ProgressWidget.Title data-testid="performance_progress_title">
        {title}
      </ProgressWidget.Title>
      <ProgressWidget.Description data-testid="performance_progress_description">
        {description}
      </ProgressWidget.Description>
      <ProgressWidget.Progress value={value} color={color} />
      <ProgressWidget.Label data-testid="performance_progress_label">
        {label}
      </ProgressWidget.Label>
    </ProgressWidget>
  )
}

export const ProgressSkeleton = () => (
  <ProgressWidgetSkeleton style={widgetStylesOverrides} />
)
