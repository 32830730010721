import { AxiosPromise } from 'axios'
import { apiV2 } from '@src/api/index'
import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { EmployeeTimelineEventInterface } from '@src/interfaces/employeeTimeline'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getEmployeeTimeline = (
  employeeId: number | string,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<GetRequestInterface<EmployeeTimelineEventInterface>> =>
  apiV2.get(
    `${API.EMPLOYEES}/${employeeId}/timeline`,
    { params: filterSortPageIntoQuery(sortBy, filters, page) },
    'v2',
  )
