import React, { useEffect, useState } from 'react'
import {
  Box,
  Cell,
  DragAndDrop,
  Flex,
  InputGroup,
  VStack,
  Text,
  TextButton,
  Token,
  ActionButton,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ApproverId,
  PolicyApprover,
  TimeOffPolicyInterface,
} from '@src/interfaces/timeOff'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import Tooltip from '@components/Tooltip/Tooltip'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import InputErrorMessage from '@components/InputErrorMessage/InputErrorMessage'
import DragIcon from '@src/components/DragIcon'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

export type ApprovalChainFieldErrors = {
  noApproversSelected?: boolean
  emptyFieldValueIndexes?: number[]
}

type Props = {
  errors?: ApprovalChainFieldErrors
  setErrors: (newValue?: ApprovalChainFieldErrors) => void
  showDescription?: boolean
  sortByInitial?: boolean
}

type ApproverInputsProps = {
  approver: PolicyApprover
  approverIdx: number
  errors?: ApprovalChainFieldErrors
  setErrors?: (newValue?: ApprovalChainFieldErrors) => void
}

const ApproverInputs = ({
  approver,
  approverIdx,
  errors,
  setErrors,
}: ApproverInputsProps) => {
  const { values } = useLapeContext<TimeOffPolicyInterface>()
  const approverType = approver.approver_type.id
  const hasError = errors?.emptyFieldValueIndexes?.includes(approverIdx)

  const onAfterChange = (newValue: IdAndName | null) => {
    if (newValue && errors?.emptyFieldValueIndexes?.length && setErrors) {
      setErrors({
        ...errors,
        emptyFieldValueIndexes: errors.emptyFieldValueIndexes.filter(
          idx => idx !== approverIdx,
        ),
      })
    }
  }

  return (
    <>
      <Flex flex="1 0">
        <LapeRadioSelectInput
          label={approverIdx === 0 ? 'Select approver' : 'Next approver'}
          name={`policy_approvers[${approverIdx}].approver_type`}
          searchable={false}
          selector={selectorKeys.time_off_policy_default_approvers}
          onChange={newValue => {
            if (newValue) {
              values.policy_approvers[approverIdx] = {
                ...values.policy_approvers[approverIdx],
                approver_type: newValue as IdAndName<ApproverId>,
                employee: null,
                groups: [],
                relationship: null,
              }
            }
          }}
        />
      </Flex>
      {approverType !== 'default_approver' && (
        <Flex flexDirection="column" width="55%">
          {approverType === 'dynamic_group' && (
            <LapeRadioSelectInput
              label="Select group"
              name={`policy_approvers[${approverIdx}].groups[0]`}
              hasError={hasError}
              onAfterChange={onAfterChange}
              selector={selectorKeys.dynamic_groups}
            />
          )}

          {approverType === 'employee' && (
            <LapeRadioSelectInput
              label="Select employee"
              name={`policy_approvers[${approverIdx}].employee`}
              hasError={hasError}
              onAfterChange={onAfterChange}
              selector={selectorKeys.all_employees_avatar_email}
            />
          )}

          {approverType === 'relationship' && (
            <LapeRadioSelectInput
              label="Select relationship"
              name={`policy_approvers[${approverIdx}].relationship`}
              hasError={hasError}
              onAfterChange={onAfterChange}
              selector={selectorKeys.time_off_policy_approver_relationships}
            />
          )}

          {hasError && <InputErrorMessage message="This field is mandatory" />}
        </Flex>
      )}
    </>
  )
}

export const PolicyApprovalChainInput = ({
  errors,
  setErrors,
  showDescription = true,
  sortByInitial = true,
}: Props) => {
  const { initialValues, values } = useLapeContext<TimeOffPolicyInterface>()
  const [approversFormatted, setApproversFormatted] = useState(false)

  useEffect(() => {
    values.policy_approvers =
      (sortByInitial ? initialValues : values).policy_approvers
        ?.sort((prev, next) => prev.sort_order - next.sort_order)
        ?.map((approver, idx) => ({ ...approver, id: idx })) || []
    setApproversFormatted(true)
  }, [])

  const approvers = values.policy_approvers || []

  useEffect(() => {
    if (values.is_approval_required && !approvers.length) {
      values.policy_approvers = [
        {
          id: 0,
          approver_type: { id: 'relationship', name: 'Relationship' },
          employee: null,
          groups: [],
          relationship: { id: 'line_manager', name: 'Line Manager' },
          sort_order: 0,
        },
      ]
    }
  }, [])

  const [activeId, setActiveId] = useState<string | number | null>(null)
  const activeApproverIdx = activeId
    ? approvers.findIndex(({ id }) => String(id) === activeId)
    : -1
  const activeApprover = activeApproverIdx >= 0 ? approvers[activeApproverIdx] : undefined
  const lastApproverId = approvers[approvers.length - 1]?.id || 0

  const [nextAddedApproverId, setNextAddedApproverId] = useState(lastApproverId + 1)

  if (!approversFormatted) {
    return null
  }
  return (
    <Cell>
      <Box width="100%">
        <VStack>
          <Text variant="h6" color={Token.color.greyTone50} mb="s-16">
            Approvers
          </Text>
          {showDescription && (
            <Text color={Token.color.greyTone50} mb="s-16">
              Order of the items below will indicate the order of the approvals. At least
              one approver must be added. The default approver for each employee can vary
              based on the settings of the time off regime to which they are assigned. You
              can configure this{' '}
              <TextButton
                use={InternalLink}
                to={pathToUrl(ROUTES.SETTINGS.TIME_OFF.REGIMES)}
              >
                here
              </TextButton>
              .
            </Text>
          )}
          {approvers.length ? (
            <>
              <InputGroup>
                <DragAndDrop.Provider
                  onDragStart={event => {
                    setActiveId(event.active.id)
                  }}
                  onDragEnd={({ over }) => {
                    const overIdx = approvers.findIndex(
                      approver => String(approver.id) === over?.id,
                    )
                    const approversWithoutDragged = approvers.filter(
                      ({ id }) => String(id) !== activeId,
                    )
                    if (overIdx >= 0 && activeApprover) {
                      values.policy_approvers = [
                        ...approversWithoutDragged.slice(0, overIdx),
                        activeApprover,
                        ...approversWithoutDragged.slice(overIdx, approvers.length - 1),
                      ].map((approver, idx) => ({ ...approver, sort_order: idx }))
                    }
                    setActiveId(null)
                    setErrors(undefined)
                  }}
                >
                  <DragAndDrop.Sortable
                    id="sortable"
                    items={approvers.map(({ id }) => String(id))}
                  >
                    {sortable => {
                      const approverIdx = approvers.findIndex(
                        ({ id }) => String(id) === sortable.id,
                      )
                      const approver = approvers[approverIdx]

                      if (!approver) {
                        return null
                      }
                      return (
                        <Flex
                          gap="s-8"
                          alignItems="center"
                          ref={sortable?.setNodeRef}
                          {...sortable?.attributes}
                          {...sortable?.listeners}
                          style={
                            sortable
                              ? {
                                  transform: sortable.transform
                                    ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                                    : undefined,
                                  transition: sortable.transition || 'none',
                                  opacity: sortable.isDragging ? 0 : undefined,
                                  zIndex: 10,
                                }
                              : undefined
                          }
                        >
                          <DragIcon />
                          <ApproverInputs
                            approver={approver}
                            approverIdx={approverIdx}
                            errors={errors}
                            setErrors={setErrors}
                          />
                          <Tooltip
                            placement="bottom"
                            hide={approvers.length !== 1}
                            text="At least one approver should be added"
                          >
                            <ActionButton
                              iconOnly
                              useIcon="Minus"
                              onClick={() => {
                                values.policy_approvers = approvers.filter(
                                  (_, idx) => idx !== approverIdx,
                                )
                                if (errors?.emptyFieldValueIndexes?.length) {
                                  setErrors({
                                    ...errors,
                                    emptyFieldValueIndexes:
                                      errors.emptyFieldValueIndexes.filter(
                                        idx => idx !== approverIdx,
                                      ),
                                  })
                                }
                              }}
                              disabled={approvers.length === 1}
                            />
                          </Tooltip>
                        </Flex>
                      )
                    }}
                  </DragAndDrop.Sortable>
                  <DragAndDrop.DragOverlay>
                    {activeApprover && (
                      <Flex gap="s-8" alignItems="center">
                        <DragIcon />
                        <ApproverInputs
                          approver={activeApprover}
                          approverIdx={activeApproverIdx}
                        />
                        <ActionButton iconOnly useIcon="Minus" />
                      </Flex>
                    )}
                  </DragAndDrop.DragOverlay>
                </DragAndDrop.Provider>
              </InputGroup>
              <Box>
                <ActionButton
                  mt="s-16"
                  width={160}
                  useIcon="Plus"
                  onClick={() => {
                    values.policy_approvers![approvers.length] = {
                      id: nextAddedApproverId,
                      approver_type: { id: 'employee', name: 'Employee' },
                      sort_order: approvers.length,
                      employee: null,
                      groups: [],
                      relationship: null,
                    }
                    setNextAddedApproverId(nextAddedApproverId + 1)
                  }}
                >
                  Add approver
                </ActionButton>
              </Box>
            </>
          ) : (
            <Box width="100%">
              <Flex flex="1 0">
                <Flex flexDirection="column" width="100%">
                  <LapeRadioSelectInput
                    label="Select approver"
                    searchable={false}
                    name="policy_approvers[0].approver_type"
                    onChange={newValue => {
                      if (newValue) {
                        delete errors?.noApproversSelected
                        setErrors(errors)

                        values.policy_approvers = [
                          {
                            id: 0,
                            approver_type: newValue as IdAndName<ApproverId>,
                            sort_order: 0,
                            employee: null,
                            groups: [],
                            relationship: null,
                          },
                        ]
                      }
                    }}
                    hasError={errors?.noApproversSelected}
                    selector={selectorKeys.time_off_policy_default_approvers}
                  />
                  {errors?.noApproversSelected && (
                    <InputErrorMessage message="This field is mandatory" />
                  )}
                </Flex>
              </Flex>
            </Box>
          )}
        </VStack>
      </Box>
    </Cell>
  )
}
