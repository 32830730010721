import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import OnboardingActions from '@src/pages/OnboardingChecklistV2/components/OnboardingActions'
import { reviewsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { API } from '@src/constants/api'
import { performanceSettings } from '@src/api/performanceSettings'
import { connect } from 'lape'
import { selectReviewTypesTitle } from '@src/pages/OnboardingChecklistV2/PerformanceReviews/index'
import { Text, Token, VStack } from '@revolut/ui-kit'
import { ReviewSettings } from '@src/pages/Settings/Performance/ReviewSettings/ReviewSettings'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'

export const PerformanceReviewTypesContent = () => {
  return (
    <>
      <PageBody>
        <VStack space="s-8" mb="s-16">
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Enable functional management',
              description:
                'A functional manager(FM) is a person with the same skills but a higher seniority compared to the person who is being mentored. When enabled the FM will have to review the mentee during all types of performance cycles.',
            }}
            name="enable_functional_management"
          />
          <Text variant="h5">Who should be invited to provide review feedback?</Text>
          <Text variant="small" color={Token.color.greyTone50}>
            We recommend 360 reviews, however you can disable some review types
          </Text>
        </VStack>
        <ReviewSettings />
      </PageBody>

      <OnboardingActions
        config={reviewsConfig}
        currentStep={selectReviewTypesTitle}
        isForm
        isLastStep={false}
        updateSteps
        disableNext={false}
        pendingNext={false}
        nextRoute={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD}
      />
    </>
  )
}

export const PerformanceReviewTypes = connect(() => {
  return (
    <Form
      api={performanceSettings}
      invalidateQueries={[API.PERFORMANCE_SETTINGS]}
      forceParams={{ id: 'reviews' }}
      isExistingForm
    >
      <PerformanceReviewTypesContent />
    </Form>
  )
})
