import React from 'react'
import {
  Box,
  Calendar,
  Flex,
  Radio,
  RadioGroup,
  Skeleton,
  Subheader,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { getTimeZoneLabel } from '@src/utils/timezones'
import { useCalendar } from '@src/pages/CandidateScheduling/hooks/useCalendar'
import { DaySlotGroupInterface } from '@src/pages/Forms/Candidate/ScheduleSidebar/DaySlotsGroup/DaySlotsGroup'
import { PredefinedAvailableCandidateSlot } from '@src/interfaces/interviewTool'

interface SelectSlotCalendarProps<T> {
  loading: boolean
  slots: DaySlotGroupInterface<T>[]
  value?: string | null
  timezone: string
  onChange: (value: string | null) => void
}

export const SelectSlotCalendar = <
  T extends Pick<PredefinedAvailableCandidateSlot, 'event_start_datetime'>,
>({
  loading,
  slots,
  timezone,
  value,
  onChange,
}: SelectSlotCalendarProps<T>) => {
  const { date, dateGroup, disabledDays, events, setDate } = useCalendar(slots, timezone)
  if (loading) {
    return (
      <Widget p="s-16">
        <Skeleton />
      </Widget>
    )
  }
  return (
    <Widget p="s-16">
      <Flex maxWidth={600} flexWrap="wrap" justifyContent="space-evenly">
        <Box flex="1 1 auto">
          <Calendar
            disabledDays={disabledDays}
            defaultValue={date}
            // @ts-ignore
            // it says that events doesn't exist for variant="date" but it does
            events={events}
            hideHeader
            value={date}
            variant="date"
            onChange={calendarEvent => {
              setDate(calendarEvent)
              onChange(null)
            }}
          />
        </Box>
        <VStack pl={{ md: 's-16' }} flex="1 1 auto">
          <Subheader variant="nested">
            <Subheader.Title>{!!date && formatDate(date)}</Subheader.Title>
          </Subheader>
          <VStack gap="s-8" height={{ all: 184, md: 344 }} overflow="auto">
            {dateGroup && (
              <RadioGroup onChange={onChange} value={value}>
                {group =>
                  dateGroup.items.map(slot => (
                    <Radio
                      key={slot.event_start_datetime}
                      {...group.getInputProps({ value: slot.event_start_datetime })}
                    >
                      <Text>{getTimeZoneLabel(slot.event_start_datetime, timezone)}</Text>
                    </Radio>
                  ))
                }
              </RadioGroup>
            )}
          </VStack>
        </VStack>
      </Flex>
    </Widget>
  )
}
