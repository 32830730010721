import React from 'react'
import { Portal } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { ChangePassword } from './ChangePassword'
import { GetActivationLinkAction } from './GetActivationLink'
import { useEmployeeActivationLinkAction } from './GetActivationLink/hooks'
import { GetActivationLinkPopup } from './GetActivationLink/GetActivationLinkPopup'
import { GetMagicLinkAction } from './GetMagicLink'
import { useEmployeeMagicLinkAction } from './GetMagicLink/hooks'
import { GetMagicLinkPopup } from './GetMagicLink/GetMagicLinkPopup'
import { ResendActivationEmail } from './ResendActivationEmail'
import { CreateTicketAction } from './CreateTicket'
import { useCreateEmployeeTicketAction } from './CreateTicket/hooks'
import { CreateTicketPopup } from './CreateTicket/CreateTicketPopup'
import { AnonymiseProfileAction } from './AnonymiseProfile'
import { EmployeeButtonProps } from '../types'
import { useAnonymiseProfileAction } from './AnonymiseProfile/hooks'
import { AnonymiseProfileConfirmation } from './AnonymiseProfile/AnonymiseProfileConfirmation'
import { ManageDataAccess } from './ManageDataAccess'
import { LinkedAccounts } from './LinkedAccounts'
import { Changelog } from './Changelog'
import {
  useCanViewAnonymiseProfile,
  useCanViewChangePassword,
  useCanViewCreateTicket,
  useCanViewFlagPerformance,
  useCanViewGetActivationLink,
  useCanViewGetMagicLink,
  useCanViewManageDataAccess,
  useCanViewNominateForPromotion,
  useCanViewOverdueItems,
  useCanViewCreateProbation,
  useCanViewCreatePip,
  useCanViewReferrals,
  useCanViewResendActivationEmail,
} from '../../Buttons/common'
import { ButtonsGroup } from '../ButtonsGroup'

type Props = {
  data: EmployeeInterface
  refreshData: () => void
  groupActions?: boolean
}

export const ActionsGroupDropdown = ({ data, refreshData, groupActions }: Props) => {
  const anonymiseProfile = useAnonymiseProfileAction({ employeeId: data.id, refreshData })
  const createTicket = useCreateEmployeeTicketAction({ employee: data })
  const activationLink = useEmployeeActivationLinkAction({ employee: data })
  const magicLink = useEmployeeMagicLinkAction({ employee: data })

  const canViewGetActivationLink = useCanViewGetActivationLink({ data })
  const canViewGetMagicLink = useCanViewGetMagicLink({ data })
  const canViewChangePassword = useCanViewChangePassword({ data })
  const canViewResendActivationEmail = useCanViewResendActivationEmail({ data })
  const canViewNominateForPromotion = useCanViewNominateForPromotion({ data })
  const canViewManageDataAccess = useCanViewManageDataAccess({ data })
  const canViewCreateTicket = useCanViewCreateTicket({ data })
  const canViewFlagPerformance = useCanViewFlagPerformance({ data })
  const canViewAnonymiseProfile = useCanViewAnonymiseProfile({ data })
  const canViewReferrals = useCanViewReferrals({ data })
  const canViewOverdueItems = useCanViewOverdueItems({ data })
  const canViewCreateProbation = useCanViewCreateProbation({ data })
  const canViewCreatePip = useCanViewCreatePip({ data })
  // showActions will always be true because of this, left showActions check logic to not lose it
  // in case rules for showing Add feedback button will change in the future
  const canViewAddFeedback = true

  const showActions =
    canViewGetActivationLink ||
    canViewGetMagicLink ||
    canViewChangePassword ||
    canViewResendActivationEmail ||
    canViewNominateForPromotion ||
    canViewManageDataAccess ||
    canViewCreateTicket ||
    canViewFlagPerformance ||
    canViewAnonymiseProfile ||
    canViewReferrals ||
    canViewOverdueItems ||
    canViewCreateProbation ||
    canViewCreatePip ||
    canViewAddFeedback

  if (!showActions) {
    return null
  }

  const actionProps: EmployeeButtonProps = {
    data,
    isDropdownItem: !!groupActions,
  }

  return (
    <ButtonsGroup variant={groupActions ? 'grouped' : 'flat'}>
      <GetActivationLinkAction
        onClick={activationLink.onClick}
        pending={activationLink.pending}
        {...actionProps}
      />
      <GetMagicLinkAction
        {...actionProps}
        onClick={magicLink.onClick}
        pending={magicLink.pending}
      />
      <ChangePassword {...actionProps} />
      <ResendActivationEmail {...actionProps} />
      <ManageDataAccess {...actionProps} />
      <Changelog {...actionProps} />
      <CreateTicketAction
        pending={createTicket.pending}
        onClick={createTicket.onClick}
        {...actionProps}
      />
      <AnonymiseProfileAction
        pending={anonymiseProfile.pending}
        setShowConfirm={anonymiseProfile.setShowConfirm}
        {...actionProps}
      />
      <LinkedAccounts {...actionProps} />
      <Portal>
        {canViewGetActivationLink && (
          <GetActivationLinkPopup
            open={activationLink.showPopup}
            onClose={() => activationLink.setShowPopup(false)}
            link={activationLink.link}
          />
        )}
        {canViewGetMagicLink && (
          <GetMagicLinkPopup
            link={magicLink.link}
            open={magicLink.showPopup}
            onClose={() => magicLink.setShowPopup(false)}
          />
        )}
        {canViewCreateTicket && (
          <CreateTicketPopup
            open={createTicket.showPopup}
            onClose={() => createTicket.setShowPopup(false)}
            createdTicket={createTicket.createdTicket}
            setCreatedTicket={createTicket.setCreatedTicket}
          />
        )}
        {canViewAnonymiseProfile && (
          <AnonymiseProfileConfirmation
            open={anonymiseProfile.showConfirm}
            onClose={() => anonymiseProfile.setShowConfirm(false)}
            pending={anonymiseProfile.pending}
            onConfirm={anonymiseProfile.onConfirm}
            onReject={() => anonymiseProfile.setShowConfirm(false)}
          />
        )}
      </Portal>
    </ButtonsGroup>
  )
}
