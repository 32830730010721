import React, { useState } from 'react'
import {
  HStack,
  RadioGroup,
  Subheader,
  VStack,
  Widget,
  Text,
  Radio,
  Avatar,
  ThemePicker,
  ThemeBackgroundsSet,
  Box,
} from '@revolut/ui-kit'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { LocalStorageKeys } from '@src/store/auth/types'
import { RowHeight } from '@src/interfaces/table'

export const Appearance = () => {
  const { setTheme, setThemeBackground } = useAppTheme()

  const [rowHeight, setRowHeight] = useState<RowHeight>(() => {
    const localStorageRowHeight = localStorage.getItem(LocalStorageKeys.TABLE_ROW_HEIGHT)

    if (localStorageRowHeight === 'large') {
      return 'large'
    }
    return 'medium'
  })

  return (
    <PageWrapper>
      <PageHeader title="Appearance" backUrl={ROUTES.MAIN} />

      <PageBody>
        <ThemePicker
          backgrounds={ThemeBackgroundsSet.People}
          onModeChange={setTheme}
          onBackgroundChange={setThemeBackground}
          labelTransparent="Transparent"
        />

        <Box mt="s-16">
          <Subheader>
            <Subheader.Title>Table density</Subheader.Title>
          </Subheader>

          <Widget p="s-16">
            <RadioGroup
              value={rowHeight}
              onChange={val => {
                if (val) {
                  setRowHeight(val)
                  localStorage.setItem(LocalStorageKeys.TABLE_ROW_HEIGHT, val)
                }
              }}
            >
              {group => (
                <HStack
                  space="s-32"
                  css={{ justifyContent: 'center', userSelect: 'none' }}
                >
                  <VStack
                    use="label"
                    space="s-12"
                    align="center"
                    style={{ cursor: 'pointer' }}
                  >
                    <Avatar useIcon="ViewListSmall" />
                    <Text>Condensed cells</Text>
                    <Radio
                      aria-label="Condensed cells"
                      {...group.getInputProps({ value: 'medium' })}
                    />
                  </VStack>

                  <VStack
                    use="label"
                    space="s-12"
                    align="center"
                    style={{ cursor: 'pointer' }}
                  >
                    <Avatar useIcon="ViewListLarge" />
                    <Text>Large cells</Text>
                    <Radio
                      aria-label="Large cells"
                      {...group.getInputProps({ value: 'large' })}
                    />
                  </VStack>
                </HStack>
              )}
            </RadioGroup>
          </Widget>
        </Box>
      </PageBody>
    </PageWrapper>
  )
}
