import {
  ApiHandlerInterface,
  GetRequestInterface,
  RequestInterfaceNew,
} from '@src/interfaces'
import {
  BookingLinkDefinitionInterface,
  BookingLink,
  BookingLinkCallProfile,
  BookingLinkCompanyInterface,
} from '@src/interfaces/bookingLinks'
import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { PredefinedAvailableCandidateSlot } from '@src/interfaces/interviewTool'
import { useFetch } from '@src/utils/reactQuery'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { LocationWithCountries } from '@src/interfaces/locations'
import { OptionInterface } from '@src/interfaces/selectors'
import { decodeToken } from '@src/utils/decodeToken'

export const getBookingLinkDefinitions =
  (apiHandler: ApiHandlerInterface = api) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<BookingLinkDefinitionInterface>
  > =>
    apiHandler.get(API.BOOKING_LINK_DEFINITIONS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getBookingLinkByDefinitionId = (
  bookingLinkDefinitionId: number,
  apiHandler = apiWithoutHandling,
) =>
  apiHandler.post<BookingLink>(
    `${API.BOOKING_LINK_DEFINITIONS}/${bookingLinkDefinitionId}/generateBookingLink`,
  )

export const useGetAvailableSlotsFromRecruiters = (
  recruiterIds?: number[],
  duration?: number,
  durationUnit?: string,
) =>
  useFetch<{ results: PredefinedAvailableCandidateSlot[] }>(
    recruiterIds?.length ? `${API.AVAILABLE_SLOTS}` : null,
    'v1',
    {
      params: {
        recruiter_ids: recruiterIds?.join?.(','),
        duration,
        duration_unit: durationUnit,
      },
    },
  )

export const bookingLinkDefinitionsFormRequests: (
  apiHandler?: ApiHandlerInterface,
) => RequestInterfaceNew<BookingLinkDefinitionInterface> = (
  apiHandler = apiWithoutHandling,
) => ({
  get: async ({ id }) => apiHandler.get(`${API.BOOKING_LINK_DEFINITIONS}/${id}`),
  update: async (data, { id }) =>
    apiHandler.patch(`${API.BOOKING_LINK_DEFINITIONS}/${id}`, data),
  submit: async data => apiHandler.post(API.BOOKING_LINK_DEFINITIONS, data),
})

export const useGetBookingLinkByToken = (token: string) =>
  useFetch<BookingLink>(
    `${API.BOOKING_LINK}/${token}`,
    'v1',
    {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    undefined,
    undefined,
    true,
  )

export const useGetLocationsWithCountries = () =>
  useFetch<LocationWithCountries[]>(
    API.LOCATIONS_WITH_COUNTRIES,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  )

export const getAvailableRecruiterSlotsForBookingLink = (token: string, page: number) =>
  api.get<PredefinedAvailableCandidateSlot[]>(
    `${API.BOOKING_LINK}/${token}/getRecruiterSlots`,
    {
      params: {
        page,
      },
      headers: {
        apitoken: decodeToken(token),
      },
    },
    undefined,
    true,
  )

export const submitProfileBookCall = (token: string, data: BookingLinkCallProfile) =>
  apiWithoutHandling.post(
    `${API.BOOKING_LINK}/${token}/submitProfileBookCall`,
    data,
    {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    undefined,
    true,
  )

export const useGetBookingLinkTimeZones = (token: string) =>
  useFetch<{ options: OptionInterface[] }>(
    `${API.BOOKING_LINK}/timezones`,
    'v1',
    {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    undefined,
    undefined,
    true,
  )

export const useGetBookingLinkCompany = (token: string) =>
  useFetch<BookingLinkCompanyInterface>(
    `${API.BOOKING_LINK}/companyContext`,
    'v1',
    {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    undefined,
    undefined,
    true,
  )
