import React from 'react'
import { InterviewType } from '@src/interfaces/interviewTool'
import { Icon, IconName, Token } from '@revolut/ui-kit'

const getStageIcon = (interviewType: InterviewType): IconName => {
  switch (interviewType) {
    case 'cv_screening':
      return 'Document'
    case 'screen_call':
      return 'Call'
    case 'home_assessment':
      return 'Utilities'
    case 'skills_round':
      return 'RepairTool'
    case 'online_test':
      return 'Laptop'
    case 'final_round':
      return 'AccountActions'
    case 'offer':
      return 'Cleaning'
    case 'hiring_panel':
      return 'People'
    default:
      return 'Apps'
  }
}

type StageIconProps = {
  interviewType: InterviewType
}

export const StageIcon = ({ interviewType }: StageIconProps) => {
  return (
    <Icon color={Token.color.greyTone50} name={getStageIcon(interviewType)} size={24} />
  )
}
