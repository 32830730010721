import { RowInterface } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import {
  goalsNameColumn,
  goalsProgressColumn,
  goalsWeightColumn,
} from '@src/constants/columns/goals'

export const getRow = (): RowInterface<GoalsInterface> => ({
  cells: [
    { ...goalsNameColumn(), width: 200 },
    {
      ...goalsWeightColumn,
      width: 50,
    },
    {
      ...goalsProgressColumn,
      width: 150,
    },
  ],
})
