import React from 'react'
import { Color } from '@revolut/ui-kit'

import { useTable } from '@src/components/TableV2/hooks'
import {
  getScheduleShiftBreakdown,
  getScheduleShiftBreakdownStats,
  getShiftBreakdownExport,
} from '@src/api/attendance'
import { RowInterface } from '@src/interfaces/data'
import { ScheduleShiftBreakdownInterface } from '@src/interfaces/attendance'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import {
  shiftBreakdownApprovalColumn,
  shiftBreakdownCompensationChoiceTypeColumn,
  shiftBreakdownCompensationTypeColumn,
  shiftBreakdownDurationColumn,
  shiftBreakdownTypeColumn,
  shiftSummaryDateColumn,
  shiftSummaryEmployeeColumn,
  shiftSummaryEndColumn,
  shiftSummaryLineManagerColumn,
  shiftSummaryLocationColumn,
  shiftSummaryStartColumn,
  shiftSummaryTeamColumn,
  shiftSummaryUpdatedOnColumn,
} from '@src/constants/columns/attendance'
import Stat from '@src/components/Stat/Stat'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { filterSortPageIntoQuery } from '@src/utils/table'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'

const Row: RowInterface<ScheduleShiftBreakdownInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW, {
        employeeId: data.employee.id,
        id: data.shift_id,
      }),
    ),
  cells: [
    {
      ...shiftSummaryDateColumn,
      width: 150,
    },
    {
      ...shiftSummaryEmployeeColumn,
      width: 200,
    },
    {
      ...shiftSummaryLineManagerColumn,
      width: 70,
    },
    {
      ...shiftSummaryTeamColumn,
      width: 200,
    },
    {
      ...shiftSummaryLocationColumn,
      width: 100,
    },
    {
      ...shiftBreakdownTypeColumn,
      width: 100,
    },
    {
      ...shiftSummaryStartColumn,
      width: 100,
    },
    {
      ...shiftSummaryEndColumn,
      width: 100,
    },
    {
      ...shiftBreakdownDurationColumn,
      width: 100,
    },
    {
      ...shiftBreakdownCompensationChoiceTypeColumn,
      width: 180,
    },
    {
      ...shiftBreakdownCompensationTypeColumn,
      width: 180,
    },
    {
      ...shiftSummaryUpdatedOnColumn,
      width: 150,
    },
    {
      ...shiftBreakdownApprovalColumn,
      width: 150,
    },
  ],
}

const ShiftBreakdown = () => {
  const table = useTable(
    {
      getItems: getScheduleShiftBreakdown,
      getStats: getScheduleShiftBreakdownStats,
    },
    [
      {
        columnName: 'approval_status',
        filters: [
          { id: ApprovalStatuses.Pending, name: ApprovalStatuses.Pending },
          { id: ApprovalStatuses.Approved, name: ApprovalStatuses.Approved },
          { id: ApprovalStatuses.NoStatus, name: ApprovalStatuses.NoStatus },
        ],
      },
    ],
  )

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label="Shifts"
          val={table?.stats?.total != null ? table.stats.total : undefined}
        />
        <Stat
          label="Pending approval"
          val={table?.stats?.pending != null ? table.stats.pending : undefined}
          color={Color.ORANGE}
        />
        <Stat
          label="Approved"
          val={table?.stats?.approved != null ? table.stats.approved : undefined}
          color={Color.GREEN}
        />
        <Stat
          label="Rejected"
          val={table?.stats?.rejected != null ? table.stats.rejected : undefined}
          color={Color.RED}
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <ExportMenu
            fileName="Shift Breakdowns"
            request={getShiftBreakdownExport}
            filterQuery={filterQuery}
          />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.ShiftBreakdown}
          row={Row}
          useWindowScroll
          {...table}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default ShiftBreakdown
