import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import {
  CareersPositionContent,
  CareersPositionContentProps,
} from '@src/pages/Careers/CareersPosition/CareersPositionContent'
import { LocationInterface } from '@src/interfaces/requisitions'
import { useCareersSettings } from '@src/api/recruitment/careers'
import { JobPostingLocationInterface } from '@src/interfaces/jobPosting'
import { isHTMLFieldEmpty } from '@src/utils/string'
import { useCareerPositionLocations } from '@src/pages/Careers/CareersPosition/useCareerPositionLocations'

interface Props extends Omit<CareersPositionContentProps, 'locations' | 'id'> {
  open: boolean
  onClose: () => void
  locations: LocationInterface[] | JobPostingLocationInterface[]
  useLayout?: boolean
}

export const CareersPositionPreviewSidebar = ({
  open,
  onClose,
  text,
  locations,
  description,
  useLayout = true,
}: Props) => {
  const { data: careersSettings } = useCareersSettings()
  const locationsWithType = useCareerPositionLocations(locations)

  return (
    <SideBar
      isOpen={open}
      onClose={onClose}
      useLayout={useLayout}
      title="Preview position"
      sideProps={{ resizable: true }}
    >
      <CareersPositionContent
        text={text || '<Position title>'}
        locations={locationsWithType}
        id=""
        description={
          !isHTMLFieldEmpty(careersSettings?.about_the_company)
            ? `<b>About the company</b><br />${careersSettings?.about_the_company} ${description}`
            : description
        }
        disabledApplyButton
      />
    </SideBar>
  )
}
