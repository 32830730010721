import React from 'react'
import { Box, Flex } from '@revolut/ui-kit'

interface PageWidgetWrapperProps {
  children?: React.ReactNode
}

export const PageWidgetWrapper = ({ children }: PageWidgetWrapperProps) => {
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <Box width="100%" maxWidth={343}>
        {children}
      </Box>
    </Flex>
  )
}
