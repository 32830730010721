import {
  ReviewCyclesInterface,
  ReviewCycleStage,
  ReviewCycleStatus,
} from '@src/interfaces/reviewCycles'

export enum CycleTypes {
  Regular = 'Regular',
  AdHoc = 'AdHoc',
}

export enum CycleModes {
  Scheduled = 'Scheduled',
  Manual = 'Manual',
}

interface CycleModel {
  isManual: (cycle: ReviewCyclesInterface) => boolean
  isTest: (cycle: ReviewCyclesInterface) => boolean
  isAdHoc: (cycle: ReviewCyclesInterface) => boolean
  isDeletable: (cycle: ReviewCyclesInterface) => boolean
  hasAutoScorecardsGeneration: (cycle: ReviewCyclesInterface) => boolean
  getStartDate: (cycle: ReviewCyclesInterface) => string
  getEndDate: (cycle: ReviewCyclesInterface) => string
  getCurrentStage: (cycle: ReviewCyclesInterface) => ReviewCycleStage | null
  getParentCycleId: (cycle: ReviewCyclesInterface) => string | number | undefined
  isClosed: (cycle: ReviewCyclesInterface) => boolean
  isOngoing: (cycle: ReviewCyclesInterface) => boolean
}

const isManual = (cycle: ReviewCyclesInterface): boolean =>
  !!cycle.is_manual_stage_switch_enabled

const isTest = (cycle: ReviewCyclesInterface): boolean => cycle.is_test

const isAdHoc = (cycle: ReviewCyclesInterface): boolean => cycle.is_adhoc

const isDeletable = (cycle: ReviewCyclesInterface): boolean => !!cycle.delete_allowed

const hasAutoScorecardsGeneration = (cycle: ReviewCyclesInterface): boolean =>
  cycle.enabled_scorecards_generations

const getStartDate = (cycle: ReviewCyclesInterface): string => cycle.start_date_time
const getEndDate = (cycle: ReviewCyclesInterface): string => cycle.end_date_time

const getCurrentStage = (cycle: ReviewCyclesInterface): ReviewCycleStage | null =>
  cycle.current_stage ?? null

const getParentCycleId = (cycle: ReviewCyclesInterface): string | number | undefined =>
  cycle.parent_cycle?.id

const isClosed = (cycle: ReviewCyclesInterface): boolean =>
  cycle.status === ReviewCycleStatus.closed
const isOngoing = (cycle: ReviewCyclesInterface): boolean =>
  cycle.status === ReviewCycleStatus.closed

const createCycleModel = (): CycleModel => {
  return {
    isManual,
    isTest,
    isAdHoc,
    isDeletable,
    hasAutoScorecardsGeneration,
    getStartDate,
    getEndDate,
    getCurrentStage,
    getParentCycleId,
    isClosed,
    isOngoing,
  }
}

export const cycleModel = createCycleModel()
