import SideBar from '@src/components/SideBar/SideBar'
import React, { useRef } from 'react'
import { Group, Item, ItemSkeleton, List, StatusWidget, Token } from '@revolut/ui-kit'
import { useTable } from '@components/TableV2/hooks'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import {
  ImportSessionInterface,
  ImportSessionState,
} from '@src/interfaces/bulkDataImport'
import { Virtuoso } from 'react-virtuoso'
import { formatDateTime } from '@src/utils/format'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { CheckSuccess, Time, ExclamationTriangle, Profile } from '@revolut/icons'
import { getUploadHistory } from '@src/api/bulkDataImport'

type ImportUploadHistoryProps = {
  endpoint: string
  name: string
  nextRoute: string
  isOpen: boolean
  onClose: (isOpen: boolean) => void
}

const getStatusThemeColor = (status?: ImportSessionState) => {
  switch (status) {
    case 'applying':
    case 'ready_for_review':
    case 'processing_file':
    case 'pending':
      return Token.color.warning
    case 'failure':
      return Token.color.danger
    case 'success':
      return Token.color.success
    default:
      return Token.color.foreground
  }
}

const getStatusIcon = (status?: ImportSessionState) => {
  switch (status) {
    case 'failure':
      return ExclamationTriangle
    case 'success':
      return CheckSuccess
    default:
      return Time
  }
}

const Scroller = React.forwardRef<HTMLDivElement>(({ ...props }, ref) => {
  return <Group ref={ref} {...props} />
})

export const BulkDataImportHistory = ({
  endpoint,
  name,
  nextRoute,
  isOpen,
  onClose,
}: ImportUploadHistoryProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const table = useTable<ImportSessionInterface>({
    getItems: (requestData: FetchDataQueryInterface) =>
      getUploadHistory(endpoint, requestData),
  })

  const onEndReached = () => {
    if (table.count > table.data.length) {
      table.fetchNextPage()
    }
  }

  return (
    <SideBar
      title="Upload history"
      isOpen={isOpen}
      onClose={() => onClose(false)}
      value="wide"
      sideProps={{ scrollRef: ref }}
    >
      {table.data && table.data.length > 0 ? (
        <Virtuoso
          data={table.data}
          customScrollParent={ref?.current || undefined}
          overscan={150}
          // https://github.com/petyosi/react-virtuoso/issues/341
          initialItemCount={table.data.length - 1}
          endReached={onEndReached}
          components={{ Scroller }}
          itemContent={index => {
            const item = table.data[index]
            if (!item) {
              return null
            }

            return (
              <Item
                variant="disclosure"
                use={InternalLink}
                to={pathToUrl(nextRoute, { id: item.id })}
                key={item.id}
              >
                <Item.Content>
                  <Item.Title>{item.file?.name}</Item.Title>
                  <Item.Description>
                    {formatDateTime(item.creation_date_time)}
                  </Item.Description>
                  <List variant="compact" mt="s-4">
                    <List.Item
                      useIcon={getStatusIcon(item.state.id)}
                      color={getStatusThemeColor(item.state.id)}
                    >
                      {item.state.name}
                    </List.Item>
                    <List.Item useIcon={Profile} color={Token.color.greyTone50}>
                      {item.created_by.full_name}
                    </List.Item>
                  </List>
                </Item.Content>
              </Item>
            )
          }}
          useWindowScroll
        />
      ) : null}
      {table.loading && (
        <ItemSkeleton>
          <ItemSkeleton.Content />
        </ItemSkeleton>
      )}
      {!table.loading && table.count === 0 ? (
        <StatusWidget>
          <StatusWidget.Title>History is empty</StatusWidget.Title>
          <StatusWidget.Description>
            No {name} have been imported yet
          </StatusWidget.Description>
        </StatusWidget>
      ) : null}
    </SideBar>
  )
}
