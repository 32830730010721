import { useSelector } from 'react-redux'

import { selectAuthenticated } from '@src/store/auth/selectors'
import { isCommercialRoot, isWorkspacesEnabled } from '@src/utils'
import { env, Environments } from '@src/constants/api'

export const useIsLandingPage = () => {
  const authenticated = useSelector(selectAuthenticated)
  const workspacesEnabled = isWorkspacesEnabled()

  return (
    window.location.pathname === '/' &&
    !authenticated &&
    (isCommercialRoot() ||
      env === Environments.developmentCommercialRoot ||
      workspacesEnabled)
  )
}
