import React from 'react'
import { VStack } from '@revolut/ui-kit'

import Message from '../components/Message'
import OnboardingActions from '../components/OnboardingActions'
import { timeOffConfig } from '../common/constants'
import { ROUTES } from '@src/constants/routes'
import { useTable } from '@src/components/TableV2/hooks'
import {
  getTimeOffPolicyTempAssignments,
  timeOffPolicyTempAssignmentsAssign,
} from '@src/api/timeOff'
import { RowInterface } from '@src/interfaces/data'
import { TempPolicyAssignmentInterface } from '@src/interfaces/timeOff'
import {
  tempPolicyAssignmentDepartmentColumn,
  tempPolicyAssignmentEmailColumn,
  tempPolicyAssignmentEmployeeColumn,
  tempPolicyAssignmentLocationColumn,
  tempPolicyAssignmentPolicyColumn,
} from '@src/constants/columns/manageTimeOffPolicies'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklist'
import { StepCompletedWidget } from '../components/StepCompletedWidget'
import { PageBody } from '@src/components/Page/PageBody'
import Table from '@src/components/TableV2/Table'

const row: RowInterface<TempPolicyAssignmentInterface> = {
  cells: [
    {
      ...tempPolicyAssignmentEmployeeColumn,
      width: 200,
    },
    {
      ...tempPolicyAssignmentEmailColumn,
      width: 200,
    },
    {
      ...tempPolicyAssignmentLocationColumn,
      width: 200,
    },
    {
      ...tempPolicyAssignmentDepartmentColumn,
      width: 200,
    },
    {
      ...tempPolicyAssignmentPolicyColumn,
      width: 200,
    },
  ],
}

export const PolicyAssignments = () => {
  const table = useTable({ getItems: getTimeOffPolicyTempAssignments })

  const checkpoint = useGetOnboardingCheckpointCategory('timeOff')

  const stepCompleted =
    checkpoint.data?.state.id === 'completed' ||
    checkpoint.data?.current_step === 'Policy assignments'

  return (
    <>
      {stepCompleted ? (
        <PageBody>
          <StepCompletedWidget />
        </PageBody>
      ) : (
        <VStack space="s-16">
          <Message
            title="Let’s review the policy assignment before applying them"
            description="Please check any warnings so that employees don’t have wrong policies applied"
          />

          <Table.Widget>
            <Table.Widget.Table>
              <AdjustableTable
                name={TableNames.TempTimeOffPolicyAssignments}
                {...table}
                hideCountAndButtonSection
                row={row}
              />
            </Table.Widget.Table>
          </Table.Widget>
        </VStack>
      )}

      <OnboardingActions
        config={timeOffConfig}
        currentStep="Policy assignments"
        isLastStep={false}
        disableBack={false}
        pendingBack={false}
        disableNext={false}
        pendingNext={false}
        nextRoute={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.BALANCE_ADJUSTMENTS.IMPORT}
        previousRoute={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.POLICIES}
        updateSteps={!stepCompleted}
        isForm={false}
        nextButtonLabel={stepCompleted ? 'Next' : 'Apply Policies'}
        onBeforeSubmit={stepCompleted ? undefined : timeOffPolicyTempAssignmentsAssign}
      />
    </>
  )
}
