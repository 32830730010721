import React from 'react'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { TargetTypeFields } from './TargetTypesFields'
import { ApprovalProcessesFields } from './ApprovalProcessesFields'
import { SaveApprovalSettingsButton } from '@src/pages/Settings/common/SaveApprovalSettingsButton'
import { PageActions } from '@components/Page/PageActions'

export const KPI = () => {
  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangePerformancePreferences)

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="Settings that apply to all categories of KPIs"
          />
          <TargetTypeFields disableEdit={disableEdit} variant="KPI" />
          <ApprovalProcessesFields disableEdit={disableEdit} variant="KPI" />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <SaveApprovalSettingsButton />
      </PageActions>
    </>
  )
}
