import React, { createContext, useContext, useMemo, useState } from 'react'
import { noop } from 'lodash'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { StageSidebar } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/StageSidebar'

interface Context {
  hasSidebar: boolean
  show: VoidFunction
  hide: VoidFunction
}

const defaultContext: Context = {
  hasSidebar: false,
  show: noop,
  hide: noop,
}

export const SidebarProviderContext = createContext<Context>(defaultContext)

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
  children: React.ReactNode
}

export const SidebarProvider = ({ cycle, stage, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasSidebar = [
    ReviewCycleStage.TeamGoals,
    ReviewCycleStage.DepartmentGoals,
  ].includes(stage)

  const show = () => {
    if (hasSidebar) {
      setIsOpen(true)
    }
  }

  const hide = () => {
    setIsOpen(false)
  }

  const context: Context = useMemo(
    () => ({
      hasSidebar,
      show,
      hide,
    }),
    [hasSidebar],
  )

  return (
    <SidebarProviderContext.Provider value={context}>
      {children}
      <StageSidebar cycle={cycle} stage={stage} open={isOpen} onClose={hide} />
    </SidebarProviderContext.Provider>
  )
}

export const useSidebarContext = () => useContext(SidebarProviderContext)
