import React from 'react'
import { Box } from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import {
  dynamicGroupsGroupNameColumn,
  dynamicGroupsMemberCountColumn,
} from '@src/constants/columns/dynamicGroups'
import { useTable } from '@src/components/TableV2/hooks'
import { getDynamicGroups } from '@src/api/dynamicGroups'
import { TableNames } from '@src/constants/table'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Message from '../components/Message'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const row: RowInterface<DynamicGroupInerface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, { id: data.id })),
  cells: [
    {
      ...dynamicGroupsGroupNameColumn,
      width: 200,
    },
    {
      ...dynamicGroupsMemberCountColumn,
      width: 60,
    },
  ],
}

export const ManageEligibilityGroups = () => {
  const table = useTable({ getItems: getDynamicGroups })

  return (
    <PageWrapper>
      <PageHeader
        title="Manage groups"
        backUrl={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.POLICIES}
      />

      <PageBody>
        <Box mb="s-16">
          <Message
            title="We suggested groups just for you"
            description="Based on your employees country of residence we have added groups for you. Adjust, edit or delete them to your needs."
          />
        </Box>

        <Table.Widget>
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              <PrimaryAction
                useIcon="Plus"
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL)}
              >
                Add group
              </PrimaryAction>
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <AdjustableTable
              {...table}
              row={row}
              name={TableNames.ManageDynamicGroups}
              useWindowScroll
              dataType="Group"
              emptyState={<EmptyTableRaw title="Groups will appear here" />}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
    </PageWrapper>
  )
}
