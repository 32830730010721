import { RowInterface } from '@src/interfaces/data'
import { GrowthPlanPerformanceMetricInterface } from '@src/interfaces/growthPlans'
import {
  functionalSkillNameColumn,
  getCurrentRatingColumn,
  getTargetRatingColumn,
  RowProps,
} from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/constants/columns'

export const getRow = (
  props: RowProps,
): RowInterface<GrowthPlanPerformanceMetricInterface> => ({
  cells: [
    {
      ...functionalSkillNameColumn,
      width: 100,
    },
    {
      ...getCurrentRatingColumn(props),
      width: 100,
    },
    {
      ...getTargetRatingColumn(props),
      width: 100,
    },
  ],
})
