import React, { useEffect, useState } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { connect } from 'lape'
import { setAutoFreeze } from 'immer'

import NotificationSystem from '@components/Notification/NotificationSystem'
import { selectAuthenticated, selectUser } from '@src/store/auth/selectors'
import { API, COOKIE, SESSION_STORAGE, WSS_ENDPOINT } from '@src/constants/api'
import {
  logOutAction,
  setAuthenticatedAction,
  setFeatureFlagsAction,
  setPermissionsAction,
  setSubscriptionStateAction,
  setUserAction,
} from '@src/store/auth/actions'
import { UserInterface } from '@src/interfaces/user'
import AdminInterface from '@src/features/AdminInterface/AdminInterface'
import { Feedback } from '@src/features/Feedback/Feedback'
import { WebsocketType } from '@src/interfaces/data'
import DevWarningBar from '@src/features/DevWarningBar/DevWarningBar'
import { DemoModeWarningBar } from '@src/features/DemoMode/DemoModeWarningBar'

import { PUBLIC_PREFIX, ROUTES, SIGNUP, WORKSPACES } from '@src/constants/routes'
import MainSections from '../MainSections/MainSections'
import LoginRedirect from '../Login/LoginRedirect'
import { entriesToObject } from '@src/utils/queryParamsHooks'
import { apiWithoutHandling } from '@src/api'
import { setFeedbackOpen } from '@src/store/feedback/actions'
import ClosePopup from '@src/pages/Login/ClosePopup'
import { finishUpload } from '@src/store/performance/actions'
import { UploadResultInterface } from '@src/interfaces/supportTool'
import EngagementQuestionPage from '@src/pages/Engagement/EngagementQuestionPage'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import Onboarding from '../Onboarding'
import { OnboardingV2 } from '../OnboardingV2'
import LoginRouter from '@src/pages/Login/Router'
import MyProfile from '../MyProfile/MyProfile'
import { IdStatuses } from '@src/interfaces/employees'
import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'
import GlobalSearchSidebar from '@components/GlobalSearchSidebar/GlobalSearchSidebar'
import PublicRouter from '@src/pages/Public/PublicRouter'
import PermissionTransferRouter from '../PermissionTransfer'
import GrantPermissionsRedirect from '@src/pages/GrantPermissionsRedirect/GrantPermissionsRedirect'
import { WhoAmIInterface } from '@src/interfaces/auth'
import { MainLayout } from '@src/features/MainLayout/MainLayout'
import AppRouter from '@src/pages/AppRouter'
import { HelpCenter } from '@src/components/HelpCenter/HelpCenter'
import { BannerInterface } from '@src/interfaces/banners'
import { useQueryClient } from 'react-query'
import {
  AppRedirectRouter,
  DASHBOARD_ROUTE,
  LEGACY_HUB_ROUTE,
  LEGACY_TABLE_ROUTE,
} from '../AppRedirectRouter'
import { getWSToken } from '@src/api/login'
import SignupPage from '@src/pages/SignUp'
import { Flex } from '@revolut/ui-kit'
import { RevbetsPage } from '../Revbets/Revbets'
import {
  SystemNotificationDescriptionInterface,
  SystemNotificationsUnreadInterface,
} from '@src/interfaces/systemNotifications'
import { signupState } from '@src/pages/SignUp/common'
import { cookiesApi } from '@src/utils/cookies'
import { Authenticated } from '@src/store/auth/constants'
import { Plans } from '@src/pages/Forms/Plans/Plans'
import { useDevSetPipeline } from '@src/pages/Login/common'
import {
  sendNotification,
  useNotificationPermission,
} from '@src/features/Notifications/browserNotifications'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { AccountClosed } from '@src/pages/AccountClosed/AccountClosed'
import { AccountRestricted } from '@src/pages/AccountRestricted/AccountRestricted'
import { TestingPeriodEnded } from '@src/pages/TestingPeriodEnded/TestingPeriodEnded'
import { LandingPage } from '@src/pages/Landing/Landing'
import { isCommercial, isWorkspacesEnabled } from '@src/utils'
import OnboardingChecklist from '../OnboardingChecklist'
import { OnboardingChecklistV2 } from '../OnboardingChecklistV2'
import TestingCycleWarningBar from '@src/features/TestingCycleWarningBar/TestingCycleWarningBar'
import { SandboxPage } from '@src/pages/Sandbox/SandboxPage'
import { useSaveLocationHistory } from '@src/utils/useSaveLocationHistory'
import { useCurrentTenantInfo } from '@src/api/tenants'
import { usePrefetchOnboardingData } from '@src/pages/OnboardingChecklistV2/common/hooks'
import { NewInterviewScheduling } from '@src/pages/Forms/Candidate/ScheduleInterview'
import { CVScreening } from '@src/pages/Forms/CVScreening/CVScreening'
import { CandidateProfile } from '@src/pages/Forms/Candidate'
import { ContactUs } from '@src/pages/Landing/ContactUs'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { LocalStorageKeys } from '@src/store/auth/types'
import { LoginSSO } from '@src/pages/Login/LoginSSO'
import { useIsLandingPage } from '@src/pages/Landing/hooks/useIsLandingPage'

setAutoFreeze(false)

const App = () => {
  const authenticated = useSelector(selectAuthenticated)
  const user = useSelector(selectUser)
  const [isLoading, setIsLoading] = useState(true)
  const [isAccountClosed, setIsAccountClosed] = useState(false)
  const [isAccountRestricted, setIsAccountRestricted] = useState(false)
  const [shouldPrefetchOnboardingData, setShouldPrefetchOnboardingData] = useState(false)
  const queryClient = useQueryClient()
  useNotificationPermission(authenticated)
  const dispatch = useDispatch()

  const tenantInfo = useCurrentTenantInfo()

  const workspacesEnabled = isWorkspacesEnabled()

  usePrefetchOnboardingData(shouldPrefetchOnboardingData)

  const isContactUs = useRouteMatch({
    path: ROUTES.CONTACT_US,
    strict: true,
    sensitive: true,
  })
  const isPublic = useRouteMatch({
    path: PUBLIC_PREFIX,
    strict: true,
    sensitive: true,
  })
  const isSignup = useRouteMatch({
    path: SIGNUP,
    strict: true,
    sensitive: true,
  })
  const isLogin = useRouteMatch({
    path: ROUTES.LOGIN.ANY,
    strict: true,
    sensitive: true,
  })
  const isWorkspace = useRouteMatch({
    path: WORKSPACES.ANY,
    strict: true,
    sensitive: true,
  })
  const onboardingRouteMatch = useRouteMatch(ROUTES.ONBOARDING.ANY)
  const onboardingV2RouteMatch = useRouteMatch(ROUTES.ONBOARDING_V2.ANY)
  const isOnboarding = !!onboardingRouteMatch
  const isOnboardingV2 = !!onboardingV2RouteMatch
  const isLandingPage = useIsLandingPage()

  const isTwoFactorLogin =
    sessionStorage.getItem(SESSION_STORAGE.TWO_FACTOR_LOGIN) != null

  useDevSetPipeline()
  useSaveLocationHistory()

  useEffect(() => {
    if (
      !isPublic &&
      !isOnboarding &&
      !isOnboardingV2 &&
      !isSignup &&
      !isLandingPage &&
      !isWorkspace &&
      !isContactUs
    ) {
      authenticatedEffect()
    } else if (isTwoFactorLogin) {
      setIsLoading(false)
      navigateTo(ROUTES.TWO_FACTOR_LOGIN)
    } else {
      setIsLoading(false)
    }
  }, [
    authenticated,
    isPublic,
    isOnboarding,
    isOnboardingV2,
    isSignup,
    isWorkspace,
    isContactUs,
  ])

  useEffect(() => {
    if (!isLoading) {
      window._revolut.splashLoader.finish()
    }
  }, [isLoading])

  const authenticatedEffect = async () => {
    setIsLoading(true)

    if (isTwoFactorLogin) {
      setIsLoading(false)
      navigateTo(ROUTES.TWO_FACTOR_LOGIN)
      return
    }

    if (!authenticated) {
      if (!isLogin) {
        dispatch(logOutAction())
      }
      /** Need to hide the full page spinner for `/login` routes */
      setIsLoading(false)
      return
    }

    try {
      /** We need to fetch the onboarding related data before /whoami response, to avoid delaying rendering of content */
      setShouldPrefetchOnboardingData(true)

      const data = await apiWithoutHandling.get<WhoAmIInterface>(
        API.WHOAMI,
        undefined,
        undefined,
        true,
      )
      if (data?.data?.employee) {
        dispatch(setUserAction(data?.data?.employee))
      }

      if (data?.data?.authenticated) {
        const permissions = data.data?.permissions
        const newFeatureFlags = data.data?.feature_flags
        const subscriptionState = data.data?.subscription_state

        workspaceLocalStorage.setItem(
          LocalStorageKeys.AUTHENTICATED,
          Authenticated.authenticated,
        )
        cookiesApi.set(COOKIE.AUTHENTICATED, Authenticated.authenticated)

        dispatch(setSubscriptionStateAction(subscriptionState))
        dispatch(setAuthenticatedAction(true))
        dispatch(setPermissionsAction(permissions))
        dispatch(setFeatureFlagsAction(newFeatureFlags))

        const query = entriesToObject(
          Array.from(new URLSearchParams(window.location.search).entries()),
        )

        if (query.openFeedback) {
          dispatch(setFeedbackOpen(true))
        }

        if (data.data.subscription_state === 'closed') {
          setIsAccountClosed(true)
        }
        if (data.data.subscription_state === 'restricted') {
          setIsAccountRestricted(true)
        }

        Sentry.setUser({ id: `${user.id}` })

        try {
          // TODO: https://revolut.atlassian.net/browse/REVCOR-2712 revert after infra is fixed
          if (isCommercial()) {
            return
          }

          // WS doesn't work for onboarding users
          if (data?.data?.employee?.status?.id === IdStatuses.onboarding) {
            return
          }

          const wsToken = await getWSToken()

          const webSocket = new WebSocket(
            `${WSS_ENDPOINT()}?token=${encodeURIComponent(wsToken.data.wss_token)}`,
          )

          webSocket.onmessage = e => {
            const event = JSON.parse(e.data)
            if (event.type === WebsocketType.Permission) {
              const newUser: UserInterface = event.data
              dispatch(setPermissionsAction(newUser?.user_permissions))
            }
            if (event.type === WebsocketType.PerformanceGradesUpload) {
              const result: UploadResultInterface = event.data
              dispatch(finishUpload(result.success))
            }
            if (
              event.type === WebsocketType.BannerAdd ||
              event.type === WebsocketType.BannerRemove
            ) {
              const result: BannerInterface = event.data
              queryClient.setQueryData([API.BANNERS, 'v1', null], result)
            }
            if (event.type === WebsocketType.NotificationCountChanged) {
              const result: SystemNotificationsUnreadInterface = event.data
              queryClient.setQueryData(
                [`${API.SYSTEM_NOTIFICATIONS}/unread`, 'v1', null],
                result,
              )
            }
            if (event.type === WebsocketType.NotificationAdd) {
              const result: SystemNotificationDescriptionInterface = event.data
              sendNotification(result)
            }
          }
        } catch {
          Sentry.captureMessage('GET WebSocket token failed')
        }
      }

      if (data?.data?.employee?.status.id === IdStatuses.onboarding) {
        const onboardingId = cookiesApi.get(COOKIE.COOKIE_EMPLOYEE_ONBOARDING_V2_ID)

        if (onboardingId) {
          navigateReplace(
            pathToUrl(ROUTES.ONBOARDING_V2.START, {
              id: data.data.employee.id,
              onboardingId,
            }),
          )
        } else {
          navigateReplace(
            pathToUrl(ROUTES.ONBOARDING.START, { id: data.data.employee.id }),
          )
        }
      }
    } catch (e) {
      console.warn(e)

      /** Can happen in case they enter non existing tenant, in which case, we redirect to login, where they wont find any login methods, but will be able to change the workspace */
      if (workspacesEnabled && e.response == null) {
        dispatch(logOutAction())
      }
    } finally {
      setIsLoading(false)
    }
  }

  if (isAccountClosed) {
    return (
      <>
        <DevWarningBar />
        <AdminInterface />
        <AccountClosed />
      </>
    )
  }

  if (isAccountRestricted) {
    return (
      <>
        <DevWarningBar />
        <AdminInterface />
        <AccountRestricted />
      </>
    )
  }

  if (tenantInfo?.demo_mode_expired) {
    return (
      <>
        <DevWarningBar />
        <AdminInterface />
        <Switch>
          <Route exact path={ROUTES.PLANS.ANY}>
            <Plans />
          </Route>
          <Route>
            <TestingPeriodEnded />
          </Route>
        </Switch>
      </>
    )
  }

  if (isPublic) {
    return (
      <>
        <DevWarningBar />
        <AdminInterface />
        <PublicRouter />
      </>
    )
  }

  if (isSignup || signupState.cookie != null) {
    return (
      <>
        <DevWarningBar />
        <NotificationSystem />
        <AdminInterface />
        <SignupPage />
      </>
    )
  }

  if (isLoading) {
    return (
      <Flex width="100%" height="100vh" alignItems="center" justifyContent="center">
        <PageLoading />
      </Flex>
    )
  }

  return (
    <>
      <DevWarningBar />
      <TestingCycleWarningBar />
      <NotificationSystem />
      <Feedback />
      <AdminInterface />
      <Switch>
        <Route exact path={ROUTES.REVBETS}>
          <RevbetsPage />
        </Route>
        {isLandingPage && (
          <Route exact path={ROUTES.MAIN}>
            <LandingPage />
          </Route>
        )}
        <Route exact path={ROUTES.CONTACT_US}>
          <ContactUs />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE.MY_PROFILE} component={MyProfile} />
        <Route exact path={ROUTES.ENGAGEMENT_PAGE} component={EngagementQuestionPage} />
        <Route exact path={ROUTES.LOGIN.REDIRECT} component={LoginRedirect} />
        <Route exact path={ROUTES.LOGIN.SSO} component={LoginSSO} />
        <Route exact path={ROUTES.SANDBOX} component={SandboxPage} />
        <Route
          exact
          path={ROUTES.GRANT_PERMISSIONS_REDIRECT}
          component={GrantPermissionsRedirect}
        />
        <Route exact path={ROUTES.CLOSE_POPUP} component={ClosePopup} />
        <Route
          exact
          path={[
            ROUTES.MAIN,
            ROUTES.PEOPLE.ANY,
            ROUTES.RECRUITMENT.ANY,
            ROUTES.ORGANISATION.ANY,
            ROUTES.FUNCTION.ANY,
            ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD,
            ROUTES.PERFORMANCE.ANALYTICS.ANY,
            ROUTES.PERFORMANCE.KPI.ANY,
            ROUTES.PERFORMANCE.GOALS.ANY,
            ROUTES.PERFORMANCE.REVIEWS.ANY,
            ROUTES.FEATURES.ANY,
            ROUTES.PINNED,
          ]}
        >
          <MainLayout fullWidth>
            <MainSections />
          </MainLayout>
        </Route>
        <Route exact path={ROUTES.ONBOARDING.ANY} component={Onboarding} />
        <Route exact path={ROUTES.ONBOARDING_V2.ANY} component={OnboardingV2} />
        <Route
          path={[ROUTES.LOGIN.MAIN, WORKSPACES.ANY, ROUTES.TWO_FACTOR_LOGIN]}
          component={LoginRouter}
        />
        <Route exact path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ANY}>
          <MainLayout>
            <PermissionTransferRouter />
          </MainLayout>
        </Route>
        <Route exact path={ROUTES.PLANS.ANY}>
          <Plans />
        </Route>
        <Route path={ROUTES.ONBOARDING_CHECKLIST.ANY}>
          <DemoModeWarningBar />
          <OnboardingChecklist />
        </Route>
        <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.ANY}>
          <DemoModeWarningBar />
          <OnboardingChecklistV2 />
        </Route>
        <Route
          path={[
            LEGACY_HUB_ROUTE,
            DASHBOARD_ROUTE,
            LEGACY_TABLE_ROUTE,
            ROUTES.FORMS.TEAM.GENERAL,
            ROUTES.FORMS.DEPARTMENT.GENERAL,
            ROUTES.FUNCTION.SKILLS,
            ROUTES.FORMS.INTERVIEW_DASHBOARD.PENDING,
            ROUTES.FORMS.INTERVIEW_DASHBOARD.COMPLETED,
            ROUTES.FORMS.INTERVIEW_TOOL_SCORECARD.ANY,
            ROUTES.FORMS.INTERVIEW_TOOL_CANDIDATE.ANY,
            ROUTES.PERFORMANCE.SUPPORT_TOOL.ANY,
            ROUTES.PERFORMANCE.REVIEW_CYCLES,
            ROUTES.PLATFORM_ONBOARDING.SETUP_STEPS.ANY,
            ROUTES.FORMS.EMPLOYEE.KPI.ANY,
            ROUTES.FORMS.EMPLOYEE.ENGAGEMENT.CATEGORIES,
            ROUTES.FORMS.EMPLOYEE.ENGAGEMENT.QUESTIONS,
            ROUTES.FORMS.EMPLOYEE.ENGAGEMENT.COMMENTS,
            ROUTES.FORMS.EMPLOYEE.TALENT.ANY,
            ROUTES.PLATFORM_ONBOARDING.INVITE_TEAM,
            ROUTES.IAP,
            ROUTES.DEEL_INTEGRATION_REDIRECT,
          ]}
        >
          <AppRedirectRouter />
        </Route>
        {/* to use context with Sidebar inside UI kit layout, it should wrap the whole Layout */}
        <Route path={ROUTES.FORMS.SCHEDULE_INTERVIEW.ANY}>
          <NewInterviewScheduling />
        </Route>
        <Route exact path={ROUTES.FORMS.CV_SCREENING}>
          <CVScreening />
        </Route>
        <Route exact path={ROUTES.FORMS.CANDIDATE.ANY}>
          <CandidateProfile />
        </Route>
        <Route path={ROUTES.ANY}>
          <MainLayout>
            <AppRouter />
          </MainLayout>
        </Route>
      </Switch>
      <GlobalSearchSidebar
        isOpen={!!globalSearchState.open}
        showAction={globalSearchState.showAction}
        onClose={() => {
          globalSearchState.open = false
          globalSearchState.showAction = false
        }}
      />
      {!isOnboarding && !isOnboardingV2 && <HelpCenter />}
    </>
  )
}

export default connect(App)
