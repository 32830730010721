import React from 'react'
import {
  Avatar,
  Cell,
  Item,
  VStack,
  Token,
  ActionButton,
  Link,
  Banner,
} from '@revolut/ui-kit'

import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useGetDocusignConsent, useGetDocuSignPreferences } from '@src/api/integrations'

export const DocuSignConsentAccountItem = () => {
  const { data: docusignPreferences } = useGetDocuSignPreferences()
  const isEnabled = docusignPreferences?.enabled
  const { data: docusignConsentData } = useGetDocusignConsent()
  const docusignConsentUrl = docusignConsentData?.url

  if (!isEnabled) {
    return null
  }
  if (!docusignConsentUrl) {
    return (
      <Banner>
        <Banner.Avatar>
          <Avatar image="https://assets.revolut.com/revoluters/hub-icons/docusign-60.png">
            <Avatar.Badge
              useIcon="Check"
              color={Token.color.background}
              bg={Token.color.success}
            />
          </Avatar>
        </Banner.Avatar>
        <Banner.Content>
          <Banner.Title>Docusign integration enabled</Banner.Title>
          <Banner.Description>
            If you haven't provided a consent to use it yet, your account probably doesn't
            exist. Please, contact your system administrator in this case.
          </Banner.Description>
        </Banner.Content>
      </Banner>
    )
  }
  return (
    <Cell p={0}>
      <VStack flex="1 0">
        <ActionWidget
          title="Why do we need consent from DocuSign?"
          text="To be able to issue documents from within Revolut People, we need to get consent from DocuSign"
          avatarColor={Token.color.greyTone20}
          bg="transparent" // TODO: REVCOR-3046 revisit after ui-kit update
        />
        {/** TODO: REVCOR-3046 revisit after ui-kit update */}
        <Item style={{ background: 'transparent' }}>
          <Item.Avatar>
            <Avatar image="https://assets.revolut.com/revoluters/hub-icons/docusign-60.png">
              <Avatar.Badge
                useIcon="16/SandWatch"
                color={Token.color.background}
                bg={Token.color.warning}
              />
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Title>DocuSign</Item.Title>
            <Item.Description color={Token.color.orange}>Pending action</Item.Description>
          </Item.Content>
          <Item.Side>
            <ActionButton
              useIcon="LinkExternal"
              use={Link}
              href={docusignConsentUrl}
              target="_blank"
            >
              Give consent
            </ActionButton>
          </Item.Side>
        </Item>
      </VStack>
    </Cell>
  )
}
