import React, { useMemo, useState } from 'react'
import { Button, Header, MoreBar, Popup } from '@revolut/ui-kit'
import StagePicker from '@src/pages/Forms/Candidate/InterviewProgress/components/StagePicker'
import {
  InterviewStageWithoutRoundInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import { createFeedback } from '@src/api/recruitment/interviewFeedback'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { Plus } from '@revolut/icons'
import { NON_ELIGIBLE_FEEDBACK_STAGES } from '@src/pages/Forms/Candidate/utils'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { useGetStagesWithoutDuplicates } from '@src/pages/Forms/Candidate/InterviewProgress/useGetStagesWithoutDuplicates'

type Props = {
  data: InterviewStageWithoutRoundInterface[]
  currentStageId?: number
}

const AddFeedback = ({ data, currentStageId }: Props) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const [isOpen, setIsOpen] = useState(false)
  const [stageId, setStageId] = useState<number | undefined>(currentStageId)
  const user = useSelector(selectUser)
  const stages = useGetStagesWithoutDuplicates(data)

  const onClose = () => {
    setStageId(undefined)
    setIsOpen(false)
  }

  const addFeedback = async () => {
    const resp = await createFeedback(stageId!, user as InterviewToolInterviewer)
    navigateTo(pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, { id: resp.data.id }))
  }

  const eligibleStages = useMemo(
    () =>
      stages.filter(item => !NON_ELIGIBLE_FEEDBACK_STAGES.includes(item.interview_type)),
    [stages],
  )

  return (
    <>
      <MoreBar.Action
        useIcon={Plus}
        onClick={e => {
          e.preventDefault()
          sendAnalyticsEvent(AnalyticsEvents.click_add_feedback_candidate_profile)
          setIsOpen(true)
        }}
      >
        Add feedback
      </MoreBar.Action>

      <Popup open={isOpen} onClose={onClose} variant="bottom-sheet">
        <Header variant="bottom-sheet">
          <Header.Title>Select stage to add feedback</Header.Title>
        </Header>
        <StagePicker
          data={eligibleStages}
          onClick={setStageId}
          selectedId={currentStageId}
          currentId={currentStageId}
        />
        <Popup.Actions horizontal>
          <Button variant="secondary" onClick={onClose} autoFocus>
            Cancel
          </Button>
          <Button elevated disabled={!stageId} onClick={addFeedback}>
            Add feedback
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export default AddFeedback
