import React from 'react'
import { useParams } from 'react-router-dom'
import { FilterButton, Token } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { getWorkScheduleReviewCustomApproversTableRequests } from '@src/api/workSchedule'
import Table from '@src/components/TableV2/Table'
import {
  timeOffWorkScheduleReviewCustomApproversEmployeeColumn,
  timeOffWorkScheduleReviewCustomApproversLocationColumn,
  timeOffWorkScheduleReviewCustomApproversRoleColumn,
  timeOffWorkScheduleReviewCustomApproversStatusColumn,
} from '@src/constants/columns/timeOffWorkScheduleReviewCustomApprovers'
import { TableNames } from '@src/constants/table'
import { useFilterToggle } from '@src/hooks/useFilterToggle'
import { RowInterface } from '@src/interfaces/data'
import {
  WorkScheduleReviewCustomApproversListItemInterface,
  WorkScheduleReviewCustomApproversStats,
} from '@src/interfaces/workSchedule'

const ROW: RowInterface<WorkScheduleReviewCustomApproversListItemInterface> = {
  cells: [
    {
      ...timeOffWorkScheduleReviewCustomApproversEmployeeColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleReviewCustomApproversLocationColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleReviewCustomApproversRoleColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleReviewCustomApproversStatusColumn,
      width: 200,
    },
  ],
}

type Props = {
  isReviewTab?: boolean
}
export const CustomApproversEligibilityTable = ({ isReviewTab }: Props) => {
  const params = useParams<{ id: string }>()
  const tableRequests = getWorkScheduleReviewCustomApproversTableRequests(params.id)
  const table = useTable<
    WorkScheduleReviewCustomApproversListItemInterface,
    WorkScheduleReviewCustomApproversStats
  >(tableRequests)

  const { toggle: toggleFilterByWarnings, isActive: isFilteredByWarnings } =
    useFilterToggle<
      WorkScheduleReviewCustomApproversListItemInterface,
      WorkScheduleReviewCustomApproversStats
    >({
      table,
      columnName: 'auto_assignments_details__valid',
      valueName: 'false',
    })

  return (
    <Table.Widget>
      {isReviewTab && (
        <Table.Widget.Info>
          <Stat label="Total" val={table.stats?.total} color={Token.color.foreground} />
          <Stat label="Warning" val={table.stats?.warning} color={Token.color.orange} />
          <Stat label="OK" val={table.stats?.ok} color={Token.color.green} />
        </Table.Widget.Info>
      )}
      {!!table.stats?.warning && isReviewTab && (
        <Table.Widget.Filters>
          <FilterButton
            active={isFilteredByWarnings}
            onClick={toggleFilterByWarnings}
            disabled={!table.stats?.warning}
          >
            Show warnings
          </FilterButton>
        </Table.Widget.Filters>
      )}
      <Table.Widget.Table>
        <AdjustableTable
          {...table}
          dataType="Eligible employee"
          name={TableNames.WorkSchedulesCustomApprovers}
          noDataMessage="Eligible employees not found"
          row={ROW}
          useWindowScroll
          hiddenCells={{
            [timeOffWorkScheduleReviewCustomApproversStatusColumn.idPoint]: !isReviewTab,
          }}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
