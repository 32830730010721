import { Item, Avatar, Token } from '@revolut/ui-kit'
import { ConnectMergeIntegrationButton } from '@src/pages/Hub/Integrations/components/ConnectMergeIntegrationButton'
import React from 'react'
import { useGetMergeConnections } from '@src/api/integrations'
import { MergeIntegration } from '@src/interfaces/integrations'

interface MergeItemProps {
  integration: MergeIntegration
  title: string
  description: string
  image: string
  onClick: VoidFunction
  onSuccess: VoidFunction
}

export const MergeItem = ({
  integration,
  title,
  description,
  image,
  onClick,
  onSuccess,
}: MergeItemProps) => {
  const { data: integrations } = useGetMergeConnections()

  const currentIntegration = integrations?.results?.find(
    int => int.integration_type === integration,
  )

  const lastSyncState = currentIntegration?.last_sync?.state
  const isPending = lastSyncState === 'pending' || lastSyncState === 'processing_file'
  const isCompleted =
    lastSyncState === 'success' ||
    lastSyncState === 'ready_for_review' ||
    lastSyncState === 'applying'

  return (
    <Item>
      <Item.Avatar>
        <Avatar image={image} bg="background">
          {isPending ? <Avatar.Badge useIcon="Loading" /> : null}
          {isCompleted ? <Avatar.Badge useIcon="Check" bg={Token.color.success} /> : null}
          {lastSyncState === 'failure' ? (
            <Avatar.Badge
              useIcon="Cross"
              bg={Token.color.danger}
              color={Token.color.foreground}
            />
          ) : null}
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        <Item.Description>{description}</Item.Description>
      </Item.Content>
      <Item.Side>
        <ConnectMergeIntegrationButton
          integration={integration}
          onClick={onClick}
          onSuccess={onSuccess}
          askConsent
          askToSyncAll
        >
          Connect
        </ConnectMergeIntegrationButton>
      </Item.Side>
    </Item>
  )
}
