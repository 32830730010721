import React, { useState } from 'react'
import { Link } from '@revolut/icons'
import { MoreBar } from '@revolut/ui-kit'
import GenerateLinkPopup from '@src/pages/Forms/Candidate/InterviewProgress/components/Popups/GenerateLinkPopup'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { useGetStagesWithoutDuplicates } from '@src/pages/Forms/Candidate/InterviewProgress/useGetStagesWithoutDuplicates'

type Props = {
  stages: InterviewStageWithoutRoundInterface[]
  round?: InterviewRoundInterface
  refresh: () => Promise<void>
  currentStageId?: number
}

const GenerateFeedbackLink = ({ round, stages, currentStageId, refresh }: Props) => {
  const stagesWithoutDuplicates = useGetStagesWithoutDuplicates(stages)
  const { sendAnalyticsEvent } = useAnalytics()

  const [openPopup, setOpenPopup] = useState<boolean>(false)

  return (
    <>
      <MoreBar.Action
        useIcon={Link}
        onClick={() => {
          sendAnalyticsEvent(AnalyticsEvents.click_generate_feedback_candidate_profile)
          setOpenPopup(true)
        }}
      >
        Generate interview link
      </MoreBar.Action>

      {round && (
        <GenerateLinkPopup
          data={stagesWithoutDuplicates}
          roundId={round.id}
          isOpen={openPopup}
          onRefresh={refresh}
          onClose={() => setOpenPopup(false)}
          initialStageId={currentStageId}
          currentStageId={currentStageId}
        />
      )}
    </>
  )
}

export default GenerateFeedbackLink
