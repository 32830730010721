import React from 'react'
import { FilterButton } from '@revolut/ui-kit'

import { RowInterface } from '@src/interfaces/data'
import Table from '@src/components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  notificationCreationDate,
  notificationDeliverySchedule,
  notificationDeliveryType,
  notificationLastSent,
  notificationName,
  notificationStatus,
  notificationType,
} from '@src/constants/columns/notificationTemplate'
import { getNotificationList } from '@src/api/notificationTemplate'
import { NotificationTemplateInterface } from '@src/interfaces/notificationTemplate'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetCommunicationSettings } from '@src/api/settings'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { useEmailNotificationsEnabled } from '@src/pages/Forms/NotificationTemplate/useEmailNotificationsEnabled'

const Row: RowInterface<NotificationTemplateInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.NOTIFICATION_TEMPLATE.PREVIEW, { id: data.id })),
  cells: [
    {
      ...notificationName,
      width: 240,
    },
    {
      ...notificationType,
      width: 160,
    },
    {
      ...notificationDeliveryType,
      width: 160,
    },
    {
      ...notificationDeliverySchedule,
      width: 200,
    },
    {
      ...ownerNameColumn,
      width: 200,
    },
    {
      ...notificationCreationDate,
      width: 200,
    },
    {
      ...notificationLastSent,
      width: 200,
    },
    {
      ...notificationStatus,
      width: 160,
    },
  ],
}

const NotificationsTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_NOTIFICATIONS_ASSIGNED_TO_ME,
    false,
  )

  const { data: settings } = useGetCommunicationSettings()
  const emailNotificationsEnabled = useEmailNotificationsEnabled()

  const allNotificationTypesDisabled =
    !(settings?.enable_email_notifications && emailNotificationsEnabled) &&
    !settings?.enable_slack_notifications &&
    !settings?.enable_system_notifications &&
    !settings?.enable_announcements &&
    !settings?.enable_sms_notifications

  const user = useSelector(selectUser)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<NotificationTemplateInterface>(
    {
      getItems: getNotificationList,
    },
    getInitialFilters(),
  )

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <Table.Widget>
      <Table.Widget.Filters>
        <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
          My notifications
        </FilterButton>
      </Table.Widget.Filters>
      {allNotificationTypesDisabled ? null : (
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <PrimaryAction
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.NOTIFICATION_TEMPLATE.GENERAL)}
              useIcon="Plus"
            >
              Add notification
            </PrimaryAction>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
      )}
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.UserNotifications}
          useWindowScroll
          row={Row}
          {...table}
          emptyState={<EmptyTableRaw title="Notifications will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default NotificationsTable
