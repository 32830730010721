import { useEffect, useState } from 'react'
import { CareersPositionLocation } from '@src/interfaces/careers'
import { locationsRequests } from '@src/api/locations'
import { LocationInterface } from '@src/interfaces/locations'
import { JobPostingLocationInterface } from '@src/interfaces/jobPosting'

export const useCareerPositionLocations = (
  locations: LocationInterface[] | JobPostingLocationInterface[],
) => {
  const [locationsWithType, setLocationsWithType] = useState<CareersPositionLocation[]>(
    [],
  )

  useEffect(() => {
    const fetchLocations = async () => {
      const resp = await locationsRequests.getItems({
        filters: [
          {
            columnName: 'id',
            filters: locations.map(item => ({
              id: item.id,
              name: String(item.id),
            })),
          },
        ],
      })

      setLocationsWithType(
        resp.data.results.map(item => ({
          name: item.location_name,
          country: item.country?.name || '',
          type: item.type?.id || 'office',
        })),
      )
    }

    if (locations.length) {
      fetchLocations()
    } else {
      setLocationsWithType([])
    }
  }, [locations])
  return locationsWithType
}
