import React from 'react'

import { Box, Color, Flex, TooltipState } from '@revolut/ui-kit'

const MIN_DISTRIBUTION_WIDTH = 0.05

type Distribution = {
  color: Color
  width: number
}

type Props = {
  distribution: Distribution[]
  tooltip: TooltipState<Element>
}
export const PreviewBar = ({ distribution, tooltip }: Props) => {
  const formattedDistribution = distribution
    .filter(datum => datum.width > 0)
    .map(datum => {
      if (datum.width < MIN_DISTRIBUTION_WIDTH) {
        return {
          ...datum,
          width: MIN_DISTRIBUTION_WIDTH,
        }
      }
      return datum
    })

  if (!formattedDistribution.length) {
    return <>N/A</>
  }
  return (
    <Flex
      flex="1 0"
      alignItems="center"
      radius="toast"
      height="s-16"
      {...tooltip.getAnchorProps()}
    >
      <Box
        width={formattedDistribution[0].width}
        height="s-8"
        bg={formattedDistribution[0].color}
        borderRadius={formattedDistribution.length > 1 ? '4px 0 0 4px' : '4px'}
      />
      {formattedDistribution.length > 2
        ? formattedDistribution
            .slice(1, formattedDistribution.length - 1)
            .map((datum, idx) => (
              <Box key={idx} width={datum.width} height="s-8" bg={datum.color} />
            ))
        : null}
      {formattedDistribution.length > 1 && (
        <Box
          width={formattedDistribution[formattedDistribution.length - 1].width}
          height="s-8"
          bg={formattedDistribution[formattedDistribution.length - 1].color}
          style={{ borderRadius: '0 4px 4px 0' }}
        />
      )}
    </Flex>
  )
}
