import { getColorForPortion } from '@src/features/ReviewCycle/components/Progress/styles'
import { Text } from '@revolut/ui-kit'
import React from 'react'

interface Props {
  dividend: number
  divisor: number
  separator: string
  description: string
  emptyDescription: string
  highlight: boolean
}

export const PortionLabel = ({
  dividend,
  divisor,
  separator,
  description,
  emptyDescription,
  highlight,
}: Props) => {
  const color = highlight ? getColorForPortion(dividend / divisor) : undefined

  if (divisor === 0) {
    return <Text>{emptyDescription}</Text>
  }

  return (
    <>
      <Text color={color}>{dividend}</Text>
      <Text>{separator}</Text>
      <Text>{divisor}</Text>
      {description && <Text> {description}</Text>}
    </>
  )
}
