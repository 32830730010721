import React from 'react'
import FilterButtonRadioSelect from '@components/FilterButtonRadioSelect/FilterButtonRadioSelect'
import { OptionInterface } from '@src/interfaces/selectors'

export const DEFAULT_WEEKS_COUNT = 2

export const DateFilterOptions = [
  {
    id: 1,
    name: '1 week',
  },
  {
    id: DEFAULT_WEEKS_COUNT,
    name: '2 weeks',
  },
  {
    id: 4,
    name: '4 weeks',
  },
]

type Props = {
  onChange: (option?: OptionInterface) => void
  value?: OptionInterface
  disabled?: boolean
}

const SlotDateFilter = ({ onChange, value, disabled }: Props) => {
  return (
    <FilterButtonRadioSelect
      options={DateFilterOptions}
      label="Dates"
      onChange={onChange}
      value={value}
      clearable={false}
      disabled={disabled}
      width={352}
    />
  )
}

export default SlotDateFilter
