import React, { useRef, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { ActionButton, Dropdown, Icon } from '@revolut/ui-kit'

import { InternalLink } from '@components/InternalLink/InternalLink'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useActiveProfileSidebar } from '@src/pages/EmployeeProfile/Layout/Summary/common'
import { ProfileSectionsData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { pathToUrl } from '@src/utils/router'
import { canViewPersonal } from '../../ProfileSummary/common'

type Props = {
  data: EmployeeInterface
  sectionsData: ProfileSectionsData
}
export const EditDropdown = ({ data, sectionsData }: Props) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const { setSidebarQueryParam, getUrlWithSidebarParam } = useActiveProfileSidebar()

  const canRequestNameChange = !!sectionsData.name.permissions?.canRequestChange
  const canRequestPositionChange = !!sectionsData.position.permissions?.canRequestChange
  const canRequestOrganisationChange =
    !!sectionsData.organisation.permissions?.canRequestChange
  const showPersonal = canViewPersonal(data)

  const hasActions =
    canRequestNameChange ||
    canRequestPositionChange ||
    canRequestOrganisationChange ||
    showPersonal

  const isProfileSummaryTab = useRouteMatch(ROUTES.FORMS.EMPLOYEE.PROFILE)

  if (!hasActions) {
    return null
  }
  return (
    <>
      <ActionButton
        ref={anchorRef}
        onClick={() => setOpen(!open)}
        useIcon={<Icon name="Pencil" size={16} />}
      >
        Edit
      </ActionButton>
      <Dropdown
        open={open}
        anchorRef={anchorRef}
        onClose={() => setOpen(false)}
        minWidth={180}
      >
        {canRequestNameChange && (
          <Dropdown.Item
            use={InternalLink}
            useIcon="CormorantGaramond"
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
              employeeId: data.id,
              type: 'name',
            })}
          >
            Name
          </Dropdown.Item>
        )}
        {showPersonal && (
          <Dropdown.Item
            use="button"
            useIcon="Profile"
            onClick={() => {
              setOpen(false)

              if (!isProfileSummaryTab) {
                navigateTo(getUrlWithSidebarParam('personalDetails'))
              } else {
                setSidebarQueryParam('personalDetails', true)
              }
            }}
          >
            Personal
          </Dropdown.Item>
        )}
        {canRequestPositionChange && (
          <Dropdown.Item
            use={InternalLink}
            useIcon="Services"
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
              employeeId: data.id,
              type: 'position',
            })}
          >
            Position
          </Dropdown.Item>
        )}
        {canRequestOrganisationChange && (
          <Dropdown.Item
            use={InternalLink}
            useIcon="Bank"
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
              employeeId: data.id,
              type: 'organisation',
            })}
          >
            Organisation
          </Dropdown.Item>
        )}
      </Dropdown>
    </>
  )
}
