import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useGetGrowthPlan, useGetGrowthPlanRelation } from '@src/api/growthPlans'
import { useIsGrowthPlansEnabled } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { Statuses } from '@src/interfaces'

interface Props {
  data?: GrowthPlanInterface
  isLoading: boolean
  refetch: VoidFunction
}

export const useGetEmployeeGrowthPlan = (employeeId: number): Props => {
  const isGrowthPlansEnabled = useIsGrowthPlansEnabled()
  const {
    data: relation,
    isLoading: isRelationLoading,
    refetch: refetchRelation,
  } = useGetGrowthPlanRelation(employeeId, isGrowthPlansEnabled)
  const {
    data,
    isLoading: isGrowthPlanLoading,
    refetch: refetchGrowthPlan,
  } = useGetGrowthPlan(
    relation?.current_growth_plan?.growth_plan?.id,
    isGrowthPlansEnabled &&
      relation?.current_growth_plan?.growth_plan?.status !== Statuses.draft,
  )

  const refetch = () => {
    refetchRelation()
    refetchGrowthPlan()
  }

  return {
    isLoading: isRelationLoading || isGrowthPlanLoading,
    data,
    refetch,
  }
}
