import React from 'react'
import { ActionButton, Avatar, Item, List, Token } from '@revolut/ui-kit'
import { features } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/components/ExtensionBanner/constants'
import {
  AvailabilityStatus,
  useExtensionAvailability,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/hooks/useExtensionAvailability'
import { MEETINGS_EXTENSION_INTERNAL } from '@src/constants/externalLinks'

export const OneToOneExtensionBanner = () => {
  const { isChecking, status } = useExtensionAvailability()

  if (isChecking || status === AvailabilityStatus.Installed) {
    return null
  }

  return (
    <Item>
      <Item.Avatar size={76}>
        <Avatar image="https://assets.revolut.com/assets/apps/Google.png" size={76} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>Get the Revolut Meeting Chrome extension</Item.Title>
        <Item.Description>
          Download our AI-powered extension to automatically capture and organise your 1-1
          meeting notes with ease.
        </Item.Description>
        <List variant="compact" pt="s-8" color={Token.color.greyTone50}>
          {features.map(feature => (
            <FeatureListItem key={feature} title={feature} />
          ))}
        </List>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <ActionButton
            use="a"
            target="_blank"
            rel="noreferrer noopener"
            href={MEETINGS_EXTENSION_INTERNAL}
          >
            Install extension
          </ActionButton>
        </Item.Value>
      </Item.Side>
    </Item>
  )
}

interface FeatureListItemProps {
  title: string
}

const FeatureListItem = ({ title }: FeatureListItemProps) => (
  <List.Item useIcon="16/StatusCheck">{title}</List.Item>
)
