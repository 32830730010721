import React, { useRef } from 'react'
import SideBar from '@components/SideBar/SideBar'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { SideProps } from '@revolut/ui-kit'
import {
  makeSidebarTitle,
  SidebarContent,
  SidebarSubtitle,
} from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/SidebarContent'
import { useSidebarContext } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/SidebarProvider'

interface Props extends SideProps {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
}

export const StageSidebar = ({ cycle, stage, open, onClose, ...sideProps }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { hasSidebar } = useSidebarContext()

  if (!hasSidebar) {
    return null
  }

  return (
    <SideBar
      isOpen={open}
      onClose={onClose}
      title={makeSidebarTitle(stage)}
      subtitle={<SidebarSubtitle cycle={cycle} stage={stage} />}
      useIcon="Flag"
      sideProps={{ scrollRef, ...sideProps }}
    >
      <SidebarContent cycle={cycle} stage={stage} scrollRef={scrollRef} />
    </SideBar>
  )
}
