import React from 'react'
import capitalize from 'lodash/capitalize'

import Table from '@components/TableV2/Table'
import {
  getSuccessionPlanStatusColor,
  getSuccessionPlanStatusVariant,
} from '@src/apps/People/KeyPersons/helpers'
import { getDefaultIdStatusVariant } from '@src/components/TableV2/Cells/StatusCell'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { KeyPersonSuccessionPlanInterface } from '@src/interfaces/keyPerson'
import { selectorKeys } from '../api'

export const keyPersonSuccessionPlanEmployeeColumn: ColumnInterface<KeyPersonSuccessionPlanInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.full_name',
    sortKey: 'employee__full_name',
    filterKey: 'employee__id',
    selectorsKey: selectorKeys.all_employees,
    title: 'Employee',
    insert: ({ data }) => (
      <UserWithAvatar name={data.employee.full_name} avatar={data.employee.avatar} />
    ),
  }

export const keyPersonSuccessionPlanEmployeeStatusColumn: ColumnInterface<KeyPersonSuccessionPlanInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.status',
    dataPoint: 'employee.status',
    sortKey: 'employee__status',
    filterKey: 'employee__status',
    selectorsKey: selectorKeys.employee_status_pending,
    title: 'Employee status',
    insert: ({ data }) => {
      return data.employee ? (
        <Table.StatusCell
          status={capitalize(data.employee.status?.replace('_', ' '))}
          variant={getDefaultIdStatusVariant(data.employee.status)}
        />
      ) : (
        '-'
      )
    },
  }

export const keyPersonSuccessionPlanResponsibilitiesColumn: ColumnInterface<KeyPersonSuccessionPlanInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee_responsibilities_count',
    dataPoint: 'employee_responsibilities_count',
    sortKey: 'employee_responsibilities_count',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Responsibilities',
  }

export const keyPersonSuccessionPlanTypeColumn: ColumnInterface<KeyPersonSuccessionPlanInterface> =
  {
    type: CellTypes.text,
    idPoint: 'succession_plan_type.id',
    dataPoint: 'succession_plan_type.name',
    sortKey: 'succession_plan_type',
    filterKey: 'succession_plan_type',
    selectorsKey: selectorKeys.key_person_succession_plan_type,
    title: 'Succession type',
  }

export const keyPersonSuccessionPlanUpdatedOnColumn: ColumnInterface<KeyPersonSuccessionPlanInterface> =
  {
    type: CellTypes.date,
    idPoint: 'update_date_time',
    dataPoint: 'update_date_time',
    sortKey: 'update_date_time',
    filterType: FilterType.date,
    filterKey: 'update_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Updated on',
  }

export const keyPersonSuccessionPlanValidUntilColumn: ColumnInterface<KeyPersonSuccessionPlanInterface> =
  {
    type: CellTypes.date,
    idPoint: 'valid_until_date_time',
    dataPoint: 'valid_until_date_time',
    sortKey: 'valid_until_date_time',
    filterType: FilterType.date,
    filterKey: 'valid_until_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Valid until',
  }

export const keyPersonSuccessionPlanStatusColumn: ColumnInterface<KeyPersonSuccessionPlanInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.key_risk_profile_succession_plan_statuses,
    title: 'Succession status',
    colors: data => getSuccessionPlanStatusColor(data.status?.id),
    insert: ({ data }) => {
      return data.employee ? (
        <Table.StatusCell
          status={capitalize(data.status.name)}
          variant={getSuccessionPlanStatusVariant(data.status.id)}
        />
      ) : (
        '-'
      )
    },
  }

export const keyPersonSuccessionPlanActionsColumn: ColumnInterface<KeyPersonSuccessionPlanInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: 'name',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }
