import React, { useMemo, useState } from 'react'
import { pathToUrl } from '@src/utils/router'
import { sumBy } from 'lodash'
import { useHistory } from 'react-router-dom'
import {
  ActionButton,
  Button,
  DetailsCell,
  Flex,
  Group,
  HStack,
  Header,
  Input,
  InputGroup,
  Popup,
  StatusPopup,
  Text,
  TextArea,
  Token,
} from '@revolut/ui-kit'

import {
  createPayment,
  createSettlement,
  paymentsTableRequests,
  useFilteredPaymentsSelector,
} from '@src/api/payroll'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { getSelectCellConfig } from '@src/components/TableV2/AdvancedCells/SelectCell/SelectCell'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import Table from '@src/components/TableV2/Table'
import Tooltip from '@src/components/Tooltip/Tooltip'
import {
  paymentsAmountColumn,
  paymentsCycleColumn,
  paymentsDirectionColumn,
  paymentsElementColumn,
  paymentsEmployeeColumn,
  paymentsPaygroupColumn,
  paymentsStatusColumn,
} from '@src/constants/columns/payments'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { useTableWithPersistentFilters } from '@src/hooks/useTableWithPersistentFilters'
import { IdAndName } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import {
  PayCyclePaymentInterface,
  PayCycleSettlementInterface,
} from '@src/interfaces/payroll'
import { formatMoneyWithCode } from '@src/utils/format'
import { findShareOptionsPayGroup } from '../../Compensation/helpers/findShareOptionsPayGroup'
import { isReconcilingExercisePayments, useUniformValue } from '../helpers'
import { FilterButtons } from './FilterButtons'
import { PAYMENTS_PERSISTENT_FILTERS_KEY } from './PaymentsTable'

const Row: RowInterface<PayCyclePaymentInterface> = {
  cells: [
    {
      ...getSelectCellConfig(),
    },
    { ...paymentsEmployeeColumn, width: 150 },
    { ...paymentsPaygroupColumn, width: 150 },
    { ...paymentsCycleColumn, width: 130 },
    { ...paymentsElementColumn, width: 130 },
    { ...paymentsDirectionColumn, width: 150 },
    { ...paymentsAmountColumn, width: 150 },
    { ...paymentsStatusColumn, width: 100 },
  ],
}

const PRECISION_FACTOR = 10000

const MATCHING_RULES: IdAndName<string>[] = [
  {
    id: 'settlement_ref',
    name: 'Settlement Ref',
  },
  {
    id: 'matching_ref',
    name: 'Matching Ref',
  },
  {
    id: 'both',
    name: 'Both',
  },
]

const MATCHING_RULES_OPTIONS = MATCHING_RULES.map(rule => ({
  value: rule,
  label: rule.name,
}))

export const ReconcilePaymentsTable = () => {
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<PayCyclePaymentInterface>>()
  const [submitPopupOpen, setSubmitPopupOpen] = useState(false)
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [date, setDate] = useState(new Date())
  const [reference, setReference] = useState('')
  const [description, setDescription] = useState('')
  const [customAmount, setCustomAmount] = useState<number>()
  const [matchingRule, setMatchingRule] = useState(MATCHING_RULES[0])
  const { payGroupId: shareOptionsPayGroupId } = findShareOptionsPayGroup()
  const history = useHistory()

  const initialFilterBy = [
    {
      filters: [{ id: 'True', name: 'True' }],
      columnName: 'payroll_settlement__reconcilable',
      nonResettable: true,
    },
  ]
  const table = useTableWithPersistentFilters(
    paymentsTableRequests,
    initialFilterBy,
    [],
    {},
    PAYMENTS_PERSISTENT_FILTERS_KEY,
    ['payroll_settlement__reconcilable'],
  )
  const exerciseOptionsMode = shareOptionsPayGroupId
    ? isReconcilingExercisePayments(shareOptionsPayGroupId, table.filterBy)
    : false

  const { data: selectedAllPayments, isLoading: isLoadingSelectedAllPayments } =
    useFilteredPaymentsSelector(table.filterBy)

  const selectedPayments = selectedData?.isAllSelected
    ? selectedAllPayments?.options.filter(
        payment => !selectedData.excludeListIds.has(`${payment.id}`),
      )
    : selectedData?.selectedRowsData

  const totalSelectedCurrency = useUniformValue<PayCyclePaymentInterface, string>(
    selectedPayments,
    'currency',
  )

  const selectedSettlementProfile = useUniformValue<PayCyclePaymentInterface, string>(
    selectedPayments,
    'payroll_element.settlement_profile',
  )

  const chargesCount = selectedPayments?.length || 0

  const totalSelectedAmountPrecise = sumBy(selectedPayments, payment =>
    Math.round(Number(payment.amount) * PRECISION_FACTOR),
  )

  const totalSelectedAmount = totalSelectedAmountPrecise / PRECISION_FACTOR

  const disabledReconcileChargesMessage = useMemo(() => {
    if (chargesCount === 0) {
      return 'You must first select some charges'
    }
    if (totalSelectedCurrency === null) {
      return "Can't reconcile payments with different currencies"
    }
    if (selectedSettlementProfile === null) {
      return "Can't reconcile payments with different settlement profiles"
    }
    return null
  }, [chargesCount, totalSelectedCurrency, selectedSettlementProfile])

  const needsRounding = !!customAmount && customAmount !== totalSelectedAmount
  const roundingSum =
    ((customAmount || 0) * PRECISION_FACTOR - totalSelectedAmountPrecise) /
    PRECISION_FACTOR

  const onSubmitReconciliation = async () => {
    if (!selectedData) {
      return
    }

    let roundingPaymentId

    if (needsRounding) {
      const { data: roundingPayment } = await createPayment({
        amount: roundingSum,
        currency: `${totalSelectedCurrency}`,
        entity_id: selectedPayments?.[0].entity_id,
        paygroup: selectedPayments?.[0].pay_cycle?.pay_group,
        is_rounding: true,
      })
      roundingPaymentId = roundingPayment.id
    }

    const toSubmit = {
      payroll_payments: selectedPayments?.map(payment => payment.id),
      reference,
      amount: needsRounding ? customAmount : totalSelectedAmount,
      currency: totalSelectedCurrency,
      expected_settlement_date: date.toISOString().split('T')[0],
      description,
      settlement_profile: selectedSettlementProfile,
      payment_type: totalSelectedAmount < 0 ? 'debit' : 'credit',
      rounding_id: needsRounding ? roundingPaymentId : undefined,
      matching_rule: matchingRule.id,
    }

    if (toSubmit.currency === null) {
      throw new Error('Can not submit when there are multiple currencies')
    }

    await createSettlement(toSubmit as PayCycleSettlementInterface)
    setSubmitPopupOpen(false)
    setConfirmationPopupOpen(false)
    setSuccessPopupOpen(true)
    history.push(
      pathToUrl(
        exerciseOptionsMode
          ? ROUTES.APPS.COMPENSATION.OPTION_EXERCISE
          : ROUTES.APPS.PAYROLL.PAYMENTS_TABLE,
      ),
    )
  }

  return (
    <PageWrapper>
      <PageHeader
        title={
          exerciseOptionsMode
            ? 'Reconcile exercise payments'
            : 'Reconcile payroll charges'
        }
        backUrl={
          exerciseOptionsMode
            ? ROUTES.APPS.COMPENSATION.OPTION_EXERCISE
            : ROUTES.APPS.PAYROLL.PAYMENTS_TABLE
        }
      />
      <PageBody maxWidth={Token.breakpoint.xl}>
        <Table.Widget>
          <Table.Widget.Info>
            <Flex gap="s-32">
              <Stat label="Charges selected" val={chargesCount} />
              {totalSelectedCurrency !== null && (
                <Stat
                  label="Total selected"
                  val={formatMoneyWithCode(totalSelectedAmount, totalSelectedCurrency)}
                />
              )}
            </Flex>
            {!exerciseOptionsMode && <FilterButtons table={table} />}
          </Table.Widget.Info>
          <Table.Widget.Actions>
            <HStack gap="s-16">
              <Tooltip text={disabledReconcileChargesMessage} placement="bottom">
                <ActionButton
                  variant="accent"
                  pending={selectedData?.isAllSelected && isLoadingSelectedAllPayments}
                  useIcon="Coins"
                  onClick={() => setSubmitPopupOpen(true)}
                  disabled={
                    !!disabledReconcileChargesMessage ||
                    (selectedData?.isAllSelected && isLoadingSelectedAllPayments)
                  }
                >
                  Reconcile charges
                </ActionButton>
              </Tooltip>
            </HStack>
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <SelectTableWrapper
              enabled
              onChange={setSelectedData}
              filters={table.filterBy}
              tableDataLength={table.data.length}
              tableData={table.data}
              primaryKey="id"
            >
              <AdjustableTable
                name={TableNames.PaymentsReconciliation}
                row={Row}
                useWindowScroll
                {...table}
              />
            </SelectTableWrapper>
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
      <Popup
        open={submitPopupOpen}
        onClose={() => setSubmitPopupOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="main">
          <Header.Title>Book settlement</Header.Title>
        </Header>
        <Text use="p" variant="caption" mb="s-16">
          Please provide details of the transaction that was made to pay for all the
          selected charges
        </Text>
        <InputGroup>
          <Group>
            <DetailsCell>
              <DetailsCell.Title>Settlement profile</DetailsCell.Title>
              <DetailsCell.Content>{selectedSettlementProfile}</DetailsCell.Content>
            </DetailsCell>
            <DetailsCell>
              <DetailsCell.Title>Amount</DetailsCell.Title>
              <DetailsCell.Content>
                {formatMoneyWithCode(totalSelectedAmount, totalSelectedCurrency || '')}
              </DetailsCell.Content>
            </DetailsCell>
          </Group>
          <Input
            label="Custom amount (optional)"
            type="number"
            value={customAmount}
            onChange={e => setCustomAmount(Number(e.currentTarget.value))}
          />
          <DatePickerInput
            value={date}
            onChange={newDate => {
              setDate(newDate || new Date())
            }}
          />
          <Input
            label="Reference"
            value={reference}
            onChange={e => {
              setReference(e.currentTarget.value)
            }}
          />
          <TextArea
            rows={2}
            label="Description"
            value={description}
            onChange={e => {
              setDescription(e.currentTarget.value)
            }}
          />
          <RadioSelectInput
            label="Matching rule"
            options={MATCHING_RULES_OPTIONS}
            value={matchingRule}
            onChange={val => setMatchingRule(val || MATCHING_RULES[0])}
          />
        </InputGroup>
        <Popup.Actions horizontal>
          <Button
            disabled={!description || !reference}
            elevated
            onClick={
              needsRounding
                ? () => setConfirmationPopupOpen(true)
                : onSubmitReconciliation
            }
          >
            {!needsRounding ? 'Submit reconciliation' : 'Submit with new rounding charge'}
          </Button>
        </Popup.Actions>
      </Popup>
      <ConfirmationDialog
        open={confirmationPopupOpen}
        onClose={() => setConfirmationPopupOpen(false)}
        label="Amounts don't match"
        onConfirm={onSubmitReconciliation}
        onReject={() => setConfirmationPopupOpen(false)}
        body={
          <Text>
            Please confirm that you want to round payments with {roundingSum}{' '}
            {totalSelectedCurrency} or check if it was a mistake.
          </Text>
        }
        yesMessage="Confirm"
        noMessage="Cancel"
      />
      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        // @ts-ignore
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Settlement successfully booked</StatusPopup.Title>
      </StatusPopup>
    </PageWrapper>
  )
}
