import React from 'react'
import { Flex, Placeholder } from '@revolut/ui-kit'

import Loader from '@src/components/CommonSC/Loader'

export const NoDataPlaceholder = () => (
  <Flex
    pb={96}
    flex="1"
    width="100%"
    height="100%"
    alignItems="flex-end"
    justifyContent="center"
  >
    <Placeholder>
      <Placeholder.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D221.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D221@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D221@3x.png',
        }}
      />
      <Placeholder.Title>No data found</Placeholder.Title>
    </Placeholder>
  </Flex>
)

export const withLoader = (isLoading: boolean, widgetContent: React.ReactNode) => {
  if (isLoading) {
    return <Loader />
  }
  return widgetContent
}
