import React from 'react'
import { ActionButton, ItemSkeleton, Placeholder, VStack, Widget } from '@revolut/ui-kit'
import { EligibleGroup } from '@src/features/ReviewCycle/PerformanceReviewCycle/EligibleEmployees/EligibleGroup'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { useNavigation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useNavigation'

interface Props {
  cycle: ReviewCyclesInterface
  groups: EligibleGroupInterface[]
  isLoading: boolean
}

export const EligibleGroupList = ({ cycle, groups, isLoading }: Props) => {
  const { toEditEligibleEmployees } = useNavigation()

  if (isLoading) {
    return <ItemSkeleton />
  }

  if (groups.length === 0) {
    return (
      <Widget>
        <Placeholder>
          <Placeholder.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D395.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D395@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D395@3x.png',
            }}
          />
          <Placeholder.Title>
            You don’t have any eligible people selected for the review
          </Placeholder.Title>
          <Placeholder.Description>
            Select teams, departments or particular employee to participate in the review
            cycle
          </Placeholder.Description>
          <Placeholder.Actions>
            <ActionButton useIcon="Plus" onClick={() => toEditEligibleEmployees(cycle)()}>
              Select employees
            </ActionButton>
          </Placeholder.Actions>
        </Placeholder>
      </Widget>
    )
  }

  return (
    <VStack space="s-8">
      {groups.map(({ id, name, filters, preview, sign, should_create_scorecards }) => (
        <EligibleGroup
          key={id}
          title={name}
          employeesCount={preview?.count ?? 0}
          filtersCount={filters ? Object.keys(filters).length : 0}
          isEligible={sign !== 'exclude'}
          isScoreCardsEnabled={should_create_scorecards}
        />
      ))}
    </VStack>
  )
}
