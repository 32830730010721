import React, { useEffect, useMemo, useState } from 'react'
import { RowInterface } from '@src/interfaces/data'
import {
  EpicOption,
  KPIEpicsTargetRow,
  KpiInterface,
  KpiTargetEpics,
} from '@src/interfaces/kpis'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { parseTargetEpicsForTable } from '@src/features/FormTabs/Kpi/KPITargets/RoadmapTargets/common'
import { ActionButton, Flex } from '@revolut/ui-kit'
import {
  getKpiTargetAction,
  getKpiTargetParentColumn,
  kpiTargetEpicsDueDate,
  kpiTargetEpicsNameColumn,
  kpiTargetEpicsProgress,
  kpiTargetEpicsStatus,
  kpiTargetOwnerColumn,
  kpiTargetStatusColumn,
} from '@src/constants/columns/kpiTargets'
import { KpiTargetActionsOptions } from '@components/ColumnInserts/KPITargetActions/KPITargetActions'
import { summaryTableProps } from '@src/features/Summary/common'
import {
  createTarget,
  openTarget,
} from '@src/features/FormTabs/Kpi/KPITargets/targetActions'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { useTable } from '@components/TableV2/hooks'
import { getKPITargetsListRequest, kpiTargetsRequestsNew } from '@src/api/kpis'
import { getGenericCyclesInfo } from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'
import { successNotification } from '@src/store/notifications/actions'
import { TableNames } from '@src/constants/table'
import { difference } from '@src/utils/form'
import SavingKPIPopup from '@src/features/FormTabs/Kpi/KPITargets/common/SavingKPIPopup'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

interface Props {
  readonly?: boolean
}

const getRow = (
  kpi: KpiInterface,
  options: KpiTargetActionsOptions,
  readonly?: boolean,
): RowInterface<KPIEpicsTargetRow | EpicOption> => ({
  cells: [
    {
      ...kpiTargetEpicsNameColumn,
      width: 180,
    },
    {
      ...getKpiTargetParentColumn(kpi),
      width: 100,
    },
    {
      ...kpiTargetEpicsProgress,
      width: 160,
    },
    {
      ...kpiTargetEpicsDueDate,
      width: 110,
    },
    {
      ...kpiTargetEpicsStatus,
      width: 110,
    },
    {
      ...kpiTargetOwnerColumn,
      width: 80,
    },
    {
      ...(kpiTargetStatusColumn as any),
      width: 100,
    },
    ...(!readonly
      ? [
          {
            ...getKpiTargetAction(options),
            width: 200,
          },
        ]
      : []),
  ],
})

const RoadmapTargetsTable = ({ readonly }: Props) => {
  const { values, initialValues, submit } = useLapeContext<KpiInterface>()
  const table = useTable<KpiTargetEpics>({
    getItems: getKPITargetsListRequest(values.id),
  })
  const [pending, setPending] = useState(false)
  const parsedTableData = useMemo(() => {
    if (!values.target_epics || values.target_epics.length < 1) {
      return []
    }
    return parseTargetEpicsForTable(values.target_epics)
  }, [values.target_epics])

  const { data: reviewCyclesData } = useGetReviewCycles({ exclude_adhoc: true })

  useEffect(() => {
    values.target_epics = table.data
  }, [table.data])

  const getAvailableCyclesInfo = (targetIndex: number | null) => {
    const reviewCycles = reviewCyclesData?.results || []
    return getGenericCyclesInfo(reviewCycles, {
      targetIndex,
      allTargets: values.target_epics,
    })
  }

  // >= 2 (not 1) here, because field 'target_epics' is always in the difference, and it's in the fieldsToExclude to avoid showing save button when nothing changed
  // this is happening bc we add first target when creating KPI using KPI's endpoint, but all future work with targets goes through target's endpoint,
  // and fields are not fully compatible between these two endpoints
  const hasChanges = Object.keys(difference(values, initialValues)).length >= 2

  const handleAdd = () => {
    const { availableCycles, defaultCycle, canEditCycle } = getAvailableCyclesInfo(null)
    createTarget({
      kpi: values,
      availableCycles,
      defaultCycle,
      canEditCycle,
    })
  }

  const onAdd = async () => {
    if (!hasChanges) {
      handleAdd()
    } else {
      setPending(true)
      try {
        await submit()
        handleAdd()
      } finally {
        setPending(false)
      }
    }
  }

  const handleOpen = (index: number) => {
    const id = values.target_epics[index]?.id
    if (!id) {
      return
    }
    const { availableCycles, defaultCycle, canEditCycle } = getAvailableCyclesInfo(index)
    openTarget(id, {
      kpi: values,
      availableCycles,
      defaultCycle,
      canEditCycle,
    })
  }

  const onOpen = async (index: number) => {
    if (!hasChanges) {
      handleOpen(index)
    } else {
      setPending(true)
      try {
        await submit()
        handleOpen(index)
      } finally {
        setPending(false)
      }
    }
  }

  const onDelete = (index: number) => {
    const id = values.target_epics[index]?.id
    if (!id) {
      return
    }
    kpiTargetsRequestsNew.delete!({ id }, { id: `${id}` })
      .then(() => {
        successNotification('Target deleted')
      })
      .then(table.refresh)
  }

  const onDeleteEpic = (rowIndex: number, epicIndex: number) => {
    const target = values.target_epics[rowIndex]
    if (!target) {
      return
    }
    const amendedEpics = target.epics.filter((_, index) => index !== epicIndex)
    kpiTargetsRequestsNew
      .update({ epics: amendedEpics, kpi: { id: values.id } }, { id: `${target.id}` })
      .then(table.refresh)
  }

  return (
    <>
      {!readonly && (
        <Flex>
          <ActionButton useIcon="Plus" onClick={onAdd}>
            Set deliverables
          </ActionButton>
        </Flex>
      )}
      <AdjustableTable
        name={TableNames.RoadmapTargets}
        onRowClick={data => {
          if ('children' in data) {
            const rowIndex = values.target_epics.findIndex(
              elm =>
                elm.review_cycle &&
                data.review_cycle &&
                +elm.review_cycle.id === +data.review_cycle.id,
            )
            onOpen(rowIndex)
          } else if ('url' in data && data.url) {
            window.open(data.url, '_blank')
          }
        }}
        idPath="uniqueKey"
        emptyState={<EmptyTableRaw title="No deliverables added" />}
        row={getRow(
          values,
          { allTargets: values.target_epics, onEdit: onOpen, onDelete, onDeleteEpic },
          readonly,
        )}
        loading={table.loading}
        count={parsedTableData.length}
        data={parsedTableData}
        childrenAlwaysOpen
        fetchChildren={parentIndexes => {
          const parentData = parsedTableData[parentIndexes[0]]
          return parentData && 'children' in parentData
            ? Promise.resolve(parentData.children)
            : Promise.resolve([])
        }}
        {...(readonly ? summaryTableProps : {})}
        hideCountAndButtonSection
      />
      <SavingKPIPopup pending={pending} isRoadmap />
    </>
  )
}

export default RoadmapTargetsTable
