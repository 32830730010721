import React, { useMemo } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import { LeadershipPositionInterface } from '@src/interfaces/leadership'
import { getLeadershipRequestsNew } from '@src/api/leadership'
import { useParams } from 'react-router-dom'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { InputGroup } from '@revolut/ui-kit'
import { connect } from 'lape'
import { PageBody } from '@components/Page/PageBody'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import { successNotification } from '@src/actions/NotificationActions'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'

type LeadershipParams = {
  entityId: string
  entityType: 'team' | 'department'
}

const LeadershipForm = connect(() => {
  const { entityId, entityType } = useParams<LeadershipParams>()
  const { values, initialValues } = useLapeContext<LeadershipPositionInterface>()

  const backUrl = useMemo(
    () =>
      pathToUrl(ROUTES.FORMS.DEPARTMENT.LEADERSHIP, {
        id: entityId,
      }),
    [entityId],
  )

  return (
    <PageWrapper>
      <PageHeader
        title={initialValues.leadership_position || 'Assign leadership position'}
        backUrl={backUrl}
        subtitle={
          initialValues.department?.name && (
            <InternalUIKitLink
              color="inherit"
              // @ts-expect-error object works fine here, but UI kit expects string
              to={pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, { id: entityId })}
            >
              {initialValues.department.name}
            </InternalUIKitLink>
          )
        }
      />
      <PageBody>
        {values.id && (
          <SettingsButtons pb="s-24">
            <DeleteButton
              deleteApi={
                getLeadershipRequestsNew({ entityId: Number(entityId), entityType })
                  .delete!
              }
              backUrl={backUrl}
              title={initialValues.leadership_position || 'leadership position'}
              onAfterDelete={() =>
                successNotification(
                  `${
                    initialValues.leadership_position || 'Leadership position'
                  } has been deleted`,
                )
              }
              dialogProps={{
                body: `Are you sure you want to delete ${
                  initialValues.leadership_position || 'this position'
                }?`,
              }}
            />
          </SettingsButtons>
        )}
        <InputGroup>
          <LapeNewInput name="leadership_position" label="Position" required />
          <LapeRadioSelectInput
            name="employee"
            label="Employee"
            selector={selectorKeys.employee}
            clearable
          />
        </InputGroup>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          afterSubmitUrl={pathToUrl(ROUTES.FORMS.DEPARTMENT.LEADERSHIP, {
            id: entityId,
          })}
        />
      </PageActions>
    </PageWrapper>
  )
})

export default () => {
  const { entityId, entityType } = useParams<LeadershipParams>()

  return (
    <Form<LeadershipPositionInterface>
      api={getLeadershipRequestsNew({ entityId: Number(entityId), entityType })}
    >
      <LeadershipForm />
    </Form>
  )
}
