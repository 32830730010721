import {
  Avatar,
  Box,
  Item,
  MoreBar,
  ActionButtonSkeleton,
  DetailsSkeleton,
  ItemSkeleton,
} from '@revolut/ui-kit'
import React from 'react'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
  ReviewCycleStage,
} from '@src/interfaces/reviewCycles'
import { stageToWidgetElementsMap } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/constants'
import { StageDetails } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Details/StageDetails'
import { StageActions } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Actions/StageActions'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
  stage: ReviewCycleStage
  stages: ReviewCycleStage[]
  settings?: PerformanceSettingsInterface
}

export const StageWidget = ({
  cycle,
  stages,
  eligibleGroups,
  stage,
  settings,
}: Props) => {
  const { iconName, title, description } = stageToWidgetElementsMap[stage]

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon={iconName} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title data-testid="stage_title">{title}</Item.Title>
        <Item.Description>{description}</Item.Description>
        <Box mb="s-8">
          <StageDetails cycle={cycle} stage={stage} settings={settings} />
        </Box>
        <Item.Actions use={MoreBar} data-testid="stage_actions">
          <StageActions
            cycle={cycle}
            stages={stages}
            eligibleGroups={eligibleGroups}
            stage={stage}
          />
        </Item.Actions>
      </Item.Content>
    </Item>
  )
}

export const StageWidgetSkeleton = () => (
  <ItemSkeleton>
    <ItemSkeleton.Avatar />
    <ItemSkeleton.Content>
      <ItemSkeleton.Title />
      <ItemSkeleton.Description />
      <DetailsSkeleton />
      <DetailsSkeleton />
      <ItemSkeleton.Actions>
        <ActionButtonSkeleton width={140} />
      </ItemSkeleton.Actions>
    </ItemSkeleton.Content>
  </ItemSkeleton>
)
