import { InterviewStageWithoutRoundInterface } from '@src/interfaces/interviewTool'

export const getGroupedStages = (
  stages: InterviewStageWithoutRoundInterface[],
): InterviewStageWithoutRoundInterface[][] => {
  const groupedStages: InterviewStageWithoutRoundInterface[][] = []

  const groupIndexes = new Map<number, number>()

  stages.forEach(stage => {
    if (stage.hiring_stage_id === null) {
      groupedStages.push([stage])
    } else if (groupIndexes.has(stage.hiring_stage_id)) {
      const idx = groupIndexes.get(stage.hiring_stage_id)
      groupedStages[idx!].push(stage)
    } else {
      groupedStages.push([stage])
      groupIndexes.set(stage.hiring_stage_id, groupedStages.length - 1)
    }
  })

  return groupedStages
}
