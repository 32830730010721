import React, { ComponentProps } from 'react'
import { Token, ActionButton as UIKitActionButton } from '@revolut/ui-kit'
import styled from 'styled-components'

const ActionButton = styled(UIKitActionButton)<{ styleVariant: 'warning' | 'success' }>`
  color: ${({ styleVariant }) =>
    styleVariant === 'warning' ? Token.color.orange : Token.color.green};
  background-color: ${({ styleVariant }) =>
    styleVariant === 'warning' ? Token.color.orange_20 : Token.color.green_20};
`

interface Props extends ComponentProps<typeof UIKitActionButton> {
  styleVariant: 'warning' | 'success'
}
export const StyledActionButton = (props: Props) => {
  return <ActionButton {...props} />
}
