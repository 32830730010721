import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PerformanceStats, usePerformanceStats } from '@src/api/goals'
import { FilterByInterface } from '@src/interfaces/data'
import { StatNavigation } from '@src/components/StatNavigation/StatNavigation'
import { NavigationConfigInterface } from '@src/components/StatNavigation/types'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useGetRoadmapSettings } from '@src/api/settings'

interface Props {
  goalFilters?: FilterByInterface[]
  roadmapFilters?: FilterByInterface[]
}

const getPerformanceConfig = (
  isOnboarding: boolean,
  isMultipleGoalsTargetsEnabled: boolean,
  roadmapsEnabled: boolean,
): NavigationConfigInterface<PerformanceStats>[] => {
  return [
    {
      key: 'overall_progress',
      label: 'Goals',
      to: isOnboarding
        ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.GENERAL
        : ROUTES.PERFORMANCE.GOALS.GENERAL,

      isPercent: true,
    },
    {
      key: 'targets_progress',
      canView: isMultipleGoalsTargetsEnabled,
      to: isOnboarding
        ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.TARGETS
        : ROUTES.PERFORMANCE.GOALS.TARGETS,
      label: 'Metrics',
    },
    {
      key: 'avg_done',
      label: 'Roadmaps',
      canView: roadmapsEnabled,
      to: isOnboarding
        ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETUP.ROADMAPS
        : ROUTES.PERFORMANCE.GOALS.ROADMAPS,
      isPercent: true,
    },
  ]
}

export const ProgressWithNavigation = ({ goalFilters, roadmapFilters }: Props) => {
  const isOnboarding = isOnboardingPath()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const performanceConfig = getPerformanceConfig(
    isOnboarding,
    !!performanceSettings?.enable_multiple_goal_targets_per_cycle,
    !!roadmapSettings?.enabled,
  )

  const api = usePerformanceStats({
    goalFilters,
    roadmapFilters,
  })
  return <StatNavigation config={performanceConfig} api={() => api} />
}
