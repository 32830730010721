import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useDeleteGrowthPlan } from '@src/api/growthPlans'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

interface Props {
  growthPlan: GrowthPlanInterface
  onComplete: VoidFunction
}

export const DeleteAction = ({ growthPlan, onComplete }: Props) => {
  const { mutateAsync: deleteGrowthPlan, isLoading } = useDeleteGrowthPlan()

  const confirmationDialog = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()

  const handleClick = () => {
    confirmationDialog.show({
      body: 'Are you sure you want to delete this growth plan?',
      onConfirm: async () => {
        try {
          await deleteGrowthPlan(growthPlan.id)
          onComplete()
          showStatusPopup({ title: 'Growth plan was successfully deleted' })
        } catch (err) {
          showStatusPopup({
            title: 'Failed to delete growth plan',
            description: getStringMessageFromError(err),
            status: 'error',
          })
        }
      },
    })
  }

  return (
    <MoreBar.Action
      variant="negative"
      useIcon="Delete"
      pending={isLoading}
      onClick={handleClick}
    >
      Delete
    </MoreBar.Action>
  )
}
