import React, { useState } from 'react'
import {
  Box,
  Icon,
  IconButton,
  Item,
  ItemSkeleton,
  TransitionCollapse,
  Widget,
} from '@revolut/ui-kit'
import {
  InterviewScorecardTemplateInterface,
  InterviewScorecardTemplateSectionInterface,
} from '@src/interfaces/interviewScorecardTemplates'
import InterviewScorecardTemplateSection from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/InterviewScorecardTemplateSection'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { usePossibleSectionOptions } from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/utils'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

interface SkillPreviewProps {
  section: InterviewScorecardTemplateSectionInterface
}

export const SkillPreview = ({ section }: SkillPreviewProps) => {
  const { values, submit } = useLapeContext<InterviewScorecardTemplateInterface>()
  const [expanded, setExpanded] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const possibleSectionOptions = usePossibleSectionOptions(values.scorecard_type?.id)
  const showErrorPopup = useErrorPopup()

  const handleDeleteSkill = async () => {
    if (values.sections) {
      const oldSections = values.sections
      setIsSubmitting(true)
      try {
        values.sections = values.sections.filter(({ id }) => section.id !== id)
        await submit()
      } catch (error) {
        values.sections = oldSections
        showErrorPopup.show({
          error,
          fallbackTitle: 'There was a error removing skills',
        })
      } finally {
        setIsSubmitting(false)
      }
    }
  }
  return (
    <Widget>
      <Item
        onClick={() => {
          setExpanded(!expanded)
        }}
      >
        <Item.Prefix>
          <Icon size={24} name={expanded ? 'ChevronUp' : 'ChevronDown'} />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>{section.title}</Item.Title>
        </Item.Content>
        {isSubmitting ? (
          <ItemSkeleton.Side />
        ) : (
          <Item.Side>
            <IconButton
              data-testid={`Delete ${section.title}`}
              useIcon="Delete"
              onClick={e => {
                // Prevents click on delete from expanding item
                e.stopPropagation()
                handleDeleteSkill()
              }}
            />
          </Item.Side>
        )}
      </Item>
      <TransitionCollapse in={expanded}>
        <InterviewScorecardTemplateSection
          key={section.id}
          editId={section.id}
          hideTitleIndex
          id={section.id!}
          isPreview
          locked
          scorecardType={values.scorecard_type?.id}
          section={section}
          sectionOptions={possibleSectionOptions}
          sections={values.sections!}
          sectionsFieldName="sections"
          setEditId={() => {}}
          skills={values.skills}
          titleIndexNumber={section.id!}
          wrapper={Box}
        />
      </TransitionCollapse>
    </Widget>
  )
}
