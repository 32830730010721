import React from 'react'
import { VStack } from '@revolut/ui-kit'
import CareersApplicationPredefinedSections from '@src/pages/Careers/CareersApplication/CareersApplicationPredefinedSections'
import { ApplicationFormGlobalSections } from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormGlobalSections'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import ApplicationFormPreviewSection from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormPreviewSection'

export const ApplicationFormPreview = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  return (
    <VStack gap="s-16" mt="s-24">
      <CareersApplicationPredefinedSections />
      <ApplicationFormGlobalSections />
      {values.application_form_sections.map((section, index) => (
        <ApplicationFormPreviewSection
          key={section.id}
          idx={index}
          sections={values.application_form_sections}
          disableEditing={false}
        />
      ))}
    </VStack>
  )
}
