import React, { useState } from 'react'
import { Item, Switch, Spinner } from '@revolut/ui-kit'
import { MergeConnectionInterface } from '@src/interfaces/integrations'
import {
  useUpdateMergeConnection,
  useInvalidateMergeConnections,
} from '@src/api/integrations'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'

interface SyncSettingsProps {
  integration: MergeConnectionInterface
}

export const SyncSettings = ({ integration }: SyncSettingsProps) => {
  const { mutateAsync: update, isLoading } = useUpdateMergeConnection()
  const [syncAll, setSyncAll] = useState(Boolean(integration?.sync_all_statuses))
  const errorPopup = useErrorPopup()
  const invalidateConnections = useInvalidateMergeConnections()

  const handleClick = async () => {
    try {
      await update([integration?.id, { sync_all_statuses: !syncAll }])
      await invalidateConnections()
      setSyncAll(!syncAll)
    } catch (error) {
      errorPopup.show({
        fallbackTitle: 'Failed to change sync settings',
        error,
      })
    }
  }

  return (
    <Item use="label">
      <Item.Content>
        <Item.Title>Sync all employees</Item.Title>
        <Item.Description>
          Allow to sync all employee records, including deleted and archived
        </Item.Description>
      </Item.Content>
      <Item.Side>
        {isLoading ? (
          <Spinner />
        ) : (
          <Switch checked={syncAll} onClick={handleClick} disabled={isLoading} />
        )}
      </Item.Side>
    </Item>
  )
}
