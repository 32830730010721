import React from 'react'
import { BaseHeaderProps } from '@src/pages/Forms/AssignKPIs/common'
import EmployeeHeader from '@src/pages/Forms/AssignKPIs/Headers/EmployeeHeader'
import TeamHeader from '@src/pages/Forms/AssignKPIs/Headers/TeamHeader'
import DepartmentHeader from '@src/pages/Forms/AssignKPIs/Headers/DepartmentHeader'
import { EntityTypes } from '@src/constants/api'
import { EmployeeInterface } from '@src/interfaces/employees'
import { employeesRequestsNew } from '@src/api/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { teamsRequestsNew } from '@src/api/teams'
import { departmentRequestsNew } from '@src/api/department'
import { FilterByInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import {
  removeNotificationDepartmentKPIsToAssign,
  removeNotificationPersonalKPIsToAssign,
  removeNotificationTeamKPIsToAssign,
} from '@src/api/kpiNotifications'
import { Statuses } from '@src/interfaces'

interface HeaderProps extends BaseHeaderProps {
  entityType: EntityTypes
}

export const HeaderRouter = ({ entityType, ...props }: HeaderProps) => {
  switch (entityType) {
    case EntityTypes.employee:
      return <EmployeeHeader {...props} />
    case EntityTypes.team:
      return <TeamHeader {...props} />
    case EntityTypes.department:
      return <DepartmentHeader {...props} />
    default:
      return null
  }
}

interface AddKpiClickOptions {
  id: string
  notificationId?: number
  entityType: EntityTypes
  user: EmployeeInterface
}

export const addKpiOnClickRouter = async ({
  id,
  notificationId,
  entityType,
  user,
}: AddKpiClickOptions) => {
  const notificationParams = notificationId
    ? {
        notificationId,
      }
    : {}

  if (entityType === EntityTypes.employee) {
    const employeeResponse = await employeesRequestsNew.get({ id })
    const { full_name, team } = employeeResponse.data

    return () =>
      navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
        initialValues: getKPIFormInitialValues(
          // @ts-ignore FIXME: REVPI-19 support optional team value
          {
            id: Number(id),
            name: full_name,
            team,
          },
          { is_employee: true },
        ),
        backUrl: pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.PERSONAL, {
          id,
          ...notificationParams,
        }),
      })
  }

  if (entityType === EntityTypes.team) {
    const team = await teamsRequestsNew.get({ id }).then(resp => resp.data)

    return () =>
      navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
        initialValues: getKPIFormInitialValues(user, {
          team: { id: team.id, name: team.name },
        }),
        backUrl: pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.TEAM, {
          id,
          ...notificationParams,
        }),
      })
  }

  if (entityType === EntityTypes.department) {
    const department = await departmentRequestsNew.get({ id }).then(resp => resp.data)

    return () =>
      navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
        initialValues: getKPIFormInitialValues(user, {
          department: { id: department.id, name: department.name },
        }),
        backUrl: pathToUrl(ROUTES.FORMS.ASSIGN_KPIS.DEPARTMENT, {
          id,
          ...notificationParams,
        }),
      })
  }

  return () => Promise.resolve()
}

export const tableInitialFiltersRouter = (
  id: string,
  entityType: EntityTypes,
  cycleOffset?: number | string,
  cycleId?: number | string,
): FilterByInterface[] => {
  const commonFilters: FilterByInterface[] = [
    {
      filters: [
        { name: Statuses.active, id: Statuses.active },
        { name: 'new', id: 'new' },
      ],
      columnName: 'status',
      nonResettable: true,
    },
    {
      columnName: 'goals_related',
      filters: [{ id: 'true', name: 'true' }],
      nonResettable: true,
    },
  ]

  if (cycleOffset !== undefined) {
    commonFilters.push({
      filters: [{ name: `${cycleOffset}`, id: cycleOffset }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    })
  }

  if (cycleId !== undefined) {
    commonFilters.push({
      filters: [{ name: `${cycleId}`, id: cycleId }],
      columnName: 'cycle__id',
      nonResettable: true,
    })
  }

  switch (entityType) {
    case EntityTypes.employee:
      return [
        ...commonFilters,
        {
          filters: [{ name: 'True', id: 'True' }],
          columnName: 'is_employee',
          nonResettable: true,
        },
        {
          filters: [{ name: id, id }],
          columnName: 'owner__id',
          nonResettable: true,
          nonInheritable: true,
        },
      ]
    case EntityTypes.team:
      return [
        ...commonFilters,
        {
          filters: [{ name: id, id }],
          columnName: 'team__id',
          nonResettable: true,
          nonInheritable: true,
        },
      ]
    case EntityTypes.department:
      return [
        ...commonFilters,
        {
          filters: [{ name: id, id }],
          columnName: 'department__id',
          nonResettable: true,
          nonInheritable: true,
        },
      ]
    default:
      return []
  }
}

export const removeNotificationRequestRouter = (
  entityType: EntityTypes,
): ((id: string | number) => AxiosPromise<void>) => {
  switch (entityType) {
    case EntityTypes.employee:
      return removeNotificationPersonalKPIsToAssign
    case EntityTypes.team:
      return removeNotificationTeamKPIsToAssign
    case EntityTypes.department:
    default:
      return removeNotificationDepartmentKPIsToAssign
  }
}

export const afterSubmitPathRouter = (entityType: EntityTypes): string => {
  switch (entityType) {
    case EntityTypes.employee:
      return ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.PERSONAL
    case EntityTypes.team:
      return ROUTES.FORMS.TEAM.KPI
    case EntityTypes.department:
      return ROUTES.FORMS.DEPARTMENT.KPI
    default:
      return '/'
  }
}
