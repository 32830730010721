import { MergeConnectionInterface } from '@src/interfaces/integrations'
import { SyncBanner } from '@src/pages/Hub/Integrations/components/SyncBanner'
import { SyncSettings } from '@src/pages/Hub/Integrations/components/SyncSettings'
import { FieldMappingItem } from '@src/pages/Hub/Integrations/components/FieldMappingItem'
import React from 'react'

interface MergeIntegrationItemsProps {
  integration?: MergeConnectionInterface
}
export const MergeIntegrationItems = ({ integration }: MergeIntegrationItemsProps) => {
  if (!integration) {
    return null
  }

  return (
    <>
      <SyncBanner integration={integration} />
      <SyncSettings integration={integration} />
      <FieldMappingItem integration={integration} />
    </>
  )
}
