import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { CreateEmployee } from './Forms/CreateEmployee'
import { EmployeeProfileLayout } from './Layout'

const backUrl = ROUTES.PEOPLE.EMPLOYEES

export const EmployeeForm = () => {
  const newEmployee = useRouteMatch(ROUTES.FORMS.EMPLOYEE.NEW)

  if (newEmployee) {
    return (
      <PageWrapper>
        <PageHeader title="New employee" backUrl={backUrl} />
        <CreateEmployee />
      </PageWrapper>
    )
  }
  return <EmployeeProfileLayout />
}
