import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ResultCategoriesTable } from './CategoriesTable'
import { allSurveyResultsBaseRoutes, ResultsInterface } from './common'
import { Heatmap } from './Heatmap'
import { ResultQuestionsTable } from './QuestionsTable'

export const TableContent = (props: ResultsInterface) => {
  return (
    <Switch>
      <Route exact path={allSurveyResultsBaseRoutes.map(route => route.QUESTIONS)}>
        {props.viewMode === 'table' && <ResultQuestionsTable {...props} />}
        {props.viewMode === 'heatmap' && <Heatmap {...props} />}
      </Route>
      <Route exact path={allSurveyResultsBaseRoutes.map(route => route.CATEGORIES)}>
        {props.viewMode === 'table' && <ResultCategoriesTable {...props} />}
        {props.viewMode === 'heatmap' && <Heatmap {...props} />}
      </Route>
    </Switch>
  )
}
