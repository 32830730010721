import React, { useEffect, useState } from 'react'
import SideBar from '@src/components/SideBar/SideBar'

import { Input, Item, Radio, Subheader, VStack } from '@revolut/ui-kit'
import { SpecialisationInterface } from '@src/interfaces/roles'

interface Props {
  isOpen: boolean
  specialisations: SpecialisationInterface[]
  onClose: VoidFunction
  onSelect: (selected: SpecialisationInterface) => void
  selectedId: number | undefined | string
}

export const SpecialisationSidebar = ({
  specialisations,
  isOpen,
  onClose,
  onSelect,
  selectedId,
}: Props) => {
  const [filterSpecialisationValue, setFilterSpecialisationValue] = useState('')
  const [filteredSpecialisations, setFilteredspecialisations] = useState<
    SpecialisationInterface[] | undefined
  >(specialisations)

  useEffect(() => {
    setFilteredspecialisations(specialisations)
  }, [specialisations])
  return (
    <SideBar isOpen={isOpen} onClose={onClose} title="Preview Role">
      <VStack gap="s-8">
        <Input
          value={filterSpecialisationValue}
          label="Role name"
          onChange={e => {
            setFilterSpecialisationValue(e.currentTarget.value)
            setFilteredspecialisations(
              specialisations?.filter(specialisation =>
                specialisation.name.includes(e.currentTarget.value),
              ),
            )
          }}
          onClear={() => {
            setFilterSpecialisationValue('')
            setFilteredspecialisations(specialisations)
          }}
        />
        <Subheader>Roles</Subheader>
        {filteredSpecialisations?.map(specialisation => (
          <Item
            use="button"
            aria-pressed={selectedId === specialisation.id}
            key={specialisation.id}
            onClick={() => {
              onSelect(specialisation)
            }}
          >
            <Item.Prefix>
              <Radio checked={selectedId === specialisation.id} />
            </Item.Prefix>
            <Item.Content>
              <Item.Title>{specialisation.name}</Item.Title>
            </Item.Content>
          </Item>
        ))}
      </VStack>
    </SideBar>
  )
}
