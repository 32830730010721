import React from 'react'
import { MoreBar, useToggle } from '@revolut/ui-kit'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import {
  nextStageToPropsMap,
  previousStageToPropsMap,
} from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/constants'
import { SwitchStagePopup } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Popup'
import { useSwitchStage } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useSwitchStage'
import { useSwitchStageStatusPopup } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/hooks/useSwitchStageStatusPopup'
import { useStageHistory } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useStageHistory'

interface Props {
  cycle: ReviewCyclesInterface
  stages: ReviewCycleStage[]
  stage: ReviewCycleStage
  direction: 'backward' | 'forward'
}

export const SwitchStageAction = ({ cycle, stages, stage, direction }: Props) => {
  const propsMap = direction === 'forward' ? nextStageToPropsMap : previousStageToPropsMap
  const props = propsMap[stage]

  const [isPopupOpen, popupToggler] = useToggle()
  const {
    showSwitchNextCompleted,
    showSwitchNextFailed,
    showSwitchPreviousCompleted,
    showSwitchPreviousFailed,
  } = useSwitchStageStatusPopup()
  const { next, previous, isLoading } = useSwitchStage(cycle, stages)
  const { update } = useStageHistory(cycle)

  const switchNextStage = async () => {
    try {
      const nextStage = await next()

      if (nextStage !== null) {
        update(nextStage)
      }

      showSwitchNextCompleted(stage)
    } catch (err) {
      showSwitchNextFailed(stage, err)
    } finally {
      popupToggler.off()
    }
  }

  const switchPreviousStage = async () => {
    try {
      const nextStage = await previous()

      if (nextStage !== null) {
        update(nextStage)
      }

      showSwitchPreviousCompleted(stage)
    } catch (err) {
      showSwitchPreviousFailed(stage, err)
    } finally {
      popupToggler.off()
    }
  }

  if (props === null) {
    return null
  }

  const handleConfirm = direction === 'forward' ? switchNextStage : switchPreviousStage
  const { label, iconName } = props

  return (
    <>
      <MoreBar.Action useIcon={iconName} onClick={popupToggler.on} pending={isLoading}>
        {label}
      </MoreBar.Action>
      <SwitchStagePopup
        cycle={cycle}
        stage={stage}
        direction={direction}
        isOpen={isPopupOpen}
        isSwitchPending={isLoading}
        handleConfirm={handleConfirm}
        handleClose={popupToggler.off}
      />
    </>
  )
}
