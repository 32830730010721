import React, { useEffect, useMemo } from 'react'
import {
  Group,
  Flex,
  Text,
  Token,
  VStack,
  chain,
  Circle,
  Icon,
  Action,
  useToggle,
  BottomSheet,
  List,
  Widget,
  Item,
} from '@revolut/ui-kit'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { TalkingPointsBottomSheetContent } from '../components/TalkingPointsBottomSheetContent'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  MeetingTemplateInterface,
  TemplateItemInterface,
  TemplateItemRole,
} from '@src/interfaces/meetings'
import { itemFieldsSetupEntitiesRecord } from '../helpers'

export const Fields = () => {
  const { values, initialValues } = useLapeContext<MeetingTemplateInterface>()
  const [openBottomSheet, toggleOpen] = useToggle()

  const personalFieldText = (
    <Text color={Token.color.greyTone50} variant="body2">
      Personal field, will not be shared with others
    </Text>
  )

  const itemValuesByRoleRecord = useMemo(() => {
    return (
      values.items?.reduce<Record<string, TemplateItemInterface>>((acc, item) => {
        acc[item.role] = item
        return acc
      }, {}) || {}
    )
  }, [values.items])

  useEffect(() => {
    if (initialValues?.items?.length === 0 || !initialValues?.items) {
      values.items = Object.values(itemFieldsSetupEntitiesRecord)
      values.enable_ai = true
    }
  }, [initialValues?.items])

  const onHandleChangeFieldsEntity = (role: TemplateItemRole) => {
    const updatedItems = itemValuesByRoleRecord[role]
      ? values.items.filter(item => item.role !== role)
      : [...values.items, itemFieldsSetupEntitiesRecord[role]]
    values.items = updatedItems
  }

  const onSavePoints = (content: string[]) => {
    const updatedItem = {
      ...itemFieldsSetupEntitiesRecord[TemplateItemRole.TalkingPoints],
      content,
    }

    const updatedItems = itemValuesByRoleRecord[TemplateItemRole.TalkingPoints]
      ? values.items.map(item =>
          item.role === TemplateItemRole.TalkingPoints ? updatedItem : item,
        )
      : [...values.items, updatedItem]
    values.items = updatedItems
  }

  const contentItems =
    typeof itemValuesByRoleRecord?.[TemplateItemRole.TalkingPoints]?.content === 'string'
      ? [itemValuesByRoleRecord?.[TemplateItemRole.TalkingPoints]?.content]
      : itemValuesByRoleRecord?.[TemplateItemRole.TalkingPoints]?.content

  return (
    <>
      <Widget padding="s-16">
        <Flex pb="s-16" flexDirection="column">
          <Text variant="emphasis1">Fields</Text>
          <Text color={Token.color.greyTone50} variant="body2">
            Which input fields do you want to capture for each meeting
          </Text>
        </Flex>

        <VStack my="s-12" gap="s-12">
          <Text color={Token.color.greyTone50} variant="body2">
            Before meeting
          </Text>
          <Group>
            <Item>
              <Item.Avatar>
                <Circle bg={Token.color.greyTone10} size={40}>
                  <Icon color={Token.color.accent} name="Form" size={24} />
                </Circle>
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Talking points</Item.Title>
                <Item.Description>{chain('Text field', 'List')}</Item.Description>
                <VStack gap="s-12">
                  <List variant="compact">
                    {contentItems?.map((contentItem, index) => {
                      return (
                        <List.Item key={index} useIcon="Dot">
                          <Text color={Token.color.greyTone50}>{contentItem}</Text>
                        </List.Item>
                      )
                    })}
                  </List>
                  <Action onClick={toggleOpen.on} useIcon="Plus">
                    {itemValuesByRoleRecord[TemplateItemRole.TalkingPoints]?.content
                      ?.length > 0
                      ? 'Edit'
                      : 'Add'}{' '}
                    default talking points
                  </Action>
                </VStack>
              </Item.Content>
              <Item.Side>
                <Item.Value>
                  <LapeNewSwitch checked disabled />
                </Item.Value>
              </Item.Side>
            </Item>
          </Group>
        </VStack>

        <VStack my="s-12" gap="s-12">
          <Text color={Token.color.greyTone50} variant="body2">
            During/after meeting
          </Text>
          <Group>
            <LapeNewSwitch
              checked={!!itemValuesByRoleRecord[TemplateItemRole.ActionItems]}
              onChange={() => onHandleChangeFieldsEntity(TemplateItemRole.ActionItems)}
              itemTypeProps={{
                title: 'Action items',
                description: chain('Text field', 'Check list'),
                avatar: (
                  <Circle bg={Token.color.greyTone10} size={40}>
                    <Icon color={Token.color.accent} name="Questionnaire" size={24} />
                  </Circle>
                ),
                transparent: true,
              }}
            />
          </Group>
          <Group>
            <LapeNewSwitch
              checked={!!itemValuesByRoleRecord[TemplateItemRole.PerformanceRating]}
              onChange={() =>
                onHandleChangeFieldsEntity(TemplateItemRole.PerformanceRating)
              }
              itemTypeProps={{
                title: 'Performance rating (manager only)',
                description: (
                  <VStack>
                    {chain('Dropdown', 'single choice')}
                    {personalFieldText}
                  </VStack>
                ),
                avatar: (
                  <Circle bg={Token.color.greyTone10} size={40}>
                    <Icon color={Token.color.accent} name="Trophy" size={24} />
                  </Circle>
                ),
                transparent: true,
              }}
            />
          </Group>
          <Group>
            <LapeNewSwitch
              checked={!!itemValuesByRoleRecord[TemplateItemRole.PersonalNotes]}
              onChange={() => onHandleChangeFieldsEntity(TemplateItemRole.PersonalNotes)}
              itemTypeProps={{
                title: 'Personal notes',
                description: (
                  <VStack>
                    <Text color={Token.color.greyTone50} variant="body2">
                      Text field
                    </Text>
                    {personalFieldText}
                  </VStack>
                ),
                avatar: (
                  <Circle bg={Token.color.greyTone10} size={40}>
                    <Icon color={Token.color.accent} name="Form" size={24} />
                  </Circle>
                ),
                transparent: true,
              }}
            />
          </Group>
        </VStack>

        <VStack gap="s-12">
          <Text color={Token.color.greyTone50} variant="body2">
            AI content
          </Text>
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable AI',
                description:
                  'Generates a summary and populates action items after the meeting (if enabled)',
                avatar: (
                  <Circle bg={Token.color.greyTone10} size={40}>
                    <Icon color={Token.color.accent} name="Cleaning" size={24} />
                  </Circle>
                ),
                transparent: true,
              }}
              name="enable_ai"
            />
          </Group>
        </VStack>
      </Widget>
      <BottomSheet
        open={openBottomSheet}
        onClose={toggleOpen.off}
        labelButtonClose="Cancel"
        preventUserClose
      >
        <TalkingPointsBottomSheetContent
          onSavePoints={onSavePoints}
          close={toggleOpen.off}
          talkingPoints={
            (itemValuesByRoleRecord[TemplateItemRole.TalkingPoints]
              ?.content as string[]) || []
          }
        />
      </BottomSheet>
    </>
  )
}
