import React, { useEffect } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import EmailForm from '@src/features/EmailForm/EmailForm'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { InputGroup } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import {
  SchedulingEmailPreviewInterface,
  ScheduleInterviewInterface,
} from '@src/interfaces/interviewTool'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { createNewKey } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { pathToUrl } from '@src/utils/router'
import { IdAndName } from '@src/interfaces'

interface ScheduleSidebarEmailProps {
  email?: SchedulingEmailPreviewInterface
}

const ScheduleSidebarEmail = ({ email }: ScheduleSidebarEmailProps) => {
  const openNewTab = useOpenNewTab()
  const { values } = useLapeContext<ScheduleInterviewInterface>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (email) {
      values.email_body = email.html
      values.sender_type = email.sender_type
      values.recipients_cc = email.recipients_cc
      values.recipients_bcc = email.recipients_bcc
      values.subject = email.subject
      values.attachments = email.attachments
    }
  }, [email])

  return (
    <>
      <InputGroup>
        <LapeRadioSelectInput<IdAndName | { id: 'create-new' }>
          name="email_template"
          label="Email template"
          selector={selectorKeys.email_templates_active}
          showCreateNewButton
          onChange={option => {
            if (option?.id === createNewKey) {
              openNewTab(pathToUrl(ROUTES.FORMS.EMAIL_TEMPLATE.GENERAL))
            } else if (option) {
              values.email_template = option
            }
          }}
        />
        <EmailForm userEmail={user.email} showInvalidPlaceholdersWarning />
      </InputGroup>
    </>
  )
}

export default ScheduleSidebarEmail
