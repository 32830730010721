import React from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { CommonActions } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSummary/Actions/CommonActions'
import { useCloseReviewCycle } from '@src/api/reviewCycles'
import { MoreBar, Text } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PermissionTypes } from '@src/store/auth/types'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { DeleteCycleAction } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSummary/Actions/DeleteCycleAction'

interface Props {
  cycle: ReviewCyclesInterface
}

export const OngoingCycleActions = ({ cycle }: Props) => {
  const { id } = cycle
  const { mutateAsync: closeCycle } = useCloseReviewCycle(id)
  const showStatusPopup = useShowStatusPopup()
  const permissions = useSelector(selectPermissions)

  const canDelete =
    permissions.includes(PermissionTypes.DeleteReviewCycles) &&
    cycleModel.isDeletable(cycle) &&
    (cycleModel.isTest(cycle) || cycleModel.isAdHoc(cycle))

  const handleStopCycle = async () => {
    await closeCycle()
    showStatusPopup({ title: `${cycle.name} cycle is successfully stopped` })
  }

  return (
    <MoreBar maxCount={2} aria-label="action panel">
      <CommonActions cycle={cycle} />
      <NewSaveButtonWithPopup<ReviewCyclesInterface>
        confirmationDialogue={{
          body: <Text>Are you sure you want to stop the cycle?</Text>,
        }}
        useIcon="Stop"
        isMoreAction
        onClick={handleStopCycle}
      >
        Stop Cycle
      </NewSaveButtonWithPopup>
      {canDelete && <DeleteCycleAction cycle={cycle} />}
    </MoreBar>
  )
}
