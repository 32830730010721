import { useParams, useRouteMatch } from 'react-router-dom'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useActiveProfileSidebar } from '@src/pages/EmployeeProfile/Layout/Summary/common'

export const useEmployeeDetailsOnAfterSubmit = (afterSubmit?: () => void) => {
  const params = useParams<{ id?: string }>()
  const isPersonalDetailsRoute = useRouteMatch([
    ROUTES.FORMS.EMPLOYEE.GENERAL.BIO,
    ROUTES.FORMS.EMPLOYEE.GENERAL.CONTACT_INFO,
    ROUTES.FORMS.EMPLOYEE.GENERAL.IDENTIFICATION,
    ROUTES.FORMS.EMPLOYEE.GENERAL.CORRESPONDENCE_ADDRESS,
    ROUTES.FORMS.EMPLOYEE.GENERAL.BANK_DETAILS,
    ROUTES.FORMS.EMPLOYEE.GENERAL.EMERGENCY_CONTACTS,
    ROUTES.FORMS.EMPLOYEE.GENERAL.LAPTOP_DELIVERY,
    ROUTES.FORMS.EMPLOYEE.GENERAL.ABOUT,
  ])
  const isDiversityRoute = useRouteMatch(ROUTES.FORMS.EMPLOYEE.GENERAL.DIVERSITY)
  const { sidebar } = useActiveProfileSidebar()
  const sidebarByEditFormPage =
    isPersonalDetailsRoute || isDiversityRoute ? 'personalDetails' : 'workDetails'
  const afterSubmitUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, params, {
    sidebar: sidebar || sidebarByEditFormPage,
  })
  const onAfterSubmit = () => {
    if (params.id) {
      navigateReplace(afterSubmitUrl)
      afterSubmit?.()
    }
  }

  return { onAfterSubmit }
}
