import { useQuery } from '@src/utils/queryParamsHooks'
import {
  CandidateSchedulingQueryParams,
  CandidatesSchedulingCancellingFeatureFlag,
} from '@src/pages/CandidateScheduling/types'
import { useGetCandidateSchedulingFeatureFlags } from '@src/api/recruitment/candidateScheduling'

export const useCandidateSchedulingFeatureFlags = () => {
  const { query } = useQuery<CandidateSchedulingQueryParams>()
  const { data, isLoading } = useGetCandidateSchedulingFeatureFlags(query?.token)
  const canCancel = data?.includes(CandidatesSchedulingCancellingFeatureFlag)
  return {
    canCancel,
    isLoading,
  }
}
