import { AxiosError } from 'axios'

type AppErrorBase<Type, SubType = undefined> = {
  type: Type
  error?: AxiosError
  subType?: SubType
  action?: 'create' | 'update' | 'delete'
}

type Forbidden = AppErrorBase<'forbidden'>

type SessionExpired = AppErrorBase<'session_expired'>

type Unauthorized = AppErrorBase<'unauthorized'>

type BadRequest = AppErrorBase<'bad_request'>

type ResourceNotFound = AppErrorBase<'resource_not_found'>

type ContentTooLarge = AppErrorBase<'content_too_large'>

type ServerError = AppErrorBase<'server_error', 'be_error'>

type BadGateway = AppErrorBase<'bad_gateway', 'be_error'>

type ServiceUnavailable = AppErrorBase<'service_unavailable', 'be_error'>

type UnknownError = AppErrorBase<'unknown_error'>

export type AppError =
  | Forbidden
  | SessionExpired
  | BadRequest
  | Unauthorized
  | ResourceNotFound
  | ContentTooLarge
  | ServerError
  | BadGateway
  | ServiceUnavailable
  | UnknownError

export type ParsableError = AxiosError | null | undefined

export const parseError = (error: ParsableError): AppError => {
  if (!error) {
    return {
      type: 'unknown_error',
    }
  }

  const status = error.response?.status

  if (status && status >= 500) {
    switch (status) {
      case 502:
        return {
          type: 'bad_gateway',
          subType: 'be_error',
          error,
        }
      case 503:
        return {
          type: 'service_unavailable',
          subType: 'be_error',
          error,
        }
      default:
        return {
          type: 'server_error',
          subType: 'be_error',
          error,
        }
    }
  }

  switch (status) {
    case 400: {
      const method = error.config?.method?.toLowerCase()
      return {
        type: 'bad_request',
        error,
        action:
          method === 'post'
            ? 'create'
            : method === 'put' || method === 'patch'
            ? 'update'
            : method === 'delete'
            ? 'delete'
            : undefined,
      }
    }
    case 401: {
      return {
        type: 'unauthorized',
        error,
      }
    }
    case 403: {
      const detail = error.response?.data.detail

      if (
        detail === 'User not authenticated' ||
        detail === 'Session expired' ||
        detail === 'Invalid token'
      ) {
        return {
          type: 'session_expired',
          error,
        }
      }

      return {
        type: 'forbidden',
        error,
      }
    }
    case 404:
      return {
        type: 'resource_not_found',
        error,
      }
    case 413:
      return {
        type: 'content_too_large',
        error,
      }
    default:
      return {
        type: 'unknown_error',
        error,
      }
  }
}
