import React from 'react'
import { Box } from '@revolut/ui-kit'
import { EntityTypes } from '@src/constants/api'
import { EmployeeInterface } from '@src/interfaces/employees'
import TableLoader from '@components/TableV2/TableLoader'
import { tableContainerOpacity } from '@components/TableV2/Table'
import { useGoalsFilters } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/Goals/useGoalsFilters'
import { PerformanceSelector, ReviewSummaryInterface } from '@src/interfaces/performance'
import { useGoalsVisibilityBySummary } from '@src/features/Goals/useGoalsVisibilityBySummary'
import { NoPersonalGoalsBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/NoPersonalGoalsBanner'
import { GoalsTable } from '@src/features/GrowthPlans/GrowthPlan/components/PerformanceSummary/Deliverables/Goals/GoalsTable'

interface Props {
  employee: EmployeeInterface
  reviewCycle: PerformanceSelector
  performanceSummary?: ReviewSummaryInterface
}

export const Goals = ({ employee, performanceSummary, reviewCycle }: Props) => {
  const { data: goalsFilters, isLoading: isGoalsFiltersLoading } = useGoalsFilters(
    EntityTypes.employee,
    employee.id,
    reviewCycle,
  )
  const {
    isLoading: isGoalsVisibilityLoading,
    isIndividualGoalsVisible,
    isTeamGoalsVisible,
  } = useGoalsVisibilityBySummary(performanceSummary, goalsFilters)

  const goalsEntityType =
    performanceSummary?.team && isTeamGoalsVisible
      ? EntityTypes.team
      : EntityTypes.employee
  const goalsEntityId =
    performanceSummary?.team && isTeamGoalsVisible
      ? performanceSummary?.team?.id
      : employee.id

  if (isGoalsFiltersLoading || isGoalsVisibilityLoading) {
    return (
      <Box opacity={tableContainerOpacity}>
        <TableLoader rowHeight="small" loading />
      </Box>
    )
  }

  return (
    <>
      {!isIndividualGoalsVisible && performanceSummary?.team && (
        <Box mb="s-16">
          <NoPersonalGoalsBanner team={performanceSummary?.team} isPerformanceBanner />
        </Box>
      )}
      <GoalsTable
        entityType={goalsEntityType}
        entityId={goalsEntityId}
        reviewCycle={reviewCycle}
      />
    </>
  )
}
