import { Flex, Text, Token, Icon, Tooltip, useTooltip, HStack } from '@revolut/ui-kit'
import { FinalGrade } from '@src/interfaces/performance'
import React from 'react'
import { gradeSettings } from '../utils'
import { formatPercentage } from '@src/utils/format'
import GradeCircle from '@components/GradeCircle'

interface Props {
  grade: FinalGrade
  periodName: string | undefined
  percent: number | null
}

export const OverallProgressCircle = ({ grade, periodName, percent }: Props) => {
  const tooltip = useTooltip()

  const progressColor = gradeSettings?.[grade]?.color

  return (
    <Flex ml="s-12" mt="s-16" mb="s-4" gap="s-16" alignItems="center">
      <GradeCircle
        progressColor={progressColor}
        avatarColor={progressColor}
        periodName={periodName}
        gradeSettings={gradeSettings}
        grade={grade}
      />
      <Flex minWidth={110} gap="s-2" flexDirection="column">
        <HStack space="s-4" align="center">
          <Text
            variant="heading3"
            whiteSpace="nowrap"
            color={gradeSettings?.[grade]?.color}
          >
            {formatPercentage(percent)}
          </Text>
          <Icon
            name="InfoOutline"
            size={16}
            color={Token.color.greyTone50}
            style={{ cursor: 'pointer' }}
            {...tooltip.getAnchorProps()}
          />
          <Tooltip {...tooltip.getTargetProps()} width={308}>
            The overall score is the weighted average of a department's goal and roadmap
            progress (if applicable), with 50% weight each. The final grade is calculated
            at end of year after results calibration.
          </Tooltip>
        </HStack>
        <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="caption">
          {'Overall score'}
        </Text>
      </Flex>
    </Flex>
  )
}
