import React from 'react'
import { Item, Avatar, Icon } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { MergeConnectionInterface } from '@src/interfaces/integrations'

interface FieldMappingItemProps {
  integration: MergeConnectionInterface
}

export const FieldMappingItem = ({ integration }: FieldMappingItemProps) => {
  return (
    <Item
      use="button"
      onClick={() => {
        navigateTo(
          pathToUrl(ROUTES.FORMS.FIELD_MAPPING, {
            id: integration.id,
          }),
        )
      }}
    >
      <Item.Avatar>
        <Avatar useIcon="Gear" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>Change field mapping</Item.Title>
      </Item.Content>
      <Item.Side>
        <Icon name="Pencil" />
      </Item.Side>
    </Item>
  )
}
