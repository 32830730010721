import React from 'react'
import { Flex, Icon, opacity, Text, Token, VStack } from '@revolut/ui-kit'
import { CellInsertParams, ColumnCellInterface, RowInterface } from '@src/interfaces/data'
import {
  calibratorColumn,
  cultureRatingColumn,
  deliverablesRatingColumn,
  departmentColumn,
  employeeNameColumn,
  finalGradeColumn,
  finalRatingColumn,
  fmGradeColumn,
  fmGradePlainColumn,
  functionalManagerColumn,
  hodGradeColumn,
  hodRatingColumn,
  hofGradeColumn,
  hofRatingColumn,
  injectCalibrationTeamsSelector,
  lineManagerColumn,
  lmGradeColumn,
  lmGradePlainColumn,
  positionColumn,
  rankingScoreColumn,
  seniorityColumn,
  skillsRatingColumn,
  systemRatingColumn,
  systemRecommendedGradeColumn,
  teamColumn,
} from '@src/constants/columns/talent/performance'
import { TalentPerformanceStatsWeightsInterface } from '@src/interfaces/talent/performance'
import { TalentType } from '@src/interfaces/talent/talent'
import { ratingToColor } from '@src/utils/performance'
import { FinalGrade, PerformanceRating } from '@src/interfaces/performance'
import styled from 'styled-components'
import { RadioSelect } from '@components/Inputs/RadioSelect/RadioSelect'
import { PerformanceRatingTitle } from '@src/constants/performance'
import GradeLockIcon from '@src/features/Calibration/GradeLockIcon'
import { GradesMapInterface } from '@src/utils/grades'
import {
  calibratedBarRaiserColumn,
  calibratedSkillsRatingColumn,
  calibrationNeededColumn,
  getCalibratedDeliverablesGradeColumn,
} from '@src/constants/columns/supportTool/calibratedGrade'
import { isDepartmentType, isFunctionType } from '@src/pages/Forms/CommonTalentTab/utils'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { CycleOption } from '@src/interfaces/selectors'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import { selectorKeys } from '@src/constants/api'
import round from 'lodash/round'
import isNumber from 'lodash/isNumber'
import { TableActionButton } from '@components/Button/TableActionButton'
import Table from '@src/components/TableV2/Table'
import { TalentUIInterface } from './types'
import { HoDGradeCell } from './components/HoDGradeCell'
import { HoFGradeCell } from './components/HoFGradeCell'
import { FinalGradeCell } from './components/FinalGradeCell'

const RadioSelectStyled = styled(RadioSelect)`
  min-height: 32px;
  border: 0;
  padding: 0;
`

const ratingOptions = Object.values(PerformanceRating)
  .filter(id => id !== PerformanceRating.skipped && id !== PerformanceRating.dont_know)
  .map(id => ({
    id,
    name: PerformanceRatingTitle[id],
  }))

interface RowProps {
  talentType: TalentType
  changeHoDGrade: (id: number, rating: FinalGrade, justification?: string) => void
  changeHoFGrade: (id: number, rating: FinalGrade) => void
  changeHoFRating: (id: number, rating: PerformanceRating) => void
  canChangeHoFGrades?: boolean
  orderingMode?: boolean
  viewRatings?: boolean
  performanceSettings?: PerformanceSettingsInterface
  disableRowSelection: (row: TalentUIInterface) => boolean
  allowSelect: boolean
  viewHistory: boolean
  gradeOptions: { id: FinalGrade; name: string }[]
  showRanking: boolean
  showRankingColumn: boolean
  statsWeights: TalentPerformanceStatsWeightsInterface | null
  calibrationSelector: selectorKeys
  gradesMap: GradesMapInterface
  selectedCycle?: CycleOption
  onBarRaiserClick: (talent: TalentUIInterface, scrollSidebar?: boolean) => void
}

export const getTalentPerfomranceTableRow = ({
  talentType,
  changeHoDGrade,
  changeHoFGrade,
  changeHoFRating,
  canChangeHoFGrades,
  orderingMode,
  viewRatings,
  performanceSettings,
  disableRowSelection,
  allowSelect,
  viewHistory,
  gradeOptions,
  showRanking,
  showRankingColumn,
  statsWeights,
  calibrationSelector,
  gradesMap,
  selectedCycle,
  onBarRaiserClick,
}: RowProps): RowInterface<TalentUIInterface> => {
  let columns = viewRatings
    ? [
        {
          ...deliverablesRatingColumn,
          width: 122,
        },
        ...(performanceSettings?.enable_skill_assessment
          ? [
              {
                ...skillsRatingColumn,
                width: 122,
              },
            ]
          : []),
        ...(performanceSettings?.enable_values
          ? [
              {
                ...cultureRatingColumn,
                width: 122,
              },
            ]
          : []),
        {
          ...systemRatingColumn,
          width: 130,
        },
        {
          ...hodRatingColumn,
          width: 122,
        },
        {
          ...hofRatingColumn,
          width: 122,
          background: (data: TalentUIInterface) =>
            data.function_owner_rating_label && !data.self_employee_locked
              ? ratingToColor(data.function_owner_rating_label)
              : undefined,
          insert: ({ data }: CellInsertParams<TalentUIInterface>) => {
            if (data.self_employee_locked) {
              return <GradeLockIcon />
            }
            const value = ratingOptions.find(
              item => item.id === data.function_owner_rating_label,
            ) || {
              id: '',
              name: '',
              text: '',
            }

            const canChange =
              !orderingMode && canChangeHoFGrades && isFunctionType(talentType)

            if (!canChange) {
              return (
                <PerformanceRatingLabelTag
                  rating={data.function_owner_rating_label}
                  fontWeight={400}
                  fontSize="small"
                  emptyMessage="-"
                  data-testid={`label_rating_hof__${data.id}`}
                />
              )
            }

            return (
              <div onClick={e => e.stopPropagation()}>
                <RadioSelectStyled
                  id={`select_rating_hof__${data.id}`}
                  options={ratingOptions}
                  onChange={option => {
                    changeHoFRating(data.id, option.id as PerformanceRating)
                  }}
                  value={value}
                  width={480}
                />
              </div>
            )
          },
        },
        {
          ...finalRatingColumn,
          width: 122,
        },
      ]
    : [
        {
          ...calibratorColumn('department_grade_calibrator'),
          width: 50,
        },
        {
          ...calibratorColumn('function_grade_calibrator'),
          title: `${
            isDepartmentType(talentType) ? 'Functional' : 'Delegated'
          } calibrator`,
          width: 50,
        },
        {
          ...(viewHistory ? lmGradeColumn : lmGradePlainColumn),
          selectorsKey: calibrationSelector,
          width: 120,
        },
        {
          ...(viewHistory ? fmGradeColumn : fmGradePlainColumn),
          selectorsKey: calibrationSelector,
          width: 120,
        },
        {
          ...getCalibratedDeliverablesGradeColumn(gradesMap),
          width: 120,
        },
        {
          ...calibratedSkillsRatingColumn,
          width: 120,
        },
        {
          ...calibratedBarRaiserColumn,
          width: 120,
          insert: ({ data }: CellInsertParams<TalentUIInterface>) => {
            if (!data.line_manager_grade && !data.functional_manager_grade) {
              return null
            }
            return (
              <TableActionButton
                onClick={e => {
                  e.stopPropagation()
                  onBarRaiserClick(data, true)
                }}
              >
                View
              </TableActionButton>
            )
          },
        },
        {
          ...systemRecommendedGradeColumn,
          selectorsKey: calibrationSelector,
          width: 70,
        },
        {
          ...hodGradeColumn,
          width: 180,
          selectorsKey: calibrationSelector,
          headerBackground: isDepartmentType(talentType)
            ? opacity(Token.colorChannel.greyTone2, 0.1)
            : undefined,
          background: () =>
            isDepartmentType(talentType)
              ? opacity(Token.colorChannel.greyTone2, 0.2)
              : undefined,
          insert: ({ data }: CellInsertParams<TalentUIInterface>) => (
            <HoDGradeCell
              data={data}
              changeHoDGrade={changeHoDGrade}
              talentType={talentType}
              gradeOptions={gradeOptions}
              requireJustification={
                performanceSettings?.require_justification_for_grade_calibration || false
              }
            />
          ),
        },
        {
          ...hofGradeColumn,
          width: 180,
          selectorsKey: calibrationSelector,
          headerBackground: isFunctionType(talentType)
            ? opacity(Token.colorChannel.greyTone2, 0.1)
            : undefined,
          background: () =>
            isFunctionType(talentType)
              ? opacity(Token.colorChannel.greyTone2, 0.2)
              : undefined,
          insert: ({ data }: CellInsertParams<TalentUIInterface>) => (
            <HoFGradeCell
              showRanking={showRanking}
              data={data}
              changeHoFGrade={changeHoFGrade}
              talentType={talentType}
              gradeOptions={gradeOptions}
              requireJustification={
                performanceSettings?.require_justification_for_grade_calibration || false
              }
            />
          ),
        },
        ...(showRankingColumn
          ? [
              {
                ...rankingScoreColumn,
                width: 140,
                headerTooltip: statsWeights ? (
                  <VStack space="s-4" p="s-12" minWidth={260}>
                    <Text
                      variant="caption"
                      fontWeight={600}
                      color={Token.color.background}
                    >
                      How is this score calculated?
                    </Text>
                    <ul
                      style={{
                        margin: 0,
                        paddingInlineStart: 12,
                        color: Token.color.greyTone20,
                      }}
                    >
                      <li>
                        {statsWeights.average_manager_recommendation}% Manager grade
                        recommendation
                      </li>
                      <li>{statsWeights.deliverables}% Deliverables assessment</li>
                      <li>{statsWeights.skills}% Skills assessment</li>
                      <li>{statsWeights.kpi}% KPI performance</li>
                      <li>{statsWeights.wnips}% Bar raiser questions</li>
                      <li>
                        {statsWeights.past_cycles_average_grade}% Historic performance
                      </li>
                    </ul>
                  </VStack>
                ) : undefined,
                insert: ({ data }: CellInsertParams<TalentUIInterface>) => {
                  return isNumber(data.ranking_score) ? round(data.ranking_score, 2) : ''
                },
              },
            ]
          : []),
        {
          ...finalGradeColumn,
          width: 140,
          selectorsKey: calibrationSelector,
          headerBackground: opacity(Token.colorChannel.greyTone2, 0.1),
          background: () => opacity(Token.colorChannel.greyTone2, 0.2),
          insert: ({ data }: CellInsertParams<TalentUIInterface>) => (
            <FinalGradeCell
              data={data}
              viewHistory={viewHistory}
              gradeOptions={gradeOptions}
            />
          ),
        },
      ].filter(i => i)

  return {
    highlight: talent => (talent.isSelected ? Token.color.greyTone8 : ''),
    cells: [
      allowSelect
        ? {
            ...getSelectCellConfig({ disableRow: disableRowSelection }),
            width: 60,
          }
        : undefined,
      {
        ...employeeNameColumn,
        insert: ({ data }: CellInsertParams<TalentUIInterface>) => (
          <Flex gap="s-8">
            {canChangeHoFGrades && orderingMode && (
              <Icon name="Drag" color={Token.color.greyTone20} size={24} />
            )}
            <Table.EmployeeCell employee={data.employee} size={40} />
          </Flex>
        ),
        width: 160,
      },
      {
        ...seniorityColumn,
        width: 70,
      },
      talentType !== TalentType.Specialisation
        ? {
            ...positionColumn,
            width: 100,
          }
        : undefined,
      isFunctionType(talentType)
        ? {
            ...departmentColumn,
            width: 120,
          }
        : undefined,
      talentType !== TalentType.Team
        ? {
            ...injectCalibrationTeamsSelector(teamColumn, selectedCycle),
            width: 120,
          }
        : undefined,
      talentType !== TalentType.DirectReport
        ? {
            ...lineManagerColumn,
            width: 50,
          }
        : undefined,
      talentType !== TalentType.FunctionalReport && {
        ...functionalManagerColumn,
        width: 50,
      },
      ...columns,
      talentType !== TalentType.Company
        ? {
            ...calibrationNeededColumn,
            width: 100,
          }
        : undefined,
    ].filter(i => i) as ColumnCellInterface<TalentUIInterface>[],
  }
}
