import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { Group, ItemSkeleton } from '@revolut/ui-kit'
import { BaseStatsItem } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/StatsItems'
import React from 'react'
import { useCalibrationStats } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/hooks/useCalibrationStats'
import { makeCalibrationStatsItemProps } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/constants'

interface Props {
  cycle: ReviewCyclesInterface
}

export const CalibrationStats = ({ cycle }: Props) => {
  const { stats, isLoading } = useCalibrationStats(cycle)

  if (isLoading) {
    return (
      <Group>
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </Group>
    )
  }

  return (
    <Group>
      {stats.map(stat => (
        <BaseStatsItem key={stat.name} {...makeCalibrationStatsItemProps(stat)} />
      ))}
    </Group>
  )
}
