import React, { useState } from 'react'
import { MoreBar, MoreBarActionProps, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { InterviewStageWithoutRoundInterface } from '@src/interfaces/interviewTool'
import { changeInterviewStage } from '@src/api/recruitment/interviews'
import { ArrowSend } from '@revolut/icons'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'

interface Props extends MoreBarActionProps {
  data: InterviewStageWithoutRoundInterface[]
  currentStageId: number
  roundId: number
  onChange: () => void
  disabled?: boolean
  onLoading?: (isLoading: boolean) => void
}

const getNextStageId = (
  stages: InterviewStageWithoutRoundInterface[],
  currentStageId: number,
) => {
  const currentStageIdx = stages.findIndex(stage => stage.id === currentStageId)

  if (currentStageIdx === -1) {
    return null
  }

  for (let i = currentStageIdx + 1; i < stages.length; i++) {
    if (
      !stages[i].hiring_stage_id ||
      stages[i].hiring_stage_id !== stages[i - 1].hiring_stage_id
    ) {
      return stages[i].id
    }
  }

  return null
}

const NextStage = ({
  data,
  currentStageId,
  roundId,
  onChange,
  disabled,
  onLoading,
  ...props
}: Props) => {
  const statusPopup = useStatusPopup()
  const { sendAnalyticsEvent } = useAnalytics()
  const [pending, setPending] = useState(false)
  const nextStageId = getNextStageId(data, currentStageId)

  if (nextStageId === null) {
    return null
  }

  const nextStage = async () => {
    setPending(true)
    onLoading?.(true)
    try {
      const response = await changeInterviewStage(roundId, nextStageId)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>
            Changed stage to {response.data.latest_interview_stage?.title}
          </StatusPopup.Title>
        </StatusPopup>,
      )
      onLoading?.(false)
      onChange()
    } catch (e) {
      onLoading?.(false)
    } finally {
      setPending(false)
    }
  }

  return (
    <MoreBar.Action
      useIcon={ArrowSend}
      onClick={e => {
        e.preventDefault()
        sendAnalyticsEvent(AnalyticsEvents.click_next_stage_candidate_profile)
        nextStage()
      }}
      disabled={pending || disabled}
      pending={pending}
      {...props}
    >
      Move to next stage
    </MoreBar.Action>
  )
}

export default NextStage
