import React, { useEffect, useState } from 'react'
import assign from 'lodash/assign'
import pick from 'lodash/pick'
import pluralize from 'pluralize'
import {
  VStack,
  Box,
  Cell,
  HStack,
  Subheader,
  Text,
  TransitionCollapse,
} from '@revolut/ui-kit'

import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TimeOffPolicyRequestsStep } from '@src/interfaces/timeOff'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { PolicyApprovalChainInput } from '../components/PolicyApprovalChainInput'
import { usePolicyApprovalChain } from '../components/hooks'
import { ActivePolicyDraftWarning } from '../components/ActivePolicyDraftWarning'
import { renderIcon } from '../common'
import { NextTabButton } from './NextTabButton'
import { PolicyEditTabCommonProps } from './common'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'

const unit = (amount?: number | null, balanceUnit?: 'day' | 'hour') =>
  pluralize(balanceUnit || 'day', amount || 0)

export const PolicyEditRequestsTab = ({
  basicStepInfo,
  copyFrom,
  setIsCopied,
  nextTabPath,
  onNavigateToNextTab,
}: PolicyEditTabCommonProps) => {
  const { values } = useLapeContext<TimeOffPolicyRequestsStep>()

  useEffect(() => {
    if (!copyFrom) {
      return
    }
    assign(
      values,
      pick(copyFrom, [
        'is_approval_required',
        'is_attachment_required',
        'is_comment_required',
        'policy_approvers',
        'request_instructions',
      ]),
    )
    setIsCopied()
  }, [copyFrom])

  const [showInstructionsField, setShowInstructionsField] = useState(false)

  const {
    approvalChainErrors,
    setApprovalChainErrors,
    approversChainInputRef,
    handlePolicyApproversValue,
  } = usePolicyApprovalChain()

  useEffect(() => {
    if (values.request_instructions) {
      setShowInstructionsField(true)
    }

    if (values.is_attachment_required && !values.attachment_required_threshold) {
      values.attachment_required_threshold = 0
    }
  }, [values.request_instructions, values.is_attachment_required])

  return (
    <>
      {values.draft_has_active_policy && <ActivePolicyDraftWarning />}
      <VStack gap="s-16">
        <Box>
          <SectionTitle title="Should requests require an approval?" />
          <LapeRadioSwitch<boolean>
            name="is_approval_required"
            variant="horizontal"
            options={[
              {
                id: 'yes',
                label: 'Yes',
                description: 'Every request should be approved by someone.',
                value: true,
              },
              {
                id: 'no',
                label: 'No',
                description: 'Requests are automatically approved at all times.',
                value: false,
              },
            ]}
          />
        </Box>
        {values.is_approval_required && (
          <Box>
            <SectionTitle
              title="Who should be able to approve requests?"
              subtitle="Define the order of approvers for the requests in this policy"
            />

            <div ref={approversChainInputRef}>
              <PolicyApprovalChainInput
                errors={approvalChainErrors}
                setErrors={setApprovalChainErrors}
                showDescription={false}
                sortByInitial={false}
              />
            </div>
          </Box>
        )}
        <Box>
          <SectionTitle
            title="What further information should be provided for a request?"
            subtitle="Here you can define any requirements for employees to create or approve requests."
          />
          <Cell p={0}>
            <VStack>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Request requires an attachment',
                  description:
                    'If required, you should indicate in the instructions field which document needs to be included.',
                  avatar: renderIcon('Document'),
                  transparent: true,
                }}
                name="is_attachment_required"
                onAfterChange={() => {
                  if (!values.is_attachment_required) {
                    values.attachment_required_threshold = null
                  }
                }}
              />
              <TransitionCollapse in={values.is_attachment_required}>
                <Box px="s-16">
                  <Subheader variant="nested">
                    Employee should provide documentation
                  </Subheader>
                  <HStack align="center" gap="s-8">
                    <Text>If requested time off is longer than</Text>
                    <LapeNewInput
                      label={`Amount of ${unit(null, basicStepInfo?.unit.id)}`}
                      name="attachment_required_threshold"
                      required
                      type="number"
                      width="170px"
                    />
                    <Text>
                      consecutive{' '}
                      {unit(
                        Number(values.attachment_required_threshold),
                        basicStepInfo?.unit.id,
                      )}
                    </Text>
                  </HStack>
                </Box>
              </TransitionCollapse>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Request requires a comment',
                  description:
                    'If required, you should indicate in the instructions what employees should fill in the comments section.',
                  avatar: renderIcon('Chat'),
                  transparent: true,
                }}
                name="is_comment_required"
              />
              <LapeNewSwitch
                name="toggle_show_instructions_field"
                checked={showInstructionsField}
                itemTypeProps={{
                  title: 'Add instructions on how to raise a request',
                  description:
                    'If required, you should indicate in the instructions field what information needs to be included.',
                  avatar: renderIcon('Questionnaire'),
                  transparent: true,
                }}
                onAfterChange={checked => {
                  if (checked) {
                    setShowInstructionsField(true)
                  } else {
                    setShowInstructionsField(false)
                    values.request_instructions = ''
                  }
                }}
              />
              {showInstructionsField && (
                <Box p="s-16">
                  <LapeNewTextArea
                    name="request_instructions"
                    label="Request instructions"
                    required
                    rows={3}
                  />
                </Box>
              )}
            </VStack>
          </Cell>
        </Box>
      </VStack>
      <NextTabButton
        onBeforeSubmit={handlePolicyApproversValue}
        nextTabPath={nextTabPath}
        onNavigateToNextTab={onNavigateToNextTab}
      />
    </>
  )
}
