import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useGetEmployeePersonalSettings } from '@src/api/employees'
import { ActionButton, Avatar, Item, Text, Token } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { GOOGLE_CALENDAR, MICROSOFT_CALENDAR } from '@src/constants/externalLinks'
import React from 'react'

interface Props {
  showDefineSlotsBanner?: boolean
}

export const InterviewAvailabilityBanner = ({ showDefineSlotsBanner = true }: Props) => {
  const user = useSelector(selectUser)
  const { data, isLoading } = useGetEmployeePersonalSettings({
    refetchOnWindowFocus: true,
  })
  const isCalendarIntegrationEnabled = data?.calendar_integration_enabled
  if (!isLoading && !isCalendarIntegrationEnabled) {
    return (
      <Item>
        <Item.Prefix>
          <Avatar useIcon="CalendarCheck" />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.calendarIntegrationBanner.title"
              defaultMessage="Integrate your Calendar"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.calendarIntegrationBanner.description"
              defaultMessage="Your calendar is not connected at the moment. Connect it to see your weekly meetings load."
            />
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            target="_blank"
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.LINKED_ACCOUNTS, { id: user.id })}
            use={InternalLink}
          >
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.calendarIntegrationBanner.connectNow"
              defaultMessage="Connect now"
            />
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }
  if (!isLoading && showDefineSlotsBanner) {
    const calendarLink =
      data?.office_suite_provider === 'google' ? GOOGLE_CALENDAR : MICROSOFT_CALENDAR
    return (
      <Item>
        <Item.Prefix>
          <Avatar useIcon="InfoOutline" />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.interviewSlotsInstructions.title"
              defaultMessage="How to define specific placeholder slots"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.interviewSlotsInstructions.description"
              defaultMessage="To create interview slot, navigate to your calendar and create an event with a phrase {interviewSlot} in the title or description of the event. You can create your interview slots as recurring or singular meetings. To manage the interview slot simply change or delete the event from your calendar."
              values={{
                interviewSlot: (
                  <>
                    “
                    <Text color={Token.color.green} style={{ whiteSpace: 'nowrap' }}>
                      [interview-slot]
                    </Text>
                    ”
                  </>
                ),
              }}
            />
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton target="_blank" href={calendarLink} use="a">
            <FormattedMessage
              id="recruitment.interviewAvailability.tablePage.interviewSlotsInstructions.openCalendar"
              defaultMessage="Open calendar"
            />
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }
  return null
}
