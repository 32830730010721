export const ERROR_DEFAULT_DURATION = 5000
export const SUCCESS_DEFAULT_DURATION = 3000

/** @deprecated */
export const ERRORS = {
  BAD_LOGIN: 'Something went wrong, try again later.',
  UNKNOWN: 'Something went wrong.',
  UNKNOWN_REFRESH: 'Something went wrong. Please refresh the page.',
  TOO_MANY_REQUESTS:
    'You are making too many requests at the same time! Please try again in a couple of seconds!',
  BAD_REQUEST: 'Invalid data.',
  NO_ACCESS: 'Authentication failed. Please check your credentials and try again.',
  SESSION_EXPIRED: 'Session expired. Please login again',
  NOT_ENOUGH_PERMISSIONS: 'Not enough permissions.',
  INVALID_INPUT:
    'Looks like your input is invalid. Try to check the data you are submitting or contact our support team',
}

export const INVALID_IAP_CREDENTIALS = 'Invalid IAP credentials:'
export const INVALID_AUTH_COOKIE = 'Auth Cookie is invalid:'
