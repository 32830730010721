import { Box, TextWidget, Token } from '@revolut/ui-kit'
import NewRadioButtons from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { calibrationLogicOptions } from '@src/features/ReviewCycle/PerformanceReviewCycle/CalibrationLogic/EditCalibrationLogic/CalibrationLogic/CalibrationLogicOptions'
import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  GradeCalculationMethods,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'

interface Props {
  calibrationMethod?: GradeCalculationMethods
}

export const CalibrationLogic = ({ calibrationMethod }: Props) => {
  const { values } = useLapeContext<ReviewCyclesInterface>()

  const selectedOption = calibrationLogicOptions.find(
    ({ value: { id } }) => id === calibrationMethod,
  )

  return (
    <TextWidget>
      <TextWidget.Title>Performance grade logic</TextWidget.Title>
      <TextWidget.Content color={Token.color.greyTone50}>
        Settings that define the grade calculation logic for all future review cycles
        <Box mt="s-16">
          <NewRadioButtons
            variant="group-items"
            direction="column"
            highlightSelected
            value={selectedOption?.value}
            options={calibrationLogicOptions}
            onChange={({ value: { id } }) => {
              values.grade_calculation_method = id
            }}
          />
        </Box>
      </TextWidget.Content>
    </TextWidget>
  )
}
