import React from 'react'
import { useSelector } from 'react-redux'

import { RowInterface } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/TableV2/hooks'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { SchedulingPolicyTableInterface } from '@src/interfaces/attendance'
import { getSchedulingPolicies } from '@src/api/attendance'
import {
  schedulingPoliciesCreatedOnColumn,
  schedulingPoliciesGroupsColumn,
  schedulingPoliciesLocationColumn,
  schedulingPoliciesMembersColumn,
  schedulingPoliciesNameColumn,
  schedulingPoliciesOwnerColumn,
  schedulingPoliciesShiftsColumn,
  schedulingPoliciesStatusColumn,
} from '@src/constants/columns/attendance'
import { Statuses } from '@src/interfaces'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const ROW: RowInterface<SchedulingPolicyTableInterface> = {
  linkToForm: ({ id, status }) =>
    navigateTo(
      pathToUrl(
        status.id === Statuses.draft
          ? ROUTES.FORMS.SCHEDULING_POLICY.GENERAL
          : ROUTES.FORMS.SCHEDULING_POLICY.PREVIEW,
        { id },
      ),
    ),
  cells: [
    {
      ...schedulingPoliciesNameColumn,
      width: 120,
    },
    {
      ...schedulingPoliciesLocationColumn,
      width: 100,
    },
    {
      ...schedulingPoliciesGroupsColumn,
      width: 100,
    },
    {
      ...schedulingPoliciesMembersColumn,
      width: 80,
    },
    {
      ...schedulingPoliciesShiftsColumn,
      width: 80,
    },
    {
      ...schedulingPoliciesCreatedOnColumn,
      width: 100,
    },
    {
      ...schedulingPoliciesOwnerColumn,
      width: 120,
    },
    {
      ...schedulingPoliciesStatusColumn,
      width: 100,
    },
  ],
}

const Policies = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddSchedulingPolicy)

  const table = useTable({ getItems: getSchedulingPolicies })

  return (
    <Table.Widget>
      {canAdd && (
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <PrimaryAction
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY.GENERAL)}
              useIcon="Plus"
            >
              Create new
            </PrimaryAction>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
      )}
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.AttendancePolicies}
          useWindowScroll
          row={ROW}
          {...table}
          emptyState={<EmptyTableRaw title="Policies will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Policies
