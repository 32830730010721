import React, { useEffect, useRef } from 'react'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getRoundedRating,
  deliverablesRating,
  useScoreCardGrades,
  CommonGradeOption,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { ProbationDeliverables } from '@src/pages/Forms/ProbationLayout/Sections/ProbationDeliverables'
import {
  getGradesWithExpectations,
  getViewGradesWithExpectations,
} from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'

// FYI It's called "Probation" but in reality it's also used in PIP
export const ProbationDeliverablesCard = connect(
  ({ onHelpClick, gradesMap }: CommonCardProps) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasKPI = !!values.review_data.kpis_section
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (
        !values.review_data.kpis_section?.recommended_rating &&
        values.review_data.kpis_section?.rating
      ) {
        set(
          values,
          'review_data.kpis_section.recommended_rating',
          getRoundedRating(values.review_data.kpis_section?.rating),
        )
      }
    }, [values.review_data.kpis_section])

    useEffect(() => {
      if (
        errors.review_data?.calculated_deliverables_ratings ||
        errors.review_data?.kpis_section
      ) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [
      errors.review_data?.calculated_deliverables_ratings,
      errors.review_data?.kpis_section,
    ])

    const kpiGrades = useScoreCardGrades()
    const kpiGradesWithExpectations = getGradesWithExpectations(kpiGrades, null)

    const onSelectGrade = (grade: CommonGradeOption) => {
      set(
        values,
        `review_data.calculated_deliverables_ratings.recommended_rating`,
        grade.key,
      )
    }

    return (
      <>
        {hasKPI ? (
          <Card
            data={values}
            renderExpandedContent={() => (
              <KPI
                reviewData={values.review_data}
                kpiGrades={kpiGradesWithExpectations}
                gradesMap={gradesMap}
              />
            )}
            type={CardContentTypes.KPI}
            title="Goals and deliverables"
            icon="Target"
            fields={[
              {
                field: 'review_data.kpis_section',
                title: 'Contribution and impact',
                grades: kpiGradesWithExpectations,
                cardJustification: null,
              },
            ]}
            onSelectDeliverableGrade={grade => {
              set(values, `review_data.kpis_section.recommended_rating`, grade.key)
            }}
            isGradeSelectedRule={(field, grade) => {
              const ratingValue = get(values, field)?.recommended_rating
              return !!ratingValue && ratingValue === grade.key
            }}
            headerRef={ref}
            onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
          />
        ) : (
          <Card
            data={values}
            renderExpandedContent={() => (
              <ProbationDeliverables
                reviewData={values.review_data}
                category={values.category}
                team={values.team}
                cycleId={values.performance_checkpoint?.cycle_id}
                checkpointNum={values.probation_checkpoint?.number}
                gradesMap={gradesMap}
              />
            )}
            type={CardContentTypes.DELIVERABLES}
            title="Goals and deliverables"
            icon="Target"
            fields={[
              {
                field: 'review_data.calculated_deliverables_ratings',
                title: 'Contribution and impact',
                grades: getViewGradesWithExpectations(deliverablesRating),
                cardJustification: null,
              },
            ]}
            onSelectDeliverableGrade={onSelectGrade}
            isGradeSelectedRule={(field, grade) => {
              const ratingValue = get(values, field)?.recommended_rating
              return !!ratingValue && ratingValue === grade.key
            }}
            headerRef={ref}
            onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
          />
        )}
      </>
    )
  },
)
