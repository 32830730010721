import React from 'react'

import SideBar from '@src/components/SideBar/SideBar'
import { Group, Item, Radio } from '@revolut/ui-kit'
import { Grade } from '@src/interfaces/settings'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
  grades: Pick<Grade, 'id' | 'label' | 'goal_performance_percent'>[]
}

export const GradesPreviewSidebar = ({ isOpen, onClose, grades }: Props) => {
  return (
    <SideBar
      isOpen={isOpen}
      onClose={onClose}
      title="Preview Grade description"
      subtitle="This is how the grades will be displayed in your scorecards."
    >
      <Group>
        {grades.map((grade, i) => (
          <Item key={grade.id}>
            <Item.Prefix>
              <Radio checked={false} />
            </Item.Prefix>
            <Item.Content>
              <Item.Title>{grade.label}</Item.Title>
              <Item.Description>
                {i === 0 &&
                  `Less than ${grade.goal_performance_percent}% contribution towards the goal`}
                {i === grades.length - 1 &&
                  `Greater than ${
                    grades.at(i - 1)?.goal_performance_percent
                  }% contribution towards the goal`}
                {i > 0 &&
                  i < grades.length - 1 &&
                  `Contribution of ${grades.at(i - 1)?.goal_performance_percent}-${
                    grade.goal_performance_percent
                  }% towards the goal`}
              </Item.Description>
            </Item.Content>
          </Item>
        ))}
      </Group>
    </SideBar>
  )
}
