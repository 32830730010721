import React from 'react'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { Details, VStack } from '@revolut/ui-kit'
import { StagePeriods } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Details/StagePeriods'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import { StageStats } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Details/StageStats'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
  settings?: PerformanceSettingsInterface
}

export const StageDetails = ({ cycle, stage, settings }: Props) => {
  const isPeriodsVisible = !cycleModel.isManual(cycle)

  return (
    <Details>
      <VStack space="s-16">
        {isPeriodsVisible && (
          <StagePeriods cycle={cycle} stage={stage} settings={settings} />
        )}
        <StageStats cycle={cycle} stage={stage} settings={settings} />
      </VStack>
    </Details>
  )
}
