import React, { MouseEvent, useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ActionButton, Subheader, VStack } from '@revolut/ui-kit'
import { connect } from 'lape'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import Outcome from '@src/pages/Forms/InterviewScorecardTemplate/Sections/Outcome/Outcome'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import ApplicationFormEditSection from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormEditSection'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import isEmpty from 'lodash/isEmpty'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import CareersApplicationPredefinedSections from '@src/pages/Careers/CareersApplication/CareersApplicationPredefinedSections'
import { useGetJobPostingsApplicationFormSettings } from '@src/api/settings'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import pluralize from 'pluralize'
import { useGetCareersPredefinedSections } from '@src/pages/Careers/hooks/useCareersPredefinedSections'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useParams } from 'react-router-dom'
import { ReuseApplicationForm } from '@src/pages/Forms/JobPosting/ApplicationForm/ReuseApplicationForm'
import { DraggableApplicationFormSections } from '@src/pages/Forms/JobPosting/ApplicationForm/DraggableApplicationFormSections'
import { generateRandomId } from '@src/utils/numbers'
import { ApplicationFormGlobalSections } from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormGlobalSections'

type ApplicationFormProps = {
  disableEditing?: boolean
  showNoCustomQuestionsWidget?: boolean
  showPredefinedSections?: boolean
  showReuseApplicationFormSection?: boolean
}
export const ApplicationForm = ({
  disableEditing = false,
  showNoCustomQuestionsWidget = false,
  showPredefinedSections = true,
  showReuseApplicationFormSection = true,
}: ApplicationFormProps) => {
  const context = useLapeContext<JobPostingInterface>()
  const { values, errors } = context
  const [openSectionIdx, setOpenSectionIdx] = useState<number | null>(null)
  const { data: applicationSettings } = useGetJobPostingsApplicationFormSettings()
  const predefinedSections = useGetCareersPredefinedSections({ locked: true })
  const permissions = useSelector(selectPermissions)
  const canEdit = permissions.includes(PermissionTypes.ChangeJobDescriptions)

  useEffect(() => {
    if (errors.application_form_sections) {
      setOpenSectionIdx(
        errors.application_form_sections.findIndex(item => !isEmpty(item)),
      )
    }
  }, [errors])

  const handleAddSection = (
    e: MouseEvent<HTMLDivElement> | MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault()
    if (!values.application_form_sections) {
      values.application_form_sections = [
        {
          title: 'New section',
          description: '',
          questions: [],
          sortId: generateRandomId(),
        },
      ]
    } else {
      values.application_form_sections.push({
        title: 'New section',
        description: '',
        questions: [],
        sortId: generateRandomId(),
      })
    }

    setOpenSectionIdx(values.application_form_sections.length - 1)
  }

  const predefinedSectionsCount =
    (applicationSettings?.global_sections?.length || 0) + predefinedSections.length

  return (
    <>
      <VStack gap="s-16">
        <Subheader>
          <Subheader.Title>Application questions</Subheader.Title>
          {showReuseApplicationFormSection && (
            <ReuseApplicationForm specialisationId={values?.specialisation?.id} />
          )}
        </Subheader>
        {showPredefinedSections && (
          <>
            <ActionWidget
              title={
                <>
                  This application form contains {predefinedSectionsCount} predefined{' '}
                  {pluralize('section', predefinedSectionsCount)} that can’t be edited.{' '}
                  <br /> Click on add new section to add new questions for the candidate
                </>
              }
            />
            <CareersApplicationPredefinedSections locked />
            <ApplicationFormGlobalSections onClickEdit={setOpenSectionIdx} locked />
          </>
        )}
        {!values.application_form_sections?.length && showNoCustomQuestionsWidget && (
          <ActionWidget title="This application form doesn't have custom questions" />
        )}
        {Boolean(values.application_form_sections?.length) && (
          <DraggableApplicationFormSections
            sections={values.application_form_sections}
            onChange={sections => {
              values.application_form_sections = sections || []
            }}
            onEdit={setOpenSectionIdx}
            disableEditing={disableEditing}
            openSectionIdx={openSectionIdx}
          />
        )}

        {!disableEditing && canEdit && (
          <ActionButton useIcon="Plus" onClick={handleAddSection}>
            Add section
          </ActionButton>
        )}
      </VStack>
      <Outcome />
      <ApplicationFormEditSection
        idx={openSectionIdx}
        onClose={() => setOpenSectionIdx(null)}
      />
    </>
  )
}

export default connect(() => {
  const params = useParams()
  return (
    <PageWrapper>
      <PageHeader
        title="Application form"
        backUrl={pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, params)}
      />
      <PageBody>
        <ApplicationForm />
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          onAfterSubmit={resp => {
            if (resp.id && resp.specialisation?.id) {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                  id: resp.id,
                  specId: resp.specialisation.id,
                }),
              )
              return
            }

            navigateReplace(pathToUrl(ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS))
          }}
          successText="Job posting saved successfully"
        />
      </PageActions>
    </PageWrapper>
  )
})
