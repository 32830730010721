import React, { useState } from 'react'
import { Box, Button, Group, Header, Popup, Tag, Token } from '@revolut/ui-kit'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { addHiringStagesToSpecialisationHiringProcess } from '@src/api/hiringProcess'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'

interface Props {
  onClose: VoidFunction
  onSuccess: VoidFunction
  jobPostingHiringProcess: HiringProcessInterface[]
  specialisationId: number
}

export const SetDefaultHiringProcessPopup = ({
  onClose,
  jobPostingHiringProcess,
  specialisationId,
  onSuccess,
}: Props) => {
  const showStatusPopup = useShowStatusPopup()
  const [loading, setLoading] = useState(false)
  const [defaultProcessChecked, setDefaultProcessChecked] = useState(true)

  const onConfirm = async () => {
    if (!defaultProcessChecked) {
      onSuccess()
      return
    }

    setLoading(true)
    const promises = jobPostingHiringProcess.map(process =>
      addHiringStagesToSpecialisationHiringProcess({
        ...process,
        id: undefined,
        specialisation: { id: specialisationId },
      }),
    )

    try {
      await Promise.all(promises)
      onSuccess()
    } catch (error) {
      showStatusPopup({
        title: 'Cannot set default hiring process',
        description: getMessageFromApiError(error),
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Popup open onClose={onClose} preventUserClose={loading}>
      <Header>
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Set as specialisation default?</Header.Title>
        <Header.Description>
          This is the first time a hiring process has been defined for this
          specialisation.
        </Header.Description>
      </Header>
      <Group p="s-16">
        <NewCheckbox
          checked={defaultProcessChecked}
          onClick={() => setDefaultProcessChecked(!defaultProcessChecked)}
          label="Set as default hiring process"
          description={
            <>
              Use this hiring process as the default hiring process for this
              specialisation.{' '}
              <Box mt="s-8">
                <Tag color={Token.color.groupedBackground}>Recommended</Tag>
              </Box>
            </>
          }
        />
      </Group>
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Back
        </Button>
        <Button elevated onClick={onConfirm} pending={loading} disabled={loading}>
          Confirm
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
