import React from 'react'
import { VStack } from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { useCanManageGrowthPlans } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import {
  EnableApprovalsDescription,
  EnableApprovalsTitle,
  EnableExtraApprovalsDescription,
  EnableExtraApprovalsTitle,
} from '@src/pages/Settings/GrowthPlans/Approvals/InputsContent'

export const GrowthPlanApprovalSettings = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const isDisabled = !useCanManageGrowthPlans()

  return (
    <VStack space="s-16">
      <ApprovalSteps
        title={<EnableApprovalsTitle />}
        description={<EnableApprovalsDescription />}
        switchField="enable_growth_plan_approvals"
        approvalProcess="growth_plan_approvals"
        entity="growth plan"
        disabled={isDisabled}
        formRef={values}
      />
      <LapeNewSwitch
        itemTypeProps={{
          title: <EnableExtraApprovalsTitle />,
          description: <EnableExtraApprovalsDescription />,
        }}
        name="enabled_hod_hof_approval_for_lead_and_above"
        disabled={isDisabled}
      />
    </VStack>
  )
}
