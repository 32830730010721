import React, { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Input,
  StatusPopup,
  Text,
  TextSkeleton,
  Token,
  useStatusPopup,
  VStack,
} from '@revolut/ui-kit'
import {
  cancelScheduling,
  useGetCandidateInterview,
} from '@src/api/recruitment/candidateScheduling'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import SuccessWidget from '@components/SuccessWidget/SuccessWidget'
import { useCandidateSchedulingFeatureFlags } from '@src/pages/CandidateScheduling/hooks/useCandidateSchedulingFeatureFlags'
import { useQuery } from '@src/utils/queryParamsHooks'
import { CandidateSchedulingQueryParams } from '@src/pages/CandidateScheduling/types'

export const CancelScheduling = () => {
  const { query } = useQuery<CandidateSchedulingQueryParams>()
  const [message, setMessage] = useState('')
  const [pending, setPending] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const { data: interview, isLoading: loadingInterview } = useGetCandidateInterview(
    query.token,
  )

  const { canCancel, isLoading: loadingFeatureFlags } =
    useCandidateSchedulingFeatureFlags()

  const statusPopup = useStatusPopup()
  const handleCancelInterview = async () => {
    setPending(true)
    try {
      if (query.token) {
        await cancelScheduling(
          query.token,
          interview?.id,
          message ? { candidate_cancellation_notes: message } : undefined,
        )
      }
      setCancelled(true)
    } catch (e) {
      const detail = getStringMessageFromError(e)
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Something went wrong</StatusPopup.Title>
          <StatusPopup.Description>{detail}</StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setPending(false)
    }
  }
  if (loadingInterview || loadingFeatureFlags) {
    return (
      <VStack aria-labelledby="loading" gap={Token.space.s16}>
        <TextSkeleton />
      </VStack>
    )
  }
  if (cancelled || !interview) {
    return (
      <Flex alignItems="center" height="100vh">
        <SuccessWidget
          type="error"
          title="Interview cancelled"
          text="Kindly reach out to the person who initially scheduled this interview to schedule a new one"
        />
      </Flex>
    )
  }
  if (!canCancel) {
    return null
  }
  return (
    <Box
      color={Token.color.foreground}
      maxWidth={505}
      mt={Token.space.s40}
      px={Token.space.s16}
    >
      {!!interview && (
        <Box pt={{ all: '0px', lg: '90px' }}>
          <Text
            color={Token.color.foreground}
            display="inline-block"
            pb={Token.space.s8}
            variant="h5"
          >
            Hey {interview.candidate?.full_name},
          </Text>
        </Box>
      )}
      <Box my={Token.space.s56}>
        <VStack gap={Token.space.s16}>
          <Text variant="body1">
            We understand that unforeseen circumstances might prevent you from attending
            the interview at the previously agreed time.
          </Text>
          <Text variant="body1">
            Please, let us know why you need to cancel the interview so we can organize
            the next one efficiently
          </Text>
          <Input
            label="Message (optional)"
            pending={pending}
            value={message}
            onChange={event => {
              setMessage(event.currentTarget.value)
            }}
          />
          <Button pending={pending} onClick={handleCancelInterview}>
            Cancel my interview
          </Button>
        </VStack>
      </Box>
    </Box>
  )
}
