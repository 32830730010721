import React from 'react'
import { Link } from 'react-router-dom'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { ActionButton, Tooltip, useTooltip } from '@revolut/ui-kit'
import { generatePositionId } from '@src/pages/Careers/utils'

type Props = {
  positionTitle: string
  positionId: string
  disabled?: boolean
}

const CareersPositionApplyButton = ({ positionTitle, positionId, disabled }: Props) => {
  const tooltip = useTooltip()

  return (
    <>
      <ActionButton
        use={disabled ? undefined : Link}
        // @ts-expect-error object works fine here, but UI kit expects string
        to={getLocationDescriptor(
          pathToUrl(PUBLIC_ROUTES.CAREERS.APPLICATION, {
            id: generatePositionId(positionTitle, positionId),
          }),
        )}
        variant={disabled ? undefined : 'black'}
        aria-disabled={disabled}
        {...tooltip.getAnchorProps()}
      >
        Apply for this role
      </ActionButton>
      {disabled && (
        <Tooltip {...tooltip.getTargetProps()}>
          Apply button is disabled in the Preview mode
        </Tooltip>
      )}
    </>
  )
}

export default CareersPositionApplyButton
