import {
  EligibleEmployeesInterface,
  ValidationErrorType,
} from '@src/interfaces/supportTool/eligibleEmployees'
import { IconName } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { InvalidEligibilityItemProps } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/components/EligibilityList/ListItems'
import { GetRequestInterface } from '@src/interfaces'
import { useOpenNewTab } from '@src/actions/RouterActions'

export const useAdaptInvalidEligibilityObjects = (
  pages: GetRequestInterface<EligibleEmployeesInterface>[],
): InvalidEligibilityItemProps[] => {
  const openInNewTab = useOpenNewTab()

  return pages
    .reduce<EligibleEmployeesInterface[]>((eligibleEmployees, { results }) => {
      return [...eligibleEmployees, ...results]
    }, [])
    .reduce<InvalidEligibilityItemProps[]>((errors, eligibilityObject) => {
      const { id, employee, validation_errors } = eligibilityObject

      return [
        ...errors,
        ...(validation_errors ?? []).map<InvalidEligibilityItemProps>(
          ({ error, error_type, object_id, object_title }) => {
            const titleLinkGetter = errorTypeToTitleLinkGetter[error_type]
            const actionLinkGetter = errorTypeToActionLinkGetter[error_type]

            return {
              key: `${id}:${error}`,
              title: object_title ?? employee.name,
              description: error,
              iconName: errorTypeToIconNameMap[error_type],
              actionLabel: errorTypeToActionLabelMap[error_type],
              titleLink: titleLinkGetter ? titleLinkGetter(eligibilityObject) : undefined,
              handleActionClick: () =>
                openInNewTab(actionLinkGetter(object_id, eligibilityObject)),
            }
          },
        ),
      ]
    }, [])
}

export const errorTypeToIconNameMap: Record<ValidationErrorType, IconName> = {
  [ValidationErrorType.ManagerDeactivated]: 'Profile',
  [ValidationErrorType.NoSeniority]: 'RepairTool',
  [ValidationErrorType.NoSpecialisation]: 'RepairTool',
  [ValidationErrorType.NoSkills]: 'RepairTool',
  [ValidationErrorType.NoSkillsDistribution]: 'RepairTool',
}

export const errorTypeToActionLabelMap: Record<ValidationErrorType, string> = {
  [ValidationErrorType.ManagerDeactivated]: 'Assign new manager',
  [ValidationErrorType.NoSeniority]: 'Set seniority',
  [ValidationErrorType.NoSpecialisation]: 'Set specialisation',
  [ValidationErrorType.NoSkills]: 'Set skills',
  [ValidationErrorType.NoSkillsDistribution]: 'Set skills distribution',
}

const employeeProfilePath = ROUTES.FORMS.EMPLOYEE.PROFILE
const competencyMatrixPath = ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX
const editEligibleEmployeePath = ROUTES.FORMS.ELIGIBLE_PEOPLE_EDIT.GENERAL

type LinkCreatorFunction = (
  id: number | null,
  eligibilityObject: EligibleEmployeesInterface,
) => string

const getEmployeeProfilePath = (id: string | number): string =>
  pathToUrl(employeeProfilePath, { id })
const getCompetencyMatrixPath: LinkCreatorFunction = id =>
  pathToUrl(competencyMatrixPath, { id })
const getEditEligibleEmployeePath: LinkCreatorFunction = (_id, eligibilityObject) =>
  pathToUrl(editEligibleEmployeePath, { id: eligibilityObject.id })

export const errorTypeToActionLinkGetter: Record<
  ValidationErrorType,
  LinkCreatorFunction
> = {
  [ValidationErrorType.ManagerDeactivated]: (id, eligibilityObject) =>
    getEditEligibleEmployeePath(id, eligibilityObject),
  [ValidationErrorType.NoSeniority]: (id, eligibilityObject) =>
    getEditEligibleEmployeePath(id, eligibilityObject),
  [ValidationErrorType.NoSpecialisation]: (id, eligibilityObject) =>
    getEditEligibleEmployeePath(id, eligibilityObject),
  [ValidationErrorType.NoSkills]: (id, eligibilityObject) =>
    getCompetencyMatrixPath(id, eligibilityObject),
  [ValidationErrorType.NoSkillsDistribution]: (id, eligibilityObject) =>
    getCompetencyMatrixPath(id, eligibilityObject),
}

export const errorTypeToTitleLinkGetter: Partial<
  Record<ValidationErrorType, (eligibilityObject: EligibleEmployeesInterface) => string>
> = {
  [ValidationErrorType.ManagerDeactivated]: (
    eligibilityObject: EligibleEmployeesInterface,
  ) => getEmployeeProfilePath(eligibilityObject.employee.id),
  [ValidationErrorType.NoSeniority]: (eligibilityObject: EligibleEmployeesInterface) =>
    getEmployeeProfilePath(eligibilityObject.employee.id),
  [ValidationErrorType.NoSpecialisation]: (
    eligibilityObject: EligibleEmployeesInterface,
  ) => getEmployeeProfilePath(eligibilityObject.employee.id),
}
