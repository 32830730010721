import React, { useRef } from 'react'
import {
  Ellipsis,
  FilterButtonSkeleton,
  Flex,
  Icon,
  MoreBar,
  Text,
  useToggle,
} from '@revolut/ui-kit'

import FilterSelect, {
  FilterSelectType,
} from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { RoundOption } from './useTimelineFilter'

export const roundSelectorIcon = 'CalendarCheck'

interface Props {
  value: RoundOption | undefined
  onChange: (newRound: RoundOption) => void
  options: RoundOption[]
  isLoadingOptions: boolean
}
export const RoundSelector = ({ value, options, isLoadingOptions, onChange }: Props) => {
  const [open, toggleOpen] = useToggle()
  const selectRef = useRef<HTMLButtonElement>(null)

  if (isLoadingOptions) {
    return <FilterButtonSkeleton width={200} />
  }
  return (
    <>
      <MoreBar.Action
        useIcon={roundSelectorIcon}
        aria-label="engagement round selector"
        aria-haspopup="listbox"
        onClick={toggleOpen.switch}
        aria-expanded={open}
        ref={selectRef}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          <Ellipsis>
            <Text variant="emphasis2">{value?.name || 'Survey round'}</Text>
          </Ellipsis>

          <Icon name={open ? 'ChevronUp' : 'ChevronDown'} />
        </Flex>
      </MoreBar.Action>
      <FilterSelect
        open={open}
        onClose={() => {
          toggleOpen.off()
        }}
        selector={() => Promise.resolve({ options })}
        anchorRef={selectRef}
        value={value ? [value] : undefined}
        onChange={newValue => {
          const [option] = newValue

          if (option) {
            toggleOpen.off()
            onChange(option)
          }
        }}
        type={FilterSelectType.SingleSelect}
        width={100}
      />
    </>
  )
}
