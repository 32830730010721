export enum meetingRecurrence {
  Daily = 'Daily',
  Weekly = 'Weekly',
  BiWeekly = 'Bi-Weekly',
  Monthly = 'Monthly',
  Single = 'Single event',
  Custom = 'Custom',
}

export enum rruleRecurrence {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum rruleWeekday {
  MO = 'MO',
  TU = 'TU',
  WE = 'WE',
  TH = 'TH',
  FR = 'FR',
}

export interface rruleInterface {
  FREQ?: rruleRecurrence
  BYDAY?: rruleWeekday[]
  INTERVAL?: string
}
