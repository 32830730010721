import { useMemo, useState } from 'react'
import { DateValue } from '@revolut/ui-kit/types/dist/components/Calendar/types'
import { Token } from '@revolut/ui-kit'
import { utcToZonedTime } from 'date-fns-tz'
import { DaySlotGroupInterface } from '@src/pages/Forms/Candidate/ScheduleSidebar/DaySlotsGroup/DaySlotsGroup'

export const useCalendar = <T>(slots: DaySlotGroupInterface<T>[], timezone: string) => {
  const [firstSlot] = slots
  const [date, setDate] = useState<DateValue>()
  if (!date && firstSlot) {
    setDate(new Date(firstSlot.groupDate))
  }
  const { disabledDays, events } = useMemo(() => {
    if (!slots.length) {
      return {
        disabledDays: [],
        events: [],
      }
    }
    const disabledDaysArr = [
      {
        // we disable all dates before the first slot
        before: new Date(firstSlot.groupDate),
      },
      // we map all slots to after:before pairs
      // except for last slot, in this case we only want to disable every date after it
      ...slots.map((slot, i) => {
        const next = slots[i + 1]
        const after = new Date(slot.groupDate)
        const before = next ? new Date(next.groupDate) : undefined
        return {
          after,
          before,
        }
      }),
    ]
    const eventsArr = slots.map(s => {
      const eventDate = new Date(s.groupDate)
      return {
        date: eventDate,
        color: Token.color.blue,
      }
    })
    return {
      disabledDays: disabledDaysArr,
      events: eventsArr,
    }
  }, [slots])
  const zonedDate = date && utcToZonedTime(date, timezone).toDateString()
  const dateGroup = zonedDate && slots.find(s => s.groupDate === zonedDate)
  return {
    date,
    dateGroup,
    disabledDays,
    events,
    setDate,
  }
}
