import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Text,
  Flex,
  Sticky,
  StatusPopup,
  Link,
  Token,
  ActionButton,
  VStack,
  DetailsCell,
  Group,
  DetailsCellSkeleton,
} from '@revolut/ui-kit'
import { getCurrentTimezone } from '@src/utils/timezones'
import { OptionInterface } from '@src/interfaces/selectors'
import SchedulingLinkExpired from '@src/pages/CandidateScheduling/SchedulingLinkExpired'
import { useGroupByDaySlots } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks'
import ConfirmedAppointment from '@src/pages/CandidateScheduling/ConfirmedAppointment'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import LazyLoadPlaceholder from '@components/LazyLoadPlaceholder/LazyLoadPlaceholder'
import {
  DurationUnitType,
  PredefinedAvailableCandidateSlot,
} from '@src/interfaces/interviewTool'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import Loader from '@components/CommonSC/Loader'
import { SelectSlotCalendar } from '@src/pages/CandidateScheduling/SelectSlotCalendar'
import { FormattedMessage } from 'react-intl'
import { SelectTimezone } from '@src/pages/CandidateScheduling/SelectTimezone'
import * as Sentry from '@sentry/react'

type InterviewDetails = {
  duration?: number | null
  durationUnit?: DurationUnitType
  id?: number
  recruiterEmail?: string
  timeZoneId?: string
  title?: string
  eventDateTime?: string
}

interface CandidateSelectSlotsProps<T> {
  confirmAppointment: boolean
  interview?: InterviewDetails
  isLoadingInterview: boolean
  isLoadingTimeZones: boolean
  noPadding?: boolean
  timeZones?: {
    options: OptionInterface[]
  }
  token: string
  onCreateCandidateAppointment: (timeZone: string, slot: T) => Promise<void>
  onGetAvailableCandidateSlots: (
    token: string,
    page: number,
    eventId?: string,
  ) => Promise<{
    slots: T[]
    count: number
  }>
  onRefetchInterview?: () => void
  onSlotChange?: () => void
  footer?: React.ReactNode
}

export const CandidateSelectSlots = <
  T extends Pick<PredefinedAvailableCandidateSlot, 'event_start_datetime'>,
>({
  confirmAppointment,
  interview,
  isLoadingInterview,
  isLoadingTimeZones,
  noPadding = false,
  timeZones,
  token,
  onCreateCandidateAppointment,
  onGetAvailableCandidateSlots,
  onRefetchInterview,
  onSlotChange,
  footer,
}: CandidateSelectSlotsProps<T>) => {
  const [loadingSlots, setLoadingSlots] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [slots, setSlots] = useState<T[]>([])
  const [totalCount, setTotalCount] = useState<number | null>(null)
  const [errorPopupOpen, setErrorPopupOpen] = useState(false)

  const currentTimeZoneId = getCurrentTimezone()

  const [timeZone, setTimeZone] = useState<OptionInterface>()
  const [sendingLoading, setSendingLoading] = useState(false)
  const [selectedSlotDate, setSelectedSlotDate] = useState<string | null>()

  const timeZoneId = timeZone ? String(timeZone.id) : currentTimeZoneId
  const normalizedSlots = useGroupByDaySlots(timeZoneId, slots)

  useEffect(() => {
    if (timeZones?.options && !timeZone) {
      setTimeZone(timeZones.options.find(item => item.id === currentTimeZoneId))
    }
  }, [timeZones])

  const onSubmit = async () => {
    setSendingLoading(true)

    const slot = slots.find(s => s.event_start_datetime === selectedSlotDate)

    if (!slot) {
      // should not happening
      Sentry.captureException('[CandidateSelectSlots] Slot not found by date')
      throw new Error('Slot not found')
    }

    try {
      await onCreateCandidateAppointment(timeZoneId, slot)
      if (onRefetchInterview) {
        onRefetchInterview()
      }
    } catch (e) {
      setErrorPopupOpen(true)
    } finally {
      setSendingLoading(false)
    }
  }

  const onLoadMore = async () => {
    if ((totalCount !== null && totalCount === slots.length) || loadingSlots) {
      return
    }

    setLoadingSlots(true)

    try {
      const resp = await onGetAvailableCandidateSlots(token, currentPage)

      setSlots([...slots, ...resp.slots])
      setCurrentPage(currentPage + 1)
      setTotalCount(resp.count)
    } finally {
      setLoadingSlots(false)
    }
  }

  if (isLoadingInterview) {
    return <Loader />
  }

  if (!interview && !isLoadingInterview) {
    return <SchedulingLinkExpired />
  }

  if (confirmAppointment && interview?.eventDateTime && interview?.timeZoneId) {
    return (
      <ConfirmedAppointment
        cancel
        date={interview.eventDateTime}
        timeZone={interview.timeZoneId}
      />
    )
  }

  return (
    <>
      <StatusPopup
        variant="error"
        open={errorPopupOpen}
        onClose={() => {
          setErrorPopupOpen(false)
        }}
      >
        <StatusPopup.Title>Something went wrong</StatusPopup.Title>
        {interview && (
          <StatusPopup.Description>
            Please refresh the page or reach out to the recruiter{' '}
            {interview.recruiterEmail && (
              <>
                (
                <Link href={`mailto:${interview.recruiterEmail}`}>
                  {interview.recruiterEmail}
                </Link>
                )
              </>
            )}
          </StatusPopup.Description>
        )}
      </StatusPopup>
      <VStack
        maxWidth={{ all: 400, md: 600 }}
        gap="s-16"
        px={noPadding ? undefined : 's-16'}
        pt={noPadding ? undefined : 's-32'}
        color={Token.color.foreground}
      >
        <Text variant="heading2">
          <FormattedMessage
            id="recruitment.candidateScheduling.candidateSelectSlots.title"
            defaultMessage="Let's get a suitable date booked for your next interview"
          />
        </Text>
        {interview ? (
          <Group>
            <DetailsCell>
              <DetailsCell.Title>Interview stage</DetailsCell.Title>
              <DetailsCell.Content>{interview.title}</DetailsCell.Content>
            </DetailsCell>
            {interview.duration && interview.durationUnit && (
              <DetailsCell>
                <DetailsCell.Title>Duration</DetailsCell.Title>
                <DetailsCell.Content>
                  {getDuration(interview.duration, interview.durationUnit)}
                </DetailsCell.Content>
              </DetailsCell>
            )}
          </Group>
        ) : (
          <DetailsCellSkeleton />
        )}
        <SelectTimezone
          loading={isLoadingTimeZones}
          options={timeZones?.options || []}
          timezone={timeZone}
          onChange={newTimezone => {
            if (newTimezone) {
              setTimeZone(newTimezone)
            }
          }}
        />
        <SelectSlotCalendar
          loading={loadingSlots}
          slots={normalizedSlots}
          timezone={timeZoneId}
          value={selectedSlotDate}
          onChange={newSlot => {
            setSelectedSlotDate(newSlot)
            if (onSlotChange) {
              onSlotChange()
            }
          }}
        />
        <LazyLoadPlaceholder onReach={onLoadMore} />
        {interview &&
          !loadingSlots &&
          totalCount !== null &&
          slots.length === totalCount && (
            <ActionWidget
              title="Not happy with the slots?"
              text="Kindly reach out to the recruiter to request new slots"
            >
              {interview.recruiterEmail ? (
                <ActionButton use="a" href={`mailto:${interview.recruiterEmail}`}>
                  Email recruiter
                </ActionButton>
              ) : null}
            </ActionWidget>
          )}

        <Sticky bottom={0}>
          <Box bg={Token.color.layoutBackground} pt="s-8">
            {footer}
            <Flex justifyContent="center">
              <Button
                elevated
                maxWidth={288}
                disabled={!selectedSlotDate || sendingLoading}
                pending={sendingLoading}
                onClick={onSubmit}
                data-testid="Confirm availability"
                mb="s-16"
              >
                Confirm availability
              </Button>
            </Flex>
          </Box>
        </Sticky>
      </VStack>
    </>
  )
}
