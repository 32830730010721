import { Group, Item, Spacer, Text } from '@revolut/ui-kit'
import React from 'react'
import { CyclesNavigationSkeleton } from '@src/pages/Forms/DepartmentForm/Performance/CyclesNavigation/CyclesNavigationSkeleton'
import { Cycle, CycleType } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { formatPercentage } from '@src/utils/format'
import {
  getOverallProgressGrade,
  gradeSettings,
} from '@src/pages/Forms/DepartmentForm/Performance/utils'

interface PerformanceCyclesNavigationProps {
  cyclesList: Cycle[] | undefined
  setSelectedCycle: (cycle: Cycle) => void
  selectedCycle: Cycle | undefined
}

export const PerformanceCyclesNavigation = ({
  cyclesList,
  selectedCycle,
  setSelectedCycle,
}: PerformanceCyclesNavigationProps) => {
  return (
    <Group
      height={{ all: '40vh', lg: 'fit-content' }}
      overflow={{ all: 'scroll', lg: 'unset' }}
    >
      {!cyclesList?.length && <CyclesNavigationSkeleton />}
      {cyclesList?.map(item => (
        <Item
          useIcon={item.type === CycleType.Year ? 'Calendar' : null}
          key={item.id + item.type}
          use="button"
          variant="compact"
          aria-pressed={selectedCycle?.review_cycle_ids === item.review_cycle_ids}
          onClick={() => {
            setSelectedCycle(item)
          }}
          py="s-16"
        >
          {item.type !== CycleType.Year && <Spacer width="s-40" />}
          <Item.Content>
            <Item.Title>{item.name}</Item.Title>
          </Item.Content>
          <Item.Side>
            <Text
              variant="body1"
              whiteSpace="nowrap"
              color={
                gradeSettings?.[
                  getOverallProgressGrade(
                    item.overall_progress
                      ? item.overall_progress.percent * 100
                      : undefined,
                  )
                ].color
              }
            >
              {formatPercentage(item.overall_progress?.percent ?? null)}
            </Text>
          </Item.Side>
        </Item>
      ))}
    </Group>
  )
}
