import React, { useEffect } from 'react'
import { Token, Flex, Button, TabBar } from '@revolut/ui-kit'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'

import { GoalForm } from './Form/GoalForm'
import { useFormObserver } from './Form/Widgets/FormObserverProvider'
import { useGoalFormSubmit } from './Form/useGoalFormSubmit'
import { useGoalFormCache } from './useGoalFormCache'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { useQuery } from '@src/utils/queryParamsHooks'
import { EntityTypes } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { LocalStorageKeys, PermissionTypes } from '@src/store/auth/types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

export const GoalFormPageBody = ({
  performanceSettings,
  defaultReviewCycle,
  onSubmitted,
  onDirtyChanged,
}: {
  performanceSettings: PerformanceSettingsInterface
  defaultReviewCycle?: ReviewCyclesInterface
  onSubmitted: () => void
  onDirtyChanged: (isDirty: boolean) => void
}) => {
  const goalForm = useLapeContext<GoalsInterface & { targets: unknown }>()
  const { values, dirty, initialValues } = goalForm
  const { hasDirtyForms } = useFormObserver()
  const { cacheUpdated } = useGoalFormCache()
  const { submit } = useGoalFormSubmit()
  const permissions = useSelector(selectPermissions)
  const showStatusPopup = useShowStatusPopup()
  const isDraft = values.approval_status.id === ApprovalStatuses.Draft

  const { query, changeQueryParam } = useQuery<{
    type: EntityTypes.department | EntityTypes.team | EntityTypes.companyV2
  }>()

  const isDirty = hasDirtyForms() || dirty

  useEffect(() => {
    onDirtyChanged(isDirty)
  }, [isDirty])

  useEffect(() => {
    if (!values.is_company && query.type === EntityTypes.companyV2) {
      values.is_company = true
    }
    const integrationConnected = workspaceLocalStorage.getItem(
      LocalStorageKeys.CONNECTED_INTEGRATION,
    )
    if (integrationConnected) {
      showStatusPopup({
        status: 'success',
        title: `${integrationConnected} integration successful`,
      })
      workspaceLocalStorage.removeItem(LocalStorageKeys.CONNECTED_INTEGRATION)
    }
  }, [])

  const onSubmit = async (updateType: 'draft' | 'submit') => {
    try {
      await submit(updateType)
      onSubmitted()
    } catch (err) {
      // that's ok, validation error
    }
  }

  const handleChangeType = (
    value: EntityTypes.department | EntityTypes.team | EntityTypes.companyV2,
  ) => {
    if (value === EntityTypes.companyV2) {
      values.is_company = true
    } else {
      values.is_company = false
    }
    // @ts-expect-error initial value is partial but that's actually what we want to here when reseting form fields
    values.content_object = initialValues.content_object
    // @ts-expect-error initial value is partial but that's actually what we want to here when reseting form fields
    values.content_type = initialValues.content_type
    changeQueryParam('type', value)
  }

  return (
    <PageBody maxWidth={{ all: Token.breakpoint.lg, xxl: Token.breakpoint.lg }}>
      {!initialValues.is_company && !initialValues.content_type && !initialValues.parent && (
        <TabBar
          onChange={value => {
            if (value) {
              handleChangeType(value)
            }
          }}
          variant="segmented"
          defaultValue={query.type || EntityTypes.companyV2}
          mb="s-24"
        >
          {permissions.includes(PermissionTypes.CanAddCompanyGoal) && (
            <TabBar.Item to={EntityTypes.companyV2}>Company goal</TabBar.Item>
          )}
          {permissions.includes(PermissionTypes.CanAddDepartmentGoal) && (
            <TabBar.Item to={EntityTypes.department}>Department goal</TabBar.Item>
          )}
          {permissions.includes(PermissionTypes.CanAddTeamGoal) && (
            <TabBar.Item to={EntityTypes.team}>Team goal</TabBar.Item>
          )}
        </TabBar>
      )}

      {cacheUpdated && (
        <GoalForm
          enforceParentKpi={performanceSettings.enable_mandatory_parent_kpi}
          defaultReviewCycle={defaultReviewCycle}
        />
      )}

      <PageActions alignSelf="center" aria-label="page actions" mt="s-24">
        <Flex justifyContent="center" maxWidth="340px" alignSelf="center" gap="s-8">
          {isDraft
            ? isDirty && (
                <Button variant="secondary" onClick={() => onSubmit('draft')}>
                  Save as draft
                </Button>
              )
            : null}
          {isDirty && (
            <Button onClick={() => onSubmit('submit')} elevated>
              {isDraft ? 'Submit' : 'Save changes'}
            </Button>
          )}
        </Flex>
      </PageActions>
    </PageBody>
  )
}
