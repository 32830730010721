import React, { useMemo, useState } from 'react'
import SlotsSelector from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotsSelector'
import { getTimeZoneId } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { utcToZonedTime } from 'date-fns-tz'
import { addWeeks, formatISO } from 'date-fns'
import { OptionInterface } from '@src/interfaces/selectors'
import { DateFilterOptions } from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotDateFilter'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendEngagementEmailInterface } from '@src/interfaces/CRM'
import { AvailableInterviewerSlot, DurationUnitType } from '@src/interfaces/interviewTool'
import { useGetAvailableInterviewSlots } from '@src/api/recruitment/interviewerScheduling'
import CalendarSelect from '@src/pages/Forms/Candidate/ScheduleSidebar/CalendarSelect'
import { InputGroup, VStack } from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { IdAndName } from '@src/interfaces'

type SlotsFormProps = {
  roundId: number
  stageId: number
}

const SlotsForm = ({ roundId, stageId }: SlotsFormProps) => {
  const { values } = useLapeContext<SendEngagementEmailInterface>()
  if (!values.is_adhoc) {
    values.is_adhoc = true
  }
  if (values.email_body) {
    values.email_content = values.email_body
    values.email_body = undefined
  }
  if (values.subject) {
    values.email_subject = values.subject
    values.subject = undefined
  }
  const { data: durationUnits = [], isFetched: unitsFetched } = useGetSelectors<
    IdAndName<DurationUnitType>
  >(selectorKeys.hiring_stage_duration_units)
  const durationUnitOptions = useMemo(
    () =>
      durationUnits?.map(item => ({
        label: item.name,
        value: item,
      })),
    [durationUnits],
  )
  if (!values.duration_unit && unitsFetched) {
    values.duration_unit = durationUnits[0]
  }
  const timeZoneId = getTimeZoneId()
  const [dateFilter, setDateFilter] = useState<OptionInterface | undefined>(
    DateFilterOptions[1],
  )
  const [interviewerFilter, setInterviewerFilter] = useState<OptionInterface[]>()
  const [interviewerGroupsFilter, setInterviewerGroupsFilter] = useState<IdAndName[]>()
  const [selectedSlots, setSelectedSlots] = useState<AvailableInterviewerSlot[]>()
  const dateRange = useMemo(() => {
    const timeZonedDate = utcToZonedTime(new Date(), timeZoneId)
    return [
      formatISO(timeZonedDate, { representation: 'date' }),
      formatISO(addWeeks(timeZonedDate, dateFilter ? +dateFilter.id : 2), {
        representation: 'date',
      }),
    ]
  }, [dateFilter, timeZoneId])
  const { data, isLoading } = useGetAvailableInterviewSlots({
    stageId,
    roundId,
    dateRange,
    interviewers: interviewerFilter,
    interviewerGroups: interviewerGroupsFilter,
    isAdhoc: true,
  })
  return (
    <VStack gap="s-16">
      <InputGroup variant="horizontal">
        <LapeNewInput
          name="duration"
          label="Duration"
          width="50%"
          type="number"
          required
        />
        <LapeRadioSelectInput
          label="Unit"
          labelPath="name"
          name="duration_unit"
          options={durationUnitOptions}
        />
      </InputGroup>
      <CalendarSelect roundId={roundId} />
      <SlotsSelector
        dateFilter={dateFilter}
        availableSlots={data?.results ?? []}
        loadingSlots={isLoading}
        timeZoneId={timeZoneId}
        interviewerFilter={interviewerFilter}
        interviewerGroupsFilter={interviewerGroupsFilter}
        isPrepCall
        selectedSlots={selectedSlots}
        stageId={stageId}
        roundId={roundId}
        onInterviewerFilterChange={(
          newInterviewersFilter: OptionInterface[] | undefined,
        ) => {
          setInterviewerFilter(newInterviewersFilter)
        }}
        onInterviewerGroupsFilterChange={newInterviewerGroupsFilter => {
          setInterviewerGroupsFilter(newInterviewerGroupsFilter)
        }}
        onDateFilterChange={(newDateFilter: OptionInterface | undefined) => {
          setDateFilter(newDateFilter)
        }}
        onSlotChange={(newSlots: AvailableInterviewerSlot[]) => {
          setSelectedSlots(newSlots)
          values.proposed_event_ids = newSlots.map(({ id }) => id)
        }}
      />
    </VStack>
  )
}

export default SlotsForm
