import React from 'react'
import { Box, Flex, Text } from '@revolut/ui-kit'
import { getTimeZoneLabel } from '@src/utils/timezones'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { getAvatarUrl } from '@src/utils/employees'
import { DaySlotGroupItemInterface } from '@src/pages/Forms/Candidate/ScheduleSidebar/DaySlotsGroup/DaySlotsGroup'

type Props = {
  day: string
  items: DaySlotGroupItemInterface[]
  timeZone: string
}

const DayTimeAvatar = ({ day, items, timeZone }: Props) => {
  return (
    <Box width="100%" aria-label={`day-${day}`}>
      <Text fontWeight={500} fontSize="primary" use="div" pb="s-8">
        {day}
      </Text>
      {items.map(slot => (
        <Flex key={slot.id} alignItems="center" justifyContent="space-between" py="s-2">
          <Text use="div">{getTimeZoneLabel(slot.event_start_datetime, timeZone)}</Text>
          <UserWithAvatar
            avatar={getAvatarUrl(slot.employee?.avatar)}
            full_name={slot.employee?.full_name}
            id={slot.employee?.id}
            compact
            ml="s-8"
          />
        </Flex>
      ))}
    </Box>
  )
}

export default DayTimeAvatar
