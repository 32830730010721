import { useState } from 'react'

export const useSidebar = <T>() => {
  const [state, setState] = useState<T>()
  return {
    sidebar: state,
    toggleSidebar: (sidebar: T) => {
      setState(sidebar === state ? undefined : sidebar)
    },
    closeSidebar: () => {
      setState(undefined)
    },
  }
}
