import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  Dropdown,
  Flex,
  HStack,
  MoreBar,
  Spinner,
  TextButton,
  Token,
} from '@revolut/ui-kit'

import LegacyButton from '@components/Button/Button'
import Dialog from '@components/Modals/Dialog/Dialog'
import { useTable } from '@components/TableV2/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import { navigateTo } from '@src/actions/RouterActions'
import {
  cancelEmployeeFieldRequest,
  cancelEmployeeFieldsRequest,
  changelogEmployeeRequests,
  fieldChangelogEmployeeRequests,
} from '@src/api/changelog'
import { approveChangeRequest, rejectChangeRequest } from '@src/api/employeeChangeRequest'
import { selectorKeys } from '@src/constants/api'
import {
  changelogAction,
  changelogChangedBy,
  changelogChangedFields,
  changelogChangedOn,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
  changelogStatus,
} from '@src/constants/columns/changelog'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import {
  ChangelogChangeRequestInterface,
  ChangelogInterface,
} from '@src/interfaces/changelog'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { EmployeeChangeRequestParams } from '@src/interfaces/employeeChangeRequest'
import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { pathToUrl } from '@src/utils/router'
import { useSelector } from 'react-redux'
import Stat from '@src/components/Stat/Stat'

const CustomDialog = styled(Dialog)`
  padding: 32px;
  max-width: 480px;
`

const DialogTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
`

const DialogMessage = styled.div`
  margin-bottom: 24px;
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  justify-content: end;
  align-items: center;
`

interface ChangelogProps {
  data: EmployeeInterface
}

const ChangelogEmployeeRow: RowInterface<ChangelogInterface<EmployeeInterface>> = {
  highlight: data => {
    if (data.status?.id === Statuses.pending) {
      return Token.color.orange_5
    }

    return ''
  },
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...changelogChangedFields,
      selectorsKey: selectorKeys.employee_changelog_fields,
      width: 240,
    },
    {
      ...changelogEffectiveDate,
      width: 240,
    },
    {
      ...changelogChangedBy,
      width: 240,
    },
    {
      ...changelogChangedOn,
      width: 240,
    },
    {
      ...changelogStatus,
      width: 240,
    },
  ],
}

const FieldsChangelogEmployeeRow: RowInterface<ChangelogInterface<EmployeeInterface>> = {
  highlight: data => {
    if (data.status?.id === Statuses.pending) {
      return Token.color.orange_5
    }

    return ''
  },
  noChildrenRequest: true,
  isNotNested: true,
  cells: [
    {
      ...changelogFieldsChangedFields,
      selectorsKey: selectorKeys.employee_changelog_fields,
      width: 210,
    },
    {
      ...changelogEffectiveDate,
      width: 205,
    },
    {
      ...changelogFieldsNewValue,
      selectorsKey: selectorKeys.employee_changelog_values,
      width: 205,
    },
    {
      ...changelogChangedBy,
      width: 205,
    },
    {
      ...changelogChangedOn,
      width: 205,
    },
    {
      ...changelogStatus,
      width: 205,
    },
  ],
}

export const Changelog = ({ data }: ChangelogProps) => {
  const [showIndividual, setShowIndividual] = useState(true)
  const [canceling, setCanceling] = useState(false)
  const [cancelingId, setCancelingId] = useState<(number | string)[] | null>(null)
  const [isChangeRequestDropdownOpen, setIsChangeRequestDropdownOpen] = useState(false)
  const permissions = useSelector(selectPermissions)
  const canCancel = permissions.includes(PermissionTypes.ChangeEmployeechangelogitem)
  const initialFilter: FilterByInterface[] = [
    {
      columnName: 'target__id',
      nonResettable: true,
      filters: [{ id: data.id, name: data.full_name }],
    },
  ]

  const requestChangeButtonRef = useRef(null)

  useEffect(() => {
    if (showIndividual) {
      changelogFieldsTable.refresh()
    } else {
      changelogTable.refresh()
    }
  }, [showIndividual])

  const initialSort: SortByInterface[] = [
    {
      sortBy: 'effective_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const changelogTable = useTable<ChangelogInterface<EmployeeInterface>>(
    changelogEmployeeRequests(data.id),
    initialFilter,
    initialSort,
  )
  const changelogFieldsTable = useTable<ChangelogInterface<EmployeeInterface>>(
    fieldChangelogEmployeeRequests(data.id),
    initialFilter,
    initialSort,
  )

  ChangelogEmployeeRow.cells[5] = {
    ...changelogAction,
    width: 240,
    insert: ({ data: cellData }) => {
      if (
        cellData.status?.id === Statuses.pending &&
        canCancel &&
        cellData.change_request == null
      ) {
        return (
          <TextButton
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setCancelingId([cellData.id])
            }}
          >
            Cancel
          </TextButton>
        )
      }

      return (
        <ChangeRequestButtons
          request={cellData.change_request}
          employeeId={data.id}
          onSuccess={() => {
            changelogTable.refresh()
            changelogFieldsTable.refresh()
          }}
        />
      )
    },
  }

  FieldsChangelogEmployeeRow.cells[6] = {
    ...changelogAction,
    width: 205,
    insert: ({ data: cellData }) => {
      if (
        data.status?.id === IdStatuses.pending &&
        canCancel &&
        cellData.change_request == null
      ) {
        return (
          <TextButton
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setCancelingId([cellData.changelog_item_id!, cellData.field_name])
            }}
          >
            Cancel
          </TextButton>
        )
      }

      return (
        <ChangeRequestButtons
          request={cellData.change_request}
          employeeId={data.id}
          onSuccess={() => {
            changelogTable.refresh()
            changelogFieldsTable.refresh()
          }}
        />
      )
    },
  }

  const cancelChanges = async (id: number) => {
    const result = await cancelEmployeeFieldsRequest(id, data.id)

    if (result.data) {
      changelogTable.refresh()
      changelogFieldsTable.refresh()

      pushNotification({
        value: 'Change was successfully cancelled.',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    }
  }

  const cancelChange = async (id: (number | string)[]) => {
    const result = await cancelEmployeeFieldRequest(
      id[0] as string,
      id[1] as number,
      data.id,
    )

    if (result.data) {
      changelogFieldsTable.refresh()
      changelogTable.refresh()

      pushNotification({
        value: 'Change was successfully cancelled.',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    }
  }

  const handleConfirmCancel = async () => {
    try {
      setCanceling(true)
      if (showIndividual) {
        cancelChange(cancelingId!)
      } else {
        cancelChanges(cancelingId?.[0] as number)
      }
      setCancelingId(null)
    } finally {
      setCanceling(false)
    }
  }

  const total = showIndividual ? changelogFieldsTable.count : changelogTable.count
  const row = useMemo(
    () => (showIndividual ? FieldsChangelogEmployeeRow : ChangelogEmployeeRow),
    [showIndividual],
  )

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <Stat val={total} label="Total" />
        </Table.Widget.Info>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <MoreBar.Action
              ref={requestChangeButtonRef}
              onClick={() => setIsChangeRequestDropdownOpen(!isChangeRequestDropdownOpen)}
              useIcon="Plus"
            >
              Request a change
            </MoreBar.Action>
            <MoreBar.Action
              onClick={() => setShowIndividual(!showIndividual)}
              useIcon={showIndividual ? 'SwitchOn' : 'SwitchOff'}
            >
              Show individual fields
            </MoreBar.Action>
          </Table.Widget.MoreBar>
          <Dropdown
            open={isChangeRequestDropdownOpen}
            anchorRef={requestChangeButtonRef}
            onClose={() => setIsChangeRequestDropdownOpen(false)}
          >
            <Dropdown.Item
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
                    employeeId: `${data.id}`,
                    type: 'name',
                  } as EmployeeChangeRequestParams),
                )
              }
              use="button"
            >
              Name
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
                    employeeId: `${data.id}`,
                    type: 'organisation',
                  } as EmployeeChangeRequestParams),
                )
              }
              use="button"
            >
              Organisation
            </Dropdown.Item>
          </Dropdown>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable<ChangelogInterface<EmployeeInterface>>
            hideCount
            useWindowScroll
            name={
              showIndividual
                ? TableNames.EmployeeFieldsChangelog
                : TableNames.EmployeeChangelog
            }
            dataType="Change"
            row={row}
            {...changelogFieldsTable}
            noDataMessage="There are no changes to display"
          />
        </Table.Widget.Table>
      </Table.Widget>

      <CustomDialog
        open={cancelingId !== null}
        onClose={() => setCancelingId(null)}
        showCloseButton
      >
        <DialogTitle>Cancel this future update?</DialogTitle>
        <DialogMessage>
          Cancelling the future update can not be undone. Continue only if you are 100%
          sure of this.
        </DialogMessage>
        <ButtonContainer>
          <LegacyButton square styles="tertiary" onClick={() => setCancelingId(null)}>
            Go back
          </LegacyButton>
          <LegacyButton square loading={canceling} onClick={handleConfirmCancel}>
            Confirm Cancellation
          </LegacyButton>
        </ButtonContainer>
      </CustomDialog>
    </>
  )
}

interface ChangeRequestButtonsProps {
  request?: ChangelogChangeRequestInterface | null
  employeeId: number
  onSuccess: () => void
}

const ChangeRequestButtons = ({
  request,
  employeeId,
  onSuccess,
}: ChangeRequestButtonsProps) => {
  const [approvePending, setApprovePending] = useState(false)
  const [rejectPending, setRejectPending] = useState(false)

  if (request == null) {
    return null
  }

  const onApproveOrReject = async (operation: 'approve' | 'reject') => {
    const buttonStateSetter =
      operation === 'approve' ? setApprovePending : setRejectPending
    const approveRejectApi =
      operation === 'approve' ? approveChangeRequest : rejectChangeRequest

    buttonStateSetter(true)

    try {
      await approveRejectApi(employeeId, request.id)
      onSuccess()
      pushNotification({
        value: `Change was successfully ${
          operation === 'approve' ? 'approved' : 'rejected'
        }`,
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    } catch {
      buttonStateSetter(false)
    }
  }

  if (request.can_approve) {
    return (
      <Flex>
        <HStack space="s-8" align="center">
          {approvePending && <Spinner color={Token.color.blue} size={13} />}
          <TextButton
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onApproveOrReject('approve')
            }}
            mr="s-8"
            disabled={rejectPending}
          >
            Approve
          </TextButton>
        </HStack>
        <HStack space="s-8" align="center">
          {rejectPending && <Spinner color={Token.color.blue} size={13} />}
          <TextButton
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onApproveOrReject('reject')
            }}
            variant="danger"
            disabled={approvePending}
          >
            Reject
          </TextButton>
        </HStack>
      </Flex>
    )
  }

  return (
    <TextButton
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        navigateTo(
          pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.DETAILS, {
            employeeId,
            id: request.change_request_set.id,
          }),
        )
      }}
    >
      View request
    </TextButton>
  )
}
