import React from 'react'
import { Text, HStack, Avatar } from '@revolut/ui-kit'
import { ItemSwitch } from '@src/components/Inputs/ItemSwitch/ItemSwitch'
import { RequiredBadge } from './RequiredBadge'

/* this is just faked button to show LMs are different from FMs we do not actually have setting for that */
export const LineManagerReviewsSwitch = () => {
  return (
    <ItemSwitch
      avatar={
        <Avatar
          variant="brand"
          bg="transparent"
          size={40}
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D183.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D183@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D183@3x.png',
          }}
        />
      }
      title={
        <HStack gap="s-8" align="center">
          <Text>Line manager reviews</Text>
          <RequiredBadge />
        </HStack>
      }
      description="Review feedback from line managers are the foundation of the performance result"
      checked
      disabled
    />
  )
}
