import React, { useMemo } from 'react'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import { useGetEligibleInterviewers } from '@src/api/recruitment/interviewerScheduling'
import { transformEmployeeToOption } from '@src/utils/employees'

export type Props = {
  onChange: (options?: OptionInterface[]) => void
  value?: OptionInterface[]
  stageId?: number
  roundId?: number
  disabled?: boolean
  isPrepCall: boolean
}
const SlotInterviewerFilter = ({
  onChange,
  value,
  stageId,
  roundId,
  disabled,
  isPrepCall,
}: Props) => {
  const { data: eligibleReviewersData, isLoading } = useGetEligibleInterviewers(
    stageId,
    roundId,
    isPrepCall,
  )

  // we should not create a new array every time, as CheckboxSelect rely on this array to determine selected items
  const options = useMemo(
    () => eligibleReviewersData?.map(transformEmployeeToOption) || [],
    [eligibleReviewersData],
  )

  return (
    <FilterButtonCheckboxSelect
      options={options}
      label="Interviewers"
      onChange={onChange}
      value={value}
      disabled={disabled}
      width={352}
      searchable
      loading={isLoading}
    />
  )
}

export default SlotInterviewerFilter
