import { HStack, InputGroup, Side } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import React from 'react'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

export const ExternalChannelForm = ({ onAfterSubmit }: { onAfterSubmit: () => void }) => {
  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Name" required />
        <LapeNewInput name="handle" label="Handle" required />
      </InputGroup>
      <Side.Actions>
        <HStack space="s-16">
          <NewSaveButtonWithPopup
            useValidator
            successText="Data was successfully updated."
            onAfterSubmit={onAfterSubmit}
          />
        </HStack>
      </Side.Actions>
    </>
  )
}
