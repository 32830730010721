import React from 'react'
import { Bullet, List, Text } from '@revolut/ui-kit'
import {
  GrowthPlanActionInterface,
  GrowthPlanPerformanceSummaryInterface,
} from '@src/interfaces/growthPlans'

interface Props {
  requirements: string[]
}

export const RequirementsList = ({ requirements }: Props) => (
  <List variant="compact" use="ol">
    {requirements.map((requirement, index) => (
      <List.Item
        key={index}
        useIcon={
          <Bullet size={20}>
            <Text variant="caption" fontSize={12}>
              {index + 1}
            </Text>
          </Bullet>
        }
      >
        {requirement}
      </List.Item>
    ))}
  </List>
)

export const getRequirements = (
  action: GrowthPlanActionInterface,
  performanceSummary: GrowthPlanPerformanceSummaryInterface | null,
): string[] => {
  if (!action.object_id || !action.content_type || !performanceSummary) {
    return []
  }

  const metrics =
    action.content_type.model === 'skillset'
      ? performanceSummary.functional_skills
      : performanceSummary.culture_values

  const connectedMetric = metrics.find(({ id }) => id === action.object_id)

  return connectedMetric?.requirements ?? []
}
