import React, { useState } from 'react'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { BaseOptionId, OptionInterface } from '@src/interfaces/selectors'
import { HStack, VStack } from '@revolut/ui-kit'
import { SearchInput } from '@src/components/TableV2/SearchTable/SearchInput'
import { FilterByInterface } from '@src/interfaces/data'

interface SkillFilterProps {
  onFunctionChange: (newFilter: OptionInterface<BaseOptionId>[] | undefined) => void
  onSearchChange: (newFilter: FilterByInterface) => void
}

export const SkillFilter = ({ onFunctionChange, onSearchChange }: SkillFilterProps) => {
  const { data: functions } = useGetSelectors(selectorKeys.functions)
  const [selectedFunctions, setSelectedFunctions] = useState<OptionInterface[]>([])
  return (
    <VStack gap="s-16">
      <SearchInput
        placeholder="Search by name"
        onFilter={newSearchFilter => {
          onSearchChange(newSearchFilter)
        }}
      />
      <HStack gap="s-16">
        <FilterButtonCheckboxSelect
          label="Function"
          searchable
          width={300}
          options={functions || []}
          value={selectedFunctions}
          onChange={newSelectedFunctions => {
            setSelectedFunctions(newSelectedFunctions || [])
            onFunctionChange(newSelectedFunctions)
          }}
        />
      </HStack>
    </VStack>
  )
}
