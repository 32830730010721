import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { TimeOffPolicies } from '@src/pages/People/PeopleSubTabs/TimeOffPage/Policies'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { TimeOffPoliciesAssignments } from './Assignments'

const routes = [
  {
    url: ROUTES.SETTINGS.TIME_OFF_POLICIES.POLICIES,
    path: ROUTES.SETTINGS.TIME_OFF_POLICIES.POLICIES,
    component: TimeOffPolicies,
    title: 'Policies',
    canView: [PermissionTypes.ViewTimeOffPolicies],
  },
  {
    url: ROUTES.SETTINGS.TIME_OFF_POLICIES.ASSIGNMENTS,
    path: ROUTES.SETTINGS.TIME_OFF_POLICIES.ASSIGNMENTS,
    component: TimeOffPoliciesAssignments,
    title: 'Assignments',
    canView: [PermissionTypes.ViewTimeOffPolicies],
  },
]

export const TimeOffPoliciesSettings = () => {
  const permissions = useSelector(selectPermissions)
  const filteredRoutes = routes.filter(route =>
    route.canView
      ? route.canView?.some(permission => permissions.includes(permission))
      : true,
  )

  return (
    <PageWrapper>
      <PageHeader
        backUrl={pathToUrl(ROUTES.SETTINGS.TIME_OFF.GENERAL)}
        title="Time off policies"
      />

      <TabBarNavigation mb="s-16" tabs={filteredRoutes} />
      <Switch>
        {filteredRoutes.map(route => {
          return (
            <Route exact key={route.path} path={route.path}>
              <route.component />
            </Route>
          )
        })}
        <Route>
          <NoAccessError />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
