import React, { useMemo } from 'react'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { navigateTo } from '@src/actions/RouterActions'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@components/Page/Page'
import { bulkCascadeGoalsRequests } from '@src/api/goals'
import { GoalsOrganisationalUnit } from '@src/interfaces/goals'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { FormObserverProvider } from '@src/pages/Forms/GoalForm/Form/Widgets/FormObserverProvider'
import { CascadeGoalFormPageBody } from '@src/pages/Forms/GoalForm/CascadeGoal/CascadeGoalFormPageBody'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useGetCompany } from '@src/api/company'
import { useGetSelectors } from '@src/api/selectors'
import { useOrgUnitsProps } from './hooks/useOrgUnitsProps'

import { useLocation, useParams } from 'react-router-dom'
import { OrgEntityInterface } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

const contentLabelByType = {
  [EntityTypes.department]: 'department',
  [EntityTypes.team]: 'team',
  [EntityTypes.teams]: 'team',
  [EntityTypes.employees]: 'employee',
  [EntityTypes.employee]: 'employee',
  [EntityTypes.company]: 'company',
  [EntityTypes.companyV2]: 'company',
  [EntityTypes.function]: 'function',
  [EntityTypes.functions]: 'functions',
  [EntityTypes.role]: 'role',
  [EntityTypes.roles]: 'roles',
  [EntityTypes.specialisation]: 'specialisation',
  [EntityTypes.specialisations]: 'specialisations',
}

const General = () => {
  const location = useLocation<{
    entity: OrgEntityInterface
  }>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: company, isLoading: isLoadingCompany } = useGetCompany()
  const { data: organisationalUnits, isLoading: isLoadingOrgUnits } =
    useGetSelectors<GoalsOrganisationalUnit>(selectorKeys.organisational_units)

  const { data: departmentsUnits, isLoading: isLoadingDepartmentsUnits } =
    useGetSelectors<GoalsOrganisationalUnit>(selectorKeys.departments_company)
  const params = useParams<{ ownerId: string }>()

  const isLoadingUnits =
    isLoadingCompany || isLoadingOrgUnits || isLoadingDepartmentsUnits

  const additionalContentProps = useOrgUnitsProps({
    company,
    organisationalUnits,
    departmentsUnits,
  })

  const showStatusPopup = useShowStatusPopup()

  const backUrl = useMemo(() => {
    switch (location.state.entity.type) {
      case EntityTypes.employees:
        return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.GOALS.GENERAL, {
          id: location.state.entity.data.id,
        })
      case EntityTypes.teams:
        return pathToUrl(ROUTES.FORMS.TEAM.GOALS.GENERAL, {
          id: location.state.entity.data.id,
        })
      case EntityTypes.department:
        return pathToUrl(ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL, {
          id: location.state.entity.data.id,
        })
      default:
        return ROUTES.MAIN
    }
  }, [location.state.entity.type, location.state.entity.data.id])

  const entityTitle = location.state.entity.type
    ? contentLabelByType[location.state.entity.type]
    : ''

  const onSubmitted = () => {
    navigateTo(backUrl)

    showStatusPopup({
      title: 'Cascaded goal is created',
      status: 'success',
    })
  }

  return performanceSettings ? (
    <PageWrapper>
      <PageHeader
        variant="narrow"
        noWrap={false}
        title={`Cascade ${entityTitle} goal`}
        onClickBack={() => navigateTo(backUrl)}
        backUrl={backUrl}
        withVerticalSpacing
      />
      <FormObserverProvider>
        <CascadeGoalFormPageBody
          isLoadingUnits={isLoadingUnits}
          onSubmitted={onSubmitted}
          ownerId={params.ownerId}
          {...additionalContentProps}
        />
      </FormObserverProvider>
    </PageWrapper>
  ) : null
}

export const CascadeGoalForm = () => {
  return (
    <PageWrapper>
      <Form
        api={bulkCascadeGoalsRequests}
        fieldsToExclude={['kpis', 'roadmaps', 'dashboards']}
      >
        <General />
      </Form>
    </PageWrapper>
  )
}
