import React, { ComponentProps, useEffect, useState } from 'react'
import { Group } from '@revolut/ui-kit'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { VisibilitySettingsButton } from './VisibilitySettingsButton'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { VisibilityModal } from './VisibilityModal'
import { PeerReviewsSwitch } from './PeerReviewsSwitch'
import { UpwardsReviewsSwitch } from './UpwardsReviewsSwitch'
import { FunctionalManagerReviewsSwitch } from './FunctionalManagerReviewsSwitch'
import { LineManagerReviewsSwitch } from './LineManagerReviewsSwitch'
import { SelfReviewsSwitch } from './SelfReviewsSwitch'

/**
 * Needs to be wrapped in the form with performanceSettings
 */
export const ReviewSettings = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const [visibilityModal, setVisibilityModal] =
    useState<ComponentProps<typeof VisibilityModal>['variant']>()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangePerformancePreferences)

  useEffect(() => {
    if (values && !values?.enable_functional_management) {
      values.enable_functional_manager_reviews = false // disable functional manager reviews if the setting for functional manager was disabled
    }
  }, [values?.enable_functional_management])
  return (
    <>
      <Group>
        <SelfReviewsSwitch />
        <LineManagerReviewsSwitch />
        <FunctionalManagerReviewsSwitch
          disabled={disableEdit || !values.enable_functional_management}
        />
        <UpwardsReviewsSwitch
          disabled={disableEdit}
          descriptionSuffix={
            <VisibilitySettingsButton
              disabled={disableEdit || !values.enable_upwards_reviews}
              onClick={() => setVisibilityModal('upward')}
              value={values?.upwards_reviews_visibility?.name}
            />
          }
        />
        <PeerReviewsSwitch
          disabled={disableEdit}
          descriptionSuffix={
            <VisibilitySettingsButton
              disabled={disableEdit || !values.enable_peer_reviews}
              onClick={() => setVisibilityModal('peer')}
              value={values?.peer_reviews_visibility?.name}
            />
          }
        />
      </Group>
      <VisibilityModal
        variant={visibilityModal}
        onClose={() => {
          setVisibilityModal(undefined)
        }}
      />
    </>
  )
}
