import React from 'react'
import {
  ActionButtonSkeleton,
  MoreBar,
  Tooltip,
  useToggle,
  useTooltip,
} from '@revolut/ui-kit'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
  ReviewCycleStage,
} from '@src/interfaces/reviewCycles'
import { useScorecardsValidation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useScorecardsValidation'
import { ValidateScorecardsPopup } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/Popup'
import { SwitchStageAction } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/SwitchStageAction'
import { useScorecardsGeneration } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useScorecardsGeneration'
import { GenerateScorecardsPopup } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/GenerateScorecards/Popup'
import { timelineModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/TimelineModel'

interface Props {
  cycle: ReviewCyclesInterface
  stages: ReviewCycleStage[]
  stage: ReviewCycleStage
  eligibleGroups: EligibleGroupInterface[]
  canSwitchNext: boolean
  canSwitchPrevious: boolean
}

export const ReviewActions = ({
  cycle,
  stages,
  stage,
  eligibleGroups,
  canSwitchNext,
  canSwitchPrevious,
}: Props) => {
  const [isValidationPopupOpen, validationPopupToggler] = useToggle()
  const [isGenerationPopupOpen, generationPopupToggler] = useToggle()
  const validateActionTooltip = useTooltip()
  const {
    isValid,
    isLoading: isValidationDataLoading,
    canValidate,
  } = useScorecardsValidation(cycle.id)
  const {
    isPending: isGenerationPending,
    isLoading: isGenerationDataLoading,
    generate,
  } = useScorecardsGeneration(cycle.id)

  const isScorecardActionsEnabled = timelineModel.getCurrentStage(cycle, stages) === stage

  if (isValidationDataLoading || isGenerationDataLoading) {
    return <ActionButtonSkeleton width={200} />
  }

  return (
    <>
      {isScorecardActionsEnabled && (
        <MoreBar.Action
          variant={canValidate && !isValid ? 'accent' : undefined}
          useIcon="DocumentsPair"
          disabled={!canValidate}
          onClick={validationPopupToggler.on}
          {...validateActionTooltip.getAnchorProps()}
        >
          Validate scorecards
          {!canValidate && (
            <Tooltip
              {...validateActionTooltip.getTargetProps()}
              width={250}
              style={{ textAlign: 'center' }}
            >
              There are no eligible employees to generate scorecards for
            </Tooltip>
          )}
        </MoreBar.Action>
      )}
      {isScorecardActionsEnabled && isValid && (
        <MoreBar.Action
          variant={isValid ? 'accent' : undefined}
          useIcon="Document"
          pending={isGenerationPending}
          disabled={isGenerationPending}
          onClick={generationPopupToggler.on}
        >
          Generate scorecards
        </MoreBar.Action>
      )}
      {canSwitchNext && (
        <SwitchStageAction
          cycle={cycle}
          stages={stages}
          stage={stage}
          direction="forward"
        />
      )}
      {canSwitchPrevious && (
        <SwitchStageAction
          cycle={cycle}
          stages={stages}
          stage={stage}
          direction="backward"
        />
      )}
      <ValidateScorecardsPopup
        cycle={cycle}
        eligibleGroups={eligibleGroups}
        isOpen={isValidationPopupOpen}
        isConfirmDisabled={isValid && isGenerationPending}
        isConfirmPending={isValid && isGenerationPending}
        handleOnValidConfirm={generate}
        handleClose={validationPopupToggler.off}
      />
      <GenerateScorecardsPopup
        cycle={cycle}
        eligibleGroups={eligibleGroups}
        isOpen={isGenerationPopupOpen}
        isConfirmDisabled={isGenerationPending}
        isConfirmPending={isGenerationPending}
        handleClose={generationPopupToggler.off}
        handleConfirm={generate}
      />
    </>
  )
}
