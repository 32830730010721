import React, { useEffect, useMemo, useRef } from 'react'
import { CycleSummaryData } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { Avatar, Flex, Placeholder, Text, Token, VStack } from '@revolut/ui-kit'
import { Stats } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { QuarterGoalsTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterGoalsTable'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { QuarterRoadmapsTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterRoadmapTable'
import { KarmaInterface, KarmaStatsInterface } from '@src/interfaces/karma'
import { QuarterKarmaTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterKarmaTable'
import { CollapsableTableWrapper } from '@src/pages/Forms/DepartmentForm/Performance/Summary/CollapsableTableWrapper'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { getKarmaColor } from '@src/pages/Forms/DepartmentForm/Performance/utils'

const getFilter = (columnName: string, id: number, name: string) => {
  return {
    columnName,
    filters: [{ id, name }],
    nonResettable: true,
    disableQueryParam: true,
  }
}

const QuarterSummary = ({
  goalTableRequests,
  goalsSettings,
  roadmapsTableRequests,
  roadmapsSettings,
  karmaTableRequests,
  karmaSettings,
  selectedCycle,
  cycleStats,
  scrollGoalsRef,
  scrollRoadmapRef,
  scrollKarmaRef,
}: CycleSummaryData) => {
  const mounted = useRef(false)

  const goalsFilters = useMemo(
    () => [
      ...goalsSettings.initialFilters,
      getFilter('cycle__id', selectedCycle.review_cycle_ids[0], selectedCycle?.name),
    ],
    [selectedCycle],
  )
  const roadmapsFilters = useMemo(
    () => [
      ...roadmapsSettings.initialFilters,
      getFilter(
        'review_cycle__offset',
        selectedCycle.review_cycle_offsets[0],
        selectedCycle?.name,
      ),
    ],
    [selectedCycle],
  )
  const karmaFilters = useMemo(
    () => [
      ...karmaSettings.initialFilters,
      // yes, we actually need to send karma_cycle_ids as 'review_cycle__id'
      getFilter(
        'review_cycle__id',
        selectedCycle.karma_cycle_ids[0],
        selectedCycle?.name,
      ),
    ],
    [selectedCycle],
  )

  const goalsTable = useTable(
    goalTableRequests,
    goalsFilters,
    goalsSettings.initialSort,
    {
      parentIdFilterKey: 'parent_id',
      childrenFetchOmitFilterKeys: ['object_id'],
    },
  )
  const roadmapsTable = useTable<RoadmapInterface, Stats>(
    roadmapsTableRequests,
    roadmapsFilters,
    roadmapsSettings.initialSort,
    {
      omitKeys: ['cycle__id'],
    },
  )
  const karmaTable = useTable<KarmaInterface, KarmaStatsInterface>(
    karmaTableRequests,
    karmaFilters,
    karmaSettings.initialSort,
    {
      omitKeys: ['cycle__id'],
    },
  )

  useEffect(() => {
    // avoid multiple requests on initial load
    if (!mounted.current) {
      mounted.current = true
      return
    }

    goalsTable.onFilterChange(goalsFilters)
    roadmapsTable.onFilterChange(roadmapsFilters)
    karmaTable.onFilterChange(karmaFilters)
  }, [selectedCycle])

  const isEmptyCycle = !goalsTable.count && !roadmapsTable.count && !karmaTable.count
  return (
    <>
      <>
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap="s-16">
            <Avatar useIcon="BarChart" />
            <Text variant="emphasis1">Department Performance Summary</Text>
          </Flex>
        </Flex>
        {isEmptyCycle && (
          <Placeholder>
            <Placeholder.Image
              image={{
                default: `https://assets.revolut.com/assets/3d-images-v2/3D299.png`,
                '2x': `https://assets.revolut.com/assets/3d-images-v2/3D299@2x.png`,
                '3x': `https://assets.revolut.com/assets/3d-images-v2/3D299@3x.png`,
              }}
            />
            <Placeholder.Title>No data to display for selected cycle</Placeholder.Title>
          </Placeholder>
        )}
        {!isEmptyCycle && (
          <VStack space="s-16">
            <CollapsableTableWrapper
              ref={scrollGoalsRef}
              headerTitle="Goals"
              icon="Target"
              headerStat={cycleStats?.[0].goal_progress?.percent}
              headerStatColor={
                cycleStats
                  ? getPercentColor(
                      cycleStats[0].goal_progress
                        ? cycleStats[0].goal_progress.percent * 100
                        : 0,
                    )
                  : Token.color.foreground
              }
            >
              <QuarterGoalsTable table={goalsTable} />
            </CollapsableTableWrapper>
            <CollapsableTableWrapper
              ref={scrollRoadmapRef}
              headerTitle="Roadmap"
              icon="Map"
              headerStat={cycleStats?.[0].roadmap_progress?.percent}
              headerStatColor={
                cycleStats
                  ? getPercentColor(
                      cycleStats[0].roadmap_progress
                        ? cycleStats[0].roadmap_progress.percent * 100
                        : 0,
                    )
                  : Token.color.foreground
              }
            >
              <QuarterRoadmapsTable table={roadmapsTable} />
            </CollapsableTableWrapper>
            <CollapsableTableWrapper
              ref={scrollKarmaRef}
              headerTitle="Karma"
              icon="ArrowsSort"
              headerStat={cycleStats?.[0].karma_percentage?.percent}
              headerStatColor={
                cycleStats
                  ? getKarmaColor(cycleStats[0].karma_percentage?.percent)
                  : Token.color.foreground
              }
            >
              <QuarterKarmaTable table={karmaTable} />
            </CollapsableTableWrapper>
          </VStack>
        )}
      </>
    </>
  )
}

export default QuarterSummary
