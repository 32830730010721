import { Tag, Token } from '@revolut/ui-kit'
import React from 'react'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'

export const isCurrentStage = (
  round?: InterviewRoundInterface,
  stage?: InterviewStageWithoutRoundInterface,
) => round && stage && round?.latest_interview_stage?.id === stage?.id

export const CurrentStage = () => {
  return <Tag color={Token.color.accent}>Current</Tag>
}
