import { Button, StatusPopup, usePrevious } from '@revolut/ui-kit'
import { selectAuthenticated } from '@src/store/auth/selectors'
import { setShowSessionExpired } from '@src/store/auth/actions'
import { selectAuth } from '@src/store/auth/selectors'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const SessionExpiredPopup = () => {
  const authenticated = useSelector(selectAuthenticated)
  const prevAuthenticated = usePrevious(authenticated)
  const showSessionExpired = useSelector(selectAuth)?.showSessionExpired
  const dispatch = useDispatch()
  const statusPopup = useShowStatusPopup()

  useEffect(() => {
    if (prevAuthenticated && !authenticated && showSessionExpired) {
      dispatch(setShowSessionExpired(false))

      statusPopup({
        status: 'error',
        title: 'Session expired. Please login again',
        actions: popup => (
          <StatusPopup.Actions>
            <Button onClick={popup.hide} variant="secondary">
              Close
            </Button>
          </StatusPopup.Actions>
        ),
      })
    }
  }, [showSessionExpired, prevAuthenticated, authenticated])

  return null
}
