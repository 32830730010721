import React from 'react'
import MenuAction, { MenuActionType } from '@components/MenuAction/MenuAction'
import { DropdownItemProps } from '@revolut/ui-kit'
import {
  InterviewPendingSchedulingStatuses,
  InterviewStatuses,
} from '@src/interfaces/interviewTool'
import { useGetCandidateSettings } from '@src/api/settings'

interface Props {
  schedulingStatus: InterviewStatuses
  itemProps: DropdownItemProps
  menuType: MenuActionType
  onClick: VoidFunction
}

export const ScheduleInterviewAction = ({
  menuType,
  schedulingStatus,
  itemProps,
  onClick,
}: Props) => {
  const { data: candidateSettings } = useGetCandidateSettings()

  if (
    candidateSettings?.enable_scheduling &&
    InterviewPendingSchedulingStatuses.includes(schedulingStatus)
  ) {
    return (
      <MenuAction
        menuType={menuType}
        props={{
          ...itemProps,
          onClick,
        }}
      >
        Schedule interview
      </MenuAction>
    )
  }

  return null
}
