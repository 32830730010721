import React from 'react'
import { Avatar, Item, VStack, Widget } from '@revolut/ui-kit'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useGetJobDescriptionApprovals } from '@src/api/jobPosting'
import { RowInterface } from '@src/interfaces/data'
import { ApprovalFlowStep } from '@src/interfaces/approvalFlow'
import {
  approvalsApproverColumn,
  approvalsRoleColumn,
} from '@src/constants/columns/jobPostings/approvals'
import { TableNames } from '@src/constants/table'

const row: RowInterface<ApprovalFlowStep> = {
  cells: [
    {
      ...approvalsApproverColumn,
      width: 140,
    },
    {
      ...approvalsRoleColumn,
      width: 140,
    },
  ],
}

export const Approvals = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const enableApprovals = jobPostingSettings?.enable_approvals
  const approved = enableApprovals && values.approval_status === 'approved'
  const { data: approvalSteps, isRefetching: isApprovalLoading } =
    useGetJobDescriptionApprovals(!approved ? values?.id : undefined)

  if (approved) {
    return null
  }

  return (
    <VStack gap="s-16">
      <Widget>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="InfoOutline" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Job posting approval </Item.Title>
            <Item.Description>
              This job posting will be reviewed and approved by following employees
            </Item.Description>
          </Item.Content>
        </Item>
        <AdjustableTable
          count={approvalSteps?.length || 0}
          data={approvalSteps || []}
          dataType="Approval steps"
          loading={isApprovalLoading}
          name={TableNames.JobPostingRequisitions}
          row={row}
          useWindowScroll
        />
      </Widget>
    </VStack>
  )
}
