import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { isInDateRange } from '@src/utils/timezones'
import { compareAsc, compareDesc, endOfDay, startOfDay } from 'date-fns'
import { notReachable } from '@src/utils/notReachable'
import { cycleModel } from '@src/features/ReviewCycle/PerformanceReviewCycle/models/CycleModel'
import isPast from 'date-fns/isPast'

export enum ReviewCycleSubStage {
  EmployeeReview = 'EmployeeReview',
  ManagerReview = 'ManagerReview',
  HODCalibration = 'HODCalibration',
  HOFCalibration = 'HOFCalibration',
}

interface TimelineModel {
  defaultStage: ReviewCycleStage
  getSubStages: (stage: ReviewCycleStage) => ReviewCycleSubStage[]
  getNextStage: (
    cycle: ReviewCyclesInterface,
    availableStages: ReviewCycleStage[],
  ) => ReviewCycleStage | null
  getPreviousStage: (
    cycle: ReviewCyclesInterface,
    availableStages: ReviewCycleStage[],
  ) => ReviewCycleStage | null
  getCurrentStage: (
    cycle: ReviewCyclesInterface,
    availableStages: ReviewCycleStage[],
  ) => ReviewCycleStage
  getCurrentStageByDates: (
    cycle: ReviewCyclesInterface,
    availableStages: ReviewCycleStage[],
  ) => ReviewCycleStage
  getPeriodStartDay: (
    cycle: ReviewCyclesInterface,
    period: ReviewCycleStage | ReviewCycleSubStage,
  ) => Date | null
  getPeriodEndDay: (
    cycle: ReviewCyclesInterface,
    period: ReviewCycleStage | ReviewCycleSubStage,
  ) => Date | null
  isOngoingStage: (cycle: ReviewCyclesInterface, stage: ReviewCycleStage) => boolean
  isFinishedStage: (
    cycle: ReviewCyclesInterface,
    stage: ReviewCycleStage,
    availableStages: ReviewCycleStage[],
  ) => boolean
}

const defaultStage = ReviewCycleStage.DepartmentGoals

const getSubStages = (stage: ReviewCycleStage): ReviewCycleSubStage[] => {
  if (stage === ReviewCycleStage.Review) {
    return [ReviewCycleSubStage.EmployeeReview, ReviewCycleSubStage.ManagerReview]
  }

  if (stage === ReviewCycleStage.Calibration) {
    return [ReviewCycleSubStage.HODCalibration, ReviewCycleSubStage.HOFCalibration]
  }

  return []
}

const getNextStage = (
  cycle: ReviewCyclesInterface,
  availableStages: ReviewCycleStage[],
): ReviewCycleStage | null => {
  const currentStage = getCurrentStage(cycle, availableStages)
  const currentStageIdx = availableStages.indexOf(currentStage)
  const nextStageIdx = currentStageIdx + 1

  return nextStageIdx <= availableStages.length ? availableStages[nextStageIdx] : null
}

const getPreviousStage = (
  cycle: ReviewCyclesInterface,
  availableStages: ReviewCycleStage[],
): ReviewCycleStage | null => {
  const currentStage = getCurrentStage(cycle, availableStages)
  const currentStageIdx = availableStages.indexOf(currentStage)
  const prevStageIdx = currentStageIdx - 1

  return prevStageIdx >= 0 ? availableStages[prevStageIdx] : null
}

const getCurrentStage = (
  cycle: ReviewCyclesInterface,
  availableStages: ReviewCycleStage[],
): ReviewCycleStage => {
  if (cycleModel.isManual(cycle)) {
    return cycle.current_stage ?? defaultStage
  }

  return getCurrentStageByDates(cycle, availableStages)
}

const getCurrentStageByDates = (
  cycle: ReviewCyclesInterface,
  availableStages: ReviewCycleStage[],
): ReviewCycleStage => {
  const ongoingStages = availableStages.filter(stage => isOngoingStage(cycle, stage))

  return ongoingStages.length > 0 ? ongoingStages[ongoingStages.length - 1] : defaultStage
}

const getCalibrationStageStartDay = (cycle: ReviewCyclesInterface): Date | null => {
  const [firstCalibrationDay] = [
    cycle.department_owner_calibration_start_day,
    cycle.head_of_function_calibration_start_day,
  ]
    .filter(Boolean)
    .map<Date>(dateString => new Date(dateString))
    .sort(compareAsc)

  return firstCalibrationDay ?? null
}

const getReviewStageEndDay = (cycle: ReviewCyclesInterface): Date | null => {
  const [lastReviewDay] = [
    cycle.self_and_peer_reviews_last_day,
    cycle.managers_reviews_last_day,
  ]
    .filter(Boolean)
    .map<Date>(dateString => new Date(dateString))
    .sort(compareDesc)

  return lastReviewDay ?? null
}

const getPeriodStartDay = (
  cycle: ReviewCyclesInterface,
  period: ReviewCycleStage | ReviewCycleSubStage,
): Date | null => {
  const {
    department_kpi_period_start_day: depGoalsStartDay,
    team_kpi_period_start_day: teamGoalsStartDay,
    review_period_start_day: reviewStartDay,
    department_owner_calibration_start_day: hODCalibrationStartDay,
    head_of_function_calibration_start_day: hOFCalibrationStartDay,
    managers_publishing_day: managersPublishDay,
    reviews_publishing_day: employeesPublishDay,
  } = cycle

  switch (period) {
    case ReviewCycleStage.DepartmentGoals:
      return depGoalsStartDay ? startOfDay(new Date(depGoalsStartDay)) : null

    case ReviewCycleStage.TeamGoals:
      return teamGoalsStartDay ? startOfDay(new Date(teamGoalsStartDay)) : null

    case ReviewCycleStage.Review:
      return reviewStartDay ? new Date(reviewStartDay) : null

    case ReviewCycleStage.Calibration:
      return getCalibrationStageStartDay(cycle)

    case ReviewCycleStage.ManagersPublish:
      return managersPublishDay ? new Date(managersPublishDay) : null

    case ReviewCycleStage.EmployeesPublish:
    case ReviewCycleStage.Completed:
      return employeesPublishDay ? new Date(employeesPublishDay) : null

    case ReviewCycleSubStage.EmployeeReview:
    case ReviewCycleSubStage.ManagerReview:
      return reviewStartDay ? new Date(reviewStartDay) : null

    case ReviewCycleSubStage.HODCalibration:
      return hODCalibrationStartDay ? new Date(hODCalibrationStartDay) : null

    case ReviewCycleSubStage.HOFCalibration:
      return hOFCalibrationStartDay ? new Date(hOFCalibrationStartDay) : null

    default:
      return notReachable(period)
  }
}

const getPeriodEndDay = (
  cycle: ReviewCyclesInterface,
  period: ReviewCycleStage | ReviewCycleSubStage,
): Date | null => {
  const {
    department_kpi_period_end_day: depGoalsEndDay,
    team_kpi_period_end_day: teamGoalsEndDay,
    self_and_peer_reviews_last_day: employeesReviewEndDay,
    managers_reviews_last_day: managersReviewEndDay,
    head_of_function_and_department_last_calibration_day: calibrationEndDay,
    managers_publishing_day: managersPublishEndDay,
    reviews_publishing_day: employeesPublishEndDay,
  } = cycle

  switch (period) {
    case ReviewCycleStage.DepartmentGoals:
      return depGoalsEndDay ? endOfDay(new Date(depGoalsEndDay)) : null

    case ReviewCycleStage.TeamGoals:
      return teamGoalsEndDay ? endOfDay(new Date(teamGoalsEndDay)) : null

    case ReviewCycleStage.Review:
      return getReviewStageEndDay(cycle)

    case ReviewCycleStage.Calibration:
      return calibrationEndDay ? new Date(calibrationEndDay) : null

    case ReviewCycleStage.ManagersPublish:
      return managersPublishEndDay ? endOfDay(new Date(managersPublishEndDay)) : null

    case ReviewCycleStage.EmployeesPublish:
    case ReviewCycleStage.Completed:
      return employeesPublishEndDay ? endOfDay(new Date(employeesPublishEndDay)) : null

    case ReviewCycleSubStage.EmployeeReview:
      return employeesReviewEndDay ? new Date(employeesReviewEndDay) : null

    case ReviewCycleSubStage.ManagerReview:
      return managersReviewEndDay ? new Date(managersReviewEndDay) : null

    case ReviewCycleSubStage.HODCalibration:
    case ReviewCycleSubStage.HOFCalibration:
      return calibrationEndDay ? new Date(calibrationEndDay) : null

    default:
      return notReachable(period)
  }
}

const isOngoingStage = (
  cycle: ReviewCyclesInterface,
  stage: ReviewCycleStage,
): boolean => {
  if (cycleModel.isManual(cycle)) {
    return cycle.current_stage === stage
  }

  const today = new Date()
  const startDay = getPeriodStartDay(cycle, stage)
  const endDay = getPeriodEndDay(cycle, stage)

  if (!startDay || !endDay) {
    return false
  }

  return isInDateRange(startDay, endDay, today)
}

const isFinishedStage = (
  cycle: ReviewCyclesInterface,
  stage: ReviewCycleStage,
  availableStages: ReviewCycleStage[],
): boolean => {
  if (cycleModel.isManual(cycle)) {
    return (
      !!cycle.current_stage &&
      availableStages.indexOf(stage) < availableStages.indexOf(cycle.current_stage)
    )
  }

  const endDay = getPeriodEndDay(cycle, stage)

  return !!endDay && isPast(endDay)
}

const createTimelineModel = (): TimelineModel => {
  return {
    defaultStage,
    getSubStages,
    getNextStage,
    getPreviousStage,
    getCurrentStage,
    getCurrentStageByDates,
    getPeriodStartDay,
    getPeriodEndDay,
    isOngoingStage,
    isFinishedStage,
  }
}

export const timelineModel = createTimelineModel()
