import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PerformanceSelector } from '@src/interfaces/performance'
import { GeneralInfoWidget } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/GeneralInfoWidget'
import { ActionItemsWidget } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/ActionItems/ActionItemsWidget'

interface Props {
  employee: EmployeeInterface
  reviewCycle: PerformanceSelector | null
}

export const EditGrowthPlanFormContent = ({ employee, reviewCycle }: Props) => {
  return (
    <VStack space="s-16">
      <GeneralInfoWidget employee={employee} />
      <ActionItemsWidget employee={employee} reviewCycle={reviewCycle} />
    </VStack>
  )
}
