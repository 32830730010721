import React from 'react'
import { Flex, Text, Token } from '@revolut/ui-kit'
import CareersPositionLocationsList, {
  CareersPositionWidgetLocationsListProps,
} from '@src/pages/Careers/CareersPosition/CareersPositionLocationsList'

interface CareersPositionHeaderProps {
  text: React.ReactNode
  locationProps: CareersPositionWidgetLocationsListProps
}

export const CareersPositionHeader = ({
  text,
  locationProps,
}: CareersPositionHeaderProps) => {
  return (
    <>
      <Text
        variant="h1"
        textAlign="center"
        display="block"
        mb="s-16"
        color={Token.color.foreground}
      >
        {text}
      </Text>
      <Flex justifyContent="center" mx={{ all: 0, lg: '-s-32' }} px="s-16">
        <CareersPositionLocationsList {...locationProps} />
      </Flex>
    </>
  )
}
