import React, { useEffect } from 'react'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import {
  Box,
  InputGroup,
  Link,
  Text,
  Widget,
  ItemSkeleton,
  List,
  useStatusPopup,
  StatusPopup,
  Button,
} from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeMultiInput from '@components/Inputs/LapeFields/LapeMultiInput'
import { JobPostingLocationTypeInterface } from '@src/interfaces/jobPosting'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { connect, useLape } from 'lape'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReferCandidateInterface } from '@src/interfaces/referCandidate'
import { getAdditionalQuestions } from '@src/api/referCandidate'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'
import MultipleDetailsSkeleton from '@components/Skeletons/MultipleDetailsSkeleton'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { CONFLICT_OF_INTERESTS, REFERRAL_BONUSES } from '@src/constants/externalLinks'
import { Grid } from '@components/CommonSC/Grid'
import LapeSingleCheckbox from '@src/components/Inputs/LapeFields/LapeSingleCheckbox'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import NewCandidatePostingSelect from '@src/features/NewCandidatePostingSelect/NewCandidatePostingSelect'
import { ApplicationQuestion } from '@src/interfaces/applicationQuestions'
import { FeatureFlags } from '@src/store/auth/types'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

// we load a massive pdf-dist library there
const UploadCVComponent = React.lazy(() => import('../NewCandidate/UploadCV'))

const QuestionInputGroup = ({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) => (
  <InputGroup>
    <Text variant="primary">{title}</Text>
    {children}
  </InputGroup>
)

const General = () => {
  const user = useSelector(selectUser)
  const { values, submit } = useLapeContext<ReferCandidateInterface>()
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const statusPopup = useStatusPopup()

  const state = useLape<{
    selectedMainPosting: JobPostingLocationTypeInterface | null
    loading: boolean
  }>({
    selectedMainPosting: null,
    loading: false,
  })

  const fetchAdditionalQuestion = async () => {
    state.loading = true
    try {
      const resp = await getAdditionalQuestions()
      values.application_form_data = resp.data
    } finally {
      state.loading = false
    }
  }

  useEffect(() => {
    // We can't save file to LocalStorage that is why I remove it if the data comes from there.
    if (values.resume && !values.resume.name) {
      values.resume = undefined
    }

    if (!values.referred_by) {
      values.referred_by = {
        id: user.id,
        name: user.full_name,
      }
    }

    fetchAdditionalQuestion()
  }, [])

  const renderQuestion = (
    question: ApplicationQuestion,
    sectionIdx: number,
    questionIdx: number,
  ) => {
    switch (question.internal_data_type) {
      case 'option_application_form_question':
        return (
          <QuestionInputGroup
            title={`${question.title} ${question.optional ? ' (Optional)' : ''}`}
          >
            <LapeNewRadioButtons
              name={`application_form_data.sections.${sectionIdx}.questions.${questionIdx}.answer`}
              options={question.options.map(option => ({
                label: option.option,
                value: option,
              }))}
            />
          </QuestionInputGroup>
        )

      case 'dropdown_application_form_question':
        return (
          <QuestionInputGroup title={question.title}>
            <LapeRadioSelectInput
              name={`application_form_data.sections.${sectionIdx}.questions.${questionIdx}.answer`}
              inputProps={{
                'data-testid': `application_form_data.sections.${sectionIdx}.questions.${questionIdx}.answer`,
              }}
              options={question.options.map(option => ({
                label: option.option,
                value: option,
              }))}
              label="Select"
              required={!question.optional}
            />
          </QuestionInputGroup>
        )

      default:
        return null
    }
  }

  const onSubmit = () => {
    values.posting_id = state.selectedMainPosting?.id
      ? String(state.selectedMainPosting.id)
      : undefined

    return submit()
  }

  const handleClose = () => {
    statusPopup.hide()
    navigateReplace(pathToUrl(ROUTES.FORMS.EMPLOYEE.REFERRALS, { id: user.id }))
  }

  const showSuccess = () => {
    statusPopup.show(
      <StatusPopup variant="success-optional" onClose={handleClose}>
        <StatusPopup.Title>
          Thank you for submitting your referral form.
        </StatusPopup.Title>
        <StatusPopup.Description>
          Please check{' '}
          <Link href={REFERRAL_BONUSES} target="_blank" rel="noreferrer noopener">
            the policy
          </Link>{' '}
          to understand if the candidate is eligible for the bonus.
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button elevated onClick={handleClose}>
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return (
    <>
      <PageBody>
        <ActionWidget
          title="Before submitting this form please ensure:"
          text={
            <>
              <List
                variant="compact"
                ml="s-16"
                color="grey-tone-50"
                style={{ listStyleType: 'decimal' }}
              >
                <List.Item color="grey-tone-50">
                  You have consent from the candidate on the referral
                </List.Item>
                <List.Item color="grey-tone-50">You have a copy of their CV</List.Item>
                <List.Item color="grey-tone-50">
                  If you are acquainted, related or hold any other personal relationship
                  with the referred person, please be advised that it is obligatory for
                  you to{' '}
                  {isCommercial ? (
                    'report potential conflict of interest'
                  ) : (
                    <Link
                      href={CONFLICT_OF_INTERESTS}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      report potential conflict of interest
                    </Link>
                  )}{' '}
                  once the candidate is hired.
                </List.Item>
              </List>
              <br />
              Please be advised that
              <HideIfCommercial>
                , among other rules outlined in the{' '}
                <Link href={REFERRAL_BONUSES} target="_blank" rel="noopener noreferrer">
                  policy
                </Link>
                ,
              </HideIfCommercial>{' '}
              the referral will be eligible for the bonus only if:
              <List
                variant="compact"
                ml="s-16"
                color="grey-tone-50"
                style={{ listStyleType: 'decimal' }}
              >
                <List.Item color="grey-tone-50">
                  The candidate is not currently in the active recruitment pipeline
                </List.Item>
                <List.Item color="grey-tone-50">
                  At least 3 months have passed from the moment the candidate was archived
                  for any other recruitment activity
                </List.Item>
              </List>
            </>
          }
          mb="s-24"
        />
        <InputGroup>
          <React.Suspense fallback={<ItemSkeleton />}>
            <UploadCVComponent required name="resume.id" />
          </React.Suspense>
          <NewCandidatePostingSelect
            selectedMainPosting={state.selectedMainPosting}
            onChangeSelectedMainPosting={val => {
              state.selectedMainPosting = val
            }}
          />
        </InputGroup>
        <AutoStepper>
          <NewStepperTitle title="General information" />
          <InputGroup>
            <LapeNewInput name="full_name" label="Candidate full name" required />
            <LapeNewInput name="email" label="Email" required />
            <LapeNewInput name="phone" label="Mobile" required />
            <LapeNewInput name="current_company" label="Current company" />
            <LapeRadioSelectInput<OptionInterface>
              name="country"
              label="Current country"
              selector={selectorKeys.countries}
            />
            <LapeRadioSelectInput<OptionInterface>
              name="referred_by"
              label="Referred by"
              selector={selectorKeys.employee}
            />
          </InputGroup>

          <NewStepperTitle title="Work details" />
          <InputGroup>
            <LapeMultiInput
              title="Add additional link eg LinkedIn, Github, portfolio, website etc (optional)"
              name="links"
              valueFieldName={undefined}
            />
          </InputGroup>

          {state.loading ? (
            <MultipleDetailsSkeleton />
          ) : (
            values.application_form_data?.sections?.map((section, sectionIdx) => (
              <React.Fragment key={sectionIdx}>
                <NewStepperTitle title={section.title} />
                <Widget p="s-24">
                  <Grid gap={24}>
                    {section.questions.map((question, questionIdx) => (
                      <Box key={questionIdx}>
                        {renderQuestion(question, sectionIdx, questionIdx)}
                      </Box>
                    ))}
                  </Grid>
                </Widget>
              </React.Fragment>
            ))
          )}

          <NewStepperTitle title="Notes" />
          <InputGroup>
            <LapeNewTextArea
              name="notes"
              label="Enter your message for the recruiter here"
              rows={3}
            />
            <LapeSingleCheckbox
              name="hiring_panel_member"
              label={
                'I confirm that by submitting this referral I cannot take part in the hiring panel for the candidate'
              }
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup<ReferCandidateInterface>
          onAfterSubmit={showSuccess}
          useValidator
          noPopup
          onClick={onSubmit}
        />
      </PageActions>
    </>
  )
}

export default connect(General)
