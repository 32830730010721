import React, { useState } from 'react'
import { Action, Avatar, Banner, VStack } from '@revolut/ui-kit'
import { Lightbulb } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { ROUTES } from '@src/constants/routes'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { BooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { PaygroupTable } from '@src/apps/People/Payroll/PaygroupTable/PaygroupTable'
import { pathToUrl } from '@src/utils/router'
import Table from '@src/components/TableV2/Table'

const nameTableColumn: ColumnInterface<{ name: string }> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Region',
}

export const actionsTableColumn: ColumnInterface<{ name: string }> = {
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
}

export const PayrollSettings = () => {
  const [isCountryLevel, setIsCountryLevel] = useState(true)

  return (
    <VStack space="s-16">
      <SectionTitle
        title="Create and activate your payroll groups here"
        subtitle="Payroll groups allow you to organise your employee’s payroll by entity and location."
      />
      <BooleanRadioSwitch
        variant="horizontal"
        value={isCountryLevel}
        onChange={setIsCountryLevel}
        noLabel="Global"
        yesLabel="By country & entity"
        noDescription="My payroll definition are done on a global level"
        yesDescription="My payroll definitions are done by country"
      />
      {isCountryLevel && (
        <>
          <Banner mb="s-24">
            <Banner.Avatar>
              <Avatar useIcon={Lightbulb} />
            </Banner.Avatar>
            <Banner.Content>
              <Banner.Title>We suggested payroll groups just for you</Banner.Title>
              <Banner.Description>
                Based on your companies entities and locations we have added default
                paygroups groups for you. Adjust, edit or delete them to your needs.
              </Banner.Description>
            </Banner.Content>
          </Banner>
          <PaygroupTable />
        </>
      )}
      {!isCountryLevel && (
        <Table.Widget>
          <Table.Widget.Table>
            <AdjustableTable
              count={0}
              data={[{ name: 'Global' }]}
              name={TableNames.PayGroups}
              row={{
                cells: [
                  {
                    ...nameTableColumn,
                    width: 300,
                  },
                  {
                    ...actionsTableColumn,
                    width: 100,
                    insert: () => (
                      <Action
                        onClick={() =>
                          navigateTo(pathToUrl(ROUTES.FORMS.PAY_GROUP_BULK_EDIT))
                        }
                        useIcon="Pencil"
                      >
                        Edit
                      </Action>
                    ),
                  },
                ],
              }}
              useWindowScroll
            />
          </Table.Widget.Table>
        </Table.Widget>
      )}
    </VStack>
  )
}
