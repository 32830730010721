import React, { useMemo, useState } from 'react'
import { Box, Flex } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import {
  FetchDataQueryInterface,
  FilterByInterface,
  SORT_DIRECTION,
  SortByInterface,
  Stats,
} from '@src/interfaces/data'
import { requisitionsRequests } from '@src/api/requisitions'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { ROUTES } from '@src/constants/routes'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  requisitionBackfillColumn,
  requisitionQueuePosition,
} from '@src/constants/columns/requisition'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'
import { teamDepartmentColumn, teamNameColumn } from '@src/constants/columns/team'
import { lineManagerColumn } from '@src/constants/columns/employee'
import ReferCandidateButton from '@components/ReferCandidateButton/ReferCandidateButton'
import AddRequisitionButton from '@src/features/CommonRequisitionTable/AddRequisitionButton'
import { TableNames } from '@src/constants/table'
import { withFavourites } from '../FavouritesFilter/withFavourites'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { useIsNewTable, useTable } from '@components/TableV2/hooks'
import ShowConfidentialFilter, { getConfidentialFilter } from './ShowConfidentialFilter'
import { useGetRequisitionSettings } from '@src/api/settings'
import { TalentType } from '@src/interfaces/talent/talent'
import { AllowedExportMenu } from '@src/features/ExportMenu/AllowedExportMenu'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import Table from '@components/TableV2/Table'
import { getCommonRequisitionTableRow } from '@src/features/CommonRequisitionTable/commonRequisitionTableRow'
import { PermissionTypes } from '@src/store/auth/types'
import { StatNavigation } from '@components/StatNavigation/StatNavigation'
import { jobsConfig } from '@src/pages/Recruitment/RecruitmentSubTabs/common'
import { useGetJobsStats } from '@src/api/jobPosting'
import { positionNumberColumn } from '@src/constants/columns/ordering'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

export const requisitionSortBy = [
  {
    sortBy: 'pipeline_queue_position',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'seniority__level',
    direction: SORT_DIRECTION.ASC,
  },
  {
    sortBy: 'priority',
    direction: SORT_DIRECTION.DESC,
  },
]

type Type =
  | 'team'
  | 'department'
  | 'main'
  | 'role'
  | 'function'
  | 'employee'
  | 'jobPosting'

type Props = {
  filterBy?: FilterByInterface[]
  sortBy?: SortByInterface[]
  statsData?: FetchDataQueryInterface
  newItemInitialValues?: Partial<RequisitionInterface>
  type: Type
  enableFavourites?: boolean
  navigation?: React.ReactElement
  jobPostingId?: number
}

type UseRequisitionReturnType = {
  filterBy?: FilterByInterface[]
  sortBy?: SortByInterface[]
  statsData?: FetchDataQueryInterface
  isConfidential?: boolean
}

export const useRequisitionTable = ({
  filterBy = [],
  sortBy,
  statsData,
}: UseRequisitionReturnType) => {
  const table = useTable<RequisitionInterface, Stats>(
    {
      ...requisitionsRequests,
      getStats: fetchQuery =>
        requisitionsRequests.getStats!({
          ...(statsData ?? {}),
          ...fetchQuery,
        }),
    },
    filterBy,
    sortBy,
  )
  return table
}

const RequisitionTable = ({
  filterBy = [],
  sortBy,
  statsData,
  newItemInitialValues = {},
  type,
  enableFavourites = false,
  navigation,
  jobPostingId,
}: Props) => {
  const isNewTable = useIsNewTable()
  const [isConfidential, setIsConfidential] = useState(false)
  const initialFilterBy = useMemo(() => [...filterBy, getConfidentialFilter(false)], [])
  const table = useRequisitionTable({
    filterBy: initialFilterBy,
    sortBy,
    statsData: {
      ...(statsData ?? {}),
      filters: [...(statsData?.filters ?? []), getConfidentialFilter(isConfidential)],
    },
  })
  const { data: requisitionSettings } = useGetRequisitionSettings()
  const { FavouritesFilter } = useFavouritesFilter('requisition')
  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const isHiringColumnsEnabled = requisitionSettings?.enable_table_hiring_fields

  const hiddenCells: Partial<Record<keyof RequisitionInterface, boolean>> = {
    [requisitionQueuePosition.idPoint]: !isHiringColumnsEnabled,
    [teamDepartmentColumn.idPoint]: type !== 'function',
    [teamNameColumn.idPoint]: type === 'team',
    [lineManagerColumn.idPoint]: type === 'employee',
  }

  const tableSettings = useMemo(
    () =>
      isNewTable
        ? {
            hidden: [
              specialisationRoleNameColumn.title,
              requisitionQueuePosition.title,
              requisitionBackfillColumn.title,
              positionNumberColumn.title,
            ],
            visible: [],
          }
        : {
            hidden: [requisitionBackfillColumn.title],
            visible: [],
          },
    [isNewTable],
  )

  const row = useMemo(
    () =>
      getCommonRequisitionTableRow({ isNewTable, stats: table.stats, isConfidential }),
    [isNewTable, table.stats, isConfidential],
  )

  return (
    <Table.Widget>
      {!isNewTable &&
        (type === 'employee' || type === 'team' || type === 'department') && (
          <Box mx={isNewTable ? 's-16' : undefined}>{navigation}</Box>
        )}
      <Table.Widget.Info>
        {isNewTable && type === 'main' ? (
          <StatNavigation api={useGetJobsStats} config={jobsConfig} />
        ) : (
          <Flex mb={isNewTable ? 0 : 's-24'}>
            <Stat
              mr="s-16"
              label="Total Headcount"
              val={table?.stats?.requisition_total_headcount}
            />
            <Stat
              label="Remaining Headcount"
              val={table?.stats?.requisition_remaining_headcount}
            />
          </Flex>
        )}
      </Table.Widget.Info>

      <Table.Widget.Search>
        <Table.Search
          placeholder="Search by title, team, or specialisation"
          onFilter={table.onFilterChange}
        />
      </Table.Widget.Search>

      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={3}>
          <AddRequisitionButton
            jobPostingId={jobPostingId}
            newItemInitialValues={newItemInitialValues}
          />
          <ReferCandidateButton />
          {type === 'main' && (
            <SettingsButton
              path={ROUTES.SETTINGS.JOBS.LIST}
              canView={settingsConfig.jobs.canView}
            />
          )}
          <AllowedExportMenu
            fileName="Requisitions"
            request={requisitionsRequests.getExport}
            filterQuery={filterQuery}
            type={type as TalentType}
            permission={PermissionTypes.DownloadRequisitions}
          />
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by title, team, or specialisation"
          onFilter={table.onFilterChange}
          variant="compact"
        />
        {isNewTable && type === 'employee' && navigation}
        {enableFavourites ? <FavouritesFilter table={table} /> : null}
        <ShowConfidentialFilter
          disabled={table.loading}
          onClick={showConfidential => {
            setIsConfidential(showConfidential)
            table.onFilterChange(getConfidentialFilter(showConfidential))
          }}
        />
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<RequisitionInterface>
          name={TableNames.CommonRequisition}
          useWindowScroll
          dataType="Requisition"
          row={row}
          hiddenCells={hiddenCells}
          hideCount={!!isNewTable}
          tableSettings={tableSettings}
          {...table}
          emptyState={<EmptyTableRaw title="Requisitions will appear here" />}
          mainColumnIndex={1}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export const CommonRequisitionTable = withFavourites(RequisitionTable)
