import React, { Reducer, useEffect, useReducer, useState } from 'react'
import SideBar from '@src/components/SideBar/SideBar'
import { DeliverableCategory, DeliverableRatingSetting } from '@src/interfaces/settings'
import { notReachable } from '@src/utils/notReachable'
import {
  Widget,
  Text,
  VStack,
  Token,
  Input,
  Subheader,
  Button,
  Spacer,
} from '@revolut/ui-kit'
import { PerformanceRatingTitle } from '@src/constants/performance'
import Form from '@src/features/Form/Form'
import { PageActions } from '@src/components/Page/PageActions'
import {
  useUpdateDeliverableCategory,
  useUpdateDeliverableRating,
} from '@src/api/performanceSettings'
import { getMessageFromApiError } from '@src/store/notifications/actions'

type Action =
  | { type: 'update'; id: number; description: string }
  | { type: 'clear' }
  | { type: 'create'; ratingData: DeliverableRatingSetting }
  | { type: 'error'; id: number; error: string | null }

const reducer = (
  state: Record<number, DeliverableRatingSetting & { error?: string | null }>,
  action: Action,
) => {
  const { type } = action
  switch (type) {
    case 'clear':
      return {}
    case 'update':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          description: action.description,
          error: undefined,
        },
      }
    case 'create':
      return { ...state, [action.ratingData.id]: action.ratingData }
    case 'error':
      return { ...state, [action.id]: { ...state[action.id], error: action.error } }
    default:
      return notReachable(type)
  }
}

interface Props {
  openedCategory: DeliverableCategory | undefined
  onClose: (options?: { refetch?: boolean }) => void
}

export const DeliverablesSidebar = ({ openedCategory, onClose }: Props) => {
  const [categoryLabel, setCategoryLabel] = useState<string>()
  const [state, dispatch] = useReducer<
    Reducer<Record<number, DeliverableRatingSetting & { error?: string | null }>, Action>
  >(reducer, {})
  const { mutateAsync: updateDeliverableRating, isLoading: isRatingPending } =
    useUpdateDeliverableRating()
  const { mutateAsync: updateDeliverableCategory, isLoading: isCategoryPending } =
    useUpdateDeliverableCategory()
  useEffect(() => {
    dispatch({ type: 'clear' })
    openedCategory?.deliverables_ratings.forEach(rating => {
      dispatch({ type: 'create', ratingData: rating })
    })
    setCategoryLabel(openedCategory?.label)
  }, [openedCategory?.id])

  const onSubmit = async () => {
    if (!openedCategory) {
      return Promise.resolve()
    }
    return Promise.all([
      openedCategory.label !== categoryLabel
        ? updateDeliverableCategory([
            openedCategory.id,
            { label: categoryLabel || openedCategory.label }, // submit category update
          ])
        : () => {},
      ...openedCategory.deliverables_ratings.map(rating => {
        if (rating.description !== state[rating.id].description) {
          // submit rating update

          return updateDeliverableRating([
            rating.id,
            { description: state[rating.id].description },
          ]).catch(e => {
            dispatch({
              type: 'error',
              id: rating.id,
              error: getMessageFromApiError(e),
            })
            throw new Error(e)
          })
        }
        return () => {}
      }),
    ])
  }

  if (!openedCategory) {
    return null
  }
  return (
    <SideBar
      title="Edit deliverable"
      isOpen
      onClose={() => {
        dispatch({ type: 'clear' })
        onClose()
      }}
    >
      <Form>
        <VStack gap="s-16">
          <Input
            value={categoryLabel}
            label="Title"
            onChange={e => {
              setCategoryLabel(e.currentTarget.value)
            }}
          />
          <Subheader>Grade definitions</Subheader>
          {Object.values(state).map((rating, i) => (
            <Widget key={rating.id} p="s-16">
              <VStack pb="s-16">
                <Text variant="emphasis1">
                  {i + 1}. {PerformanceRatingTitle[rating.rating]}
                </Text>
                <Text variant="body2" color={Token.color.greyTone50}>
                  Description
                </Text>
              </VStack>
              <Input
                invalid={!!rating.error}
                errorMessage={rating.error}
                value={rating.description}
                placeholder="Insert description"
                onChange={e => {
                  dispatch({
                    type: 'update',
                    id: rating.id,
                    description: e.currentTarget.value,
                  })
                }}
              />
            </Widget>
          ))}
          <Spacer height={56} />
          <PageActions bottom={0}>
            <Button
              variant="secondary"
              onClick={e => {
                e.preventDefault()
                dispatch({ type: 'clear' })
                setCategoryLabel(openedCategory.label)
                onClose()
              }}
            >
              Cancel
            </Button>
            <Button
              pending={isRatingPending || isCategoryPending}
              onClick={e => {
                e.preventDefault()
                onSubmit().then(() => {
                  onClose({ refetch: true })
                })
              }}
            >
              Confirm
            </Button>
          </PageActions>
        </VStack>
      </Form>
    </SideBar>
  )
}
