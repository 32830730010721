import React, { useMemo, useState } from 'react'
import { useToggle } from '@revolut/ui-kit'

import { engagementAnswersTableRequests } from '@src/api/engagement'
import SideBar from '@src/components/SideBar/SideBar'
import Stat from '@src/components/Stat/Stat'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useTable } from '@src/components/TableV2/hooks'
import {
  engagementResultsV2AnsweredOnColumn,
  engagementResultsV2AnswerScoreColumn,
  engagementResultsV2AnswerTextColumn,
  engagementResultsV2CategoryColumn,
  engagementResultsV2QuestionTextColumn,
} from '@src/constants/columns/engagementResultsV2'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EngagementAnswerInterface } from '@src/interfaces/engagement'
import { AcknowledgementSidebar } from './AcknowledgementSidebar'
import { toIdAndName } from '@src/utils/toIdAndName'

const getRow = (
  onClickRow: (rowData: EngagementAnswerInterface) => void,
): RowInterface<EngagementAnswerInterface> => ({
  linkToForm: data => {
    onClickRow(data)
  },
  cells: [
    {
      ...engagementResultsV2QuestionTextColumn,
      width: 400,
    },
    {
      ...engagementResultsV2AnswerTextColumn,
      width: 400,
    },
    {
      ...engagementResultsV2CategoryColumn,
      width: 200,
    },
    {
      ...engagementResultsV2AnswerScoreColumn,
      width: 200,
    },
    {
      ...engagementResultsV2AnsweredOnColumn,
      width: 200,
    },
  ],
})

export const CommentsTab = () => {
  const [sidebarOpen, toggleSidebarOpen] = useToggle()
  const [selectedAnswer, setSelectedAnswer] = useState<EngagementAnswerInterface>()

  const table = useTable<EngagementAnswerInterface>(engagementAnswersTableRequests, [
    {
      columnName: 'answer_text__isnull',
      filters: [toIdAndName('false')],
      nonResettable: true,
    },
  ])

  const row = useMemo(
    () =>
      getRow(data => {
        setSelectedAnswer(data)
        toggleSidebarOpen.on()
      }),
    [],
  )

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <Stat label="Comments" val={table?.loading ? undefined : table?.count} />
        </Table.Widget.Info>
        <Table.Widget.Table>
          <AdjustableTable
            {...table}
            hideCount
            useWindowScroll
            dataType="Comment"
            name={TableNames.EngagementSurveysResultComments}
            emptyState={<EmptyTableRaw title="No comments found for this survey" />}
            row={row}
          />
        </Table.Widget.Table>
      </Table.Widget>
      {selectedAnswer && (
        <SideBar
          variant="medium"
          isOpen={sidebarOpen}
          onClose={toggleSidebarOpen.off}
          title={selectedAnswer?.question.question_text}
        >
          <AcknowledgementSidebar
            answerId={selectedAnswer.id}
            closeSidebar={toggleSidebarOpen.off}
          />
        </SideBar>
      )}
    </>
  )
}
