import React from 'react'
import { Box, Group, Subheader, Text, Token } from '@revolut/ui-kit'

import { SelfReviewsSwitch } from '@src/pages/Settings/Performance/ReviewSettings/SelfReviewsSwitch'
import { LineManagerReviewsSwitch } from '@src/pages/Settings/Performance/ReviewSettings/LineManagerReviewsSwitch'
import { FunctionalManagerReviewsSwitch } from '@src/pages/Settings/Performance/ReviewSettings/FunctionalManagerReviewsSwitch'
import { UpwardsReviewsSwitch } from '@src/pages/Settings/Performance/ReviewSettings/UpwardsReviewsSwitch'
import { PeerReviewsSwitch } from '@src/pages/Settings/Performance/ReviewSettings/PeerReviewsSwitch'

interface Props {
  hasFunctionalManagement: boolean
}

export const ReviewTypes = ({ hasFunctionalManagement }: Props) => {
  return (
    <>
      <Box>
        <Subheader>
          <Subheader.Title>Review types</Subheader.Title>
        </Subheader>
        <Text variant="caption" color={Token.color.greyTone50}>
          Based on your selection, we will create a separate Scorecard for each review
          type.
        </Text>
      </Box>
      <Group>
        <SelfReviewsSwitch />
        <LineManagerReviewsSwitch />
        <FunctionalManagerReviewsSwitch disabled={!hasFunctionalManagement} />
        <UpwardsReviewsSwitch />
        <PeerReviewsSwitch />
      </Group>
    </>
  )
}
