import React from 'react'
import { Group, Box } from '@revolut/ui-kit'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { PermissionTypes, FeatureFlags } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { APPROVAL_PROCESS_GROUPS_API } from '@src/api/settings'
import ShowIfCommercial from '@components/ShowIfCommercial/ShowIfCommercial'
import { ItemSwitch } from '@components/Inputs/ItemSwitch/ItemSwitch'

export const Function = () => {
  const queryClient = useQueryClient()

  const permissions = useSelector(selectPermissions)
  const featureFlegs = useSelector(selectFeatureFlags)

  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)
  const hideSpecialisationsEnabled = featureFlegs.includes(
    FeatureFlags.HideSpecialisationsEnabled,
  )
  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="Settings that apply to functions, roles and specialisations"
          />
          <Box mt="s-24">
            <Group>
              <ShowIfCommercial>
                {hideSpecialisationsEnabled && (
                  <ItemSwitch
                    title={'Allow specialisations for roles'}
                    name={'enable_hide_specialisations'}
                    description={'Enable to have an additional level under roles'}
                    checked={!values.enable_hide_specialisations}
                    onChange={e => {
                      values.enable_hide_specialisations = !e.target.checked
                    }}
                  />
                )}
              </ShowIfCommercial>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Allow Role and Specialisation owners to view salaries',
                  description:
                    'Allows roles and specialisation owners to view salaries in the talent section',
                }}
                name="enable_role_specialisation_owners_view_salaries"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable multiple levels per seniority',
                  description: 'Allows to define custom levels per seniority',
                }}
                name="enable_multiple_levels_per_seniority"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable cost control assignment',
                  description:
                    'Allows to define a user as the assigned cost control when creating a function',
                }}
                name="enable_cost_control_assignment"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable executive functions',
                  description:
                    'Allows to set functions as ‘executive’ type. Only executive functions can use director+ seniorities.',
                }}
                name="enable_executive_functions"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable data export for Talent and Requisition sections',
                  description:
                    'Allows to download information for functions, roles and specialisations as CSV or XLSX files',
                }}
                name="enable_download_actions_for_function_data"
                disabled={disableEdit}
              />
            </Group>
          </Box>

          <NewStepperTitle title="Roles" subtitle="Settings that apply to all roles" />
          <Box mt="s-24">
            <ApprovalSteps
              title="Enable Role approval"
              description="Whenever a new role is created or an existing one is changed it asks for approval"
              switchField="enable_roles_approvals"
              approvalProcess="roles_approval_steps"
              entity="role"
              disabled={disableEdit}
              formRef={values}
            />
          </Box>

          <NewStepperTitle
            title="Specialisation"
            subtitle="Settings that apply to all specialisations"
          />
          <Box mt="s-24">
            <ApprovalSteps
              title="Enable Specialisation approval"
              description="Whenever a new specialisation is created or an existing one is changed it asks for approval"
              switchField="enable_specialisations_approvals"
              approvalProcess="specialisations_approval_steps"
              entity="specialisation"
              disabled={disableEdit}
              formRef={values}
            />
          </Box>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.ORGANISATION_SETTINGS)
            queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
