import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { ActionButton, MoreBar, TableWidget } from '@revolut/ui-kit'

import { InternalLink } from '@components/InternalLink/InternalLink'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { getEmployeePolicyAssignments } from '@src/api/timeOff'
import {
  timeOffPolicyAssignmentAssignedByColumn,
  timeOffPolicyAssignmentAssignedOnColumn,
  timeOffPolicyAssignmentCategoryColumn,
  timeOffPolicyAssignmentEndDateColumn,
  timeOffPolicyAssignmentNameColumn,
  timeOffPolicyAssignmentStartDateColumn,
  timeOffPolicyAssignmentStatusColumn,
} from '@src/constants/columns/timeOff'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import { EmployeeTimeOffPolicyAssignmentInterface } from '@src/interfaces/timeOff'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { EmptyTableState, PolicyPortalButton, UserGuidesButton } from './common'

const ROW: RowInterface<EmployeeTimeOffPolicyAssignmentInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.PREVIEW, {
        employeeId: data.employee.id,
        id: data.id,
      }),
    ),
  cells: [
    {
      ...timeOffPolicyAssignmentCategoryColumn,
      width: 100,
    },
    {
      ...timeOffPolicyAssignmentNameColumn,
      width: 300,
    },
    {
      ...timeOffPolicyAssignmentStartDateColumn,
      width: 100,
    },
    {
      ...timeOffPolicyAssignmentEndDateColumn,
      width: 100,
    },
    {
      ...timeOffPolicyAssignmentAssignedOnColumn,
      width: 200,
    },
    {
      ...timeOffPolicyAssignmentAssignedByColumn,
      width: 200,
    },
    {
      ...timeOffPolicyAssignmentStatusColumn,
      width: 100,
    },
  ],
}

const showAllFilterOff = {
  columnName: 'status',
  filters: [
    { id: Statuses.active, name: Statuses.active },
    { id: Statuses.pending, name: Statuses.pending },
  ],
}

const showAllFilterOn = {
  columnName: 'status',
  filters: [],
}

interface Props {
  data: EmployeeInterface
}

export const EmployeeTimeOffPolicies = ({ data }: Props) => {
  const table = useTable({ getItems: getEmployeePolicyAssignments(data.id) }, [
    showAllFilterOff,
  ])

  const [showAllItems, setShowAllItems] = useState(false)

  const canAdd = data.field_options?.permissions?.includes(
    PermissionTypes.AddTimeOffPolicyAssignments,
  )

  const onShowAllChange = () => {
    const nextState = !showAllItems
    table.onFilterChange(nextState ? showAllFilterOn : showAllFilterOff)
    setShowAllItems(nextState)
  }

  const emptyState = table.data.length === 0

  const linkToAssignPolicy = pathToUrl(
    ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.GENERAL,
    {
      employeeId: data.id,
    },
  )

  return (
    <TableWidget>
      {emptyState ? null : (
        <TableWidget.Info>
          <Stat label="Policies" val={table?.loading ? undefined : table?.count} />
        </TableWidget.Info>
      )}
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && !emptyState && (
            <MoreBar.Action use={InternalLink} to={linkToAssignPolicy} useIcon="Plus">
              Assign policy
            </MoreBar.Action>
          )}
          <MoreBar.Action
            useIcon={showAllItems ? 'SwitchOn' : 'SwitchOff'}
            onClick={onShowAllChange}
          >
            Show all
          </MoreBar.Action>
          <PolicyPortalButton />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.EmployeeTimeOffPolicies}
          useWindowScroll
          row={ROW}
          {...table}
          emptyState={
            <EmptyTableState
              usePlaceholder
              title="No time off policies assigned yet"
              description={
                <>
                  You must ensure that the applicable time off policies have been assigned
                  to this employee before they can request time off. If you need help
                  enabling automatic policy assignment, or if something doesn’t look
                  right, please consult the time off user guides.
                </>
              }
              actions={
                <>
                  {canAdd && (
                    <ActionButton use={Link} to={linkToAssignPolicy} useIcon="Plus">
                      Assign policy
                    </ActionButton>
                  )}
                  <UserGuidesButton />
                </>
              }
            />
          }
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
