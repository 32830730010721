import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/TableV2/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { copyToClipboard, Flex } from '@revolut/ui-kit'
import { getEmailTemplatesPlaceholders } from '@src/api/emailTemplates'
import { EmailPlaceholderInterface } from '@src/interfaces/emailTemplates'
import {
  emailPlaceholderNameColumn,
  emailPlaceholderTypeColumn,
} from '@src/constants/columns/emailPlaceholders'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const Row: RowInterface<EmailPlaceholderInterface> = {
  cells: [
    {
      ...emailPlaceholderNameColumn,
      width: 100,
    },
    {
      ...emailPlaceholderTypeColumn,
      width: 100,
    },
  ],
}

const PlaceholdersTable = () => {
  const table = useTable<EmailPlaceholderInterface>({
    getItems: getEmailTemplatesPlaceholders,
  })

  const onRowClick = async (row: EmailPlaceholderInterface) => {
    await copyToClipboard(row.name)

    pushNotification({
      value: 'Link copied to clipboard',
      duration: SUCCESS_DEFAULT_DURATION,
      type: NotificationTypes.success,
    })
  }

  return (
    <Flex flexDirection="column" width="100%">
      <AdjustableTable<EmailPlaceholderInterface>
        name={TableNames.EmailPlaceholders}
        useWindowScroll
        row={Row}
        {...table}
        onRowClick={onRowClick}
        emptyState={<EmptyTableRaw title="Placeholders will appear here." />}
        hideCount
      />
    </Flex>
  )
}

export default PlaceholdersTable
