import { useEffect, useState } from 'react'

import { useEngagementSurveyRounds } from '@src/api/engagement'
import { formatDate, formatPeriod } from '@src/utils/format'
import { IdAndName } from '@src/interfaces'
import { TimelineFilterMode } from '../common'
import { toIdAndName } from '@src/utils/toIdAndName'

const DATE_FORMAT = 'yyyy-MM-dd'

type DatesRange = {
  dateFrom: string
  dateTo: string
}

export type RoundOption = IdAndName & DatesRange

export interface UseTimelineFilterReturnType extends DatesRange {
  calendar: {
    value: IdAndName<string>[]
    setValue: (newDates: IdAndName<string>[]) => void
  }
  round: {
    value: RoundOption | undefined
    setValue: (newRound: RoundOption) => void
    options: RoundOption[]
    isLoadingOptions: boolean
  }
  mode: {
    value: TimelineFilterMode
    setValue: (newMode: TimelineFilterMode) => void
  }
  clearAll: VoidFunction
}

export const useTimelineFilter = (
  surveyId: number | undefined,
): UseTimelineFilterReturnType => {
  const [filterMode, setFilterMode] = useState<TimelineFilterMode>('rounds')
  const [selectedRound, setSelectedRound] = useState<RoundOption>()
  const [selectedDatesRange, setSelectedDatesRange] = useState<IdAndName<string>[]>([])

  const { data: roundsOptionsData, isLoading: isLoadingRoundsOptions } =
    useEngagementSurveyRounds(surveyId)

  const roundsOptions =
    roundsOptionsData?.results.map(round => {
      const datesRange =
        round.sent_on && round.closing_date
          ? `${formatPeriod(round.sent_on, round.closing_date)}`
          : undefined

      return {
        id: round.id,
        name: datesRange || `Round ${round.id}`,
        dateFrom: round.sent_on || '',
        dateTo: round.closing_date || '',
      }
    }) || []

  useEffect(() => {
    if (roundsOptions?.[0]) {
      setSelectedRound(roundsOptions?.[0])
    }
  }, [roundsOptionsData])

  const calendarDateFrom = selectedDatesRange[0]
    ? formatDate(selectedDatesRange[0].id, DATE_FORMAT)
    : ''
  const calendarDateTo = selectedDatesRange[1]
    ? formatDate(selectedDatesRange[1].id, DATE_FORMAT)
    : ''

  const roundDateFrom = selectedRound?.dateFrom
    ? formatDate(selectedRound.dateFrom, DATE_FORMAT)
    : ''
  const roundDateTo = selectedRound?.dateTo
    ? formatDate(selectedRound.dateTo, DATE_FORMAT)
    : ''

  return {
    dateFrom: filterMode === 'calendar' ? calendarDateFrom : roundDateFrom,
    dateTo: filterMode === 'calendar' ? calendarDateTo : roundDateTo,
    calendar: {
      value: selectedDatesRange,
      setValue: setSelectedDatesRange,
    },
    round: {
      value: selectedRound,
      options: roundsOptions,
      isLoadingOptions: isLoadingRoundsOptions,
      setValue: newValue => {
        setSelectedRound(newValue)
        if (newValue.dateFrom && newValue.dateTo) {
          setSelectedDatesRange([
            toIdAndName(newValue.dateFrom),
            toIdAndName(newValue.dateTo),
          ])
        }
      },
    },
    mode: {
      value: filterMode,
      setValue: setFilterMode,
    },
    clearAll: () => {
      setSelectedDatesRange([])
      setSelectedRound(undefined)
    },
  }
}
