import {
  RowInterface,
  FilterByInterface,
  SORT_DIRECTION,
  FilterOption,
} from '@src/interfaces/data'
import {
  IssueInterface,
  IssuesCounts,
  IssuesCategoryCounts,
  IssueSeverity,
  IssueType,
  IssueTypes,
} from '@src/interfaces/issues'
import {
  issueLinkColumn,
  issueSummaryColumn,
  issueAssigneeColumn,
  issueOpenDateColumn,
  issueDueDateColumn,
  issueSeverityColumn,
  issueDaysLeftColumn,
  issueTeamColumn,
  issueTypeColumn,
  issueStatusColumn,
  issueDepartmentColumn,
  issueAverageCountColumn,
  issueInitialAverageCountColumn,
} from '@src/constants/columns/issues'
import { Token, Flex, FilterButton, chain, MoreBar } from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import React, { useState, PropsWithChildren, useMemo, useEffect } from 'react'
import { useTableReturnType, useIsNewTable } from '@components/TableV2/hooks'
import Stat from '@components/Stat/Stat'
import { AuditStatuses } from '@src/interfaces/audit'
import Table from '@src/components/TableV2/Table'
import { StatFilters } from '@components/StatFilters/StatFilters'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { toIdAndName } from '@src/utils/toIdAndName'
import { matchPath, useLocation } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { CXLABS_BASE_URL } from '@src/constants/externalLinks'

interface IssuesTableProps {
  table: useTableReturnType<IssueInterface>
  name: TableNames
  counts?: IssuesCounts
  issuesCategoryCounts?: IssuesCategoryCounts
  showDepartments?: boolean
  showTeams?: boolean
  showIssueType?: boolean
  showIssueStatus?: boolean
  showFiltersAsStats?: boolean
  departmentId?: number
  teamId?: number
}

const rowLegacy: RowInterface<IssueInterface> = {
  highlight: data => {
    if (data.severity?.id === 'blocker' || data.severity?.id === 'critical') {
      return Token.color.inputError
    }
    return ''
  },
  cells: [
    {
      ...issueLinkColumn,
      width: 200,
    },
    {
      ...issueTypeColumn,
      width: 200,
    },
    {
      ...issueSummaryColumn,
      width: 400,
    },
    {
      ...issueTeamColumn,
      width: 200,
    },
    {
      ...issueAssigneeColumn,
      width: 200,
    },
    {
      ...issueOpenDateColumn,
      width: 200,
    },
    {
      ...issueDueDateColumn,
      width: 200,
    },
    {
      ...issueSeverityColumn,
      width: 180,
    },
    {
      ...issueInitialAverageCountColumn,
      width: 80,
    },
    {
      ...issueAverageCountColumn,
      width: 80,
    },

    {
      ...issueDaysLeftColumn,
      width: 200,
    },
    {
      ...issueStatusColumn,
      width: 200,
    },
  ],
}

const row: RowInterface<IssueInterface> = {
  cells: [
    {
      ...issueLinkColumn,
      width: 110,
    },
    {
      ...issueSummaryColumn,
      width: 300,
    },
    {
      ...issueDepartmentColumn,
      width: 180,
    },
    {
      ...issueTypeColumn,
      width: 90,
    },
    {
      ...issueTeamColumn,
      width: 210,
    },
    {
      ...issueAssigneeColumn,
      width: 150,
    },
    {
      ...issueSeverityColumn,
      width: 90,
    },

    {
      ...issueOpenDateColumn,
      width: 100,
    },
    {
      ...issueDueDateColumn,
      width: 100,
    },
    {
      ...issueInitialAverageCountColumn,
      width: 100,
    },
    {
      ...issueAverageCountColumn,
      width: 130,
    },

    {
      ...issueDaysLeftColumn,
      title: 'Days left',
      colors: () => Token.color.greyTone50,
      width: 80,
    },
    {
      ...issueStatusColumn,
      width: 100,
    },
  ],
}

const countIssues = (item?: Record<IssueSeverity, number>) => {
  if (!item) {
    return 0
  }
  const values = Object.values(item)

  return values.reduce((accumulator, value) => {
    return accumulator + value
  }, 0)
}

const issuesMap: Record<IssueType, string> = {
  user_issue: 'Findings',
  technical_bug: 'Technical Bugs',
  user: 'User Problems',
  product_audit: 'Product Audits',
}

const TypeFilterButton = ({
  type,
  issuesCategoryCounts,
  table,
  children,
}: PropsWithChildren<{
  type: IssueType
  table: useTableReturnType<IssueInterface>
  issuesCategoryCounts?: IssuesCategoryCounts
}>) => {
  const isActiveFilter = !!table.filterBy
    .find(f => f.columnName === 'category')
    ?.filters.find(f => f.id === type)

  const onToggleTypeFilter = () => {
    table.onFilterChange({
      filters: !isActiveFilter ? [{ name: type, id: type }] : [],
      columnName: 'category',
      nonResettable: true,
    })
  }

  return (
    <FilterButton onClick={onToggleTypeFilter} active={isActiveFilter}>
      {chain(
        children,
        issuesCategoryCounts ? countIssues(issuesCategoryCounts[type]) : 0,
      )}
    </FilterButton>
  )
}

const IssuesStatFilters = ({
  issuesCategoryCounts,
  table,
}: {
  issuesCategoryCounts?: IssuesCategoryCounts
  table: useTableReturnType<IssueInterface>
}) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const astraUserIssuesEnabled = featureFlags.includes(
    FeatureFlags.AstraUserIssuesEnabled,
  )

  const selectedFilter = table.filterBy.find(
    ({ columnName }) => columnName === 'category',
  )?.filters?.[0]?.id

  const onToggleTypeFilter = (type: string) => {
    table.onFilterChange({
      filters: type && type !== 'total' ? [{ name: type, id: type }] : [],
      columnName: 'category',
      nonResettable: true,
    })
  }

  const total = useMemo(
    () =>
      issuesCategoryCounts
        ? IssueTypes.reduce((acc, val) => acc + countIssues(issuesCategoryCounts[val]), 0)
        : 0,
    [issuesCategoryCounts],
  )

  const filters = useMemo(
    () => [
      {
        title: 'Total',
        id: 'total',
        value: total,
      },
      // remove filter when BE is updated REVCOR-3293
      ...IssueTypes.filter(item => item !== 'user' || astraUserIssuesEnabled).map(
        issueType => {
          return {
            id: issueType,
            title: issuesMap[issueType],
            value: issuesCategoryCounts
              ? countIssues(issuesCategoryCounts[issueType])
              : 0,
          }
        },
      ),
    ],
    [total, issuesCategoryCounts],
  )

  return (
    <StatFilters
      selectedFilter={selectedFilter ? String(selectedFilter) : 'total'}
      filters={filters}
      onClick={onToggleTypeFilter}
    />
  )
}

export const IssuesTable = ({
  table,
  name,
  counts,
  issuesCategoryCounts,
  showDepartments = true,
  showTeams = true,
  showIssueType = true,
  showIssueStatus = false,
  showFiltersAsStats = false,
  departmentId,
  teamId,
}: IssuesTableProps) => {
  const isNewTable = useIsNewTable()
  const [showHistory, setShowHistory] = useState(false)
  const featureFlags = useSelector(selectFeatureFlags)
  const astraUserIssuesEnabled = featureFlags.includes(
    FeatureFlags.AstraUserIssuesEnabled,
  )
  const defaultFilters: FilterOption[] = [
    toIdAndName(AuditStatuses.PENDING_FILING),
    toIdAndName(AuditStatuses.QUALITY_REVIEW),
    toIdAndName(AuditStatuses.IN_PROGRESS),
    toIdAndName(AuditStatuses.TO_DO),
  ]

  const getFilter = (enabled: boolean): FilterByInterface => {
    const filters = [
      ...defaultFilters,
      enabled ? toIdAndName(AuditStatuses.DONE) : null,
      astraUserIssuesEnabled ? toIdAndName(AuditStatuses.NOT_APPLICABLE) : null,
    ].filter(Boolean)

    return {
      filters,
      columnName: 'status',
      nonResettable: true,
    }
  }
  const onShowHistoricData = () => {
    setShowHistory(prev => {
      const newShowHistory = !prev
      table.onFilterChange(getFilter(newShowHistory))
      return newShowHistory
    })
  }

  const currentCategory = table.filterBy.find(f => f.columnName === 'category')
  const showsUserIssuesTab = currentCategory?.filters.some(f => f.id === 'user')

  const location = useLocation()
  const routeMappings = [
    {
      path: ROUTES.FORMS.TEAM.ISSUES.ANY,
      url: `${CXLABS_BASE_URL}/${departmentId}/${teamId}?timeFrame=MONTH&source=chats`,
    },
    {
      path: ROUTES.FORMS.DEPARTMENT.ISSUES.ANY,
      url: `${CXLABS_BASE_URL}/${departmentId}?timeFrame=MONTH&source=chats`,
    },
    {
      path: ROUTES.ORGANISATION.COMPANY.ISSUES,
      url: CXLABS_BASE_URL,
    },
  ]
  const getButtonLink = () => {
    const match = routeMappings.find(({ path }) =>
      matchPath(location.pathname, { path, exact: true }),
    )
    return match?.url
  }

  useEffect(() => {
    if (astraUserIssuesEnabled) {
      if (showsUserIssuesTab) {
        table.onFilterChange([
          {
            filters: [
              ...defaultFilters,
              toIdAndName(AuditStatuses.NOT_APPLICABLE),
              toIdAndName(AuditStatuses.DONE),
            ],
            columnName: 'status',
            nonResettable: true,
          },
        ])
        table.onSortChange({
          sortBy: 'average_count',
          direction: SORT_DIRECTION.ASC,
        })
      } else {
        table.onFilterChange([
          {
            filters: defaultFilters,
            columnName: 'status',
            nonResettable: true,
          },
        ])
      }
    }
  }, [showsUserIssuesTab])
  return (
    <>
      {!isNewTable && counts ? (
        <Table.Widget.Info>
          <Stat label="Total" val={table.count} />
          <Stat label="Blocker" color={Token.color.danger} val={counts.blocker} />
          <Stat label="Critical" color={Token.color.danger} val={counts.critical} />
          <Stat label="Major" color={Token.color.warning} val={counts.major} />
          <Stat label="Medium" color={Token.color.yellow} val={counts.medium} />
          <Stat label="Minor" color={Token.color.greyTone50} val={counts.minor} />
        </Table.Widget.Info>
      ) : null}

      {!isNewTable ? (
        <Table.Widget.Search>
          <Flex justifyContent="flex-end">
            <FilterButton onClick={onShowHistoricData} active={showHistory}>
              Show historic data
            </FilterButton>
          </Flex>
        </Table.Widget.Search>
      ) : null}
      {isNewTable && !showFiltersAsStats ? (
        <Table.Widget.Filters>
          {IssueTypes.map(issueType => (
            <TypeFilterButton
              type={issueType}
              table={table}
              issuesCategoryCounts={issuesCategoryCounts}
              key={issueType}
            >
              {issuesMap[issueType]}
            </TypeFilterButton>
          ))}
        </Table.Widget.Filters>
      ) : null}
      {isNewTable && showFiltersAsStats ? (
        <Table.Widget.Filters>
          <IssuesStatFilters table={table} issuesCategoryCounts={issuesCategoryCounts} />
        </Table.Widget.Filters>
      ) : null}
      <Table.Widget.Actions>
        {astraUserIssuesEnabled && showsUserIssuesTab && (
          <Table.Widget.MoreBar>
            <Table.ColumnsSettingsButton />
            {!!table.data.length && (
              <MoreBar.Action
                target="_blank"
                rel="noreferrer noopener"
                href={getButtonLink()}
                use="a"
                useIcon="LinkExternalSimple"
              >
                View in CX Labs
              </MoreBar.Action>
            )}
          </Table.Widget.MoreBar>
        )}
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<IssueInterface>
          dataType="Issue"
          name={name}
          row={isNewTable ? row : rowLegacy}
          useWindowScroll
          hiddenCells={{
            [issueTeamColumn.idPoint]: !showTeams,
            [issueTypeColumn.idPoint]: !isNewTable && !showIssueType,
            [issueStatusColumn.idPoint]: !isNewTable && !showIssueStatus,
            [issueDepartmentColumn.idPoint]: !showDepartments,
            [issueInitialAverageCountColumn.idPoint]:
              !showsUserIssuesTab || !astraUserIssuesEnabled,
            [issueAverageCountColumn.idPoint]:
              !showsUserIssuesTab || !astraUserIssuesEnabled,
          }}
          {...table}
          hideCount={isNewTable}
          tableSettings={
            isNewTable
              ? {
                  visible: [],
                  hidden: [issueOpenDateColumn.title, issueDueDateColumn.title],
                }
              : undefined
          }
        />
      </Table.Widget.Table>
    </>
  )
}
