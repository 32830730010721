import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { GrowthPlanApprovalSettings } from '@src/pages/Settings/GrowthPlans/Approvals/ApprovalSettings'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { performanceSettings } from '@src/api/performanceSettings'
import { PermissionTypes } from '@src/store/auth/types'
import { PageBody } from '@components/Page/PageBody'
import { SaveApprovalSettingsButton } from '@src/pages/Settings/common/SaveApprovalSettingsButton'
import { PageActions } from '@components/Page/PageActions'

export const GrowthPlanSettingsPage = () => {
  const routes = [
    {
      title: 'General',
      path: ROUTES.SETTINGS.GROWTH_PLANS,
      url: ROUTES.SETTINGS.GROWTH_PLANS,
      canView: [PermissionTypes.PerformanceTeam],
      component: GrowthPlanSettingsPageContent,
    },
  ]

  return (
    <SettingsForm
      routes={routes}
      api={performanceSettings}
      title="Growth plan settings"
    />
  )
}

const GrowthPlanSettingsPageContent = () => {
  return (
    <>
      <PageBody>
        <GrowthPlanApprovalSettings />
      </PageBody>
      <PageActions>
        <SaveApprovalSettingsButton />
      </PageActions>
    </>
  )
}
