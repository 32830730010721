import React from 'react'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { ActionButton, Avatar, Box, chain, Item, Widget } from '@revolut/ui-kit'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { EligibleGroupList } from '@src/features/ReviewCycle/PerformanceReviewCycle/EligibleEmployees/EligibleGroupList'
import { useNavigation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useNavigation'

interface Props {
  cycle: ReviewCyclesInterface
  count: number
  groups: EligibleGroupInterface[]
  isLoading: boolean
}

export const EligibleEmployees = ({ cycle, count, groups, isLoading }: Props) => {
  const permissions = useSelector(selectPermissions)
  const { toEligibleEmployeesList, toEditEligibleEmployees } = useNavigation()

  const canViewEligibleEmployees = permissions.includes(
    PermissionTypes.ViewReviewsWhitelist,
  )
  const title = chain('Eligible employees', count)

  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="People" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description>
            Total number of employees who are eligible to participate in this cycle
          </Item.Description>
          {canViewEligibleEmployees && (
            <Item.Actions>
              <ActionButton
                useIcon="16/ListBullet"
                onClick={() => toEligibleEmployeesList(cycle)()}
              >
                All employees
              </ActionButton>
            </Item.Actions>
          )}
        </Item.Content>
        <Item.Side>
          <Item.Value>
            <ActionButton onClick={() => toEditEligibleEmployees(cycle)()}>
              Edit
            </ActionButton>
          </Item.Value>
        </Item.Side>
      </Item>
      <Box px="s-16" pb="s-16">
        <EligibleGroupList cycle={cycle} groups={groups} isLoading={isLoading} />
      </Box>
    </Widget>
  )
}
