import { Button, Header, Popup } from '@revolut/ui-kit'
import React, { useState } from 'react'

import { navigateTo } from '@src/actions/RouterActions'
import { setEmployeeContractEndDate } from '@src/api/employees'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import { ROUTES } from '@src/constants/routes'
import { useGetTerminationDisabledDays } from '@src/features/Contracts/useGetTerminationDisabledDays'
import { getErrorMessageFromError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

type Props = {
  open: boolean
  setOpen: (isOpen: boolean) => void
  id: string
  employeeId: string
}

export const Terminate = ({ open, setOpen, id, employeeId }: Props) => {
  const [terminationDate, setTerminationDate] = useState<Date | null>(null)

  const showStatusPopup = useShowStatusPopup()

  const onTerminate = async () => {
    if (!terminationDate) {
      return
    }
    try {
      await setEmployeeContractEndDate({
        id,
        employeeId,
        endDate: terminationDate,
      })
      setOpen(false)

      showStatusPopup({
        status: 'success',
        title: 'Contract was scheduled for termination',
        onClose: async () => {
          navigateTo(
            `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
              id: employeeId,
            })}#contracts`,
          )
        },
      })
    } catch (e) {
      showStatusPopup({
        status: 'error',
        title: 'Could not submit termination',
        description: getErrorMessageFromError(e),
      })
    }
  }

  const disabledDays = useGetTerminationDisabledDays({ employeeId })

  return (
    <>
      <Popup open={open} onClose={() => setOpen(false)} variant="bottom-sheet">
        <Header variant="item">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Terminate Contract</Header.Title>
        </Header>
        <DatePickerInput
          disabledDays={disabledDays}
          value={terminationDate}
          onChange={setTerminationDate}
          label="End date"
        />
        <Popup.Actions>
          <Button variant="negative" onClick={onTerminate} disabled={!terminationDate}>
            Terminate
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
