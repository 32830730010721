import React from 'react'
import {
  Cell,
  Checkbox,
  InputGroup,
  Slider,
  Spacer,
  Subheader,
  Text,
} from '@revolut/ui-kit'
import { UnitOfMeasure } from '../SidebarForms/components/UnitOfMeasure'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiInterface } from '@src/interfaces/kpis'
import { ProgressDirection } from '../SidebarForms/components/ProgressDirection'
import { InitTargetValues } from '../SidebarForms/components/InitTargetValues'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import MultiInput from '@src/components/Inputs/MultiInput/MultiInput'
import { FormType } from './types'

interface Props {
  variant: FormType
}

export const ManualForm = ({ variant }: Props) => {
  const { values } = useLapeContext<KpiInterface>()

  const onChangeMultiInput = (input: string[]) => {
    // need to set init and target values for progress calculation
    values.targets[0].initial_value = 0
    values.targets[0].target = input.length

    const todo =
      input.map(item => ({
        name: item,
      })) || []
    values.todo_items = todo
  }

  return (
    <InputGroup>
      <InputGroup variant="horizontal">
        <LapeRadioSelectInput
          name="means_of_measure"
          label="Means of Measure"
          disabled={variant === 'side-edit'}
          selector={selectorKeys.kpi_means_of_measure}
        />
        <UnitOfMeasure variant={variant} />
      </InputGroup>
      {values.means_of_measure?.id === 'value' && (
        <>
          <ProgressDirection />
          <InputGroup variant="horizontal">
            <InitTargetValues />
          </InputGroup>
          {variant === 'side-edit' && (
            <>
              {/* @ts-expect-error py prop actually does work */}
              <Subheader py={0}>Metric progression</Subheader>
              <LapeNewInput
                type="money"
                name="current_progress"
                label="Current value"
                required
              />
              <Slider
                min={values.targets[0].initial_value || 0}
                max={values.targets[0].target || 0}
                value={values.current_progress}
                onChange={(value: number) => {
                  values.current_progress = value
                }}
              />
            </>
          )}
        </>
      )}
      {values.means_of_measure?.id === 'todo' && (
        <>
          <Text variant="emphasis1">To-do's</Text>
          {variant === 'side-edit' && (
            <InputGroup>
              {values.todo_items?.map(item => (
                <Cell use="label" key={item.id || item.name}>
                  <Checkbox
                    checked={item.is_completed}
                    onChange={async event => {
                      event.preventDefault()
                      // at that point we're sure that todo_items exits and there are ids
                      values.todo_items!.find(({ id }) => id === item.id)!.is_completed =
                        event.target.checked
                    }}
                  >
                    <Text variant="body1">{item.name}</Text>
                  </Checkbox>
                </Cell>
              ))}
            </InputGroup>
          )}
          {variant === 'new' && (
            <>
              <MultiInput
                label="To-do"
                value={values.todo_items?.map(({ name }) => name)}
                onChange={onChangeMultiInput}
                splitOnNewLine
                data-name="todo_items"
              />
            </>
          )}
        </>
      )}

      {variant === 'side-edit' && (
        <HideIfCommercial>
          <Spacer height="s-2" />
          <LapeNewInput label="Proof URL" name="metabase_url" hideOptional />
        </HideIfCommercial>
      )}
    </InputGroup>
  )
}
