import React from 'react'
import {
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Cell,
  Tag,
  Token,
  opacity,
} from '@revolut/ui-kit'
import { LocationPin, People, RepairTool } from '@revolut/icons'

import { getStatusColor } from '@components/CommonSC/General'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { FormTitleAvatar } from '@src/pages/EmployeeProfile/Preview/components/FormTitleAvatar'
import { EmployeeTimeOffRequestInterface } from '@src/interfaces/timeOff'
import { SyncedTag } from '@src/pages/EmployeeProfile/Preview/components/SyncedTag'

type Props = {
  data: EmployeeInterface
  actions: React.ReactNode
  refreshData: () => void
  timeOff?: EmployeeTimeOffRequestInterface
}
export const FormTitle = ({ data, actions, refreshData, timeOff }: Props) => {
  const isExternal = data?.employee_type === 'external'

  const status = data?.status?.id ? (
    <Tag
      variant="outlined"
      color={getStatusColor(data.status.id) || Token.color.greyTone50}
    >
      {data.status.name}
    </Tag>
  ) : null

  const synced =
    data?.field_options?.synchronised && data?.field_options?.synchronised.length > 0 ? (
      <SyncedTag />
    ) : null

  const companyRelation = isExternal ? (
    <Tag variant="outlined" color={Token.color.greyTone50}>
      External
    </Tag>
  ) : null

  const specialisation = data.specialisation?.id ? (
    <HStack align="center">
      <RepairTool color={Token.color.greyTone50} size={15} />
      <Text
        use={InternalLink}
        to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
          id: data.specialisation.id,
        })}
        target="_blank"
        color={opacity(Token.colorChannel.foreground, 0.8)}
        variant="caption"
        textDecoration="none"
      >
        {isExternal
          ? data.specialisation.name
          : (data as EmployeeInterface).job_title || data.specialisation.name}
      </Text>
    </HStack>
  ) : null

  const team = data.team?.name ? (
    <HStack gap="s-4" align="center">
      <People color={Token.color.greyTone50} size={15} />
      <Text
        use={InternalLink}
        to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, {
          id: data.team.id,
        })}
        target="_blank"
        color={opacity(Token.colorChannel.foreground, 0.8)}
        variant="caption"
        textDecoration="none"
      >
        {data.team.name}
      </Text>
    </HStack>
  ) : null

  const location = data.location?.location_name ? (
    <HStack gap="s-2" align="center">
      <LocationPin color={Token.color.greyTone50} size={15} />
      <Text
        use={InternalLink}
        to={pathToUrl(ROUTES.FORMS.LOCATION.GENERAL, {
          id: data.location.id,
        })}
        target="_blank"
        color={opacity(Token.colorChannel.foreground, 0.8)}
        variant="caption"
        textDecoration="none"
      >
        {data.location.location_name}
      </Text>
    </HStack>
  ) : null

  return (
    <Box maxWidth={{ all: '100%', lg: Token.breakpoint.xl }} mt="s-8">
      <Cell pt="s-24" px="s-16" pb="s-16">
        <VStack gap="s-16" overflow="hidden">
          <Flex flex={1} alignItems="center" gap="s-16">
            {data.id && (
              <Flex alignSelf="flex-start">
                <FormTitleAvatar
                  data={data}
                  timeOff={timeOff}
                  refreshData={refreshData}
                />
              </Flex>
            )}
            <VStack gap="s-4">
              <Text variant="h1" whiteSpace="pre-wrap">
                {data.id ? data.full_name : `New Employee`}
              </Text>
              <Flex flexWrap="wrap" gap={{ sm: 's-6', md: 's-12' }}>
                {status}
                {synced}
                {companyRelation}
                {specialisation}
                {team}
                {location}
              </Flex>
            </VStack>
          </Flex>
          {actions}
        </VStack>
      </Cell>
    </Box>
  )
}
