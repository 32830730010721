import React, { useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { connect } from 'lape'
import { Box, Color, MoreBar, chain, Text, Token } from '@revolut/ui-kit'
import DOMPurify from 'dompurify'

import Form from '@src/features/Form/Form'
import {
  notificationTemplateRequests,
  sendNotification,
  sendTestNotification,
  testNotificationTemplate,
  getNotificationSendingHistory,
} from '@src/api/notificationTemplate'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  NotificationTemplateInterface,
  NotificationType,
  TemplateType,
  DeliveryType,
  NotificationStatus,
} from '@src/interfaces/notificationTemplate'
import { ROUTES } from '@src/constants/routes'
import General from './General'
import Preview from './Preview'
import Logs from './Logs'
import { pathToUrl } from '@src/utils/router'
import Loader from '@src/components/CommonSC/Loader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageBody } from '@src/components/Page/PageBody'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { DeleteButtonLape } from '../FormButtons'
import SideBar from '@components/SideBar/SideBar'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { Grid } from '@src/components/CommonSC/Grid'
import Icon from '@src/components/Icon/Icon'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { successNotification } from '@src/store/notifications/actions'
import SettingsButtons from '@src/features/SettingsButtons'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import { useGetCommunicationSettings } from '@src/api/settings'
import AnnouncementPreview from './components/AnnouncementPreview/AnnouncementPreview'
import ArchiveButton from '@src/pages/Forms/NotificationTemplate/components/ArchiveButton/ArchiveButton'
import { useTable } from '@components/Table/hooks'
import { useEmailNotificationsEnabled } from './useEmailNotificationsEnabled'

export const isHtmlPreviewEnabled = (values: NotificationTemplateInterface) =>
  values.type?.id === NotificationType.email &&
  values.template_type?.id === TemplateType.html

const typeToName = {
  [NotificationType.email]: 'Email',
  [NotificationType.slack]: 'Slack',
  [NotificationType.sms]: 'SMS',
  [NotificationType.system]: 'System',
  [NotificationType.announcement]: 'Announcement',
}

const NotificationTemplate = () => {
  const params = useParams<{ id?: string }>()
  const isPreviewRoute = !!useRouteMatch(ROUTES.FORMS.NOTIFICATION_TEMPLATE.PREVIEW)
  const isGeneralRoute = !!useRouteMatch(ROUTES.FORMS.NOTIFICATION_TEMPLATE.GENERAL)

  const { data: settings } = useGetCommunicationSettings()
  const emailNotificationsEnabled = useEmailNotificationsEnabled()
  const emailEnabled = emailNotificationsEnabled && !!settings?.enable_email_notifications
  const slackEnabled = !!settings?.enable_slack_notifications
  const smsEnabled = !!settings?.enable_sms_notifications
  const systemEnabled = !!settings?.enable_system_notifications
  const announcementEnabled = !!settings?.enable_announcements

  const form = useLapeContext<NotificationTemplateInterface>()
  const { values, initialValues, dirty } = form

  const [sendConfirmationVisible, setSendConfirmationVisible] = useState(false)
  const [testRunning, setTestRunning] = useState(false)
  const [testSending, setTestSending] = useState(false)
  const [sending, setSending] = useState(false)
  const [previewOpen, setPreviewOpen] = useState(false)
  const [hasSelectedType, setHasSelectedType] = useState(false)

  const sendingHistoryTable = useTable(
    {
      getItems: getNotificationSendingHistory(values.id),
    },
    undefined,
    undefined,
    { disable: !values.id },
  )

  const backUrl = ROUTES.APPS.COMMUNICATION.NOTIFICATIONS
  const title = (() => {
    if (isGeneralRoute) {
      return params.id
        ? `Edit ${
            values?.type?.id === NotificationType.announcement
              ? 'announcement'
              : 'notification'
          }`
        : `New ${values?.type?.name} ${
            values?.type?.id === NotificationType.announcement ? '' : 'notification'
          }`
    }
    return initialValues?.name
  })()

  const onTypeSelect = (type: NotificationType) => () => {
    values.type = { id: type, name: typeToName[type] }
    setHasSelectedType(true)
  }

  const runTest = () => {
    setTestRunning(true)
    testNotificationTemplate(values)
      .then(res => successNotification(res.data.message))
      .finally(() => setTestRunning(false))
  }

  const sendTest = () => {
    setTestSending(true)
    sendTestNotification(values)
      .then(res => successNotification(res.data.message))
      .finally(() => setTestSending(false))
  }

  const onConfirmSend = () => {
    setSending(true)
    sendNotification(values)
      .then(res => {
        successNotification(res.data.message)
        sendingHistoryTable.refresh()
        closeSendConfirmation()
      })
      .finally(() => setSending(false))
  }

  const closeSendConfirmation = () => {
    setSendConfirmationVisible(false)
  }

  if (!values) {
    return <Loader />
  }

  if (!params.id && !hasSelectedType) {
    return (
      <PageWrapper>
        <PageHeader
          title="Select type of notification"
          backUrl={ROUTES.APPS.COMMUNICATION.NOTIFICATIONS}
        />
        <PageBody>
          <Grid flow="row" gap={24}>
            {systemEnabled && (
              <NewSelectionCard
                onClick={onTypeSelect(NotificationType.system)}
                icon="Bell"
                title="System notification"
                subtitle="Create the template for notification displayed on employee’s dashboard"
                transparent={false}
              />
            )}
            {announcementEnabled && (
              <NewSelectionCard
                onClick={onTypeSelect(NotificationType.announcement)}
                icon="Megaphone"
                title="Announcements"
                subtitle="Create an announcement to be broadcast to a group of users"
                transparent={false}
              />
            )}
            {emailEnabled && (
              <NewSelectionCard
                onClick={onTypeSelect(NotificationType.email)}
                icon="Envelope"
                title="Email"
                subtitle="Create the email template to be sent to work or personal emails"
                transparent={false}
              />
            )}
            {slackEnabled && (
              <NewSelectionCard
                onClick={onTypeSelect(NotificationType.slack)}
                icon={() => <Icon type="Slack" />}
                title="Slack"
                subtitle="Create the template for a slack notification"
                transparent={false}
              />
            )}
            {smsEnabled && (
              <NewSelectionCard
                onClick={onTypeSelect(NotificationType.sms)}
                icon="Smartphone"
                title="SMS"
                subtitle="Create the template for an SMS notification to be sent to personal phone numbers"
                transparent={false}
              />
            )}
          </Grid>
        </PageBody>
      </PageWrapper>
    )
  }

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.NOTIFICATION_TEMPLATE.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.NOTIFICATION_TEMPLATE.PREVIEW, params),
      component: <Preview table={sendingHistoryTable} />,
    },
    {
      title: chain(
        'Logs',
        <Box data-testid="all_count">{values.stats_all}</Box>,
        <Box color={Color.RED} data-testid="failed_count">
          {values.stats_fail}
        </Box>,
      ),
      path: ROUTES.FORMS.NOTIFICATION_TEMPLATE.LOGS,
      to: pathToUrl(ROUTES.FORMS.NOTIFICATION_TEMPLATE.LOGS, params),
      component: <Logs />,
    },
  ]

  const runTestButton =
    values?.type?.id !== NotificationType.announcement &&
    values?.type?.id !== NotificationType.system ? (
      <MoreBar.Action onClick={runTest} pending={testRunning} useIcon="Check">
        Verify template
      </MoreBar.Action>
    ) : null

  const reviewHtmlButton = isHtmlPreviewEnabled(values) && (
    <MoreBar.Action onClick={() => setPreviewOpen(!previewOpen)} useIcon="Document">
      Preview
    </MoreBar.Action>
  )

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={chain(
          title,
          values.status && values.status.id !== NotificationStatus.active ? (
            <Text color={Token.color.greyTone50} mb="s-16">
              {values.status.name}
            </Text>
          ) : null,
        )}
        backUrl={backUrl}
      >
        {isGeneralRoute ? null : (
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={tabs} />
          </Box>
        )}
      </PageHeader>

      {isGeneralRoute && (
        <SettingsButtons mt="s-16">
          {reviewHtmlButton}
          {runTestButton}
        </SettingsButtons>
      )}
      {isPreviewRoute && (
        <SettingsButtons mb="s-8" mt="s-16" maxWidth={450}>
          {values.status.id === NotificationStatus.created && <ArchiveButton />}
          {reviewHtmlButton}
          <AnnouncementPreview />
          {!dirty && values.delivery_type?.id !== DeliveryType.event_based ? (
            <MoreBar.Action
              useIcon="SendMessage"
              onClick={() => setSendConfirmationVisible(true)}
              pending={sending}
            >
              Send
            </MoreBar.Action>
          ) : null}
          <EditButton
            route={pathToUrl(ROUTES.FORMS.NOTIFICATION_TEMPLATE.GENERAL, params)}
          />
          {runTestButton}
          {!dirty && (
            <MoreBar.Action
              useIcon="SendMessage"
              onClick={sendTest}
              pending={testSending}
            >
              Send test
            </MoreBar.Action>
          )}
          {values.status.id !== NotificationStatus.created && <ArchiveButton />}
          <DeleteButtonLape
            useMoreBar
            data={values}
            backUrl={backUrl}
            deleteApi={notificationTemplateRequests.delete!}
            title="Notification"
          />
        </SettingsButtons>
      )}

      <Switch>
        <Route exact path={ROUTES.FORMS.NOTIFICATION_TEMPLATE.GENERAL}>
          <General />
        </Route>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            {tab.component}
          </Route>
        ))}
      </Switch>

      <ConfirmationDialog
        open={sendConfirmationVisible}
        onClose={closeSendConfirmation}
        onConfirm={onConfirmSend}
        loading={sending}
        onReject={closeSendConfirmation}
        label="Send notification"
        body="Are you sure you want to send the notification to all the recipients?"
      />

      <SideBar
        isOpen={previewOpen}
        onClose={() => setPreviewOpen(false)}
        title="HTML preview"
        variant="wide"
        sideProps={{ resizable: true }}
      >
        <ActionWidget
          title="Please test your template before sending it to real users"
          text="HTML doesn’t work the same in emails and webpages, therefore we cannot guarantee that your email notification will look exactly like the preview shown below. Please use the ‘Test’ action to verify your template layout."
          avatarColor={Token.color.accent}
        />
        <Box
          mt="s-16"
          backgroundColor={Color.BACKGROUND}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(values.template_text),
          }}
          data-testid="html_preview"
        />
      </SideBar>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={notificationTemplateRequests}>
    <NotificationTemplate />
  </Form>
))
