import React, { useRef } from 'react'
import { Ellipsis, Flex, Icon, MoreBar, Text, useToggle } from '@revolut/ui-kit'

import { FilterDateRange } from '@src/components/Inputs/Filters/FilterDateRange/FilterDateRange'
import { formatPeriod } from '@src/utils/format'
import { IdAndName } from '@src/interfaces'

export const calendarSelectorIcon = 'CalendarWeek'

interface Props {
  value: IdAndName<string>[]
  onChange: (option: IdAndName<string>[]) => void
}
export const CalendarDatesSelector = ({ value, onChange }: Props) => {
  const [open, toggleOpen] = useToggle()
  const selectRef = useRef<HTMLButtonElement>(null)

  const [fromDate, toDate] = value
  const datesRange =
    fromDate?.id && toDate?.id ? formatPeriod(fromDate.id, toDate.id) : undefined

  return (
    <>
      <MoreBar.Action
        useIcon={calendarSelectorIcon}
        aria-label="engagement dates range selector"
        aria-haspopup="listbox"
        onClick={toggleOpen.switch}
        aria-expanded={open}
        ref={selectRef}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          <Ellipsis>
            <Text variant="emphasis2">{datesRange || 'Dates filter'}</Text>
          </Ellipsis>

          <Icon name={open ? 'ChevronUp' : 'ChevronDown'} />
        </Flex>
      </MoreBar.Action>
      <FilterDateRange
        open={open}
        onClose={toggleOpen.off}
        value={value}
        onChange={newDates => {
          onChange(newDates)
          toggleOpen.off()
        }}
      />
    </>
  )
}
