import React from 'react'
import groupBy from 'lodash/groupBy'
import {
  Box,
  HStack,
  Item,
  Subheader,
  Text,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'

import SideBar from '@components/SideBar/SideBar'
import { useGetConfigurableBulkUploadFields } from '@src/api/bulkDataImport'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { BulkUploadConfigurableField } from '@src/interfaces/bulkDataImport'
import { ConfigurableField } from '../helpers'
import { EmployeeCustomFields } from './EmployeeCustomFields'
import { getAddFieldButtonProps, WithLimitedFieldsAmount } from './helpers'

enum EmployeeTabs {
  DefaultFields = 'Default',
  CustomFields = 'Custom',
}

type Props = {
  isOpen: boolean
  onClose: () => void
  entityName: string
  onAddField: (field: ConfigurableField) => void
  onRemoveField: (field: ConfigurableField) => void
  addedFields: ConfigurableField[]
  mandatoryColumns: string[]
  onCreateNewField: () => void
}
export const AddFieldsSidebar = ({
  isOpen,
  onClose,
  entityName,
  onAddField,
  onRemoveField,
  addedFields,
  mandatoryColumns,
  onCreateNewField,
}: Props) => {
  const { data: configurableFieldsData = [] } = useGetConfigurableBulkUploadFields(
    entityName === 'contract'
      ? 'contracts'
      : entityName === 'goals'
      ? 'goals'
      : 'employees',
  )
  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs: [EmployeeTabs.DefaultFields, EmployeeTabs.CustomFields],
    highlightSelected: false,
  })
  const isEmployeesUpload = entityName === 'employee'
  const showStandardFields =
    !isEmployeesUpload || currentTab === EmployeeTabs.DefaultFields

  const groupedFieldsData: Record<string, BulkUploadConfigurableField[]> = groupBy(
    configurableFieldsData,
    field => field.category,
  )

  return (
    <SideBar title="Add fields" isOpen={isOpen} onClose={onClose}>
      {isEmployeesUpload && <Box mb="s-24">{tabBar}</Box>}
      {showStandardFields && (
        <VStack space="s-8">
          {Object.entries(groupedFieldsData).map(([group, fields]) => (
            <React.Fragment key={group}>
              <Subheader variant="nested">
                <Subheader.Title>{group}</Subheader.Title>
              </Subheader>

              <VStack width="100%" gap="s-8">
                {fields.map(field => {
                  const isMandatoryColumn =
                    field.is_required || mandatoryColumns.includes(field.column_name)

                  const mandatoryColumnAction = isMandatoryColumn ? (
                    <Text
                      color={Token.color.greyTone50}
                      aria-label={`${field.name} preset`}
                    >
                      Preset
                    </Text>
                  ) : undefined

                  const removeFieldAction = (
                    <TextButton
                      onClick={() => onRemoveField(field)}
                      aria-label={`Remove ${field.name}`}
                    >
                      Remove
                    </TextButton>
                  )

                  const addFieldAction = (
                    <WithLimitedFieldsAmount addedFieldsNum={addedFields.length}>
                      <TextButton
                        onClick={() => onAddField(field)}
                        {...getAddFieldButtonProps(entityName, field, addedFields)}
                        aria-label={`Add ${field.name}`}
                      >
                        Add
                      </TextButton>
                    </WithLimitedFieldsAmount>
                  )

                  const optionalFieldAction = addedFields.some(f => f.name === field.name)
                    ? removeFieldAction
                    : addFieldAction

                  return (
                    <Item key={field.name}>
                      <Item.Content>
                        <HStack space="s-8" align="center">
                          <Text>{field.name}</Text>
                        </HStack>
                      </Item.Content>
                      <Item.Side>
                        {mandatoryColumnAction || optionalFieldAction}
                      </Item.Side>
                    </Item>
                  )
                })}
              </VStack>
            </React.Fragment>
          ))}
        </VStack>
      )}
      {currentTab === EmployeeTabs.CustomFields && (
        <EmployeeCustomFields
          onAddField={onAddField}
          onRemoveField={onRemoveField}
          addedFields={addedFields}
          onCreateNewField={onCreateNewField}
        />
      )}
    </SideBar>
  )
}
