import { EligibleGroupInterface } from '@src/interfaces/reviewCycles'
import {
  ActionButton,
  ActionButtonSkeleton,
  DetailsCellSkeleton,
  TextWidget,
  TextWidgetSkeleton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import NoDataWidget from '@src/pages/Forms/ReviewCycle/components/NoDataWidget'
import { EligibleGroup } from '@src/features/ReviewCycle/PerformanceReviewCycle/EligibleEmployees/EditEligibleGroups/EligibleGroup'
import React from 'react'

interface Props {
  groups: EligibleGroupInterface[]
  isLoading: boolean
  handleAdd: () => void
  handleEdit: (group: EligibleGroupInterface) => () => void
  handleDelete: (group: EligibleGroupInterface) => () => Promise<void>
}

export const EligibleGroups = ({
  groups,
  isLoading,
  handleAdd,
  handleEdit,
  handleDelete,
}: Props) => {
  if (isLoading) {
    return (
      <VStack space="s-16">
        <TextWidgetSkeleton />
        <DetailsCellSkeleton height={370} />
        <ActionButtonSkeleton width={190} />
      </VStack>
    )
  }

  if (!groups.length) {
    return (
      <VStack space="s-16">
        <NoDataWidget text="No employees selected as eligible" />
        <ActionButton useIcon="Plus" onClick={handleAdd}>
          Add employee group
        </ActionButton>
      </VStack>
    )
  }

  return (
    <VStack space="s-16">
      <TextWidget>
        <TextWidget.Title>
          Who should be reviewed in this performance cycle?
        </TextWidget.Title>
        <TextWidget.Content variant="caption" color={Token.color.greyTone50}>
          Select the eligibility criteria
        </TextWidget.Content>
      </TextWidget>
      {groups.map(group => (
        <EligibleGroup
          key={group.id}
          title={group.name}
          employeesCount={group.preview?.count ?? 0}
          filtersCount={group.filters ? Object.keys(group.filters).length : 0}
          isEligible={group.sign !== 'exclude'}
          isScoreCardsEnabled={group.should_create_scorecards}
          employees={group.preview?.results ?? []}
          handleEdit={handleEdit(group)}
          handleDelete={handleDelete(group)}
        />
      ))}
      <ActionButton useIcon="Plus" onClick={handleAdd}>
        Add employee group
      </ActionButton>
    </VStack>
  )
}
