import React from 'react'
import {
  Avatar,
  Box,
  Caption,
  HStack,
  IconName,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { GradeCalculationMethods } from '@src/interfaces/reviewCycles'
import { getCalibrationMethodTitle } from '@src/features/ReviewCycle/PerformanceReviewCycle/CalibrationLogic/constants'
import { OptionInterface } from '@src/interfaces/selectors'

interface Props {
  iconName: IconName
  title: string
  description: string
}

const OptionLabel = ({ iconName, title, description }: Props) => (
  <HStack ml="s-8">
    <Box mr="s-16">
      <Avatar useIcon={iconName} />
    </Box>
    <VStack>
      <Text>{title}</Text>
      <Caption color={Token.color.greyTone50}>{description}</Caption>
    </VStack>
  </HStack>
)

const lMRecommendationOption: RadioOption<OptionInterface<GradeCalculationMethods>> = {
  value: {
    name: GradeCalculationMethods.LineManagerSuggestion,
    id: GradeCalculationMethods.LineManagerSuggestion,
  },
  label: (
    <OptionLabel
      iconName="Profile"
      title={getCalibrationMethodTitle(GradeCalculationMethods.LineManagerSuggestion)}
      description="The grade given by the Line Manager will be recommended as the final grade for the employee."
    />
  ),
}

const fMRecommendationOption: RadioOption<OptionInterface<GradeCalculationMethods>> = {
  value: {
    name: GradeCalculationMethods.FunctionalManagerSuggestion,
    id: GradeCalculationMethods.FunctionalManagerSuggestion,
  },
  label: (
    <OptionLabel
      iconName="Profile"
      title={getCalibrationMethodTitle(
        GradeCalculationMethods.FunctionalManagerSuggestion,
      )}
      description="The grade given by the Functional Manager will be recommended as the final grade for the employee."
    />
  ),
}

const combinedRecommendationsOption: RadioOption<
  OptionInterface<GradeCalculationMethods>
> = {
  value: {
    name: GradeCalculationMethods.PerformanceGradeSuggestion,
    id: GradeCalculationMethods.PerformanceGradeSuggestion,
  },
  label: (
    <OptionLabel
      iconName="BulkSelection"
      title={getCalibrationMethodTitle(
        GradeCalculationMethods.PerformanceGradeSuggestion,
      )}
      description="The grade calculates as average of Line manager grade suggestion, Functional manager grade suggestion and Calculated grade."
    />
  ),
}

const combinedGradesOption: RadioOption<OptionInterface<GradeCalculationMethods>> = {
  value: {
    name: GradeCalculationMethods.PerformanceExtraGradeSuggestion,
    id: GradeCalculationMethods.PerformanceExtraGradeSuggestion,
  },
  label: (
    <OptionLabel
      iconName="BulkSelection"
      title={getCalibrationMethodTitle(
        GradeCalculationMethods.PerformanceExtraGradeSuggestion,
      )}
      description="The grade calculates as average of Line manager grade suggestion, Functional manager grade suggestion and Calculated grade, but will be adjusted based on the responses provided in the Bar Raiser questions section by the managers. Based on their positive or negative responses, recommended grade can be adjusted up or down."
    />
  ),
}

const scorecardGradesOption: RadioOption<OptionInterface<GradeCalculationMethods>> = {
  value: {
    name: GradeCalculationMethods.CalculatedGrade,
    id: GradeCalculationMethods.CalculatedGrade,
  },
  label: (
    <OptionLabel
      iconName="16/EditorTable"
      title={getCalibrationMethodTitle(GradeCalculationMethods.CalculatedGrade)}
      description="The grade calculates based on the assessment provided by the managers in the scorecard versus the skills level expectations in the competency matrix."
    />
  ),
}

export const calibrationLogicOptions: RadioOption<
  OptionInterface<GradeCalculationMethods>
>[] = [
  lMRecommendationOption,
  fMRecommendationOption,
  scorecardGradesOption,
  combinedRecommendationsOption,
  combinedGradesOption,
]
