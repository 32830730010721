import { HStack, Icon, TextButton, Text } from '@revolut/ui-kit'
import React from 'react'

interface Props {
  onClick?: VoidFunction
  value?: string
  disabled?: boolean
}

export const VisibilitySettingsButton = ({ onClick, value, disabled }: Props) => {
  if (!onClick) {
    return null
  }
  return (
    <TextButton onClick={onClick} disabled={disabled}>
      <HStack gap="s-8" align="center">
        <Icon name="Pencil" size={16} />
        <Text>Visibility: {value || 'Click to set visivility'}</Text>
      </HStack>
    </TextButton>
  )
}
