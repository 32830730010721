import React from 'react'
import {
  Header,
  Popover,
  TopNav,
  Avatar,
  abbreviate,
  VStack,
  chain,
  HStack,
  Link,
} from '@revolut/ui-kit'
import { useSelector, useDispatch } from 'react-redux'

import {
  selectFeatureFlags,
  selectPermissions,
  selectUser,
} from '@src/store/auth/selectors'
import { getAvatarUrl } from '@src/utils/employees'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'
import { logOutAction } from '@src/store/auth/actions'
import { useSubsciptionInfo } from '@src/api/plans'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import {
  generalSettings,
  organisationSettings,
  otherSettings,
  peopleSettings,
  performanceSettings,
  plansAndBilling,
  recruitmentSettings,
  securitySettings,
} from '@src/pages/Settings/SettingsLandingPage/SettingsLandingPage'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { setFeedbackOpen } from '@src/store/feedback/actions'
import { useGetHelpCenterSettings, useGlobalSettings } from '@src/api/settings'
import bugReportSideState from '@components/HelpCenter/components/BugReportSideState'
import { useOpenHelpCenter } from '@src/components/HelpCenter/hooks'
import AppVersion from '@src/features/AppVersion/AppVersion'
import { useGetCanEnableTestCycleSandbox } from '@src/utils/reviewCycles'
import { TopNavAccountSwitcher } from './TopNavAccountSwitcher'

export const UserProfile = () => {
  const user = useSelector(selectUser)

  const dispatch = useDispatch()

  const subscriptionInfo = useSubsciptionInfo()
  const { data: helpCenterData } = useGetHelpCenterSettings()
  const { openHelpCenter } = useOpenHelpCenter()
  const canEnableSandbox = useGetCanEnableTestCycleSandbox()
  const { settings } = useGlobalSettings()

  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const canRaiseTickets = featureFlags?.includes(FeatureFlags.TicketsApplication)

  const helpCenterEnabled = helpCenterData?.help_section_enabled
  const canViewUsersAccessManagement = permissions.includes(PermissionTypes.ViewUser)

  const canViewAdmin =
    canEnableSandbox ||
    [
      ...peopleSettings(),
      ...organisationSettings(),
      ...recruitmentSettings(settings),
      ...otherSettings({ isCommercial }),
      ...securitySettings(),
      ...performanceSettings({ isCommercial }),
      ...plansAndBilling(subscriptionInfo),
      ...generalSettings({
        isCommercial,
        accountsEnabled: featureFlags?.includes(FeatureFlags.TenantAccounts),
      }),
    ]
      .reduce<PermissionTypes[]>((prev, curr) => [...prev, ...(curr.canView || [])], [])
      .some(permission => permissions.includes(permission))

  const isExternal = user.employee_type === 'external'
  const specialisation = isExternal
    ? user.specialisation?.name
    : user.job_title || user.specialisation?.name

  const fullName = user.full_name ? abbreviate(user.full_name) : ''

  return (
    <>
      <TopNav.Profile
        image={getAvatarUrl(user?.avatar)}
        label={fullName}
        footer={
          <VStack space="s-4" align="center">
            <AppVersion onClick={TopNav.closePopover} />
            <HStack>
              {chain(
                <Link href={PUBLIC_ROUTES.TERMS_AND_CONDITIONS} target="_blank">
                  Terms
                </Link>,
                <Link href={PUBLIC_ROUTES.PRIVACY_NOTICE} target="_blank">
                  Privacy policy
                </Link>,
              )}
            </HStack>
          </VStack>
        }
        accountSwitcher={isCommercial ? <TopNavAccountSwitcher /> : undefined}
      >
        <Header variant="item">
          <Header.Title>{user.full_name}</Header.Title>
          <Header.Description>{specialisation}</Header.Description>
          <Header.Avatar>
            <Avatar size={56} label={fullName} image={getAvatarUrl(user?.avatar)} />
          </Header.Avatar>
        </Header>
        <Popover.Group>
          <Popover.Item
            onClick={TopNav.closePopover}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: user.id })}
            use={InternalLink}
            useIcon="Profile"
          >
            My profile
          </Popover.Item>
          {canViewAdmin ? (
            <Popover.Item
              onClick={TopNav.closePopover}
              use={InternalLink}
              to={pathToUrl(ROUTES.SETTINGS.ALL)}
              useIcon="Gear"
            >
              Settings
            </Popover.Item>
          ) : null}
          {canViewUsersAccessManagement ? (
            <Popover.Item
              onClick={TopNav.closePopover}
              use={InternalLink}
              to={pathToUrl(ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS)}
              useIcon="LockOpened"
            >
              Access management
            </Popover.Item>
          ) : null}
          {helpCenterEnabled ? (
            <HideIfCommercial>
              <Popover.Item
                onClick={() => {
                  TopNav.closePopover()
                  openHelpCenter()
                }}
                useIcon="QuestionOutline"
                use="button"
              >
                Help
              </Popover.Item>
            </HideIfCommercial>
          ) : null}
          <Popover.Item
            onClick={TopNav.closePopover}
            use={InternalLink}
            to={pathToUrl(ROUTES.SETTINGS.APPEARANCE)}
            useIcon="Palette"
          >
            Appearance
          </Popover.Item>
          <HideIfCommercial>
            <Popover.Item
              onClick={TopNav.closePopover}
              to={pathToUrl(ROUTES.PREFERENCES.PRIVACY)}
              useIcon="Shield"
              use={InternalLink}
            >
              Data & privacy policies
            </Popover.Item>
          </HideIfCommercial>

          {canRaiseTickets ? (
            <>
              <Popover.Item
                onClick={() => {
                  TopNav.closePopover()
                  bugReportSideState.open = true
                }}
                use="button"
                useIcon="Bug"
              >
                Report a bug
              </Popover.Item>
              <Popover.Item
                onClick={TopNav.closePopover}
                to={ROUTES.TICKETS.ALL}
                use={InternalLink}
                useIcon="Chat"
              >
                View my requests
              </Popover.Item>
            </>
          ) : null}
          <Popover.Item
            onClick={() => {
              TopNav.closePopover()
              dispatch(setFeedbackOpen(true))
            }}
            use="button"
            useIcon="SendMessage"
          >
            Share feedback
          </Popover.Item>
          <Popover.Item
            onClick={() => {
              TopNav.closePopover()
              dispatch(logOutAction())
            }}
            use="button"
            useIcon="LogoutDoor"
            color="danger"
          >
            Logout
          </Popover.Item>
        </Popover.Group>
      </TopNav.Profile>
    </>
  )
}
