import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useSidebarContext } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/SidebarProvider'
import { SwitchStageAction } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Actions/SwitchStage/SwitchStageAction'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'

interface Props {
  cycle: ReviewCyclesInterface
  stages: ReviewCycleStage[]
  stage: ReviewCycleStage
  canSwitchNext: boolean
  canSwitchPrevious: boolean
}

export const GoalsActions = ({
  cycle,
  stages,
  stage,
  canSwitchNext,
  canSwitchPrevious,
}: Props) => {
  const { show } = useSidebarContext()

  return (
    <>
      <MoreBar.Action useIcon="LinkExternal" onClick={show}>
        Review goals
      </MoreBar.Action>
      {canSwitchNext && (
        <SwitchStageAction
          cycle={cycle}
          stages={stages}
          stage={stage}
          direction="forward"
        />
      )}
      {canSwitchPrevious && (
        <SwitchStageAction
          cycle={cycle}
          stages={stages}
          stage={stage}
          direction="backward"
        />
      )}
    </>
  )
}
