import { useMemo } from 'react'
import { utcToZonedTime } from 'date-fns-tz'
import { addWeeks, formatISO } from 'date-fns'
import { OptionInterface } from '@src/interfaces/selectors'
import { DEFAULT_WEEKS_COUNT } from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotDateFilter'

export const useGetSchedulingDateRange = (
  timeZoneId: string,
  dateFilter?: OptionInterface,
) => {
  return useMemo(() => {
    const timeZonedDate = utcToZonedTime(new Date(), timeZoneId)
    return [
      formatISO(timeZonedDate, { representation: 'date' }),
      formatISO(
        addWeeks(timeZonedDate, dateFilter ? +dateFilter.id : DEFAULT_WEEKS_COUNT),
        {
          representation: 'date',
        },
      ),
    ]
  }, [dateFilter, timeZoneId])
}
