import { AxiosError } from 'axios'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { stageToLabelMap } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/constants'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const useSwitchStageStatusPopup = () => {
  const showStatusPopup = useShowStatusPopup()

  const showSwitchNextCompleted = (stage: ReviewCycleStage) => {
    showStatusPopup({
      status: 'success',
      title: `${stageToLabelMap[stage]} period completed`,
    })
  }

  const showSwitchNextFailed = (stage: ReviewCycleStage, err: AxiosError) => {
    showStatusPopup({
      status: 'error',
      title: `${stageToLabelMap[stage]} period not completed`,
      description: getStringMessageFromError(err, 'Please try again later'),
    })
  }

  const showSwitchPreviousCompleted = (stage: ReviewCycleStage) => {
    showStatusPopup({
      status: 'success',
      title: `${stageToLabelMap[stage]} period re-opened`,
    })
  }

  const showSwitchPreviousFailed = (stage: ReviewCycleStage, err: AxiosError) => {
    showStatusPopup({
      status: 'error',
      title: `${stageToLabelMap[stage]} period not re-opened`,
      description: getStringMessageFromError(err, 'Please try again later'),
    })
  }

  return {
    showSwitchNextCompleted,
    showSwitchNextFailed,
    showSwitchPreviousCompleted,
    showSwitchPreviousFailed,
  }
}
