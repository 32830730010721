import mapValues from 'lodash/mapValues'
import omit from 'lodash/omit'
import uniq from 'lodash/uniq'

import { useQuery } from '@src/utils/queryParamsHooks'
import { DYNAMIC_COLUMNS_QUERY_KEY } from '@src/features/GenericEditableTable/constants'
import { SelectTableWrapperOnChangeData } from '@components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { FieldOptions } from '@src/interfaces'

export const getBulkSessionFieldsMap = <T extends string>(
  mandatoryFields: string[],
  fieldsDependencies: Record<T, T[]>,
) =>
  mapValues(fieldsDependencies, dependenciesList =>
    uniq([...mandatoryFields, ...dependenciesList]),
  )

export const getHiddenSessionColumnsBySessionField = <T extends string>(
  sessionField: T,
  columnsToHideList: T[],
  dependencies: Record<T, T[]>,
) => {
  const columnsToHide = Object.fromEntries(columnsToHideList.map(col => [col, true]))
  const columnsBySessionFields = mapValues(dependencies, dependenciesList =>
    omit(columnsToHide, dependenciesList),
  )
  return columnsBySessionFields[sessionField] || {}
}

export const getVisibleSessionActionsBySessionField = <T extends string>(
  sessionField: T,
  dependencies: Record<T, T[]>,
): Record<string, boolean> => {
  const dependenciesList = dependencies[sessionField]
  if (dependenciesList) {
    return Object.fromEntries(dependenciesList.map(field => [field, true]))
  }
  return mapValues(dependencies, () => true)
}

const useQueryDataColumns = () => {
  const { query } = useQuery()
  return query[DYNAMIC_COLUMNS_QUERY_KEY]?.split(',') || []
}

export const useHiddenColumnsByQuery = (availableFields: string[]) => {
  const visibleColsByQuery = useQueryDataColumns()
  const columnsToHideList = availableFields.filter(
    field => !visibleColsByQuery?.includes(field),
  )
  return Object.fromEntries(columnsToHideList.map(col => [col, true]))
}

export const useVisibleActionsByQuery = () => {
  const visibleColsByQuery = useQueryDataColumns()
  return Object.fromEntries(visibleColsByQuery.map(col => [col, true]))
}

export const hasSynchronisedFields = (
  selectionContext?: SelectTableWrapperOnChangeData<{
    id: number
    data?: { field_options?: FieldOptions }
  }>,
) => {
  if (selectionContext?.isAllSelected) {
    return selectionContext?.tableData.some(
      data =>
        data.data?.field_options?.synchronised?.length &&
        data.data?.field_options?.synchronised?.length > 0,
    )
  }

  return selectionContext?.selectedRowsData.some(
    data =>
      data.data?.field_options?.synchronised?.length &&
      data.data?.field_options?.synchronised?.length > 0,
  )
}
