import { useGetPerformanceSelector } from '@src/api/performance'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import CycleSelector, { CycleSelectorProps, ReviewCycle } from './CycleSelector'
import { getGenericCyclesInfo } from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'
import {
  ReviewCycleCategory,
  ReviewCyclesInterface,
  ReviewCycleStatus,
} from '@src/interfaces/reviewCycles'
import React, { useEffect, useState } from 'react'

export const GoalTargetCycleSelector = ({
  onChange,
  isEmployee,
  ownerId,
  initialCycle,
  ...selectorPropsOverride
}: {
  onChange?: (cycle: ReviewCycle | ReviewCyclesInterface) => void
  isEmployee: boolean
  ownerId?: number
  initialCycle?: ReviewCycle
} & Partial<CycleSelectorProps>) => {
  const { data: employeeAvailableCycles } = useGetPerformanceSelector(ownerId, {
    exclude_adhoc: true,
  })
  const { data: reviewCyclesData } = useGetReviewCycles({ exclude_adhoc: true })
  const [targetCycle, setTargetCycle] = useState<ReviewCycle | undefined>(initialCycle)

  const getAvailableCyclesInfo = (targetIndex: number | null) => {
    const reviewCycles = reviewCyclesData?.results || []
    return getGenericCyclesInfo(reviewCycles, {
      targetIndex,
      allTargets: [],
    })
  }

  const { availableCycles } = getAvailableCyclesInfo(null)
  const employeeFilteredCycles = employeeAvailableCycles?.filter(
    cycle => cycle.status !== ReviewCycleStatus.closed,
  )

  const cycles: ReviewCycle[] =
    isEmployee && employeeFilteredCycles ? employeeFilteredCycles : availableCycles

  const onSelect = (cycle: ReviewCycle) => {
    onChange && onChange(cycle)
    setTargetCycle(cycle)
  }

  useEffect(() => {
    if (
      targetCycle?.category &&
      [
        ReviewCycleCategory.PIP,
        ReviewCycleCategory.PIP_V2,
        ReviewCycleCategory.Probation,
      ].includes(targetCycle.category) &&
      !cycles.find(({ id }) => id === targetCycle?.id)
    ) {
      const newCycle = cycles.find(({ offset }) => offset === 0) || cycles?.[0]
      onSelect(newCycle)
    }
  }, [ownerId])

  return (
    <>
      <CycleSelector
        value={targetCycle}
        reviewCycles={cycles}
        onSelect={onSelect}
        {...selectorPropsOverride}
      />
    </>
  )
}
