import React, { ComponentType, useMemo } from 'react'
import { Badge, Box, H5, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import { PageWrapper } from '@src/components/Page/Page'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'

import { Route, Switch, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageBody } from '@src/components/Page/PageBody'
import Form from '@src/features/Form/Form'
import { FormActions } from './components/FormActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { isStepDone } from './helpers'
import { PageActions } from '@src/components/Page/PageActions'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { MeetingTemplateEditStep } from './interfaces'
import { General, GeneralTimingSection } from './tabs/General'
import { Fields } from './tabs/Fields'
import { Audience } from './tabs/Audience'
import {
  meetingsTemplatesFormRequests,
  meetingsTemplatesDefaultSettingsFormRequests,
  useGetMeetingTemplate,
} from '@src/api/meetings'
import { MeetingTemplateInterface } from '@src/interfaces/meetings'
import { PreviewHeader } from './components/PreviewHeader'
import { PreviewGeneralSection } from './components/PreviewGeneralSection'
import { PreviewFieldsSection } from './components/PreviewFieldsSection'
import { PreviewAudienceSection } from './components/PreviewAudienceSection'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

const formTabs: (TabBarTableNavigationInterface & {
  component: ComponentType<any>
  key: MeetingTemplateEditStep
})[] = [
  {
    title: 'General Info',
    key: 'general',
    component: General,
  },
  {
    title: 'Fields',
    key: 'fields',
    component: Fields,
  },
  {
    title: 'Audience',
    key: 'audience',
    component: Audience,
  },
]

const DefaultTemplateSettingFormContent = () => {
  const showStatusPopup = useShowStatusPopup()

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.PERFORMANCE.ONE_TO_ONE.TEMPLATES}
        title="Default templates settings"
        subtitle="Define the default layout for all 1:1 meeting templates"
      />
      <PageBody gap="s-16" maxWidthMd={Token.breakpoint.md}>
        <VStack gap="s-16">
          <Text fontSize={18}>Default timing</Text>
          <Widget padding="s-16">
            <GeneralTimingSection />
          </Widget>
        </VStack>
        <VStack gap="s-16">
          <Text fontSize={18}>Default fields</Text>
          <Widget padding="s-16">
            <Fields />
          </Widget>
        </VStack>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          hideWhenNoChanges={false}
          useValidator
          noPopup
          onAfterSubmit={() => {
            navigateTo(ROUTES.PERFORMANCE.ONE_TO_ONE.TEMPLATES)
            showStatusPopup({
              title: 'Default settings are saved',
              status: 'success',
            })
          }}
        >
          Save
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageWrapper>
  )
}

const MeetingTemplateFormContent = () => {
  const { id, step } = useParams<{ id?: string; step: MeetingTemplateEditStep }>()
  const { values, dirty, initialValues } = useLapeContext<MeetingTemplateInterface>()
  const confirmationDialog = useConfirmationDialog()

  const title = id ? 'Edit meeting template' : 'New meeting template'

  const subTitle = id ? '' : 'Create a new meeting template'

  const handleBack = () => {
    if (dirty) {
      confirmationDialog.show({
        showHeader: false,
        body: (
          <H5 color={Token.color.foreground}>
            You have unsaved changes do you want to proceed?
          </H5>
        ),
        noMessage: 'Cancel',
        yesMessage: 'Confirm',
        onConfirm: async () => {
          goBack(ROUTES.PERFORMANCE.ONE_TO_ONE.TEMPLATES)
        },
      })
    } else {
      goBack(ROUTES.PERFORMANCE.ONE_TO_ONE.TEMPLATES)
    }
  }

  const tabs = useMemo(() => {
    return formTabs.map((tab, idx) => {
      const path = pathToUrl(ROUTES.FORMS.MEETING_TEMPLATE.EDIT, { id, step: tab.key })

      return {
        ...tab,
        path,
        disabled: !values.id,
        url: path,
        preTitle: (
          <Box mr="s-8">
            <Badge
              useIcon={isStepDone(tab.key, initialValues) ? 'Check' : undefined}
              size={16}
              bg={
                isStepDone(tab.key, initialValues)
                  ? Token.color.success
                  : step === tab.key
                  ? Token.color.greyTone50
                  : Token.color.greyTone10
              }
            >
              {!isStepDone(tab.key, initialValues) ? idx + 1 : null}
            </Badge>
          </Box>
        ),
      }
    })
  }, [id, step, formTabs, values])

  return (
    <PageWrapper>
      <PageHeader
        onClickBack={handleBack}
        title={title}
        subtitle={subTitle}
        backUrl={ROUTES.PERFORMANCE.ONE_TO_ONE.TEMPLATES}
      />
      <Box mb="s-16">
        <TabBarNavigation tabs={tabs} />
      </Box>
      <Switch>
        {tabs.map(tab => (
          <Route exact key={tab.key} path={tab.path}>
            <PageBody maxWidthMd={Token.breakpoint.md}>
              <VStack gap="s-16">
                <tab.component />
              </VStack>
            </PageBody>
            <PageActions>
              <FormActions currentStep={tab.key} />
            </PageActions>
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

const Preview = () => {
  const { id } = useParams<{ id: string }>()

  const onEdit = (step: MeetingTemplateEditStep, shouldGoBackOnSave: boolean = true) => {
    navigateTo(pathToUrl(ROUTES.FORMS.MEETING_TEMPLATE.EDIT, { step, id: data?.id }), {
      shouldGoBackOnSave,
    })
  }

  const { data, isLoading } = useGetMeetingTemplate(id)
  return (
    <PageWrapper>
      <PageBody gap="s-16" maxWidthMd={Token.breakpoint.md}>
        <PreviewHeader onEdit={onEdit} data={data} isLoading={isLoading} />
        <PreviewGeneralSection onEdit={onEdit} data={data} isLoading={isLoading} />
        <PreviewFieldsSection onEdit={onEdit} data={data} isLoading={isLoading} />
        <PreviewAudienceSection onEdit={onEdit} data={data} isLoading={isLoading} />
      </PageBody>
    </PageWrapper>
  )
}

export const MeetingTemplateForm = () => {
  return (
    <Switch>
      <Route path={ROUTES.FORMS.MEETING_TEMPLATE.PREVIEW}>
        <Preview />
      </Route>
      <Route exact path={ROUTES.FORMS.MEETING_TEMPLATE.DEFAULT_SETTING}>
        <Form isExistingForm api={meetingsTemplatesDefaultSettingsFormRequests}>
          <DefaultTemplateSettingFormContent />
        </Form>
      </Route>
      <Route exact path={ROUTES.FORMS.MEETING_TEMPLATE.EDIT}>
        <Form api={meetingsTemplatesFormRequests}>
          <MeetingTemplateFormContent />
        </Form>
      </Route>
    </Switch>
  )
}
