import {
  ConnectionType,
  ExternalChannelItemInterface,
  SlackDataInterface,
} from '@src/interfaces/deparment'
import { GetRequestData } from '@src/interfaces'
import { slackChannelNameToUrl } from '@src/features/CommunicationGroups/helpers'

export const parseSlackConnections = (
  externalChannels: GetRequestData<SlackDataInterface> | undefined,
) => {
  const parsedSlackData: ExternalChannelItemInterface[] = []
  const addMainChannel = (channel: SlackDataInterface) => {
    if (channel.slack_channel) {
      parsedSlackData.push({
        id: channel.slack_channel.id,
        name: 'Main channel',
        handle: channel.slack_channel.name,
        href: slackChannelNameToUrl(channel.slack_channel.name),
        type: ConnectionType.Channel,
        is_primary_group: channel.is_primary_group,
      })
    }
  }

  const addMainUserGroup = (channel: SlackDataInterface) => {
    if (channel.slack_user_group) {
      parsedSlackData.push({
        id: channel.slack_user_group.id,
        name: channel.slack_user_group.name,
        handle: channel.slack_user_group.handle,
        status: channel.slack_user_group.status,
        type: ConnectionType.Group,
        is_primary_group: channel.is_primary_group,
      })
    }
  }

  const addFunctionSlackInfo = (channel: SlackDataInterface) => {
    const nameSuffix = channel.slack_channel ? 'channel' : 'group'
    const item = {
      name: `${channel.function!.name} function ${nameSuffix}`,
      handle: channel.slack_channel?.name
        ? channel.slack_channel.name
        : channel.slack_user_group.handle,
      status: channel.slack_user_group?.status,
      id: channel.slack_channel?.id
        ? channel.slack_channel.id
        : channel.slack_user_group.id,
      type: channel.slack_channel?.id ? ConnectionType.Channel : ConnectionType.Group,
      is_primary_group: channel.is_primary_group,
    }
    parsedSlackData.push(item)
  }
  externalChannels?.results.forEach((channel: SlackDataInterface) => {
    if (channel.is_primary_group) {
      addMainChannel(channel)
      addMainUserGroup(channel)
    }

    if (channel.function) {
      addFunctionSlackInfo(channel)
    }
  })
  return parsedSlackData
}
