import { useEffect } from 'react'
import {
  GetRequestInterface,
  IdAndName,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { usePrevious } from '@revolut/ui-kit'

import { api, apiWithoutHandling, normalizeCaptchaError } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  CurrentTenantInfoInterface,
  TenantAdminInterface,
  TenantDiscountInterface,
  TenantInterface,
  TenantResponseInterface,
  TenantSubscriptionPlansInterface,
} from '@src/interfaces/tenants'
import { useFetch, useUpdate, usePost } from '@src/utils/reactQuery'
import {
  SubscriptionInfoInterface,
  SubscriptionInvoiceInterface,
} from '@src/interfaces/plans'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { FilterByInterface } from '@src/interfaces/data'
import { TenantOnboardingCheckpointInterface } from '@src/interfaces/onboardingChecklist'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { getFileNameFromHeaders } from '@src/utils/files'
import {
  setFeatureFlagsAction,
  setPermissionsAction,
  setUserAction,
} from '@src/store/auth/actions'
import { WhoAmIInterface } from '@src/interfaces/auth'

export const tenantsAdminRequests: RequestInterfaceNew<TenantAdminInterface> = {
  get: async ({ id }) => api.get(`${API.TENANTS_ADMIN}/${id}`, undefined, 'v2'),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TENANTS_ADMIN}/${id}`, data, undefined, 'v2'),
  submit: async data =>
    apiWithoutHandling
      .post(API.TENANTS_ADMIN, data, undefined, 'v2')
      .catch(normalizeCaptchaError),
}

export const tenantsRequests: RequestInterfaceNew<TenantInterface> = {
  get: async ({ id }) => api.get(`${API.TENANTS}/${id}`, undefined, 'v2'),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TENANTS}/${id}`, data, undefined, 'v2'),
  submit: async data => apiWithoutHandling.post(API.TENANTS, data, undefined, 'v2'),
}

export const useGetTenant = (id?: string) =>
  useFetch<TenantResponseInterface>(
    id ? `${API.TENANTS}/${id}` : null,
    'v2',
    undefined,
    undefined,
    {
      refetchInterval: 5000,
    },
  )

export const useCloseAccount = (id?: string) =>
  useUpdate(`${API.TENANTS}/${id}/closeAccount`, undefined, undefined, true)

export const useSuspendAccount = (id?: string) =>
  useUpdate(`${API.TENANTS}/${id}/suspend`, undefined, undefined, true)

export const useVerifyVAT = (id?: string) =>
  usePost(`${API.TENANTS}/${id}/verifyVatNumber`)

export const tenantInvoicesRequests = (
  id: string,
): TableRequestInterface<SubscriptionInvoiceInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.TENANTS}/${id}/subscriptions/invoices`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const useTenantDiscounts = (id: string) => {
  const result = useFetch<GetRequestInterface<TenantDiscountInterface>>(
    `${API.TENANTS}/${id}/discounts`,
  )
  return { discounts: result?.data?.results, ...result }
}

export const useTenantSubscriptionPlans = (id: string) => {
  const result = useFetch<GetRequestInterface<TenantSubscriptionPlansInterface>>(
    `${API.TENANTS}/${id}/availableSubscriptionPlans`,
  )
  return {
    subscriptionPlans: result?.data?.results.map(p => p.subscription_plan),
    ...result,
  }
}

export const deleteTenantDiscount = (id: string, discountId: number) =>
  apiWithoutHandling.delete(`${API.TENANTS}/${id}/discounts/${discountId}`)

export const tenantDiscountsFormRequests: RequestInterfaceNew<TenantDiscountInterface> = {
  get: async ({ id, tenantId }) => api.get(`${API.TENANTS}/${tenantId}/discounts/${id}`),
  update: async (data, { id, tenantId }) =>
    apiWithoutHandling.patch(`${API.TENANTS}/${tenantId}/discounts/${id}`, data),
  submit: async (data, { tenantId }) =>
    apiWithoutHandling.post(`${API.TENANTS}/${tenantId}/discounts`, data),
}

export const downloadTenantInvoice = async (
  id: string | number,
  invoiceId: string | number,
) => {
  const res = await api.get<Blob>(
    `${API.TENANTS}/${id}/subscriptions/invoices/${invoiceId}/download`,
    {
      responseType: 'blob',
    },
  )
  const fileName = getFileNameFromHeaders(res.headers, 'invoice.pdf')

  return { file: URL.createObjectURL(res.data), fileName: fileName || 'invoice.pdf' }
}

export const useTenantInvoiceDetails = (
  id: string | number,
  invoiceId: string | number,
) =>
  useFetch<SubscriptionInvoiceInterface>(
    `${API.TENANTS}/${id}/subscriptions/invoices/${invoiceId}`,
    undefined,
    undefined,
    true,
    {
      refetchOnWindowFocus: false,
    },
  )

export const useTenantSubscriptionInfo = (id: string | number) =>
  useFetch<SubscriptionInfoInterface>(`${API.TENANTS}/${id}/subscriptions/info`, 'v2')

export const useInvalidateTenantSubscriptionInfo = (id: string | number) => {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries([`${API.TENANTS}/${id}/subscriptions/info`, 'v2'])
  }
}

export const useTenantOnboardingProgress = (id: string | number) => {
  const response = useFetch<GetRequestInterface<TenantOnboardingCheckpointInterface>>(
    `${API.TENANTS}/${id}/onboarding`,
  )
  return response.data?.results
}
export const setTenantFeatures = (
  tenants: { id: string | number }[],
  features: IdAndName<string>[],
  mode: 'enable' | 'disable',
) => apiWithoutHandling.post(`${API.TENANTS}/${mode}Features`, { tenants, features })

export const useTenantSelector = (filters: FilterByInterface[], enabled = true) =>
  useFetch<{ options: IdAndName<string>[] }>(
    `${API.TENANTS}/selector`,
    undefined,
    { params: filterSortPageIntoQuery(undefined, filters) },
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled,
    },
  )

let isUseCurrentTenantInfoExitDemoModeCalled = false

export const useCurrentTenantInfo = (disable?: boolean) => {
  const dispatch = useDispatch()
  const featureFlags = useSelector(selectFeatureFlags)

  const result = useFetch<CurrentTenantInfoInterface>(
    `${API.TENANTS}/currentTenantInfo`,
    undefined,
    undefined,
    true,
    {
      enabled: !!featureFlags?.includes(FeatureFlags.CommercialProduct) && !disable,
      cacheTime: Infinity,
      staleTime: 1000 * 60 * 10,
    },
  )

  const isDemoMode = result.data?.state === 'demo'
  const prevIsDemoMode = usePrevious(isDemoMode)

  useEffect(() => {
    /** In demo mode the user object is not the real one, so when they exit demo mode we need to update the user object and related properties */
    if (prevIsDemoMode && !isDemoMode && !isUseCurrentTenantInfoExitDemoModeCalled) {
      /** Since hook is initialised in multiple places, we want only 1 call the the /whoami, so we have a global variable as guard */
      isUseCurrentTenantInfoExitDemoModeCalled = true

      apiWithoutHandling
        .get<WhoAmIInterface>(API.WHOAMI, undefined, undefined, true)
        .then(userData => {
          if (userData?.data?.employee) {
            dispatch(setUserAction(userData?.data?.employee))
          }
          if (userData.data?.permissions) {
            dispatch(setPermissionsAction(userData.data.permissions))
          }
          if (userData.data?.feature_flags) {
            dispatch(setFeatureFlagsAction(userData.data.feature_flags))
          }
        })
        .catch(() => {
          /** If it fails, we should reload the whole page to trigger the standard initialisation flow */
          window.location.reload()
        })
    }
  }, [prevIsDemoMode, isDemoMode])

  return { ...result.data, isDemoMode }
}

export const activateFromDemoMode = (tenantId: number | string) =>
  api.put(`${API.TENANTS}/${tenantId}/activateFromDemoMode`)
