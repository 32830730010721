import React from 'react'
import Stat from '@components/Stat/Stat'
import { TalentPerformanceStatsInterface } from '@src/interfaces/talent/performance'
import isNumber from 'lodash/isNumber'
import round from 'lodash/round'
import { GradesMapInterface } from '@src/utils/grades'
import { FinalGrade } from '@src/interfaces/performance'
import get from 'lodash/get'
import { Flex, Highlights, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'

type Props = {
  gradesMap: GradesMapInterface
  stats?: TalentPerformanceStatsInterface
}

export const AboveLimitsIconWithTooltip = ({ size }: { size: number }) => {
  const tooltip = useTooltip()

  return (
    <>
      <ExclamationTriangle
        {...tooltip.getAnchorProps()}
        size={size}
        color={Token.color.orange}
        cursor="pointer"
      />
      <Tooltip {...tooltip.getTargetProps()} placement="top" maxWidth={320}>
        The size of the group is above expectations. Please review and reassign calibrated
        grades.{' '}
      </Tooltip>
    </>
  )
}

export const TalentHeaderGrades = ({ gradesMap, stats }: Props) => {
  if (!stats?.headcount) {
    return null
  }

  const headcount = stats.headcount
  const getStatPercent = (val: number | null) => {
    return isNumber(val) ? `${round((val * 100) / headcount, 0)}%` : 'N/A'
  }

  const statsMap = Object.keys(gradesMap)
    .filter(grade => grade !== FinalGrade.Empty)
    .reverse()
    .map(grade => ({
      key: grade,
      value: getStatPercent(get(stats, grade)),
      label: gradesMap[grade],
      aboveLimit: get(stats.above_limits, grade),
    }))

  return (
    <Highlights variant="tabs" gap="s-16">
      {statsMap.map(stat => (
        <Stat
          key={stat.key}
          label={stat.label}
          val={
            <Flex gap="s-8" alignItems="center">
              {stat.value}
              {stat.aboveLimit && <AboveLimitsIconWithTooltip size={20} />}
            </Flex>
          }
        />
      ))}
    </Highlights>
  )
}
