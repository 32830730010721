import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { ApprovalFlowStep } from '@src/interfaces/approvalFlow'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import Table from '@components/TableV2/Table'

export const approvalsApproverColumn: ColumnInterface<ApprovalFlowStep> = {
  type: CellTypes.insert,
  idPoint: 'approver.id',
  dataPoint: 'approver.full_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Approver',
  insert: ({ data }) => {
    if (data.approver || data.expected_approver) {
      return <Table.EmployeeCell employee={data.approver || data.expected_approver} />
    }
    return '-'
  },
}

export const approvalsRoleColumn: ColumnInterface<ApprovalFlowStep> = {
  type: CellTypes.text,
  idPoint: 'approval_step',
  dataPoint: 'approval_step',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Role',
}
