import React, { useMemo, useRef, useState } from 'react'
import { Cell, chain, Flex, TabBar, Box, ActionButton } from '@revolut/ui-kit'
import { SwitchOff, SwitchOn } from '@revolut/icons'

import { commonDashboardSubtabs } from '@src/apps/General/Todo/common'
import { useEmployeesOverdueInboxStats } from '../Preview/ProfileSummary/common'
import Loader from '@components/CommonSC/Loader'
import { useTableReturnType } from '@components/TableV2/hooks'
import {
  NotificationsInterface,
  NotificationsStatisticsInterface,
} from '@src/interfaces/notifications'
import { EmployeeGeneralProps } from '.'

const ActionItems = ({ data }: EmployeeGeneralProps) => {
  const [selectedTab, setSelectedTab] = useState(commonDashboardSubtabs[0].title)
  const [showAllItems, setShowAllItems] = useState(false)
  const tableRef =
    useRef<
      useTableReturnType<NotificationsInterface, NotificationsStatisticsInterface, {}>
    >(null)

  const { stats, refresh } = useEmployeesOverdueInboxStats(data)

  const filterBy = useMemo(
    () => [
      {
        columnName: 'deadline',
        filters: [
          showAllItems
            ? { id: '', name: '' }
            : { id: `,${new Date().toISOString()}`, name: 'deadline' },
        ],
      },
    ],
    [showAllItems],
  )

  if (stats == null) {
    return <Loader />
  }

  const [primaryTab, ...secondaryTabs] = commonDashboardSubtabs
  const tabsToDisplay = [
    primaryTab,
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    ...secondaryTabs.filter(tab => !!stats[tab.statPath]),
  ]

  return (
    <>
      <TabBar behaviour="scroll">
        {tabsToDisplay.map(tab => (
          <TabBar.Item
            onClick={() => setSelectedTab(tab.title)}
            aria-selected={selectedTab === tab.title}
            style={{ flexShrink: 0, marginLeft: 0 }}
            key={tab.title}
          >
            {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
            {chain(tab.title, stats[tab.statPath])}
          </TabBar.Item>
        ))}
      </TabBar>
      <Box mt="s-16">
        <Cell>
          <Flex flexDirection="column" width="100%">
            <Flex mb="s-16">
              <ActionButton
                useIcon={showAllItems ? SwitchOn : SwitchOff}
                onClick={() => setShowAllItems(!showAllItems)}
              >
                Show all items
              </ActionButton>
            </Flex>
            {tabsToDisplay.map(tab => {
              if (tab.title === selectedTab) {
                return (
                  <tab.component
                    employeeId={data.id}
                    tableRef={tableRef}
                    filterBy={filterBy}
                    refreshStats={refresh}
                    key={tab.title}
                  />
                )
              }
              return null
            })}
          </Flex>
        </Cell>
      </Box>
    </>
  )
}

export default ActionItems
