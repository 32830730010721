import React from 'react'
import { useSelector } from 'react-redux'
import {
  Avatar,
  DragAndDrop,
  HStack,
  Icon,
  IconButton,
  Item,
  Token,
  VStack,
} from '@revolut/ui-kit'

import {
  getQuestionDescription,
  getQuestionItemAvatarIcon,
} from '@src/apps/People/Engagement/helpers'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

interface Props {
  question: EngagementQuestionInterface
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  onEdit?: VoidFunction
  onDelete?: VoidFunction
}
export const QuestionItem = ({ question, onDelete, sortable, onEdit }: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)

  return (
    <VStack>
      <Item
        p="s-8"
        ref={sortable?.setNodeRef}
        {...sortable?.attributes}
        {...sortable?.listeners}
        style={{
          border: `1px solid ${Token.color.greyTone10}`,
          ...(sortable
            ? {
                transform: sortable.transform
                  ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                  : undefined,
                transition: sortable.transition || 'none',
                opacity: sortable.isDragging ? 0 : undefined,
                zIndex: 10,
              }
            : undefined),
        }}
      >
        <Item.Prefix>
          <Icon name="Drag" color={Token.color.greyTone50} />
        </Item.Prefix>
        <Item.Avatar>
          <Avatar
            useIcon={getQuestionItemAvatarIcon(question.type?.id, isEngagementV2)}
          />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{question.question_text}</Item.Title>
          <Item.Description>
            {getQuestionDescription(question, isEngagementV2)}
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <HStack space="s-2">
            {onEdit && (
              <IconButton
                aria-label="Edit question"
                variant="default"
                useIcon="Pencil"
                color={Token.color.greyTone10}
                onClick={onEdit}
              />
            )}
            <IconButton
              aria-label="Delete question"
              variant="default"
              useIcon="Delete"
              color={Token.color.greyTone10}
              onClick={onDelete}
            />
          </HStack>
        </Item.Side>
      </Item>
    </VStack>
  )
}
