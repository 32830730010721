import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { RowInterface } from '@src/interfaces/data'
import { dragIconColumn } from '@src/constants/columns/ordering'
import {
  hiringProcessActionColumn,
  hiringProcessDurationColumn,
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSeniorityColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockSpecialisationsColumn,
  hiringProcessTypeColumn,
} from '@src/constants/columns/hiringProcess'
import HiringStageDeleteButton from '@src/pages/Forms/RoleForm/HiringProcess/HiringStageDeleteButton'
import React from 'react'
import { Icon, Token } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'

export const hiringProcessTableRow = (
  handleDelete?: (data: HiringProcessInterface) => Promise<void>,
  handleOrderChange?: (data: HiringProcessInterface[]) => void,
  orderingDisabled?: boolean,
): RowInterface<HiringProcessInterface> => ({
  cells: [
    handleOrderChange
      ? {
          ...dragIconColumn,
          insert: () => (
            <Tooltip
              placement="top"
              text="Reset filters to enable ordering"
              hide={!orderingDisabled}
            >
              <Icon name="Drag" color={Token.color.grey20} />
            </Tooltip>
          ),
        }
      : null,
    {
      ...hiringProcessTitleWithLockSpecialisationsColumn,
      width: 185,
    },
    {
      ...hiringProcessTypeColumn,
      width: 125,
    },
    {
      ...hiringProcessDurationColumn,
      width: 100,
    },
    {
      ...hiringProcessSeniorityColumn,
      width: 106,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 200,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 90,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 139,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 240,
    },
    handleDelete
      ? {
          ...hiringProcessActionColumn,
          width: 100,
          insert: ({ data }: { data: HiringProcessInterface }) => (
            <HiringStageDeleteButton onConfirm={() => handleDelete(data)} />
          ),
        }
      : null,
  ].filter(Boolean),
})
