import React from 'react'
import { Box } from '@revolut/ui-kit'
import { CareersPositionLocation } from '@src/interfaces/careers'
import { CareersPositionHeader } from '@src/pages/Careers/CareersPosition/CareersPositionHeader'
import { CareersPositionBody } from '@src/pages/Careers/CareersPosition/CareersPositionBody'

export interface CareersPositionContentProps {
  text: string
  locations: CareersPositionLocation[]
  id: string
  description: string
  disabledApplyButton?: boolean
  video?: string
}

export const CareersPositionContent = (props: CareersPositionContentProps) => {
  return (
    <Box pb="s-56">
      <CareersPositionHeader
        text={props.text}
        locationProps={{ locations: props.locations }}
      />
      <CareersPositionBody {...props} />
    </Box>
  )
}
