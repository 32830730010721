import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Header,
  MoreBarSkeleton,
  Side,
  Skeleton,
  VStack,
} from '@revolut/ui-kit'
import {
  SchedulingEmailPreviewInterface,
  SchedulingType,
  ScheduleInterviewInterface,
} from '@src/interfaces/interviewTool'
import { getSchedulingEmailPreview } from '@src/api/recruitment/interviewerScheduling'
import {
  getTimeZoneId,
  getUtcCustomDate,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import ScheduleSidebarEmail from './ScheduleSidebarEmailForm'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { useGetCandidateSettings } from '@src/api/settings'
import { CandidateSettingsInterface } from '@src/interfaces/settings'
import { useExtensionApiContext } from '@src/utils/extension'

type Props = {
  roundId: number
  onBack: () => void
  onSubmit: () => void
  schedulingType: SchedulingType
  isPrepCall?: boolean
  noInitialFetching?: boolean
}

const getSchedulingTemplateByType = (
  schedulingType: SchedulingType,
  isPrepCall?: boolean,
  candidateSettings?: CandidateSettingsInterface,
) => {
  if (!candidateSettings) {
    return undefined
  }

  if (isPrepCall) {
    return candidateSettings.default_adhoc_call_email_template
  }

  if (
    schedulingType === 'classic_scheduling' ||
    schedulingType === 'dynamic_scheduling'
  ) {
    return candidateSettings.default_automatic_scheduling_email_template
  }

  return candidateSettings.default_custom_scheduling_email_template
}

const EmailScheduleSidebar = ({
  roundId,
  onBack,
  onSubmit,
  schedulingType,
  isPrepCall,
  noInitialFetching,
}: Props) => {
  const { values } = useLapeContext<ScheduleInterviewInterface>()
  const { data: candidateSettings } = useGetCandidateSettings()
  const mounted = useRef(false)
  const apiHandler = useExtensionApiContext()

  const timeZoneId = getTimeZoneId(values.scheduling_timezone)
  const utcCustomDate = getUtcCustomDate(timeZoneId, values.custom_date)
  const [emailPreview, setEmailPreview] = useState<SchedulingEmailPreviewInterface>()
  const [isLoadingEmailPreview, setLoadingEmailPreview] = useState(false)

  useEffect(() => {
    const template = getSchedulingTemplateByType(
      schedulingType,
      isPrepCall,
      candidateSettings,
    )

    if (template && !values.email_template) {
      values.email_template = template
    }
  }, [schedulingType, isPrepCall, candidateSettings])

  useEffect(() => {
    const fetchEmail = async () => {
      if (!values.interview_stage?.id || !values.email_template?.id) {
        return
      }

      setLoadingEmailPreview(true)

      try {
        const resp = await getSchedulingEmailPreview(
          values.interview_stage.id,
          roundId,
          schedulingType,
          utcCustomDate,
          timeZoneId,
          values.email_template.id,
          values.duration,
          values.duration_unit?.id,
          apiHandler,
        )

        setEmailPreview(resp.data)
      } finally {
        setLoadingEmailPreview(false)
      }
    }

    if (!noInitialFetching || mounted.current) {
      fetchEmail()
    }

    mounted.current = true
  }, [
    noInitialFetching,
    values.email_template,
    values.interview_stage,
    roundId,
    schedulingType,
    utcCustomDate,
    timeZoneId,
    values.duration,
    values.duration_unit,
  ])

  const canSendInvite = !!values.subject && !!values.email_body

  return (
    <>
      <Header variant="item">
        <Header.BackButton aria-label="Back" onClick={onBack} />
        <Header.Title aria-label="sidebar-title">Email to send</Header.Title>
      </Header>
      <Box>
        {isLoadingEmailPreview ? (
          <VStack gap="s-16">
            <Skeleton height="56px" borderRadius={8} />
            <Skeleton height="56px" borderRadius={8} />
            <MoreBarSkeleton />
            <Skeleton height="56px" borderRadius={8} />
            <Skeleton height="200px" borderRadius={8} />
          </VStack>
        ) : (
          <ScheduleSidebarEmail email={emailPreview} />
        )}
      </Box>
      <Side.Actions>
        <Button elevated onClick={onSubmit} disabled={!canSendInvite}>
          Continue
        </Button>
      </Side.Actions>
    </>
  )
}

export default connect(EmailScheduleSidebar)
