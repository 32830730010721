import * as React from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Item,
  Skeleton,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
  ReviewCycleStage,
} from '@src/interfaces/reviewCycles'
import { CycleTimeline } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/CycleTimeline'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { ProgressWidgetSkeleton } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/ProgressWidget/ProgressWidget'
import { StageWidgetSkeleton } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/StageWidget'

interface Props {
  cycle: ReviewCyclesInterface
  stages: ReviewCycleStage[]
  eligibleGroups: EligibleGroupInterface[]
  settings?: PerformanceSettingsInterface
  isLoading: boolean
}

export const CycleStages = ({
  cycle,
  stages,
  eligibleGroups,
  settings,
  isLoading,
}: Props) => {
  const editStagePath = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.TIMELINE, { id: cycle.id })

  if (isLoading) {
    return <CycleStagesSkeleton />
  }

  return (
    <Widget>
      <VStack space="s-8">
        <Item>
          <Item.Avatar>
            <Avatar useIcon="TurboTransfer" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Cycle stages</Item.Title>
            <Item.Description>Manage cycles stages and notifications</Item.Description>
          </Item.Content>
          <Item.Side>
            <ActionButton to={editStagePath} use={InternalLink}>
              Edit
            </ActionButton>
          </Item.Side>
        </Item>
        <Box mx="s-8" mb="s-8">
          <CycleTimeline
            cycle={cycle}
            stages={stages}
            eligibleGroups={eligibleGroups}
            settings={settings}
          />
        </Box>
      </VStack>
    </Widget>
  )
}

const CycleStagesSkeleton = () => (
  <Skeleton height="auto" radius="widget">
    <VStack width="100%" space="s-8" p="s-8">
      <ProgressWidgetSkeleton />
      <StageWidgetSkeleton />
    </VStack>
  </Skeleton>
)
