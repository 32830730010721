import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const useEmailNotificationsEnabled = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  return isCommercial
    ? featureFlags?.includes(FeatureFlags.TemplatedEmailNotifications)
    : true
}
