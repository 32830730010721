import React from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { Actions } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSummary/Actions/Actions'
import { StatusTag } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleSummary/StatusTag'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

interface Props {
  cycle: ReviewCyclesInterface
}

export const CycleSummary = ({ cycle }: Props) => {
  const { name, status } = cycle

  return (
    <PageHeader.Title
      useIcon="DocumentsPair"
      title={name}
      labels={status && <StatusTag status={status} />}
      actions={<Actions cycle={cycle} />}
    />
  )
}
