import React from 'react'

import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import {
  adpReportsExportTableRequests,
  adpReportsFtpUploadTableRequests,
} from '@src/api/payroll'
import Table from '@src/components/TableV2/Table'
import {
  payCycleAdpChangeReportsEndDateTableColumn,
  payCycleAdpChangeReportsPayGroupTableColumn,
  payCycleAdpChangeReportsRequestedByTableColumn,
  payCycleAdpChangeReportsStartDateTableColumn,
  payCycleAdpChangeReportsStatusTableColumn,
  payCycleAdpChangeReportsTypeTableColumn,
} from '@src/constants/columns/payCycleReports'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { AdpReportInterface } from '@src/interfaces/payroll'
import { pathToUrl } from '@src/utils/router'

const rows = {
  cells: [
    {
      ...payCycleAdpChangeReportsPayGroupTableColumn,
      width: 180,
    },
    {
      ...payCycleAdpChangeReportsTypeTableColumn,
      width: 120,
    },
    {
      ...payCycleAdpChangeReportsStartDateTableColumn,
      width: 100,
    },
    {
      ...payCycleAdpChangeReportsEndDateTableColumn,
      width: 100,
    },
    {
      ...payCycleAdpChangeReportsRequestedByTableColumn,
      width: 120,
    },
    {
      ...payCycleAdpChangeReportsStatusTableColumn,
      width: 120,
    },
  ],
}

interface Props {
  id: string
  action: string
}
export const DownloadReportsTable = ({ action, id }: Props) => {
  const table = useTable<AdpReportInterface>(
    action === 'download'
      ? adpReportsExportTableRequests(id)
      : adpReportsFtpUploadTableRequests(id),
  )

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<AdpReportInterface>
          dataType="Report"
          name={TableNames.AdpChangeReports}
          noDataMessage="Reports will appear here."
          onRowClick={(row: { id: number }) => {
            navigateTo(
              pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLES_DOWNLOAD_REPORT, {
                action,
                id,
                requestId: row.id,
              }),
            )
          }}
          row={rows}
          useWindowScroll
          {...table}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
