import { MoreBar } from '@revolut/ui-kit'
import React, { useState } from 'react'

import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import {
  engagementSurveyFormRequests,
  engagementSurveyHistoryRequests,
  testEngagementSurvey,
} from '@src/api/engagement'
import { HistoryActions } from '@src/apps/People/Engagement/Survey/Preview/HistoryActions'
import { StartSurveyForm } from '@src/apps/People/Engagement/Survey/Preview/StartSurveyForm'
import Table from '@src/components/TableV2/Table'
import {
  surveyHistoryActionsColumn,
  surveyHistoryAudienceSizeColumn,
  surveyHistoryParticipationRate,
  surveyHistoryQuestionsNumColumn,
  surveyHistorySentByColumn,
  surveyHistorySentOnColumn,
  surveyHistoryStatusColumn,
} from '@src/constants/columns/engagementSurveyHistory'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RowInterface } from '@src/interfaces/data'
import {
  EngagementSurveyInterface,
  EngagementSurveyRoundInterface,
} from '@src/interfaces/engagement'
import { pathToUrl } from '@src/utils/router'

const getRows = (refetch: () => void): RowInterface<EngagementSurveyRoundInterface> => ({
  cells: [
    {
      ...surveyHistorySentOnColumn,
      width: 200,
    },
    {
      ...surveyHistorySentByColumn,
      width: 200,
    },
    {
      ...surveyHistoryQuestionsNumColumn,
      width: 200,
    },
    {
      ...surveyHistoryAudienceSizeColumn,
      width: 200,
    },
    {
      ...surveyHistoryParticipationRate,
      width: 200,
    },
    {
      ...surveyHistoryStatusColumn,
      width: 200,
    },
    {
      ...surveyHistoryActionsColumn,
      width: 220,
      insert: ({ data }) => <HistoryActions data={data} refetch={refetch} />,
    },
  ],
})

export const SurveyHistory = () => {
  const { initialValues, values } = useLapeContext<EngagementSurveyInterface>()
  const table = useTable<EngagementSurveyRoundInterface>(
    engagementSurveyHistoryRequests(initialValues.id!),
    [
      {
        columnName: 'is_test',
        filters: [{ id: 'false', name: 'false' }],
        nonResettable: true,
      },
    ],
  )

  const [isTestPending, setIsTestPending] = useState(false)
  const [isStartSubmitOpen, setIsStartSubmitOpen] = useState(false)

  const updateSurveyStatus = () => {
    engagementSurveyFormRequests
      .get({ id: String(initialValues.id) })
      .then(({ data }) => {
        values.status = data.status
      })
  }
  const refetch = () => {
    updateSurveyStatus()
    table.refresh()
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <MoreBar.Action
              useIcon="SendMessage"
              variant="primary"
              onClick={() => setIsStartSubmitOpen(true)}
            >
              Send
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="Bug"
              pending={isTestPending}
              onClick={async () => {
                setIsTestPending(true)
                try {
                  const { data } = await testEngagementSurvey(initialValues.id!)
                  navigateTo(
                    `${pathToUrl(ROUTES.ENGAGEMENT_PAGE, undefined, {
                      survey_id: String(data.id),
                      is_test: 'true',
                    })}`,
                  )
                } finally {
                  setIsTestPending(false)
                }
              }}
            >
              Test
            </MoreBar.Action>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable<EngagementSurveyRoundInterface>
            name={TableNames.EngagementSurveyHistory}
            useWindowScroll
            row={getRows(refetch)}
            {...table}
            noDataMessage="Survey history will appear here"
          />
        </Table.Widget.Table>
      </Table.Widget>
      <StartSurveyForm
        surveyStatus={values.status}
        surveyId={initialValues.id!}
        isOpen={isStartSubmitOpen}
        onClose={() => setIsStartSubmitOpen(false)}
        refetch={refetch}
      />
    </>
  )
}
