import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { Virtuoso } from 'react-virtuoso'
import React, { RefObject } from 'react'
import { PopupState } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/constants'
import { notReachable } from '@src/utils/notReachable'
import { useNavigation } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useNavigation'
import { useInvalidEligibilityObjects } from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/hooks/useInvalidEligibilityObjects'
import {
  InvalidEligibilityItemsSkeleton,
  EligibleGroupItemContext,
  InvalidEligibilityItemProps,
  renderEligibilityGroupItem,
  renderInvalidEligibilityItem,
  EligibilityGroupItem,
  InvalidEligibilityItem,
} from '@src/features/ReviewCycle/PerformanceReviewCycle/CycleStages/Timeline/StageWidget/Popups/components/EligibilityList/ListItems'
import { useScrollParent } from '@src/features/ReviewCycle/PerformanceReviewCycle/hooks/useScrollParent'
import { Group } from '@revolut/ui-kit'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
  state: PopupState
  scrollRef?: RefObject<HTMLDivElement>
}

const virtualizationThreshold = 10

export const PopupBody = ({ cycle, eligibleGroups, state, scrollRef }: Props) => {
  const { toEditEligibleEmployees } = useNavigation()
  const scrollParent = useScrollParent<HTMLDivElement>(scrollRef)

  switch (state) {
    case PopupState.Default:
      return (
        <DefaultState
          eligibleGroups={eligibleGroups}
          scrollParent={scrollParent}
          onItemActionClick={() =>
            toEditEligibleEmployees(cycle, undefined, { blank: true })()
          }
        />
      )
    case PopupState.Valid:
      return <ValidState eligibleGroups={eligibleGroups} scrollParent={scrollParent} />
    case PopupState.Invalid:
      return <InvalidState cycleId={cycle.id} scrollParent={scrollParent} />

    default:
      return notReachable(state)
  }
}

interface ListStateProps {
  scrollParent?: HTMLDivElement
}

interface DefaultStateProps extends ListStateProps {
  eligibleGroups: EligibleGroupInterface[]
  onItemActionClick: VoidFunction
}

const DefaultState = ({
  eligibleGroups,
  scrollParent,
  onItemActionClick,
}: DefaultStateProps) => {
  if (eligibleGroups.length < virtualizationThreshold) {
    return (
      <Group style={transparentThemeBackgroundOverrides}>
        {eligibleGroups.map(group => (
          <EligibilityGroupItem
            key={group.id}
            {...group}
            onItemActionClick={onItemActionClick}
          />
        ))}
      </Group>
    )
  }

  return (
    <Virtuoso<EligibleGroupInterface, EligibleGroupItemContext>
      overscan={10}
      height={500}
      data={eligibleGroups}
      itemContent={(index, props) =>
        renderEligibilityGroupItem(index, props, {
          onItemActionClick,
        })
      }
      customScrollParent={scrollParent}
    />
  )
}

interface ValidStateProps extends ListStateProps {
  eligibleGroups: EligibleGroupInterface[]
}

const ValidState = ({ eligibleGroups, scrollParent }: ValidStateProps) => {
  if (eligibleGroups.length < virtualizationThreshold) {
    return (
      <Group style={transparentThemeBackgroundOverrides}>
        {eligibleGroups.map(group => (
          <EligibilityGroupItem key={group.id} {...group} isValid />
        ))}
      </Group>
    )
  }

  return (
    <Virtuoso<EligibleGroupInterface, EligibleGroupItemContext>
      overscan={10}
      height={500}
      data={eligibleGroups}
      itemContent={(index, props) =>
        renderEligibilityGroupItem(index, props, { isValid: true })
      }
      customScrollParent={scrollParent}
    />
  )
}

interface InvalidStateProps extends ListStateProps {
  cycleId: number | string
}

export const InvalidState = ({ cycleId, scrollParent }: InvalidStateProps) => {
  const { itemProps, isLoading, handleFetchMore } = useInvalidEligibilityObjects(cycleId)

  if (isLoading) {
    return <InvalidEligibilityItemsSkeleton />
  }

  if (itemProps.length < virtualizationThreshold) {
    return (
      <Group style={transparentThemeBackgroundOverrides}>
        {itemProps.map(({ key, ...props }) => (
          <InvalidEligibilityItem key={key} {...props} />
        ))}
      </Group>
    )
  }

  return (
    <Virtuoso<InvalidEligibilityItemProps>
      overscan={10}
      height={500}
      data={itemProps}
      itemContent={(index, props) => renderInvalidEligibilityItem(index, props)}
      customScrollParent={scrollParent}
      endReached={handleFetchMore}
    />
  )
}
