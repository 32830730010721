import { Grid } from '@components/CommonSC/Grid'
import { getTimeZoneLabel } from '@src/utils/timezones'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import React, { useEffect } from 'react'
import NewRadio from '@components/Inputs/NewRadio/NewRadio'
import { decl } from '@src/utils/string'
import { useGroupByDayPotentialSlots } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks'
import { useGetCustomPotentialInterviewSlots } from '@src/api/recruitment/interviewerScheduling'
import compact from 'lodash/compact'
import InterviewSlotsSkeleton from '@src/pages/Forms/Candidate/ScheduleSidebar/InterviewSlotsSkeleton'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { ActionButton, Box, Cell, Color, Flex, RadioGroup, Text } from '@revolut/ui-kit'
import {
  dateToCustomDate,
  getTimeZoneId,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getAvatarUrl } from '@src/utils/employees'
import { ScheduleInterviewInterface } from '@src/interfaces/interviewTool'
import { getDurationPayload } from '@src/pages/Forms/Candidate/ScheduleSidebar/normalizers/getDurationPayload'

type Props = {
  roundId: number
  showNonClashing: boolean
  customSlot?: string
  onChangeCustomSlot: (date?: string) => void
  onClose: () => void
}

const PotentialSlots = ({
  roundId,
  showNonClashing,
  customSlot,
  onChangeCustomSlot,
  onClose,
}: Props) => {
  const { values } = useLapeContext<ScheduleInterviewInterface>()

  const timeZoneId = getTimeZoneId(values.scheduling_timezone)

  const { data: slots, isLoading: isLoadingCustomSlots } =
    useGetCustomPotentialInterviewSlots(
      values.interview_stage?.id,
      roundId,
      compact([values.interviewer, ...(values.additional_interviewers || [])]),
      showNonClashing,
      getDurationPayload(values.duration, values.duration_unit?.id),
      values.duration_unit?.id,
    )

  const groupedSlots = useGroupByDayPotentialSlots(timeZoneId, slots)

  const handleCustomSlotChange = (value: string | null) => {
    if (value && slots?.find(slot => slot.slot_start === value)) {
      onChangeCustomSlot(value)
      values.custom_date = dateToCustomDate(value, timeZoneId)
      return
    }

    values.custom_date = {
      day: undefined,
      time: undefined,
    }
  }

  useEffect(() => {
    if (!isLoadingCustomSlots) {
      handleCustomSlotChange(customSlot || null)
    }
  }, [
    customSlot,
    timeZoneId,
    showNonClashing,
    values.interviewer,
    values.additional_interviewers,
    isLoadingCustomSlots,
  ])

  if (isLoadingCustomSlots) {
    return <InterviewSlotsSkeleton />
  }

  if (Array.isArray(slots) && slots.length === 0) {
    return (
      <ActionWidget
        mt="s-16"
        title="No slots available"
        text="No custom slots are available for selected interviewers"
      >
        <ActionButton onClick={onClose}>Schedule manually</ActionButton>
      </ActionWidget>
    )
  }

  return (
    <RadioGroup<string> onChange={handleCustomSlotChange} value={customSlot}>
      {group => (
        <>
          {groupedSlots.map(groupedSlot => (
            <Cell mt="s-16" key={groupedSlot.id}>
              <Grid flow="row" gap={16} width="100%">
                <Box>
                  <Flex justifyContent="space-between">
                    <Text fontWeight={500}>{groupedSlot.label}</Text>
                    <Text color={Color.GREY_TONE_50}>
                      {groupedSlot.items.length}{' '}
                      {decl(groupedSlot.items.length, 'slot', 'slots')}
                    </Text>
                  </Flex>
                </Box>

                {groupedSlot.items?.length &&
                  groupedSlot.items.map(slot => (
                    <Box key={slot.slot_start}>
                      <Flex justifyContent="space-between">
                        <NewRadio
                          label={getTimeZoneLabel(slot.slot_start, timeZoneId)}
                          {...group.getInputProps({ value: slot.slot_start })}
                          data-testid={`slot-${slot.slot_start}`}
                        />
                        <Flex>
                          {slot.free_interviewers.map(employee => (
                            <UserWithAvatar
                              id={employee.id}
                              full_name={employee.full_name}
                              avatar={getAvatarUrl(employee.avatar)}
                              tooltipPlacement="top"
                              compact
                              key={employee.id}
                              ml="s-8"
                            />
                          ))}
                        </Flex>
                      </Flex>
                    </Box>
                  ))}
              </Grid>
            </Cell>
          ))}
        </>
      )}
    </RadioGroup>
  )
}

export default PotentialSlots
