import Quill from 'quill'
import { Token } from '@revolut/ui-kit'

import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getAvatarUrl } from '@src/utils/employees'
import { LocalStorageKeys } from '@src/store/auth/types'

const InlineBlot = Quill.import('blots/inline')

export const TAG_CLASS = 'comment_tag'

export type TagValue = {
  id: number
  name: string
  avatar: EmployeeOptionInterface['avatar']
  jobTitle?: string
}

export interface TagBlotOptions {
  onCreate(node: HTMLAnchorElement): void
  onDelete(): void
}

export const getTagValueFromNode = (node: HTMLAnchorElement): TagValue => {
  return {
    id: Number(node.dataset.userid) || 0,
    name: node.dataset.username || '',
    avatar: getAvatarUrl(node.dataset.avatar),
    jobTitle: node.dataset.userrole,
  }
}

export const setTagValueToNode = (node: HTMLAnchorElement, tagValue: TagValue) => {
  node.dataset.userid = `${tagValue.id}`
  node.dataset.username = tagValue.name
  node.dataset.avatar = getAvatarUrl(tagValue.avatar) || ''
  node.dataset.userrole = tagValue.jobTitle
}

export const initializeTagBlot = ({ onCreate, onDelete }: TagBlotOptions) => {
  class TagBlot extends InlineBlot {
    static create(value: TagValue) {
      const node: HTMLAnchorElement = super.create()

      // Add identifiers
      node.classList.add(TAG_CLASS)

      // Add link attributes
      node.setAttribute('target', '_blank')

      // Style and content
      if (value.name) {
        node.innerHTML = `@${value.name.replaceAll(' ', '.')}`
        setTagValueToNode(node, value)

        const activeWorkspace = localStorage.getItem(LocalStorageKeys.ACTIVE_WORKSPACE)
        const url = pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: value.id })
        node.setAttribute('href', activeWorkspace ? `/${activeWorkspace}${url}` : url)
      }
      node.style.color = Token.color.accent
      node.style.textDecoration = 'none'
      node.style.cursor = 'pointer'

      /** If it's inside a table or any other container that has a click handler */
      node.onclick = e => e.stopPropagation()

      // To make sure node is already rendered
      setTimeout(() => onCreate(node), 0)

      return node
    }

    deleteAt(...args: unknown[]) {
      onDelete()
      super.deleteAt(...args)
    }
  }

  TagBlot.blotName = 'tag'
  TagBlot.className = TAG_CLASS
  TagBlot.tagName = 'A'

  Quill.register(TagBlot, true)
}

export default initializeTagBlot
