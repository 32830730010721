import React, { useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'
import { Cell, Label, Legend, Pie, PieChart } from 'recharts'
import type { PolarViewBox } from 'recharts/types/util/types'
import { Absolute, Token, Relative } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import { ChartSkeleton } from '@src/pages/Forms/QueryForm/components/Charts/ChartSkeleton'
import { ResponsiveContainer } from '@src/pages/Forms/QueryForm/components/Charts/ResponsiveContainer'
import { getSecondaryShapeColor } from '@src/pages/Forms/QueryForm/components/Charts/helpers'
import { PerformanceLegend } from '@src/features/ReviewCycle/Analytics/Charts/PerformancePieChart/PerformanceLegend'
import { PerformanceItemData } from '@src/features/ReviewCycle/Analytics/Charts/PerformancePieChart/PerformanceItems'

const END_ANGLE = -40
const START_ANGLE = 220
const INNER_CIRCLE_SIZE = '90%'
const OUTER_CIRCLE_SIZE = '100%'
const CORNER_RADIUS = 50
const PADDING_ANGLE = 3
const ANIMATION_DURATION = 500

interface Props {
  total: number
  data: PerformanceItemData[]
  isLoading: boolean
  label: string
}

export const PerformancePieChart = ({ data, isLoading, label, total }: Props) => {
  const { ref, width, height } = useResizeObserver<HTMLDivElement>()
  const fallbackHeight = height || 300

  const pieChartCells = useMemo(() => {
    return data.map(({ color }, index) => {
      return (
        <Cell
          key={`outer-cell-${index}`}
          fill={getSecondaryShapeColor(color)}
          stroke={Token.color.widgetBackground}
          style={{ outline: 'none' }}
        />
      )
    })
  }, [data])

  if (isLoading) {
    return <ChartSkeleton height={fallbackHeight} />
  }

  return (
    <Relative
      ref={ref}
      height="100%"
      width="100%"
      data-testid="performance_analytics_pie_chart"
    >
      <Absolute bottom={0} left={0} right={0} top={0}>
        {height ? (
          <ResponsiveContainer height={height} width="100%">
            <PieChart width={width} height={height}>
              <Pie
                cx="50%"
                cy="60%"
                data={data}
                dataKey="value"
                innerRadius={INNER_CIRCLE_SIZE}
                outerRadius={OUTER_CIRCLE_SIZE}
                startAngle={START_ANGLE}
                endAngle={END_ANGLE}
                cornerRadius={CORNER_RADIUS}
                paddingAngle={PADDING_ANGLE}
                animationDuration={ANIMATION_DURATION}
              >
                {pieChartCells}
                <Label
                  content={props => {
                    const viewBox = (props.viewBox || {}) as PolarViewBox
                    const cx = Number(viewBox.cx || 0)
                    const cy = Number(viewBox.cy || 0)

                    const positioningProps = {
                      x: cx,
                      y: cy,
                      textAnchor: 'middle',
                    }

                    return (
                      <text pointerEvents="none">
                        {label && (
                          <tspan
                            {...positioningProps}
                            dy="-3em"
                            fill={Token.color.foreground}
                            fontSize={Token.fontSize.emphasis1}
                          >
                            {label}
                          </tspan>
                        )}
                        <tspan
                          {...positioningProps}
                          dy="0.2em"
                          fill={Token.color.foreground}
                          fontSize={56}
                          fontWeight={700}
                        >
                          {formatPercentage(total)}
                        </tspan>
                      </text>
                    )
                  }}
                />
              </Pie>
              <Legend content={props => <PerformanceLegend {...props} />} />
            </PieChart>
          </ResponsiveContainer>
        ) : null}
      </Absolute>
    </Relative>
  )
}
