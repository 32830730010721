import React from 'react'
import { useSelector } from 'react-redux'

import { DepartmentInterface } from '@src/interfaces/deparment'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResultsPublished'
import { SurveryResultsTabPublished as SurveyResultsPublishedV2 } from '@src/pages/Performance/Engagement/components/v2/ResultsTab/Published'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { toIdAndName } from '@src/utils/toIdAndName'

export const Engagement = ({ data }: { data: DepartmentInterface }) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)

  if (isEngagementV2) {
    return (
      <SurveyResultsPublishedV2
        scopeFilters={[
          { columnName: 'department', filters: [toIdAndName(String(data.id))] },
        ]}
      />
    )
  }
  return (
    <SurveyResultsPublished
      entity={{
        type: EngagementResultsScope.Departments,
        id: data.id,
        permissions: data.field_options.permissions,
      }}
    />
  )
}
