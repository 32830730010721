import { getCalibrationFilters } from '@src/features/Calibration/getCalibrationFilters'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { useShowRanking } from '../utils'
import { getTalentTypes, TalentType } from '@src/interfaces/talent/talent'
import { useMemo } from 'react'

export const useCalibrationInitialOptions = ({
  talentType,
  talentId,
  cycleId,
}: {
  talentId: number
  cycleId: number
  talentType: TalentType
}) => {
  const { entity } = useOrgEntity()
  const showRanking = useShowRanking(talentType)
  const TalentTypeOptions = getTalentTypes(talentId, true)
  const { filter } = TalentTypeOptions[talentType]

  const { initialFilters, initialSortBy } = useMemo(() => {
    const categoryFilter = showRanking
      ? {
          filters: [{ name: `${talentType}`, id: talentType }],
          columnName: 'category',
          nonResettable: true,
        }
      : undefined
    const sortBy = [
      ...(showRanking
        ? [
            {
              sortBy: 'function_owner_grade_suggestion__value',
              direction: SORT_DIRECTION.ASC,
              nonResettable: true,
            },
            {
              sortBy: 'ranking_score',
              direction: SORT_DIRECTION.ASC,
              nonResettable: true,
            },
          ]
        : [
            {
              sortBy: 'absolute_rating_score__value',
              direction: SORT_DIRECTION.ASC,
              nonResettable: true,
            },
          ]),
    ]
    const filters: FilterByInterface[] = [
      filter,
      categoryFilter,
      {
        filters: [{ name: `${cycleId}`, id: cycleId }],
        columnName: 'cycle__id',
        nonResettable: true,
      },
      ...getCalibrationFilters(entity),
    ].filter(Boolean)

    return { initialFilters: filters, initialSortBy: sortBy }
  }, [showRanking, entity, talentType])

  return { initialFilters, initialSortBy }
}
