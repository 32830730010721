import * as React from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import { useFormValidator } from '@src/features/Form/FormValidator'
import HTMLEditor, {
  DEFAULT_MODULES,
  HTMLEditorProps,
} from '@components/HTMLEditor/HTMLEditor'

interface Props extends Omit<HTMLEditorProps, 'value' | 'onChange' | 'label'> {
  name: string
  value?: string
  disabled?: boolean
  onChange?: (val?: string) => void
  onAfterChange?: (value?: string) => void
  /** Marks field as optional otherwise */
  required?: boolean
  /** Hides the optional text for display only fields */
  hideOptional?: boolean
}

const LapeHTMLEditor = ({
  name,
  hideOptional,
  onAfterChange = () => {},
  resetEmptyContent,
  ...props
}: Props) => {
  const lapeProps = useLapeField(name)
  const formValidator = useFormValidator()
  const { value, onChange, hidden } = lapeProps

  if (hidden) {
    return null
  }

  return (
    <HTMLEditor
      onChange={(val?: string) => {
        const newValue = resetEmptyContent ? val ?? undefined : val || undefined
        onChange(newValue)
        onAfterChange(newValue)
      }}
      value={value !== undefined ? value : ''}
      modules={DEFAULT_MODULES}
      resetEmptyContent={resetEmptyContent}
      {...props}
      {...getCommonInputProps(
        { ...props, hideOptional, name },
        lapeProps,
        !!formValidator?.validated,
      )}
    />
  )
}

export default connect(LapeHTMLEditor)
