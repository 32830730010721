import React, { useMemo, useRef, useState } from 'react'
import pluralize from 'pluralize'
import { connect } from 'lape'
import {
  Avatar,
  FilterButton,
  MoreBar,
  Spinner,
  Subheader,
  TableWidget,
  Token,
  VStack,
} from '@revolut/ui-kit'

import {
  documentsBulkRequestActivate,
  documentsBulkRequestFormRequests,
  documentsBulkRequestTriggerValidation,
  documentsBulkRequestUnassignEmployee,
  getDocumentsBulkRequestEligibleEmployeesTableRequests,
} from '@src/api/documents'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  DocumentsBulkRequestEligibleEmployeeInterface,
  DocumentsBulkRequestEligibleEmployeesStatsInterface,
  DocumentsBulkRequestInterface,
} from '@src/interfaces/documents'
import {
  documentsBulkRequestEligibleEmployeeNameColumn,
  documentsBulkRequestEligibleEmployeeCountryColumn,
  documentsBulkRequestEligibleEmployeeDepartmentColumn,
  documentsBulkRequestEligibleEmployeeSeniorityColumn,
  documentsBulkRequestEmployeeValidationStatusColumn,
} from '@src/constants/columns/documentsBulkRequestEligibleEmployees'
import Stat from '@components/Stat/Stat'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import SelectTableWrapper, {
  SelectionControls,
  SelectTableWrapperOnChangeData,
} from '@components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import { getSelectCellConfig } from '@components/TableV2/AdvancedCells/SelectCell/SelectCell'
import { navigateReplace } from '@src/actions/RouterActions'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useRefreshRequestStatus } from '../common'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useFilterToggle } from '@src/hooks/useFilterToggle'

const getRow = (
  canEdit: boolean,
): RowInterface<DocumentsBulkRequestEligibleEmployeeInterface> => ({
  cells: [
    canEdit
      ? {
          ...getSelectCellConfig(),
        }
      : null,
    {
      ...documentsBulkRequestEligibleEmployeeNameColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeCountryColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeDepartmentColumn,
      width: 200,
    },
    {
      ...documentsBulkRequestEligibleEmployeeSeniorityColumn,
      width: 180,
    },
    {
      ...documentsBulkRequestEmployeeValidationStatusColumn,
      width: 200,
    },
  ].filter(Boolean),
})

type SelectedData =
  SelectTableWrapperOnChangeData<DocumentsBulkRequestEligibleEmployeeInterface>
type SelectedDataControls =
  SelectionControls<DocumentsBulkRequestEligibleEmployeeInterface>

const ReviewFormContent = connect(() => {
  const { values } = useLapeContext<DocumentsBulkRequestInterface>()
  const { query } = useQuery()

  const [selectedData, setSelectedData] = useState<SelectedData>()
  const [isPendingEmployeesUnassign, setIsPendingEmployeesUnassign] = useState(false)
  const [isPendingTriggerReValidate, setIsPendingTriggerReValidate] = useState(false)

  const selectTableControls = useRef<SelectedDataControls>()

  const table = useTable<
    DocumentsBulkRequestEligibleEmployeeInterface,
    DocumentsBulkRequestEligibleEmployeesStatsInterface
  >(
    getDocumentsBulkRequestEligibleEmployeesTableRequests(values.id),
    undefined,
    undefined,
    { disable: !values.id, disableQuery: true },
  )

  const { isPolling: isPollingRequestStatus } = useRefreshRequestStatus(() => {
    table.refresh()
    selectTableControls.current?.resetState()
  })

  const { toggle: toggleFilterByWarnings, isActive: isFilteredByWarnings } =
    useFilterToggle<
      DocumentsBulkRequestEligibleEmployeeInterface,
      DocumentsBulkRequestEligibleEmployeesStatsInterface
    >({
      table,
      columnName: 'status',
      valueName: 'validation_failed',
    })

  const validationNotInProgress =
    !isPollingRequestStatus && values.status.id !== 'validation_in_progress'
  const isReadyToBeSubmitted = values.status.id === 'ready_to_be_submitted'

  const row = useMemo(() => getRow(validationNotInProgress), [validationNotInProgress])

  return (
    <>
      <VStack space="s-16">
        <Subheader>
          <Subheader.Title>
            Review and confirm the employees that you are going to bulk request from
          </Subheader.Title>
        </Subheader>
        {isPollingRequestStatus && (
          <ActionWidget
            title="Eligible employees are being validated"
            text="Validation process can take a while.
            Please wait until it is finished to submit the request, or go to the preview page to submit later."
            avatar={<Spinner color={Token.color.blue} />}
            avatarColor={Token.color.warning}
          >
            <MoreBar>
              <MoreBar.Action
                useIcon="ArrowThinRight"
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.DOCUMENTS.BULK_REQUEST.PREVIEW, {
                  id: values.id,
                })}
              >
                Go to preview
              </MoreBar.Action>
            </MoreBar>
          </ActionWidget>
        )}
        {!values.is_valid && !isPollingRequestStatus && (
          <ActionWidget
            title="Employees validation has failed"
            text="Please review and edit eligible employees table to start the validation process again"
            avatar={<Avatar useIcon="ExclamationTriangle" color={Token.color.red} />}
            avatarColor={Token.color.warning}
          />
        )}
        <TableWidget>
          <TableWidget.Info>
            <Stat val={table.stats?.total} label="Total" />
            <Stat
              val={table.stats?.validations_pending}
              color={Token.color.warning}
              label="Validating"
            />
            <Stat
              val={table.stats?.validations_failed}
              label={pluralize('Warning', table.stats?.validations_failed)}
              color={Token.color.warning}
            />
            <Stat
              val={table.stats?.validations_completed}
              label="OK"
              color={Token.color.success}
            />
          </TableWidget.Info>
          <TableWidget.Actions>
            <MoreBar>
              <MoreBar.Action
                useIcon="Retry"
                variant="accent"
                pending={isPendingTriggerReValidate}
                disabled={isPollingRequestStatus || isReadyToBeSubmitted}
                onClick={async () => {
                  try {
                    if (isFilteredByWarnings) {
                      toggleFilterByWarnings()
                    }
                    setIsPendingTriggerReValidate(true)
                    documentsBulkRequestTriggerValidation(values.id)
                    values.status.id = 'validation_in_progress'
                  } finally {
                    setIsPendingTriggerReValidate(false)
                  }
                }}
              >
                Re-validate
              </MoreBar.Action>
              <MoreBar.Action
                disabled={!selectedData?.someSelected || isPollingRequestStatus}
                useIcon="CrossSmall"
                variant="negative"
                pending={isPendingEmployeesUnassign}
                onClick={async () => {
                  let selectedIds: number[] | undefined
                  let invertSelection = false

                  if (selectedData?.selectedRowsIds.size) {
                    selectedIds = Array.from(selectedData.selectedRowsIds).map(id =>
                      Number(id),
                    )
                  } else if (
                    selectedData?.isAllSelected &&
                    !selectedData.excludeListIds.size
                  ) {
                    selectedIds = []
                  } else if (
                    selectedData?.isAllSelected &&
                    selectedData.excludeListIds.size
                  ) {
                    selectedIds = Array.from(selectedData.excludeListIds).map(id =>
                      Number(id),
                    )
                    invertSelection = true
                  }
                  if (selectedIds !== undefined) {
                    try {
                      selectTableControls.current?.resetState()
                      setIsPendingEmployeesUnassign(true)
                      await documentsBulkRequestUnassignEmployee(
                        values.id,
                        selectedIds,
                        invertSelection,
                      )
                      table.refresh()
                      documentsBulkRequestTriggerValidation(values.id)
                      values.status.id = 'validation_in_progress'
                    } finally {
                      setIsPendingEmployeesUnassign(false)
                    }
                  }
                }}
              >
                Remove selected
              </MoreBar.Action>
            </MoreBar>
          </TableWidget.Actions>
          <TableWidget.Filters>
            {!!table.stats?.validations_failed && (
              <FilterButton
                active={isFilteredByWarnings}
                onClick={toggleFilterByWarnings}
                disabled={isPollingRequestStatus || !table.stats?.validations_failed}
              >
                Show warnings
              </FilterButton>
            )}
          </TableWidget.Filters>
          <TableWidget.Table>
            <SelectTableWrapper
              enabled={validationNotInProgress}
              onChange={setSelectedData}
              filters={table.filterBy}
              tableDataLength={table.data.length}
              tableData={table.data}
              onControlsLoaded={controls => {
                selectTableControls.current = controls
              }}
            >
              <AdjustableTable<
                DocumentsBulkRequestEligibleEmployeeInterface,
                DocumentsBulkRequestEligibleEmployeesStatsInterface
              >
                useWindowScroll
                idPath="employee.id"
                name={TableNames.BulkRequestDocumentsEligibleEmployees}
                row={row}
                {...table}
                noDataMessage="All eligible employees will appear here"
                dataType="Eligible employee"
              />
            </SelectTableWrapper>
          </TableWidget.Table>
        </TableWidget>
      </VStack>
      <PageActions>
        <NewSaveButtonWithPopup
          mt="s-64"
          disabled={!isReadyToBeSubmitted}
          hideWhenNoChanges={false}
          successText="Your request was successfully submitted"
          onClick={() => {
            return documentsBulkRequestActivate(values.id)
          }}
          onAfterSubmit={() =>
            navigateReplace(
              pathToUrl(
                ROUTES.APPS.DOCUMENTS.BULK_REQUEST.PREVIEW,
                { id: values.id },
                query,
              ),
            )
          }
        >
          Submit request
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
})

export const Review = () => (
  <PageBody>
    <Form api={documentsBulkRequestFormRequests}>
      <ReviewFormContent />
    </Form>
  </PageBody>
)
