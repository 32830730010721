import React from 'react'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { useGetEligibleInterviewerGroups } from '@src/api/recruitment/interviewerScheduling'
import { Tooltip, useTooltip } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'

export interface Props {
  onChange: (options?: IdAndName[]) => void
  value?: IdAndName[]
  stageId?: number
  roundId?: number
  isPrepCall: boolean
}
export const SlotInterviewerGroupFilter = ({
  onChange,
  value,
  stageId,
  roundId,
  isPrepCall,
}: Props) => {
  const { data: eligibleInterviewGroups, isLoading } = useGetEligibleInterviewerGroups(
    stageId,
    roundId,
    isPrepCall,
  )
  const showTooltip = !eligibleInterviewGroups?.length
  const tooltip = useTooltip({
    disabled: !showTooltip,
  })

  return (
    <div {...tooltip.getAnchorProps()}>
      <FilterButtonCheckboxSelect<IdAndName>
        options={eligibleInterviewGroups || []}
        label="Int. groups"
        onChange={onChange}
        value={value}
        disabled={showTooltip}
        width={352}
        searchable
        loading={isLoading}
      />
      <Tooltip {...tooltip.getTargetProps()}>
        This interview stage is not configured to have interviewer groups
      </Tooltip>
    </div>
  )
}
