import { Token } from '@revolut/ui-kit'

import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'
import { FaqStatus, FaqTopicStatus } from '@src/interfaces/faq'

export const faqStatusToColor = (status?: FaqStatus) => {
  switch (status) {
    case 'active':
      return Token.color.green
    case 'archived':
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}

export const faqTopicStatusToColor = (status?: FaqTopicStatus) => {
  switch (status) {
    case 'active':
      return Token.color.green
    case 'archived':
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}

export const faqTopicStatusToColorTagVariant = (
  status?: FaqTopicStatus,
): ColorTagVariant => {
  switch (status) {
    case 'active':
      return 'success'
    case 'archived':
      return 'neutral'
    default:
      return 'default'
  }
}
