import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTable, useTableReturnType } from '@src/components/Table/hooks'
import {
  bulkArchiveSpecialisationHiringStages,
  bulkCreateHiringProcessRound,
  specialisationArchiveHiringProcessRound,
  specialisationsHiringProcessOverviewRequests,
} from '@src/api/hiringProcess'
import {
  Avatar,
  Button,
  Checkbox,
  Grid,
  Group,
  HStack,
  Header,
  InputGroup,
  Item,
  ItemSkeleton,
  MoreBar,
  Popup,
  StatusPopup,
  StatusWidget,
  TransitionCollapse,
  Widget,
  useStatusPopup,
} from '@revolut/ui-kit'
import { Virtuoso } from 'react-virtuoso'
import {
  HiringProcessInterface,
  SpecialisationsHiringProcessOverviewInterface,
  StageType,
} from '@src/interfaces/hiringProccess'
import pluralize from 'pluralize'
import HiringProcessDetails from '@src/components/HiringProcessDetails/HiringProcessDetails'
import ShevronToggle from '@src/components/Atoms/ShevronToggle'
import RoundDetailsSidebar from '@src/pages/OnboardingChecklist/HiringProcess/components/RoundDetailsSidebar'
import HiringStagePopup from '@src/pages/OnboardingChecklist/HiringProcess/components/HiringStagePopup'
import { updateHiringProcessRounds } from '@src/api/specialisations'
import SelectTableWrapper, {
  SelectContext,
  SelectTableWrapperOnChangeData,
} from '@src/components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import { SelectAllCell } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import AddStageButton from '@src/pages/OnboardingChecklist/HiringProcess/components/AddStageButton'
import SearchTable, {
  OnFilterHandler,
} from '@src/components/Table/SearchTable/SearchTable'

type RemoveStageButtonProps = {
  disabled: boolean
  specialisations: SpecialisationsHiringProcessOverviewInterface[]
  onRefresh: () => void
}

type GroupByStageTitle = {
  count: number
  disabled: boolean
  stageIds: number[]
  title: string
}

const RemoveStageButton = ({
  disabled,
  specialisations,
  onRefresh,
}: RemoveStageButtonProps) => {
  const [openPopup, setOpenPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedStages, setSelectedStages] = useState<GroupByStageTitle[]>([])
  const statusPopup = useStatusPopup()
  useEffect(() => {
    setSelectedStages([])
  }, [specialisations])
  const stagesList = useMemo(() => {
    const groupByStageTitle = specialisations.reduce((acc, specialisation) => {
      specialisation.hiring_process_rounds.forEach(hiringProcessRound => {
        const key = hiringProcessRound.title
        const disabledStage = hiringProcessRound.stage_type.id === 'cv_screening'
        const curr = acc.get(key) ?? {
          count: 0,
          disabled: disabledStage,
          stageIds: [],
          title: key,
        }
        acc.set(key, {
          ...curr,
          disabled: disabledStage,
          count: curr.count + 1,
          stageIds: [...curr.stageIds, hiringProcessRound.id],
        })
      })
      return acc
    }, new Map<string, GroupByStageTitle>())
    return Array.from(groupByStageTitle.values())
  }, [specialisations])
  const handleRemoveStages = async () => {
    setLoading(true)
    const stageIds = selectedStages.flatMap(selected => selected.stageIds)
    await bulkArchiveSpecialisationHiringStages(stageIds)
    statusPopup.show(
      <StatusPopup
        variant="success"
        onClose={() => {
          setLoading(false)
          setOpenPopup(false)
        }}
      >
        <StatusPopup.Title>
          Successfully removed specialisation hiring stage
        </StatusPopup.Title>
      </StatusPopup>,
    )
    onRefresh()
  }
  return (
    <>
      <MoreBar.Action
        variant="negative"
        disabled={disabled}
        useIcon="Cross"
        onClick={() => {
          setOpenPopup(true)
        }}
      >
        Remove stages
      </MoreBar.Action>
      {openPopup && (
        <Popup
          open
          onClose={() => {
            setOpenPopup(false)
          }}
        >
          <Header>
            <Header.Title>Remove stages</Header.Title>
          </Header>
          <Widget p="s-16">
            <InputGroup>
              {stagesList.map(stage => {
                const selected = !!selectedStages.find(
                  ({ title }) => stage.title === title,
                )
                return (
                  <Checkbox
                    key={stage.title}
                    disabled={stage.disabled || loading}
                    checked={selected}
                    onClick={() => {
                      if (selected) {
                        setSelectedStages(
                          selectedStages.filter(({ title }) => title !== stage.title),
                        )
                      } else {
                        setSelectedStages([...selectedStages, stage])
                      }
                    }}
                  >
                    <Checkbox.Label>{stage.title}</Checkbox.Label>
                    <Checkbox.Description>
                      used in {stage.count} {pluralize('specialisation', stage.count)}
                    </Checkbox.Description>
                  </Checkbox>
                )
              })}
            </InputGroup>
          </Widget>
          <Popup.Actions horizontal>
            <Button
              elevated
              pending={loading}
              variant="secondary"
              onClick={() => {
                setOpenPopup(false)
              }}
            >
              Cancel
            </Button>
            <Button
              elevated
              disabled={!selectedStages.length}
              pending={loading}
              onClick={() => {
                handleRemoveStages()
              }}
            >
              Next
            </Button>
          </Popup.Actions>
        </Popup>
      )}
    </>
  )
}

type SpecialisationListControlsProps = {
  specialisations: SpecialisationsHiringProcessOverviewInterface[]
  specialisationIds: string[]
  onFilterChange: OnFilterHandler
  onRefresh: () => void
}

const SpecialisationListControls = ({
  specialisations,
  specialisationIds,
  onFilterChange,
  onRefresh,
}: SpecialisationListControlsProps) => {
  const selectContext = useContext(SelectContext)
  const { selectedRowsData, isAllSelected } = selectContext
  const disableActions = isAllSelected ? false : !selectedRowsData.size
  const [openPopup, setOpenPopup] = useState(false)
  const [stage, setStage] = useState<Partial<HiringProcessInterface>>()
  const statusPopup = useStatusPopup()
  const showLoadingPopup = () => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>
          Applying hiring process rounds to specialisations
        </StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showSuccessPopup = () => {
    statusPopup.show(
      <StatusPopup variant="success" onClose={statusPopup.hide}>
        <StatusPopup.Title>
          Successfully applied hiring process round to specialisations
        </StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showErrorPopup = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>
          Error applying hiring process rounds to specialisations
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const handleBulkCreate = async (hiringProcessRoundId: number) => {
    showLoadingPopup()
    try {
      await bulkCreateHiringProcessRound(specialisationIds, hiringProcessRoundId)
      showSuccessPopup()
      onRefresh()
    } catch {
      showErrorPopup()
    }
  }
  return (
    <>
      <Widget p="s-16">
        <HStack gap="s-16" align="center">
          <SelectAllCell />
          <AddStageButton
            disabled={disableActions}
            onClick={stageType => {
              setStage({
                stage_type: {
                  id: stageType.id as StageType,
                  name: stageType.name,
                },
              })
              setOpenPopup(true)
            }}
          />
          <RemoveStageButton
            disabled={disableActions}
            specialisations={specialisations}
            onRefresh={onRefresh}
          />
          <SearchTable placeholder="Search" onFilter={onFilterChange} />
        </HStack>
      </Widget>
      {openPopup && (
        <HiringStagePopup
          disableStageType
          isSpecialisation={false}
          generateScorecard={false}
          stage={stage}
          onClose={result => {
            if (result) {
              handleBulkCreate(result.id)
            }
            setOpenPopup(false)
            setStage(undefined)
          }}
          onRefresh={onRefresh}
        />
      )}
    </>
  )
}

type SpecialisationItemProps = {
  loading: boolean
  specialisationHiringProcessOverview: SpecialisationsHiringProcessOverviewInterface
  onRefresh: () => void
  onShowRoundDetails: (hiringProcessRound: HiringProcessInterface) => void
}

const SpecialisationItem = ({
  loading,
  specialisationHiringProcessOverview,
  onRefresh,
  onShowRoundDetails,
}: SpecialisationItemProps) => {
  const selectionContext = useContext(SelectContext)
  const { selectedRowsData, isAllSelected, excludeList, onSelect } = selectionContext
  const selected =
    selectedRowsData.has(String(specialisationHiringProcessOverview.id)) ||
    (isAllSelected && !excludeList.has(String(specialisationHiringProcessOverview.id)))
  const [state, setState] = useState<SpecialisationsHiringProcessOverviewInterface>(
    specialisationHiringProcessOverview,
  )
  useEffect(() => {
    setState(specialisationHiringProcessOverview)
  }, [specialisationHiringProcessOverview])
  const [expanded, setExpanded] = useState(false)
  const [stage, setStage] = useState<Partial<HiringProcessInterface>>()
  const [openPopup, setOpenPopup] = useState(false)
  const statusPopup = useStatusPopup()
  const rounds = state.hiring_process_rounds
  const handleDelete = async (hiringProcessRound: HiringProcessInterface) => {
    const oldState = state
    setState({
      ...state,
      hiring_process_rounds: state.hiring_process_rounds.filter(
        ({ id }) => id !== hiringProcessRound.id,
      ),
    })
    try {
      await specialisationArchiveHiringProcessRound(hiringProcessRound.id)
    } catch {
      setState(oldState)
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            There was an error deleting hiring process rounds order
          </StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }
  const handleOrderChange = async (hiringProcessRounds: HiringProcessInterface[]) => {
    const oldState = state
    setState({
      ...state,
      hiring_process_rounds: hiringProcessRounds,
    })
    try {
      await updateHiringProcessRounds(state.id, hiringProcessRounds)
    } catch {
      setState(oldState)
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            There was an error updating hiring process rounds order
          </StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }
  return (
    <>
      <Group my="s-16">
        <Item
          use="button"
          onClick={() => {
            setExpanded(!expanded)
          }}
        >
          <Item.Prefix>
            <Checkbox
              checked={selected}
              onClick={e => {
                e.stopPropagation()
                onSelect(
                  String(specialisationHiringProcessOverview.id),
                  !selected,
                  specialisationHiringProcessOverview,
                )
              }}
            />
          </Item.Prefix>
          <Item.Content>
            <Item.Title>{state.name}</Item.Title>
            <Item.Description>
              {rounds.length} {pluralize('Hiring stage', rounds.length)}
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <ShevronToggle isOpen={expanded} />
          </Item.Side>
        </Item>
        <TransitionCollapse in={expanded}>
          <HiringProcessDetails
            loading={loading}
            hiringProcessRounds={rounds}
            onDeleteRound={handleDelete}
            onEditRound={hiringProcessRound => {
              setStage(hiringProcessRound)
              setOpenPopup(true)
            }}
            onOrderChange={handleOrderChange}
            onShowRoundDetails={onShowRoundDetails}
          />
          <Item
            use="button"
            onClick={() => {
              setOpenPopup(true)
            }}
          >
            <Item.Avatar>
              <Avatar useIcon="Plus" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Add hiring stage</Item.Title>
            </Item.Content>
          </Item>
        </TransitionCollapse>
      </Group>
      {openPopup && (
        <HiringStagePopup
          disableStageType={!!stage}
          isSpecialisation
          generateScorecard={false}
          stage={{
            ...stage,
            specialisation: {
              id: specialisationHiringProcessOverview.id,
            },
          }}
          onClose={() => {
            setOpenPopup(false)
            setStage(undefined)
          }}
          onRefresh={onRefresh}
        />
      )}
    </>
  )
}

type SpecialisationListNoResultsFoundProps = {
  table: useTableReturnType<SpecialisationsHiringProcessOverviewInterface>
}

const SpecialisationListNoResultsFound = ({
  table,
}: SpecialisationListNoResultsFoundProps) => {
  const noResults = !table.data.length && !table.loading
  if (!noResults) {
    return null
  }
  return (
    <StatusWidget>
      <StatusWidget.Title>No specialisations found</StatusWidget.Title>
      {!!table.filterBy.length && (
        <StatusWidget.Description>
          No specialisations found for {table.filterBy[0].filters[0].id}
        </StatusWidget.Description>
      )}
    </StatusWidget>
  )
}

const SpecialisationList = () => {
  const table = useTable(specialisationsHiringProcessOverviewRequests)
  const [showRoundDetails, setShowRoundDetails] = useState<HiringProcessInterface>()
  const [selectedData, setSelectedData] =
    useState<
      SelectTableWrapperOnChangeData<SpecialisationsHiringProcessOverviewInterface>
    >()
  const [selectedSpecialisationIds, selectedSpecialisations] = useMemo(() => {
    let ids = selectedData?.selectedRowsIds
      ? Array.from(selectedData?.selectedRowsIds)
      : []
    if (selectedData?.isAllSelected) {
      ids = table.data.reduce<string[]>((acc, item) => {
        if (!selectedData?.excludeListIds?.has(String(item.id))) {
          acc.push(String(item.id))
        }

        return acc
      }, [])
    }
    const specialisations = table.data.filter(({ id }) => ids.includes(String(id)))
    return [ids, specialisations]
  }, [selectedData, table.data])
  return (
    <>
      <SelectTableWrapper
        enabled
        onChange={setSelectedData}
        filters={table.filterBy}
        tableDataLength={table.data.length}
        tableData={table.data}
      >
        <SpecialisationListControls
          specialisations={selectedSpecialisations}
          specialisationIds={selectedSpecialisationIds}
          onFilterChange={table.onFilterChange}
          onRefresh={table.refresh}
        />
        {table.data.length ? (
          <Grid mb="s-64" pb="s-64">
            <Virtuoso<SpecialisationsHiringProcessOverviewInterface>
              useWindowScroll
              data={table.data}
              overscan={150}
              style={{ height: '100vh' }}
              itemContent={(_, specialisationHiringProcessOverview) => {
                return (
                  <SpecialisationItem
                    key={specialisationHiringProcessOverview.id}
                    loading={table.loading}
                    specialisationHiringProcessOverview={
                      specialisationHiringProcessOverview
                    }
                    onRefresh={table.refresh}
                    onShowRoundDetails={hiringProcessRound => {
                      setShowRoundDetails(hiringProcessRound)
                    }}
                  />
                )
              }}
              endReached={() => {
                table.fetchNextPage()
              }}
            />
          </Grid>
        ) : (
          <></>
        )}
      </SelectTableWrapper>
      {table.loading && (
        <Group>
          <ItemSkeleton />
        </Group>
      )}
      <SpecialisationListNoResultsFound table={table} />
      {showRoundDetails && (
        <RoundDetailsSidebar
          hiringProcessRound={showRoundDetails}
          generatedScorecard={false}
          onClose={() => {
            setShowRoundDetails(undefined)
          }}
        />
      )}
    </>
  )
}

export default SpecialisationList
