import React, { useEffect, useMemo, useState } from 'react'
import { ActionButton } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { JobPostingLocationTypeInterface } from '@src/interfaces/jobPosting'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import ChangeJobPostingSidebar from '@src/pages/Forms/JobPosting/Components/ChangeJobPostingSidebar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReferCandidateInterface } from '@src/interfaces/referCandidate'
import { connect } from 'lape'
import { renderOption } from '@src/components/JobPostingOption/JobPostingOption'

type Props = {
  selectedMainPosting: JobPostingLocationTypeInterface | null
  onChangeSelectedMainPosting: (value: JobPostingLocationTypeInterface | null) => void
  noSideBar?: boolean
}

const NewCandidatePostingSelect = ({
  selectedMainPosting,
  onChangeSelectedMainPosting,
  noSideBar,
}: Props) => {
  const { errors } = useLapeContext<ReferCandidateInterface>()
  const { data: postingsOptions } = useGetSelectors<JobPostingLocationTypeInterface>(
    selectorKeys.job_postings_locations_type,
  )

  const [openSidebar, setOpenSidebar] = useState(false)

  const mainPostingsOptions = useMemo(() => {
    return (
      postingsOptions?.map(item => ({
        label: item.name,
        value: item,
      })) || []
    )
  }, [postingsOptions])

  useEffect(() => {
    if (noSideBar) {
      return
    }

    if (selectedMainPosting) {
      setOpenSidebar(true)
    }
  }, [selectedMainPosting, noSideBar])

  return (
    <>
      {!!selectedMainPosting && !noSideBar && (
        <ChangeJobPostingSidebar
          onClose={() => setOpenSidebar(false)}
          selectedJobPostingId={selectedMainPosting.id}
          postingIds={[selectedMainPosting.id]}
          isOpen={openSidebar}
          isSelector={false}
          title="Selected postings"
          subtitle=""
        />
      )}

      <RadioSelectInput<JobPostingLocationTypeInterface>
        label="Select main posting"
        options={mainPostingsOptions}
        value={selectedMainPosting}
        onChange={option => {
          onChangeSelectedMainPosting(option || null)
          errors.posting_id = undefined
        }}
        message={errors.posting_id}
        hasError={!!errors.posting_id}
        inputProps={{
          'data-name': 'posting_id',
        }}
      >
        {renderOption}
      </RadioSelectInput>

      {!!selectedMainPosting && !noSideBar && (
        <ActionButton onClick={() => setOpenSidebar(true)}>
          View selected posting details
        </ActionButton>
      )}
    </>
  )
}

export default connect(NewCandidatePostingSelect)
