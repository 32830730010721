import { connect } from 'lape'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Absolute, Box, Flex, HStack, InputGroup, MoreBar, Token } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import {
  bulkAssignPolicyRequests,
  timeOffAllPoliciesTableRequests,
} from '@src/api/timeOff'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeSingleCheckbox from '@src/components/Inputs/LapeFields/LapeSingleCheckbox'
import SideBar from '@src/components/SideBar/SideBar'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useTable } from '@src/components/TableV2/hooks'
import { selectorKeys } from '@src/constants/api'
import {
  timeOffPolicyBalanceColumn,
  timeOffPolicyCategoryColumn,
  timeOffPolicyCreatedOnColumn,
  timeOffPolicyDetailsUrlColumn,
  timeOffPolicyGroupColumn,
  timeOffPolicyHeadcountColumn,
  timeOffPolicyNameColumn,
  timeOffPolicyPaidColumn,
  timeOffPolicyStatusColumn,
  timeOffPolicyUnitColumn,
} from '@src/constants/columns/timeOff'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Statuses } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { BulkAssignInterface, TimeOffPolicyTableInterface } from '@src/interfaces/timeOff'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { AddNewPolicyPopup } from './AddNewPolicyPopup'

const ROW: RowInterface<TimeOffPolicyTableInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.PREVIEW, { id })),
  cells: [
    {
      ...timeOffPolicyNameColumn,
      width: 250,
    },
    {
      ...timeOffPolicyHeadcountColumn,
      width: 100,
    },
    {
      ...timeOffPolicyGroupColumn,
      width: 240,
    },
    {
      ...timeOffPolicyCategoryColumn,
      width: 200,
    },
    {
      ...timeOffPolicyPaidColumn,
      width: 120,
    },
    {
      ...timeOffPolicyUnitColumn,
      width: 100,
    },
    {
      ...timeOffPolicyBalanceColumn,
      width: 130,
    },
    {
      ...timeOffPolicyDetailsUrlColumn,
      width: 100,
    },
    {
      ...timeOffPolicyCreatedOnColumn,
      width: 200,
    },
    {
      ...timeOffPolicyStatusColumn,
      width: 100,
    },
  ],
}

export const TimeOffPolicies = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddTimeOffPolicies)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [newPopupOpen, setNewPopupOpen] = useState(false)

  const initialFilterBy = [
    {
      filters: [{ id: Statuses.active, name: Statuses.active }],
      columnName: 'status',
      nonResettable: true,
    },
  ]

  const table = useTable(timeOffAllPoliciesTableRequests, initialFilterBy)

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <HStack space="s-32">
            <Stat label="Total" val={table.stats?.total} />
            <Stat label="Active" val={table.stats?.active} color={Token.color.green} />
            <Stat label="Draft" val={table.stats?.draft} color={Token.color.orange} />
            <Stat
              label="Archived"
              val={table.stats?.archived}
              color={Token.color.greyTone50}
            />
          </HStack>
        </Table.Widget.Info>
        <Table.Widget.Actions>
          {canAdd && (
            <Table.Widget.MoreBar>
              <MoreBar.Action onClick={() => setNewPopupOpen(true)} useIcon="Plus">
                Add policy
              </MoreBar.Action>
              <MoreBar.Action onClick={() => setSidebarOpen(true)} useIcon="Lightning">
                Bulk assign
              </MoreBar.Action>
            </Table.Widget.MoreBar>
          )}
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable
            hideCount
            name={TableNames.TimeOffPolicies}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="Policies will appear here"
          />
        </Table.Widget.Table>
      </Table.Widget>
      <AddNewPolicyPopup open={newPopupOpen} onClose={() => setNewPopupOpen(false)} />
      <SideBar
        title="Bulk assign"
        subtitle="Use this to assign a policy to multiple groups at once"
        isOpen={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        <Form
          api={bulkAssignPolicyRequests}
          forceParams={{ new: 'new' }}
          disableLocalStorageCaching
        >
          <BulkAssignForm
            onSuccess={() => {
              table.refresh()
              setSidebarOpen(false)
            }}
          />
        </Form>
      </SideBar>
    </>
  )
}

interface SidebarFormProps {
  onSuccess: () => void
}

const BulkAssignForm: React.FC<SidebarFormProps> = connect(({ onSuccess }) => {
  const { values } = useLapeContext<BulkAssignInterface>()

  const CALENDAR_HEIGHT_ADJUSTMENT = '550px'

  return (
    <Flex flexDirection="column" height={0}>
      <Box pb={CALENDAR_HEIGHT_ADJUSTMENT}>
        <InputGroup>
          <LapeRadioSelectInput
            label="Group"
            selector={selectorKeys.dynamic_groups}
            name="group"
          />
          <LapeRadioSelectInput
            label="Policy"
            selector={selectorKeys.active_time_off_policies}
            name="policy"
          />
          <LapeSingleCheckbox
            name="force_use_employee_join_date"
            label="Use employee join date as start date"
            description="If employee has no join date, today's date will be used"
            checked={values.force_use_employee_join_date}
            onChange={e => {
              if (e.currentTarget.checked) {
                values.start_date = new Date().toISOString()
                values.force_use_employee_join_date = true
              } else {
                values.start_date = ''
                values.force_use_employee_join_date = false
              }
            }}
          />
          {!values.force_use_employee_join_date && (
            <LapeDatePickerInput name="start_date" label="Start date" required />
          )}
          <LapeDatePickerInput name="end_date" label="End date" />
        </InputGroup>
      </Box>

      <Absolute bottom="s-24" left="s-24" right="s-24">
        <Flex justifyContent="center">
          <NewSaveButtonWithPopup
            successText="Policies assigned"
            onAfterSubmit={onSuccess}
            useValidator
            maxWidth={375}
          />
        </Flex>
      </Absolute>
    </Flex>
  )
})
