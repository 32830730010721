import styled from 'styled-components'
import { Token } from '@revolut/ui-kit'

export const ActiveStageBorder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 8px;
  background-color: ${Token.color.accent};
  border-radius: ${Token.radius.widget} 0 0 ${Token.radius.widget};
`
