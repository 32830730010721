import React, { useMemo } from 'react'
import capitalize from 'lodash/capitalize'
import {
  ActionButtonSkeleton,
  Flex,
  MoreBar,
  RadioSelect,
  Tag,
  Text,
  useDropdown,
} from '@revolut/ui-kit'
import { EmployeeTimeOffBalanceCycle } from '@src/interfaces/timeOff'
import { getTagAndColors } from './utils'

export interface CycleOption extends EmployeeTimeOffBalanceCycle {
  startToEnd: string
}

interface Props {
  isLoading: boolean
  onChange: (newValue: CycleOption) => void
  options: CycleOption[]
  value?: CycleOption
}

const TaggedCycle = ({ value }: Pick<Props, 'value'>) => {
  if (!value) {
    return <Text>Unknown cycle</Text>
  }
  const { tag, ...colors } = getTagAndColors(value.status.id)

  return (
    <Flex alignItems="center" justifyContent="space-between" gap="s-8">
      <Text>{value.startToEnd}</Text>
      {tag && (
        <Tag {...colors} variant="faded">
          {capitalize(tag)}
        </Tag>
      )}
    </Flex>
  )
}

export const BalanceCycleSelector = ({ isLoading, options, onChange, value }: Props) => {
  const dropdown = useDropdown()

  const formattedOptions = useMemo(
    () =>
      options.map(option => {
        return { key: option.id, label: option.startToEnd, value: option }
      }),
    [options],
  )

  if (isLoading) {
    return <ActionButtonSkeleton width={200} />
  }

  return (
    <>
      <MoreBar.Action
        {...dropdown.getAnchorProps()}
        aria-label="Select current cycle"
        aria-haspopup="listbox"
        useIcon="CalendarDate"
      >
        <TaggedCycle value={value} />
      </MoreBar.Action>
      <RadioSelect
        {...dropdown.getTargetProps()}
        labelList="Available cycles"
        minWidth={300}
        onChange={newValue => {
          if (newValue) {
            onChange(newValue)
          }
        }}
        options={formattedOptions}
        value={value}
      >
        {option => <TaggedCycle key={option.key} value={option.value} />}
      </RadioSelect>
    </>
  )
}
