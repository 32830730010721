import { OneToOneMeetingChangelogItem } from '@src/interfaces/meetings'

export const changeLocationToTitleMap: Record<
  OneToOneMeetingChangelogItem['change_location'],
  string
> = {
  action_items: 'Action items',
  talking_points: 'Talking points',
  summary: 'Summary',
  manager_personal_notes: 'Personal notes',
  report_personal_notes: 'Personal notes',
  performance_rating: 'Performance Rating',
  attachments: 'Attachments',
}
