import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  InterviewFeedbackInterface,
  InterviewFeedbackStatus,
} from '@src/interfaces/interviewTool'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ActionButton, Text, Token } from '@revolut/ui-kit'
import { InterviewResultLabel } from '@components/ColumnInserts/InterviewResult/InterviewResultLabel'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import Table from '@components/TableV2/Table'
import { useIsNewTable } from '@components/TableV2/hooks'
import { CandidateNameCell } from '@src/constants/columns/candidates'
import { ColorTagVariant } from '@components/ColorTag/ColorTag'

const ViewLink = styled(Link)`
  color: ${Token.color.blue};
  text-decoration: none;

  &:hover {
    color: ${Token.color.blue_60};
  }
`

const getStatusVariant = (status: InterviewFeedbackStatus): ColorTagVariant => {
  switch (status) {
    case InterviewFeedbackStatus.draft:
    case InterviewFeedbackStatus.pending:
      return 'warning'

    default:
      return 'neutral'
  }
}

export const interviewStatus: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status_order',
  filterKey: 'status',
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (
    <Table.StatusCell status={data.status} variant={getStatusVariant(data.status)} />
  ),
  title: 'Status',
}

export const interviewCandidate: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'interview_stage.interview_round.candidate.full_name',
  dataPoint: 'interview_stage.interview_round.candidate.full_name',
  sortKey: 'interview_stage__interview_round__candidate__full_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Candidate',
  insert: ({ data }) => (
    <CandidateNameCell
      id={data.interview_stage.interview_round.candidate.id}
      name={data.interview_stage.interview_round.candidate.full_name}
      archived={data.interview_stage.interview_round.state === 'archived'}
      // it should not be a link as users usually don't have the access to the candidate profile from the interviews table
      noLink
    />
  ),
}

export const interviewRole: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.text,
  idPoint: 'interview_stage.interview_round.role.id',
  dataPoint: 'interview_stage.interview_round.role.name',
  sortKey: 'interview_stage__interview_round__role',
  filterKey: 'interview_stage__interview_round__role',
  selectorsKey: selectorKeys.role,
  title: 'Role',
}

export const interviewSeniority: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'interview_stage.interview_round.seniority.id',
  dataPoint: 'interview_stage.interview_round.seniority.name',
  sortKey: 'interview_stage__interview_round__seniority',
  filterKey: 'interview_stage__interview_round__seniority',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => (
    <Table.SeniorityCell
      seniority={data.interview_stage?.interview_round?.seniority?.name}
    />
  ),
}

export const interviewNameColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.text,
  idPoint: 'interview_stage.title',
  dataPoint: 'interview_stage.title',
  sortKey: 'interview_stage__title',
  filterKey: 'interview_stage',
  selectorsKey: selectorKeys.specialisation_hiring_stages,
  colors: () => Token.color.greyTone50,
  title: 'Stage',
}

export const interviewDateColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'scheduled_date_time',
  dataPoint: 'scheduled_date_time',
  sortKey: 'scheduled_date_time',
  filterKey: 'scheduled_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Date',
}

export const interviewRatingColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'result',
  dataPoint: 'result',
  sortKey: 'result_order',
  filterKey: 'result',
  selectorsKey: selectorKeys.interview_feedback_result_choices,
  insert: ({ data }) =>
    data.status === InterviewFeedbackStatus.completed ? (
      <InterviewResultLabel result={data.result} status={data.status} iconSize={16} />
    ) : (
      <Text color={Token.color.greyTone50}>n/a</Text>
    ),
  title: 'Rating',
}

const ScorecardButton = ({
  id,
  status,
}: {
  id: number
  status: InterviewFeedbackStatus
}) => {
  const isNewTable = useIsNewTable()

  let label = null

  switch (status) {
    case InterviewFeedbackStatus.completed:
      label = 'Edit'
      break

    case InterviewFeedbackStatus.pending:
      label = 'Rate'
      break

    default:
      label = 'View'
  }

  if (isNewTable) {
    return (
      <ActionButton
        use={Link}
        // @ts-expect-error object works fine here, but UI kit expects string
        to={getLocationDescriptor(
          pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, { id }),
        )}
        onClick={e => e.stopPropagation()}
        variant="primary"
      >
        {label}
      </ActionButton>
    )
  }

  return (
    <ViewLink
      to={getLocationDescriptor(
        pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, { id }),
      )}
      onClick={e => e.stopPropagation()}
    >
      {label}
    </ViewLink>
  )
}

export const interviewScorecardColumn: ColumnInterface<InterviewFeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Scorecard',
  insert: ({ data }) => <ScorecardButton id={data.id} status={data.status} />,
}
