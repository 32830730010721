export const parsePercentage = (value: string): number | null => {
  if (value === '') {
    return null
  } // Treat empty string as null

  // Replace commas with dots and remove the % sign
  const sanitizedValue = value.replace(',', '.').replace('%', '')

  // Parse as a float
  const numericValue = parseFloat(sanitizedValue)

  return isNaN(numericValue) ? null : numericValue
}
