import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useEffect, useState } from 'react'
import { useTableReturnType } from '@components/TableV2/hooks'
import { useQuery } from '@src/utils/queryParamsHooks'

export const useAssignedToMeFilter = <T, S>(table: useTableReturnType<T, S>) => {
  const user = useSelector(selectUser)
  const { query } = useQuery()

  const [showAssignedToMe, setShowAssignedToMe] = useState(false)

  const APPROVER_FILTER_COL_NAME = 'approver__id'

  useEffect(() => {
    setShowAssignedToMe(query[APPROVER_FILTER_COL_NAME] === String(user.id))
  }, [query[APPROVER_FILTER_COL_NAME]])

  const getFiltersByCurrentUser = (assignedToMeOnly: boolean) => {
    const filters = assignedToMeOnly
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : []

    return {
      filters,
      columnName: APPROVER_FILTER_COL_NAME,
    }
  }

  const onToggleAssignedToMe = () => {
    setShowAssignedToMe(!showAssignedToMe)
    table.onFilterChange(getFiltersByCurrentUser(!showAssignedToMe))
  }

  return { showAssignedToMe, onToggleAssignedToMe }
}
