import React from 'react'
import { useQueryClient } from 'react-query'

import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { APPROVAL_PROCESS_GROUPS_API } from '@src/api/settings'

export const SaveApprovalSettingsButton = () => {
  const queryClient = useQueryClient()

  return (
    <NewSaveButtonWithPopup
      successText="Settings saved successfully"
      onAfterSubmit={() => {
        queryClient.invalidateQueries(API.PERFORMANCE_SETTINGS)
        queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
      }}
      useValidator
    />
  )
}
